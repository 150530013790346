import { EmployeeDto } from "./employee.dto";
import { PaginatorInfo } from "./generic.dto";
import { LeavePackage } from "./leave-package.dto";

export const LEAVE_REQUEST_OPTIONS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
    CANCELLED: "CANCELLED"
}

const LEAVE_RESPONSE_OPTIONS = {
    APPROVED: "APPROVED",
    DECLINED: "DECLINED"
}

export type LEAVE_REQUEST_OPTIONS = keyof typeof LEAVE_REQUEST_OPTIONS;

export type LEAVE_RESPONSE_OPTIONS = keyof typeof LEAVE_RESPONSE_OPTIONS;

export type LeaveRequest = {
    id: number;
    employeeId: number;
    leavePackageId: number;
    startDate: string;
    returnDate: string;
    comment: string;
    status: LEAVE_REQUEST_OPTIONS;
    cancelledByEmployeeId: number | null;
    createdAt: string;
    modifiedAt: string | null;
    responseCompletedAt: string | null;
    cancelledAt: string | null;
}
  
export class CreateLeaveRequestDto {
    employeeId!: number;
    leaveTypeId!: number;
    startDate!: string;
    returnDate!: string;
    comment!: string;
}

export class UpdateLeaveRequestDto{
    leaveTypeId?: number;
    startDate?: string;
    returnDate?: string;
    comment?: string;
}

export enum LeaveRequestOrderBy {
    START_DATE_ASC = 'startDate:asc',
    START_DATE_DESC = 'startDate:desc',
    RETURN_DATE_ASC = 'returnDate:asc',
    RETURN_DATE_DESC = 'returnDate:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export type REQUEST_QUERY_MODE ='SELF' | 'SUPERVISEES' | 'ALL';

export class QueryLeaveRequestDto {
    employeeId?: number;
    leavePackageId?: number;
    status?: LEAVE_REQUEST_OPTIONS;
    queryMode?: REQUEST_QUERY_MODE;
    'startDate.gte'?: string;
    'startDate.lte'?: string;
    'returnDate.gte'?: string;
    'returnDate.lte'?: string;
    page?: number = 1;
    limit?: number;
    orderBy: LeaveRequestOrderBy = LeaveRequestOrderBy.CREATED_AT_DESC;
}

export class LeaveResponseInputDto {
    action!: LEAVE_RESPONSE_OPTIONS;
    comment?: string;
}

export class CancelLeaveRequestDto {
    status!: LEAVE_REQUEST_OPTIONS;
    cancelledByEmployeeId!: number;
    cancelledAt!: string;
}

enum LEAVE_RESPONSE_TYPE {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    ADJUSTED = 'ADJUSTED'
  }

type LeaveResponse = {
    id: number;
    leaveRequestId: number;
    approvingEmployeeId: number;
    comment: string;
    responseType: LEAVE_RESPONSE_TYPE;
    createdAt: Date;
}

export interface LeaveRequestDto extends LeaveRequest {
    employee?: EmployeeDto,
    leavePackage?: LeavePackage,
    leaveResponses?: LeaveResponse[]
}

export interface LeaveRequestPaginator extends PaginatorInfo<LeaveRequestDto> {}

export const ADJUSTMENT_OPTIONS = {
    INCREASE: 'INCREASE',
    DECREASE: 'DECREASE'
  };
  
  export type ADJUSTMENT_OPTIONS =  keyof typeof ADJUSTMENT_OPTIONS;
  
  export class AdjustDaysDto {
    adjustment!: ADJUSTMENT_OPTIONS;
    count!: number;
    comment!: string;
  }