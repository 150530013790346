import { useState } from "react";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { IAddEditTableForm, validatePast } from "../../../../helpers/appHelpers";
import { EmployeeDto } from "../../../../models/employee.dto";
import { GrievanceType } from "../../../../models/grievance-type.dto";
import { useCreateGrievanceReport, useUpdateGrievanceReport } from "../../../../services/grievance-report.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useUserStore from "../../../../state-management/useUserStore";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";

interface IFormInputs {
	grievanceTypeId: number;
	reportingEmployeeId: number;
	reportDate: string;
	note: string;
}

interface IGrievanceModalForm extends IAddEditTableForm {
	employees: EmployeeDto[];
	grievanceTypes: GrievanceType[];
}

const GrievanceModalForm = ({
	open,
	title,
	setOpen,
	isEditForm,
	tableAPIRef,
	employees,
	grievanceTypes,
}: IGrievanceModalForm) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			grievanceTypeId: isEditForm ? formDataValues?.grievanceTypeId : undefined,
			note: isEditForm ? formDataValues?.notes : "",
			reportDate: isEditForm ? formDataValues?.reportDate?.slice(0, 10) : "",
		}
	});
	
	const [reportedEmployeeIds, setReportedEmployeeIds] = useState<any[]>([]);
	const { mutate: createGrievanceType } = useCreateGrievanceReport();
	const { mutate: updateGrievanceType } = useUpdateGrievanceReport();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateGrievanceType(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			const reportedEmployeeId = reportedEmployeeIds.length 
				? reportedEmployeeIds as any : undefined;

			createGrievanceType(
				{ 
					payload: { 
						...data, 
						companyId: companyProfile.companyId,
						reportedEmployeeId
					} 
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Grievance Type" for="grievanceTypeId" />
						<select
							defaultValue={isEditForm ? formDataValues?.grievanceTypeId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.grievanceTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("grievanceTypeId", {
								required: "Select grievance type here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Grievance Type
							</option>
							{grievanceTypes.map((actionType) => (
								<option 
									key={actionType.id} 
									value={actionType.id} 
								>
									{actionType.name}
								</option>
							))}
						</select>
						{errors.grievanceTypeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.grievanceTypeId.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label title="Reporting Employee" for="reportingEmployeeId" />
							<select
								defaultValue={isEditForm ? formDataValues?.reportingEmployeeId : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.reportingEmployeeId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("reportingEmployeeId", {
									required: "Select reporting employee here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Employee
								</option>
								{employees.map((employee) => (
									<option 
										key={employee.id} 
										value={employee.id} 
									>
										{employee.firstName + " " + employee.lastName}
									</option>
								))}
							</select>
							{errors.reportingEmployeeId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.reportingEmployeeId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Report Date" for="reportDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							defaultValue={
								isEditForm ? formDataValues?.reportDate?.slice(0, 10) : ""
							}
							type="date"
							{...register("reportDate", {
								required: "Enter report date here",
								validate: validatePast,
							})}
							id="reportDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.reportDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.reportDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.reportDate.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full flex flex-col gap-2">
							<Label title="Reported Employees" for="reportedEmployeeId" optional />
							<MultiSelectField
								key={"reportedEmployeeId"}
								options={employees}
								setResourceIds={setReportedEmployeeIds}
								placeholder={"Select Employees"}
								mapTexts={["firstName", "lastName"]}
								required={false}
							/>
						</div>
					)}
					<div className="w-full col-span-2">
						<Label title="Note" for="note" />
						<div className="flex items-center">
							<input
								placeholder={"Write a brief note"}
								defaultValue={isEditForm ? formDataValues?.note : ""}
								type="string"
								{...register("note", {
									required: "Enter note here",
								})}
								id="note"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.note
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
						</div>
						{errors.note && (
							<p className="text-red-500 text-sm mt-1">
								{errors.note.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						title={`${
							uploading 
								? "Uploading..." 
								: isEditForm 
								? "Update Grievance Report" 
								: "Add Grievance Report"
						}`}
						type="submit"
						className={"h-14 px-12"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default GrievanceModalForm;
