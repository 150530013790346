import {APP_CONFIG} from "../helpers/appHelpers";
import {PaginatorInfo} from "./generic.dto";

type SubscriptionTier = {
    id: string;
    name: string;
    description: string;
    maxUsers: number;
    maxCompanies: number;
    maxEmployees: number;
    billingPeriod: BILLING_PERIOD;
    plan: PLAN;
    priceCurrencyId: number;
    priceAmount: number;
    promotionId?: number;
    countryId?: number;
    active?: boolean;
    createdAt: string
    modifiedAt: string | null
}

export interface SubscriptionTierDto extends SubscriptionTier {}

export const BILLING_PERIOD = {
    MONTHLY: 'MONTHLY',
    ANNUALLY: 'ANNUALLY',
};

export type BILLING_PERIOD = keyof typeof BILLING_PERIOD

export const PLAN = {
    PREPAID: 'PREPAID',
    POSTPAID: 'POSTPAID',
};

export type PLAN = keyof typeof PLAN

export class CreateSubscriptionTierDto {
    name!: string;
    description!: string;
    maxUsers!: number;
    maxCompanies!: number;
    maxEmployees!: number;
    billingPeriod!: BILLING_PERIOD;
    plan!: PLAN;
    priceCurrencyId!: number;
    priceAmount!: number;
    promotionId?: number;
    countryId?: number;
    active?: boolean;
}

export class UpdateSubscriptionTierDto {
    name?: string;
    description?: string;
    maxUsers?: number;
    maxCompanies?: number;
    maxEmployees?: number;
    billingPeriod?: BILLING_PERIOD;
    plan?: PLAN;
    priceCurrencyId?: number;
    priceAmount?: number;
    promotionId?: number;
    countryId?: number;
    active?: boolean;
}

export enum SubscriptionTierOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    PRICE_AMOUNT_ASC = 'priceAmount:asc',
    PRICE_AMOUNT_DESC = 'priceAmount:desc',
}

export class QuerySubscriptionTierDto {
    priceCurrencyId?: number;
    detailed?: boolean;
    promotionId?: number;
    countryId?: number;
    active?: boolean;
    plan?: PLAN;
    page?:number = 1;
    limit?: number;
    orderBy?: SubscriptionTierOrderBy = SubscriptionTierOrderBy.CREATED_AT_DESC;
}
export class QueryApplicableSubscriptionTiersDto extends QuerySubscriptionTierDto {
    countryId?: number;
    detailed?: boolean;
    page = 1;
    limit?: number;
    orderBy?: SubscriptionTierOrderBy = SubscriptionTierOrderBy.CREATED_AT_DESC;
}

export type DetailedSubscriptionTierDto = SubscriptionTier & {
    currency?: Currency | null;
    promotion?: Promotion | null;
    netAmount?: number;
}

type Currency = {
    id: number;
    code: string;
};

type Promotion = {
    id: number;
    code: string;
    title: string;
    discountPercent: number;
    activeDays: number;
    startDate: string;
    endDate: string;
}

export class SearchSubscriptionTierDto {
    q?: string;
    page = 1;
    limit: number = APP_CONFIG.PAGE_LIMIT;
    orderBy: SubscriptionTierOrderBy = SubscriptionTierOrderBy.CREATED_AT_DESC;
}

export interface SubscriptionTierPaginator extends PaginatorInfo<SubscriptionTierDto> {}