import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
	createdAtmodifiedAtActions,
	enumToStringConverter,
	formatDate,
	moneyFormat,
} from "../../../../helpers/appHelpers";
import { Avatar } from "@mui/material";
import { STATUS } from "../../../../models/employee.dto";

export const columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employeeName",
		headerName: "Employee Name",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell: (params: any) => {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.row.photoUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p className="text-sm">
						{params.row.firstName + " " + params.row.lastName}
					</p>
				</div>
			);
		},
	},
	{
		field: "gender",
		headerName: "Gender",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "dateOfBirth",
		headerName: "Date Of Birth",
		width: 170,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const dateOfBirth = formatDate(params);
			return dateOfBirth;
		},
	},
	{
		field: "nationality",
		headerName: "Nationality",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "email",
		headerName: "Corporate Email Address",
		width: 265,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getOrganizationStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getOrganizationStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "employeeNumber",
		headerName: "Employee Number",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "netSalary",
		headerName: "Net Salary",
		type: "number",
		width: 200,
		align: "left",
		headerAlign: "left",
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row?.employeePayInfo?.basicPay;
			return <p className="text-sm">GHS {moneyFormat(amount || 0, "en", 2)}</p>;
		},
	},
	{
		field: "msisdn",
		headerName: "Phone Number",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];

export const getOrganizationStatusColor = (status: STATUS) => {
	switch(status) {
		case "ACTIVE":
			return "#4CAF50"
		case "TERMINATED":
			return "#808080"
		case "DISABLED":
			return "#F44336"
		case "ELAPSED_CONTRACT":
			return "#FFC107"
		case "ARCHIVED": 
			return "#2196F3"
		default: 
			return "#BF21F3"
	}
};
