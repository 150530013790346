import { GridColDef } from "@mui/x-data-grid";
import { enumToStringConverter, formatDateAndTime, moneyFormat } from "../../../../../helpers/appHelpers";
import { getInvoiceStatusColor, getTransactionTypeColor } from "../../../../admin/billing/data";

export const TransactionHistoryColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "referenceCode",
		headerName: "Reference Code",
		headerClassName: "super-app-theme--header",
		width: 250,
		valueGetter: (params) => {
			const referenceCode = params.row.invoice?.referenceCode;
			return referenceCode || "--";
		},
	},
	{
		field: "amount",
		headerName: "Amount",
		type: "number",
		width: 200,
		align: "left",
		headerAlign: "left",
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value, "en", 2),
	},
	{
		field: "type",
		headerName: "Transaction Type",
		headerClassName: "super-app-theme--header",
		width: 220,
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getTransactionTypeColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getTransactionTypeColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p className="text-sm">{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "narration",
		headerName: "Description",
		width: 350,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "createdAt",
		headerName: "Date",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => {
			const serviceDate = formatDateAndTime(params);
			return serviceDate;
		},
	},
	{
		field: "status",
		headerName: "Status",
		headerClassName: "super-app-theme--header",
		width: 200,
		renderCell(params) {
			const status = params.row.invoice?.status;

			return status ? (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getInvoiceStatusColor(status) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getInvoiceStatusColor(status), 
							opacity: "15%"
						}}
					/>
					<p className="text-sm">{enumToStringConverter(status)}</p>
				</div>
			) : (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: "#808080" }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: "#808080", 
							opacity: "15%"
						}}
					/>
					<p className="text-sm">Completed</p>
				</div>
			);
		},
	},
];