import { useState, useEffect } from "react";

export function useViewPort() {
	const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);

	useEffect(() => {
        const handleResize = () => {
            setViewPortWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth])

    return viewPortWidth;
}