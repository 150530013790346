import { Box, Modal } from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";

interface IModalComponentProps {
	open: boolean;
	title: string;
	width?: number | string;
	additionalStyles?: any;
	children?: React.ReactNode;
	handleClose: () => void;
}

const ModalComponent = ({
	open,
	title,
	width,
	additionalStyles,
	children,
	handleClose,
}: IModalComponentProps) => {
	let modalWidth = width ? width : 850;
	const style = {
		width: modalWidth,
		maxWidth: "95svw",
		maxHeight: "95svh",
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: 24,
		border: "none",
		borderRadius: "8px",
		outline: "none",
		overflow: "auto",
		display: "flex",
		flexDirection: "column",
	};

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{ ...style, ...additionalStyles }}>
			<div className="flex justify-between items-center px-6 lg:px-9 pt-5 lg:pt-9 mb-4">
					<h1 className="font-bold text-lg">{title}</h1>
					<button onClick={handleClose}>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<div className="grow px-6 lg:px-9 pb-5 lg:pb-9 overflow-auto scroll-smooth">
					{children}
				</div>
			</Box>
		</Modal>
	);
};

export default ModalComponent;
