import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { QUALIFICATION_TYPE } from "../../../../../../models/employee-qualification.dto";
import { useCreateEmployeeQualification } from "../../../../../../services/employee-qualification.service";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { validatePast } from "../../../../../../helpers/appHelpers";

interface IFormInputs {
    qualificationType: QUALIFICATION_TYPE
    qualification: string
    awardingInstitution: string
    awardDate: string;
}

interface INewQualification {
	employeeId: number;
	setShowNewQualificationForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewQualification = ({ employeeId, setShowNewQualificationForm }: INewQualification) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = React.useState(false);
	const { mutate: createEmployeeQualification } = useCreateEmployeeQualification();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createEmployeeQualification(
			{
				payload: {
					...data,
					employeeId,
					companyId: currentCompany?.id as number,
				}
			},
			{
				onSuccess: () => setShowNewQualificationForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Qualification Type" for="qualificationType" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.qualificationType
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("qualificationType", {
							required: "Select qualification type here",
						})}
					>
						<option value="">
							Select Type
						</option>
						<option value="PROFESSIONAL">Professional</option>
						<option value="ACADEMIC">Academic</option>
						<option value="VOCATIONAL">Vocational</option>
					</select>
					{errors.qualificationType && (
						<p className="text-red-500 text-sm mt-1">{errors.qualificationType.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Qualification" for="qualification" />
					<input
						placeholder={"Enter qualification"}
						type="text"
						{...register("qualification", {
							required: "Enter qualification here",
						})}
						id="qualification"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.qualification
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.qualification && (
						<p className="text-red-500 text-sm mt-1">
							{errors.qualification.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Awarding Institution" for="awardingInstitution" />
					<input
						placeholder={"Enter institution"}
						type="text"
						{...register("awardingInstitution", {
							required: "Enter awarding institution here",
						})}
						id="awardingInstitution"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.awardingInstitution
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.awardingInstitution && (
						<p className="text-red-500 text-sm mt-1">
							{errors.awardingInstitution.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Award Date" for="awardDate" />
					<input
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("awardDate", {
							required: "Enter award date here",
							validate: validatePast,
						})}
						id="awardDate"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.awardDate
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.awardDate && (
						<p className="text-red-500 text-sm mt-1">
							{errors.awardDate.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewQualificationForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Qualification"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewQualification;