import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    ExchangeRateHistoryPaginator,
    QueryExchangeRateHistoryDto
} from '../models/exchange-rate-history.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetExchangeRateHistory(options?: QueryExchangeRateHistoryDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE_HISTORY.GET;
    const request = (options: QueryExchangeRateHistoryDto) => API.BillingAndSubscriptionAPI.getExchangeRateHistory(options);

    const { data, isLoading, error, refetch } = useQuery<ExchangeRateHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}
