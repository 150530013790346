import NewRelative from "./NewRelative";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useState } from "react";
import { useGetEmployeeRelative } from "../../../../../../services/employee-relative.service";
import EditRelative from "./EditRelative";
import { EmployeeRelativeDto, EmployeeRelativeOrderBy } from "../../../../../../models/employee-relative.dto";
import { AiOutlinePlus } from "react-icons/ai";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { SkewLoader } from "react-spinners";

const EmployeeRelatives = () => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [showNewRelativeForm, setShowNewRelativeForm] = useState(false);

	const { data: employeeRelatives, isLoading } = useGetEmployeeRelative({ 
		employeeId: formDataValues?.id,
		orderBy: EmployeeRelativeOrderBy.CREATED_AT_DESC,
	});
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold text-sm">Available Relatives</h2>
				{!showNewRelativeForm && (
					<button
						onClick={() => setShowNewRelativeForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Relative
					</button>
				)}
			</div>
			
			{showNewRelativeForm && (
				<NewRelative 
					employeeId={formDataValues?.id}
					companyId={currentCompany?.id as number}
					setShowNewRelativeForm={setShowNewRelativeForm} 
				/>
			)}
				
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{(employeeRelatives as EmployeeRelativeDto[]).map((relative) => (
				<EditRelative 
					key={relative.id}
					employeeRelative={relative} 
				/>
			))}
			
			{(!isLoading && employeeRelatives.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no relatives added yet. Click 'Add New Relative' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default EmployeeRelatives;