import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import { CustomTaxReliefDto } from "../../../../../models/custom-tax-relief.dto";
import { useDeleteCustomTaxRelief, useUpdateCustomTaxRelief } from "../../../../../services/custom-tax-relief.service";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	amount: number;
	active: boolean;
}

interface IEditCustomTaxRelief {
	customTaxRelief: CustomTaxReliefDto;
}

const EditCustomTaxRelief = ({customTaxRelief}: IEditCustomTaxRelief) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateCustomTaxRelief } = useUpdateCustomTaxRelief();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: customTaxRelief.name,
			code: customTaxRelief.code,
			description: customTaxRelief.description,
			amount: customTaxRelief.amount,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateCustomTaxRelief(
			{ payload: data, id: customTaxRelief.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCustomTaxRelief } = useDeleteCustomTaxRelief();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCustomTaxRelief(
			{ id: customTaxRelief?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={customTaxRelief.id + "bh-content"}
				id={customTaxRelief.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{customTaxRelief.name}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
							<div className="w-full">
								<Label title="Name" for={`name`} />
								<input
									defaultValue={customTaxRelief.name}
									disabled={formDisabled}
									placeholder={"Enter Name"}
									type="text"
									{...register(`name`, {
										required: "Enter name here",
									})}
									id={`name`}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.name && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.name?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: customTaxRelief.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors?.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.code?.message}
									</p>
								)}
							</div>
							<div className="col-span-2 w-full">
								<Label title="Description" for={`description`} />
								<FormDescriptionInput 
									attributes={{
										defaultValue: customTaxRelief.description,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.description
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
									register={register}
									required="Enter brief description here"
									disabled={formDisabled}
								/>
								{errors?.description && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.description?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Amount" for="amount" />
								<MoneyInput 
									attributes={{
										id: "amount",
										placeholder: "0",
										disabled: formDisabled,
									}}
									defaultValue={customTaxRelief.amount}
									register={register}
									name="amount"
									setValue={setValue}
									required="Enter amount here"
									error={errors.amount}
								/>
								{errors.amount && (
									<p className="text-red-500 text-sm mt-1">
										{errors.amount.message}
									</p>
								)}
							</div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									defaultChecked={customTaxRelief.active === true}
									disabled={formDisabled}
									type="checkbox"
									{...register("active", {
										required: false
									})}
									id="active"
								/>
								<label htmlFor="active">Active</label>
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12 hover:opacity-95"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={customTaxRelief.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditCustomTaxRelief;
