import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 220,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 180,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];

export const formatDepartmentRank = (rank: number) => {
	switch(rank) {
		case 0: 
			return "Head";
		case 1: 
			return "First Deputy";
		case 2: 
			return "Second Deputy";
		case 3: 
			return "Third Deputy";
		case 4: 
			return "Fourth Deputy";
		case 5: 
			return "Fifth Deputy";
	}
}