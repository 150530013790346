import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Columns } from "./data";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import APISearchInput from "../../../../../components/search/APISearchInput";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import DisciplinaryTypeModalForm from "./modal";
import { 
	useDeleteDisciplinaryActionType, 
	useGetDisciplinaryActionType, 
	useGetDisciplinaryActionTypeBySearch 
} from "../../../../../services/disciplinary-action-type.service";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import TableFilter from "../../../../../components/table/filter/TableFilter";

const DisciplinaryTypes = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const { mutate: searchDisciplinaryActionTypes } = useGetDisciplinaryActionTypeBySearch();
	const {
        tableAPIRef,
        tableData,
        fetchedTableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams({
		fetchHook: useGetDisciplinaryActionType,
		options: { companyId: currentCompany?.id }
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};
	
	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteDisciplinaryType } = useDeleteDisciplinaryActionType();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteDisciplinaryType(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Disciplinary Types</h2>
			<hr />
			<div className="flex items-center justify-between mt-7 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchDisciplinaryActionTypes}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Disciplinary Type
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<DisciplinaryTypeModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={isEditForm
						? "Update Disciplinary Type"
						: "Add New Disciplinary Type"}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</div>
	);
};

export default DisciplinaryTypes;
