import { BillingTypeDto } from "./billing-type.dto";
import { CountryDto } from "./country.dto";
import { CurrencyDto } from "./currency.dto";
import { PaginatorInfo } from "./generic.dto";
import { OrganizationConfig } from "./organization-config.dto";
import { SubscriptionTierDto } from "./subscription-tier.dto";

export const STATUS = {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
    SUSPENDED_PENDING_PAYMENT: "SUSPENDED_PENDING_PAYMENT"
}

export type STATUS = keyof typeof STATUS;

type Organization = {
    id: string;
    name: string;
    countryId: number;
    currencyId: number;
    billingTypeId: number;
    billingType: BillingTypeDto;
	subscriptionTierId: string;
	subsciptionTier: SubscriptionTierDto;
    status: STATUS;
    contactEmail: string;
    billingContactEmail?: string;
    contactMsisdn?: string;
    address: string;
    logoUrl?: string;
    subscriptionDate?: string;
    lastSuccessfullyBilledAt?: string;
    statusLastModifiedAt?: string;
    createdAt: string;
    modifiedAt?: string;
}

export class GenericOrganizationConfigDto {
    maxUsers?: number | null;
    maxCompanies?: number | null;
    maxEmployees?: number | null;
    enableMonthlyStatementAlerts?: boolean = false;
}

export type OrganizationWithOptionalRelations = Organization & {
    config?: OrganizationConfig | GenericOrganizationConfigDto | null;
    country?: CountryDto;
    currency?: CurrencyDto;
    billingType?: BillingTypeDto;
    subscriptionTier?: SubscriptionTierDto;
  }

export class CreateOrUpdateOrganizationConfigDto extends GenericOrganizationConfigDto {
    organizationId!: string;
}

export interface CreateOrganizationDto {
    name: string;
    countryId: number;
    billingTypeId: number;
    subscriptionTierId?: string;
    contactEmail: string;
    billingContactEmail?: string;
    contactMsisdn?: string;
    address?: string;
    logoUrl?: string;
    documents?: DocumentRecord[];
    config?: GenericOrganizationConfigDto;
    adminProfile: AdminProfile;
}

export type AdminProfile = {
    username: string;
    emailAddress: string;
    firstName?: string;
    lastName?: string;
}

export interface UpdateOwnOrganizationDto {
    name?: string;
    contactEmail?: string;
    billingContactEmail?: string;
    contactMsisdn?: string;
    address?: string;
    logoUrl?: string;
}

export interface UpdateOrganizationPartialDto extends UpdateOwnOrganizationDto {
    countryId?: number;
    status?: STATUS;
}

export interface UpdateOrganizationDto extends UpdateOrganizationPartialDto {
    billingTypeId?: number;
    subscriptionTierId?: string;
    subscriptionDate?: string;
    lastSuccessfullyBilledAt?: string;
    statusLastModifiedAt?: string;
}

export enum OrganizationOrderBy {
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
}

export class QueryOrganizationDto {
    countryId?: number;
    billingTypeId?: number;
    subscriptionTierId?: string;
    contactEmail?: string;
    page?: number = 1;
    limit?: number;
    orderBy: OrganizationOrderBy = OrganizationOrderBy.CREATED_AT_DESC;
}

export class CountOrganizationDto {
    countryId?: number;
    billingTypeId?: number;
    subscriptionTierId?: string;
    status?: STATUS;
    'createdAt.gte'?: string;
    'createdAt.lte'?: string;
  }

export class DocumentRecord {
    documentTypeId!: string;
    issuingCountry!: string;
    url!: string;
}

export type OrganizationDto = Organization & { config: GenericOrganizationConfigDto };
export interface OrganizationPaginator extends PaginatorInfo<OrganizationDto> {}

export interface OrganizationWithOptionalRelationsPaginator extends PaginatorInfo<OrganizationDto> {}

export class SearchOrganizationDto {
    q!: string;
    page?: number;
    limit?: number;
  }