import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useCancelLeaveRequest } from "../../../../services/leave-request.service";

type CancelLeaveRequestModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const CancelLeaveRequestModal =  ({ open, setOpen, tableAPIRef }: CancelLeaveRequestModalProps) => {
	const { formDataValues } = useEditFormStore();
	const [canceling, setCanceling] = useState(false);

	const { mutate: cancelLeaveRequest } = useCancelLeaveRequest();

	const cancel = () => {
		setCanceling(true);

		cancelLeaveRequest(
			{ id: formDataValues?.id },
			{
				onSuccess: (updatedData) => {
					tableAPIRef?.current.updateRows([updatedData.data]);
					setOpen(false);
				},
				onSettled: () => setCanceling(false)
			}
		)
	};

	return (
		<ModalComponent
			width={660}
			open={open}
			title="Cancel Request"
			handleClose={() => setOpen(false)}
		>
            <section className="flex items-start justify-between">
                <div>
                    <h2 className="text-secondary-lightgray text-2xs">Start Date</h2>
                    <p className="text-2sm">
                        {new Date(formDataValues?.startDate).toLocaleDateString("en-US", { 
                            month: "short", day: "numeric", year: "numeric" 
                        })}
                    </p>
                </div>
                <div>
                    <h2 className="text-secondary-lightgray text-2xs">Return Date</h2>
                    <p className="text-2sm">
                        {new Date(formDataValues?.returnDate).toLocaleDateString("en-US", { 
                            month: "short", day: "numeric", year: "numeric" 
                        })}
                    </p>
                </div>
            </section>
			<div className="mt-6">
				<h2 className="text-secondary-lightgray text-2xs">Leave Type</h2>
                <div
                    className="rounded-lg px-3 py-2 inline-flex text-white"
                    style={{ background: formDataValues?.leavePackage?.leaveType?.colorCode }}
                >
                    <p className="text-xs">{formDataValues?.leavePackage?.leaveType?.colorCode}</p>
                </div>
			</div>
			<div className="w-full flex justify-end mt-8">
				<OrangeButton 
					title={canceling ? "Canceling..." : "Cancel Request"}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					disabled={canceling} 
					onClick={cancel}
				/>
			</div>
		</ModalComponent>
	);
};
 
export default CancelLeaveRequestModal;