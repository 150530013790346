import {TaxCodeBenefitRule} from "./tax-code-benefit-rule.dto";
import {TaxCodeReliefRule} from "./tax-code-relief-rule.dto";
import {TaxRate} from "./tax-rate.dto";
import {BenefitTax} from "./benefit-tax.dto";
import {TaxRelief} from "./tax-relief.dto";
import {TaxCodeRateRule} from "./tax-code-rate-rule.dto";
import {PaginatorInfo} from "./generic.dto";
import { Country } from "./country.dto";

export type TaxCode = {
  id: number
  countryId: number
  code: string
  enabled: boolean
  isDefault: boolean | null
  startDate: string
  endDate: string
  effectiveDate: string | null
  expiryDate: string | null
  createdAt: string
  modifiedAt: string | null
}


export class CreateTaxCodeDto {
  /**
   * @ignore
   */
  countryId!: number
  enabled!: boolean
  isDefault?: boolean
  startDate!: string
  endDate!: string
  effectiveDate?: string
  expiryDate?: string
  taxRateIds?: number[]
  benefitTaxIds?: number[]
  taxReliefIds?: number[]
}

export interface TaxCodeDto extends TaxCode {
  rateRules?: TaxCodeRateRuleDto[]
  benefitRules?: TaxCodeBenefitRule[]
  reliefRules?: TaxCodeReliefRule[]
  country?: Country
}

export interface TaxCodeRateRuleDto extends TaxCodeRateRule {
  taxRate?: TaxRate
}

export interface TaxCodeBenefitRuleDto extends TaxCodeBenefitRule {
  benefitTax?: BenefitTax
}

export interface TaxCodeReliefRuleDto extends TaxCodeReliefRule {
  taxRelief?: TaxRelief
}


export class UpdateTaxCodeDto {
  enabled?: boolean
  startDate?: string
  endDate?: string
}

export class SetDefaultTaxCodeDto {
  effectiveDate!: string
  expiryDate!: string
}

export class QueryTaxCodeDto {
  countryId?: number;
  enabled?: boolean;
  isDefault?: boolean;
  'startDate.gte'?: string;
  'startDate.lte'?: string;
  'endDate.gte'?: string;
  'endDate.lte'?: string;
  'effectiveDate.gte'?: string;
  'effectiveDate.lte'?: string;
  'expiryDate.gte'?: string;
  'expiryDate.lte'?: string;
  page?: number = 1;
  limit?: number;
  orderBy?: TaxCodeOrderBy = TaxCodeOrderBy.CREATED_AT_ASC;
}



export enum TaxCodeOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  CODE_ASC = 'code:asc',
  CODE_DESC = 'code:desc',
  START_DATE_ASC = 'startDate:asc',
  START_DATE_DESC = 'startDate:desc',
  END_DATE_ASC = 'endDate:asc',
  END_DATE_DESC = 'endDate:desc',
  EFFECTIVE_DATE_ASC = 'effectiveDate:asc',
  EFFECTIVE_DATE_DESC = 'effectiveDate:desc',
  EXPIRY_DATE_ASC = 'expiryDate:asc',
  EXPIRY_DATE_DESC = 'expiryDate:desc',
}

export interface TaxCodePaginator extends PaginatorInfo<TaxCodeDto> {}

export class SearchTaxCodeDto {
  q?: string
  page: number = 1;
  limit?: number;
  orderBy: TaxCodeOrderBy = TaxCodeOrderBy.CREATED_AT_ASC;
}