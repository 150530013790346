import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import { useCreateEmployee } from "../../../../../services/employee.service";
import { EMPLOYEE_PAY_INFO_TYPE} from "../../../../../models/employee.dto";
import { useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import useUserStore from "../../../../../state-management/useUserStore";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { EmployeeFormContext } from "../../EmployeeFormContext";
import { PaymentInstitutionBranchDto } from "../../../../../models/payment-institution-branch.dto";
import API from '../../../../../services/utils/api';
import { useAsyncEffect, useLockFn } from "ahooks";

interface IFormInputs {
	splitPayment: boolean;
	primaryPayPercent: number;
	primaryPaymentChannel: EMPLOYEE_PAY_INFO_TYPE;
	secondaryPaymentChannel?: EMPLOYEE_PAY_INFO_TYPE;
	primaryInstitutionId: number;
	primaryAccountNumber: number;
	primaryInstitutionBranchId?: number;
	secondaryInstitutionId?: number;
	secondaryAccountNumber?: number;
	secondaryInstitutionBranchId?: number;
}

interface IPaymentInfo {
	formData: any;
	setFormData: React.Dispatch<React.SetStateAction<any>>;
	setView: (view: string) => void;
	closeForm: () => void;
}

const PaymentInfo = ({
	formData,
	setView,
	setFormData,
	closeForm,
}: IPaymentInfo) => {
	const { userType, currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const companyProfile = (currentUser.user as any)?.companyProfiles;
	const { paymentInstitutions } = useContext(EmployeeFormContext);
	const [uploading, setUploading] = useState(false);

	const [primaryInstitutionBranches, setPrimaryInstitutionBranches] = 
		useState<PaymentInstitutionBranchDto[]>([]);
	const [secondaryInstitutionBranches, setSecondaryInstitutionBranches] = 
		useState<PaymentInstitutionBranchDto[]>([]);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm<IFormInputs>({
		defaultValues: formData
	});

	useEffect(() => {
		if (formData?.secondaryInstitutionId || formData?.secondaryAccountNumber) return;

		clearErrors("secondaryInstitutionId");
		clearErrors("secondaryAccountNumber");
	}, [formData?.secondaryInstitutionId, formData?.secondaryAccountNumber])
	
	useEffect(() => {
		const { unsubscribe } = watch((values) => {
			setFormData({ ...formData, ...values });
		});
		return () => unsubscribe();
	}, [watch])

	const handlePaymentInstitutionChange = async (id: string, primary = true) => {
		if (!id) {
			if (primary) {
				setPrimaryInstitutionBranches([]);
				setValue( "primaryPaymentChannel", "");
			} else  {
				setSecondaryInstitutionBranches([]);
				setValue( "secondaryPaymentChannel", undefined);
			}

			return
		}

		const paymentInstitution = paymentInstitutions.find(
			paymentInstitution => paymentInstitution.id === Number(id)
		);

		if (!paymentInstitution) return;
		if (paymentInstitution.paymentChannelType !== "BANK") {
			if (primary) {
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "primaryInstitutionBranchId", undefined);
			} else  {
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "secondaryInstitutionBranchId", undefined);
			}
			
			return
		};

		try {
			const paymentInstitutionBranches = await API.BillingAndSubscriptionAPI
				.getPaymentInstitutionBranch({
					limit: APP_CONFIG.PAGE_LIMIT,
					active: true,
					orderBy: "name:asc" as any,
					paymentInstitutionId: paymentInstitution.id
				})

			if (primary) {
				setPrimaryInstitutionBranches(paymentInstitutionBranches.data);
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			} else  {
				setSecondaryInstitutionBranches(paymentInstitutionBranches.data);
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			}
		} catch {}
	};

	// Setting the default values of institution branches
	useAsyncEffect(useLockFn(async () => {
		if (
			formData?.primaryInstitutionId && 
			formData?.primaryPaymentChannel === "BANK" && 
			!primaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.primaryInstitutionId);
		}

		if (
			formData?.secondaryInstitutionId && 
			formData?.secondaryPaymentChannel === "BANK" && 
			!secondaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.secondaryInstitutionId, false);
		}
	}), [])

	useEffect(() => {
		if (formData?.primaryInstitutionBranchId && primaryInstitutionBranches.length) {
			setValue("primaryInstitutionBranchId", formData.primaryInstitutionBranchId);
		}

		if (formData?.secondaryInstitutionBranchId && secondaryInstitutionBranches.length) {
			setValue("secondaryInstitutionBranchId", formData.secondaryInstitutionBranchId);
		}
	}, [primaryInstitutionBranches, secondaryInstitutionBranches])

	const { mutate: createEmployee } = useCreateEmployee();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		const employeePayInfo = {
			basicPay: formData.basicPay,
			payPeriod: formData.payPeriod,
			splitPayment: data.splitPayment === "yes" ? true : false,
			primaryPayPercent: data.primaryPayPercent / 100,
			primaryPaymentChannel: data.primaryPaymentChannel,
			secondaryPaymentChannel: data.secondaryPaymentChannel
				? data.secondaryPaymentChannel
				: "BANK",
			currencyId: formData.currencyId,
		};

		const employeeAccountInfo = [
			{
				type: data.primaryPaymentChannel,
				institutionId: Number(data.primaryInstitutionId),
				institutionBranchId: data.primaryInstitutionBranchId || undefined,
				accountNumber: data.primaryAccountNumber,
				primary: true,
				secondary: false,
				active: true,
				currencyId: formData.currencyId,
			},
		];

		if (data?.secondaryInstitutionId) {
			employeeAccountInfo.push({
				type: data.secondaryPaymentChannel,
				institutionId: Number(data.secondaryInstitutionId),
				institutionBranchId: data.secondaryInstitutionBranchId || undefined,
				accountNumber: data.secondaryAccountNumber,
				primary: false,
				secondary: true,
				active: true,
				currencyId: formData.currencyId,
			});
		}

		const payload = {
			companyId: userType === "HR" ? companyProfile[0]?.companyId : currentCompany?.id,
			notchId: formData.notchId || undefined,
			employeeNumber: formData.employeeNumber,
			title: formData.title,
			firstName: formData.firstName,
			lastName: formData.lastName,
			otherNames: formData.otherNames,
			gender: formData.gender,
			dateOfBirth: formData.dateOfBirth,
			photoUrl: formData.photoUrl,
			ssn: formData.ssn,
			taxIdentificationNumber: formData.taxIdentificationNumber,
			majorGradeLevelId: formData.majorGradeLevelId || undefined,
			minorGradeLevelId: formData.minorGradeLevelId || undefined,
			nationality: formData.nationality,
			regionId: formData.regionId || undefined,
			tribeId: formData.tribeId || undefined,
			email: formData.email,
			privateEmail: formData.privateEmail,
			msisdn: formData.msisdn,
			address: formData.address,
			alternateMsisdn: formData.alternateMsisdn,
			digitalAddress: formData.digitalAddress,
			jobTitleId: formData.jobTitleId || undefined,
			departmentId: formData.departmentId || undefined,
			divisionId: formData.divisionId || undefined,
			stationId: formData.stationId || undefined,
			costAreaId: formData.costAreaId || undefined,
			status: formData.status,
			employmentDate: formData.employmentDate,
			terminationDate: formData.terminationDate || undefined,
			reemployed: formData.reemployed === "yes" ? true : false,
			resident: formData.resident === "yes" ? true : false,
			unionMember: formData.unionMember === "yes" ? true : false,
			employeePayInfo,
			employeeAccountInfo,
			hr: formData.hr === "yes" ? true : false,
		};

		createEmployee(
			{ payload },
			{
				onSuccess: () => {
					addNewTableData();
					setUploading(false);
					setView("personalDetails");
					closeForm();
				},
				onError: () => setUploading(false)
			}
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="mt-6">
				<div className="flex space-x-12 text-base">
					<h3>Split Payment</h3>
					<div className="flex space-x-3 items-center">
						<input
							{...register("splitPayment", {
								required: "Select an option",
							})}
							type="radio"
							value={"yes"}
							id="yes"
						/>
						<label htmlFor="yes">Yes</label>
					</div>
					<div className="flex space-x-3 items-center">
						<input
							{...register("splitPayment", {
								required: "Select an option",
							})}
							type="radio"
							value={"no"}
							id="no"
						/>
						<label htmlFor="no">No</label>
					</div>
				</div>
				{errors.splitPayment && (
					<p className="text-red-500 text-sm mt-1">
						{errors.splitPayment.message}
					</p>
				)}
			</div>

			<div className="mt-6 flex flex-col">
				<Label title="Primary Payment Percentage" for="primaryPayPercent" />
				<div className="flex items-center mt-2">
					<input
						placeholder={"0 - 100"}
						type="number"
						{...register("primaryPayPercent", {
							required: "Enter primary payment percentage here" ,
							valueAsNumber: true,
							min: {
								value: 1,
								message: "Enter a value between 1 and 100",
							},
							max: {
								value: 100,
								message: "Enter a value between 1 and 100",
							},
						})}
						id="primaryPayPercent"
						className={`w-[38%] bg-secondary-gray rounded-tl-lg rounded-bl-lg h-14 px-5 text-sm  ${
							errors.primaryPayPercent
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					<div className="h-14 w-16 bg-black rounded-tr-lg rounded-br-lg text-white flex items-center justify-center">
						%
					</div>
				</div>
				{errors.primaryPayPercent && (
					<p className="text-red-500 text-sm mt-1">
						{errors.primaryPayPercent.message}
					</p>
				)}
			</div>

			<div className="mt-10">
				<h3 className="font-bold">Primary Payment Channel</h3>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="primaryInstitutionId" />
						<select
							id="primaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.primaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("primaryInstitutionId", {
								required: "Select primary bank here",
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value)}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.primaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="primaryAccountNumber" />
						<input
							placeholder="Enter number"
							type="number"
							{...register("primaryAccountNumber", {
								required: "Enter primary bank account number here",
							})}
							id="primaryAccountNumber"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.primaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.primaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.primaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="primaryInstitutionBranchId" />
							<select
								id="primaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.primaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("primaryInstitutionBranchId", {
									required: "Enter primary bank branch here",
									valueAsNumber: true,
								})}
							>
								<option value="">Select Branch</option>
								{primaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.primaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.primaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</div>

			<div className="mt-10">
				<h3 className="font-bold">Secondary Payment Channel <i>(optional)</i></h3>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="secondaryInstitutionId" />
						<select
							id="secondaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.secondaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("secondaryInstitutionId", {
								validate: (value, formData) => {
									if (!value && formData.secondaryAccountNumber) {
										return "Select bank or clear account number field"
									}

									return true
								},
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value, false)}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.secondaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="secondaryAccountNumber" />
						<input
							placeholder="Enter number"
							type="number"
							id="secondaryAccountNumber"
							{...register("secondaryAccountNumber", {
								validate: (value, formData) => {
									if (!value && formData.secondaryInstitutionId) {
										return "Enter account number or clear bank field"
									}

									return true
								}
							})}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.secondaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.secondaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.secondaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="secondaryInstitutionBranchId" />
							<select
								id="secondaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.secondaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("secondaryInstitutionBranchId", {
									required: "Enter secondary bank or clear bank field",
									valueAsNumber: true,
								})}
							>
								<option value="">Select Branch</option>
								{secondaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.secondaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.secondaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</div>
			
			<div className="w-full flex items-center justify-end gap-4 mt-10">
				<button
					type="button"
					onClick={() => setView("employmentDetails")}
					className="h-14 px-8 border border-gray-300 rounded-lg text-sm text-gray-600"
				>
					Go Back
				</button>
				<button
					type="submit"
					disabled={uploading}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
				>
					{uploading ? "Uploading..." : "Save Record"}
				</button>
			</div>
		</form>
	);
};

export default PaymentInfo;
