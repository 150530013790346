import { useEffect, useState } from "react";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { Helmet } from "react-helmet-async";
import LeaveDaysChart from "../../../components/charts/LeaveDaysDoughnut";
import RecentPayTable from "./resources/RecentPayTable";
import useUserStore from "../../../state-management/useUserStore";
import { LeaveTypeDto } from "../../../models/leave-type.dto";
import { IoCaretDown } from "react-icons/io5";
import { useManageLeaveSummary } from "./hooks/useManageLeaveSummary";
import { useGetMyAnnouncement } from "../../../services/announcement.service";
import { AnnouncementDto, AnnouncementOrderBy } from "../../../models/announcement.dto";
import { formatDate } from "../../../helpers/appHelpers";
import { SkewLoader } from "react-spinners";
import AnnouncementsView from "./resources/AnnouncementsView";
import { useManageAccordions } from "../../../helpers/useManageAccordions";
import { BsArrowRightShort } from "react-icons/bs";

const SelfserviceDashboard = () => {
	const currentUser = useUserStore().currentUser.user;
	const companyProfiles = (currentUser.companyProfiles || [])[0];
	const [openAnnouncementsView, setOpenAnnouncementsView] = useState(false);
	const [defaultAnnouncements, setDefaultAnnouncements] = useState<AnnouncementDto[]>([]);
	const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(0);

	const {
        leaveTypes,
        leaveTypeSummary,
        activeLeaveType,
        dropdownOpen,
        setDropdownOpen,
        handleLeaveTypeMenu,
    } = useManageLeaveSummary(
		companyProfiles?.employeeId as number
	);

	const {
		accordionsData: announcements,
		isLoading: loadingAnnouncements, 
		pageCount,
    } = useManageAccordions<AnnouncementDto>({
		fetchHook: useGetMyAnnouncement,
		options: { 
			orderBy: AnnouncementOrderBy.PUBLISH_DATE_DESC
		}
	});

	useEffect(() => {
		if (pageCount === 1 && announcements.length) {
			setDefaultAnnouncements(announcements.slice(0, 5));
		}
	}, [loadingAnnouncements])

	return (
		<SelfserviceLayout pageHeader="Dashboard">
			<Helmet>
				<title>Dashboard | Self Service Flow</title>
			</Helmet>
			<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between lg:space-x-6">
				<div className="lg:w-[35%]">
					<h2 className="font-bold text-2sm lg:text-[19px] text-charcoal mb-6 lg:mb-5">Leave Days</h2>
					<div className="h-[450px] border border-[#333333] border-opacity-[15%] rounded-lg flex flex-col justify-center relative">
						{activeLeaveType && (
							<div className="absolute right-2 top-2 whitespace-nowrap text-sm">
								<button 
									onClick={() => setDropdownOpen(prev => !prev)} 
									className="focus:outline-none flex items-center gap-1 font-medium"
								>
									<span className="max-w-[120px] truncate">{activeLeaveType.name}</span>
									<IoCaretDown />
								</button>
								{dropdownOpen && (
									<div className="absolute z-[999] right-0 mt-2 w- 48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
										<div className="py-1 flex flex-col">
											{(leaveTypes as LeaveTypeDto[]).map((leaveType) => (
												<button 
													key={leaveType.id}
													className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
														${activeLeaveType.id === leaveType.id 
															? "bg-gray-200" 
															: "hover:bg-gray-100"
														}
													`} 
													role="menuitem"
													onClick={() => handleLeaveTypeMenu(leaveType)}
												>
													{leaveType.name}
												</button>
											))}
										</div>
									</div>
								)}
							</div>
						)}
						<div className="h-[280px] flex flex-col items-center relative">
							<LeaveDaysChart {...leaveTypeSummary} />

							{leaveTypeSummary.numberOfDaysLeft && (
								<div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center space-y-2">
									<h1 className="text-[40px] font-bold">
										{leaveTypeSummary?.numberOfDaysLeft}
									</h1>
									<p className="text-sm">Leave days left</p>
								</div>
							)}
						</div>
						<div className="space-y-3 mt-6 ml-14 text-2xs font-inter font-light">
							<div className="flex items-center space-x-2">
								<div className="h-3.5 w-3.5 bg-[#27AE60] rounded-full" />
								<p>
									<span className="font-bold">
										{leaveTypeSummary?.numberOfDaysLeft || "--"}
									</span>
									<span> days left</span>
								</p>
							</div>
							<div className="flex items-center space-x-2">
								<div className="h-3.5 w-3.5 bg-[#6978F0] rounded-full" />
								<p>
									<span className="font-bold">
										{leaveTypeSummary?.numberOfDaysPending || "--"}
									</span>
									<span> days pending</span>
								</p>
							</div>
							<div className="flex items-center space-x-2">
								<div className="h-3.5 w-3.5 bg-[#e0e0e1] rounded-full" />
								<p>
									<span className="font-bold">
										{leaveTypeSummary?.numberOfDaysUsed || "--"}
									</span>
									<span> days used</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:w-[65%] mt-9 lg:mt-0">
					<h2 className="font-bold text-2sm lg:text-[19px] text-charcoal mb-6 lg:mb-5">Recent Pay</h2>
					<div className="mobile-full-width max-lg:pl-4">
						<div className="border border-[#333333] border-opacity-[15%] rounded-lg">
							<RecentPayTable />
						</div>
					</div>
				</div>
			</div>
			<div className="mt-9 lg:mt-[38px] pb-10 lg:pb-20">
				<div className="flex justify-between max-lg:items-center text-charcoal mb-6 lg:mb-5">
					<h2 className="font-bold text-2sm lg:text-[19px]">
						Announcements
					</h2>
					<div
						className="flex items-center font-semibold text-2xs lg:text-base hover:cursor-pointer"
							onClick={() => {
								setSelectedAnnouncementId(0);
								setOpenAnnouncementsView(true);
							}}
					>
						<span>View more</span>
						<BsArrowRightShort className="text-xl lg:text-2xl" />
					</div>
				</div>
				<div className="space-y-3 border border-[#333333] border-opacity-[15%] rounded-lg lg:px-5 px-3 py-2">
					{(loadingAnnouncements && !defaultAnnouncements.length) && (
						<div className="text-center py-[80px]">
							<SkewLoader color="#F58B00" />
						</div>
					)}
					{(!loadingAnnouncements && !defaultAnnouncements.length) && (
						<div className="text-center py-[80px] max-lg:text-sm">
							No announcements to show.
						</div>
					)}
					{defaultAnnouncements.map((announcement, index) => (
						<div 
							key={announcement.id} 
							className={`py-4 lg:py-6 flex justify-between items-start border-gray-200 cursor-pointer 
								${index === 0  ? "" : "border-t"} hover:scale-y-[1.05] transition-all`
							}
							onClick={() => {
								setSelectedAnnouncementId(announcement.id);
								setOpenAnnouncementsView(true);
							}}
						>
							<div className="flex gap-3">
								<div className="min-h-[4px] max-h-[4px] min-w-[4px] rounded-full bg-secondary-red mt-2" />
								<div className="lg:text-2sm text-2xs">
									<h3>{announcement.title}</h3>
									<p className="text-gray-500 mt-4 line-clamp-1">{announcement.body}</p>
								</div>
							</div>
							<p className="lg:text-xs text-[10px] w-[20%] lg:w-[15%] flex justify-end text-gray-500">
								{formatDate({ value: announcement.publishDate } as any)}
							</p>
						</div>
					))}
				</div>
			</div>
			{openAnnouncementsView && (
				<AnnouncementsView
					open={openAnnouncementsView} 
					setOpen={setOpenAnnouncementsView} 
					announcements={announcements} 
					isLoading={loadingAnnouncements} 
					pageCount={pageCount} 
					selectedAnnouncementId={selectedAnnouncementId} 
				/>
			)}
		</SelfserviceLayout>
	);
};

export default SelfserviceDashboard;
