import { useEffect, useState } from "react";
import {IoImageOutline} from "react-icons/io5";
import Label from "../../../../components/form_fields/Label";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import {useDropzone} from "react-dropzone";
import {UpdatePayrollCompanyDto} from "../../../../models/company-account.dto";
import {useDeletePayrollProcessingCompany, useUpdateCompany} from "../../../../services/company-account.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {toast} from "react-toastify";
import { uploadOneDocument } from "../../../../services/document.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import DeleteModal from "../../../../components/modals/DeleteModal";

interface IFormInputs extends UpdatePayrollCompanyDto {}

const CompanyDetails = () => {
	const { 
		currentCompany, 
		savedCompanies, 
		setCurrentCompany,
		setSavedCompanies,
	} = useCompanyStore();
    const [isEditable, setIsEditable] = useState(false);
	const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState("");
	const [file, setFile] = useState<File | null>(null);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        if (currentCompany?.logoUrl) {
            setImage(currentCompany.logoUrl)
        }
    }, [currentCompany]);

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            name: currentCompany?.name,
            address: currentCompany?.address,
            contactEmail: currentCompany?.contactEmail,
            contactMsisdn: currentCompany?.contactMsisdn as string,
            workHoursInADay: currentCompany?.workHoursInADay || undefined,
            leaveRequestApprovalsRequired: currentCompany?.leaveRequestApprovalsRequired || undefined,
            reimbursementRequestApprovalsRequired: currentCompany?.reimbursementRequestApprovalsRequired || undefined,
        }
    });

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
            "image/png": [".png"],
        },
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setImage(URL.createObjectURL(file));
            setIsEditable(true);
			setFile(file);
        },
    });

    const { mutate: updateCompany } = useUpdateCompany();
    const { mutate: deleteCompany } = useDeletePayrollProcessingCompany();
	const [deleting, setDeleting] = useState(false);

    const onSubmit: SubmitHandler<IFormInputs> = async (data: any) => {
		setUploading(true);

		if (file) {
			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				data.logoUrl = fileUrl;
			} else {
				toast.error("Failed to update company logo. Try again!");
                setUploading(false);
                return
			}
		}

        updateCompany(
            {
                id: currentCompany?.id as number,
                payload: {...(data as UpdatePayrollCompanyDto)},
            },
            {
                onSuccess: (updatedCompany) => {
                    setCurrentCompany(updatedCompany?.data);
            
                    const updatedCompanies = savedCompanies.map(company => {
                        if (company.id === updatedCompany.data.id) {
                            return updatedCompany.data;
                        }
                        return company;
                    });
            
                    setSavedCompanies(updatedCompanies);
                    toast.success('Company profile updated successfully');
                },
                onSettled: () => setUploading(false),
            }
        );
    };

    const handleDeleteCompany = () => {
		setDeleting(true);

		deleteCompany(
			{ id: currentCompany?.id as number },
			{
				onSuccess: () => {
                    const newSavedCompanies = savedCompanies.filter(
                        company => company.id !== currentCompany?.id
                    );

                    if (newSavedCompanies[0]) {
                        setCurrentCompany(newSavedCompanies[0]);
                        setSavedCompanies(newSavedCompanies);
                    } else {
                        setCurrentCompany(undefined as any);
                        setSavedCompanies([]);
                    }

					setOpenDeleteModal(false);
                    setDeleting(false);
				},
				onError: () => setDeleting(false),
			}
		);
    };

    return (
        <div>
            <div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-sm">
                <h2 className="font-semibold text-sm">Company Details</h2>
                <button
                    className={`border-none text-primary-blue text-sm ${
                        isEditable ? "hidden" : ""
                    }`}
                    onClick={() => setIsEditable(true)}
                >
                    Edit
                </button>
            </div>
            <div className="flex flex-col mt-5">
                <img
                    alt="company"
                    src={image ? image : "/assets/logos/zeplin.png"}
                    className="border border-gray-300 rounded-lg w-44 h-44 object-cover"
                />
                <div
                    className="text-primary-blue flex items-center space-x-2 mt-3 cursor-pointer"
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <IoImageOutline className=""/>{" "}
                    <span className="text-[11px]">Change Logo</span>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-8">
                    <div className="w-full">
                        <Label title="Name" for="companyName"/>
                        <input
                            placeholder="Enter company's Name"
                            defaultValue={currentCompany?.name}
                            disabled={!isEditable}
                            type="text"
                            {...register("name", {
                                required: "Enter your company name",
                            })}
                            id="companyName"
                            className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
                                errors.name
                                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                    : "border-none"
                            }`}
                        />
                        {errors.name && (
                            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
                        )}
                    </div>
                    <div className="w-full">
                        <Label title="Email" for="companyEmail"/>
                        <input
                            placeholder="Enter Email"
                            defaultValue={currentCompany?.contactEmail}
                            disabled={!isEditable}
                            type="email"
                            {...register("contactEmail", {
                                required: "Enter your company's email",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered value does not match email format",
                                },
                            })}
                            className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
                                errors.contactEmail
                                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                    : "border-none"
                            }`}
                        />
                        {errors.contactEmail && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.contactEmail.message}
                            </p>
                        )}
                    </div>
                    <div className="w-full">
                        <Label title="Phone" for="contactMsisdn"/>
                        <Controller
                            name={"contactMsisdn"}
                            control={control}
                            rules={{
                                required: "Enter a phone number here",
                                maxLength: {
                                    value: 12,
                                    message: "Enter a valid phone number",
                                },
                            }}
                            render={({field}) => (
                                <div
                                    className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
                                        errors.contactMsisdn
                                            ? "border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`}
                                >
                                    <PhoneInput
                                        {...field}
                                        country={"gh"}
                                        disabled={!isEditable}
                                        value={currentCompany?.contactMsisdn}
                                        inputStyle={{
                                            height: errors.contactMsisdn ? "50px" : "48px",
                                            border: "none",
                                            backgroundColor: "#F2F2F4",
                                            color: "black",
                                            boxShadow: "none",
                                            width: "95%",
                                            margin: "0px 0px 0px 15px",
                                            borderRadius: "10px",
                                        }}
                                        containerStyle={{
                                            // marginTop: "8px",
                                            height: errors.contactMsisdn ? "50px" : "48px",
                                            border: "none",
                                            backgroundColor: "#F2F2F4",
                                            boxShadow: "none",
                                            borderRadius: "10px",
                                        }}
                                        buttonStyle={{
                                            border: "none",
                                            backgroundColor: "#F2F2F4",
                                            boxShadow: "none",
                                            padding: "0px 10px",
                                        }}
                                    />
                                </div>
                            )}
                        />
                        {errors.contactMsisdn && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.contactMsisdn.message}
                            </p>
                        )}
                    </div>
                    <div className="w-full">
                        <Label title="Address" for="address"/>
                        <input
                            placeholder="Enter Address"
                            defaultValue={currentCompany?.address}
                            disabled={!isEditable}
                            type="email"
                            {...register("address", {
                                required: "Enter your company's address",
                                // pattern: {
                                // 	value: /\S+@\S+\.\S+/,
                                // 	message: "Entered value does not match email format",
                                // },
                            })}
                            className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
                                errors.address
                                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                    : "border-none"
                            }`}
                        />
                        {errors.address && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.address.message}
                            </p>
                        )}
                    </div>
                    <div className="w-full">
                        <Label
                            title="Approvals Required for Leave Requests"
                            for="leaveRequestApprovalsRequired"
                            optional
                        />
                        <select
                            className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm border-none`}
                            {...register("leaveRequestApprovalsRequired", {
                                required: false,
                                valueAsNumber: true,
                            })}
                            disabled={!isEditable}
                        >
                            <option value="">
                                Select Option
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                        </select>
                    </div>
                    <div className="w-full">
                        <Label
                            title="Approvals Required for Reimbursement Requests"
                            for="reimbursementRequestApprovalsRequired"
                            optional
                        />
                        <select
                            className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm border-none`}
                            {...register("reimbursementRequestApprovalsRequired", {
                                required: false,
                                valueAsNumber: true,
                            })}
                            disabled={!isEditable}
                        >
                            <option value="">
                                Select Option
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                        </select>
                    </div>
                    <div className="flex flex-col w-full">
                        <Label
                            title="Work Hours In A Day"
                            for="workHoursInADay"
                            optional
                        />
                        <div className="flex items-center mt-2 h-14">
                            <input
                                placeholder="Enter value"
                                type="number"
                                {...register("workHoursInADay", {
                                    required: false,
                                    valueAsNumber: true,
                                })}
                                id="workHoursInADay"
                                className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm`}
                                disabled={!isEditable}
                            />
                            <div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
                                Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 flex flex-col gap-6">
                    <div className="flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            defaultChecked={currentCompany?.enableEmployeeLogin === true}
                            {...register("enableEmployeeLogin", {
                                required: false,
                            })}
                            id="enableEmployeeLogin"
                        />
                        <label htmlFor="enableEmployeeLogin">Enable Employee Accounts</label>
                    </div>
                    <div className="flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            defaultChecked={currentCompany?.considerPublicHolidayAsWorkday === true}
                            {...register("considerPublicHolidayAsWorkday", {
                                required: false,
                            })}
                            id="considerPublicHolidayAsWorkday"
                        />
                        <label htmlFor="considerPublicHolidayAsWorkday">Consider Public Holiday As Workday</label>
                    </div>
                    <div className="flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            defaultChecked={currentCompany?.considerWeekendAsWorkday === true}
                            {...register("considerWeekendAsWorkday", {
                                required: false,
                            })}
                            id="considerWeekendAsWorkday"
                        />
                        <label htmlFor="considerWeekendAsWorkday">Consider Weekend As Workday</label>
                    </div>
                    <div className="flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            defaultChecked={currentCompany?.allowNegativeRates === true}
                            {...register("allowNegativeRates", {
                                required: false,
                            })}
                            id="allowNegativeRates"
                        />
                        <label htmlFor="allowNegativeRates">Allow Negative Rates</label>
                    </div>
                </div>
                {isEditable && (
                    <div className="flex w-full justify-end space-x-4 items-center mt-10">
                        <button
                            type="button"
                            className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
                            onClick={() => setIsEditable(false)}
                            disabled={uploading}
                        >
                            Cancel
                        </button>
                        <OrangeButton
                            title={uploading ? "Updating..." : "Save"}
                            className={"px-12"}
                            onClick={handleSubmit(onSubmit)}
                            disabled={uploading}
                        />
                    </div>
                )}
					
                {!isEditable && (
                    <div className="flex w-full justify-end space-x-4 items-center mt-10">
                        <button
                            type="button"
                            onClick={() => setOpenDeleteModal(true)}
                            className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
                            disabled={uploading}
                        >
                            Delete
                        </button>
                    </div>
                )}

                <DeleteModal
                    openDeleteModal={openDeleteModal}
                    spanContent={currentCompany?.name}
                    setOpenDeleteModal={setOpenDeleteModal}
                    handleDeleteMethod={handleDeleteCompany}
                    deleting={deleting}
                />
            </div>
        </div>
    );
};

export default CompanyDetails;
