import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateEmployeeApproverDto,
    EmployeeApproverDto,
    EmployeeApproverPaginator,
    QueryEmployeeApproverDto,
    UpdateEmployeeApproverDto
} from '../models/employee-approver.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetEmployeeApprover(employeeId: number, options?: QueryEmployeeApproverDto) {
    const url = ENDPOINTS.HR.EMPLOYEE_APPROVER.GET;
    const request = (
        employeeId: number, options: QueryEmployeeApproverDto
    ) => API.HrAPI.getEmployeeApprover(employeeId, options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeApproverPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(employeeId, Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetEmployeeApproverById(employeeId: number, id: number) {
    const url = ENDPOINTS.HR.EMPLOYEE_APPROVER.DETAIL;
    const request = API.HrAPI.getEmployeeApproverById(employeeId, id);

    const { data, isLoading, error } = useQuery<{ data: EmployeeApproverDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreateEmployeeApprover() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ employeeId, payload }: { employeeId: number, payload: CreateEmployeeApproverDto }) => API.HrAPI.createEmployeeApprover(employeeId, payload),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_APPROVER.GET);
            },
        },
    );
}

export function usePreflightEmployeeApprover() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ employeeId, payload }: { employeeId: number, payload: CreateEmployeeApproverDto }) => API.HrAPI.preflightEmployeeApprover(employeeId, payload),
    );
}

export function useUpdateEmployeeApprover() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id, employeeId }: { payload: UpdateEmployeeApproverDto, id: number, employeeId: number }) => API.HrAPI.updateEmployeeApprover(employeeId, id, payload),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_APPROVER.GET);
            },
        },
    );
}

export function useDeleteEmployeeApprover() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, employeeId }: { id: number, employeeId: number }) => API.HrAPI.deleteEmployeeApprover(employeeId, id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_APPROVER.GET);
            },
        },
    );
}