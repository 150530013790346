import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import { enumToStringConverter } from "../../../../../helpers/appHelpers";
import { OVERTIME_PAYMENT_TIER_TYPE } from "../../../../../models/overtime-payment-tier.dto";
import { OvertimePaymentTier, OvertimeDto } from "../../../../../models/overtime.dto";
import { useCreateOvertimePaymentTier } from "../../../../../services/overtime-payment-tier.service";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";
import useEditFormStore from "../../../../../state-management/useEditFormStore";

type IFormInputs = Omit<OvertimePaymentTier, "type"> & { paymentType: string };

interface INewPaymentTierForm {
	overtimeType: OvertimeDto;
	companyCurrencies: CompanyCurrencyConfigDto[];
	setShowNewPaymentTierForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewPaymentTierForm = ({ 
	overtimeType, 
	companyCurrencies, 
	setShowNewPaymentTierForm,
}: INewPaymentTierForm) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const { mutate: createPaymentTier } = useCreateOvertimePaymentTier();
	
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		data.type = data.paymentType;
		delete data.paymentType;

		createPaymentTier(
			{
				payload: {
					...data,
					overtimeId: overtimeType.id,
				}
			},
			{
				onSuccess: () => setShowNewPaymentTierForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Payment Type" for="paymentType" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.paymentType
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("paymentType", {
							required: "Select payment type here",
						})}
					>
						<option value="">
							Select Type
						</option>
						{Object.entries(OVERTIME_PAYMENT_TIER_TYPE).map(([key, value]) => (
							<option key={key} value={value} >
								{enumToStringConverter(value)}
							</option>
						))}
					</select>
					{errors.paymentType && (
						<p className="text-red-500 text-sm mt-1">
							{errors.paymentType.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Currency" for="currencyId" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
							errors.currencyId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("currencyId", {
							required: "Enter currency here",
							valueAsNumber: true,
						})}
					>
						<option value="">
							Select Currency
						</option>
						{companyCurrencies?.map((item) => (
							<option key={item?.id} value={item?.id}>
								{item?.currency?.name} ({item?.currency?.code})
							</option>
						))}
					</select>
					{errors.currencyId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currencyId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Fixed Component" for="fixedComponent" />
					<MoneyInput 
						attributes={{
							id: "fixedComponent",
							placeholder: "Enter value",
						}}
						register={register}
						name="fixedComponent"
						setValue={setValue}
						required="Enter fixed component here"
						error={errors.fixedComponent}
					/>
					{errors.fixedComponent && (
						<p className="text-red-500 text-sm mt-1">
							{errors.fixedComponent.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Factor Component" for="factorComponent" />
					<MoneyInput 
						attributes={{
							id: `factorComponent`,
							placeholder: "Enter value",
						}}
						register={register}
						name="factorComponent"
						setValue={setValue}
						required="Enter factor component here"
						error={errors.factorComponent}
					/>
					{errors.factorComponent && (
						<p className="text-red-500 text-sm mt-1">
							{errors.factorComponent.message}
						</p>
					)}
				</div>
				<div className="flex flex-col w-full">
					<Label title="Minimum Number of Hours" for="minHours" />
					<div className="flex items-center mt-2 h-14">
						<input
							placeholder={"0"}
							type="number"
							{...register("minHours", {
								required: "Enter minimum number of hours here",
								valueAsNumber: true,
								validate: (value, formData) => {
									const maxHoursPermitted = formDataValues.maxHoursPermitted;
									const minHoursRequired = formDataValues.minHoursRequired;
									const maxHours = formData.maxHours;
									
									if (maxHours && value >= maxHours) {
										return "Must be less than the max hours"
									}
									if (value > maxHoursPermitted || value < minHoursRequired) {
										return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
									}
									return true
								}
							})}
							id="minHours"
							className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
								errors.minHours
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
							Hours
						</div>
					</div>
					{errors.minHours && (
						<p className="text-red-500 text-sm mt-1">
							{errors.minHours.message}
						</p>
					)}
				</div>
				<div className="flex flex-col w-full">
					<Label title="Maximum Number of Hours" for="maxHours" />
					<div className="flex items-center mt-2 h-14">
						<input
							placeholder={"0"}
							type="number"
							{...register("maxHours", {
								required: "Enter maximum number of hours here",
								valueAsNumber: true,
								validate: (value) => {
									const maxHoursPermitted = formDataValues.maxHoursPermitted;
									const minHoursRequired = formDataValues.minHoursRequired;
									
									if (value > maxHoursPermitted || value < minHoursRequired) {
										return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
									}
									return true
								}
							})}
							id="maxHours"
							className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
								errors.maxHours
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
							Hours
						</div>
					</div>
					{errors.maxHours && (
						<p className="text-red-500 text-sm mt-1">
							{errors.maxHours.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewPaymentTierForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Payment Tier"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewPaymentTierForm;
