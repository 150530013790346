import { EmployeeDto } from './employee.dto';
import { CompanyDocumentTypeDto } from './company-document-type.dto';
import { PaginatorInfo } from './generic.dto';

type EmployeeDocument = {
  id: number;
  employeeId: number;
  typeId: number;
  url: string;
  createdAt: Date;
  modifiedAt: Date | null;
}

export interface EmployeeDocumentDto extends EmployeeDocument {}

export interface EmployeeDocumentPaginator extends PaginatorInfo<EmployeeDocumentDto> {}

export class CreateEmployeeDocumentDto {
  employeeId!: number;
  typeId!: number;
  url!: string;
}

export class UpdateEmployeeDocumentDto {
  employeeId?: number;
  typeId?: number;
  url?: string;
}

export interface EmployeeDocumentDto extends EmployeeDocument {
  employee?: EmployeeDto;
  documentType?: CompanyDocumentTypeDto;
}

export enum EmployeeDocumentOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export class QueryEmployeeDocumentDto {
  employeeId?: number;
  typeId?: number;
  page = 1;
  limit?: number;
  orderBy: EmployeeDocumentOrderBy = EmployeeDocumentOrderBy.CREATED_AT_DESC;
}