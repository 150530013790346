import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import { compareDateFields, IAddEditTableForm, validateFuture } from "../../../../../../helpers/appHelpers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useCreatePromotion, useUpdatePromotion } from "../../../../../../services/promotion.service";
import { IoMdClose } from "react-icons/io";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	title: string;
	code: string;
	description: string;
    discountPercent: number;
    activeDays: number;
    startDate: string;
    endDate: string;
	active: boolean;
	allowUpdateDuringActivePeriod?: boolean;
}

const PromotionModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [tags, setTags] = useState<string[]>(
		isEditForm ? formDataValues?.tags : []
	);
	const [tagInputValue, setTagInputValue] = useState("");

	const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			
			const tagExists = tags.find(tag => {
				return tag === tagInputValue.trim()
			})

			if (!tagExists) {
				setTags(prev => [...prev, tagInputValue.trim()]);
				setTagInputValue("");
			};
		}
	};
	const deleteTag = (entry: string) => {
		const filteredTags = tags.filter(tag => {
			return tag !== entry
		})

		setTags(filteredTags);
	};

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			title: isEditForm ? formDataValues?.title : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			discountPercent: isEditForm ? formDataValues?.discountPercent : "",
			activeDays: isEditForm ? formDataValues?.activeDays : "",
			startDate: isEditForm ? formDataValues?.startDate.slice(0, 10) : "",
			endDate: isEditForm ? formDataValues?.endDate.slice(0, 10) : "",
			allowUpdateDuringActivePeriod: isEditForm 
				? formDataValues?.allowUpdateDuringActivePeriod 
				: "",
			active: isEditForm ? formDataValues?.active : "",
		}
	});

    const { mutate: createPromotion } = useCreatePromotion();
    const { mutate: updatePromotion } = useUpdatePromotion();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updatePromotion(
				{ payload: { ...data, tags }, id: formDataValues?.id }, 
                {
                    onSuccess: (updatedData: any) => {
                        tableAPIRef?.current.updateRows([updatedData.data]);
                        setOpen(false);
                        reset();
                    },
                    onSettled: () => setUploading(false),
                }
            );
		} else {
			createPromotion(
				{ payload: { ...data, tags } }, 
				{
                    onSuccess: () => {
						addNewTableData()
                        setOpen(false);
                        reset();
                    },
                    onSettled: () => setUploading(false),
                }
            );
		}
	};
    
	const handleClose = () => {
		reset();
		setOpen(false);
	};

    return (  
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Title" for="title" />
						<input
							autoFocus
							placeholder={"Enter title"}
							type="text"
							{...register("title", {
								required: "Enter title here",
							})}
							id="title"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.title
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.title && (
							<p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Date" for="startDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("startDate", {
								required: "Enter start date here",
								validate: validateFuture,
							})}
							id="startDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.startDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.startDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Date" for="endDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("endDate", {
								required: "Enter end date here",
								validate: (value) => compareDateFields(
									watch("startDate"),
									value,
									"End date must be greater than start date"
								)
							})}
							id="endDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.endDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.endDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Active Days" for="activeDays" />
						<div className="flex items-center">
							<input
								placeholder={"0"}
								type="number"
								{...register("activeDays", {
									required: "Enter active days",
									valueAsNumber: true,
								})}
								id="activeDays"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.activeDays
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
						</div>
						{errors.activeDays && (
							<p className="text-red-500 text-sm mt-1">
								{errors.activeDays.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Discount Percent" for="discountPercent" />
						<div className="flex items-center h-14 mt-2">
							<input
								placeholder={"0 - 100"}
								type="number"
								{...register("discountPercent", {
									required: "Enter discount percent",
									valueAsNumber: true,
									min: {
										value: 0,
										message: "Enter a value greater than or equal to 0",
									},
									max: {
										value: 100,
										message: "Enter a value less than or equal to 100",
									},
								})}
								id="discountPercent"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.discountPercent
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
								%
							</div>
						</div>
						{errors.discountPercent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.discountPercent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Tags" for="tags" optional />
						{tags.length >= 1 && (
							<div className="flex flex-wrap gap-2 text-sm mt-2">
								{tags.map((tag, index) => (
									<div key={index} className="flex items-center gap-1 px-[10px] py-1 rounded-full bg-[#D4D4D4]">
										<span>{tag}</span>
										<IoMdClose 
											className="text-xl cursor-pointer" 
											onClick={() => deleteTag(tag)}
										/>
									</div>
								))}
							</div>
						)}
						<input
							id="tags"
							placeholder={"Add tags"}
							type="text"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
							value={tagInputValue}
							onChange={(e) => setTagInputValue(e.target.value)}
							onKeyDown={addTag}
						/>
                        <p className="text-sm mt-1">Type in a tag name and hit 'Enter'</p>
					</div>
					<div className="flex space-x-3 items-center col-span-2">
						<input 
							type="checkbox"
							{...register("active", {
								required: false,
							})}
							id="active"
						/>
						<label htmlFor="active">Active</label>
					</div>
					<div className="flex space-x-3 items-center col-span-2">
						<input 
							type="checkbox"
							{...register("allowUpdateDuringActivePeriod", {
								required: false,
							})}
							id="allowUpdateDuringActivePeriod"
						/>
						<label htmlFor="allowUpdateDuringActivePeriod">Allow Update During Active Period</label>
					</div>
				</div>
				
				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						title={`${
							uploading 
								? "Uploading..." 
								: isEditForm 
								? "Update Promotion" 
								: "Add Promotion"
						}`}
						className={"h-14 px-12"}
                        type="submit"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
    );
}
 
export default PromotionModalForm;