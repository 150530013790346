import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, formatDate } from "../../../helpers/appHelpers";

export const OvertimeColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "minHoursRequired",
		headerName: "Min Hours Required",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxHoursPermitted",
		headerName: "Max Hours Permitted",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	{
		field: "taxable",
		headerName: "Taxable",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];

export const BonusesColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	{
		field: "periodStartDate",
		headerName: "Period Start Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const periodStartDate = formatDate(params);
			return periodStartDate;
		},
	},
	{
		field: "periodEndDate",
		headerName: "Period End Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const periodEndDate = formatDate(params);
			return periodEndDate;
		},
	},
	{
		field: "prorate",
		headerName: "Prorate",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "minEmploymentPeriod",
		headerName: "Min Employment Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + ` ${enumToStringConverter(params.row.employmentPeriodUnit)}(s)`,
	},
	{
		field: "paymentFrequency",
		headerName: "Payment Frequency",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	
	...createdAtmodifiedAtActions
];
