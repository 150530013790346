import {
    CreateOAuthTokenAccessDto,
    CreateOAuthTokenAccessResponseDto,
    FileUploadResponseDto
} from "../../../models/document.dto";
import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import querystring from "querystring";

export class Document {
    token = (payload: CreateOAuthTokenAccessDto) => HttpClient
        //@ts-ignore
        .post<CreateOAuthTokenAccessResponseDto>(ENDPOINTS.DOCUMENT.GENERATE_TOKEN, payload, {
            headers: { "Content-Type": "application/x-www-form-urlencoded"}
        });
    uploadFile = (payload: FormData, accessToken: string) => fetch(ENDPOINTS.DOCUMENT.FILE_UPLOAD.CREATE, {
        method: "POST",
        body: payload,
        headers: new Headers({
            //"Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + accessToken
        })
    }).then((resp:any) => resp.json() as FileUploadResponseDto)

    // HttpClient
    // .post<FileUploadResponseDto>(ENDPOINTS.DOCUMENT.FILE_UPLOAD.CREATE, payload, {
    //     headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + accessToken}
    // });
    deleteFile = (url: string) => HttpClient
        .delete<any>(ENDPOINTS.DOCUMENT.FILE_UPLOAD.DELETE
            .replace('{fileUrl}', String(url)));
}