import { useState } from "react";
import NewEmployeeUnion from "./NewEmployeeUnion";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useGetUnion } from "../../../../../../services/union.service";
import EditEmployeeUnion from "./EditEmployeeUnion";
import { AiOutlinePlus } from "react-icons/ai";
import { EmployeeUnionDto, EmployeeUnionOrderBy } from "../../../../../../models/employee-union.dto";
import { useGetEmployeeUnion } from "../../../../../../services/employee-union.service";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { SkewLoader } from "react-spinners";
import { APP_CONFIG } from "../../../../../../helpers/appHelpers";
import { UnionOrderBy } from "../../../../../../models/union.dto";

const UnionMembership = () => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [showNewUnionForm, setShowNewUnionForm] = useState(false);
	
	const { data: employeeUnions, isLoading } = useGetEmployeeUnion({ 
		employeeId: formDataValues?.id,
		orderBy: EmployeeUnionOrderBy.CREATED_AT_DESC,
	});

	const { data: unions } = useGetUnion({ 
		active: true,
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: UnionOrderBy.NAME_ASC,
	});
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Unions</h2>
				{!showNewUnionForm && (
					<button
						onClick={() => setShowNewUnionForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Union
					</button>
				)}
			</div>

			{showNewUnionForm && (
				<NewEmployeeUnion 
					employeeId={formDataValues?.id}
					companyId={currentCompany?.id as number}
					unions={unions?.data || []}
					setShowNewUnionForm={setShowNewUnionForm} 
				/>
			)}

			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{(employeeUnions as EmployeeUnionDto[]).map((employeeUnion) => (
				<EditEmployeeUnion 
					key={employeeUnion.id}
					employeeUnion={employeeUnion}
					unions={unions?.data || []}
				/>
			))}

			{(!isLoading && employeeUnions.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no unions added yet. Click 'Add New Union' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default UnionMembership;