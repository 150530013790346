import { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";

interface IFormDescriptionInput {
    attributes: React.InputHTMLAttributes<HTMLInputElement>; 
    register: UseFormRegister<any>;
    required?: boolean | string;
    disabled?: boolean;
    characterLimit?: number;
}
const FormDescriptionInput = ({
    attributes, 
    register, 
    required, 
    disabled, 
    characterLimit
}: IFormDescriptionInput) => {
    const [charLimit] = useState(characterLimit || 200);
    const [remainingChars, setRemainingChars] = useState(charLimit);

    const charactersLeft = () => {
        const inputFieldWrapper = document.querySelector(".description-field-wrapper") as HTMLDivElement;
        const inputField = inputFieldWrapper.querySelector("input") as HTMLInputElement;

        const remaining = charLimit - inputField.value.length;
        setRemainingChars(remaining);
    };

    useEffect(() => charactersLeft(), [charLimit]);

    return (  
        <div className="w-full description-field-wrapper">
            <input
                id="description"
                type="text"
                placeholder={"Enter brief description"}
                maxLength={charLimit}
                {...register("description", {
                    required: typeof required === "boolean" ? required : "Enter description here",
                })}
                onInput={charactersLeft}
                disabled={disabled}
                { ...attributes }
            />
            <div className="text-xs mt-1">{remainingChars} characters left</div>
        </div>
    );
}
 
export default FormDescriptionInput;