import { Link } from "react-router-dom";
import { GlobalSearchDto } from "./data";
import useRecentSearchStore from "../../../../state-management/useRecentSearch";

interface SearchItemsProp {
    searchItemsArray: GlobalSearchDto[];
    closeGlobalSearch: () => void;
}
const SearchItems = ({searchItemsArray, closeGlobalSearch}: SearchItemsProp) => {
    const { addToRecentSearch } = useRecentSearchStore();

    return (  
        <div className="max-h-[350px] w-full px-6 overflow-y-auto text-white ">
            {searchItemsArray.map((appItem, index) => (
                <div key={index}>
                    {appItem.parentPageName ?
                        <div className="w-full ">
                            {appItem.subPages?.length !== undefined && appItem.subPages?.length ?
                                <div className="text-[#585B5B] font-semibold mt-5 mb-3 ">{appItem.parentPageName}</div> : <></>
                            }

                            <div className="w-full pl-5 border-l-2 flex flex-col gap-2 ">
                                {appItem.subPages?.map((subPage, index) => (
                                    <Link 
                                        key={index} 
                                        to={subPage.link} 
                                        onClick={() => {
                                            addToRecentSearch({...subPage, parentPage: appItem.parentPageName});
                                            closeGlobalSearch();
                                        }}
                                        className="w-full px-4 pt-3 pb-5 bg-slate-500 rounded-[15px] flex items-center 
                                        justify-between hover:scale-[1.01] transition-all duration-200 ease-linear "
                                    >
                                        <div className="flex items-center gap-3 ">
                                            <div className="h-6 px-[6px] rounded-[6px] bg-slate-400 font-bold flex items-center justify-between ">#</div>
                                            <div>
                                                <div className="w-fit px-3 py-2 rounded-full bg-slate-400 text-sm font-semibold mb-2 ">{subPage.name}</div>
                                                <div className="text-xs">{subPage.description}</div>
                                            </div>
                                        </div>

                                        <div></div>
                                    </Link>
                                ))}
                            </div>
                        </div> :

                        <Link 
                            to={appItem.link ? appItem.link : ""}
                            onClick={() => {
                                addToRecentSearch(appItem);
                                closeGlobalSearch();
                            }}
                            className="w-full px-4 pt-3 pb-5 bg-slate-500 rounded-[15px] flex items-center 
                            justify-between hover:scale-[1.01] transition-all duration-200 ease-linear mt-5 "
                        >
                            <div className="flex items-center gap-3 ">
                                <div className="h-6 px-[6px] rounded-[6px] bg-slate-400 font-bold flex items-center justify-between ">#</div>
                                <div>
                                    <div className="w-fit px-3 py-2 rounded-full bg-slate-400 text-sm font-semibold mb-2 ">{appItem.name}</div>
                                    <div className="text-xs">{appItem.description}</div>
                                </div>
                            </div>

                            <div></div>
                        </Link> 
                    }

                    {searchItemsArray.length === 1 && appItem.subPages?.length !== undefined && appItem.subPages?.length < 1 ?
                        <p className="text-gray-400 text-center pt-10 pb-4 ">No result found</p> : <></>
                    }
                </div>
            ))}
        </div>
    );
}
 
export default SearchItems;