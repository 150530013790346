import { useState } from "react";
import Header from "../header";
import { HiOutlineSupport } from "react-icons/hi";
import { Avatar } from "@mui/material";
import { PATHS } from "../../routes/routes.paths";
import { GoGraph } from "react-icons/go";
import { BsFileEarmarkText } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { HiOutlineScale } from "react-icons/hi2";
import { SlOrganization } from "react-icons/sl";
import { IoCalendarClearOutline } from "react-icons/io5";
import { GrAnnounce } from "react-icons/gr";
import useUserStore from "../../state-management/useUserStore";
import { TbExchange } from "react-icons/tb";
import { LuBookMarked } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import SidebarItem from "./resources/SidebarItem";
import { CgFileDocument } from "react-icons/cg";

interface IHumanResourceLayoutProps {
	children: React.ReactNode;
}

const bgColor = "linear-gradient(0deg, rgba(204,247,247,1) 0%, rgba(255,255,255,0.665703781512605) 70%)";

const HumanResourceLayout = ({ children }: IHumanResourceLayoutProps) => {
	const { currentUser } = useUserStore();
	const [showSidebar, setShowSidebar] = useState(
		sessionStorage.getItem("showSidebar") ? false : true
	);
	
	const toggleSidebar = () => {
		setShowSidebar(prev => {
			if (prev) {
				sessionStorage.setItem("showSidebar", "false");
			} else {
				sessionStorage.removeItem("showSidebar");
			}
			return !prev
		})
	};

	return (
		<div className={"flex h-[100svh] w-full"}>
			{/* sidebar */}
			<section className={`h-full ${showSidebar ? "w-[300px] px-6" : "w-[80px] pr-4 pl-5"} sticky top-0 border-r py-6 
				overflow-y-auto sidebar-two-scroll flex flex-col justify-between`}
			>
				<div>
					<div className={`flex items-center mb-10 ${!showSidebar && "justify-center"}`}>
						{showSidebar ? (
							<img
								src={"/assets/logos/akatua-logo.svg"}
								alt={"Logo"}
								width={150}
							/>
						) : (
							<img
								src={"/assets/icons/akatua-icon.png"}
								alt={"Logo"}
								width={40}
							/>
						)}
					</div>
					<div
						className={`w-full rounded-lg flex py-2 items-center text-white ${
							showSidebar && "bg-primary-blue justify-start px-3 space-x-3"
						}`}
					>
						<Avatar
							alt={currentUser?.user?.firstName}
							src={currentUser?.user?.imageUrl}
							sx={{ width: 40, height: 40 }}
						/>
						{showSidebar && (
							<div className="min-w-0">
								<h3 className="text-[11px] lg:text-xs font-bold">
									{currentUser?.user?.firstName +
										" " +
										currentUser?.user?.lastName}
								</h3>
								<p className="text-[8px] lg:text-[10px] mt-[2px] overflow-hidden truncate font-normal">
									{currentUser?.user?.emailAddress}
								</p>
							</div>
						)}
					</div>
					<div className="flex flex-col space-y-4 border-b pb-5 mt-3">
						<SidebarItem 
							title="Employees Management"
							path={PATHS.HR_EMPLOYEE_MANAGEMENT}
							Icon={BsPeople}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Payroll Management"
							path={PATHS.HR_PAYROLL_MANAGEMENT}
							Icon={BsFileEarmarkText}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Pay Advances"
							path={PATHS.HR_PAY_ADVANCES}
							Icon={MdOutlinePayments}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Organisational Tree"
							path={PATHS.HR_ORGANISATIONAL_TREE}
							Icon={SlOrganization}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Work Time"
							path={PATHS.HR_WORK_TIME}
							Icon={LuBookMarked}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Disciplinary & Grievance"
							path={PATHS.HR_DISCIPLINARY_GRIEVANCE}
							Icon={HiOutlineScale}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Leave Management"
							path={PATHS.HR_LEAVE_MANAGEMENT}
							Icon={IoCalendarClearOutline}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Reimbursements"
							path={PATHS.HR_REIMBURSEMENTS}
							Icon={TbExchange}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Bonus Management"
							path={PATHS.HR_BONUS_MANAGEMENT}
							Icon={TbReportMoney}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Announcements"
							path={PATHS.HR_ANNOUNCEMENTS}
							Icon={GrAnnounce}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Document Types"
							path={PATHS.HR_DOCUMENT_TYPES}
							Icon={CgFileDocument}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Reports"
							path={PATHS.HR_REPORTS}
							Icon={GoGraph}
							showSidebar={showSidebar}
						/>
					</div>
				</div>
				<div className="flex flex-col space-y-4 py-5">
					<SidebarItem 
						title="Support"
						path={PATHS.HR_SUPPORT}
						Icon={HiOutlineSupport}
						showSidebar={showSidebar}
					/>
				</div>
			</section>
			{/* Header + Children */}
			<section 
				style={{
					width: showSidebar ? "calc(100% - 300px)": "calc(100% - 80px)",
				}}
			>
				<div className={`sticky top-0 z-50 w-full`}>
					<Header onMenuClick={toggleSidebar} />
				</div>
				<div
					style={{ 
						background: bgColor,
						height: "calc(100% - 70px)",
					}}
					className="px-10 py-8 overflow-y-auto w-full"
				>
					<div className="w-full h-full max-w-[2000px] mx-auto">
						{children}
					</div>
				</div>
			</section>
		</div>
	);
};

export default HumanResourceLayout;
