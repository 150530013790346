import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    GrievanceType, GrievanceTypePaginator,
    CreateGrievanceTypeDto, QueryGrievanceTypeDto,
    UpdateGrievanceTypeDto, SearchGrievanceTypeDto 
} from '../models/grievance-type.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetGrievanceType(options?: QueryGrievanceTypeDto) {
    const url = ENDPOINTS.HR.GRIEVANCE_TYPE.GET;
    const request = (options: QueryGrievanceTypeDto) => API.HrAPI.getGrievanceType(options);

    const { data, isLoading, error, refetch } = useQuery<GrievanceTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetGrievanceTypeBySearch() {

    return useMutation(
        (options?: SearchGrievanceTypeDto) => API.HrAPI.getGrievanceTypeBySearch(options),
        {
            onSuccess: (response) => {
                
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetGrievanceTypeById(id: number){
    const url = ENDPOINTS.HR.GRIEVANCE_TYPE.DETAIL;
    const request = API.HrAPI.getGrievanceTypeById(id);

    const { data, isLoading, error } = useQuery<{ data: GrievanceType }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateGrievanceType(onSuccessCallback?: (responsData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateGrievanceTypeDto }) => API.HrAPI.createGrievanceType(payload),
        {
            onSuccess: (response) => {
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.GRIEVANCE_TYPE.GET);
            },
        },
    );
}

export function useUpdateGrievanceType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateGrievanceTypeDto }) => API.HrAPI.updateGrievanceType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.GRIEVANCE_TYPE.GET);
            },
        },
    );
}

export function useDeleteGrievanceType() {

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteGrievanceType(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}