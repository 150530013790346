import React, { useState } from "react";
import { PATHS } from "../../../routes/routes.paths";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { leaveManagement } from "../../../components/custom_tab/tabColumns";
import LeaveRequestsView from "./views/LeaveRequestsView";
import LeavePackagesView from "./views/LeavePackagesView";
import { useSearchParams } from "react-router-dom";

const LeaveManagement = () => {
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "leaveRequestsView";
	const [view, setView] = React.useState(currentView);

	return (
		<HumanResourceLayout>
			<Helmet>
				<title>Leave Management | HR Flow </title>
			</Helmet>
			<h1 className="font-bold text-xl text-charcoal mb-8">Leave Management</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={leaveManagement}
				isCount={true}
			>
				<div className="mt-6">
					{view === "leaveRequestsView" && <LeaveRequestsView />}
					{view === "leavePackagesView" && <LeavePackagesView />}
				</div>
			</CustomTab>
		</HumanResourceLayout>
	);
};

export default LeaveManagement;
