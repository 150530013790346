import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
import { GlobalSearchDto } from "../components/header/resources/global_search/data";

export interface RecentSearchDto {
    id: number;
    name?: string;
    description?: string;
    link?: string;
    parentPage?: string;
}

type RecentSearchStore = {
    recentSearch: RecentSearchDto[],
    addToRecentSearch: (data: RecentSearchDto) => void,
    deleteFromRecentSearch: (id: number) => void,
    filterRecentSearch: (searchItems: GlobalSearchDto[]) => void,
}

const useRecentSearchStore = create(
    persist<RecentSearchStore>(
        (set, get) => ({
            recentSearch: [],
            addToRecentSearch: (data: RecentSearchDto) => {
                const currentRecentSearchItems = get().recentSearch;
                let newRecentSearchItems: RecentSearchDto[] = [];
                
                if (currentRecentSearchItems.length === 3) {
                    newRecentSearchItems = [
                        currentRecentSearchItems[1],
                        currentRecentSearchItems[2],
                        data
                    ]
                } else {
                    newRecentSearchItems = [ ...currentRecentSearchItems, data ]
                }

                set({ recentSearch: newRecentSearchItems });
            },
            deleteFromRecentSearch: (id: number) => {
                const filteredRecentSearchItems = get().recentSearch.filter(
                    item => item.id !== id
                );

                set({ recentSearch: filteredRecentSearchItems });
            },
            filterRecentSearch: (searchItems: GlobalSearchDto[]) => {
                const recentSearchItems = get().recentSearch;
        
                recentSearchItems.forEach((item) => {
                    const validItem = Boolean(searchItems.find((searchItem) => {
                        if (searchItem.parentPageName) {
                            return searchItem.subPages?.find(subPage => subPage.name === item.name)
                        } else {
                            return searchItem.name === item.name
                        }
                    }));
        
                    if (!validItem) {
                        get().deleteFromRecentSearch(item.id);
                    }
                })
            }
        }),
        {
            name: 'recent-search',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useRecentSearchStore;