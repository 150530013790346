import { useState } from "react";
import CustomTab from "../../../../components/custom_tab/CustomTab";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { NewEmployeeTabColumns } from "../../../../components/custom_tab/tabColumns";
import PersonalDetails from "./forms/PersonalDetails";
import EmploymentDetails from "./forms/EmploymentDetails";
import PaymentInfo from "./forms/PaymentInfo";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";

interface IAddNewEmployee extends Omit<IAddEditTableForm, 
	"title" | "isEditForm" | "tableAPIRef"
> {}

const AddNewEmployee = ({ open, setOpen }: IAddNewEmployee) => {
	const [view, setView] = useState("personalDetails");
	const [formData, setFormData] = useState<any>();
	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			open={open}
			title="Add New Employee"
			handleClose={handleClose}
		>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={NewEmployeeTabColumns}
			>
				<div className="mt-8">
					{view === "personalDetails" && (
						<PersonalDetails
							formData={formData}
							setFormData={setFormData}
							setView={setView}
						/>
					)}
					{view === "employmentDetails" && (
						<EmploymentDetails
							formData={formData}
							setFormData={setFormData}
							setView={setView}
						/>
					)}
					{view === "paymentInfo" && (
						<PaymentInfo
							formData={formData}
							setFormData={setFormData}
							setView={setView}
							closeForm={handleClose}
						/>
					)}
				</div>
			</CustomTab>
		</ModalComponent>
	);
};

export default AddNewEmployee;
