import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import SkewLoader from "react-spinners/SkewLoader";
import EditWorkDaysInAPeriod from "./EditWorkDaysInAPeriod";
import NewWorkDaysInAPeriod from "./NewWorkDaysInAPerod";
import { WorkDaysInAPeriodDto } from "../../../../../models/work-days-in-a-period.dto";
import { useGetWorkDaysInAPeriod } from "../../../../../services/work-days-in-a-period.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const WorkDaysInAPeriod = ({ type }: { type: string }) => {
	const { currentCompany } = useCompanyStore();
	const [showNewWorkDaysInAPeriodForm, setShowNewWorkDaysInAPeriodForm] = useState(false);
	
	const {
		accordionsData: workDaysInAPeriod,
		isLoading,
		pageCount,
    } = useManageAccordions<WorkDaysInAPeriodDto>({
		fetchHook: useGetWorkDaysInAPeriod,
		options: { companyId: currentCompany?.id }
	});
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Work Days in a Period</h2>
				{!showNewWorkDaysInAPeriodForm && (
					<button
						onClick={() => setShowNewWorkDaysInAPeriodForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Entry
					</button>
				)}
			</div>
			
			{showNewWorkDaysInAPeriodForm && (
				<NewWorkDaysInAPeriod 
					setShowNewWorkDaysInAPeriodForm={setShowNewWorkDaysInAPeriodForm} 
				/>
			)}
			
			{(isLoading && !workDaysInAPeriod.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{workDaysInAPeriod.map((workDayPeriod) => (
				<EditWorkDaysInAPeriod 
					key={workDayPeriod.id}
					workDayPeriod={workDayPeriod} 
				/>
			))}
			
			{(!isLoading && !workDaysInAPeriod.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no work days in a period added yet. Click 'Add New Entry' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default WorkDaysInAPeriod;
