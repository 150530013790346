import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useCancelInvoice } from "../../../../services/invoice.service";

type CancelInvoiceModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const CancelInvoiceModal =  ({ open, setOpen, tableAPIRef }: CancelInvoiceModalProps) => {
	const { formDataValues } = useEditFormStore();
	const [narration, setNarration] = useState("");
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(false);

    const { mutate: cancelInvoice } = useCancelInvoice();

	const complete = () => {
        if (!narration.trim()) {
            setError(true);
            return
        }

		setUploading(true);

        const payload = {
            reference: formDataValues?.referenceCode,
            narration,
        }

        cancelInvoice(
            { payload },
            {
                onSuccess: (updatedData) => {
                    tableAPIRef?.current.updateRows([updatedData.data]);
                    setOpen(false)
                },
                onSettled: () => setUploading(false)
            }
        )
	};

	return (
		<ModalComponent
			width={660}
			open={open}
			title={formDataValues?.referenceCode}
			handleClose={() => setOpen(false)}
		>
			<div className="w-full mt-">
				<Label title="Narration" for="narration" />
				<input
					placeholder={"Enter narration"}
					type="text"
					id="narration"
					className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
					value={narration}
					onChange={(e) => setNarration(e.target.value)}
				/>
                {error && (
                    <p className="text-red-500 text-sm mt-1">Enter narration here</p>
                )}
			</div>
			<div className="w-full flex justify-end mt-8">
				<OrangeButton 
					title={uploading ? "Canceling..." : "Cancel Invoice"}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					disabled={uploading} 
					onClick={complete}
				/>
			</div>
		</ModalComponent>
	);
};
 
export default CancelInvoiceModal;