import { useState } from "react";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { AiOutlinePlus } from "react-icons/ai";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { SkewLoader } from "react-spinners";
import { APP_CONFIG } from "../../../../../../helpers/appHelpers";
import { CompanyDocumentTypeOrderBy } from "../../../../../../models/company-document-type.dto";
import { useGetCompanyDocumentType } from "../../../../../../services/company-document-type.service";
import { EmployeeDocumentOrderBy } from "../../../../../../models/employee-document.dto";
import { useGetEmployeeDocument } from "../../../../../../services/employee-document.service";
import EditEmployeeDocument from "./EditEmployeeDocument";
import NewEmployeeDocument from "./NewEmployeeDocument";

const EmployeeDocuments = () => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [showNewDocumentForm, setShowNewDocumentForm] = useState(false);
	
	const { data: employeeDocuments, isLoading } = useGetEmployeeDocument({ 
		employeeId: formDataValues?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: EmployeeDocumentOrderBy.CREATED_AT_DESC,
	});

	const { data: companyDocumentTypes } = useGetCompanyDocumentType({ 
		companyId: currentCompany?.id as number,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: CompanyDocumentTypeOrderBy.NAME_ASC,
	});
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Documents</h2>
				{!showNewDocumentForm && (
					<button
						onClick={() => setShowNewDocumentForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Document
					</button>
				)}
			</div>

			{showNewDocumentForm && (
				<NewEmployeeDocument 
					employeeId={formDataValues?.id}
					companyDocumentTypes={companyDocumentTypes?.data || []}
					setShowNewDocumentForm={setShowNewDocumentForm} 
				/>
			)}

			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{employeeDocuments?.data?.map((document) => (
				<EditEmployeeDocument 
					key={document.id}
					employeeDocument={document}
					companyDocumentTypes={companyDocumentTypes?.data || []}
				/>
			))}

			{(!isLoading && !employeeDocuments?.data?.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no documentss added yet. Click 'Add New Document' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default EmployeeDocuments;