import { GridColDef } from "@mui/x-data-grid";
import { moneyFormat, createdAtmodifiedAtActions, enumToStringConverter } from "../../../helpers/appHelpers";

export const AllowancesColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	// {
	// 	field: "formulaType",
	// 	headerName: "Formula Type",
	// 	width: 180,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => enumToStringConverter(params.value),
	// },
	// {
	// 	field: "fixedComponent",
	// 	headerName: "Fixed Component",
	// 	width: 180,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// },
	// {
	// 	field: "rateComponent",
	// 	headerName: "Rate Component",
	// 	width: 180,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// },
	{
		field: "minEmploymentPeriod",
		headerName: "Min Employment Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employmentPeriodUnit",
		headerName: "Employment Period Unit",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "paymentFrequency",
		headerName: "Payment Frequency",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "paymentInstance",
		headerName: "Payment Instance",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.paymentInstance;
			const currency = params.row.companyCurrency?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "minValue",
		headerName: "Min Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.minValue;
			const currency = params.row.companyCurrency?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "maxValue",
		headerName: "Max Value",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.maxValue;
			const currency = params.row.companyCurrency?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	// {
	// 	field: "prorate",
	// 	headerName: "Prorate",
	// 	width: 140,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	renderCell(params) {
	// 		return (
	// 			<div className="flex items-center">
	// 				<p>
	// 					{params.value === true ? "Yes" : "No"}
	// 				</p>
	// 			</div>
	// 		);
	// 	},
	// },
	
	...createdAtmodifiedAtActions
];

export const BenefitsColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	// {
	// 	field: "value",
	// 	headerName: "Value",
	// 	width: 140,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	renderCell(params) {
	// 		return (
	// 			<div className="flex items-center">
	// 				{moneyFormat(params.value) || "--"}
	// 			</div>
	// 		);
	// 	},
	// },
	// {
	// 	field: "frequency",
	// 	headerName: "Frequency",
	// 	width: 180,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => enumToStringConverter(params.value) || "--",
	// },
	// {
	// 	field: "taxCode",
	// 	headerName: "Tax Code",
	// 	width: 180,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => params.value || "--",
	// },
	{
		field: "benefitTypeInstance",
		headerName: "Benefit Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value.name,
	},
	
	...createdAtmodifiedAtActions
];

export const LoanBenefitsColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "centralBankInterestRate",
		headerName: "Central Bank Interest Rate",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "employerInterestRate",
		headerName: "Employer Interest Rate",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "loanTenor",
		headerName: "Loan Tenor",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + " Months",
	},
	
	...createdAtmodifiedAtActions
];