import { CurrencyDto } from "./currency.dto";
import { PaginatorInfo } from "./generic.dto";

type OrganizationAccount = {
    id: number;
    organizationId: string;
    currencyId: number;
    balance: number;
    createdAt: Date;
    modifiedAt: Date | null;
}
export enum OrganizationAccountOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
}

export class QueryOrganizationAccountDto {
    organizationId?: string;
    currencyId?: number;
    isDefault?: boolean;
  
    page?: number = 1;
    limit?: number;
    orderBy?: OrganizationAccountOrderBy = OrganizationAccountOrderBy.CREATED_AT_DESC;
  }
  
  export interface OrganizationAccountDto extends OrganizationAccount {
    currency?: CurrencyDto;
  }

  export interface OrganizationAccountPaginator extends PaginatorInfo<OrganizationAccountDto> {}