import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CompanyCurrencyConfigDto, CompanyCurrencyConfigPaginator,
    CreateCompanyCurrencyConfigDto, QueryCompanyCurrencyConfigDto,
    UpdateCompanyCurrencyConfigDto
} from "../models/company-currency.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetCompanyCurrencyConfig(options?: QueryCompanyCurrencyConfigDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.GET;
    const request = (options: QueryCompanyCurrencyConfigDto) => API.ProcessingCompanyAPI.getCompanyCurrencyConfig(options);

    const { data, isLoading, error, refetch } = useQuery<CompanyCurrencyConfigPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetCompanyCurrencyConfigById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.DETAIL
    const request = API.ProcessingCompanyAPI.getCompanyCurrencyConfigById(id);

    const {data, isLoading, error} = useQuery<{ data:CompanyCurrencyConfigDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateCompanyCurrencyConfig(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateCompanyCurrencyConfigDto}) => API.ProcessingCompanyAPI.createCompanyCurrencyConfig(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.GET);
            },
        }
    )
}

export function useUpdateCompanyCurrencyConfig() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateCompanyCurrencyConfigDto, id: number}) => API.ProcessingCompanyAPI.updateCompanyCurrencyConfig(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.GET);
            },
        }
    )
}

export function useDeleteCompanyCurrencyConfig() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteCompanyCurrencyConfig(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.GET);
            },
        }
    )
}

