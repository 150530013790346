import {PaginatorInfo} from "./generic.dto";
import {EmployeeRelativeDto} from "./employee-relative.dto";
import { UnionDto } from "./union.dto";

type EmployeeUnion = {
    id: number
    employeeId: number
    companyId: number
    unionId: number
    membershipNumber: string | null
    active: boolean
    createdAt: string
    modifiedAt: string | null
}
export class CreateEmployeeUnionDto {
    employeeId!: number
    companyId!: number
    unionId!: number
    membershipNumber!: string
    active!: boolean
}


export class UpdateEmployeeUnionDto {
    employeeId?: number
    companyId?: number
    unionId?: number
    membershipNumber?: string
    active?: boolean
}

export interface EmployeeUnionDto extends EmployeeUnion {
    union?: UnionDto;
}

export interface EmployeeUnionPaginator extends PaginatorInfo<EmployeeUnionDto> {}

export class QueryEmployeeUnionDto {
    employeeId?: number
    companyId?: number
    unionId?: number
    membershipNumber?: string
    active?: boolean
    page?: number = 1;
    limit?: number;
    orderBy?: EmployeeUnionOrderBy = EmployeeUnionOrderBy.CREATED_AT_ASC;
}

export enum EmployeeUnionOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc'
}