import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import { useUpdateEmployee } from "../../../../../services/employee.service";
import { EMPLOYEE_PAY_INFO_TYPE, EmployeeDto } from "../../../../../models/employee.dto";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useCreateEmployeeAccountInfo } from "../../../../../services/employee-account-info.service";
import { useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { PaymentInstitutionBranchDto } from "../../../../../models/payment-institution-branch.dto";
import { EmployeeFormContext } from "../../EmployeeFormContext";
import API from '../../../../../services/utils/api';
import { useAsyncEffect, useLockFn } from "ahooks";
import { toast } from 'react-toastify';

interface IFormInputs {
	splitPayment: boolean;
	primaryPayPercent: number;
	primaryPaymentChannel: EMPLOYEE_PAY_INFO_TYPE;
	primaryInstitutionId: number;
	primaryInstitutionBranchId?: number;
	primaryAccountNumber: string;
	secondaryPaymentChannel?: EMPLOYEE_PAY_INFO_TYPE;
	secondaryInstitutionId?: number;
	secondaryInstitutionBranchId?: number;
	secondaryAccountNumber?: string;
}

interface IPaymentInfo {
	formData: any;
	setFormData: React.Dispatch<React.SetStateAction<any>>;
	setView: (view: string) => void;
	closeForm: () => void;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
	defaultPrimaryInstitutionBranches: PaymentInstitutionBranchDto[];
	defaultSecondaryInstitutionBranches: PaymentInstitutionBranchDto[];
}

const PaymentInfo = ({
	formData,
	setView,
	setFormData,
	closeForm,
	tableAPIRef,
	defaultPrimaryInstitutionBranches,
	defaultSecondaryInstitutionBranches,
}: IPaymentInfo) => {
	const { paymentInstitutions } = useContext(EmployeeFormContext);
	const [uploading, setUploading] = useState(false);

	const [primaryInstitutionBranches, setPrimaryBankBranches] = 
		useState<PaymentInstitutionBranchDto[]>(defaultPrimaryInstitutionBranches);
	const [secondaryInstitutionBranches, setSecondaryBankBranches] = 
		useState<PaymentInstitutionBranchDto[]>(defaultSecondaryInstitutionBranches);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm<IFormInputs>({
		defaultValues: formData
	});

	useEffect(() => {
		if (formData?.secondaryInstitutionId || formData?.secondaryAccountNumber) return;

		clearErrors("secondaryInstitutionId");
		clearErrors("secondaryAccountNumber");
	}, [formData?.secondaryInstitutionId, formData?.secondaryAccountNumber])
	
	useEffect(() => {
		const { unsubscribe } = watch((values) => {
			setFormData({ ...formData, ...values });
		});
		return () => unsubscribe();
	}, [watch])

	const uploadCompleted = (updatedData: EmployeeDto) => {
		tableAPIRef?.current.updateRows([updatedData]);
		addNewTableData();
		setUploading(false);
		setView("personalDetails");
		closeForm();
	};

	const handlePaymentInstitutionChange = async (id: string, primary = true) => {
		if (!id) {
			if (primary) {
				setPrimaryBankBranches([]);
				setValue( "primaryPaymentChannel", "");
			} else  {
				setSecondaryBankBranches([]);
				setValue( "secondaryPaymentChannel", undefined);
			}

			return
		}

		const paymentInstitution = paymentInstitutions.find(
			paymentInstitution => paymentInstitution.id === Number(id)
		);

		if (!paymentInstitution) return;
		if (paymentInstitution.paymentChannelType !== "BANK") {
			if (primary) {
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "primaryInstitutionBranchId", undefined);
			} else  {
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
				setValue( "secondaryInstitutionBranchId", undefined);
			}
			
			return
		};

		try {
			const paymentInstitutionBranches = await API.BillingAndSubscriptionAPI
				.getPaymentInstitutionBranch({
					limit: APP_CONFIG.PAGE_LIMIT,
					active: true,
					orderBy: "name:asc" as any,
					paymentInstitutionId: paymentInstitution.id
				})

			if (primary) {
				setPrimaryBankBranches(paymentInstitutionBranches.data);
				setValue(
					"primaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			} else  {
				setSecondaryBankBranches(paymentInstitutionBranches.data);
				setValue(
					"secondaryPaymentChannel", 
					paymentInstitution.paymentChannelType
				);
			}
		} catch {}
	};

	// Setting the default values of institution branches
	useAsyncEffect(useLockFn(async () => {
		if (
			formData?.primaryInstitutionId && 
			formData?.primaryPaymentChannel === "BANK" && 
			!primaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.primaryInstitutionId);
		}

		if (
			formData?.secondaryInstitutionId && 
			formData?.secondaryPaymentChannel === "BANK" && 
			!secondaryInstitutionBranches.length
		) {
			await handlePaymentInstitutionChange(formData.secondaryInstitutionId, false);
		}
	}), [])

	useEffect(() => {
		if (formData?.primaryInstitutionBranchId && primaryInstitutionBranches.length) {
			setValue("primaryInstitutionBranchId", formData.primaryInstitutionBranchId);
		}

		if (formData?.secondaryInstitutionBranchId && secondaryInstitutionBranches.length) {
			setValue("secondaryInstitutionBranchId", formData.secondaryInstitutionBranchId);
		}
	}, [primaryInstitutionBranches, secondaryInstitutionBranches])

	const { mutate: updateEmployee } = useUpdateEmployee();
	const { mutate: createEmployeeAccountInfo } = useCreateEmployeeAccountInfo();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		const employeePayInfo = {
			basicPay: formData.basicPay,
			payPeriod: formData.payPeriod,
			splitPayment: data.splitPayment === "yes" ? true : false,
			primaryPayPercent: data.primaryPayPercent / 100,
			primaryPaymentChannel: data.primaryPaymentChannel,
			secondaryPaymentChannel: data.secondaryPaymentChannel
				? data.secondaryPaymentChannel
				: "BANK",
			currencyId: formData.currencyId,
		};

		const employeeAccountInfo: any[] = [];
		const primaryAccountConfig = {
			type: data.primaryPaymentChannel,
			institutionId: Number(data.primaryInstitutionId),
			institutionBranchId: data.primaryInstitutionBranchId || undefined,
			accountNumber: data.primaryAccountNumber,
			primary: true,
			secondary: false,
			active: true,
			currencyId: formData.currencyId,
		};
		const secondaryAccountConfig = {
			type: data.secondaryPaymentChannel,
			institutionId: Number(data.secondaryInstitutionId),
			institutionBranchId: data.secondaryInstitutionBranchId || undefined,
			accountNumber: data.secondaryAccountNumber,
			primary: false,
			secondary: true,
			active: true,
			currencyId: formData.currencyId,
		};

		if (formData?.employeeAccountInfo?.data[0]) {
			employeeAccountInfo.push({
				id: formData?.employeeAccountInfo?.data[0]?.id,
				...primaryAccountConfig,
			})
		}

		if (formData?.employeeAccountInfo.data[1]) {
			employeeAccountInfo.push({
				id: formData?.employeeAccountInfo?.data[1]?.id,
				...secondaryAccountConfig,
			});
		}

		const payload = {
			notchId: formData.notchId || undefined,
			employeeNumber: formData.employeeNumber,
			title: formData.title,
			firstName: formData.firstName,
			lastName: formData.lastName,
			otherNames: formData.otherNames,
			gender: formData.gender,
			dateOfBirth: formData.dateOfBirth,
			photoUrl: formData.photoUrl,
			ssn: formData.ssn,
			taxIdentificationNumber: formData.taxIdentificationNumber,
			majorGradeLevelId: formData.majorGradeLevelId || undefined,
			minorGradeLevelId: formData.minorGradeLevelId || undefined,
			nationality: formData.nationality,
			regionId: formData.regionId || undefined,
			tribeId: formData.tribeId || undefined,
			email: formData.email,
			privateEmail: formData.privateEmail,
			msisdn: formData.msisdn,
			address: formData.address,
			alternateMsisdn: formData.alternateMsisdn,
			digitalAddress: formData.digitalAddress,
			jobTitleId: formData.jobTitleId || undefined,
			departmentId: formData.departmentId || undefined,
			divisionId: formData.divisionId || undefined,
			stationId: formData.stationId || undefined,
			costAreaId: formData.costAreaId || undefined,
			status: formData.status,
			employmentDate: formData.employmentDate,
			terminationDate: formData.terminationDate || undefined,
			reemployed: formData.reemployed === "yes" ? true : false,
			resident: formData.resident === "yes" ? true : false,
			unionMember: formData.unionMember === "yes" ? true : false,
			employeePayInfo,
			employeeAccountInfo,
			hr: formData.hr === "yes" ? true : false,
		};

		updateEmployee(
			{ payload, id: formData?.id },
			{
				onSuccess: (updatedEmployee) => {
					const createSecondaryAccountInfo = () => createEmployeeAccountInfo(
						{ payload: { employeeId: formData?.id, ...secondaryAccountConfig } }, 
						{
							onSuccess: () => {
								toast.success("Secondary Payment Channel created successfully");
								uploadCompleted(updatedEmployee.data);
							},
							onError: () => setUploading(false),
						}
					);

					if (!employeeAccountInfo[0] && data.primaryPaymentChannel) {
						createEmployeeAccountInfo(
							{ payload: { employeeId: formData?.id, ...primaryAccountConfig } }, 
							{
								onSuccess: () => {
									toast.success("Primary Payment Channel created successfully");

									if (!employeeAccountInfo[1] && data.secondaryPaymentChannel) {
										createSecondaryAccountInfo();
									}
								},
								onError: () => setUploading(false),
							}
						)
						return
					}
					
					if (!employeeAccountInfo[1] && data.secondaryPaymentChannel) {
						createSecondaryAccountInfo();
						return
					}

					uploadCompleted(updatedEmployee.data);
				},
				onError: () => setUploading(false)
			}
		);
	};
	
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="mt-6">
				<div className="flex space-x-12 text-base">
					<h3>Split Payment</h3>
					<div className="flex space-x-3 items-center">
						<input
							type="radio"
							value={"yes"}
							{...register("splitPayment", {
								required: "Select an option",
							})}
							id="yes"
						/>
						<label htmlFor="yes">Yes</label>
					</div>
					<div className="flex space-x-3 items-center">
						<input
							type="radio"
							value={"no"}
							{...register("splitPayment", {
								required: "Select an option",
							})}
							id="no"
						/>
						<label htmlFor="no">No</label>
					</div>
				</div>
				{errors.splitPayment && (
					<p className="text-red-500 text-sm mt-1">
						{errors.splitPayment.message}
					</p>
				)}
			</div>
			
			<div className="mt-6 flex flex-col">
				<Label title="Primary Payment Percentage" for="primaryPayPercent" />
				<div className="flex items-center mt-2">
					<input
						placeholder={"0 - 100"}
						type="number"
						{...register("primaryPayPercent", {
							required: "Enter primary payment percentage here" ,
							valueAsNumber: true,
							min: {
								value: 1,
								message: "Enter a value between 1 and 100",
							},
							max: {
								value: 100,
								message: "Enter a value between 1 and 100",
							},
						})}
						id="primaryPayPercent"
						className={`w-[38%] bg-secondary-gray rounded-tl-lg rounded-bl-lg h-14 px-5 text-sm  ${
							errors.primaryPayPercent
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					<div className="h-14 w-16 bg-black rounded-tr-lg rounded-br-lg text-white flex items-center justify-center">
						%
					</div>
				</div>
				{errors.primaryPayPercent && (
					<p className="text-red-500 text-sm mt-1">
						{errors.primaryPayPercent.message}
					</p>
				)}
			</div>

			<div className="mt-10">
				<h3 className="font-bold">Primary Payment Channel</h3>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="primaryInstitutionId" />
						<select
							defaultValue={formData?.primaryInstitutionId}
							id="primaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.primaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("primaryInstitutionId", {
								required: "Select primary bank here",
								valueAsNumber: true,
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value)}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.primaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="primaryAccountNumber" />
						<input
							defaultValue={formData?.primaryAccountNumber}
							placeholder="Enter number"
							type="number"
							{...register("primaryAccountNumber", {
								required: "Enter primary bank account number here",
							})}
							id="primaryAccountNumber"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.primaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.primaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.primaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.primaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="primaryInstitutionBranchId" />
							<select
								defaultValue={formData?.primaryInstitutionBranchId || undefined}
								id="primaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.primaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("primaryInstitutionBranchId", {
									required: "Enter primary bank branch here",
								})}
							>
								<option value="">Select Branch</option>
								{primaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.primaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.primaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</div>

			<div className="mt-10">
				<h3 className="font-bold">Secondary Payment Channel <i>(optional)</i></h3>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="Bank" for="secondaryInstitutionId" />
						<select
							defaultValue={formData?.secondaryInstitutionId}
							id="secondaryInstitutionId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.secondaryInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("secondaryInstitutionId", {
								validate: (value, formData) => {
									if (!value && formData.secondaryAccountNumber) {
										return "Select bank or clear account number field"
									}

									return true
								},
							})}
							onInput={(e: any) => handlePaymentInstitutionChange(e.target.value, false)}
						>
							<option value="">Select Bank</option>
							{paymentInstitutions?.map((paymentInstitution) => (
								<option 
									key={paymentInstitution?.id} 
									value={paymentInstitution?.id}
								>
									{paymentInstitution?.name}
								</option>
							))}
						</select>
						{errors.secondaryInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryInstitutionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Number" for="secondaryAccountNumber" />
						<input
							defaultValue={formData?.secondaryAccountNumber}
							placeholder="Enter number"
							type="number"
							id="secondaryAccountNumber"
							{...register("secondaryAccountNumber", {
								validate: (value, formData) => {
									if (!value && formData.secondaryInstitutionId) {
										return "Enter account number or clear bank field"
									}

									return true
								}
							})}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.secondaryAccountNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.secondaryAccountNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors.secondaryAccountNumber.message}
							</p>
						)}
					</div>
					{formData?.secondaryPaymentChannel === "BANK" && (
						<div className="w-full">
							<Label title="Bank Branch" for="secondaryInstitutionBranchId" />
							<select
								defaultValue={formData?.secondaryInstitutionBranchId}
								id="secondaryInstitutionBranchId"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.secondaryInstitutionBranchId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("secondaryInstitutionBranchId", {
									required: "Enter secondary bank branch or clear bank field",
									valueAsNumber: true,
								})}
							>
								<option value="">Select Branch</option>
								{secondaryInstitutionBranches.map((paymentInstitutionBranch) => (
									<option 
										key={paymentInstitutionBranch?.id} 
										value={paymentInstitutionBranch?.id}
									>
										{paymentInstitutionBranch?.name}
									</option>
								))}
							</select>
							{errors.secondaryInstitutionBranchId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.secondaryInstitutionBranchId.message}
								</p>
							)}
						</div>
					)}
				</div>
			</div>

			{/* <div className="mt-10">
				<h3 className="font-bold">Payroll Approvals</h3>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="First Approver" for="approvers.0.approverId" />
						<select
							id="approvers.0.approverId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.approvers && errors.approvers[0]?.approverId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("approvers.0.approverId", {
								required: "Select first approver here",
							})}
							onInput={() => setValue("approvers.0.level", 1)}
						>
							<option value="">
								Select Employee
							</option>
							{employees?.data?.map((employee) => (
								<option 
									key={employee.id} 
									value={employee.id} 
								>
									{employee.firstName + " " + employee.lastName}
								</option>
							))}
						</select>
						{errors.approvers && errors.approvers[0]?.approverId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.approvers[0].approverId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Second Approver" for="approvers.1.approverId" />
						<select
							id="approvers.1.approverId"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.approvers && errors.approvers[1]?.approverId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("approvers.1.approverId", {
								required: "Select second approver here",
							})}
							onInput={() => setValue("approvers.1.level", 2)}
						>
							<option value="">
								Select Employee
							</option>
							{employees?.data?.map((employee) => (
								<option 
									key={employee.id} 
									value={employee.id} 
								>
									{employee.firstName + " " + employee.lastName}
								</option>
							))}
						</select>
						{errors.approvers && errors.approvers[1]?.approverId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.approvers[1].approverId.message}
							</p>
						)}
					</div>
				</div>
			</div> */}

			<div className="w-full flex items-center justify-end gap-4 mt-10">
				<button
					type="button"
					onClick={() => setView("employmentDetails")}
					className="h-14 px-8 border border-gray-300 rounded-lg text-sm text-gray-600"
				>
					Go Back
				</button>
				<button
					type="submit"
					disabled={uploading}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
				>
					{uploading ? "Uploading..." : "Save Record"}
				</button>
			</div>
		</form>
	);
};

export default PaymentInfo;
