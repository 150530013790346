import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { enumToStringConverter } from "../../../../../helpers/appHelpers";
import { OvertimePaymentTierDto, OVERTIME_PAYMENT_TIER_TYPE } from "../../../../../models/overtime-payment-tier.dto";
import { OvertimePaymentTier } from "../../../../../models/overtime.dto";
import { useUpdateOvertimePaymentTier, useDeleteOvertimePaymentTier } from "../../../../../services/overtime-payment-tier.service";
import Label from "../../../../../components/form_fields/Label";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";
import useEditFormStore from "../../../../../state-management/useEditFormStore";

type IFormInputs = Omit<OvertimePaymentTier, "type"> & { paymentType: string };

interface IEditPaymentTier {
	paymentTier: OvertimePaymentTierDto;
	tier: number;
	companyCurrencies: CompanyCurrencyConfigDto[];
}

const EditPaymentTier = ({ paymentTier, tier, companyCurrencies }: IEditPaymentTier) => {
	const { formDataValues } = useEditFormStore();
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updatePaymentTier } = useUpdateOvertimePaymentTier();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			paymentType: paymentTier.type,
			fixedComponent: paymentTier.fixedComponent,
			factorComponent: paymentTier.factorComponent,
			minHours: paymentTier.minHours,
			maxHours: paymentTier.maxHours,
			currencyId: paymentTier.currencyId,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: any) => {
		setUploading(true);

		data.type = data.paymentType;
		delete data.paymentType;

		updatePaymentTier(
			{ payload: data, id: paymentTier.id }, 
			{
				onSuccess: () => setFormDisabled(true),
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletPaymentTier } = useDeleteOvertimePaymentTier();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletPaymentTier(
			{ id: paymentTier?.id },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={paymentTier.id + "bh-content"}
				id={paymentTier.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					Tier {tier}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
							<div className="w-full">
								<Label title="Payment Type" for="paymentType" />
								<select
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.paymentType
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("paymentType", {
										required: "Select payment type here",
									})}
								>
									<option value="">
										Select Type
									</option>
									{Object.entries(OVERTIME_PAYMENT_TIER_TYPE).map(([key, value]) => (
										<option key={key} value={value} >
											{enumToStringConverter(value)}
										</option>
									))}
								</select>
								{errors.paymentType && (
									<p className="text-red-500 text-sm mt-1">
										{errors.paymentType.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Currency" for="currencyId" />
								<select
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
										errors.currencyId
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("currencyId", {
										required: "Enter currency here",
										valueAsNumber: true,
									})}
								>
									<option value="">
										Select Currency
									</option>
									{companyCurrencies?.map((item) => (
										<option key={item?.id} value={item?.id}>
											{item?.currency?.name} ({item?.currency?.code})
										</option>
									))}
								</select>
								{errors.currencyId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.currencyId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Fixed Component" for="fixedComponent" />
								<MoneyInput 
									attributes={{
										id: "fixedComponent",
										placeholder: "Enter value",
										disabled: formDisabled,
									}}
									register={register}
									name="fixedComponent"
									defaultValue={paymentTier.fixedComponent}
									setValue={setValue}
									required="Enter fixed component here"
									error={errors.fixedComponent}
								/>
								{errors.fixedComponent && (
									<p className="text-red-500 text-sm mt-1">
										{errors.fixedComponent.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Factor Component" for="factorComponent" />
								<MoneyInput 
									attributes={{
										id: `factorComponent`,
										placeholder: "Enter value",
										disabled: formDisabled,
									}}
									register={register}
									name="factorComponent"
									defaultValue={paymentTier.factorComponent}
									setValue={setValue}
									required="Enter factor component here"
									error={errors.factorComponent}
								/>
								{errors.factorComponent && (
									<p className="text-red-500 text-sm mt-1">
										{errors.factorComponent.message}
									</p>
								)}
							</div>
							<div className="flex flex-col w-full">
								<Label title="Minimum Number of Hours" for="minHours" />
								<div className="flex items-center mt-2 h-14">
									<input
										placeholder={"0"}
										type="number"
										{...register("minHours", {
											required: "Enter minimum number of hours here",
											valueAsNumber: true,
											validate: (value, formData) => {
												const maxHoursPermitted = formDataValues.maxHoursPermitted;
												const minHoursRequired = formDataValues.minHoursRequired;
												const maxHours = formData.maxHours;
												
												if (maxHours && value >= maxHours) {
													return "Must be less than the max hours"
												}
												if (value > maxHoursPermitted || value < minHoursRequired) {
													return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
												}
												return true
											}
										})}
										id="minHours"
										className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
											errors.minHours
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										disabled={formDisabled}
									/>
									<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
										Hours
									</div>
								</div>
								{errors.minHours && (
									<p className="text-red-500 text-sm mt-1">
										{errors.minHours.message}
									</p>
								)}
							</div>
							<div className="flex flex-col w-full">
								<Label title="Maximum Number of Hours" for="maxHours" />
								<div className="flex items-center mt-2 h-14">
									<input
										placeholder={"0"}
										type="number"
										{...register("maxHours", {
											required: "Enter maximum number of hours here",
											valueAsNumber: true,
											validate: (value) => {
												const maxHoursPermitted = formDataValues.maxHoursPermitted;
												const minHoursRequired = formDataValues.minHoursRequired;
												
												if (value > maxHoursPermitted || value < minHoursRequired) {
													return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
												}
												return true
											}
										})}
										id="maxHours"
										className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
											errors.maxHours
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										disabled={formDisabled}
									/>
									<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
										Hours
									</div>
								</div>
								{errors.maxHours && (
									<p className="text-red-500 text-sm mt-1">
										{errors.maxHours.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent="this tier"
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditPaymentTier;
