import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import useUserStore from "../../../../../state-management/useUserStore";
import { useCreateCostCenter } from "../../../../../services/cost-center.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
    sequenceNumber: number;
}

interface INewCostCenterForm {
	setShowNewCostCenterForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewCostCenterForm = ({
	setShowNewCostCenterForm,
}: INewCostCenterForm) => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createCostCenter } = useCreateCostCenter();
	const { addNewAccordionsData } = useManageAccordionsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createCostCenter(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
					organizationId: currentUser.user.organization?.id as string,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewCostCenterForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for={`name`} />
					<input
						placeholder={"Enter Name"}
						type="text"
						{...register(`name`, {
							required: "Enter name here",
						})}
						id={`name`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.name && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.name?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="col-span-2 w-full">
					<Label title="Description" for={`description`} />
					<FormDescriptionInput 
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`
						}}
						register={register}
						required="Enter brief description here"
					/>
					{errors?.description && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.description?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Sequence Number" for={`sequenceNumber`} />
					<input
						placeholder={"Enter sequence number"}
						type="number"
						{...register(`sequenceNumber`, {
							required: "Enter sequence number here",
							valueAsNumber: true
						})}
						id={`sequenceNumber`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.sequenceNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.sequenceNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.sequenceNumber?.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewCostCenterForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Cost Center"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewCostCenterForm;
