import { useState } from "react";
import useUserStore from "../../../../state-management/useUserStore";
import { useAsyncEffect, useLockFn } from "ahooks";
import API from "../../../../services/utils/api";
import { EmployeeDto } from "../../../../models/employee.dto";

export function useSupervisorCheck(companyId: number) {
    const { setSupervisor } = useUserStore();
    const [supervisees, setSupervisees] = useState<EmployeeDto[]>([]);
    const [refetchData, setRefetchData] = useState(true);

    useAsyncEffect(useLockFn(async () => {
        if (!refetchData) return;

        try {
            const response = await API.HrAPI.getSupervisees(companyId);

            setSupervisor(response.meta.hasSupervisees);
            setSupervisees(response.data);
            setRefetchData(false);
        } catch (error: any) {
            if (
                error.response?.data?.error === "NOT_FOUND" ||
                error.response?.data?.message?.toLowercase() === "No position for Employee"
            ) {
                setRefetchData(false);
            }
        }
    }), [companyId])

    return { supervisees }
}