import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import {
    APP_CONFIG,
	IAddEditTableForm,
	compareDateFields,
	validateFuture,
} from "../../../../helpers/appHelpers";
import {
	useCreateLeaveRequest,
	useUpdateLeaveRequest,
} from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { EmployeeDto } from "../../../../models/employee.dto";
import { useAsyncEffect } from "ahooks";
import API from "../../../../services/utils/api";
import { ApplicableLeaveTypeOrderBy, LeaveTypeDto } from "../../../../models/leave-type.dto";

interface IFormInputs {
	employeeId: number;
	leaveTypeId: number;
	startDate: string;
	returnDate: string;
	comment: string;
}

type HrLeaveRequestModalFormProps = IAddEditTableForm & {
    employees: EmployeeDto[];
}

const HrLeaveRequestModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
    employees,
}: HrLeaveRequestModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [leaveTypes, setLeaveTypes] = useState<LeaveTypeDto[]>([]);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
        setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
        defaultValues: {
            leaveTypeId: isEditForm ? formDataValues?.leavePackage?.leaveType?.id : "",
            startDate: isEditForm ? formDataValues?.startDate.slice(0, 10)  : "",
            returnDate: isEditForm ? formDataValues?.returnDate.slice(0, 10)  : "",
            comment: isEditForm ? formDataValues?.comment : "",
        }
    });

    const employeeId = watch("employeeId");

    useAsyncEffect(async () => {
        if (!employeeId && !isEditForm) return;
        
        const { data } = await API.HrAPI.getApplicableLeaveType({
            employeeId: employeeId || formDataValues?.employeeId,
            limit: APP_CONFIG.PAGE_LIMIT,
            page: 1,
            orderBy: ApplicableLeaveTypeOrderBy.CREATED_AT_DESC,
        })

        setLeaveTypes(data);
    }, [employeeId])

    useEffect(() => {
        if (leaveTypes.length && isEditForm) {
            setValue("leaveTypeId", formDataValues?.leavePackage?.leaveType?.id)
        }
    }, [leaveTypes.length])

	const { mutate: createLeaveRequest } = useCreateLeaveRequest();
	const { mutate: updateLeaveRequest } = useUpdateLeaveRequest();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateLeaveRequest(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createLeaveRequest(
				{ 
                    payload: {
                        ...data,
                    }
                },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
				<div className="space-y-5 lg:space-y-0 lg:grid grid-cols-2 gap-x-4 gap-y-6">
                    {!isEditForm && (
                        <div className="w-full">
                            <Label title="Employee" for="employeeId" />
                            <select
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.employeeId
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                {...register("employeeId", {
                                    required: "Select employee here",
                                    valueAsNumber: true,
                                })}
                            >
                                <option value="">Select Employee</option>
                                {employees.map((employee) => (
                                    <option key={employee.id} value={employee.id}>
                                        {employee.firstName + " " + employee.lastName}
                                    </option>
                                ))}
                            </select>
                            {errors.employeeId && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.employeeId.message}
                                </p>
                            )}
                        </div>
                    )}
					<div className={`w-full ${isEditForm ? "col-span-2" : ""}`}>
						<Label title="Leave Type" for="leaveTypeId" />
						<select
							defaultValue={isEditForm ? formDataValues?.leavePackage?.leaveType?.id : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.leaveTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("leaveTypeId", {
								required: "Select leave type here",
								valueAsNumber: true,
							})}
                            disabled={!leaveTypes.length}
						>
							<option value="">Select Leave Type</option>
							{leaveTypes.map((leavePackage) => (
								<option key={leavePackage.id} value={leavePackage.id}>
									{leavePackage.name}
								</option>
							))}
						</select>
						{errors.leaveTypeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.leaveTypeId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Date" for="startDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.startDate.slice(0, 10) : ""
							}
							type="date"
							{...register("startDate", {
								required: "Enter start date here",
								validate: validateFuture,
							})}
							id="startDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.startDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.startDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Return Date" for="returnDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.returnDate.slice(0, 10) : ""
							}
							type="date"
							{...register("returnDate", {
								required: "Enter return date here",
								validate: (value) => compareDateFields(
									watch("startDate"), 
									value,
									"Start date must be less than return date"
								),
							})}
							id="returnDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.returnDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.returnDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.returnDate.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Comment" for="comment" />
						<input
							defaultValue={isEditForm ? formDataValues?.comment : ""}
							placeholder={"Enter comment"}
							type="text"
							{...register("comment", {
								required: "Enter comment here",
							})}
							id="comment"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.comment
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.comment && (
							<p className="text-red-500 text-sm mt-1">
								{errors.comment.message}
							</p>
						)}
					</div>
				</div>

				<div className="flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Request"
								: "Create Request"
						}
						className={"px-4"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default HrLeaveRequestModalForm;
