import React, {useEffect} from "react";
import {HelmetProvider} from "react-helmet-async";
import AppRoutes from "./routes";
import {useIsFetching} from "react-query";
import { ToastContainer } from 'react-toastify';
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import Tailwind from "primereact/passthrough/tailwind";
// import "primeflex/primeflex.css"; // css utility
// import "primeicons/primeicons.css";
// import "primereact/resources/primereact.css";
import "react-toastify/dist/ReactToastify.css";
function App() {
	const isFetching = useIsFetching();

	return (
		// <PrimeReactProvider >
			<HelmetProvider>
				<ToastContainer />
				<AppRoutes />
			</HelmetProvider>
		// </PrimeReactProvider>
	);
}

export default App;