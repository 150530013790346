import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CompleteReimbursementRequestDto, 
    CreateReimbursementRequestDto, 
    QueryReimbursementRequestDto, 
    ReimbursementRequestDto, 
    ReimbursementRequestPaginator, 
    ReimbursementRequestUpdatesDto, 
    ReimbursementResponseInputDto, 
    SearchReimbursementRequestDto, 
    UpdateReimbursementRequestDto 
} from '../models/reimbursement-request.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetReimbursementRequest(options?: QueryReimbursementRequestDto) {
    const url = ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET;
    const request = (options: QueryReimbursementRequestDto) => API.HrAPI.getReimbursementRequest(options);

    const { data, isLoading, error, refetch } = useQuery<ReimbursementRequestPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetReimbursementRequestSearch() {
    
    return useMutation(
        (options?: SearchReimbursementRequestDto) => API.HrAPI.getReimbursementRequestSearch(options),
        {
            onSuccess: (response) => {
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetReimbursementRequestById(id: number) {
    const url = ENDPOINTS.HR.REIMBURSEMENT_REQUEST.DETAIL;
    const request = API.HrAPI.getReimbursementRequestById(id);

    const { data, isLoading, error } = useQuery<{ data: ReimbursementRequestDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreateReimbursementRequest(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateReimbursementRequestDto }) => API.HrAPI.createReimbursementRequest(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET);
            },
        },
    );
}

export function useUpdateReimbursementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateReimbursementRequestDto, id: number }) => API.HrAPI.updateReimbursementRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET);
            },
        },
    );
}

export function useRespondToReimbursementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: ReimbursementResponseInputDto, id: number }) => API.HrAPI.respondToReimbursementRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET);
            },
        },
    );
}

export function usePostUpdateToReimbursementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: ReimbursementRequestUpdatesDto, id: number }) => API.HrAPI.postUpdateToReimbursementRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET);
            },
        },
    );
}

export function useCompleteReimbursementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: CompleteReimbursementRequestDto, id: number }) => API.HrAPI.completeReimbursementRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET);
            },
        },
    );
}

export function useDeleteReimbursementRequest() {

    return useMutation(
        ({ id}: {id: number}) => API.HrAPI.deleteReimbursementRequest(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}