import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";

export const ExplicitDeductionsColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "applyOn",
		headerName: "Apply On",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{params.value > 0 ? moneyFormat(params.value) : "--"}
				</div>
			);
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{params.value > 0 ? (params.value + "%") : "--"}
				</div>
			);
		},
	},

	...createdAtmodifiedAtActions
];

export const LoansColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "repaymentType",
		headerName: "Repayment Type",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "interestRate",
		headerName: "Interest Rate",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "numberOfMonths",
		headerName: "Number Of Months",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "startPayPeriod",
		headerName: "Start Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},

	...createdAtmodifiedAtActions
];