import { useContext, useEffect, useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import Calendar from "react-calendar";
import { format } from "date-fns";
import "react-calendar/dist/Calendar.css";
import { 
	IAddEditTableForm, 
	compareDateFields,
	validateFuture 
} from "../../../../helpers/appHelpers";
import { UserCompanyProfile } from "../../../../models/user.dto";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import useUserStore from "../../../../state-management/useUserStore";
import { useCreateLeavePlan, useUpdateLeavePlan } from "../../../../services/leave-plan.service";
import { ApplicableLeaveTypeContext } from "..";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useViewPort } from "../../../../helpers/useViewPort";

interface IFormInputs {
	leaveTypeId: number;
	intendedStartDate: string;
	intendedReturnDate: string;
	comment: string;
}

const LeavePlanModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const viewPortWidth = useViewPort();
	const leaveTypes = useContext(ApplicableLeaveTypeContext);
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
            leaveTypeId: isEditForm ? formDataValues?.leavePackage?.leaveType.id : "",
            intendedStartDate: isEditForm ? formDataValues?.intendedStartDate.slice(0, 10)  : "",
            intendedReturnDate: isEditForm ? formDataValues?.intendedReturnDate.slice(0, 10)  : "",
            comment: isEditForm ? formDataValues?.comment : "",
		}
	});

	const [dateRange, setDateRange] = useState<[Date, Date]>([
		new Date(),
		new Date(),
	]);

	const intendedStartDate = watch("intendedStartDate"); // This subscribes to the start date value
	const intendedReturnDate = watch("intendedReturnDate"); // This subscribes to the end date value

	// When calendar date is clicked, set the form fields
	const handleDateChange = (range: Date[]) => {
		const [start, end] = range;
		setValue("intendedStartDate", format(start, "yyyy-MM-dd"));
		setValue("intendedReturnDate", format(end || start, "yyyy-MM-dd"));
		setDateRange([start, end || start]);
	};

	// When form fields change, update the calendar
	useEffect(() => {
		const start = intendedStartDate
			? new Date(intendedStartDate)
			: dateRange[0];
		const end = intendedReturnDate
			? new Date(intendedReturnDate)
			: dateRange[1];
		setDateRange([start, end]);
	}, [intendedStartDate, intendedReturnDate]);

	const renderLegend = () => (
		<div className="flex items-center space-x-3 mt-2">
			{leaveTypes.map((leaveType) => (
				<div key={leaveType.id} className="flex items-center mt-2">
					<span 
						className={`legend-dot bg-[${leaveType.colorCode}]`}
						style={{ background: leaveType.colorCode }}
					/>
					<span className="ml-2 text-xs">{leaveType.name}</span>
				</div>
			))}
		</div>
	);

	// const getLeaveTypeForDate = (date: Date): string => {
	// 	let className = "";
	// 	for (const leaveType of leaveTypes) {
	// 		const { type, dates } = leaveType;
	// 		if (dates.length === 2) {
	// 			// Make sure the start date is before the end date
	// 			const interval = { start: dates[0], end: dates[1] };
	// 			if (interval.start > interval.end) {
	// 				console.error("Invalid interval for leave:", type, interval);
	// 			} else if (isWithinInterval(date, interval)) {
	// 				className = leaveTypesSample[type].color; // assign the class name corresponding to the leave type
	// 				break; // Stop checking if we've found a match
	// 			}
	// 		}
	// 	}
	// 	return className; // Returns the class name or an empty string if no match was found
	// };

	const { mutate: createLeavePlan } = useCreateLeavePlan();
	const { mutate: updateLeavePlan } = useUpdateLeavePlan();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateLeavePlan(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createLeavePlan(
				{
					payload: {
						...data,
						employeeId: companyProfile.employeeId as number,
					},
				},
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<>
			<ModalComponent
				width={viewPortWidth < 1024 ? "92%" : 900}
				open={open}
				title={title}
				handleClose={handleClose}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex lg:flex-row flex-col lg:items-center lg:justify-between mt-6">
						<div className="lg:w-1/2">
							<Calendar
								selectRange={true}
								onChange={handleDateChange as any}
								value={dateRange}
								// tileClassName={({ date, view }) => {
								// 	if (view === "month") {
								// 		return getLeaveTypeForDate(date);
								// 	}
								// }}
							/>
							{/* Render the legend below the calendar */}
							<div>{renderLegend()}</div>
						</div>
						<div className="lg:w-1/2 mt-10 lg:mt-0">
							<form>
								<div className="w-full">
									<Label title="Leave Type" for="leaveTypeId" />
									<select
										defaultValue={isEditForm ? formDataValues?.leavePackage?.leaveType.id : ""}
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.leaveTypeId
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										{...register("leaveTypeId", {
											required: "Select leave type here",
											valueAsNumber: true,
										})}
									>
										<option value="">Select Leave Type</option>
										{leaveTypes.map((leaveType) => (
											<option key={leaveType.id} value={leaveType.id}>
												{leaveType.name}
											</option>
										))}
									</select>
									{errors.leaveTypeId && (
										<p className="text-red-500 text-sm mt-1">
											{errors.leaveTypeId.message}
										</p>
									)}
								</div>
								<div className="w-full mt-6">
									<Label title="Intended Start Date" for="intendedStartDate" />
									<input
										type="date"
										{...register("intendedStartDate", {
											required: "Enter start date here",
											validate: validateFuture,
										})}
										id="intendedStartDate"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.intendedStartDate
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.intendedStartDate && (
										<p className="text-red-500 text-sm mt-1">
											{errors.intendedStartDate.message}
										</p>
									)}
								</div>
								<div className="w-full mt-6">
									<Label
										title="Intended Return Date"
										for="intendedReturnDate"
									/>
									<input
										type="date"
										{...register("intendedReturnDate", {
											required: "Enter end date here",
											validate: (value) => compareDateFields(
												watch("intendedStartDate"), 
												value,
												"Start date must be less than return date"
											),
										})}
										id="intendedReturnDate"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.intendedReturnDate
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.intendedReturnDate && (
										<p className="text-red-500 text-sm mt-1">
											{errors.intendedReturnDate.message}
										</p>
									)}
								</div>
								<div className="w-full mt-6">
									<Label title="Comment" for="comment" />
									<input
										placeholder={"Enter comment"}
										type="text"
										{...register("comment", {
											required: "Enter comment here",
										})}
										id="comment"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.comment
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.comment && (
										<p className="text-red-500 text-sm mt-1">
											{errors.comment.message}
										</p>
									)}
								</div>
							</form>
						</div>
					</div>

					<div className="flex justify-end mt-8">
						<OrangeButton
							type="submit"
							title={
								uploading
									? "Uploading..."
									: isEditForm
									? "Edit Plan"
									: "Plan Leave"
							}
							className={"px-4"}
							disabled={uploading}
						/>
					</div>
				</form>
			</ModalComponent>
		</>
	);
};

export default LeavePlanModalForm;
