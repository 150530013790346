import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import EditCompanyLevel from "./EditCompanyLevel";
import NewCompanyLevel from "./NewCompanyLevel";
import SkewLoader from "react-spinners/SkewLoader";
import { useGetCompanyLevel } from "../../../../../services/company-level.service";
import { CompanyLevelDto, CompanyLevelOrderBy } from "../../../../../models/company-level.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";

const CompanyLevels = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewCompanyLevelForm, setShowNewCompanyLevelForm] = useState(false);
	
	const {
		accordionsData: companyLevels,
		isLoading,
		pageCount,
    } = useManageAccordions<CompanyLevelDto>({
		fetchHook: useGetCompanyLevel,
		options: { companyId: currentCompany?.id }
	});

	const { data: allCompanyLevels } = useGetCompanyLevel({
        companyId: currentCompany?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CompanyLevelOrderBy.LEVEL_NAME_ASC,
    });
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Company Levels</h2>
				{!showNewCompanyLevelForm && (
					<button
						onClick={() => setShowNewCompanyLevelForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Company Level
					</button>
				)}
			</div>

			{showNewCompanyLevelForm && (
				<NewCompanyLevel 
					companyLevels={companyLevels}
					setShowNewCompanyLevelForm={setShowNewCompanyLevelForm} 
				/>
			)}
			
			{(isLoading && !companyLevels.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{companyLevels.map((companyLevel) => (
				<EditCompanyLevel 
					key={companyLevel.id}
					companyLevel={companyLevel} 
					companyLevels={
						allCompanyLevels?.data?.filter(
							level => level.id !== companyLevel.id
						) || []
					} 
				/>
			))}

			{(!isLoading && !companyLevels.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no company level added yet. Click 'Add New Company Level' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default CompanyLevels;
