import React from "react";
import { FiSearch } from "react-icons/fi";

const excludedKeys = new Set([
	"id", "image", "onboardingDate", "createdAt", "modifiedAt", 
	"startDate", "endDate", "code"
]);

interface SearchProps {
	fallbackData: any[];
	setTableData: React.Dispatch<React.SetStateAction<any>>;
	attributes?: React.InputHTMLAttributes<HTMLInputElement>;
}
const Search = ({fallbackData, setTableData, attributes}: SearchProps) => {
	const [inputValue, setInputValue] = React.useState("");

	const updateInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		setInputValue(input.value)
	};

	const searchItems = () => {
		const lowercasedInputValue = inputValue.trim().toLowerCase();

		if (lowercasedInputValue === "") {
			setTableData(fallbackData);
			return;
		}		 

		const filteredData: any = fallbackData.filter((dataItem) => {
			const entries = Object.entries(dataItem);

			const filteredEntries = entries.filter(([key, value]) => {
				return !excludedKeys.has(key);
			});

			return filteredEntries.some(([key, value]) => {
				if (!value) return false;
				const lowercasedValue = value?.toString().toLowerCase();
				return lowercasedValue.indexOf(lowercasedInputValue) !== ~0;
			});
		});

		setTableData(filteredData);
	};

	return (
		<div className="w-full relative">
			<input
				type="text"
				placeholder="Search"
				className="w-full bg-secondary-gray rounded-lg py-3 pl-12 pr-4 text-sm"
				value={inputValue}
				onChange={(e) => {
					setInputValue(e.target.value);
					searchItems();
				}}
				onKeyUp={(e) => {
					updateInputValue(e);
					searchItems();
				}}
				{ ...attributes }
			/>
			<FiSearch className="absolute top-1/2 -translate-y-1/2 left-5 text-gray-400 text-lg" />
		</div>
	);
};

export default Search;
