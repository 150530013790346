import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import SkewLoader from "react-spinners/SkewLoader";
import { CompanyGroupingDto } from "../../../../../models/company-grouping.dto";
import { useGetCompanyGrouping } from "../../../../../services/company-grouping.service";
import EditCompanyGroup from "./EditCompanyGroup";
import NewCompanyGroup from "./NewCompanyGroup";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";
import { enumToStringConverter } from "../../../../../helpers/appHelpers";

const CompanyGrouping = ({ type }: { type: string }) => {
	const { currentCompany } = useCompanyStore();
	const [showNewCompanyGroupForm, setShowNewCompanyGroupForm] = useState(false);
	
	const {
		accordionsData: companyGroups,
		isLoading,
		pageCount,
    } = useManageAccordions<CompanyGroupingDto>({
		fetchHook: useGetCompanyGrouping,
		options: { 
			type,
			companyId: currentCompany?.id,
			orderBy: undefined,
		}
	});
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available {type.toLowerCase()}s</h2>
				{!showNewCompanyGroupForm && (
					<button
						onClick={() => setShowNewCompanyGroupForm(true)}
						className="border-none flex items-center text-primary-blue capitalize"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New {type.toLowerCase()}
					</button>
				)}
			</div>

			{showNewCompanyGroupForm && (
				<NewCompanyGroup 
					type={type}
					setShowNewCompanyGroupForm={setShowNewCompanyGroupForm} 
				/>
			)}
			
			{(isLoading && !companyGroups.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{companyGroups.map((companyGroup) => (
				<EditCompanyGroup 
					key={companyGroup.id}
					companyGroup={companyGroup} 
				/>
			))}

			{(!isLoading && !companyGroups.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no {type.toLowerCase()}s added yet. Click 'Add New {enumToStringConverter(type.toLowerCase())}' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default CompanyGrouping;
