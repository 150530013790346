import { SubmitHandler, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { CreateTaxReliefDto, PayrollComponent } from "../../../../../../models/tax-relief.dto";
import { APP_CONFIG, enumToStringConverter, IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import {
	useCreateTaxRelief,
	useUpdateTaxRelief,
} from "../../../../../../services/tax-relief.service";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import { ActiveCountryContext } from "..";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { useGetTaxReliefType } from "../../../../../../services/tax-relief-type.service";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";

interface IFormInputs extends CreateTaxReliefDto {}

const ReliefTaxRuleModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const countries = useContext(ActiveCountryContext);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			countryId: isEditForm ? formDataValues?.countryId : undefined,
			rateComponent: isEditForm ? formDataValues?.rateComponent : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			taxReliefTypeId: isEditForm ? formDataValues?.taxReliefTypeId : "",
			incomePeriod: isEditForm ? formDataValues?.incomePeriod : "",
		}
	});

	const countryId = watch("countryId") || undefined;
	const { 
		data: taxReliefTypes, 
		isLoading: taxReliefTypesLoading,
		refetch: refetchTaxReliefType
	} = useGetTaxReliefType(
		{
			countryId,
			limit: APP_CONFIG.PAGE_LIMIT,
			orderBy: "createdAt:desc" as any,
		}, 
		{ enabled: false }
	);

	useEffect(() => {
		if (countryId) {
			refetchTaxReliefType();
		}
	}, [countryId])

	useEffect(() => {
		setValue(
			"taxReliefTypeId", 
			isEditForm ? formDataValues?.taxReliefTypeId : ""
		)
	}, [taxReliefTypesLoading])

	const { mutate: createReliefTaxRule } = useCreateTaxRelief();
	const { mutate: updateReliefTaxRule } = useUpdateTaxRelief();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		data.taxReliefTypeId = Number(data.taxReliefTypeId);

		if (isEditForm) {
			delete data.countryId;
			updateReliefTaxRule(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createReliefTaxRule(
				{ payload: data },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label 
							title="Tax Relief Type" 
							for="taxReliefTypeId" 
							optional={!isEditForm && countryId === undefined ? "Select country first" : undefined}
						/>
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.taxReliefTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("taxReliefTypeId", {
								required: "Select tax relief type",
							})}
						>
							<option value="">Select Type</option>
							{taxReliefTypes?.data?.map((taxReliefType) => (
								<option
									key={taxReliefType.id}
									value={taxReliefType.id}
									className="capitalize"
								>
									{taxReliefType.name}
								</option>
							))}
						</select>
					</div>
					<div className="w-full">
						<Label title="Rate Component" for="rateComponent" />
						<div className="flex items-center h-14 mt-2">
							<input
								placeholder={"0"}
								type="number"
								{...register("rateComponent", {
									required: "Enter rate component",
									pattern: {
										value: /^[0-9]*$/,
										message: "Enter only numbers",
									},
								})}
								id="rateComponent"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.rateComponent
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
								%
							</div>
						</div>
						{errors.rateComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.rateComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Fixed Component" for="fixedComponent" optional />
						<MoneyInput 
							attributes={{
								id: "fixedComponent",
								placeholder: "0"
							}}
							register={register}
							name="fixedComponent"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.fixedComponent : undefined}
							required={false}
							error={errors.fixedComponent}
						/>
					</div>
					<div className="w-full">
						<Label title="Income Period" for="incomePeriod" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.incomePeriod
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("incomePeriod", {
								required: "Select income period here",
							})}
						>
							<option value="">Select Period</option>
							<option value="MONTH">Month</option>
							<option value="YEAR">Year</option>
						</select>
						{errors.incomePeriod && (
							<p className="text-red-500 text-sm mt-1">
								{errors.incomePeriod.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Amount Based On" for="amountDerivedFrom" optional />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.amountDerivedFrom
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("amountDerivedFrom", {
								required: false,
							})}
						>
							<option value="">Select Option</option>
							{Object.entries(PayrollComponent).map(([key, value]) => (
								<option key={key} value={value}>{enumToStringConverter(value)}</option>
							))}
						</select>
						{errors.amountDerivedFrom && (
							<p className="text-red-500 text-sm mt-1">
								{errors.amountDerivedFrom.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-8">
					<button
						type="submit"
						disabled={uploading}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					>
						{`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Rate Rule"
								: "Add Rate Rule"
						}`}
					</button>
				</div>
			</form>
		</ModalComponent>
	);
};

export default ReliefTaxRuleModalForm;
