import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import Label from "../../../../../components/form_fields/Label";
import { useDropzone } from "react-dropzone";
import { IoImageOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Avatar, Box, Modal, Popover } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import { ModalStyle } from "../../../../../helpers/appHelpers";
import { uploadOneDocument } from "../../../../../services/document.service";
import { useUpdateSelf } from "../../../../../services/user.service";
import { UpdateSelfDto } from "../../../../../models/user.dto";
import useUserStore from "../../../../../state-management/useUserStore";
import ChangePasswordModal from "../../../../../components/header/resources/ChangePasswordModal";

const ProfileSettings = () => {
	const { currentUser, setCurrentUser } = useUserStore();
	const [isEditable, setIsEditable] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [image, setImage] = useState("");
	const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
	const [openDeletePhotoConfirmation, setOpenDeletePhotoConfirmation] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/png": [".png"],
		},
		maxFiles: 1,
		onDrop: async (acceptedFiles) => {
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFiles[0]);
			reader.onloadend = () => {
				const base64 = reader.result;
				setImage(base64 as string);
			};
			setFile(acceptedFiles[0]);
			setIsEditable(true);
		},
	});

	const handlePhotoDelete = () => {
		setFile(null);
		setImage("");
		setOpenDeletePhotoConfirmation(false);
		setAnchorEl(null);
	};

	const {
		register,
		handleSubmit,
		control,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm<UpdateSelfDto>({
		defaultValues: {
			firstName: currentUser.user.firstName,
			lastName: currentUser.user.lastName,
			emailAddress: currentUser.user.emailAddress,
			msisdn: currentUser.user.msisdn,
		}
	});

	const phoneInputHeight = errors.msisdn ? "50px" : "48px";
	const { mutate: updateProfile } = useUpdateSelf();
	const [uploading, setUploading] = useState(false);

	const onSubmit: SubmitHandler<UpdateSelfDto> = async (data: UpdateSelfDto) => {
		setUploading(true);

		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			const uploadedFile = await uploadOneDocument(formData, true);
			data.imageUrl = uploadedFile?.data?.url;
		}

		updateProfile(
			{ payload: data },
			{
				onSuccess: (updatedUser) => {
					setCurrentUser({
						...currentUser,
						user: {
							...(currentUser as any).user,
							...updatedUser.data
						},
					});
					setIsEditable(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 flex w-full justify-between font-semibold text-sm">
				<h2 className="font-semibold text-sm">Profile Settings</h2>
				<button
					className={`border-none text-primary-blue text-sm ${
						isEditable === true ? "hidden" : ""
					}`}
					onClick={() => setIsEditable(true)}
				>
					Edit
				</button>
			</div>
			<div className="flex flex-col gap-2 mt-5">
				<div className="text-sm">Profile Picture</div>
				<div className="relative inline-flex">
					<button
						className="absolute -top-2 left-[166px] z-10 bg-white rounded-full shadow-md border border-gray-400 p-1 flex items-center justify-center"
						onClick={handleClick}
					>
						<MdModeEdit className="text-lg hover:opacity-90" />
					</button>
					<Avatar
						variant="square"
						sx={{ borderRadius: "5px", width: 180, height: 180 }}
						alt="company"
						src={image ? image : currentUser.user.imageUrl}
						className="border border-gray-300"
					/>
				</div>
			</div>
			<div>
				<div className="flex space-x-4 mt-8">
					<div className="w-full">
						<Label title="First Name" for="firstName" />
						<input
							placeholder="Enter First Name"
							type="text"
							{...register("firstName", {
								required: "Enter your first name",
							})}
							id="firstName"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.firstName
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.firstName && (
							<p className="text-red-500 text-sm mt-1">
								{errors.firstName.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Last Name" for="lastName" />
						<input
							placeholder="Enter Last Name"
							type="text"
							{...register("lastName", {
								required: "Enter your last name",
							})}
							id="lastName"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.lastName
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.lastName && (
							<p className="text-red-500 text-sm mt-1">
								{errors.lastName.message}
							</p>
						)}
					</div>
				</div>
				<div className="flex space-x-4 mt-8">
					<div className="w-full">
						<Label title="Email" for="emailAddress" />
						<input
							placeholder="Enter Email"
							type="text"
							{...register("emailAddress", {
								required: "Enter your email address here",
								pattern: {
									value: /\S+@\S+\.\S+/,
									message: "Entered value does not match email address format",
								},
							})}
							id="emailAddress"
							className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
								errors.emailAddress
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							disabled={!isEditable}
						/>
						{errors.emailAddress && (
							<p className="text-red-500 text-sm mt-1">
								{errors.emailAddress.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Account Phone Number" for="msisdn" />
						<Controller
							name={"msisdn"}
							control={control}
							rules={{
								required: false,
								maxLength: {
									value: 12,
									message: "Enter a valid phone number",
								},
							}}
							render={({ field }) => (
								<div
									className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
										errors.msisdn
											? "border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								>
									<PhoneInput
										{...field}
										country={"gh"}
										disabled={!isEditable}
										inputStyle={{
											height: phoneInputHeight,
											border: "none",
											backgroundColor: "#F2F2F4",
											color: "black",
											boxShadow: "none",
											width: "95%",
											margin: "0px 0px 0px 15px",
											borderRadius: "10px",
										}}
										containerStyle={{
											// marginTop: "8px",
											height: phoneInputHeight,
											border: "none",
											backgroundColor: "#F2F2F4",
											boxShadow: "none",
											borderRadius: "10px",
										}}
										buttonStyle={{
											border: "none",
											backgroundColor: "#F2F2F4",
											boxShadow: "none",
											padding: "0px 10px",
										}}
									/>
								</div>
							)}
						/>
						{errors.msisdn && (
							<p className="text-red-500 text-sm mt-1">
								{errors.msisdn.message}
							</p>
						)}
					</div>
				</div>
				<div className="w-full flex items-center justify-between mt-16">
					<button
						className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
						onClick={() => setOpenUpdatePassword(true)}
					>
						Update Password
					</button>
					{isEditable && (
						<div className="flex items-center gap-4">
							<button
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									reset();
									clearErrors();
									setImage("");
									setIsEditable(false);
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={uploading ? "Uploading..." : "Save"}
								className={"px-12 hover:opacity-95"}
								onClick={handleSubmit(onSubmit)}
								disabled={uploading}
							/>
						</div>
					)}
				</div>
			</div>
			{openUpdatePassword && (
				<ChangePasswordModal
					open={openUpdatePassword}
					setOpen={setOpenUpdatePassword}
					title={"Change Password"}
				/>
			)}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: 150, // Set your desired width
						borderRadius: 8,
						display: "flex",
						justifyContent: "center", // Set your desired border radius
					},
				}}
			>
				<div className="flex flex-col justify-center py-3 space-y-2">
					<div
						className="text-primary-blue flex items-center p-3 cursor-pointer hover:opacity-80 hover:bg-blue-50 rounded-lg bg-slate-50"
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						<IoImageOutline />{" "}
						<span className="text-xs ml-2">Change Photo</span>
					</div>
					{/* <div
						onClick={() => {
							setOpenDeletePhotoConfirmation(true);
						}}
						className="flex items-center space-x-2 p-3 text-red-400 cursor-pointer hover:opacity-80 hover:bg-red-50 rounded-lg bg-slate-50"
					>
						<MdOutlineFolderDelete />
						<span className="border-none text-xs">Remove Photo</span>
					</div> */}
				</div>
			</Popover>

			<Modal
				open={openDeletePhotoConfirmation}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle} width={400}>
					<p className="text-center">
						Are you sure you want to delete this photo?
					</p>
					<div className="flex w-full justify-center space-x-4 items-center mt-10">
						<button
							onClick={() => setOpenDeletePhotoConfirmation(false)}
							className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
						>
							Cancel
						</button>
						<button
							onClick={handlePhotoDelete}
							className="px-12 py-4 border text-white hover:opacity-90 bg-secondary-red rounded-lg text-2xs"
						>
							Yes, Delete
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default ProfileSettings;
