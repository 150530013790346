import { Box, Modal } from "@mui/material";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useState } from "react";
import { useCreateLeaveRequest } from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import useUserStore from "../../../../state-management/useUserStore";
import { useDeleteLeavePlan } from "../../../../services/leave-plan.service";
import { useViewPort } from "../../../../helpers/useViewPort";

const ConvertPlanModal = ({
	openConvertPlan,
	setOpenConvertPlan,
	setLeavePlanTableData,
}) => {
	const viewPortWidth = useViewPort();
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [converting, setConverting] = useState(false);

	const { mutate: createLeaveRequest } = useCreateLeaveRequest();
	const { mutate: deleteLeavePlan } = useDeleteLeavePlan();

	const handleConvertPlan = () => {
		setConverting(true);

		createLeaveRequest(
			{
				payload: {
					leaveTypeId: formDataValues?.leavePackage?.leaveType.id,
					startDate: formDataValues?.intendedStartDate.slice(0, 10),
					returnDate: formDataValues?.intendedReturnDate.slice(0, 10),
					comment: formDataValues?.comment,
					employeeId: companyProfile.employeeId as number
				}
			},
			{
				onSuccess: () => setOpenConvertPlan(false),
				onSettled: () => setConverting(false),
			}
		);
	};

	return (
		<Modal open={openConvertPlan}>
			<Box sx={{
				position: "absolute" as "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: viewPortWidth < 1024 ? "92%" : 450,
				py: 4,
				bgcolor: "background.paper",
				boxShadow: 24,
				border: "none",
				borderRadius: "8px",
				outline: "none",
				overflow: "auto",
				maxHeight: "95vh",
				display: "flex",
				flexDirection: "column",
			}}>
				<div className="w-full px-6 lg:px-8">
					<div className="flex justify-center">
						<h3 className="text-xl font-bold">Convert Leave Plan</h3>
					</div>
					<div className="mt-6">
						<p className="text-2sm text-center">
							Do you want to convert this leave plan
							<br className="max-lg:hidden" /> 
							{" "}into a leave request?
						</p>
					</div>
					<div className="max-lg:w-full mt-8 flex justify-center space-x-4 whitespace-nowrap text-center">
						<button
							title={"Cancel"}
							onClick={() => setOpenConvertPlan(false)}
							className={`lg:px-14 max-lg:w-full text-gray-400 bg-white border rounded-lg
								border-secondary-lightgray text-2xs ${converting && "opacity-50"}
							`}
							disabled={converting}
						>
							Cancel
						</button>
						<OrangeButton
							onClick={handleConvertPlan}
							title={converting ? "Converting..." : "Yes, Convert"}
							className={"lg:px-10 max-lg:w-full"}
							disabled={converting}
						/>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default ConvertPlanModal;

