import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { LeavePackageColumns } from "../data";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import APISearchInput from "../../../../components/search/APISearchInput";
import LeavePackagesModalForm from "../modals/LeavePackagesModalForm";
import { useGetLeaveType } from "../../../../services/leave-type.service";
import { 
	useGetLeavePackageSearch, 
	useGetLeavePackage, 
	useDeleteLeavePackage 
} from "../../../../services/leave-package.service";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetCompanyLevel } from "../../../../services/company-level.service";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useUserStore from "../../../../state-management/useUserStore";
import { LeavePackageDto } from "../../../../models/leave-package.dto";
import TableFilter from "../../../../components/table/filter/TableFilter";

const LeavePackagesView = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const { mutate: searchLeavePackages } = useGetLeavePackageSearch();
	const {
		tableAPIRef,
		tableData,
		fetchedTableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<LeavePackageDto>({
		fetchHook: useGetLeavePackage,
		options: { companyId: companyProfile.companyId }
	});
	
	// Fetched data for select fields requiring data from database
	const options = {
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "createdAt:desc" as any,
	};
	const { data: leaveTypes } = useGetLeaveType(options);
	const { data: companyLevels } = useGetCompanyLevel({
		companyId: companyProfile.companyId,
		...options
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteLeavePackage } = useDeleteLeavePackage();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteLeavePackage(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = LeavePackageColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex items-center justify-between mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchLeavePackages}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Leave Type",
								fieldName: "leaveTypeId",
								fieldType: "SELECT_FIELD",
								options: leaveTypes?.data || [],
							},
							{
								title: "Paid",
								fieldName: "paid",
								fieldType: "YES_NO",
							},
							{
								title: "Redeemable",
								fieldName: "redeemable",
								fieldType: "YES_NO",
							},
							{
								title: "Accrued",
								fieldName: "accrued",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Leave Package
				</div>
			</div>
			<CustomStyledTable
				apiRef={tableAPIRef}
				rows={tableData}
				columns={columnsWithAdditions}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<LeavePackagesModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={isEditForm
						? "Update Leave Package"
						: "Add New Leave Package"}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					leaveTypes={leaveTypes?.data || []}
					companyLevels={companyLevels?.data || []}
				/>
			)}
		</>
	);
}

export default LeavePackagesView;