import { useContext, useState } from 'react'
import Label from "../../../../components/form_fields/Label";
import PhoneInput from "react-phone-input-2";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Avatar } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { IoImageOutline } from "react-icons/io5";
import { GoPencil } from "react-icons/go";
import { MdCancel } from "react-icons/md";
import { EmployeeDataContext } from '..';
import { uploadOneDocument } from '../../../../services/document.service';
import { useUpdateEmployee } from '../../../../services/employee.service';
import { useUpdateSelf } from '../../../../services/user.service';
import useUserStore from '../../../../state-management/useUserStore';

interface IFormInputs {
	photoUrl?: string;
	email?: string;
	privateEmail?: string;
	msisdn?: string;
	alternateMsisdn?: string;
}
const PersonalDetailsView = () => {
	const employeeData = useContext(EmployeeDataContext)?.employeeData;
	const setEmployeeData = useContext(EmployeeDataContext)?.setEmployeeData;
	const [file, setFile] = useState<File | null>(null);
	const [image, setImage] = useState<string | undefined>(undefined);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		control,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			email: employeeData?.email || "",
			privateEmail: employeeData?.privateEmail || "",
			msisdn: employeeData?.msisdn || "",
			alternateMsisdn: employeeData?.alternateMsisdn || "",
		}
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/png": [".png"],
		},
		maxFiles: 1,
		onDrop: async (acceptedFiles) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}
			
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				const base64 = reader.result;
				setImage(base64 as string);
			};
			setFile(file);
			setIsEdit(true);
		},
	})

	const msisdnInputHeight = errors.msisdn ? "50px" : "48px";
	const alternateMsisdnInputHeight = errors.msisdn ? "50px" : "48px";
	const { currentUser, setCurrentUser } = useUserStore();
	const { mutate: updateEmployee } = useUpdateEmployee();
	const { mutate: updateProfile } = useUpdateSelf();

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		if (!employeeData?.id) return;
		
		setUploading(true);

		let photoUrl = "";
		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			const uploadedFile = await uploadOneDocument(formData, true);
			photoUrl = uploadedFile?.data?.url as string;
		}
		if (photoUrl) data.photoUrl = photoUrl;

		updateEmployee(
			{ payload: data, id: employeeData.id },
			{
				onSuccess: (updatedData) => {
					setEmployeeData && setEmployeeData(updatedData.data);
					setIsEdit(false);
				},
				onSettled: () => setUploading(false),
			}
		)

		if (photoUrl) {
			updateProfile(
				{ payload: { imageUrl: photoUrl } },
				{
					onSuccess: (updatedUser) => {
						setCurrentUser({
							...currentUser,
							user: {
								...(currentUser as any).user,
								...updatedUser.data
							},
						});
					}
				}
			)
		};
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex lg:flex-row flex-col items-start justify-between">
				{!isEdit ? (
					<div
						onClick={() => setIsEdit(true)}
						className="bg-[#26A0F91A] mb-6 text-primary-blue p-4 rounded-lg flex lg:hidden space-x-2 items-center cursor-pointer"
					>
						<GoPencil />
						<p className="text-2xs">Edit Details</p>
					</div>
				) : (
					<div
						onClick={() => {
							reset();
							clearErrors();
							setImage("");
							setIsEdit(false);
						}}
						className="bg-[#f926261a] mb-6 text-red-500 p-4 rounded-lg flex lg:hidden space-x-2 items-center cursor-pointer"
					>
						<MdCancel />
						<p className="text-2xs">Cancel Edit</p>
					</div>
				)}
				<div>
					<Avatar
						variant="rounded"
						src={image ? image : employeeData?.photoUrl}
						alt="Profile Picture"
						sx={{ width: "182px", height: "182px" }}
					/>
					<div>
						<div {...getRootProps()} className="cursor-pointer">
							<input {...getInputProps()} />
							<div className="text-primary-blue flex items-center space-x-2 mt-3">
								<IoImageOutline className="" />{" "}
								<span className="text-[11px]">Change Profile Photo</span>
							</div>
						</div>
					</div>
				</div>
				{!isEdit ? (
					<div
						onClick={() => setIsEdit(true)}
						className="bg-[#26A0F91A] text-primary-blue p-4 rounded-lg lg:flex hidden space-x-2 items-center cursor-pointer"
					>
						<GoPencil />
						<p className="text-2xs">Edit Details</p>
					</div>
				) : (
					<div
						onClick={() => {
							reset();
							clearErrors();
							setImage("");
							setIsEdit(false);
						}}
						className="bg-[#f926261a] text-red-500 p-4 rounded-lg lg:flex hidden space-x-2 items-center cursor-pointer"
					>
						<MdCancel />
						<p className="text-2xs">Cancel Edit</p>
					</div>
				)}
			</div>
			<div className="flex flex-col lg:flex-row lg:space-x-3 items-start mt-10 lg:mt-6">
				<div className="w-full">
					<Label title="Company Email" for="email" />
					<input
						defaultValue={employeeData?.email}
						placeholder={"Enter email"}
						type="text"
						disabled={true}
						{...register("email")}
						id="email"
						className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm opacity-60 border-none"
					/>
				</div>
				<div className="w-full lg:mt-0 mt-6">
					<Label title="Private Email" for="privateEmail" />
					<input
						defaultValue={
							isEdit ? employeeData?.privateEmail : employeeData?.privateEmail
						}
						placeholder={"Enter private email"}
						disabled={!isEdit}
						type="text"
						{...register("privateEmail", {
							required: false,
							pattern: {
								value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								message: "Enter a valid email address",
							},
						})}
						id="privateEmail"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.privateEmail
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.privateEmail && (
						<p className="text-red-500 text-sm mt-1">
							{errors.privateEmail.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex flex-col lg:flex-row lg:space-x-3 items-start my-6">
				<div className="w-full">
					<Label title="Telephone 1" for="msisdn" />
					<Controller
						name={"msisdn"}
						control={control}
						rules={{
							required: false,
							maxLength: {
								value: 12,
								message: "Enter a valid phone number",
							},
						}}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
									errors.msisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									value={isEdit ? employeeData?.msisdn : employeeData?.msisdn}
									disabled={!isEdit}
									inputStyle={{
										height: msisdnInputHeight,
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										// marginTop: "8px",
										height: msisdnInputHeight,
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
					{errors.msisdn && (
						<p className="text-red-500 text-sm mt-1">{errors.msisdn.message}</p>
					)}
				</div>
				<div className="w-full lg:mt-0 mt-6">
					<Label title="Telephone 2" for="alternateMsisdn" />
					<Controller
						name={"alternateMsisdn"}
						control={control}
						rules={{
							required: false,
							maxLength: {
								value: 12,
								message: "Enter a valid phone number",
							},
						}}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
									errors.alternateMsisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									value={
										isEdit
											? employeeData?.alternateMsisdn
											: employeeData?.alternateMsisdn
									}
									disabled={!isEdit}
									inputStyle={{
										height: alternateMsisdnInputHeight,
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										// marginTop: "8px",
										height: alternateMsisdnInputHeight,
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
					{errors.alternateMsisdn && (
						<p className="text-red-500 text-sm mt-1">
							{errors.alternateMsisdn.message}
						</p>
					)}
				</div>
			</div>

			<div className="flex justify-end max-lg:mt-8 max-lg:mb-6">
				<OrangeButton
					title={uploading ? "Uploading..." : "Save Changes"}
					type="submit"
					className={`px-4`}
					disabled={!isEdit || uploading}
				/>
			</div>
		</form>
	);
};

export default PersonalDetailsView