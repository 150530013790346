import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { EmployeeBandDto } from "../../../../../../models/employee-band.dto";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { useCreateNotch } from "../../../../../../services/notches.service";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { moneyFormat } from "../../../../../../helpers/appHelpers";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
	amount: number;
}

interface INewNotchForm {
	employeeBand: EmployeeBandDto;
	setShowNewNotchForm: React.Dispatch<React.SetStateAction<boolean>>;
	setForceRefetch: React.Dispatch<React.SetStateAction<number>>;
}

const NewNotchForm = ({
	employeeBand,
	setShowNewNotchForm,
	setForceRefetch,
}: INewNotchForm) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createNotch } = useCreateNotch();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createNotch(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
					employeeBandId: employeeBand.id,
				}
			},
			{
				onSuccess: () => {
					setForceRefetch(Math.random());
					setShowNewNotchForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Amount" for="amount" />
					<MoneyInput 
						attributes={{
							id: "amount",
							placeholder: "Enter amount"
						}}
						register={register}
						name="amount"
						setValue={setValue}
						required={"Enter amount here"}
						validate={(value: number) => {
							if (value < employeeBand.minimumBasicPay) {
								return `Cannot be less than the minimum basic pay 
									(${moneyFormat(employeeBand.minimumBasicPay)})`
							}
							if (employeeBand.maximumBasicPay && value > employeeBand.maximumBasicPay) {
								return `Cannot be more than the maximum basic pay 
									(${moneyFormat(employeeBand.maximumBasicPay)})`
							}
							return true
						}}
						error={errors.amount}
					/>
					{errors.amount && (
						<p className="text-red-500 text-sm mt-1">
							{errors.amount?.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewNotchForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Notch"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewNotchForm;
