import { SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import { useCreateCustomTaxRelief } from "../../../../../services/custom-tax-relief.service";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import { EmployeeDto } from "../../../../../models/employee.dto";
import { EmployeeBandDto } from "../../../../../models/employee-band.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../../routes/routes.paths";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	amount: number;
	active: boolean;
}

interface INewCustomTaxReliefForm {
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
	setShowNewCustomTaxReliefForm: React.Dispatch<SetStateAction<boolean>>;
}

const NewCustomTaxReliefForm = ({
	employees,
	employeeBands,
	setShowNewCustomTaxReliefForm,
}: INewCustomTaxReliefForm) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const { mutate: createCustomTaxRelief } = useCreateCustomTaxRelief();
	const { addNewAccordionsData } = useManageAccordionsData();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		const optionalValues: any = {};
		if (employeeIds.length) optionalValues.employeeIds = employeeIds;
		if (employeeBandIds.length) optionalValues.employeeBandIds = employeeBandIds;

		createCustomTaxRelief(
			{
				payload: {
					...data,
					...optionalValues,
					companyId: currentCompany?.id as number,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewCustomTaxReliefForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for={`name`} />
					<input
						placeholder={"Enter Name"}
						type="text"
						{...register(`name`, {
							required: "Enter name here",
						})}
						id={`name`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.name && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.name?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="col-span-2 w-full">
					<Label title="Description" for={`description`} />
					<FormDescriptionInput 
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`
						}}
						register={register}
						required="Enter brief description here"
					/>
					{errors?.description && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.description?.message}
						</p>
					)}
				</div>
				<div className="w-full flex flex-col gap-2">
					<Label title="Assign Employees" for="employeeIds" optional />
					<MultiSelectField
						key={"employeeIds"}
						options={employees}
						setResourceIds={setEmployeeIds}
						placeholder={"Select Employees"}
						pathname={PATHS.EMPLOYEES}
						required={false}
						mapTexts={["firstName", "lastName"]}
					/>
				</div>
				<div className="w-full flex flex-col gap-2">
					<Label title="Assign Employee Bands" for="employeeBandIds" optional />
					<MultiSelectField
						key={"employeeBandIds"}
						options={employeeBands}
						setResourceIds={setEmployeeBandIds}
						placeholder={"Select Bands"}
						pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
						required={false}
					/>
				</div>
				<div className="w-full">
					<Label title="Amount" for="amount" />
					<MoneyInput 
						attributes={{
							id: "amount",
							placeholder: "0"
						}}
						register={register}
						name="amount"
						setValue={setValue}
						required="Enter amount here"
						error={errors.amount}
					/>
					{errors.amount && (
						<p className="text-red-500 text-sm mt-1">
							{errors.amount.message}
						</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active")}
						id="active"
					/>
					<label htmlFor="active">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewCustomTaxReliefForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Custom Tax Relief"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewCustomTaxReliefForm;
