import { useEffect, useState } from 'react'
import { AiOutlinePlus } from "react-icons/ai";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Columns } from "./data";
import CountriesModalForm from "./modal";
import useEditFormStore from '../../../../../state-management/useEditFormStore';
import DeleteModal from '../../../../../components/modals/DeleteModal';
import ActionPopover from '../../../../../components/popovers/actionPopover';
import { useDeleteCountry, useGetCountry, useGetCountrySearch } from '../../../../../services/country.service';
import { useGetCurrency } from '../../../../../services/currency.service';
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import { CountryDto } from '../../../../../models/country.dto';
import { APP_CONFIG } from '../../../../../helpers/appHelpers';
import { CurrencyOrderBy } from '../../../../../models/currency.dto';
import TableFilter from '../../../../../components/table/filter/TableFilter';
import APISearchInput from '../../../../../components/search/APISearchInput';

const Countries = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const { mutate: searchCountries } = useGetCountrySearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<CountryDto>(
		{ fetchHook: useGetCountry }
	);

	// Modal Form select fields API data source
	const { data: currencies } = useGetCurrency({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: CurrencyOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCountry } = useDeleteCountry();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCountry(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];
	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Countries</h2>
			<hr />
			<div className="flex justify-between items-center mt-7 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchCountries}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Active",
								fieldName: "active",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Country	
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<CountriesModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
							? "Update Country"
							: "Add New Country"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					currencies={currencies?.data || []}
				/>
			)}
		</div>
	);
};

export default Countries