import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "priceAmount",
		headerName: "Price",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.value;
			const currency = params.row.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "netAmount",
		headerName: "Net Price",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.value;
			const currency = params.row.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "maxUsers",
		headerName: "Max Users",
		width: 160,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxCompanies",
		headerName: "Max Companies",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "maxEmployees",
		headerName: "Max Employees",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "billingPeriod",
		headerName: "Billing Period",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "plan",
		headerName: "Plan",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "promotion",
		headerName: "Promotion",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.row.promotion ?
						<p>
							{params.row.promotion.title}
						</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
