import { useState } from "react";
import EditGradeLevel from "./EditGradeLevel";
import NewGradeLevel from "./NewGradeLevel";
import { AiOutlinePlus } from "react-icons/ai";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { GradeLevelDto } from "../../../../../models/grade-level.dto";
import { useGetGradeLevel } from "../../../../../services/grade-level.service";
import SkewLoader from "react-spinners/SkewLoader";
import { useGetCompanyLevel } from "../../../../../services/company-level.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { CompanyLevelOrderBy } from "../../../../../models/company-level.dto";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const GradeLevels = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewGradeLevelForm, setShowNewGradeLevelForm] = useState(false);
	
	const {
		accordionsData: gradeLevels,
		isLoading,
		pageCount,
    } = useManageAccordions<GradeLevelDto>({
		fetchHook: useGetGradeLevel,
		options: { 
			companyId: currentCompany?.id,
			orderBy: undefined
		}
	});

	const { data: companyLevels } = useGetCompanyLevel({
        companyId: currentCompany?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CompanyLevelOrderBy.LEVEL_NAME_ASC,
    });
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Grade Levels</h2>
				{!showNewGradeLevelForm && (
					<button
						onClick={() => setShowNewGradeLevelForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Grade Level
					</button>
				)}
			</div>
			
			{showNewGradeLevelForm && (
				<NewGradeLevel 
					companyLevels={companyLevels?.data || []}
					setShowNewGradeLevelForm={setShowNewGradeLevelForm} 
				/>
			)}

			{(isLoading && !gradeLevels.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{gradeLevels.map((gradeLevel) => (
				<EditGradeLevel 
					key={gradeLevel.id}
					gradeLevel={gradeLevel} 
					companyLevels={companyLevels?.data || []}
				/>
			))}

			{(!isLoading && !gradeLevels.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no grade levels added yet. Click 'Add New Grade Level' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default GradeLevels;
