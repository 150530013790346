import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CompanyGroupingDto, CompanyGroupingPaginator,
    CreateCompanyGroupingDto,
    QueryCompanyGroupingDto,
    UpdateCompanyGroupingDto
} from "../models/company-grouping.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetCompanyGrouping(options?: QueryCompanyGroupingDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.GET;
    const request = (options: QueryCompanyGroupingDto) => API.ProcessingCompanyAPI.getCompanyGrouping(options);

	const { data, isLoading, error, refetch } = useQuery<CompanyGroupingPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetCompanyGroupingById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.DETAIL
    const request = API.ProcessingCompanyAPI.getCompanyGroupingById(id);

    const {data, isLoading, error} = useQuery<{ data: CompanyGroupingDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetCompanyGroupingByTypeAndCompanyId(companyId: number, type: string) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.BY_TYPE
    const request = API.ProcessingCompanyAPI.getCompanyGroupingByTypeAndCompanyId(companyId, type);

    const {data, isLoading, error} = useQuery<CompanyGroupingDto[], Error>(
        [url, companyId, type],
        (() => request),
        {
            enabled: !!(companyId),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useCreateCompanyGrouping() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: { payload: CreateCompanyGroupingDto }) => API.ProcessingCompanyAPI.createCompanyGrouping(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.GET);
            },
        }
    )
}

export function useUpdateCompanyGrouping() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {
            payload: UpdateCompanyGroupingDto,
            id: number
        }) => API.ProcessingCompanyAPI.updateCompanyGrouping(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.GET);
            },
        }
    )
}

export function useDeleteCompanyGrouping() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteCompanyGroupingById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}