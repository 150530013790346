import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    CreateAnnouncementDto,
    AnnouncementDto,
    QueryAnnouncementDto,
    AnnouncementPaginator,
    SearchAnnouncementDto,
    UpdateAnnouncementDto,
    UpdateAnnouncementResourceDto,
} from '../models/announcement.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetAnnouncement(options?: QueryAnnouncementDto) {
    const url = ENDPOINTS.HR.ANNOUNCEMENT.GET;
    const request = (options: QueryAnnouncementDto) => API.HrAPI.getAnnouncement(options);

    const { data, isLoading, error, refetch } = useQuery<AnnouncementPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetMyAnnouncement(options?: QueryAnnouncementDto) {
    const url = ENDPOINTS.HR.ANNOUNCEMENT.MY_GET;
    const request = (options: QueryAnnouncementDto) => API.HrAPI.getMyAnnouncement(options);

    const { data, isLoading, error, refetch } = useQuery<AnnouncementPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetAnnouncementBySearch() {

    return useMutation(
        (options?: SearchAnnouncementDto) => API.HrAPI.getAnnouncementSearch(options),
        {
            onSuccess: (response) => {
                
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetMyAnnouncementBySearch() {

    return useMutation(
        (options?: SearchAnnouncementDto) => API.HrAPI.getMyAnnouncementSearch(options),
        {
            onSuccess: (response) => {
                
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetAnnouncementById(id: number){
    const url = ENDPOINTS.HR.ANNOUNCEMENT.DETAIL;
    const request = API.HrAPI.getAnnouncementById(id);

    const { data, isLoading, error } = useQuery<{ data: AnnouncementDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateAnnouncement() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateAnnouncementDto }) => API.HrAPI.createAnnoucement(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.ANNOUNCEMENT.GET);
            },
        },
    );
}

export function useUpdateAnnouncement() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateAnnouncementDto }) => API.HrAPI.updateAnnouncement(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.ANNOUNCEMENT.GET);
            },
        },
    );
}

export function useUpdateAnnouncementResource() {
    const queryClient = useQueryClient();

    return useMutation(
        (
            { id, resourceId, payload }: 
            { 
                id: number, resourceId: number, payload: UpdateAnnouncementResourceDto 
            }) => API.HrAPI.updateAnnouncementResource(id, resourceId, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.ANNOUNCEMENT.GET);
            },
        },
    );
}

export function useDeleteAnnouncement() {

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteAnnouncment(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}