import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    PermissionDto, PermissionPaginator,
    CreatePermissionDto, QueryPermissionDto,
    UpdatePermissionDto
} from "../models/permission.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetPermission(options?: QueryPermissionDto) {
    const url = ENDPOINTS.USER.PERMISSION.GET;
    const request = (options: QueryPermissionDto) => API.UserAPI.getPermission(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<PermissionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return API.Handlers.paginationHandler(data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage)
}

export function useGetPermissionById(code: string) {
    const url = ENDPOINTS.USER.PERMISSION.DETAIL
    const request = API.UserAPI.getPermissionById(code);

    const {data, isLoading, error} = useQuery<{ data: PermissionDto }, Error>(
        [url, code],
        (() => request),
        {
            enabled: !!(code),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreatePermission(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreatePermissionDto}) => API.UserAPI.createPermission(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.USER.PERMISSION.GET);
            },
        }
    )
}

export function useUpdatePermission() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, code}: {payload: UpdatePermissionDto, code: string}) => API.UserAPI.updatePermission(code, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.USER.PERMISSION.GET);
            },
        }
    )
}

