import { SubmitHandler, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { CreateTaxRateDto } from "../../../../../../models/tax-rate.dto";
import { APP_CONFIG, IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useCreateTaxRate, useUpdateTaxRate } from "../../../../../../services/tax-rate.service";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import { ActiveCountryContext } from "..";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { useGetBenefitTax } from "../../../../../../services/benefit-tax.service";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";

type IFormInputs = Omit<CreateTaxRateDto, "onDirectIncome"> & {
	onDirectIncome: string;
}

const RateTaxRuleModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const countries = useContext(ActiveCountryContext);
	const [onDirectIncome, setOnDirectIncome] = useState(false);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		if (isEditForm) setOnDirectIncome(formDataValues?.onDirectIncome);
	}, [formDataValues])

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			countryId: isEditForm ? formDataValues?.countryId : undefined,
			minTaxableAmount: isEditForm ? formDataValues?.minTaxableAmount : "",
			maxTaxableAmount: isEditForm ? formDataValues?.maxTaxableAmount : "",
			rateComponent: isEditForm ? formDataValues?.rateComponent : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			benefitTaxId: isEditForm ? formDataValues?.benefitTaxId : "",
			incomePeriod: isEditForm ? formDataValues?.incomePeriod : "",
			onDirectIncome: isEditForm ? (formDataValues?.onDirectIncome ? "yes" : "no") : "",
		}
	});

	const maxTaxableAmount = watch("maxTaxableAmount");
	const countryId = watch("countryId") || undefined;
	const { 
		data: benefitTaxRules, 
		refetch: refetchBenefitTax
	} = useGetBenefitTax(
		{
			countryId,
			limit: APP_CONFIG.PAGE_LIMIT,
			page: 1,
			orderBy: "createdAt:desc" as any,
		}, 
		{ enabled: false }
	);

	useEffect(() => {
		if (countryId) {
			refetchBenefitTax();
		}
	}, [countryId])

	const { mutate: createRateTaxRule } = useCreateTaxRate();
	const { mutate: updateRateTaxRule } = useUpdateTaxRate();
	const { addNewTableData } = useManageTableWithSearchParamsData();

    const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		data.onDirectIncome = data.onDirectIncome === "yes" ? true : false;
		data.benefitTaxId = data.benefitTaxId ? Number(data.benefitTaxId) : undefined;
		!data.benefitTaxId && delete data.benefitTaxId;
		!data.fixedComponent && delete data.fixedComponent;

		if (isEditForm) {
            delete data.countryId;
			updateRateTaxRule(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
            delete data.benefitTaxId;
			createRateTaxRule(
				{ payload: data },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

    return (
        <ModalComponent
            width={775}
            open={open}
            title={title}
            handleClose={handleClose}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Income Period" for="incomePeriod" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.incomePeriod
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("incomePeriod", {
									required: "Select income period here",
								})}
							>
								<option value="">Select Period</option>
								<option value="MONTH">Month</option>
								<option value="YEAR">Year</option>
							</select>
							{errors.incomePeriod && (
								<p className="text-red-500 text-sm mt-1">
									{errors.incomePeriod.message}
								</p>
							)}
						</div>
					</>)}
					<div className="w-full">
						<Label title="Min Taxable Amount" for="minTaxableAmount" />
						<MoneyInput 
							attributes={{
								id: "minTaxableAmount",
								placeholder: "0"
							}}
							register={register}
							name="minTaxableAmount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.minTaxableAmount : undefined}
							required="Enter minimum taxable amount"
							validate={(value: number) => {
								if (maxTaxableAmount && value > maxTaxableAmount) {
									return "Cannot be more than the max taxable value"
								}
								return true
							}}
							error={errors.minTaxableAmount}
						/>
						{errors.minTaxableAmount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minTaxableAmount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Max Taxable Amount" for="maxTaxableAmount" />
						<MoneyInput 
							attributes={{
								id: "maxTaxableAmount",
								placeholder: "0"
							}}
							register={register}
							name="maxTaxableAmount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.maxTaxableAmount : undefined}
							required="Enter maximum taxable amount"
							error={errors.maxTaxableAmount}
						/>
						{errors.maxTaxableAmount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxTaxableAmount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Rate Component" for="rateComponent" />
						<div className="flex items-center h-14 mt-2">
							<input
								defaultValue={
									isEditForm
										? formDataValues?.rateComponent
										: undefined
								}
								placeholder={"0"}
								type="number"
								{...register("rateComponent", {
									required: "Enter rate component",
									valueAsNumber: true,
								})}
								id="rateComponent"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.rateComponent
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
								%
							</div>
						</div>
						{errors.rateComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.rateComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Fixed Component" for="fixedComponent" optional />
						<MoneyInput 
							attributes={{
								id: "fixedComponent",
								placeholder: "0"
							}}
							register={register}
							name="fixedComponent"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.fixedComponent : undefined}
							required={false}
							error={errors.fixedComponent}
						/>
					</div>
					{isEditForm && (
						<div className="w-full">
							<Label title="Income Period" for="incomePeriod" />
							<select
								defaultValue={isEditForm ? formDataValues?.incomePeriod : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.incomePeriod
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("incomePeriod", {
									required: "Select income period here",
								})}
							>
								<option value="">Select Period</option>
								<option value="MONTH">Month</option>
								<option value="YEAR">Year</option>
							</select>
							{errors.incomePeriod && (
								<p className="text-red-500 text-sm mt-1">
									{errors.incomePeriod.message}
								</p>
							)}
						</div>
					)}
					<div className="col-span-2">
						<div className="flex space-x-10 text-base">
                            <Label title="Apply On Direct Income" for="onDirectIncome" />
							<div className="flex space-x-3 items-center">
								<input
									{...register("onDirectIncome", { required: true })}
									defaultChecked={isEditForm && formDataValues?.onDirectIncome === true}
									type="radio"
									value={"yes"}
									id="onDirectIncome"
									onChange={() => setOnDirectIncome(true)}
								/>
								<label htmlFor="onDirectIncome">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("onDirectIncome", { required: true })}
									defaultChecked={isEditForm && formDataValues?.onDirectIncome === false}
									type="radio"
									value={"no"}
									id="notDirectIncome"
									onChange={() => setOnDirectIncome(false)}
								/>
								<label htmlFor="notDirectIncome">No</label>
							</div>
						</div>
                        {errors.incomePeriod && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.incomePeriod.message}
                            </p>
                        )}
					</div>
					{(!onDirectIncome && countryId) && (
						<div className="w-full">
							<Label title="Benefit Tax" for="benefitTaxId" />
							<select
								defaultValue={isEditForm ? formDataValues?.benefitTaxId : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.benefitTaxId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("benefitTaxId", {
									required: "Select benefit tax here",
								})}
							>
								<option value="">
									Select ID
								</option>
								{benefitTaxRules?.data?.map((benefitTax) => (
									<option
										key={benefitTax.id}
										value={benefitTax.id}
									>
										{benefitTax.id}
									</option>
								))}
							</select>
							{errors.benefitTaxId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.benefitTaxId.message}
								</p>
							)}
						</div>
					)}
				</div>

                <div className="w-full flex justify-end mt-8">
                    <button
                        type="submit"
                        className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                    >
                        {`${
                            uploading
                                ? "Uploading..."
                                : isEditForm
                                ? "Update Rate Rule"
                                : "Add Rate Rule"
                        }`}
                    </button>
                </div>
            </form>
        </ModalComponent>

    );
}

export default RateTaxRuleModalForm;