import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { CountryDto } from "../../../../../../models/country.dto";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { CreatePaymentInstitutionDto } from "../../../../../../models/payment-institution.dto";
import { useCreatePaymentInstitution, useUpdatePaymentInstitution } from "../../../../../../services/payment-institution.service";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

type IFormInputs = Omit<CreatePaymentInstitutionDto, "active"> & {
	active: string;
};

interface PaymentInstitutionModalFormProps extends IAddEditTableForm {
	countries: CountryDto[];
}

const PaymentInstitutionModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries,
}: PaymentInstitutionModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			shortName: isEditForm ? formDataValues?.shortName : "",
			code: isEditForm ? formDataValues?.code : "",
			paymentChannelType: isEditForm ? formDataValues?.paymentChannelType : "",
			swiftCode: isEditForm ? formDataValues?.swiftCode : "",
		}
	});

	const { mutate: createPaymentInstitution } = useCreatePaymentInstitution();
	const { mutate: updatePaymentInstitution } = useUpdatePaymentInstitution();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

        const active = data.active === "yes" ? true : false;
		
		if (isEditForm) {
			updatePaymentInstitution(
				{ payload: { ...data, active }, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createPaymentInstitution(
				{ payload: { ...data, active } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Short Name" for="shortName" />
						<input
							placeholder={"Enter short name"}
							type="text"
							{...register("shortName", {
								required: "Enter short name here",
							})}
							id="shortName"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.shortName
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.shortName && (
							<p className="text-red-500 text-sm mt-1">{errors.shortName.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Payment Channel Type" for="paymentChannelType" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.paymentChannelType
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("paymentChannelType", {
								required: "Select payment channel type here",
							})}
						>
							<option value="">Select Type</option>
							<option value="WALLET">Wallet</option>
							<option value="BANK">Bank</option>
							{/* <option value="OTHER">Other</option> */}
						</select>
						{errors.paymentChannelType && (
							<p className="text-red-500 text-sm mt-1">
								{errors.paymentChannelType.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					)}
					<div className={`w-full ${isEditForm && "col-span-2"}`}>
						<Label title="Swift Code" for="swiftCode" optional />
						<input
							placeholder={"Enter swift code"}
							type="text"
							{...register("swiftCode", {
								required: false,
							})}
							id="swiftCode"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
						/>
					</div>
					<div className="col-span-2">
						<div className="flex space-x-9 font-light">
							<h3>Active</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === true}
									type="radio"
									value={"yes"}
									id="active"
								/>
								<label htmlFor="active">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === false}
									type="radio"
									value={"no"}
									id="inactive"
								/>
								<label htmlFor="inactive">No</label>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Payment Institution"
								: "Add New Payment Institution"
						}`}
						className={`px-8`}
						type={"submit"}
						onClick={handleSubmit(onSubmit)}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default PaymentInstitutionModalForm;
