import {CountOrganizationDto, CreateOrUpdateOrganizationConfigDto, CreateOrganizationDto, OrganizationPaginator, OrganizationWithOptionalRelations, OrganizationWithOptionalRelationsPaginator, QueryOrganizationDto, SearchOrganizationDto, UpdateOrganizationDto} from "../../../models/organization.dto";
import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import { OrganizationConfig } from "../../../models/organization-config.dto";
import { CreateOrganizationPaymentMethodDto, OrganizationPaymentMethodDto, OrganizationPaymentMethodPaginator, QueryOrganizationPaymentMethodDto, UpdateOrganizationPaymentMethodDto } from "../../../models/organization-payment-method.dto";
import { CreateOrganizationPromotionDto, OrganizationPromotionDto, OrganizationPromotionPaginator, QueryOrganizationPromotionDto } from "../../../models/organization-promotion.dto";
import { QuerySubscriptionHistoryDto, SubscriptionHistoryDto, SubscriptionHistoryPaginator } from "../../../models/subscription-history.dto";

export class Organization {
    getOrganization = (query?: QueryOrganizationDto) => HttpClient
        .get<OrganizationWithOptionalRelationsPaginator>(ENDPOINTS.ORGANIZATION.GET, query)
    getOrganizationSearch = (query?: SearchOrganizationDto) => HttpClient
        .get<OrganizationWithOptionalRelationsPaginator>(ENDPOINTS.ORGANIZATION.SEARCH, query)
    create = (payload: CreateOrganizationDto) => HttpClient
        .post<any>(ENDPOINTS.ORGANIZATION.CREATE, payload);
    update = (organizationId: number, payload: UpdateOrganizationDto) => HttpClient
        .patch<{ data: OrganizationWithOptionalRelations}>(ENDPOINTS.ORGANIZATION.UPDATE
            .replace('{organizationId}', String(organizationId)), payload);
    updateBillingTypeId = (organizationId: number, payload: UpdateOrganizationDto) => HttpClient
        .put<any>(ENDPOINTS.ORGANIZATION.UPDATE_BILLING_TYPE
            .replace('{organizationId}', String(organizationId)), payload);
    getOrganizationCount = (query?: CountOrganizationDto) => HttpClient
            .get<{ data: { count: number} }>(ENDPOINTS.ORGANIZATION.GET_COUNT, query);

    getOrganizationConfigById = (organizationId: string) => HttpClient
        .get<{ data: OrganizationConfig }>(ENDPOINTS.ORGANIZATION.ORGANIZATION_CONFIG.DETAIL
            .replace('{organizationId}', String(organizationId)));
    createOrUpdateOrganizationConfig = (payload: CreateOrUpdateOrganizationConfigDto) => HttpClient
        .post<{ data: OrganizationConfig }>(ENDPOINTS.ORGANIZATION.ORGANIZATION_CONFIG.CREATE_OR_UPDATE, payload);

    getOrganizationPaymentMethod = (query?: QueryOrganizationPaymentMethodDto) => HttpClient
        .get<OrganizationPaymentMethodPaginator>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PAYMENT_METHOD.GET, query)
    getOrganizationPaymentMethodById = (orgPaymentMethodId?: number) => HttpClient
        .get<{ data: OrganizationPaymentMethodDto}>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PAYMENT_METHOD.DETAIL.
            replace('{orgPaymentMethodId}', String(orgPaymentMethodId)))
    createOrganizationPaymentMethod = (payload: CreateOrganizationPaymentMethodDto) => HttpClient
        .post<{ data: OrganizationPaymentMethodDto}>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PAYMENT_METHOD.CREATE, payload);
    updateOrganizationPaymentMethod = (orgPaymentMethodId: number, payload: UpdateOrganizationPaymentMethodDto) => HttpClient
        .patch<any>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PAYMENT_METHOD.UPDATE
            .replace('{orgPaymentMethodId}', String(orgPaymentMethodId)), payload);

    getOrganizationPromotion = (query?: QueryOrganizationPromotionDto) => HttpClient
        .get<OrganizationPromotionPaginator>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PROMOTION.GET, query)
    getOrganizationPromotionById = (orgPromotionId?: number) => HttpClient
        .get<{ data: OrganizationPromotionDto}>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PROMOTION.DETAIL.
            replace('{orgPromotionId}', String(orgPromotionId)))
    createOrganizationPromotion = (payload: CreateOrganizationPromotionDto) => HttpClient
        .post<{ data: OrganizationPromotionDto}>(ENDPOINTS.ORGANIZATION.ORGANIZATION_PROMOTION.CREATE, payload);

    getSubscriptionHistory = (query?: QuerySubscriptionHistoryDto) => HttpClient
        .get<SubscriptionHistoryPaginator>(ENDPOINTS.ORGANIZATION.SUBSCRIPTION_HISTORY.GET, query)
    getSubscriptionHistoryById = (subscriptionHistoyId?: number) => HttpClient
        .get<{ data: SubscriptionHistoryDto}>(ENDPOINTS.ORGANIZATION.SUBSCRIPTION_HISTORY.DETAIL.
            replace('{subscriptionHistoyId}', String(subscriptionHistoyId)))

}