import { ReactNode, createContext } from "react";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import useCompanyStore from "../../../state-management/useCompanyStore";
import { useGetCompanyGrouping } from "../../../services/company-grouping.service";
import { useGetGradeLevel } from "../../../services/grade-level.service";
import { useGetCompanyCurrencyConfig } from "../../../services/company-currency.service";
import { useGetCostCenter } from "../../../services/cost-center.service";
import { useGetJobTitle } from "../../../services/job-title.service";
import { useGetNationality } from "../../../services/nationality.service";
import { useGetPaymentInstitution } from "../../../services/payment-institution.service";
import { useGetDepartments } from "../../../services/department.service";

export const EmployeeFormContext = createContext<any>(null);

const EmployeeFormContextWrapper = ({ children }: { children: ReactNode }) => {
	const { currentCompany } = useCompanyStore();

	const defaultConfig = {
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "name:asc" as any,
	}
    
	const { data: nationalities } = useGetNationality(defaultConfig);
	const { data: paymentInstitutions } = useGetPaymentInstitution({
		active: true,
		...defaultConfig,
	});
	const { data: MajorGradeLevelData } = useGetGradeLevel({
		companyId: currentCompany?.id,
		type: "MAJOR",
		...defaultConfig,
	});
	const { data: MinorGradeLevelData } = useGetGradeLevel({
		companyId: currentCompany?.id,
		type: "MINOR",
		...defaultConfig,
	});
	const { data: RegionData } = useGetCompanyGrouping({
		type: "REGION",
		companyId: currentCompany?.id,
		...defaultConfig,
	});
	const { data: TribeData } = useGetCompanyGrouping({
		type: "TRIBE",
		companyId: currentCompany?.id,
		...defaultConfig,
	});
	const { data: DepartmentData } = useGetDepartments(
		currentCompany?.id as number, defaultConfig
	);
	const { data: DivisionData } = useGetCompanyGrouping({
		type: "DIVISION",
		companyId: currentCompany?.id,
		...defaultConfig,
	});
	const { data: StationData } = useGetCompanyGrouping({
		type: "STATION",
		companyId: currentCompany?.id,
		...defaultConfig,
	});
	const { data: companyCurrencies } = useGetCompanyCurrencyConfig({
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
	});
	const { data: jobTitles } = useGetJobTitle({
		companyId: currentCompany?.id,
		...defaultConfig,
	});
	const { data: CostCenterData } = useGetCostCenter({
		companyId: currentCompany?.id,
		...defaultConfig,
	});
    
    return (
        <EmployeeFormContext.Provider value={{
            nationalities: nationalities?.data || [],
            MajorGradeLevelData: MajorGradeLevelData?.data || [],
            MinorGradeLevelData: MinorGradeLevelData?.data || [],
            RegionData: RegionData?.data || [],
            TribeData: TribeData?.data || [],
            companyCurrencies: companyCurrencies?.data || [],
            jobTitles: jobTitles?.data || [],
            CostCenterData: CostCenterData?.data || [],
            DepartmentData: DepartmentData?.data || [],
            DivisionData: DivisionData?.data || [],
            StationData: StationData?.data || [],
            paymentInstitutions: paymentInstitutions?.data || [],
        }}>
            {children}
        </EmployeeFormContext.Provider>
    );
}
 
export default EmployeeFormContextWrapper;