import { EmployeeDto } from "./employee.dto";
import { PaginatorInfo } from "./generic.dto";
import { PayPeriodDto } from "./pay-period.dto";

type Loan = {
  id: number;
  employeeId: number;
  repaymentType: REPAYMENT_TYPE;
  amount: number;
  interestRate: number;
  numberOfMonths: number;
  initialPayment: number | null;
  finalPayment: number | null;
  applicablePayPeriodCount: number;
  repaymentAmount: number | null;
  startPayPeriodId: number;
  description: string;
  createdAt: Date;
  modifiedAt: Date | null;
}

type LoanRepaymentInfo = {
  id: number;
  loanId: number;
  payPeriodId: number;
  amount: number;
  createdAt: Date;
}

export enum REPAYMENT_TYPE {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export class CreateLoanDto {
  employeeId!: number
  repaymentType!: REPAYMENT_TYPE
  amount!:number
  interestRate!: number
  numberOfMonths!: number
  initialPayment?: number
  finalPayment?: number
  applicablePayPeriodCount!: number
  repaymentAmount?: number
  startPayPeriodId!: number
  repaymentDetails?: RepaymentItems[]
  description!: string
}

export class RepaymentItems {
  payPeriodId!: number
  amount!: number
}

export class CreateLoanRepaymentInfoDto {
  loanId!: number
  payPeriodId!: number
  amount!: number

  constructor(loanId: number, payPeriodId: number, amount: number) {
    this.loanId = loanId;
    this.payPeriodId = payPeriodId;
    this.amount = amount;
  }
}

export interface LoanDto extends Loan {
  loanRepaymentInfoDto?: LoanRepaymentInfoDto[]
  startPayPeriod?: PayPeriodDto;
  employee?: EmployeeDto | null;

}

export interface LoanRepaymentInfoDto extends LoanRepaymentInfo {
  payPeriod?: PayPeriodDto
}

export interface LoanPaginator extends PaginatorInfo<LoanDto> {}


export class UpdateLoanDto {
  repaymentType?: REPAYMENT_TYPE
  amount?: number
  interestRate?: number
  numberOfMonths?: number
  initialPayment?: number
  finalPayment?: number
  applicablePayPeriodCount?: number
  repaymentAmount?: number
  startPayPeriodId?: number
  addRepaymentDetails?: RepaymentItems[]
  removeRepaymentDetails?: RepaymentItems[]
  description?: string
}


export class QueryLoanDto {
  employeeId?: number
  repaymentType?: REPAYMENT_TYPE
  startPayPeriodId?: number
  companyId?: number
  page: number = 1;
  limit?: number;
  orderBy: LoanOrderBy = LoanOrderBy.CREATED_AT_ASC;
}


export enum LoanOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc'
}