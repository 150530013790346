import { createContext, useEffect, useState } from "react";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { PATHS } from "../../../routes/routes.paths";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import PersonalDetailsView from "./views/PersonalDetailsView";
import { profiileDetailsTabColumns } from "../../../components/custom_tab/tabColumns";
import EmployeeRelativesView from "./views/EmployeeRelativesView";
import PaymentInfo from "./views/PaymentInfoView";
import { useGetEmployeeById } from "../../../services/employee.service";
import { EmployeeDto } from "../../../models/employee.dto";
import SkewLoader from "react-spinners/SkewLoader";
import EmployeeUnionsView from "./views/EmployeeUnionsView";
import useUserStore from "../../../state-management/useUserStore";
import { useSearchParams } from "react-router-dom";
import { useGetPaymentInstitution } from "../../../services/payment-institution.service";
import { PaymentInstitutionDto } from "../../../models/payment-institution.dto";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import EmploymentDocumentsView from "./views/EmploymentDocumentsView";

export interface IEmployeeDataContext {
	employeeData: EmployeeDto | undefined;
	paymentInstitutions: PaymentInstitutionDto[];
	setEmployeeData: React.Dispatch<React.SetStateAction<EmployeeDto | undefined>>;
}

export const EmployeeDataContext = createContext<IEmployeeDataContext | null>(
	null
);

const SelfserviceProfileDetails = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [view, setView] = useState(
		searchParams.get("view") || "personalDetailsView"
	);
	const [currentComponent, setCurrentComponent] = useState<any>();
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [employeeData, setEmployeeData] = useState<EmployeeDto | undefined>();

	useEffect(() => {
		setSearchParams({ view: view });
		const component = components.find((item) => item.view === view);
		setCurrentComponent(component);
	}, [view]);

	const { data, isLoading } = useGetEmployeeById(companyProfile?.employeeId as number);
	const { data: paymentInstitutions } = useGetPaymentInstitution({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "name:asc" as any,
	});

	useEffect(() => {
		if (!isLoading) {
			setEmployeeData(data?.data);
		}
	}, [isLoading]);
	
	return (
		<SelfserviceLayout pageHeader="Profile Details">
			<Helmet>
				<title>Profile Details | Self Service Flow</title>
			</Helmet>
			<EmployeeDataContext.Provider value={{ 
				employeeData, 
				paymentInstitutions: paymentInstitutions?.data || [],
				setEmployeeData,
			}}>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={profiileDetailsTabColumns}
				>
					{employeeData ? (
						<>
							{currentComponent && (
								<section 
									key={employeeData.id} 
									className="grow mobile-full-width px-4 py-4 lg:p-8 bg-white overflow-y-auto"
								>
									<currentComponent.component />
								</section>
							)}
						</>
					) : (
						<div className="grow flex items-center justify-center">
							<SkewLoader color="#F58B00" />
						</div>
					)}
				</CustomTab>
			</EmployeeDataContext.Provider>
		</SelfserviceLayout>
	);
};

export default SelfserviceProfileDetails;

const components = [
	{
		view: "personalDetailsView",
		component: PersonalDetailsView,
	},
	{
		view: "employeeRelativesView",
		component: EmployeeRelativesView,
	},
	{
		view: "paymentInfoView",
		component: PaymentInfo,
	},
	{
		view: "unionMembershipView",
		component: EmployeeUnionsView,
	},
	{
		view: "employmentDocumentsView",
		component: EmploymentDocumentsView,
	},
];
