import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	IAddEditTableForm,
	compareDateFields,
	APP_CONFIG,
} from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import {
	useCreateTaxCode,
	useSetDefaultTaxCode,
	useUpdateTaxCode,
} from "../../../../../../services/tax-code.service";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import { CountryDto } from "../../../../../../models/country.dto";
import { useGetBenefitTax } from "../../../../../../services/benefit-tax.service";
import { useGetTaxRate } from "../../../../../../services/tax-rate.service";
import { useGetTaxRelief } from "../../../../../../services/tax-relief.service";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import MultiSelectField from "../../../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../../../routes/routes.paths";

interface IFormInputs {
	countryId: number
	startDate: string
	endDate: string
	enabled: string
	isDefault: string
	effectiveDate: string
	expiryDate: string
}

interface TaxCodeModalFormProps extends IAddEditTableForm {
	countries: CountryDto[];
}

const TaxCodeModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries,
}: TaxCodeModalFormProps) => {
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [defaultCode, setDefaultCode] = useState(false);

	useEffect(() => {
		if (isEditForm) setDefaultCode(formDataValues?.isDefault);
	}, [formDataValues])
	
	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			startDate: isEditForm ? formDataValues?.startDate.slice(0, 10) : "",
			endDate: isEditForm ? formDataValues?.endDate.slice(0, 10) : "",
			enabled: isEditForm ? (formDataValues?.enabled ? "yes" : "no") : "",
			isDefault: isEditForm ? (formDataValues?.isDefault ? "yes" : "no") : "",
			effectiveDate: (isEditForm && formDataValues?.isDefault) 
				? formDataValues?.effectiveDate.slice(0, 10) 
				: undefined,
			expiryDate: (isEditForm && formDataValues?.isDefault) 
				? formDataValues?.expiryDate.slice(0, 10) 
				: undefined,
		}
	});

	const countryId = watch("countryId") || undefined;
	const options = {
		countryId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "createdAt:desc" as any,
	};

	const { 
		data: taxRateRules, 
		refetch: refetchTaxRate 
	} = useGetTaxRate(options, { enabled: false });
	const { 
		data: benefitTaxRules, 
		refetch: refetchBenefitTax
	} = useGetBenefitTax(options, { enabled: false });
	const { 
		data: reliefTaxRules, 
		refetch: refetchTaxRelief
	} = useGetTaxRelief(options, { enabled: false });

	useEffect(() => {
		if (countryId) {
			refetchTaxRate();
			refetchBenefitTax();
			refetchTaxRelief();
		}
	}, [countryId])

	const [taxRateIds, setTaxRateIds] = useState<number[]>([]);
	const [benefitTaxIds, setBenefitTaxIds] = useState<number[]>([]);
	const [taxReliefIds, setTaxReliefIds] = useState<number[]>([]);

	const { mutate: createTaxCode } = useCreateTaxCode();
	const { mutate: updateTaxCode } = useUpdateTaxCode();
	const { mutate: setDefaultTaxCode } = useSetDefaultTaxCode();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		data.enabled = data.enabled === "yes" ? true : false;
		data.isDefault = data.isDefault === "yes" ? true : false;
		data.countryId = Number(data.countryId);

		if (isEditForm) {
			updateTaxCode(
				{
					payload: {
						enabled: data.enabled,
						startDate: data.startDate,
						endDate: data.endDate,
					}, 
					id: formDataValues?.id
				}, 
				{
					onSuccess: (updatedData) => {
						if (defaultCode) {
							setDefaultTaxCode(
								{
									payload: {
										effectiveDate: data.effectiveDate,
										expiryDate: data.expiryDate,
									}, 
									id: formDataValues?.id
								}, {
								onSuccess: (defaultTaxCodeData) => {
									tableAPIRef?.current.updateRows([defaultTaxCodeData.data]);
									setUploading(false);
									setOpen(false);
									reset();
								},
								onError: () => {
									setUploading(false);
								}
							})
						} else {
							tableAPIRef?.current.updateRows([updatedData.data]);
							setUploading(false);
							setOpen(false);
							reset();
						}
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createTaxCode(
				{
					payload: {
						...data,
						taxRateIds,
						benefitTaxIds,
						taxReliefIds,
					}
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Start Date" for="startDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.startDate.slice(0, 10) : ""
							}
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("startDate", {
								required: "Enter start date here",
							})}
							id="startDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.startDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.startDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Date" for="endDate" />
						<input
							defaultValue={
								isEditForm ? formDataValues?.endDate.slice(0, 10) : ""
							}
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("endDate", {
								required: "Enter end date here",
								validate: (value) => compareDateFields(watch("startDate"), value),
							})}
							id="endDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.endDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.endDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endDate.message}
							</p>
						)}
					</div>
					{(!isEditForm && countryId) && <>
						<div className="w-full">
							<Label title="Tax Rate Rules" for="taxRateIds" optional />
							<MultiSelectField
								key={"taxRateIds"}
								options={taxRateRules?.data || []}
								setResourceIds={setTaxRateIds}
								placeholder={"Select Options"}
								mapText="id"
								pathname={PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_RULES + "&taxRule=rate"}
								required={false}
							/>
						</div>
						<div className="w-full">
							<Label title="Benefit Tax Rules" for="benefitTaxIds" optional />
							<MultiSelectField
								key={"benefitTaxIds"}
								options={benefitTaxRules?.data || []}
								setResourceIds={setBenefitTaxIds}
								placeholder={"Select Options"}
								mapText="id"
								pathname={PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_RULES + "&taxRule=benefit"}
								required={false}
							/>
						</div>
						<div className="w-full">
							<Label title="Relief Tax Rules" for="taxReliefIds" optional />
							<MultiSelectField
								key={"taxReliefIds"}
								options={reliefTaxRules?.data || []}
								setResourceIds={setTaxReliefIds}
								placeholder={"Select Options"}
								mapText="id"
								pathname={PATHS.ADMIN_SUB_SYSTEM_CONFIGURATIONS.TAX_RULES + "&taxRule=relief"}
								required={false}
							/>
						</div>
					</>}
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Enabled</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("enabled")}
									defaultChecked={isEditForm && formDataValues?.enabled === true}
									type="radio"
									value={"yes"}
									id="enabled"
								/>
								<label htmlFor="enabled">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("enabled")}
									defaultChecked={isEditForm && formDataValues?.enabled === false}
									type="radio"
									value={"no"}
									id="inenabled"
								/>
								<label htmlFor="inenabled">No</label>
							</div>
						</div>
						{errors.enabled && (
							<p className="text-red-500 text-sm mt-1">
								{errors.enabled.message}
							</p>
						)}
					</div>
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Default</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("isDefault")}
									defaultChecked={isEditForm && formDataValues?.isDefault === true}
									type="radio"
									value={"yes"}
									id="isDefault"
									onChange={() => setDefaultCode(true)}
								/>
								<label htmlFor="isDefault">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("isDefault")}
									defaultChecked={isEditForm && formDataValues?.isDefault === false}
									type="radio"
									value={"no"}
									id="notDefault"
									onChange={() => setDefaultCode(false)}
								/>
								<label htmlFor="notDefault">No</label>
							</div>
						</div>
					</div>
					{defaultCode &&
					<>
						<div className="w-full">
							<Label title="Effective Date" for="effectiveDate" />
							<input
								defaultValue={
									isEditForm ? (formDataValues?.effectiveDate && formDataValues?.effectiveDate.slice(0, 10)) : ""
								}
								placeholder={"mm/dd/yyyy"}
								type="date"
								{...register("effectiveDate", {
									required: "Enter effective date here",
								})}
								id="effectiveDate"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.effectiveDate
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
						</div>
						<div className="w-full">
							<Label title="Expiry Date" for="expiryDate" />
							<input
								defaultValue={
									isEditForm ? (formDataValues?.expiryDate && formDataValues?.expiryDate.slice(0, 10)) : ""
								}
								placeholder={"mm/dd/yyyy"}
								type="date"
								{...register("expiryDate", {
									required: "Enter expiry date here",
								})}
								id="expiryDate"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.expiryDate
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
						</div>
					</>
					}
				</div>

				<div className="w-full flex justify-end mt-8">
					<button disabled={uploading} className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm">
						{`${
							uploading 
								? "Uploading..." 
								: isEditForm 
									? "Update Tax Code" 
									: "Add Tax Code"
						}`}
					</button>
				</div>
			</form>
		</ModalComponent>
	);
};

export default TaxCodeModalForm;
