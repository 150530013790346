import {PaginatorInfo} from "./generic.dto";
import {CostCenterDto} from "./cost-center.dto";
import { EmployeeBand } from "./employee-band.dto";
import { EmployeeBandBonus } from "./employee-band-bonus.dto";
import { PayPeriodDto } from "./pay-period.dto";
import { CompanyCurrencyConfigDto } from "./company-currency.dto";

export const EMPLOYMENT_PERIOD_UNIT = {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};

export type EMPLOYMENT_PERIOD_UNIT = (typeof EMPLOYMENT_PERIOD_UNIT)[keyof typeof EMPLOYMENT_PERIOD_UNIT]

export const PAYMENT_FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY',
    PRESET: 'PRESET'
};

export type PAYMENT_FREQUENCY = (typeof PAYMENT_FREQUENCY)[keyof typeof PAYMENT_FREQUENCY]

export type Bonus = {
    id: number
    companyId: number
    code: string
    description: string
    name: string
    fixedComponent: number
    rateComponent: number
    periodStartDate: string
    periodEndDate: string
    prorate: boolean
    minEmploymentPeriod: number
    employmentPeriodUnit: EMPLOYMENT_PERIOD_UNIT
    paymentFrequency: PAYMENT_FREQUENCY
    minValue: number
    maxValue: number | null
    active: boolean
    createdAt: string
    modifiedAt: string | null
    currencyId: number | null
}

export class CreateBonusDto {
    companyId!: number;
    code!: string;
    name!: string;
    description!: string;
    fixedComponent!: number;
    rateComponent!: number; // Decimal between 0.0 and 1.0 both inclusive
    prorate!: boolean;
    periodStartDate!: string;
    periodEndDate!: string;
    minEmploymentPeriod?: number;
    employmentPeriodUnit!: EMPLOYMENT_PERIOD_UNIT
    paymentFrequency!:PAYMENT_FREQUENCY
    minValue!: number;
    maxValue?: number;
    active!: boolean;
    currencyId?: number;
    employeeBandIds?: number[];
    payPeriodIds?: number[];
}

export class UpdateBonusDto {
    companyId?: number;
    code?: string;
    name?: string;
    description?: string;
    fixedComponent?: number;
    rateComponent?: number; // Decimal between 0.0 and 1.0 both inclusive
    prorate?: boolean;
    periodStartDate?: string;
    periodEndDate?: string;
    minEmploymentPeriod?: number;
    employmentPeriodUnit?: EMPLOYMENT_PERIOD_UNIT
    paymentFrequency?: PAYMENT_FREQUENCY
    minValue?: number;
    maxValue?: number;
    active?: boolean;
    currencyId?: number;
    addPayPeriodIds?: number[];
    removePayPeriodIds?: number[];
}

export type PresetBonusConfig = {
    id: number
    bonusId: number
    day: number | null
    month: number
    year: number | null
    active: boolean
    createdAt: string
    modifiedAt: string | null
}

export interface BonusDto extends Bonus {
    presetConfig?: PresetBonusConfig | null
    employeeBandBonuses?: EmployeeBandBonusDto[];
    companyCurrency?: CompanyCurrencyConfigDto;
    payPeriods?: PayPeriodDto[];
}

export interface EmployeeBandBonusDto extends EmployeeBandBonus {
    employeeBand?: EmployeeBand;
}

export class QueryBonusDto {
    companyId?: number;
    code?: string;
    periodStartDate?: string;
    'periodStartDate.gte'?: string;
    'periodStartDate.lte'?: string;
    periodEndDate?: string;
    'periodEndDate.gte'?: string;
    'periodEndDate.lte'?: string;
    paymentFrequency?: PAYMENT_FREQUENCY;
    active?: boolean;
    page?: number = 1;
    limit?: number;
    orderBy?: BonusOrderBy = BonusOrderBy.CREATED_AT_ASC;
}

export enum BonusOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    MIN_VALUE_ASC = 'minValue:asc',
    MIN_VALUE_DESC = 'minValue:desc',
    MAX_VALUE_ASC = 'maxValue:asc',
    MAX_VALUE_DESC = 'maxValue:desc',
    PERIOD_START_DATE_ASC = 'periodStartDate:asc',
    PERIOD_START_DATE_DESC = 'periodStartDate:desc',
    PERIOD_END_DATE_ASC = 'periodEndDate:asc',
    PERIOD_END_DATE_DESC = 'periodEndDate:desc',

}

export interface BonusPaginator extends PaginatorInfo<BonusDto> {}

export class SearchBonusDto {
    q?: string
    page: number = 1;
    limit?: number;
    orderBy: BonusOrderBy = BonusOrderBy.CREATED_AT_ASC;
  }