import SecureLS from 'secure-ls';

let ls: SecureLS;
const init = () => {
    ls = new SecureLS({
        encodingType: 'aes',
        encryptionSecret: process.env.SECRET_KEY,
    });
};

if (global?.localStorage) init();

function set(key: string, data: any) {
    ls.set(key, data);
}

function get(key: string) {
    return ls.get(key);
}

function remove(key: string) {
    ls.remove(key);
}

function removeAll() {
    ls.removeAll();
}

function clear() {
    ls.clear();
}

function getAllKeys() {
    return ls.getAllKeys();
}

export { set, get, clear, getAllKeys, remove, removeAll, ls };