import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { AiOutlinePlus } from "react-icons/ai";
import PartialSalaryModalForm from "../modals/PartialSalaryModalForm";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { PartialSalaryColumns } from "../data";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { PartialSalaryDto } from "../../../../models/partial-salary.dto";
import { useDeletePartialSalary, useGetPartialSalary } from "../../../../services/partial-salary.service";
import APISearchInput from "../../../../components/search/APISearchInput";

const PartialSalaryView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	// const { mutate: searchPartialSalaries } = useGetPartialSalarySearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<PartialSalaryDto>({
		fetchHook: useGetPartialSalary,
		options: {}
	});
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletePartialSalary } = useDeletePartialSalary();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletePartialSalary(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 200,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = PartialSalaryColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					{/* <APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchPartialSalaries}
					/> */}
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Employee",
								fieldName: "employeeId",
								fieldType: "SELECT_FIELD",
								options: employees?.data || [],
								mapTexts: ["firstName", "lastName"],
							},
							{
								title: "Start Pay Period",
								fieldName: "startPayPeriodId",
								fieldType: "SELECT_FIELD",
								options: payPeriods?.data || [],
								mapText: "code",
							},
							{
								title: "Expired",
								fieldName: "expired",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Partial Salary</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>

			{openModal && (
				<PartialSalaryModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Partial Salary" : "Create Partial Salary"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					payPeriods={payPeriods?.data || []}
					employees={employees?.data || []}
				/>
			)}
		</>
	);
};

export default PartialSalaryView;
