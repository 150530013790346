import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { BenefitsnAllowancesTabColumns } from "../../../components/custom_tab/tabColumns";
import { useSearchParams } from "react-router-dom";
import BenefitsView from "./views/BenefitsView";
import AllowancesView from "./views/AllowancesView";
import LoanBenefitsView from "./views/LoanBenefitsView";

const BenefitsAndAllowances = () => {
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "allowances";
	const [view, setView] = useState(currentView);

	return (
		<PayrollCompanyLayout>
			<Helmet>
				<title>Benefits & Allowances | Company Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Benefits & Allowances</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={BenefitsnAllowancesTabColumns}
			>
				{view === "allowances" && <AllowancesView />}
				{view === "benefits" && <BenefitsView />}
				{view === "loanBenefits" && <LoanBenefitsView />}
			</CustomTab>
		</PayrollCompanyLayout>
	);
};

export default BenefitsAndAllowances;
