import { createContext, useState } from "react";
import RateTaxRuleView from "./views/RateTaxRuleView";
import BenefitsTaxRuleView from "./views/BenefitsTaxRuleView";
import ReliefTaxRuleView from "./views/ReliefTaxRuleView";
import { CountryDto, CountryOrderBy } from "../../../../../models/country.dto";
import { useGetCountry } from "../../../../../services/country.service";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { useSearchParams } from "react-router-dom";
import { TaxRulesTabColumns } from "../../../../../components/custom_tab/tabColumns";

export const ActiveCountryContext = createContext<CountryDto[]>([]);

const TaxRules = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [rule, setRule] = useState(searchParams.get("taxRule") || "rate");
	
	const changeRule = (item: any) => {
		setRule(item.view);
        setSearchParams({
			view: searchParams.get("view") || "taxRules",
			taxRule: item.view,
		});
	};

	const { data: countries } = useGetCountry({ 
		active: true, 
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CountryOrderBy.NAME_ASC,
	});

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Tax Rules</h2>
			<hr className="mb-6" />
			<ActiveCountryContext.Provider value={countries?.data || []}>
				<div className="flex space-x-6 border-b overflow-auto children-scroll-bar whitespace-nowrap w-full">
					{TaxRulesTabColumns.map((item: any, key) => (
						<button
							key={key}
							onClick={() => changeRule(item)}
							className={`flex ${
								rule === item.view
									? "border-b-2 border-primary-mango text-charcoal"
									: "text-gray-400"
							} pb-3`}
						>
							{item.name}
						</button>
					))}
				</div>
				{rule === "rate" && <RateTaxRuleView />}
				{rule === "benefit" && <BenefitsTaxRuleView />}
				{rule === "relief" && <ReliefTaxRuleView />}
			</ActiveCountryContext.Provider>
		</div>
	);
};

export default TaxRules;
