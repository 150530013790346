import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import Label from "../../../../../../components/form_fields/Label";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { 
	useCreateEmployeeBand, 
	useUpdateEmployeeBand 
} from "../../../../../../services/employee-band.service";
import { CreateNotchesWithBandDto, NOTCH_TYPE, NotchDto, PAY_PERIOD } from "../../../../../../models/employee-band.dto";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { CompanyCurrencyConfigDto } from "../../../../../../models/company-currency.dto";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";

interface IFormInputs {
	name: string;
	level: number;
	description: string;
	minimumBasicPay: number;
	maximumBasicPay?: number;
	payPeriod: PAY_PERIOD;
	currencyId?: number;
	notch?: CreateNotchesWithBandDto;
	notchType?: NOTCH_TYPE;
	notchCode?: string;
	notchCount?: number;
	notchItems?: NotchDto[];
}

interface EmployeeBandModalFormProps extends IAddEditTableForm {
	currencies: CompanyCurrencyConfigDto[];
}

const EmployeeBandModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	currencies,
}: EmployeeBandModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [notchCount, setNotchCount] = useState(0);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			level: isEditForm ? formDataValues?.level : undefined,
			description: isEditForm ? formDataValues?.description : "",
			minimumBasicPay: isEditForm ? formDataValues?.minimumBasicPay : undefined,
			maximumBasicPay: isEditForm ? formDataValues?.maximumBasicPay : undefined,
			payPeriod: isEditForm ? formDataValues?.payPeriod : "",
			currencyId: isEditForm ? formDataValues?.currencyId : undefined,
		}
	});

	const notchType = watch("notchType");
	const minimumBasicPay = watch("minimumBasicPay");
	const maximumBasicPay = watch("maximumBasicPay");

	const getNotchValue = (index: number) => {
		return watch(`notchItems.${index}.amount`) || undefined
	};

	const { mutate: createEmployeeBand } = useCreateEmployeeBand();
	const { mutate: updateEmployeeBand } = useUpdateEmployeeBand();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateEmployeeBand(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			switch(data.notchType) {
				case "EVENLY_SPREAD":
					data.notch = {
						notchType: data.notchType,
						code: data.notchCode as string,
						count: data.notchCount as number,
					}
					break;
				case "FIXED":
					data.notch = {
						notchType: data.notchType,
						items: notchCount ? (data.notchItems?.slice(0, notchCount) || []) : []
					}
					break;
			}
			delete data.notchCode;
			delete data.notchCount;
			delete data.notchType;
			delete data.notchItems;

			createEmployeeBand(
				{ payload: { ...data, companyId: currentCompany?.id as number } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={730}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Level" for="level" />
						<input
							defaultValue={isEditForm ? formDataValues?.level : ""}
							placeholder={"Enter level"}
							type="number"
							{...register("level", {
								required: "Enter level here",
								valueAsNumber: true,
							})}
							id="level"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.level
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.level && (
							<p className="text-red-500 text-sm mt-1">{errors.level.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Basic Pay" for="minimumBasicPay" />
						<MoneyInput 
							attributes={{
								id: "minimumBasicPay",
								placeholder: "0"
							}}
							register={register}
							name="minimumBasicPay"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.minimumBasicPay : undefined}
							required="Enter minimum basic pay here"
							validate={(value: number) => {
								if (maximumBasicPay && value > maximumBasicPay) {
									return "Cannot be more than the maximum basic pay"
								}
								return true
							}}
							error={errors.minimumBasicPay}
						/>
						{errors.minimumBasicPay && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minimumBasicPay.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Basic Pay" for="maximumBasicPay" optional />
						<MoneyInput 
							attributes={{
								id: "maximumBasicPay",
								placeholder: "0"
							}}
							register={register}
							name="maximumBasicPay"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.maximumBasicPay : undefined}
							required={false}
							error={errors.maximumBasicPay}
						/>
					</div>
					<div className="w-full">
						<Label title="Pay Period" for="payPeriod" />
						<select
							defaultValue={isEditForm ? formDataValues?.payPeriod : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.payPeriod
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("payPeriod", {
								required: "Select pay period here",
							})}
						>
							<option value="">Select Pay Period</option>
							<option value="HOUR">Hour</option>
							<option value="DAY">Day</option>
							<option value="WEEK">Week</option>
							<option value="MONTH">Month</option>
							<option value="QUARTER">Quater</option>
							<option value="YEAR">Year</option>
						</select>
						{errors.payPeriod && (
							<p className="text-red-500 text-sm mt-1">
								{errors.payPeriod.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency" for="currencyId" optional />
						<select
							defaultValue={isEditForm ? formDataValues?.currencyId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
							{...register("currencyId", {
								required: false,
								valueAsNumber: true,
							})}
						>
							<option value="">Select Currency</option>
							{currencies.map((currency) => (
								<option 
									key={currency.id} 
									value={currency.id}
								>
									{currency?.currency?.name}
								</option>
							))}
						</select>
					</div>
				</div>
				{!isEditForm && (
					<div className="mt-6">
						<h3 className="font-semibold text-base flex items-center gap-1.5">
							<span>Set Band Notch </span>
							<i> (optional)</i>
						</h3>
						<div className="flex flex-col mt-6">
							<div className="flex space-x-3 items-center mb-6">
								<input
									type="radio"
									value="EVENLY_SPREAD"
									{...register("notchType", {
										required: notchCount ? "Select an option" : false,
									})}
									id="EVENLY_SPREAD"
								/>
								<label htmlFor="EVENLY_SPREAD">
									Spread Evenly between Starting and Final Salary
								</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									type="radio"
									value="FIXED"
									{...register("notchType", {
										required: notchCount ? "Select an option" : false,
									})}
									id="FIXED"
								/>
								<label htmlFor="FIXED">
									Fixed Amount for each notch
								</label>
							</div>
							{errors.notchType && (
								<p className="text-red-500 text-sm mt-1">{errors.notchType.message}</p>
							)}
						</div>
						{notchType === "EVENLY_SPREAD" && (
							<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
								<div className="w-full">
									<Label title="Number of Notches" for="notchCount" />
									<input
										placeholder={"Enter value"}
										type="number"
										{...register("notchCount", {
											required: notchType ? "Enter number of notches here" : false,
											valueAsNumber: true,
											validate: (value) => {
												if (value && value > 0) return true;
												return "Value must be greater than 0"
											}
										})}
										id="notchCount"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.notchCount
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.notchCount && (
										<p className="text-red-500 text-sm mt-1">{errors.notchCount.message}</p>
									)}
								</div>
								<div className="w-full">
									<Label title="Unique Code" for="notchCode" />
									<input
										placeholder={"Enter code"}
										type="string"
										{...register("notchCode", {
											required: "Enter code here",
										})}
										id="notchCode"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.notchCode
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									{errors.notchCode && (
										<p className="text-red-500 text-sm mt-1">{errors.notchCode.message}</p>
									)}
								</div>
							</div>
						)}

						{notchType === "FIXED" && (
						<>
							{Array.from({ length: notchCount || 0 }).map((_, index) => (
								<div key={index} className="flex items-center gap-4 mt-6">
									<h6 className="font-bold whitespace-nowrap text-sm flex flex-col gap-3">
										<p>Notch {index + 1}</p>
										{(index + 1 === notchCount) && (
											<button 
												type="button"
												onClick={() => setNotchCount(prev => prev - 1)}
											>
												<BsTrash className="text-xl hover:scale-[1.1]" />
											</button>
										)}
									</h6>
									<div className="w-full">
										<input
											placeholder={"Enter code"}
											type="string"
											{...register(`notchItems.${index}.code`, {
												required: "Enter code here",
											})}
											id={`notchItems.${index}.code`}
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
												errors.notchItems && errors.notchItems[index]?.code
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
											}`}
										/>
										{errors.notchItems && errors.notchItems[index]?.code && (
											<p className="text-red-500 text-sm mt-1">
												{errors.notchItems[index]?.code?.message}
											</p>
										)}
									</div>
									<div className="w-full">
										<MoneyInput 
											attributes={{
												id: `notchItems.${index}.amount`,
												placeholder: "Enter amount"
											}}
											register={register}
											name={`notchItems.${index}.amount`}
											setValue={setValue}
											required={"Enter amount here"}
											defaultValue={getNotchValue(index)}
											validate={(value: number) => {
												if (value < minimumBasicPay) {
													return "Cannot be less than the minimum basic pay"
												}
												if (maximumBasicPay && value > maximumBasicPay) {
													return "Cannot be more than the maximum basic pay"
												}
												return true
											}}
											error={errors.notchItems && errors.notchItems[index]?.amount}
										/>
										{errors.notchItems && errors.notchItems[index]?.amount && (
											<p className="text-red-500 text-sm mt-1">
												{errors.notchItems[index]?.amount?.message}
											</p>
										)}
									</div>
								</div>
							))}
							<div
								className="w-fit flex items-center text-primary-blue cursor-pointer mt-6"
								onClick={() => setNotchCount(prev => prev + 1)}
							>
								<AiOutlinePlus className="mr-1 text-base" />
								<span className="text-sm">Add Notch</span>
							</div>
						</>
						)}
					</div>
				)}
				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
									? "Update Employee Band"
									: "Add New Employee Band"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default EmployeeBandModalForm;
