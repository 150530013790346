import PhoneInput from "react-phone-input-2";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { PayrollCompanyDto } from "../../../../models/company-account.dto";
import Label from "../../../../components/form_fields/Label";

type ViewCompanyDetails = {
	open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    company: PayrollCompanyDto;
}

const ViewCompanyDetails = ({ open, setOpen, company }: ViewCompanyDetails) => {
    return (
		<ModalComponent
			width={900}
			open={open}
			title={company.name + " Details"}
			handleClose={() => setOpen(false)}
		>
            <div className="flex flex-col mt-5">
                <img
                    alt={company.name}
                    src={company.logoUrl || ""}
                    className="border border-gray-300 rounded-lg w-44 h-44 object-cover"
                />
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-8">
                <div className="w-full">
                    <Label title="Name" for="companyName"/>
                    <input
                        defaultValue={company?.name}
                        type="text"
                        id="companyName"
                        className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
                        disabled
                    />
                </div>
                <div className="w-full">
                    <Label title="Contact Email" for="companyEmail"/>
                    <input
                        defaultValue={company?.contactEmail}
                        type="text"
                        id="companyEmail"
                        className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
                        disabled
                    />
                </div>
                <div className="w-full">
                    <Label title="Phone" for="contactMsisdn"/>
                    <div className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none">
                        <PhoneInput
                            country={"gh"}
                            disabled
                            value={company?.contactMsisdn}
                            inputStyle={{
                                height: "56px",
                                border: "none",
                                backgroundColor: "#F2F2F4",
                                color: "black",
                                boxShadow: "none",
                                width: "95%",
                                margin: "0px 0px 0px 15px",
                                borderRadius: "10px",
                            }}
                            containerStyle={{
                                // marginTop: "8px",
                                height: "56px",
                                border: "none",
                                backgroundColor: "#F2F2F4",
                                boxShadow: "none",
                                borderRadius: "10px",
                            }}
                            buttonStyle={{
                                border: "none",
                                backgroundColor: "#F2F2F4",
                                boxShadow: "none",
                                padding: "0px 10px",
                            }}
                        />
                    </div>
                </div>
                <div className="w-full">
                    <Label title="Address" for="address"/>
                    <input
                        defaultValue={company?.address}
                        type="text"
                        id="address"
                        className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
                        disabled
                    />
                </div>
                <div className="w-full">
                    <Label
                        title="Approvals Required for Leave Requests"
                        for="leaveRequestApprovalsRequired"
                    />
                    <input
                        defaultValue={company?.leaveRequestApprovalsRequired}
                        type="text"
                        id="leaveRequestApprovalsRequired"
                        className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
                        disabled
                    />
                </div>
                <div className="w-full">
                    <Label
                        title="Approvals Required for Reimbursement Requests"
                        for="reimbursementRequestApprovalsRequired"
                    />
                    <input
                        defaultValue={company?.reimbursementRequestApprovalsRequired}
                        type="text"
                        id="reimbursementRequestApprovalsRequired"
                        className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
                        disabled
                    />
                </div>
                <div className="flex flex-col w-full">
                    <Label
                        title="Work Hours In A Day"
                        for="workHoursInADay"
                    />
                    <div className="flex items-center mt-2 h-14">
                        <input
                            defaultValue={company?.workHoursInADay || undefined}
                            type="text"
                            id="workHoursInADay"
                            className="w-full bg-secondary-gray rounded-lg h-full px-5 text-sm border-none"
                            disabled
                        />
                        <div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
                            Hours
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex flex-col gap-6">
                <div className="flex space-x-3 items-center">
                    <input 
                        type="checkbox"
                        defaultChecked={company?.enableEmployeeLogin === true}
                        id="enableEmployeeLogin"
                    />
                    <label htmlFor="enableEmployeeLogin">Enable Employee Accounts</label>
                </div>
                <div className="flex space-x-3 items-center">
                    <input 
                        type="checkbox"
                        defaultChecked={company?.considerPublicHolidayAsWorkday === true}
                        id="considerPublicHolidayAsWorkday"
                    />
                    <label htmlFor="considerPublicHolidayAsWorkday">Consider Public Holiday As Workday</label>
                </div>
                <div className="flex space-x-3 items-center">
                    <input 
                        type="checkbox"
                        defaultChecked={company?.considerWeekendAsWorkday === true}
                        id="considerWeekendAsWorkday"
                    />
                    <label htmlFor="considerWeekendAsWorkday">Consider Weekend As Workday</label>
                </div>
                <div className="flex space-x-3 items-center">
                    <input 
                        type="checkbox"
                        defaultChecked={company?.allowNegativeRates === true}
                        id="allowNegativeRates"
                    />
                    <label htmlFor="allowNegativeRates">Allow Negative Rates</label>
                </div>
            </div>
		</ModalComponent>
    );
}
 
export default ViewCompanyDetails;