import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BonusesColumns } from "../data";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import { BonusDto, PAYMENT_FREQUENCY } from "../../../../models/bonus.dto";
import { AiOutlinePlus } from "react-icons/ai";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useDeleteBonus, useGetBonus, useGetBonusSearch } from "../../../../services/bonus.service";
import BonusModalForm from "../modals/BonusModalForm";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetCompanyCurrencyConfig } from "../../../../services/company-currency.service";
import APISearchInput from "../../../../components/search/APISearchInput";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";

const BonusView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const { formDataValues, setFormData } = useEditFormStore();

	const { mutate: searchBonuses } = useGetBonusSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<BonusDto>({
		fetchHook: useGetBonus,
		options: { companyId: currentCompany?.id }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: companyCurrencies } = useGetCompanyCurrencyConfig({
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "name:asc" as any,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteBonus } = useDeleteBonus();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteBonus(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = BonusesColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div className="font-inter">
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchBonuses}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Min Value",
										fieldName: "minValue",
									},
									{
										title: "Max Value",
										fieldName: "maxValue",
									},
									{
										title: "Period Start Date",
										fieldName: "periodStartDate",
									},
									{
										title: "Period End Date",
										fieldName: "periodEndDate",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Payment Frequency",
								fieldName: "paymentFrequency",
								fieldType: "CHECKBOX_LIST",
								options: PAYMENT_FREQUENCY,
							},
							{
								title: "Period Start Date",
								fieldName: "periodStartDate",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Period End Date",
								fieldName: "periodEndDate",
								fieldType: "DATE_RANGE",
							},
							{
								title: "Active",
								fieldName: "active",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Bonus Scheme</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<BonusModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Bonus" : "Add New Bonus"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					companyCurrencies={companyCurrencies?.data || []}
					employeeBands={employeeBands?.data || []}
					payPeriods={payPeriods?.data || []}
				/>
			)}
		</div>
	);
};

export default BonusView;
