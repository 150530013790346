import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useDeleteContractType, useUpdateContractType } from "../../../../../services/contract-type.service";
import { ContractTypeDto } from "../../../../../models/contract-type.dto";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	active: boolean;
	considerEmployee: boolean;
	fixedTerm: boolean;
	postRetirement: boolean;
	suspendOnExpiration: boolean;
}

interface IEditContractType {
	contractType: ContractTypeDto;
	setReloadData: React.Dispatch<React.SetStateAction<string>>;
}

const EditContractType = ({ contractType, setReloadData }: IEditContractType) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateContractType } = useUpdateContractType();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: contractType.name,
			code: contractType.code,
			description: contractType.description,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateContractType(
			{ payload: data, id: contractType.id },
			{
				onSuccess: () => {
					setReloadData("true");
					setExpanded(false);
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteContractType } = useDeleteContractType();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteContractType(
			{ id: contractType?.id },
			{
				onSuccess: () => {
					setReloadData("true");
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={contractType.id + "bh-content"}
				id={contractType.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{contractType.name}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
							<div className="w-full">
								<Label title="Name" for="name" />
								<input
									defaultValue={contractType.name}
									disabled={formDisabled}
									placeholder={"Enter name"}
									type="text"
									{...register("name", {
										required: "Enter name here",
									})}
									id="name"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.name && (
									<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: contractType.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full col-span-2">
								<Label title="Description" for="description" />
								<FormDescriptionInput 
									attributes={{
										defaultValue: contractType.description,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.description
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`
									}}
									register={register}
									required="Enter brief description here"
									disabled={formDisabled}
								/>
								{errors?.description && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.description?.message}
									</p>
								)}
							</div>
						</div>
						<div className="w-full mt-6 flex flex-col gap-6">
							<div className="flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={contractType.considerEmployee === true}
									{...register("considerEmployee", {
										required: false,
									})}
									id="considerEmployee"
									disabled={formDisabled}
								/>
								<label htmlFor="considerEmployee">Consider Employee</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={contractType.fixedTerm === true}
									{...register("fixedTerm", {
										required: false,
									})}
									id="fixedTerm"
									disabled={formDisabled}
								/>
								<label htmlFor="fixedTerm">Fixed Term</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={contractType.postRetirement === true}
									{...register("postRetirement", {
										required: false,
									})}
									id="postRetirement"
									disabled={formDisabled}
								/>
								<label htmlFor="postRetirement">Post Retirement</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={contractType.suspendOnExpiration === true}
									{...register("suspendOnExpiration", {
										required: false,
									})}
									id="suspendOnExpiration"
									disabled={formDisabled}
								/>
								<label htmlFor="suspendOnExpiration">Suspend On Expiration</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={contractType.active === true}
									{...register("active", {
										required: false,
									})}
									id="active"
									disabled={formDisabled}
								/>
								<label htmlFor="active">Active</label>
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={contractType.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditContractType;
