import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../../helpers/appHelpers";

export const Rows = [
	{
		id: 1,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 2,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 3,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 4,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 5,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 6,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 7,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 8,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 9,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
	{
		id: 10,
		payPeriod: "12/08/2022 - 12/09/2022",
		datePaid: "12/09/2022, 06:31",
		grossPay: "GHs 10,545.67",
		netPay: "GHs 9,545.67",
		pdfUrl: "https://pdfobject.com/pdf/sample.pdf",
	},
];

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "dateCompleted",
		headerName: "Date Paid",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "netAmount",
		headerName: "Net Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];
