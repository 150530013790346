import { useContext, useMemo } from "react";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { EmployeeOvertimeEntryDto } from "../../../../models/employee-overtime-entry.dto";
import { EmployeeSupervisees } from "../../../../routes";
import { useGetEmployeeWorkTime } from "../../../../services/employee-work-time.service";
import { EmployeeWorkTimeColumns, SuperviseeWorkTimeColumns,  } from "../data";
import { REQUEST_QUERY_MODE } from "../../../../models/leave-request.dto";
import { useViewPort } from "../../../../helpers/useViewPort";

type SelfserviceWorkTimeViewProps = {
	superviseesMode: REQUEST_QUERY_MODE;
}
const SelfserviceWorkTimeView = ({ superviseesMode }: SelfserviceWorkTimeViewProps) => {
	const viewPortWidth = useViewPort();
	const supervisees = useContext(EmployeeSupervisees);

	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<EmployeeOvertimeEntryDto>({
		fetchHook: useGetEmployeeWorkTime,
		options: { 
			queryMode: superviseesMode,
		},
		reloadDeps: [superviseesMode]
	});

	const tableColumns = useMemo(() => {
		if (superviseesMode === "SELF") {
			return EmployeeWorkTimeColumns;
		}
		return SuperviseeWorkTimeColumns;
	}, [])

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-4 lg:mb-8">
				<div className="flex items-center gap-3">
					{/* <APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={undefined as any}
					/> */}
					{superviseesMode === "SELF" ? (
						<TableFilter
							fields={[
								{
									title: "",
									fieldName: "",
									fieldType: "SORT",
									options: [
										{
											title: "Date Created",
											fieldName: "createdAt",
										},
										{
											title: "Date Modified",
											fieldName: "modifiedAt",
										},
									]
								},
								{
									title: "Time Unit",
									fieldName: "timeUnit",
									fieldType: "CHECKBOX_LIST",
									options: { HOUR: "HOUR", DAY: "DAY" }
								}
							]}
						/>
					):(
						<TableFilter
							fields={[
								{
									title: "",
									fieldName: "",
									fieldType: "SORT",
									options: [
										{
											title: "Date Created",
											fieldName: "createdAt",
										},
										{
											title: "Date Modified",
											fieldName: "modifiedAt",
										},
									]
								},
								{
									title: "Supervisee",
									fieldName: "employeeId",
									fieldType: "SELECT_FIELD",
									options: supervisees || [],
									mapTexts: ["firstName", "lastName"],
								},
								{
									title: "Time Unit",
									fieldName: "timeUnit",
									fieldType: "CHECKBOX_LIST",
									options: { HOUR: "HOUR", DAY: "DAY" }
								}
							]}
						/>
					)}
				</div>
			</div>
			<div className="mobile-full-width max-lg:pl-4">
				<CustomStyledTable
					rows={tableData}
					columns={tableColumns(viewPortWidth < 1024)}
					apiRef={tableAPIRef}
					loading={isLoading}
					pageCount={pageCount}
				/>
			</div>
		</>
	);
};

export default SelfserviceWorkTimeView;
