import {AuthTokenPair, LoginDto, LoginResponse, UpdateSelfDto} from "../../../models/auth.dto";
import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import {
    AccountCheckDto,
    AccountCheckResponse,
    ChangePasswordDto,
    CountUserDto,
    CreateUserDto,
    InitiatePasswordResetDto,
    ResendUserConfirmationDto,
    SearchUserDto,
    SetPasswordDto, UpdateUserDto,
    UserDto,
    UserPaginator
} from "../../../models/user.dto";
import {QueryOptionsDto} from "../../../models/generic.dto";
import {
    CreateOrganizationRoleDto,
    OrganizationRoleDto,
    OrganizationRolePaginator,
    QueryOrganizationRoleDto, UpdateOrganizationRoleDto
} from "../../../models/organization-role.dto";
import {
    CompanyRoleDto,
    CompanyRolePaginator,
    CreateCompanyRoleDto,
    QueryCompanyRoleDto, UpdateCompanyRoleDto
} from "../../../models/company-role.dto";
import {
    CreateUserCompanyProfileDto,
    QueryUserCompanyProfileDto, UpdateUserCompanyProfileDto,
    UserCompanyProfileDto,
    UserCompanyProfilePaginator
} from "../../../models/user-company-profile.dto";
import {
    CreatePermissionDto,
    PermissionDto,
    PermissionPaginator,
    QueryPermissionDto, UpdatePermissionDto
} from "../../../models/permission.dto";
import { CreatePermissionGroupDto, PermissionGroup, PermissionGroupDto, PermissionGroupPaginator, QueryPermissionGroupDto, UpdatePermissionGroupDto } from "../../../models/permission-group.dto";

import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

export class User {
    signIn = (payload: LoginDto) => HttpClient
        .post<LoginResponse>(ENDPOINTS.USER.AUTH.LOGIN, payload);

    refreshToken = (token: string) => axios
        .post(ENDPOINTS.USER.AUTH.REFRESH_TOKEN, {}, {headers: {Authorization: `Bearer ${token}`}});

    confirmToken = (payload: SetPasswordDto) => HttpClient
        .post<LoginResponse>(ENDPOINTS.USER.AUTH.CONFIRM, payload);

    resendConfirmation = (payload: ResendUserConfirmationDto) => HttpClient
        .post<{ data:UserDto }>(ENDPOINTS.USER.AUTH.RESEND, payload);

    forgotPassword = (payload: InitiatePasswordResetDto) => HttpClient
        .post<{ data:UserDto }>(ENDPOINTS.USER.AUTH.FORGOT_PASSWORD, payload);

    resetPassword = (payload: SetPasswordDto) => HttpClient
        .post<LoginResponse>(ENDPOINTS.USER.AUTH.PASSWORD, payload);

    getUser = (query?: QueryOptionsDto) => HttpClient
        .get<UserPaginator>(ENDPOINTS.USER.GET, query);
    getUserBySearch = (query?: SearchUserDto) => HttpClient
        .get<UserPaginator>(ENDPOINTS.USER.SEARCH, query);
    getUserById = (userId: string) => HttpClient
        .get<{ data:UserDto }>(ENDPOINTS.USER.DETAIL
            .replace('{userId}', String(userId)));
    getUserCount = (query?: CountUserDto) => HttpClient
       .get<{ data: { count: number} }>(ENDPOINTS.USER.GET_COUNT, query);

    createUser = (payload: CreateUserDto) => HttpClient
        .post<{ data:UserDto }>(ENDPOINTS.USER.CREATE, payload);
    updateUser = (userId: string, payload: UpdateUserDto) => HttpClient
        .patch<{ data:UserDto }>(ENDPOINTS.USER.UPDATE
            .replace('{userId}', String(userId)), payload);
    updateSelf = (payload: UpdateSelfDto) => HttpClient
        .patch<{ data:UserDto }>(ENDPOINTS.USER.UPDATE_SELF, payload);
    changePassword = (payload: ChangePasswordDto) => HttpClient
        .put<void>(ENDPOINTS.USER.CHANGE_PASSWORD, payload);
    accountCheck = (payload: AccountCheckDto) => HttpClient
        .get<{ data:AccountCheckResponse }>(ENDPOINTS.USER.ACCOUNT_CHECK, payload);



    getOrganizationRole = (query?: QueryOrganizationRoleDto) => HttpClient
        .get<OrganizationRolePaginator>(ENDPOINTS.USER.ORGANIZATION_ROLE.GET, query);
    getOrganizationRoleById = (roleId: number) => HttpClient
        .get<{ data:OrganizationRoleDto }>(ENDPOINTS.USER.ORGANIZATION_ROLE.DETAIL
            .replace('{roleId}', String(roleId)));
    createOrganizationRole = (payload: CreateOrganizationRoleDto) => HttpClient
        .post<{ data:OrganizationRoleDto }>(ENDPOINTS.USER.ORGANIZATION_ROLE.CREATE, payload);
    updateOrganizationRole = (roleId: string, payload: UpdateOrganizationRoleDto) => HttpClient
        .patch<{ data:OrganizationRoleDto }>(ENDPOINTS.USER.ORGANIZATION_ROLE.UPDATE
            .replace('{roleId}', String(roleId)), payload);

    getCompanyRole = (query?: QueryCompanyRoleDto) => HttpClient
        .get<CompanyRolePaginator>(ENDPOINTS.USER.COMPANY_ROLE.GET, query);
    getCompanyRoleById = (roleId: number) => HttpClient
        .get<{ data:CompanyRoleDto }>(ENDPOINTS.USER.COMPANY_ROLE.DETAIL
            .replace('{roleId}', String(roleId)));
    createCompanyRole = (payload: CreateCompanyRoleDto) => HttpClient
        .post<{ data:CompanyRoleDto }>(ENDPOINTS.USER.COMPANY_ROLE.CREATE, payload);
    updateCompanyRole = (roleId: number, payload: UpdateCompanyRoleDto) => HttpClient
        .patch<{ data:CompanyRoleDto }>(ENDPOINTS.USER.COMPANY_ROLE.UPDATE
            .replace('{roleId}', String(roleId)), payload);

    getUserCompanyProfile = (query?: QueryUserCompanyProfileDto) => HttpClient
        .get<UserCompanyProfilePaginator>(ENDPOINTS.USER.USER_COMPANY_PROFILE.GET, query);
    getUserCompanyProfileById = (profileId: number) => HttpClient
        .get<{ data:UserCompanyProfileDto }>(ENDPOINTS.USER.USER_COMPANY_PROFILE.DETAIL
            .replace('{profileId}', String(profileId)));
    createUserCompanyProfile = (payload: CreateUserCompanyProfileDto) => HttpClient
        .post<{ data:UserCompanyProfileDto }>(ENDPOINTS.USER.USER_COMPANY_PROFILE.CREATE, payload);
    updateUserCompanyProfile = (profileId: number, payload: UpdateUserCompanyProfileDto) => HttpClient
        .patch<{ data:UserCompanyProfileDto }>(ENDPOINTS.USER.USER_COMPANY_PROFILE.UPDATE
            .replace('{profileId}', String(profileId)), payload);

    getPermission = (query?: QueryPermissionDto) => HttpClient
        .get<PermissionPaginator>(ENDPOINTS.USER.PERMISSION.GET, query);
    getPermissionById = (code: string) => HttpClient
        .get<{ data:PermissionDto }>(ENDPOINTS.USER.PERMISSION.DETAIL
            .replace('{code}', String(code)));
    createPermission = (payload: CreatePermissionDto) => HttpClient
        .post<{ data:PermissionDto }>(ENDPOINTS.USER.PERMISSION.CREATE, payload);
    updatePermission = (code: string, payload: UpdatePermissionDto) => HttpClient
        .patch<{ data:PermissionDto }>(ENDPOINTS.USER.PERMISSION.UPDATE
            .replace('{code}', String(code)), payload);
    
    getPermissionGroup = (query?: QueryPermissionGroupDto) => HttpClient
        .get<PermissionGroupPaginator>(ENDPOINTS.USER.PERMISSION_GROUP.GET, query);
    getPermissionGroupById = (permissionGroupId: string) => HttpClient
        .get<{ data: PermissionGroupDto }>(ENDPOINTS.USER.PERMISSION_GROUP.DETAIL
            .replace('{permissionGroupId}', String(permissionGroupId)));
    createPermissionGroup = (payload: CreatePermissionGroupDto) => HttpClient
        .post<{ data: PermissionGroup}>(ENDPOINTS.USER.PERMISSION_GROUP.CREATE, payload);
    updatePermissionGroup = (permissionGroupId: string, payload: UpdatePermissionGroupDto) => HttpClient
        .patch<{ data: PermissionGroup}>(ENDPOINTS.USER.PERMISSION_GROUP.UPDATE
            .replace('{permissionGroupId}', String(permissionGroupId)), payload);

}