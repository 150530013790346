import Header from "../header";
import { Avatar } from "@mui/material";
import { PATHS } from "../../routes/routes.paths";
import { MdOutlineManageAccounts } from "react-icons/md";
import { BsCreditCard2Front, BsToggles2 } from "react-icons/bs";
import { useState } from "react";
import useUserStore from "../../state-management/useUserStore";
import SidebarItem from "./resources/SidebarItem";

const DEFAULT_COMPANY_IMAGE = "/assets/logos/zeplin.png";

interface IAdminLayoutProps {
	children: React.ReactNode;
}

const bgColor = "linear-gradient(0deg, rgba(204,247,247,1) 0%, rgba(255,255,255,0.665703781512605) 70%)";

const AdminLayout = ({ children }: IAdminLayoutProps) => {
	const { currentUser } = useUserStore();
	const [showSidebar, setShowSidebar] = useState(
		sessionStorage.getItem("showSidebar") ? false : true
	);
	
	const toggleSidebar = () => {
		setShowSidebar(prev => {
			if (prev) {
				sessionStorage.setItem("showSidebar", "false");
			} else {
				sessionStorage.removeItem("showSidebar");
			}
			return !prev
		})
	};

	return (
		<div className={"flex h-[100svh] w-full"}>
			{/* sidebar */}
			<section className={`h-full ${showSidebar ? "w-[300px] px-6" : "w-[80px] pr-4 pl-5"} sticky top-0 border-r py-6 
				overflow-y-auto sidebar-two-scroll flex flex-col justify-between`}
			>
				<div>
					<div className={`flex items-center mb-10 ${!showSidebar && "justify-center"}`}>
						{showSidebar ? (
							<img
								src={"/assets/logos/akatua-logo.svg"}
								alt="Akatua Logo"
								className="h-6"
							/>
						) : (
							<img
								src={"/assets/icons/akatua-icon.png"}
								alt="Akatua Logo"
								width={40}
							/>
						)}
					</div>
					<div
						className={`w-full rounded-lg flex py-2 items-center text-white ${
							showSidebar && "bg-primary-blue justify-start px-3 space-x-3"
						}`}
					>
						<Avatar
							alt={currentUser.user.firstName}
							src={currentUser.user.imageUrl}
							sx={{ width: 40, height: 40 }}
						/>
						{showSidebar && (
							<div className="min-w-0">
								<h3 className="text-[11px] lg:text-xs font-bold">
									{currentUser.user.firstName +
										" " +
										currentUser.user.lastName}
								</h3>
								<p className="text-[8px] lg:text-[10px] mt-[2px] overflow-hidden truncate font-normal">
									{currentUser.user.emailAddress}
								</p>
							</div>
						)}
					</div>
					<div className="flex flex-col space-y-4 border-b pb-5 mt-3">
						<SidebarItem 
							title="Accounts Management"
							path={PATHS.ADMIN_ACCOUNTS_MANAGEMENT}
							Icon={MdOutlineManageAccounts}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="System Configurations"
							path={PATHS.ADMIN_SYSTEM_CONFIGURATIONS}
							Icon={BsToggles2}
							showSidebar={showSidebar}
						/>
						<SidebarItem 
							title="Billing"
							path={PATHS.ADMIN_BILLING}
							Icon={BsCreditCard2Front}
							showSidebar={showSidebar}
						/>
					</div>
				</div>
			</section>
			{/* Header + Children */}
			<section 
				style={{
					width: showSidebar ? "calc(100% - 300px)": "calc(100% - 80px)",
				}}
			>
				<div className={`sticky top-0 z-50 w-full`}>
					<Header onMenuClick={toggleSidebar} />
				</div>
				<div
					style={{ 
						background: bgColor,
						height: "calc(100% - 70px)",
					}}
					className="px-10 py-8 overflow-y-auto w-full"
				>
					<div className="w-full h-full max-w-[2000px] mx-auto">
						{children}
					</div>
				</div>
			</section>
		</div>
	);
};

export default AdminLayout;
