import React from 'react'
import { PATHS } from "../../../routes/routes.paths";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";

const HRReports = () => {
	return (
		<HumanResourceLayout>
			HRReports
		</HumanResourceLayout>
	);
};

export default HRReports;