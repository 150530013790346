import { useState, useEffect } from "react";
import Label from "../../../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { CreateUserDto } from '../../../../../../models/auth.dto';
import { useAccountCheck, useCreateUser, useUpdateUser } from "../../../../../../services/user.service";
import { OrganizationRoleDto } from "../../../../../../models/organization-role.dto";
import useUserStore from "../../../../../../state-management/useUserStore";
import { PayrollCompanyDto } from "../../../../../../models/company-account.dto";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import MultiSelectField from "../../../../../../components/form_fields/MultiSelectField";

interface IFormInputs extends CreateUserDto {}

type TeamMemberModalFormProps = IAddEditTableForm & {
	organizationRoles: OrganizationRoleDto[];
	companies: PayrollCompanyDto[];
	addNewData: () => void;
}

const TeamMemberModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	organizationRoles,
	companies,
	addNewData,
}: TeamMemberModalFormProps) => {
	const { currentUser } = useUserStore();
	const { formDataValues } = useEditFormStore();
	const [usernameExists, setUsernameExists] = useState(false);
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			organizationRoleId: isEditForm ? formDataValues?.organizationRoleId: "",
			firstName: isEditForm ? formDataValues?.firstName: "",
			lastName: isEditForm ? formDataValues?.lastName: "",
			username: isEditForm ? formDataValues?.username: "",
			emailAddress: isEditForm ? formDataValues?.emailAddress: "",
		}
	});

	const username = watch("username");
	const { mutate: validateUsername } = useAccountCheck();

	useEffect(() => {
		if (!username?.trim() || isEditForm) {
			setUsernameExists(false);
			return
		}

		validateUsername(
			{ payload: { username: username?.trim() } },
			{ onSuccess: (data) => setUsernameExists(data.data.exists) }
		)
	}, [username])

	const [userCompaniesIds, setUserCompaniesIds] = useState<number[]>([]);
	const { mutate: createUser } = useCreateUser();
	const { mutate: updateUser } = useUpdateUser();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		if (usernameExists) return;

		setUploading(true);

		const companyProfiles = userCompaniesIds.map((companyId) => {
			return {
				companyId: Number(companyId),
				roleId: data.organizationRoleId,
				employeeGrade: null
			}
		})

		if (isEditForm) {
			delete data.username;

			const getPayload = () => {
				if (formDataValues.companyProfiles.length !== companyProfiles.length) {
					return { ...data, companyProfiles }
				}

				// Check if every companyId in formDataValues.companyProfile is also in companyProfiles
				const companyProfileNotUpdated = (formDataValues.companyProfiles as any[]).every((company1) =>
					companyProfiles.some((company2) => company1.companyId === company2.companyId)
				)

				if (companyProfileNotUpdated) {
					return { ...data }
				} else {
					return { ...data, companyProfiles }
				}
			}

			updateUser(
				{ payload: getPayload(), id: formDataValues.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createUser(
				{
					payload: {
						...data,
						organizationId: currentUser.user.organization?.id, 
						companyProfiles,
					}
				}, 
				{
					onSuccess: () => {
						addNewData();
						setOpen(false);
					},
					onSettled: () => setUploading(false),
				},
			);
		}
	};
	
	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
					<div className="w-full">
						<Label title="First Name" for="firstName" />
						<input
							type="text"
							{...register("firstName", {
								required: "Enter your first name",
							})}
							id="firstName"
							placeholder="Enter first name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-4 mt-2 bg text-sm ${
								errors.firstName
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.firstName && (
							<p className="text-red-500 text-sm mt-1">
								{errors.firstName.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Last Name" for="lastName" />
						<input
							type="text"
							{...register("lastName", {
								required: "Enter your last name",
							})}
							id="lastName"
							placeholder="Enter last name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-4 mt-2 bg text-sm ${
								errors.lastName
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.lastName && (
							<p className="text-red-500 text-sm mt-1">
								{errors.lastName.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Username" for="username" />
						<input
							type="text"
							{...register("username", {
								required: "Enter your username",
							})}
							id="username"
							placeholder="Enter username"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-4 mt-2 bg text-sm ${
								errors.username
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							style={{ opacity: isEditForm ? 0.65 : 1 }}
							disabled={isEditForm}
						/>
						{(!usernameExists && errors.username) && (
							<p className="text-red-500 text-sm mt-1">
								{errors.username.message}
							</p>
						)}
						{usernameExists && (
							<p className="text-red-500 text-sm mt-1">
								Username already taken
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Email" for="email" />
						<input
							type="email"
							{...register("emailAddress", {
								required: "Enter your email",
								pattern: {
									value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
									message: "Enter a valid email address",
								},
							})}
							id="email"
							placeholder="yourname@email.com"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-4 mt-2 bg text-sm ${
								errors.emailAddress
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.emailAddress && (
							<p className="text-red-500 text-sm mt-1">
								{errors.emailAddress.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Organization Role" for="organizationRoleId" />
						<select
							defaultValue={isEditForm ? (formDataValues && formDataValues.organizationRoleId) : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.organizationRoleId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("organizationRoleId", {
								required: "Select organization role here",
							})}
						>
							<option value="">
								Select Role
							</option>
							{organizationRoles.map((role: any) => (
								<option 
									key={role.id} 
									value={role.id}
								>
									{role.name}
								</option>
							))}
						</select>
						{errors.organizationRoleId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.organizationRoleId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Assigned Companies" for="assignedCompanyIds" optional />
						<MultiSelectField
							key={"assignedCompanyIds"}
							options={companies}
							setResourceIds={setUserCompaniesIds}
							placeholder={"Select Companies"}
							defaultValues={
								isEditForm 
									? formDataValues.companyProfiles.map(companyProfile => companyProfile.companyId) 
									: []
							}
							required={false}
						/>
					</div>
				</div>

				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update"
								: "Send Invite"
						}
						className={"h-14 px-8"}
						type="submit"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default TeamMemberModalForm;
