import { PaginatorInfo } from "./generic.dto";
import { InvoiceDto } from "./invoice.dto";

type ACTOR_TYPE = "SYSTEM" | "USER";

export const TRANSACTION_TYPE = {
  CREDIT: "CREDIT",
  DEBIT: "DEBIT",
  REVERSAL: "REVERSAL",
  ALL: "ALL",
}
type TRANSACTION_TYPE = keyof typeof TRANSACTION_TYPE;

export type OrganizationTransaction = {
  id: string;
  organizationId: string;
  priorTransactionId: string | null;
  invoiceId: number | null;
  invoice: InvoiceDto;
  amount: number;
  type: TRANSACTION_TYPE;
  narration: string;
  reason: string;
  balanceBefore: number;
  balanceAfter: number;
  actorType: ACTOR_TYPE;
  actor: JSON;
  createdAt: Date;
}

export class CreateOrganizationTransactionDto {
  organizationId!: string;
  priorTransactionId?: number;
  amount!: number;
  balanceBefore?: number;
  balanceAfter?: number;
  invoiceId?: number;
  type!: TRANSACTION_TYPE;
  narration!: string;
  reason!: string;
  actorType!: ACTOR_TYPE;
}

export class CreditOrganizationTransactionDto {
  organizationId!: string;
  amount!: number;
  narration!: string;
}

export class ReverseOrganizationTransactionDto {
  transactionId!: string;
  narration!: string;
}

export class UpdateOrganizationTransactionDto {
  amount?: number;
}

export enum OrganizationTransactionOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
}

export class QueryOrganizationTransactionDto {
  organizationId?: string;
  invoiceId?: number;
  type?: TRANSACTION_TYPE;
  'createdAt.gte'?: string;
  'createdAt.lte'?: string;

  page?: number = 1;
  limit?: number;
  orderBy?: OrganizationTransactionOrderBy = OrganizationTransactionOrderBy.CREATED_AT_DESC;
}

export interface OrganizationTransactionPaginator extends PaginatorInfo<OrganizationTransaction> {}

export class SearchOrganizationTransactionDto {
  q?: string;

  page= 1;
  limit?: number ;
  orderBy: OrganizationTransactionOrderBy = OrganizationTransactionOrderBy.CREATED_AT_DESC;
}