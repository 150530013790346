import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { PayrollTabColumns, HR_PayrollTabColumns } from "../../../components/custom_tab/tabColumns";
import { useSearchParams } from "react-router-dom";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import CompanyPayrollRuns from "./views/CompanyPayrollRuns";
import CompanyPayslips from "./views/CompanyPayslips";

const Payroll = () => {
	const { userType } = useUserStore();
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "companyPayrollRuns";
	const [view, setView] = useState(currentView);
	
	return (<>
		{userType === "ORGANIZATION" ? (
			<PayrollCompanyLayout>
				<Helmet>
					<title>Payroll | Company Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Payroll</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={PayrollTabColumns}
				>
					{view === "companyPayrollRuns" && <CompanyPayrollRuns />}
					{view === "companyPayslips" && <CompanyPayslips />}
				</CustomTab>
			</PayrollCompanyLayout>
		):(
			<HumanResourceLayout>
				<Helmet>
					<title>Payroll Management | HR Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Payroll Management</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={HR_PayrollTabColumns}
				>
					{view === "companyPayrollRuns" && <CompanyPayrollRuns />}
					{view === "companyPayslips" && <CompanyPayslips />}
				</CustomTab>
			</HumanResourceLayout>
		)}
	</>);
};

export default Payroll;
