import { create } from "zustand";
import { persist, StorageValue } from 'zustand/middleware'
import SecureLS from 'secure-ls';
import { PayrollCompanyDto } from "../models/company-account.dto";

const ls = new SecureLS({ isCompression: false });

// Custom storage engine that uses secure-ls and its methods
const secureLsStorage = {
    getItem: (name: string) => {
        const value = ls.get(name);
        return value ? JSON.parse(value) : null;
    },
    setItem: (name: string, value: StorageValue<CompanyStore>) => 
        ls.set(name, JSON.stringify(value)),
    removeItem: (name: string) => ls.remove(name),
};

type CompanyStore = {
    currentCompany: PayrollCompanyDto | null,
    savedCompanies: PayrollCompanyDto[],
    setCurrentCompany: (data: PayrollCompanyDto) => void,
    setSavedCompanies: (data: PayrollCompanyDto[]) => void,
    clearCompanyStore: () => void,
}

const useCompanyStore = create(
    persist<CompanyStore>(
        (set) => ({
            currentCompany: null,
            savedCompanies: [],
            setCurrentCompany: (data: PayrollCompanyDto) => {
                set({ currentCompany: data })
            },
            setSavedCompanies: (data: PayrollCompanyDto[]) => {
                set({ savedCompanies: data })
            },
            clearCompanyStore: () => {
                set({ currentCompany: null, savedCompanies: [] })
            },
        }),
        {
            name: '@company',
            storage: secureLsStorage,
        }
    )
);

export default useCompanyStore;