import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import { DisabilitiesDto } from "../../../../../models/disabilities.dto";
import { EmployeeBandDto } from "../../../../../models/employee-band.dto";
import { JobTitleDto } from "../../../../../models/job-titles.dto";
import { useDeleteJobTitle, useUpdateJobTitle } from "../../../../../services/job-title.service";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import { manageResourceIds } from "../../../../../helpers/appHelpers";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../../components/form_fields/MultiSelectField";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
    employeeBandId: number;
    companyLevelId: number;
    maximumAge?: number;
    minimumAge?: number;
    minimumExperienceYears?: number;
    acceptDisability: boolean;
    remove?: number[];
    add?: number[];
}

interface IEditJobTitle {
	jobTitle: JobTitleDto;
	companyLevels: CompanyLevelDto[];
	employeeBands: EmployeeBandDto[];
	disabilities: DisabilitiesDto[];
}

const EditJobTitle = ({
	jobTitle,
	companyLevels,
	employeeBands,
	disabilities,
}: IEditJobTitle) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateJobTitle } = useUpdateJobTitle();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors, isSubmitted },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: jobTitle.name,
			code: jobTitle.code,
			description: jobTitle.description,
			employeeBandId: jobTitle.employeeBandId,
			companyLevelId: jobTitle.companyLevelId,
			maximumAge: jobTitle.maximumAge as number | undefined,
			minimumAge: jobTitle.minimumAge as number | undefined,
			minimumExperienceYears: jobTitle.minimumExperienceYears as number | undefined,
			acceptDisability: jobTitle.acceptDisability,
		}
	});

	const maximumAge = watch("maximumAge");
	const acceptDisability = watch("acceptDisability");
	const [acceptedDisabilities, setAcceptedDisabilities] = useState<number[]>([]);

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		if (acceptDisability && !acceptedDisabilities.length) return;
		
		setUploading(true);
		
		if (data.acceptDisability) {
			const { addIds, removeIds } = manageResourceIds({
				newData: acceptedDisabilities,
				oldData: jobTitle.jobTitleAcceptedDisabilities?.map(disability => disability.disabilityId) || [],
			});
			
			data.add = addIds || [];
			data.remove = removeIds || [];
		} else if (jobTitle.jobTitleAcceptedDisabilities?.length) {
			data.remove = jobTitle.jobTitleAcceptedDisabilities?.map(disability => disability.disabilityId) || undefined;
		}

		updateJobTitle(
			{ payload: data, id: jobTitle.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteJobTitle } = useDeleteJobTitle();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteJobTitle(
			{ id: jobTitle?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={jobTitle.id + "bh-content"}
				id={jobTitle.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{jobTitle.name}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Name" for="name" />
								<input
									disabled={formDisabled}
									placeholder={"Enter name"}
									type="text"
									{...register("name", {
										required: "Enter name here",
									})}
									id="name"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.name && (
									<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full col-span-2">
								<Label title="Description" for="description" />
								<FormDescriptionInput 
									attributes={{
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.description
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`
									}}
									register={register}
									required="Enter brief description here"
									disabled={formDisabled}
								/>
							</div>
							<div className="w-full">
								<Label title="Employee Band" for="employeeBandId" />
								<APISelectField 
									key="employeeBandId"
									attributes={{
										id: "employeeBandId",
										disabled: formDisabled
									}}
									placeholder="Select Employee Band"
									register={register}
									name="employeeBandId"
									options={employeeBands}
									pathname="/dashboard/company-settings?view=bands"
									required={"Select employee band here"}
									error={errors.employeeBandId}
									valueAsNumber
								/>
								{errors.employeeBandId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.employeeBandId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Company Level" for="companyLevelId" />
								<APISelectField 
									key="companyLevelId"
									attributes={{
										id: "companyLevelId",
										disabled: formDisabled
									}}
									placeholder="Select Company Level"
									register={register}
									name="companyLevelId"
									options={companyLevels}
									mapText="levelName"
									pathname="/dashboard/company-settings?view=companyLevel"
									required={"Select company level here"}
									error={errors.companyLevelId}
									valueAsNumber
								/>
								{errors.companyLevelId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.companyLevelId.message}
									</p>
								)}
							</div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={jobTitle.acceptDisability === true}
									{...register("acceptDisability", {
										required: false
									})}
									id="acceptDisability"
									disabled={formDisabled}
								/>
								<label htmlFor="acceptDisability">Accept Disability</label>
							</div>
							<div className="w-full">
								<Label title="Minimum Age" for={`minimumAge`} optional />
								<input
									placeholder={"Enter minimum age"}
									type="number"
									{...register(`minimumAge`, {
										required: false,
										valueAsNumber: true,
										validate: (value) => {
											if (value && maximumAge && value > maximumAge) {
												return "Must be less than the maximum age"
											}
											return true
										}
									})}
									id={`minimumAge`}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.minimumAge
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.minimumAge && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.minimumAge?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Maximum Age" for={`maximumAge`} optional />
								<input
									placeholder={"Enter maximum age"}
									type="number"
									{...register(`maximumAge`, {
										required: false,
										valueAsNumber: true
									})}
									id={`maximumAge`}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.maximumAge
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.maximumAge && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.maximumAge?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Minimum Experience Years" for={`minimumExperienceYears`} optional />
								<input
									placeholder={"Enter minimum experience years"}
									type="number"
									{...register(`minimumExperienceYears`, {
										required: false,
										valueAsNumber: true
									})}
									id={`minimumExperienceYears`}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.minimumExperienceYears
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.minimumExperienceYears && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.minimumExperienceYears?.message}
									</p>
								)}
							</div>
							{(jobTitle.acceptDisability || acceptDisability) && (
								<div className="w-full">
									<Label title="Accepted Disabilities" for="acceptedDisabilities" />
									<MultiSelectField
										key={"acceptedDisabilities"}
										options={disabilities}
										setResourceIds={setAcceptedDisabilities}
										placeholder={"Select Disabilities"}
										required={acceptDisability && isSubmitted}
										defaultValues={jobTitle.jobTitleAcceptedDisabilities?.map(disability => disability.disabilityId) || []}
									/>
								</div>
							)}
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={jobTitle.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditJobTitle;
