import {PaginatorInfo} from "./generic.dto";
import {EmployeePayInfoDto} from "./employee-pay-info.dto";

export const QUALIFICATION_TYPE = {
    PROFESSIONAL: 'PROFESSIONAL',
    ACADEMIC: 'ACADEMIC',
    VOCATIONAL: 'VOCATIONAL'
};

export type QUALIFICATION_TYPE = (typeof QUALIFICATION_TYPE)[keyof typeof QUALIFICATION_TYPE]

type EmployeeQualification = {
    id: number
    employeeId: number
    companyId: number
    qualificationType: QUALIFICATION_TYPE
    qualification: string
    awardingInstitution: string | null
    awardDate: string | null
    createdAt: string
    modifiedAt: string | null
}

export class CreateEmployeeQualificationDto {
    employeeId!: number
    companyId!: number
    qualificationType!: QUALIFICATION_TYPE
    qualification!: string
    awardingInstitution!: string
    awardDate!: string | string;
}

export class UpdateEmployeeQualificationDto {
    employeeId?: number
    companyId?: number
    qualificationType?: QUALIFICATION_TYPE
    qualification?: string
    awardingInstitution?: string
    awardDate?: string
}


export interface EmployeeQualificationDto extends EmployeeQualification { }

export interface EmployeeQualificationPaginator extends PaginatorInfo<EmployeeQualificationDto> {}

export class QueryEmployeeQualificationDto {
    employeeId?: number
    companyId?: number
    qualificationType?: QUALIFICATION_TYPE
    qualification?: string
    awardingInstitution?: string
    'awardDate.gte'?: string
    'awardDate.lte'?: string
    page: number = 1;
    limit?: number;
    orderBy?: EmployeeQualificationOrderBy = EmployeeQualificationOrderBy.CREATED_AT_ASC;
}


export enum EmployeeQualificationOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    QUALIFICATION_ASC = 'qualification:asc',
    QUALIFICATION_DESC = 'qualification:desc',
    AWARDING_INST_ASC = 'awardingInstitution:asc',
    AWARDING_INST_DESC = 'awardingInstitution:desc',
    AWARD_DATE_ASC = 'awardDate:asc',
    AWARD_DATE_DESC = 'awardDate:desc'
}
