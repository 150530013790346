import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    EmployeeAccountInfoDto, EmployeeAccountInfoPaginator,
    CreateEmployeeAccountInfoDto, QueryEmployeeAccountInfoDto,
    UpdateEmployeeAccountInfoDto,
} from '../models/employee-account-info.dto';
import ENDPOINTS from './utils/endpoints';

export function useEmployeeAccountInfo(options?: QueryEmployeeAccountInfoDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.GET;
    const request = (options: QueryEmployeeAccountInfoDto) => API.ProcessingCompanyAPI.getEmployeeAccountInfo(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<EmployeeAccountInfoPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return API.Handlers.paginationHandler(
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    );
}

export function useGetEmployeeAccountInfoById(id: number){
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.DETAIL;
    const request = API.ProcessingCompanyAPI.getEmployeeAccountInfoById(id);

    const { data, isLoading, error } = useQuery<{ data: EmployeeAccountInfoDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateEmployeeAccountInfo(onSuccessCallback?: () => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateEmployeeAccountInfoDto }) => API.ProcessingCompanyAPI.createEmployeeAccountInfo(payload),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.DETAIL);
            },
        },
    );
}

export function useUpdateEmployeeAccountInfo() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateEmployeeAccountInfoDto }) => API.ProcessingCompanyAPI.updateEmployeeAccountInfo(id, payload),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.DETAIL);
            },
        },
    );
}

export function useDeleteEmployeeAccountInfo() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteEmployeeAccountInfoById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.GET);
            },
        }
    )
}