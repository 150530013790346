import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { SkewLoader } from "react-spinners";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import EditLeader from "./EditLeader";
import NewLeader from "./NewLeader";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import { EmployeeDto } from "../../../../../../models/employee.dto";
import { DepartmentLeadershipOrderBy } from "../../../../../../models/department-leadership.dto";
import { useGetDepartmentLeadership } from "../../../../../../services/department-leadership.service";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";

type DepartmentLeadershipProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	employees: EmployeeDto[];
}

const DepartmentLeadership = ({ open, setOpen, employees }: DepartmentLeadershipProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [showNewLeaderForm, setShowNewLeaderForm] = useState(false);
	
	const { data: leaders, isLoading } = useGetDepartmentLeadership(
		currentCompany?.id as number,
		{
			departmentId: formDataValues?.id,
			orderBy: DepartmentLeadershipOrderBy.CREATED_AT_DESC,
			limit: 10,
		}
	);
	
	return (
		<ModalComponent
			width={730}
			open={open}
			title={formDataValues.name + " Department Leaders"}
			handleClose={() => setOpen(false)}
		>
			<div className="mb-4">
				<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
					<h2 className="font-semibold">Available Leaders</h2>
					{!showNewLeaderForm && (
						<button
							onClick={() => setShowNewLeaderForm(true)}
							className="border-none flex items-center text-primary-blue"
						>
							<AiOutlinePlus className="mr-2 text-lg" />
							Add New Leader
						</button>
					)}
				</div>

				{showNewLeaderForm && (
					<NewLeader 
						employees={employees}
						setShowNewLeaderForm={setShowNewLeaderForm} 
						definedRanks={leaders?.data?.map((leader) => leader.rank)}
					/>
				)}

				{isLoading && (
					<div className="text-center mt-10 py-10">
						<SkewLoader color="#F58B00" />
					</div>
				)}
	
				{leaders?.data?.map((leader) => (
					<EditLeader 
						key={leader.id}
						leader={leader} 
						employees={employees}
					/>
				))}

				{(!isLoading && leaders?.data?.length === 0) && (
					<div>
						<h3 className="text-center text-sm font-semibold mt-10">
							Sorry no leaders added yet. Click 'Add New Leader' to begin.
						</h3>
					</div>
				)}
			</div>
		</ModalComponent>
	);
};

export default DepartmentLeadership;
