import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<p>
					<span>{params.value} </span>
					<span> ({params.row.shortName})</span>
				</p>
			);
		},
	},
	{
		field: "code",
		headerName: "Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "paymentChannelType",
		headerName: "Payment Channel Type",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return params.row.country ?
				<p>
					{params.row.country.name}
				</p> :
				<p>--</p>
		},
	},
	{
		field: "swiftCode",
		headerName: "Swift Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];
