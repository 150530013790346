import React, { useContext, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GENDER } from "../../../../../models/employee.dto";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import { EmployeeFormContext } from "../../EmployeeFormContext";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import useUserStore from "../../../../../state-management/useUserStore";

interface IPersonalDetails {
	formData: any;
	setFormData: React.Dispatch<React.SetStateAction<any>>;
	setView: (view: string) => void;
}

interface IFormInputs {
	photoUrl?: string;
	firstName: string;
	lastName: string;
	otherNames?: string;
	dateOfBirth: string;
	gender: GENDER;
	title: string;
	privateEmail: string;
	email: string;
	msisdn: string;
	alternateMsisdn: string;
	majorGradeLevelId?: number;
	minorGradeLevelId?: number;
	nationality: string;
	regionId: string;
	resident: boolean;
	tribeId: number;
	ssn: number;
	reemployed: boolean;
	address: string;
	digitalAddress: string;
	taxIdentificationNumber: string;
	unionMember?: boolean;
}

const PersonalDetails = ({
	formData,
	setView,
	setFormData,
}: IPersonalDetails) => {
	const { userType } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const {
		nationalities,
		MajorGradeLevelData,
		MinorGradeLevelData,
		RegionData,
		TribeData,
	} = useContext(EmployeeFormContext);

	const {
		register,
		watch,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	useEffect(() => {
		const { unsubscribe } = watch((values) => {
			setFormData({ ...formData, ...values });
		});
		return () => unsubscribe();
	}, [watch])

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setFormData((prev: any) => ({ ...prev, data }));
		setView("employmentDetails");
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex space-x-4">
				<div className="w-full">
					<Label title="First Name" for="firstName" />
					<input
						defaultValue={formData?.firstName}
						placeholder={"Enter first name"}
						type="text"
						{...register("firstName", {
							required: "Enter first name here",
						})}
						id="firstName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.firstName
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.firstName && (
						<p className="text-red-500 text-sm mt-1">
							{errors.firstName.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Last Name" for="lastName" />
					<input
						defaultValue={formData?.lastName}
						placeholder={"Enter last name"}
						type="text"
						{...register("lastName", {
							required: "Enter last name here",
						})}
						id="lastName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.lastName
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.lastName && (
						<p className="text-red-500 text-sm mt-1">
							{errors.lastName.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Other Names" for="otherNames" optional />
					<input
						defaultValue={formData?.otherNames}
						placeholder={"Enter other names"}
						type="text"
						{...register("otherNames", {
							required: false,
						})}
						id="otherNames"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.otherNames
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.otherNames && (
						<p className="text-red-500 text-sm mt-1">
							{errors.otherNames.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Date of Birth" for="dateOfBirth" />
					<input
						defaultValue={formData?.dateOfBirth?.slice(0, 10)}
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("dateOfBirth", {
							required: "Enter date of birth here",
						})}
						id="dateOfBirth"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.dateOfBirth
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.dateOfBirth && (
						<p className="text-red-500 text-sm mt-1">
							{errors.dateOfBirth.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Gender" for="gender" />
					<select
						defaultValue={formData?.gender}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.gender
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("gender", {
							required: "Select gender here",
						})}
					>
						<option value="">Select Gender</option>
						<option value="MALE">Male</option>
						<option value="FEMALE">Female</option>
					</select>
					{errors.gender && (
						<p className="text-red-500 text-sm mt-1">{errors.gender.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Title" for="title" />
					<select
						defaultValue={formData?.title}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.title
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("title", {
							required: "Select title here",
						})}
					>
						<option value="">Select Title</option>
						<option value="Master">Master</option>
						<option value="Miss">Miss</option>
						<option value="Mr.">Mr.</option>
						<option value="Mrs.">Mrs.</option>
					</select>
					{errors.title && (
						<p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label 
						title="Corporate Email" 
						for="email" 
						optional={!currentCompany?.enableEmployeeLogin} 
					/>
					<input
						defaultValue={formData?.email}
						placeholder={"Enter email address"}
						type="email"
						{...register("email", {
							required: currentCompany?.enableEmployeeLogin
								? "Enter corporate email here"
								: false,
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Invalid email address",
							},
						})}
						id="firstName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.email
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.email && (
						<p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Private Email" for="privateEmail" optional />
					<input
						defaultValue={formData?.privateEmail}
						placeholder={"Enter email address"}
						type="email"
						{...register("privateEmail", {
							required: false,
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Invalid email address",
							},
						})}
						id="privateEmail"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.privateEmail
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.privateEmail && (
						<p className="text-red-500 text-sm mt-1">
							{errors.privateEmail.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Telephone" for="msisdn" optional />
					<Controller
						name={"msisdn"}
						control={control}
						rules={{
							required: false,
							maxLength: {
								value: 12,
								message: "Enter a valid phone number",
							},
						}}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
									errors.msisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									value={formData?.msisdn}
									inputStyle={{
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										// marginTop: "8px",
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
					{errors.msisdn && (
						<p className="text-red-500 text-sm mt-1">{errors.msisdn.message}</p>
					)}
				</div>
				<div className="w-full">
					<label
						htmlFor="alternateMsisdn"
						className="text-secondary-darkgray text-sm"
					>
						Telephone <i className="font-bold">(optional)</i>
					</label>
					<Controller
						name={"alternateMsisdn"}
						control={control}
						defaultValue=""
						rules={{
							required: false,
							maxLength: {
								value: 12,
								message: "Enter a valid phone number",
							},
						}}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
									errors.alternateMsisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									value={formData?.alternateMsisdn}
									inputStyle={{
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										height: "50px",
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
					{errors.alternateMsisdn && (
						<p className="text-red-500 text-sm mt-1">
							{errors.alternateMsisdn.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label
						title="Grade Major Level"
						for="majorGradeLevelId"
						optional
					/>
					<APISelectField 
						key="majorGradeLevelId"
						attributes={{
							id: "majorGradeLevelId",
							defaultValue: formData?.majorGradeLevelId
						}}
						placeholder="Select Level"
						register={register}
						name="majorGradeLevelId"
						options={MajorGradeLevelData}
						pathname="/dashboard/company-settings?view=gradeLevel"
						required={false}
						error={errors.majorGradeLevelId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
					{errors.majorGradeLevelId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.majorGradeLevelId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label
						title="Grade Minor Level"
						for="minorGradeLevelId"
						optional
					/>
					<APISelectField 
						key="minorGradeLevelId"
						attributes={{
							id: "minorGradeLevelId",
							defaultValue: formData?.minorGradeLevelId
						}}
						placeholder="Select Level"
						register={register}
						name="minorGradeLevelId"
						options={MinorGradeLevelData}
						pathname="/dashboard/company-settings?view=gradeLevel"
						required={false}
						error={errors.minorGradeLevelId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
					{errors.minorGradeLevelId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.minorGradeLevelId.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Nationality" for="nationality" />
					<select
						defaultValue={formData?.nationality}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.nationality
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("nationality", {
							required: "Select nationality here",
						})}
					>
						<option value="">Select Nationality</option>
						{nationalities?.map((nationality) => (
							<option 
								key={nationality.id} 
								value={nationality.name}
							>
								{nationality.name}
							</option>
						))}
					</select>
					{errors.nationality && (
						<p className="text-red-500 text-sm mt-1">
							{errors.nationality.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Region" for="regionId" optional />
					<APISelectField 
						key="regionId"
						attributes={{
							id: "regionId",
							defaultValue: formData?.regionId
						}}
						placeholder="Select Region"
						register={register}
						name="regionId"
						options={RegionData}
						pathname="/dashboard/company-settings?view=regions"
						required={false}
						error={errors.regionId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
					{errors.regionId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.regionId.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Resident?" for="resident" optional />
					<select
						defaultValue={formData?.resident ? "yes" : "no"}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.resident
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("resident", {
							required: false,
						})}
					>
						<option value="">Select Option</option>
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</select>
					{errors.resident && (
						<p className="text-red-500 text-sm mt-1">
							{errors.resident.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Tribe" for="tribeId" optional />
					<APISelectField 
						key="tribeId"
						attributes={{
							id: "tribeId",
							defaultValue: formData?.tribeId
						}}
						placeholder="Select Tribe"
						register={register}
						name="tribeId"
						options={TribeData}
						pathname="/dashboard/company-settings?view=tribes"
						required={false}
						error={errors.tribeId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
					{errors.tribeId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.tribeId.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Address" for="address" optional />
					<input
						defaultValue={formData?.address}
						placeholder={"Enter employee address"}
						type="text"
						{...register("address", {
							required: false,
						})}
						id="firstName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.address
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
				</div>
				<div className="w-full">
					<Label title="Digital Address" for="digitalAddress" optional />
					<input
						defaultValue={formData?.digitalAddress}
						placeholder={"Enter digital address"}
						type="text"
						{...register("digitalAddress", {
							required: false,
						})}
						id="firstName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.digitalAddress
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
				</div>
			</div>
			<div className="flex space-x-4 mt-6">
				<div className="w-full">
					<Label title="Social Security Number" for="ssn" />
					<input
						defaultValue={formData?.ssn}
						placeholder={"******"}
						type="text"
						{...register("ssn", {
							required: "Enter social security number here",
						})}
						id="ssn"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.ssn
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.ssn && (
						<p className="text-red-500 text-sm mt-1">{errors.ssn.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label
						title="Tax ID Number"
						for="taxIdentificationNumber"
						optional
					/>
					<input
						defaultValue={formData?.taxIdentificationNumber}
						placeholder={"******"}
						type="text"
						{...register("taxIdentificationNumber", {
							required: false,
						})}
						id="taxIdentificationNumber"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.taxIdentificationNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.taxIdentificationNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors.taxIdentificationNumber.message}
						</p>
					)}
				</div>
			</div>
			<div className="mt-6">
				<div className="flex space-x-4 text-sm">
					<h3>Re-employed?</h3>
					<div className="flex space-x-3 items-center">
						<input
							defaultChecked={
								formData?.reemployed === true || 
								formData?.reemployed === "yes"
							}
							{...register("reemployed", {
								required: "Select an option",
							})}
							type="radio"
							value="yes"
						/>
						<Label for={"yes"} title={"Yes"} />
					</div>
					<div className="flex space-x-3 items-center">
						<input
							defaultChecked={
								formData?.reemployed === false || 
								formData?.reemployed === "no"
							}
							{...register("reemployed", {
								required: "Select an option",
							})}
							type="radio"
							value="no"
						/>
						<Label for={"no"} title={"No"} />
					</div>
				</div>
				{errors.reemployed && (
					<p className="text-red-500 text-sm mt-1">
						{errors.reemployed.message}
					</p>
				)}
			</div>
			<div className="flex space-x-4 text-sm mt-6">
				<h3>Union member?</h3>
				<div className="flex space-x-3 items-center">
					<input
						defaultChecked={
							formData?.unionMember === true || 
							formData?.unionMember === "yes"
						}
						{...register("unionMember", {
							required: false,
						})}
						type="radio"
						value="yes"
					/>
					<Label for={"yes"} title={"Yes"} />
				</div>
				<div className="flex space-x-3 items-center">
					<input
						defaultChecked={
							formData?.unionMember === false || 
							formData?.unionMember === "no"
						}
						{...register("unionMember", {
							required: false,
						})}
						type="radio"
						value="no"
					/>
					<Label for={"no"} title={"No"} />
				</div>
			</div>
			<div className="w-full flex justify-end mt-8">
				<button
					onClick={handleSubmit(onSubmit)}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
				>
					Continue
				</button>
			</div>
		</form>
	);
};

export default PersonalDetails;
