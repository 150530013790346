import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem } from "@mui/x-data-grid";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import { PensionDto } from "../../../../../models/pension.dto";
import PensionModalForm from "./modal";
import { AiOutlinePlus } from "react-icons/ai";
import TableFilter from "../../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import { useDeletePension, useGetPension, useGetPensionSearch } from "../../../../../services/pension.service";
import APISearchInput from "../../../../../components/search/APISearchInput";
import { Columns } from "./data";
import { CountryOrderBy } from "../../../../../models/country.dto";
import { useGetCountry } from "../../../../../services/country.service";

const Pensions = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditPensionForm, setIsEditPensionForm] = useState(false);
	
	const { mutate: searchPensions } = useGetPensionSearch();
	const {
        tableAPIRef,
        fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<PensionDto>({
		fetchHook: useGetPension,
	});
	
	// Fetched data for select fields requiring data from database
	const { data: countries } = useGetCountry({ 
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: CountryOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditPensionForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletePension } = useDeletePension();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletePension(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};
	
	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchPensions}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Country",
								fieldName: "countryId",
								fieldType: "SELECT_FIELD",
								options: countries?.data || [],
							},
							{
								title: "Tier",
								fieldName: "tier",
								fieldType: "SELECT_FIELD",
								options: [
									{ id: 1, name: 1 }, 
									{ id: 2, name: 2 }, 
									{ id: 3, name: 3 }, 
								],
							},
							{
								title: "Active",
								fieldName: "active",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Pension Tier</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditPensionForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<PensionModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditPensionForm
							? "Update Pension Tier"
							: "Add New Pension Tier"
					}
					isEditForm={isEditPensionForm}
					tableAPIRef={tableAPIRef}
					countries={countries?.data || []}
				/>
			)}
		</>
	);
};

export default Pensions;
