import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    BillingTypeDto, BillingTypePaginator,
    CreateBillingTypeDto, QueryBillingTypeDto,
    UpdateBillingTypeDto
} from "../models/billing-type.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetBillingType(options?: QueryBillingTypeDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.GET;
    const request = (options: QueryBillingTypeDto) => API.BillingAndSubscriptionAPI.getBillingType(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<BillingTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return API.Handlers.paginationHandler(data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage)
}

export function useGetBillingTypeById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.DETAIL
    const request = API.BillingAndSubscriptionAPI.getBillingTypeById(id);

    const {data, isLoading, error} = useQuery<{ data:BillingTypeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateBillingType(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateBillingTypeDto}) => API.BillingAndSubscriptionAPI.createBillingType(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.GET);
            },
        }
    )
}

export function useUpdateBillingType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateBillingTypeDto, id: number}) => API.BillingAndSubscriptionAPI.updateBillingType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.GET);
            },
        }
    )
}

export function useDeleteBillingType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteBillingType(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.GET);
            },
        }
    )
}
