import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import { useDeleteCompanyLevel, useUpdateCompanyLevel } from "../../../../../services/company-level.service";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";

interface IFormInputs {
	levelNumber: number;
	levelName: string;
	parentId?: number;
	childId?: number;
	juniorLevel: boolean;
}

interface IEditCompanyLevel {
	companyLevel: CompanyLevelDto;
	companyLevels: CompanyLevelDto[];
}

const EditCompanyLevel = ({companyLevel, companyLevels}: IEditCompanyLevel) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateCompanyLevel } = useUpdateCompanyLevel();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			levelName: companyLevel.levelName,
			levelNumber: companyLevel.levelNumber,
			parentId: companyLevel.parentId as any,
			childId: companyLevel.childId as any,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		if (!data.parentId) delete data.parentId;
		if (!data.childId) delete data.childId;

		updateCompanyLevel(
			{ payload: data, id: companyLevel.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCompanyLevel } = useDeleteCompanyLevel();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCompanyLevel(
			{ id: companyLevel?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={companyLevel.id + "bh-content"}
				id={companyLevel.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{companyLevel.levelName}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
							<div className="w-full">
								<Label title="Level Name" for={`levelName`} />
								<input
									defaultValue={companyLevel.levelName}
									disabled={formDisabled}
									placeholder={"Enter level name"}
									type="text"
									{...register(`levelName`, {
										required: "Enter level name here",
									})}
									id={`levelName`}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.levelName
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.levelName && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.levelName?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Level Number" for={`levelNumber`} />
								<input
									defaultValue={companyLevel.levelNumber}
									disabled={formDisabled}
									placeholder={"Enter level number"}
									type="number"
									{...register(`levelNumber`, {
										required: "Enter level number here",
									})}
									id={`levelNumber`}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.levelNumber
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.levelNumber && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.levelNumber?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Parent Level" for="parentId" optional />
								<select
									defaultValue={companyLevel.parentId as any}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.parentId
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("parentId", {
										required: false,
										valueAsNumber: true
									})}
								>
									<option value="">
										Select Parent Level
									</option>
									{companyLevels.map((companyLevel) => (
										<option 
											key={companyLevel.id} 
											value={companyLevel.id} 
										>
											{companyLevel.levelName}
										</option>
									))}
								</select>
							</div>
							<div className="w-full">
								<Label title="Child Level" for="childId" optional />
								<select
									defaultValue={companyLevel.childId as any}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.childId
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("childId", {
										required: false,
										valueAsNumber: true
									})}
								>
									<option value="">
										Select Child Level
									</option>
									{companyLevels.map((companyLevel) => (
										<option 
											key={companyLevel.id} 
											value={companyLevel.id} 
										>
											{companyLevel.levelName}
										</option>
									))}
								</select>
							</div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									defaultChecked={companyLevel.juniorLevel === true}
									disabled={formDisabled}
									type="checkbox"
									{...register("juniorLevel", {
										required: false
									})}
									id="juniorLevel"
								/>
								<label htmlFor="juniorLevel">Junior Staff</label>
							</div>
						</div>
					</>

					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={companyLevel.levelName}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditCompanyLevel;
