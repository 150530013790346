import {useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import {BsUpload} from "react-icons/bs";
import {RiCloseLine} from "react-icons/ri";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useGetCountry } from "../../../../services/country.service";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useGetCurrency } from "../../../../services/currency.service";
import { CreatePayrollCompanyDto } from "../../../../models/company-account.dto";
import { useCreateCompany } from "../../../../services/company-account.service";
import { toast } from "react-toastify";
import { uploadOneDocument } from "../../../../services/document.service";
import useUserStore from "../../../../state-management/useUserStore";
import { CountryOrderBy } from "../../../../models/country.dto";
import { CurrencyOrderBy } from "../../../../models/currency.dto";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { useRefreshToken, useSignOut } from "../../../../services/auth.service";

interface IFormInputs extends CreatePayrollCompanyDto {}

const CompanyDetails = ({ setView }: any) => {
	const { currentUser } = useUserStore();
	const signOutUser = useSignOut();
	const { 
		savedCompanies, 
		setSavedCompanies,
		setCurrentCompany,
	} = useCompanyStore();
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/png": [".png"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			Object.assign(file, {
				preview: URL.createObjectURL(file),
			});

			setFile(file);
		},
	});

	const { data: countries } = useGetCountry({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CountryOrderBy.NAME_ASC,
	});
	const { data: currencies } = useGetCurrency({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CurrencyOrderBy.NAME_ASC,
	});

	const { mutate: createCompany } = useCreateCompany();
	const { mutate: refreshToken } = useRefreshToken();

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		setUploading(true);

		const payload = data as CreatePayrollCompanyDto;
		payload.organizationId = currentUser.user.organizationId;

		if (file) {
			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				payload.logoUrl = fileUrl;
			} else {
				toast.error("Failed to upload company logo");
			}
		}

		createCompany(
			{ payload },
			{
				onSuccess: ({ data: createdCompany }) => {
					refreshToken(
						{ token: currentUser.refresh_token },
						{
							onSuccess: () => {
								setCurrentCompany(createdCompany);
								setSavedCompanies([createdCompany, ...savedCompanies]);
								setUploading(false);
								setView("dashboard");
							},
							onError: () => {
								toast.error(`There was an error setting up your new company. Kindly sign out and sign back in to fix this.`);
								signOutUser();
							}
						}
					);
				},
				onError: (error) => {
					setUploading(false);
					const text =
						error?.response?.data?.message ||
						"An error occurred whiles creating company";
					const details = error?.response?.data?.details || [];
					
					if (details.length) {
						const list = details.join("\n");
						toast.error(text + "\n" + list);
					} else {
						toast.error(text);
					}
				},
			}
		);
	};

	return (
		<form className="w-full" onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6">
				<div className="w-full">
					<Label title="Name" for="name" />
					<input
						placeholder={"Your company name"}
						type="text"
						{...register("name", {
							required: "Enter your organisation name",
						})}
						id="name"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.name && (
						<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Email Address" for="contactEmail" />
					<input
						placeholder={"youremail@email.com"}
						type="text"
						{...register("contactEmail", {
							required: "Enter your email address",
							pattern: {
								value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								message: "Enter a valid email address",
							},
						})}
						id="contactEmail"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.contactEmail
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.contactEmail && (
						<p className="text-red-500 text-sm mt-1">
							{errors.contactEmail.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Currency" for="currencyId" />
					<select
						placeholder="Select currency"
						className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
							errors.countryId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register(`currencyId`, {
							required: "Select currency",
							valueAsNumber: true,
						})}
					>
						<option value="">
							Select Currency
						</option>
						{currencies?.data.map((item) => (
							<option key={item.id} value={item.id}>
								{item.name}
							</option>
						))}
					</select>
					{errors.currencyId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currencyId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Country" for="countryId" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm  ${
							errors.countryId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register(`countryId`, {
							required: "Select country",
							valueAsNumber: true,
						})}
					>
						<option value="">
							Select Country
						</option>
						{countries?.data.map((item) => (
							<option key={item.id} value={item.id}>
								{item.name}
							</option>
						))}
					</select>
					{errors.countryId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.countryId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Address" for="address" />
					<input
						placeholder={"Enter your address"}
						type="text"
						{...register("address", {
							required: "Enter your company's address",
						})}
						id="accountName"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.address
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.address && (
						<p className="text-red-500 text-sm mt-1">
							{errors.address.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Telephone" for="contactMsisdn" optional />
					<Controller
						name={"contactMsisdn"}
						control={control}
						rules={{ required: false }}
						render={({ field }) => (
							<div
								className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center justify-center ${
									errors.contactMsisdn
										? "border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<PhoneInput
									{...field}
									country={"gh"}
									inputStyle={{
										height: errors.contactMsisdn ? "50px" : "48px",
										border: "none",
										backgroundColor: "#F2F2F4",
										color: "black",
										boxShadow: "none",
										width: "95%",
										margin: "0px 0px 0px 15px",
										borderRadius: "10px",
									}}
									containerStyle={{
										margin: "4px 0px",
										height: errors.contactMsisdn ? "50px" : "48px",
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										borderRadius: "10px",
									}}
									buttonStyle={{
										border: "none",
										backgroundColor: "#F2F2F4",
										boxShadow: "none",
										padding: "0px 10px",
									}}
								/>
							</div>
						)}
					/>
				</div>
				<div className="w-full">
					<Label
						title="Approvals Required for Leave Requests"
						for="leaveRequestApprovalsRequired"
						optional
					/>
					<select
						className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm border-none`}
						{...register("leaveRequestApprovalsRequired", {
							required: false,
							valueAsNumber: true,
						})}
					>
						<option value="">
							Select Option
						</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
					</select>
				</div>
				<div className="w-full">
					<Label
						title="Approvals Required for Reimbursement Requests"
						for="reimbursementRequestApprovalsRequired"
						optional
					/>
					<select
						className={`w-full bg-secondary-gray rounded-lg h-12 px-5 mt-2 text-sm border-none`}
						{...register("reimbursementRequestApprovalsRequired", {
							required: false,
							valueAsNumber: true,
						})}
					>
						<option value="">
							Select Option
						</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
					</select>
				</div>
				<div className="flex flex-col w-full">
					<Label
						title="Work Hours In A Day"
						for="workHoursInADay"
						optional
					/>
					<div className="flex items-center mt-2 h-14">
						<input
							placeholder="Enter value"
							type="number"
							{...register("workHoursInADay", {
								required: false,
								valueAsNumber: true,
							})}
							id="workHoursInADay"
							className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
								errors.workHoursInADay
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
							Hours
						</div>
					</div>
				</div>
			</div>
			<div className="w-full mt-6 flex flex-col gap-6">
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("enableEmployeeLogin", {
							required: false,
						})}
						id="enableEmployeeLogin"
					/>
					<label htmlFor="enableEmployeeLogin">Enable Employee Accounts</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("considerPublicHolidayAsWorkday", {
							required: false,
						})}
						id="considerPublicHolidayAsWorkday"
					/>
					<label htmlFor="considerPublicHolidayAsWorkday">Consider Public Holiday As Workday</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("considerWeekendAsWorkday", {
							required: false,
						})}
						id="considerWeekendAsWorkday"
					/>
					<label htmlFor="considerWeekendAsWorkday">Consider Weekend As Workday</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("allowNegativeRates", {
							required: false,
						})}
						id="allowNegativeRates"
					/>
					<label htmlFor="allowNegativeRates">Allow Negative Rates</label>
				</div>
			</div>
			<div className="w-full flex items-center space-x-4 mt-10">
				<div
					{...getRootProps()}
					className=" border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex flex-col justify-center items-center text-primary-blue bg-[#26A0F91A] text-xs"
				>
					<input {...getInputProps()} />
					<BsUpload className="text-xl" />
					<p className="mt-3 font-light">Upload Company Logo</p>
				</div>
				{file && (
					<div className="h-[100px] w-full flex justify-between items-center rounded-lg bg-slate-50 border overflow-hidden space-x-8 px-3">
						<div className="flex items-center space-x-2">
							<img
								src={(file as any).preview}
								alt="preview"
								// height={70}
								width={"70px"}
							/>
							<div className="text-vs leading-5 w-4/6">
								<p className="font-semibold max-w-[200px] truncate">{file.name}</p>
								<div className="flex items-center text-gray-500 space-x-2">
									<p>{file.type}</p>
									<span className="h-1 w-1 rounded-full bg-gray-500"></span>
									<p>{(file.size / 1000000).toFixed(2)}mb</p>
								</div>
							</div>
						</div>
						<RiCloseLine
							className="font-bold text-2xl cursor-pointer"
							onClick={() => setFile(null)}
						/>
					</div>
				)}
			</div>
			<OrangeButton
				title="Continue"
				type="submit"
				className={"px-12 mt-12"}
				disabled={uploading}
			/>
		</form>
	);
};

export default CompanyDetails;
