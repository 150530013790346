import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm, validatePast } from "../../../../helpers/appHelpers";
import { Box, LinearProgress } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { BsFillFileEarmarkTextFill, BsUpload } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { CompanyCurrencyConfigDto } from "../../../../models/company-currency.dto";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import { 
	useCreateReimbursementRequest, 
	useUpdateReimbursementRequest 
} from "../../../../services/reimbursement-request.service";
import useUserStore from "../../../../state-management/useUserStore";
import { uploadOneDocument } from "../../../../services/document.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useViewPort } from "../../../../helpers/useViewPort";

interface IFormInputs {
    title: string;
    description: string;
    currencyId: number;
    amount: number;
    expenditureDate: string;
}

type FileStatus = "success" | "error" | "uploading" | ""

type ReimbursementRequestModalProps = IAddEditTableForm & {
	companyCurrencies: CompanyCurrencyConfigDto[];
}

const ReimbursementRequestModal = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	companyCurrencies,
}: ReimbursementRequestModalProps) => {
	const viewPortWidth = useViewPort();
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");

	const {
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			title: isEditForm ? formDataValues?.title : "",
			description: isEditForm ? formDataValues?.description : "",
			currencyId: isEditForm ? formDataValues?.currencyId : "",
			amount: isEditForm ? formDataValues?.amount : "",
			expenditureDate: isEditForm ? formDataValues?.expenditureDate.slice(0, 10) : "",
		}
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
			"text/pdf": [".pdf"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (pdf, png, jpeg, jpg, heic or svg)");
		},
	});

	const { mutate: createRequest } = useCreateReimbursementRequest();
	const { mutate: updateRequest } = useUpdateReimbursementRequest();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = async (data: any) => {
		setUploading(true);

		let attachmentUrls: string[] = [];
		if (file) {
			setFileStatus("uploading");

			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				attachmentUrls.push(fileUrl);
				setFileStatus("success");
			} else {
				setFileStatus("error");
				setUploading(false);
				return
			}
		}
		if (attachmentUrls.length) data.attachmentUrls = attachmentUrls;

		if (isEditForm) {
			updateRequest(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createRequest(
				{
					payload: {
						...data,
						employeeId: companyProfile.employeeId,
					}
				},
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setFile(null);
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent
			width={viewPortWidth < 1024 ? "92%" : 660}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Title" for="title" />
						<input
							placeholder={"Enter title"}
							type="text"
							{...register("title", {
								required: "Enter title here",
							})}
							id="title"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.title
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.title && (
							<p className="text-red-500 text-sm mt-1">{errors.title.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Expenditure Date" for="expenditureDate" />
						<input
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("expenditureDate", {
								required: "Enter end date here",
								validate: validatePast,
							})}
							id="expenditureDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.expenditureDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.expenditureDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.expenditureDate.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Amount" for="amount" />
						<MoneyInput 
							attributes={{
								id: "amount",
								placeholder: "0"
							}}
							register={register}
							name="amount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.amount : ""}
							required="Enter amount here"
							error={errors.amount}
						/>
						{errors.amount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.amount?.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency" for="currencyId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.currencyId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currencyId", {
								required: "Enter currency here",
								valueAsNumber: true,
							})}
						>
							<option value="">Select Currency</option>
							{companyCurrencies.map((item) => (
								<option key={item.id} value={item.id}>
									{item.currency?.name + ` (${item.currency?.code})`}
								</option>
							))}
						</select>
						{errors.currencyId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.currencyId?.message}
							</p>
						)}
					</div>
				</div>
				{!isEditForm && (
					<div className="w-full mt-8">
						<Label title="Attached File" for="" optional />
						<div
							{...getRootProps()}
							className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
							flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
						>
							<input {...getInputProps()} />
							<BsUpload className="text-xl mb-2" />
							<p>
								Upload receipt or proof 
								<span className="font-semibold"> (2mb max)</span>
							</p>
							<p>(pdf, png, jpeg, jpg, heic or svg)</p>
						</div>
					</div>
				)}
				{file && (
					<div className="flex items-center space-x-4 w-full mt-1">
						<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
						<div className="flex flex-col items-start w-full">
							<div className="flex justify-between items-center w-full mb-1">
								<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
									{file.name}
								</p>
								<button 
									type="button"
									onClick={() => {
										setFile(null);
										setFileStatus("");
									}}
								>
									<IoMdClose className="text-xl hover:scale-[1.1]" />
								</button>
							</div>
							<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
								<LinearProgress
									color={fileStatus === "error" ? "error" : "primary"}
									variant={"determinate"}
									value={fileStatus ? 100 : 0}
								/>
							</Box>
							<div className="flex justify-between text-xs w-full">
								<p>
									File size: {(file.size / 1000000).toFixed(2)}mb
								</p>
								{fileStatus === "success" ? (
									<span className="text-green-500 text-xs">
										File uploaded successfully
									</span>
								) : fileStatus === "error" ? (
									<span className="text-red-500 text-xs">
										File upload error! Try again
									</span>
								) : null}
							</div>
						</div>
					</div>
				)}
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton 
						type="submit"
						title={
							uploading 
							? (fileStatus === "uploading") 
								? "Uploading File..." 
								: "Saving Claim..." 
							: isEditForm 
								? "Update Claim"
								: "Submit Claim"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading} 
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default ReimbursementRequestModal;
