import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    GradeLevelDto, GradeLevelPaginator,
    CreateGradeLevelDto,
    UpdateGradeLevelDto,
    QueryGradeLevelDto,
    SearchGradeLevelDto
} from "../models/grade-level.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetGradeLevel(options?: QueryGradeLevelDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.GET;
    const request = (options: QueryGradeLevelDto) => API.ProcessingCompanyAPI.getGradeLevel(options);

	const { data, isLoading, error, refetch } = useQuery<GradeLevelPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetGradeLevelSearch() {
    const queryClient = useQueryClient();

    return useMutation(
        (options?: SearchGradeLevelDto) => API.ProcessingCompanyAPI.getGradeLevelSearch(options),
        {
            onSuccess: (response,) => {
                
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.GET);
            },
        }
    )
}

export function useGetGradeLevelById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.DETAIL
    const request = API.ProcessingCompanyAPI.getGradeLevelById(id);

    const {data, isLoading, error} = useQuery<{ data:GradeLevelDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateGradeLevel() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateGradeLevelDto}) => API.ProcessingCompanyAPI.createGradeLevel(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.GET);
            },
        }
    )
}

export function useUpdateGradeLevel() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateGradeLevelDto, id: number}) => API.ProcessingCompanyAPI.updateGradeLevel(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.GET);
            },
        }
    )
}

export function useDeleteGradeLevel() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteGradeLevel(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}