import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface Notification {
  id: number;
  message: string;
  date: string;
  read: boolean;
}

const defaultNotifications: Notification[] = [
    {
        id: 1,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
    {
        id: 2,
        message: "This is an unread notification message",
        date: "Today",
        read: true
    },
    {
        id: 3,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
    {
        id: 4,
        message: "This is an unread notification message",
        date: "Today",
        read: false
    },
];

type NotificationStore = {
    notifications: Notification[],
    unread: number;
    setNotifications: (notifications: Notification[]) => void,
    markAsRead: (id: number) => void,
    markAllAsRead: () => void,
    setUnread: () => void,
}

const useNotificationsStore = create(
    persist<NotificationStore>(
        (set, get) => ({
            notifications: defaultNotifications,
            unread: 0,
            setNotifications: (notifications: Notification[]) =>
                set((state) => ({ ...state, notifications })),
            markAsRead: (id: number) => {
                set((state) => ({
                    notifications: state.notifications.map((notification) =>
                        notification.id === id ? { ...notification, read: true } : notification
                    ),
                }))
            },
            markAllAsRead: () => {
                set((state) => ({
                    notifications: state.notifications.map((notification) => ({
                        ...notification,
                        read: true,
                    })),
                }))
            },
            setUnread: () => {
                const unread = get().notifications.reduce((count, notification) => {
                    return notification.read ? count : count + 1;
                }, 0);
        
                set((state) => ({ ...state, unread }));
            }
        }),
        {
            name: "notifications",
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useNotificationsStore;