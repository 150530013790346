import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { CreateOrUpdateOrganizationConfigDto, OrganizationConfig } from "../models/organization-config.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetOrganizationConfigById(organizationId: string) {
    const url = ENDPOINTS.ORGANIZATION.ORGANIZATION_CONFIG.DETAIL;
    const request = API.OrganizationAPI.getOrganizationConfigById(organizationId);

    const {data, isLoading, error} = useQuery<{ data: OrganizationConfig }, Error>(
        [url, organizationId],
        (() => request),
        {
            enabled: !!(organizationId),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateOrUpdateOrganizationConfig() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateOrUpdateOrganizationConfigDto}) => API.OrganizationAPI.createOrUpdateOrganizationConfig(payload),
        {
            onSuccess: () => {
                toast.success('Organization configurations updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                
            },
        }
    )
}
