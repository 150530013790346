import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useState } from "react";
import { useDeleteEmployeeRelative, useUpdateEmployeeRelative } from "../../../../../../services/employee-relative.service";
import { UpdateEmployeeRelativeDto, EmployeeRelativeDto } from "../../../../../../models/employee-relative.dto";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AiOutlineDownCircle } from "react-icons/ai";
import DeleteModal from "../../../../../../components/modals/DeleteModal";

interface IFormInputs extends UpdateEmployeeRelativeDto {}

interface IEditRelative {
	employeeRelative: EmployeeRelativeDto;
}

const EditRelative = ({ employeeRelative }: IEditRelative) => {
	const [relative, setRelative] = useState(employeeRelative);
	const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateEmployeeRelative } = useUpdateEmployeeRelative();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<IFormInputs>({
        defaultValues: {
            firstName: relative.firstName,
            lastName: relative.lastName,
            otherNames: relative.otherNames,
            gender: relative.gender,
            msisdn: relative.msisdn,
            idType: relative.idType,
            idNumber: relative.idNumber,
            relationship: relative.relationship,
        }
	});

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		updateEmployeeRelative(
			{ payload: data, id: relative.id },
			{
				onSuccess: (data) => {
                    setRelative(data.data);
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteEmployeeRelative } = useDeleteEmployeeRelative();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteEmployeeRelative(
			{ id: relative?.id },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg lg:py-2 mb-2",
            }}
        >
            <AccordionSummary
                expandIcon={<AiOutlineDownCircle className="text-lg lg:text-xl" />}
                aria-controls={relative.id + "bh-content"}
                id={relative.id + "bh-header"}
                sx={{ border: "1px" }}
            >
                <h2 className="px-1 lg:px-3 font-inter text-2xs lg:text-sm font-semibold">
                    {relative.firstName + " " + relative.lastName}
                </h2>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handleSubmit(onSubmit)} className="px-1 lg:px-3">
                    <div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
                        <h2 className="font-semibold text-2xs">Details</h2>
                        <button
                            type="button"
                            className={`border-none text-primary-blue text-2xs ${
                                !formDisabled ? "hidden" : ""
                            }`}
                            onClick={(e) => setFormDisabled(false)}
                        >
                            Edit
                        </button>
                    </div>
                    <div className="lg:grid grid-cols-2 gap-x-4 gap-y-6 mt-4 space-y-4 lg:space-y-0">
                        <div className="w-full">
                            <Label title="First Name" for="firstName" />
                            <input
                                defaultValue={relative.firstName}
                                placeholder={"Enter first name"}
                                disabled={formDisabled}
                                type="text"
                                {...register("firstName", {
                                    required: "Enter first name here",
                                })}
                                id="firstName"
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.firstName
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors.firstName && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.firstName.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Last Name" for="lastName" />
                            <input
                                defaultValue={relative.lastName}
                                placeholder={"Enter last name"}
                                disabled={formDisabled}
                                type="text"
                                {...register("lastName", {
                                    required: "Enter last name here",
                                })}
                                id="lastName"
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.lastName
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors.lastName && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.lastName.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Other Names" for="otherNames" />
                            <input
                                defaultValue={relative.otherNames}
                                placeholder={"Enter other names"}
                                disabled={formDisabled}
                                type="text"
                                {...register("otherNames", {
                                    required: "Enter other name here",
                                })}
                                id="otherNames"
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.otherNames
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors.otherNames && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.otherNames.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Gender" for="gender" />
                            <select
                                defaultValue={relative.gender}
                                disabled={formDisabled}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.gender
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                id="gender"
                                {...register("gender", {
                                    required: "Select gender here",
                                })}
                            >
                                <option value="">
                                    Select Gender
                                </option>
                                <option value="MALE">Male</option>
                                <option value="FEMALE">Female</option>
                            </select>
                            {errors.gender && (
                                <p className="text-red-500 text-sm mt-1">{errors.gender.message}</p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Telephone" for="msisdn"/>
                            <Controller
                                name={"msisdn"}
                                control={control}
                                rules={{
                                    required: false,
                                    maxLength: {
                                        value: 12,
                                        message: "Enter a valid phone number",
                                    },
                                }}
                                render={({ field }) => (
                                    <div
                                        className={`w-full bg-secondary-gray rounded-lg mt-2 flex items-center ${
                                            errors.msisdn
                                                ? "border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                    >
                                        <PhoneInput
                                            {...field}
                                            disabled={formDisabled}
                                            country={"gh"}
                                            value={relative.msisdn}
                                            inputStyle={{
                                                height: "50px",
                                                border: "none",
                                                backgroundColor: "#F2F2F4",
                                                color: "black",
                                                boxShadow: "none",
                                                width: "95%",
                                                margin: "0px 0px 0px 15px",
                                                borderRadius: "10px",
                                            }}
                                            containerStyle={{
                                                // marginTop: "8px",
                                                height: "50px",
                                                border: "none",
                                                backgroundColor: "#F2F2F4",
                                                boxShadow: "none",
                                                borderRadius: "10px",
                                            }}
                                            buttonStyle={{
                                                border: "none",
                                                backgroundColor: "#F2F2F4",
                                                boxShadow: "none",
                                                padding: "0px 10px",
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            {errors.msisdn && (
                                <p className="text-red-500 text-sm mt-1">{errors.msisdn.message}</p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Relationship" for="relationship" />
                            <select
                                defaultValue={relative.relationship}
                                disabled={formDisabled}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.relationship
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                id="relationship"
                                {...register("relationship", {
                                    required: "Select relationship here",
                                })}
                            >
                                <option value="">
                                    Select Relationship
                                </option>
                                <option value="SPOUSE">Spouse</option>
                                <option value="CHILD">Child</option>
                                <option value="PARENT">Parent</option>
                                <option value="GUARDIAN">Gurdian</option>
                                <option value="DEPENDENT">Dependent</option>
                            </select>
                            {errors.relationship && (
                                <p className="text-red-500 text-sm mt-1">{errors.relationship.message}</p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Identification Type" for="idType" />
                            <select
                                defaultValue={relative.idType}
                                disabled={formDisabled}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.idType
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                id="idType"
                                {...register("idType", {
                                    required: "Select Id type here",
                                })}
                            >
                                <option value="">
                                    Select Type
                                </option>
                                <option value="VOTERS">Voters</option>
                            </select>
                            {errors.idType && (
                                <p className="text-red-500 text-sm mt-1">{errors.idType.message}</p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Identification Number" for="idNumber" />
                            <input
                                defaultValue={relative.idNumber}
                                disabled={formDisabled}
                                placeholder={"Enter number"}
                                type="text"
                                {...register("idNumber", {
                                    required: "Enter Id number here",
                                })}
                                id="idNumber"
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.idNumber
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors.idNumber && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.idNumber.message}
                                </p>
                            )}
                        </div>
                        <div className="col-span-2 flex space-x-3 items-center">
                            <input 
                                type="checkbox"
                                defaultChecked={relative.nextOfKin === true}
                                {...register("nextOfKin", {
                                    required: false,
                                })}
                                id="nextOfKin"
                                disabled={formDisabled}
                            />
                            <label htmlFor="nextOfKin" className="max-lg:text-sm">Next Of Kin</label>
                        </div>
                        <div className="col-span-2 flex space-x-3 items-center">
                            <input 
                                type="checkbox"
                                defaultChecked={relative.active === true}
                                {...register("active", {
                                    required: false,
                                })}
                                id="active"
                                disabled={formDisabled}
                            />
                            <label htmlFor="active" className="max-lg:text-sm">Active</label>
                        </div>
                    </div>
					
					{!formDisabled && (
						<div className="w-full flex items-center gap-4 lg:justify-end mt-10">
							<button
								type="button"
								className="px-12 py-4 max-lg:w-full border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12 max-lg:w-full"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={relative.firstName + " " + relative.lastName}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default EditRelative;