import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import API from './utils/api'
import {CreatePayrollCompanyDto} from "../models/company-account.dto";
import {
    CreateEmployeeDto,
    UpdateEmployeeDto,
    EmployeePaginator,
    EmployeeDto,
    QueryEmployeeDto,
	CountEmployeeDto,
	SearchEmployeeDto
} from "../models/employee.dto";
import ENDPOINTS from "./utils/endpoints";
import { toast } from "react-toastify";

export function useGetEmployee(options?: QueryEmployeeDto) {
	const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.GET;
	const request = (options: QueryEmployeeDto) =>
		API.ProcessingCompanyAPI.getEmployee(options);

	const { data, isLoading, error, refetch } = useQuery<EmployeePaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetEmployeeSearch() {

    return useMutation(
        (options?: SearchEmployeeDto) => API.ProcessingCompanyAPI.getEmployeeSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetEmployeeCount(options?: CountEmployeeDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.GET_COUNT;
    const request = API.ProcessingCompanyAPI.getEmployeeCount(options);

    const {data, isLoading, error} = useQuery<{ data: { count: number} }, Error>(
        [url, options],
        (() => request),
    );

    return {
        data,
        isLoading,
        error,
    };
}

export function useGetEmployeeById(id: number) {
	const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.DETAIL;
	const request = API.ProcessingCompanyAPI.getEmployeeById(id);

	const { data, isLoading, error } = useQuery<{ data: EmployeeDto }, Error>(
		[url, id],
		() => request,
		{
			enabled: !!id,
		}
	);

	return {
		data: data,
		isLoading,
		error,
	};
}

export function useCreateEmployee() {

	return useMutation(
		({ payload }: { payload: CreateEmployeeDto }) =>
			API.ProcessingCompanyAPI.createEmployee(payload),
		{
			onSuccess: () => {
				toast.success("Employee successfully created");
			},
			onError: API.Handlers.onErrorHandler,
			onSettled: () => {},
		}
	);
}

export function useUpdateEmployee() {

    return useMutation(
			({ payload, id }: { payload: UpdateEmployeeDto; id: number }) =>
				API.ProcessingCompanyAPI.updateEmployee(id, payload),
			{
				onSuccess: () => {
					toast.success("Employee details updated successfully");
				},
				onError: API.Handlers.onErrorHandler,
				onSettled: () => {},
			}
		);
}

export function useUploadEmployee() {

    return useMutation(
			({ payload, companyId }: { payload: FormData; companyId: number }) =>
				API.ProcessingCompanyAPI.updloadEmployee(companyId, payload),
			{
				onSuccess: () => {
				},
				onError: API.Handlers.onErrorHandler,
				onSettled: () => {},
			}
		);
}

export function useDeleteEmployee() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteEmployee(id),
        {
            onSuccess: () => {
                toast.success('Employee deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}