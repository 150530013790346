import { useState, useEffect, useMemo } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useDropzone } from "react-dropzone";
import { BsUpload, BsFillFileEarmarkTextFill } from "react-icons/bs";
import Label from "../../../../components/form_fields/Label";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { CountryDto } from "../../../../models/country.dto";
import { BillingTypeDto } from "../../../../models/billing-type.dto";
import { GenericOrganizationConfigDto } from "../../../../models/organization.dto";
import { useCreateOrganization, useUpdateOrganization } from "../../../../services/organization.service";
import { useCreateOrUpdateOrganizationConfig } from "../../../../services/organization-config.service";
import { uploadOneDocument } from "../../../../services/document.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useAccountCheck } from "../../../../services/user.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { CustomizedTooltip, IAddEditTableForm } from "../../../../helpers/appHelpers";
import { FaQuestionCircle } from "react-icons/fa";

type FileStatus = "success" | "error" | "uploading" | ""

interface IFormInputs {
	name: string;
    countryId: number;
    contactEmail: string;
    billingContactEmail: string;
    contactMsisdn?: string;
    address?: string;
    logoUrl?: string;
	adminProfile: {
		username: string;
		emailAddress: string;
		firstName?: string;
		lastName?: string;
	};
    config?: GenericOrganizationConfigDto;
}

type AccountModalFormProps = IAddEditTableForm & {
	countries: CountryDto[];
	billingTypes: BillingTypeDto[];
}

const AccountModalForm = ({ 
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries, 
	billingTypes,
}: AccountModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");
	const [usernameExists, setUsernameExists] = useState(false);
	const [unlimitedConfig, setUnlimitedConfig] = useState<Record<string, boolean>>(
		isEditForm ?
		{
			maxCompanies: formDataValues?.config?.maxCompanies === -1,
			maxEmployees: formDataValues?.config?.maxEmployees === -1,
			maxUsers: formDataValues?.config?.maxUsers === -1,
		}
		: {}
	);
	const [uploading, setUploading] = useState(false);

	const contractBillingTypeId = useMemo(() => {
		return billingTypes.find(
			type => !type.requireSubscription
		)?.id || 3
	}, [billingTypes.length]);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : undefined,
			contactEmail: isEditForm ? formDataValues?.contactEmail : undefined,
			countryId: isEditForm ? formDataValues?.countryId : undefined,
			contactMsisdn: isEditForm ? formDataValues?.contactMsisdn : undefined,
			address: isEditForm ? formDataValues?.address : undefined,
			config: {
				maxCompanies: isEditForm ? formDataValues?.config?.maxCompanies : undefined,
				maxEmployees: isEditForm ? formDataValues?.config?.maxEmployees : undefined,
				maxUsers: isEditForm ? formDataValues?.config?.maxUsers : undefined
			}
		}
	});

	const username = watch("adminProfile.username");
	const { mutate: validateUsername } = useAccountCheck();

	useEffect(() => {
		if (!username?.trim() || isEditForm) {
			setUsernameExists(false);
			return
		}

		validateUsername(
			{ payload: { username: username?.trim() } },
			{ onSuccess: (data) => setUsernameExists(data.data.exists) }
		)
	}, [username])

	const handleAccountConfig = (config: "maxUsers" | "maxCompanies" | "maxEmployees") => {
		let configUnlimited = false;

		setUnlimitedConfig(prev => {
			configUnlimited = !Boolean(prev[config]);
			return { ...prev, [config]: !Boolean(prev[config]) }
		});

		if (configUnlimited) {
			setValue(`config.${config}`, -1);
		} else {
			setValue(`config.${config}`, undefined);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (png, jpeg, jpg, heic or svg)");
		},
	});

	const { mutate: createOrganization } = useCreateOrganization();
	const { mutate: updateOrganization } = useUpdateOrganization();
	const { mutate: updateOrganizationConfig } = useCreateOrUpdateOrganizationConfig();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		if (usernameExists) return;
		setUploading(true);
		
		if (file) {
			setFileStatus("uploading");

			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url;

			if (fileUrl) {
				data.logoUrl = fileUrl;
				setFileStatus("success");
			} else {
				setFileStatus("error");
			}
		}

		if (isEditForm) {
			const config = data.config;
			delete (data as any).config;
			data.billingContactEmail = data.contactEmail;

			updateOrganization(
				{ payload: data as any, id: formDataValues.id },
				{
					onSuccess: (updatedData) => {
						if (
							formDataValues?.billingType?.name === "Contract" &&
							formDataValues?.status !== "SUSPENDED" &&
							!(
								config?.maxCompanies === formDataValues?.config?.maxCompanies &&
								config?.maxUsers === formDataValues?.config?.maxUsers &&
								config?.maxEmployees === formDataValues?.config?.maxEmployees
							)
						) {
							updateOrganizationConfig(
								{ payload: { organizationId: formDataValues.id, ...config } },
								{
									onSuccess: (updatedConfig) => {
										const updatedOrganization = {
											...updatedData.data,
											config: updatedConfig.data,
										};

										tableAPIRef?.current.updateRows([updatedOrganization]);
										setOpen(false);
									},
									onSettled: () => setUploading(false),
								}
							)
						} else {
							tableAPIRef?.current.updateRows([updatedData.data]);
							setOpen(false);
						}
					},
					onError: () => setUploading(false),
				}
			)
		} else {
			createOrganization(
				{ 
					payload: { 
						...data, 
						contactEmail: data.adminProfile.emailAddress,
						billingContactEmail: data.adminProfile.emailAddress,
						billingTypeId: contractBillingTypeId,
					} as any
				},
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
					},
					onSettled: () => setUploading(false),
				}
			)
		}
	};

	const handleClose = () => {
		setFile(null);
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="First Name" for="firstName" />
							<input
								type="text"
								{...register("adminProfile.firstName", {
									required: "Enter your first name",
								})}
								id="firstName"
								placeholder="Enter first name"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.adminProfile?.firstName
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.firstName && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.firstName.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Last Name" for="lastName" />
							<input
								type="text"
								{...register("adminProfile.lastName", {
									required: "Enter your last name",
								})}
								id="lastName"
								placeholder="Enter last name"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.adminProfile?.lastName
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.lastName && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.lastName.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Username" for="userName" />
							<input
								type="text"
								{...register("adminProfile.username", {
									required: "Enter your username",
								})}
								id="userName"
								placeholder="Enter username"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.adminProfile?.username
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{(!usernameExists && errors.adminProfile?.username) && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.username.message}
								</p>
							)}
							{usernameExists && (
								<p className="text-red-500 text-sm mt-1">
									Username already taken
								</p>
							)}
						</div>
						<div className="w-full">
							<div className="flex items-center">
								<Label title="Email" for="adminProfile.emailAddress" />
								<CustomizedTooltip 
									title="This will be used for user authentication and also serve as the contact email of the organization." 
									placement="top"
									arrow
								>
									<Button variant="text" sx={{ padding: "0", position: "relative", right: "20px" }}>
										<FaQuestionCircle className="text-black" />
									</Button>
								</CustomizedTooltip>
							</div>
							<input
								type="email"
								{...register("adminProfile.emailAddress", {
									required: "Enter your email",
									pattern: {
										value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
										message: "Enter a valid email address",
									},
								})}
								id="adminProfile.emailAddress"
								placeholder="Enter email address"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.adminProfile?.emailAddress
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.emailAddress && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.emailAddress.message}
								</p>
							)}
						</div>
					</>)}
					<div className="w-full">
						<Label title="Organization Name" for="organizationName" />
						<input
							type="text"
							{...register("name", {
								required: "Enter your organization name",
							})}
							id="organizationName"
							placeholder="Enter organization name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">
								{errors.name.message}
							</p>
						)}
					</div>
					{isEditForm && (
						<div className="w-full">
							<Label title="Contact Email" for="contactEmail" />
							<input
								type="email"
								{...register("contactEmail", {
									required: "Enter your email",
									pattern: {
										value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
										message: "Enter a valid email address",
									},
								})}
								id="contactEmail"
								placeholder="Enter email address"
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.contactEmail
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.contactEmail && (
								<p className="text-red-500 text-sm mt-1">
									{errors.contactEmail.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Country" for="countryId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.countryId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("countryId", {
								required: "Select country here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Country
							</option>
							{countries.map((country) => (
								<option key={country.id} value={country.id} >
									{country.name}
								</option>
							))}
						</select>
						{errors.countryId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.countryId.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label title="Address" for="address" optional />
							<input
								placeholder={"Enter address"}
								type="text"
								{...register("address", {
									required: false,
								})}
								id="address"
								className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
							/>
						</div>
					)}
					<div className="w-full">
						<Label title="Telephone" for="contactMsisdn" optional />
						<Controller
							name={"contactMsisdn"}
							control={control}
							rules={{ required: false }}
							render={({ field }) => (
								<div className="w-full bg-secondary-gray rounded-lg mt-2 flex items-center justify-center border-none">
									<PhoneInput
										{...field}
										country={"gh"}
										inputStyle={{
											height: errors.contactMsisdn ? "50px" : "48px",
											border: "none",
											backgroundColor: "#F2F2F4",
											color: "black",
											boxShadow: "none",
											width: "95%",
											margin: "0px 0px 0px 15px",
											borderRadius: "10px",
										}}
										containerStyle={{
											margin: "4px 0px",
											height: errors.contactMsisdn ? "50px" : "48px",
											border: "none",
											backgroundColor: "#F2F2F4",
											boxShadow: "none",
											borderRadius: "10px",
										}}
										buttonStyle={{
											border: "none",
											backgroundColor: "#F2F2F4",
											boxShadow: "none",
											padding: "0px 10px",
										}}
									/>
								</div>
							)}
						/>
					</div>
					{isEditForm && (
						<div className="col-span-2 w-full">
							<Label title="Address" for="address" optional />
							<input
								placeholder={"Enter address"}
								type="text"
								{...register("address", {
									required: false,
								})}
								id="address"
								className="w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
							/>
						</div>
					)}
					{(!isEditForm || (isEditForm && 
							formDataValues?.billingType?.name === "Contract" && 
							formDataValues?.status !== "SUSPENDED")
					) && (
						<div className="w-full col-span-2 mt-2">
							<h3 className="font-semibold text-base mb-4">
								Account Configurations
							</h3>
							<div className="grid grid-cols-2 gap-x-4 gap-y-6">
								<div className="w-full">
									<div className="flex items-center justify-between">
										<Label title="Maximum Users" for="config.maxUsers" />
										<div 
											onClick={() => handleAccountConfig("maxUsers")}
											className="px-2 rounded border border-black text-xs cursor-pointer hover:scale-[1.05]"
										>
											{unlimitedConfig["maxUsers"] ? "Cancel" : "Unlimited"}
										</div>
									</div>
									<div className="relative">
										<input
											placeholder={"Enter max users"}
											type="number"
											{...register("config.maxUsers", {
												required: "Enter max users here",
												valueAsNumber: true,
											})}
											id="config.maxUsers"
											min={-1}
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm 
												${errors.config?.maxUsers
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
												}
												${unlimitedConfig["maxUsers"] ? "invisible" : ""}
											 `}
										/>
										{unlimitedConfig["maxUsers"] && (
											<input
												type="text"
												value="Unlimited"
												className="absolute inset-0 w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
												disabled
											/>
										)}
									</div>
									{errors.config?.maxUsers && (
										<p className="text-red-500 text-sm mt-1">{errors.config.maxUsers.message}</p>
									)}
								</div>
								<div className="w-full">
									<div className="flex items-center justify-between">
										<Label title="Maximum Companies" for="config.maxCompanies" />
										<div 
											onClick={() => handleAccountConfig("maxCompanies")}
											className="px-2 rounded border border-black text-xs cursor-pointer hover:scale-[1.05]"
										>
											{unlimitedConfig["maxCompanies"] ? "Cancel" : "Unlimited"}
										</div>
									</div>
									<div className="relative">
										<input
											placeholder={"Enter max companies"}
											type="number"
											{...register("config.maxCompanies", {
												required: "Enter max companies here",
												valueAsNumber: true,
											})}
											id="config.maxCompanies"
											min={-1}
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm 
												${errors.config?.maxCompanies
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
												}
												${unlimitedConfig["maxCompanies"] ? "invisible" : ""}
											 `}
										/>
										{unlimitedConfig["maxCompanies"] && (
											<input
												type="text"
												value="Unlimited"
												className="absolute inset-0 w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
												disabled
											/>
										)}
									</div>
									{errors.config?.maxCompanies && (
										<p className="text-red-500 text-sm mt-1">{errors.config?.maxCompanies.message}</p>
									)}
								</div>
								<div className="w-full">
									<div className="flex items-center justify-between">
										<Label title="Maximum Employees" for="config.maxEmployees" />
										<div 
											onClick={() => handleAccountConfig("maxEmployees")}
											className="px-2 rounded border border-black text-xs cursor-pointer hover:scale-[1.05]"
										>
											{unlimitedConfig["maxEmployees"] ? "Cancel" : "Unlimited"}
										</div>
									</div>
									<div className="relative">
										<input
											placeholder={"Enter max employees"}
											type="number"
											{...register("config.maxEmployees", {
												required: "Enter max employees here",
												valueAsNumber: true,
											})}
											id="config.maxEmployees"
											min={-1}
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm 
												${errors.config?.maxEmployees
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
												}
												${unlimitedConfig["maxEmployees"] ? "invisible" : ""}
											 `}
										/>
										{unlimitedConfig["maxEmployees"] && (
											<input
												type="text"
												value="Unlimited"
												className="absolute inset-0 w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
												disabled
											/>
										)}
									</div>
									{errors.config?.maxEmployees && (
										<p className="text-red-500 text-sm mt-1">{errors.config?.maxEmployees.message}</p>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
                <div className="w-full mt-6">
                    <Label title={`${isEditForm ? "Update" : ""} Company Logo`} for="" optional />
                    <div
                        {...getRootProps()}
                        className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
                        flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
                    >
                        <input {...getInputProps()} />
                        <BsUpload className="text-xl mb-2" />
                        <p>
                            Upload Company Logo
                            <span className="font-semibold"> (2mb max)</span>
                        </p>
                        <p>(png, jpeg, jpg, heic or svg)</p>
                    </div>
                </div>
				{file && (
					<div className="flex items-center space-x-4 w-full mt-1">
						<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
						<div className="flex flex-col items-start w-full">
							<div className="flex justify-between items-center w-full mb-1">
								<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
									{file.name}
								</p>
								<button onClick={() => {
									setFile(null);
									setFileStatus("");
								}}>
									<IoMdClose className="text-xl hover:scale-[1.1]" />
								</button>
							</div>
							<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
								<LinearProgress
									color={fileStatus === "error" ? "error" : "primary"}
									variant={"determinate"}
									value={fileStatus ? 100 : 0}
								/>
							</Box>
							<div className="flex justify-between text-xs w-full">
								<p>
									File size: {(file.size / 1000000).toFixed(2)}mb
								</p>
								{fileStatus === "success" ? (
									<span className="text-green-500 text-xs">
										File uploaded successfully
									</span>
								) : fileStatus === "error" ? (
									<span className="text-red-500 text-xs">
										File upload error! Try again
									</span>
								) : null}
							</div>
						</div>
					</div>
				)}

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Account"
								: "Create Account"
						}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default AccountModalForm;
