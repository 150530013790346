import React from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../components/form_fields/Label";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Helmet } from "react-helmet-async";

interface IFormInputs {
	password: string;
	confirmPassword: string;
}

const CreatePassword = () => {
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInputs>();
	const [isShown, setIsShown] = React.useState(false);

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		console.log(data, "data");
		navigate("/signin");
	};
	return (
		<OnboardingLayout>
			<Helmet>
				<title>Create Password | Akatua</title>
			</Helmet>
			<div className="flex flex-col px-24 justify-center w-full">
				<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />
				<h1 className="mt-5 font-bold text-xl text-charcoal">
					Hey 👋, welcome to Akatua
				</h1>
				<div className="mt-4">
					<form className="text-charcoal">
						<div className="mt-6 flex items-center w-full space-x-4">
							<div className="w-full">
								<Label title="Password" for="password" />
								<div className="relative">
									<input
										type={!isShown ? "password" : "text"}
										id={"password"}
										placeholder={"Enter password"}
										className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
											errors.password
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										{...register("password", {
											required: "Enter your password here",
											minLength: {
												value: 8,
												message: "Password should be at least 8 characters",
											},
										})}
									/>

									<div
										className={`absolute top-[26px] right-6 block `}
										onClick={() => {
											setIsShown(!isShown);
										}}
									>
										{!isShown ? (
											<BsEyeSlash className="text-gray-400 text-lg" />
										) : (
											<BsEye className="text-gray-400 text-lg" />
										)}
									</div>
								</div>
								{errors.password && (
									<p className="text-red-500 text-sm mt-1">
										{errors.password.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Confirm Password" for="confirmPassword" />
								<div className="relative">
									<input
										type={!isShown ? "password" : "text"}
										id={"confirmPassword"}
										placeholder={"Re-Enter new password"}
										className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
											errors.confirmPassword
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										{...register("confirmPassword", {
											required: "Confirm your new password",
											validate: {
												matchesPreviousPassword: (value) => {
													const { password } = getValues();
													return (
														password === value || "Passwords should match!"
													);
												},
											},
										})}
									/>

									<div
										className={`absolute top-[26px] right-6 block `}
										onClick={() => {
											setIsShown(!isShown);
										}}
									>
										{isShown === false ? (
											<BsEyeSlash className="text-gray-400 text-lg" />
										) : (
											<BsEye className="text-gray-400 text-lg" />
										)}
									</div>
								</div>
								{errors.confirmPassword && (
									<p className="text-red-500 text-sm mt-1">
										{errors.confirmPassword.message}
									</p>
								)}
							</div>
						</div>

						<div className="w-[50%]">
							<p className="text-xs mt-8">
								By signing up you agree to the{" "}
								<span className="text-primary-blue">Terms and Conditions</span>
							</p>
							<OrangeButton
								title="Reset Password"
								className="w-full mt-10 font-light"
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</form>
				</div>
				{/* <p className="text-xs mt-6">
					Go back to{" "}
					<span className="text-primary-blue">
						<Link to="/signin">Sign in</Link>
					</span>
				</p> */}
			</div>
		</OnboardingLayout>
	);
};

export default CreatePassword;
