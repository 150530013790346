import { CompanyLevelLeavePackage } from "./company-level-leave-package.dto";
import { PaginatorInfo } from "./generic.dto";
import { LeavePackage } from "./leave-package.dto";

export type CompanyLevel = {
    id: number;
    organizationId: string;
    companyId: number | null;
    levelNumber: number;
    levelName: string;
    juniorLevel: boolean;
    parentId: number | null;
    childId: number | null;
    createdAt: string;
    modifiedAt: string | null;
}

export class CreateCompanyLevelDto {
  organizationId!: string;
  companyId?: number;
  levelNumber!: number;
  levelName!: string;
  parentId?: number;
  childId?: number;
  juniorLevel!: boolean;
}

export interface CompanyLevelDto extends CompanyLevel {
  companyLevelLeavePackages?: CompanyLevelLeavePackageDto[]
}

export interface CompanyLevelLeavePackageDto extends CompanyLevelLeavePackage {
  leavePackages?: LeavePackage
}

export class UpdateCompanyLevelDto {
  organizationId?: string;
  companyId?: number;
  levelNumber?: number;
  levelName?: string;
  parentId?: number;
  childId?: number;
  juniorLevel?: boolean;
}

export class QueryCompanyLevelDto {
  organizationId?: string;
  companyId?: number;
  levelNumber?: number;
  parentId?: number;
  childId?: number;
  juniorLevel?: boolean;
  includeLeavePackages?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy?: CompanyLevelOrderBy = CompanyLevelOrderBy.CREATED_AT_ASC;
}

export class IncludeLeavePackagesQueryDto {
  includeLeavePackages?: boolean;
}

export enum CompanyLevelOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  LEVEL_NUMBER_ASC = 'levelNumber:asc',
  LEVEL_NUMBER_DESC = 'levelNumber:desc',
  LEVEL_NAME_ASC = 'levelName:asc',
  LEVEL_NAME_DESC = 'levelName:desc',
}

export interface CompanyLevelPaginator extends PaginatorInfo<CompanyLevelDto> {}
