import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import Search from "../../../../../components/search/SearchInput";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { Columns } from "./data";
import CompanyCurrencyModalForm from "./modal";
import { 
	useGetCompanyCurrencyConfig, 
	useDeleteCompanyCurrencyConfig 
} from "../../../../../services/company-currency.service";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { CurrencyOrderBy } from "../../../../../models/currency.dto";
import { useGetCurrency } from "../../../../../services/currency.service";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";
import TableFilter from "../../../../../components/table/filter/TableFilter";

const CompanyCurrency = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const {
		tableAPIRef,
		tableData,
		fetchedTableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<CompanyCurrencyConfigDto>({
		fetchHook: useGetCompanyCurrencyConfig,
		options: { companyId: currentCompany?.id }
	});

	const { data: currencies } = useGetCurrency({ 
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		active: true,
		orderBy: CurrencyOrderBy.CREATED_AT_DESC
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCompanyCurrency } = useDeleteCompanyCurrencyConfig();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCompanyCurrency(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Company Currency</h2>
			<hr />
			<div className="flex items-center justify-between mt-7 mb-8">
				<div className="flex items-center gap-3">
					<Search 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Company Currency
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.currency?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<CompanyCurrencyModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={isEditForm
						? "Update Company Currency"
						: "Add New Company Currency"}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					currencies={currencies?.data || []}
				/>
			)}
		</div>
	);
};

export default CompanyCurrency;
