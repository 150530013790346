import { useEffect, useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useCreateBenefit, useUpdateBenefit } from "../../../../services/benefit.service";
import { enumToStringConverter, FREQUENCY, IAddEditTableForm } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import { BenefitType } from "../../../../models/benefit-type.dto";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { TaxCodeDto } from "../../../../models/tax-code.dto";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import { EmployeeDto } from "../../../../models/employee.dto";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";

interface IFormInputs {
	benefitTypeInstanceId: number;
	name: string;
	code: string;
	description: string;
	value: number;
	taxCode: string;
	frequency: string;
	employeeIds?: number[];
	employeeBandIds?: number[];
}

type IBenefitModalForm = IAddEditTableForm & {
	benefitTypes: BenefitType[];
	taxCodes: TaxCodeDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
}

const BenefitModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	benefitTypes,
	taxCodes,
	employees,
	employeeBands,
}: IBenefitModalForm) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [benefitType, setBenefitType] = useState("");
	
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			benefitTypeInstanceId: isEditForm ? formDataValues?.benefitTypeInstanceId : undefined,
			value: isEditForm ? formDataValues?.value : undefined,
			taxCode: isEditForm ? formDataValues?.taxCode : "",
			frequency: isEditForm ? formDataValues?.frequency : "",
		}
	});

	const benefitTypeInstanceId = watch("benefitTypeInstanceId");
	useEffect(() => {
		if (!benefitTypeInstanceId) return;

		const selected = benefitTypes.find(
			benefitType => benefitType.id === benefitTypeInstanceId
		);

		setBenefitType(selected?.benefitType || "");
	}, [benefitTypeInstanceId])

	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const { mutate: createBenefit } = useCreateBenefit();
	const { mutate: updateBenefit } = useUpdateBenefit();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		if (isEditForm) {
			updateBenefit(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeIds = employeeIds.length ? employeeIds as any : undefined;
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;

			createBenefit(
				{ payload: { companyId: currentCompany?.id, ...data } },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={775}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							autoFocus
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<label
							className="text-2xs text-secondary-darkgray"
							htmlFor="description"
						>
							Description
						</label>
						<FormDescriptionInput 
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Benefit Type" for="benefitTypeInstanceId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.benefitTypeInstanceId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("benefitTypeInstanceId", {
								required: "Select benefit type here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Type
							</option>
							{benefitTypes.map((benefitType) => (
								<option 
									key={benefitType.id} 
									value={benefitType.id}
								>
									{benefitType.name}
								</option>
							))}
						</select>
						{errors.benefitTypeInstanceId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.benefitTypeInstanceId.message}
							</p>
						)}
					</div>
					{benefitType !== "GIFT" && (<>
						<div className="w-full">
							<Label title="Value" for="value" />
							<MoneyInput 
								attributes={{
									id: "value",
									placeholder: "0"
								}}
								register={register}
								name="value"
								setValue={setValue}
								defaultValue={isEditForm ? formDataValues?.value : undefined}
								required="Enter value here"
								error={errors.value}
							/>
							{errors.value && (
								<p className="text-red-500 text-sm mt-1">
									{errors.value.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Tax Code" for="taxCode" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.taxCode
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("taxCode", {
									required: "Select tax code here",
								})}
							>
								<option value="">
									Select Tax Code
								</option>
								{taxCodes.map((taxCode) => (
									<option 
										key={taxCode.code} 
										value={taxCode.code} 
									>
										{taxCode.code}
									</option>
								))}
							</select>
							{errors.taxCode && (
								<p className="text-red-500 text-sm mt-1">
									{errors.taxCode.message}
								</p>
							)}
						</div>
						{benefitType !== "MEDICAL" && (
							<div className="w-full">
								<Label title="Frequency" for="frequency" />
								<select
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
										errors.frequency
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("frequency", {
										required: "Select frequency here",
									})}
								>
									<option value="">
										Select frequency
									</option>
									{FREQUENCY.map((item, index) => (
										<option key={index} value={item}>
											{enumToStringConverter(item)}
										</option>
									))}
								</select>
								{errors.frequency && (
									<p className="text-red-500 text-sm mt-1">
										{errors.frequency.message}
									</p>
								)}
							</div>
						)}
					</>)}
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="Assign Employees" for="employeeIds" optional />
							<MultiSelectField
								key={"employeeIds"}
								options={employees}
								setResourceIds={setEmployeeIds}
								placeholder={"Select Employees"}
								pathname={PATHS.EMPLOYEES}
								required={false}
								mapTexts={["firstName", "lastName"]}
							/>
						</div>
						<div className="w-full">
							<Label title="Assign Employee Bands" for="employeeBandIds" optional />
							<MultiSelectField
								key={"employeeBandIds"}
								options={employeeBands}
								setResourceIds={setEmployeeBandIds}
								placeholder={"Select Bands"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
								required={false}
							/>
						</div>
					</>)}
				</div>
				<div className="w-full flex justify-end mt-12">
					<OrangeButton
						type="submit"
						title={`${
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Benefit" 
								: "Add Benefit"}`
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default BenefitModalForm;
