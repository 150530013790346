import { PaginatorInfo } from "./generic.dto";

export enum TRANSACTION_TYPE {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    REVERSAL = 'REVERSAL',
    ALL = 'ALL'
}

export enum REQUEST_TYPE {
  MANUAL = 'MANUAL',
  PERIODIC = 'PERIODIC'
}

export class CreateStatementRequestDto {
  organizationId!: string;
  transactionType!: TRANSACTION_TYPE;
  startDate!: string;
  endDate!: string;
  recipientEmail!: string;
}

export class UpdateStatementRequestDto {
  startDate?: Date;
  endDate?: Date;
  transactionType!: TRANSACTION_TYPE;
}

export enum StatementRequestOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
}
export class QueryStatementRequestDto {
  organizationId?: string;
  transactionType?: TRANSACTION_TYPE;
  requestType?: REQUEST_TYPE;
  includedRelations?: boolean;
  page?: number = 1;
  limit?: number;;
  orderBy?: StatementRequestOrderBy = StatementRequestOrderBy.CREATED_AT_DESC;
}

export class StatementRequestDto {
  id!: number;
  organizationId!: string;
  transactionType!: TRANSACTION_TYPE;
  requestType!: REQUEST_TYPE;
  startDate!: Date;
  endDate!: Date;
  recipientEmail!: string;
  createdAt!: Date;
  completedAt!: Date | null;
  completed!: boolean;
}

export interface StatementRequestPaginator extends PaginatorInfo<StatementRequestDto> {}
