import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { useCreateCompanyGrouping } from "../../../../../services/company-grouping.service";
import { COMPANY_GROUPING_TYPE } from "../../../../../models/company-grouping.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	active: boolean;
}

interface INewCompanyGroup {
	type: string;
	setShowNewCompanyGroupForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewCompanyGroup = ({
	type,
	setShowNewCompanyGroupForm,
}: INewCompanyGroup) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createCompanyGroup } = useCreateCompanyGrouping();
	const { addNewAccordionsData } = useManageAccordionsData();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createCompanyGroup(
			{
				payload: {
					...data,
					type: type as COMPANY_GROUPING_TYPE,
					companyId: currentCompany?.id as number,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewCompanyGroupForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Name" for={`name`} />
					<input
						placeholder={"Enter Name"}
						type="text"
						{...register(`name`, {
							required: "Enter name here",
						})}
						id={`name`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.name && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.name?.message}
						</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active")}
						id="active"
					/>
					<label htmlFor="active">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewCompanyGroupForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : `Add ${type.toLowerCase()}`}`}
					className={"h-14 px-8"}
					type="submit"
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewCompanyGroup;
