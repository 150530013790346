import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FREQUENCY } from "../../../../../models/savings-scheme.dto";
import { useDeleteWorkDaysInAPeriod, useUpdateWorkDaysInAPeriod } from "../../../../../services/work-days-in-a-period.service";
import { WorkDaysInAPeriodDto } from "../../../../../models/work-days-in-a-period.dto";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import { enumToStringConverter } from "../../../../../helpers/appHelpers";

interface IFormInputs {
	period: keyof typeof FREQUENCY
	numberOfDays: number
}

interface IEditWorkDaysInAPeriod {
	workDayPeriod: WorkDaysInAPeriodDto;
}

const EditWorkDaysInAPeriod = ({workDayPeriod}: IEditWorkDaysInAPeriod) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateWorkDaysInAPeriod } = useUpdateWorkDaysInAPeriod();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			period: workDayPeriod.period,
			numberOfDays: workDayPeriod.numberOfDays,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateWorkDaysInAPeriod(
			{ payload: data, id: workDayPeriod.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteWorkDaysInAPeriod } = useDeleteWorkDaysInAPeriod();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteWorkDaysInAPeriod(
			{ id: workDayPeriod?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={workDayPeriod.id + "bh-content"}
				id={workDayPeriod.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{enumToStringConverter(workDayPeriod.period)}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Period" for={`period`} />
								<select
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.period
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register(`period`, {
										required: "Select period here",
									})}
								>
									<option value="">
										Select Period
									</option>
									{Object.keys(FREQUENCY).map(item => (
										<option value={item}>{enumToStringConverter(item)}</option>
									))}
								</select>
								{errors?.period && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.period?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Number Of Days" for={`numberOfDays`} />
								<input
									disabled={formDisabled}
									placeholder={"Enter number"}
									type="number"
									{...register(`numberOfDays`, {
										required: "Enter number of days here",
										valueAsNumber: true
									})}
									id={`numberOfDays`}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.numberOfDays
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.numberOfDays && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.numberOfDays?.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={workDayPeriod.period}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditWorkDaysInAPeriod;
