import { SubmitHandler, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { CreateBenefitTaxDto } from "../../../../../../models/benefit-tax.dto";
import { APP_CONFIG, IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useCreateBenefitTax, useUpdateBenefitTax } from "../../../../../../services/benefit-tax.service";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import { ActiveCountryContext } from "..";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { useGetBenefitType } from "../../../../../../services/benefit-type.service";
import { useGetIndustry } from "../../../../../../services/industry.service";
import { PATHS } from "../../../../../../routes/routes.paths";
import MultiSelectField from "../../../../../../components/form_fields/MultiSelectField";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";

type IFormInputs = Omit<CreateBenefitTaxDto, "isFinalTax"> & {
	isFinalTax: string;
}

const BenefitTaxRuleModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const countries = useContext(ActiveCountryContext);
 	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			countryId: isEditForm ? formDataValues?.countryId : undefined,
			minTaxableAmount: isEditForm ? formDataValues?.minTaxableAmount : "",
			maxTaxableAmount: isEditForm ? formDataValues?.maxTaxableAmount : "",
			rateComponent: isEditForm ? formDataValues?.rateComponent : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			benefitTypeInstanceId: isEditForm ? formDataValues?.benefitTypeInstanceId : "",
			industrySetting: isEditForm ? formDataValues?.industrySetting : "",
			isFinalTax: isEditForm ? (formDataValues?.isFinalTax ? "yes" : "no") : "",
		}
	});

	const maxTaxableAmount = watch("maxTaxableAmount");
	const countryId = watch("countryId") || undefined;
	const options = {
		countryId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "name:asc" as any,
	};
	
	const { 
		data: benefitTypes, 
		isLoading: benefitTypesLoading,
		refetch: refetchBenefitTypes 
	} = useGetBenefitType(options, { enabled: false });
	const { 
		data: industries, 
		refetch: refetchIndustries 
	} = useGetIndustry(options, { enabled: false });

	useEffect(() => {
		if (countryId) {
			refetchBenefitTypes();
			refetchIndustries();
		}
	}, [countryId])

	useEffect(() => {
		setValue(
			"benefitTypeInstanceId", 
			isEditForm ? formDataValues?.benefitTypeInstanceId : ""
		)
	}, [benefitTypesLoading])
	
	const [industriesIds, setIndustriesIds] = useState<number[]>([]);
	const { mutate: createBenefitTaxRule } = useCreateBenefitTax();
	const { mutate: updateBenefitTaxRule } = useUpdateBenefitTax();
	const { addNewTableData } = useManageTableWithSearchParamsData();

    const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		
		data.isFinalTax = data.isFinalTax === "yes" ? true : false;
		data.benefitTypeInstanceId = Number(data.benefitTypeInstanceId);
		
		if (isEditForm) {
            delete data.countryId;
			updateBenefitTaxRule(
				{
					payload: data,
					id: formDataValues?.id,
				},
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.industries = industriesIds;
			createBenefitTaxRule(
				{ payload: data },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

    return (
        <ModalComponent
            width={775}
            open={open}
            title={title}
            handleClose={handleClose}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label 
							title="Benefit Type" 
							for="benefitTypeInstanceId" 
							optional={!isEditForm && countryId === undefined ? "Select country first" : undefined}
						/>
						<select
							defaultValue={isEditForm ? formDataValues?.benefitTypeInstanceId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.benefitTypeInstanceId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("benefitTypeInstanceId", {
								required: "Select benefit type here",
							})}
						>
							<option value="">
								Select Type
							</option>
							{benefitTypes?.data?.map((benefitType) => (
								<option
									key={benefitType.id}
									value={benefitType.id}
									className="capitalize"
								>
									{benefitType.name}
								</option>
							))}
						</select>
						{errors.benefitTypeInstanceId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.benefitTypeInstanceId.message}
							</p>
						)}
					</div>
					{isEditForm && (
						<div className="w-full">
							<Label title="Industry Settings" for="industrySetting" />
							<select
								defaultValue={isEditForm ? formDataValues?.industrySetting : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.industrySetting
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("industrySetting", {
									required: "Select industry setting here",
								})}
							>
								<option value="">Select Setting</option>
								<option value="APPLICABLE">Applicable</option>
								<option value="NON_APPLICABLE">Non Applicable</option>
								<option value="EXEMPT">Exempt</option>
								<option value="NONE">None</option>
							</select>
							{errors.industrySetting && (
								<p className="text-red-500 text-sm mt-1">
									{errors.industrySetting.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Min Taxable Amount" for="minTaxableAmount" />
						<MoneyInput 
							attributes={{
								id: "minTaxableAmount",
								placeholder: "0"
							}}
							register={register}
							name="minTaxableAmount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.minTaxableAmount : undefined}
							required="Enter minimum taxable amount"
							validate={(value: number) => {
								if (maxTaxableAmount && value > maxTaxableAmount) {
									return "Cannot be more than the max taxable value"
								}
								return true
							}}
							error={errors.minTaxableAmount}
						/>
						{errors.minTaxableAmount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minTaxableAmount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Max Taxable Amount" for="maxTaxableAmount" />
						<MoneyInput 
							attributes={{
								id: "maxTaxableAmount",
								placeholder: "0"
							}}
							register={register}
							name="maxTaxableAmount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.maxTaxableAmount : undefined}
							required="Enter maximum taxable amount"
							error={errors.maxTaxableAmount}
						/>
						{errors.maxTaxableAmount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxTaxableAmount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Rate Component" for="rateComponent" />
						<div className="flex items-center h-14 mt-2">
							<input
								defaultValue={
									isEditForm
										? formDataValues?.rateComponent
										: undefined
								}
								placeholder={"0"}
								type="number"
								{...register("rateComponent", {
									required: "Enter rate component",
									valueAsNumber: true,
								})}
								id="rateComponent"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.rateComponent
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
								%
							</div>
						</div>
						{errors.rateComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.rateComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Fixed Component" for="fixedComponent" optional />
						<MoneyInput 
							attributes={{
								id: "fixedComponent",
								placeholder: "0"
							}}
							register={register}
							name="fixedComponent"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.fixedComponent : undefined}
							required={false}
							error={errors.fixedComponent}
						/>
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label 
								title="Industries" 
								for="industriesIds" 
								optional={countryId === undefined ? "Select country first" : true} 
							/>
							<MultiSelectField
								key={"industriesIds"}
								options={industries?.data || []}
								setResourceIds={setIndustriesIds}
								placeholder={"Select Options"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=industries"}
								required={false}
							/>
						</div>
					)}
					{!isEditForm && (
						<div className="w-full">
							<Label title="Industry Settings" for="industrySetting" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.industrySetting
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("industrySetting", {
									required: "Select industry setting here",
								})}
							>
								<option value="">Select Setting</option>
								<option value="APPLICABLE">Applicable</option>
								<option value="NON_APPLICABLE">Non Applicable</option>
								<option value="EXEMPT">Exempt</option>
								<option value="NONE">None</option>
							</select>
							{errors.industrySetting && (
								<p className="text-red-500 text-sm mt-1">
									{errors.industrySetting.message}
								</p>
							)}
						</div>
					)}
					<div className="col-span-2">
						<div className="flex space-x-10 text-base">
                            <Label title="Final Tax" for="isFinalTax" />
							<div className="flex space-x-3 items-center">
								<input
									{...register("isFinalTax")}
									defaultChecked={isEditForm && formDataValues?.isFinalTax === true}
									type="radio"
									value={"yes"}
									id="isFinalTax"
								/>
								<label htmlFor="isFinalTax">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("isFinalTax")}
									defaultChecked={isEditForm && formDataValues?.isFinalTax === false}
									type="radio"
									value={"no"}
									id="notFinalTax"
								/>
								<label htmlFor="notFinalTax">No</label>
							</div>
						</div>
					</div>
				</div>

                <div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
                            uploading
                                ? "Uploading..."
                                : isEditForm
                                ? "Update Benefit Rule"
                                : "Add Benefit Rule"
                        }
						className={"px-4"}
						disabled={uploading}
					/>
                </div>
            </form>
        </ModalComponent>
    );
}

export default BenefitTaxRuleModalForm;