import {PaginatorInfo} from "./generic.dto";

export const GRADE_LEVEL_TYPE = {
    MAJOR: 'MAJOR',
    MINOR: 'MINOR'
};

export type GRADE_LEVEL_TYPE = (typeof GRADE_LEVEL_TYPE)[keyof typeof GRADE_LEVEL_TYPE]

type GradeLevel = {
    id: number
    companyId: number
    name: string
    code: string
    companyLevelId: number;
    description: string;
    type: GRADE_LEVEL_TYPE
    createdAt: string
    modifiedAt: string | null
}
export class CreateGradeLevelDto{
    companyId!: number;
    companyLevelId!: number;
    code!: string;
    name!: string;
    description?: string;
    type!: GRADE_LEVEL_TYPE
}

export class UpdateGradeLevelDto{
    code?: string;
    name?: string;
    description?: string;
    companyLevelId?: number;
    type?: GRADE_LEVEL_TYPE
}

export interface GradeLevelDto extends GradeLevel {}

export interface GradeLevelPaginator extends PaginatorInfo<GradeLevelDto> {}

export class QueryGradeLevelDto {
    companyId?: number;
    companyLevelId?: number;
    code?: string;
    type?: GRADE_LEVEL_TYPE;
    page?: number = 1;
    limit?: number;
    orderBy?: GradeLevelOrderBy = GradeLevelOrderBy.NAME_ASC;
  }

  
export class SearchGradeLevelDto {
    q?: string
    page?: number = 1;
    limit?: number;
    orderBy?: GradeLevelOrderBy = GradeLevelOrderBy.CODE_ASC;
  }
  
  export enum GradeLevelOrderBy {
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc'
  }