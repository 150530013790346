import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateStatementRequestDto,
    QueryStatementRequestDto, 
    StatementRequestDto, 
    StatementRequestPaginator,
    UpdateStatementRequestDto
} from '../models/statement-request.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetStatementRequest(options?: QueryStatementRequestDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.GET;
    const request = (options: QueryStatementRequestDto) => API.BillingAndSubscriptionAPI.getStatementRequest(options);

    const { data, isLoading, error, refetch } = useQuery<StatementRequestPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetStatementRequestById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.DETAIL;
    const request = API.BillingAndSubscriptionAPI.getStatementRequestById(id);

    const { data, isLoading, error } = useQuery<{ data: StatementRequestDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreateStatementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateStatementRequestDto }) => API.BillingAndSubscriptionAPI.createStatementRequest(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.GET);
            },
        },
    );
}

export function useUpdateStatementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateStatementRequestDto, id: number }) => API.BillingAndSubscriptionAPI.updateStatementRequest(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.GET);
            },
        },
    );
}

export function useDeleteStatementRequest() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteStatementRequest(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.GET);
            },
        },
    );
}