import { useState, useEffect } from "react";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { 
    EmployeLeaveTypeSummary, 
    LeaveTypeDto, 
    ApplicableLeaveTypeOrderBy 
} from "../../../../models/leave-type.dto";
import { useGetEmployeeLeaveTypeSummary } from "../../../../services/leave-request.service";
import { useGetApplicableLeaveType } from "../../../../services/leave-type.service";

export function useManageLeaveSummary(employeeId: number) {
	const [leaveTypeSummary, setLeaveTypeSummary] = 
		useState<EmployeLeaveTypeSummary>({} as EmployeLeaveTypeSummary);
	const [activeLeaveType, setActiveLeaveType] = useState<LeaveTypeDto>();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const { data: leaveTypes, isLoading } = useGetApplicableLeaveType({
		employeeId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: ApplicableLeaveTypeOrderBy.CREATED_AT_DESC,
	});
	
	const { mutate: getLeaveTypeSummary } = useGetEmployeeLeaveTypeSummary();

	const handleLeaveTypeSummary = (leaveTypeId: number) => {
		getLeaveTypeSummary(
			{ employeeId, leaveTypeId },
			{ onSuccess: (data) => setLeaveTypeSummary(data.data) }
		)
	};

	useEffect(() => {
		if (!isLoading && leaveTypes[0]) {
			handleLeaveTypeSummary(leaveTypes[0].id);
			setActiveLeaveType(leaveTypes[0]);
		}
	}, [isLoading])

	const handleLeaveTypeMenu = (leaveType: LeaveTypeDto) => {
		handleLeaveTypeSummary(leaveType.id);
		setActiveLeaveType(leaveType);
		setDropdownOpen(prev => !prev);
	};

    return {
        leaveTypes,
        leaveTypeSummary,
        activeLeaveType,
        dropdownOpen,
        setDropdownOpen,
        handleLeaveTypeMenu,
    }
}