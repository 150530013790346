import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateSalaryAdvanceDto,
    QuerySalaryAdvanceDto,
    SalaryAdvanceDto,
    SalaryAdvancePaginator,
    UpdateSalaryAdvanceDto
} from "../models/salary-advance.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetSalaryAdvance(options?: QuerySalaryAdvanceDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.GET;
    const request = (options: QuerySalaryAdvanceDto) => API.ProcessingCompanyAPI.getSalaryAdvance(options);

	const { data, isLoading, error, refetch } = useQuery<SalaryAdvancePaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}


export function useGetSalaryAdvanceById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.DETAIL;
    const request = API.ProcessingCompanyAPI.getSalaryAdvanceById(id);

    const {data, isLoading, error} = useQuery<{ data: SalaryAdvanceDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateSalaryAdvance() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateSalaryAdvanceDto}) => API.ProcessingCompanyAPI.createSalaryAdvance(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.GET);
            },
        }
    )
}

export function useUpdateSalaryAdvance() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateSalaryAdvanceDto, id: number}) => API.ProcessingCompanyAPI.updateSalaryAdvance(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.GET);
            },
        }
    )
}

export function useDeleteSalaryAdvance() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteSalaryAdvance(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}