import { AiOutlinePlus } from "react-icons/ai";
import { CostCenterDto } from "../../../../../models/cost-center.dto";
import { useGetCostCenter } from "../../../../../services/cost-center.service";
import SkewLoader from "react-spinners/SkewLoader";
import { useState } from "react";
import NewCostCenter from "./NewCostCenter";
import EditCostCenter from "./EditCostCenter";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const CostCenters = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewCostCenterForm, setShowNewCostCenterForm] = useState(false);
	
	const {
		accordionsData: costCenters,
		isLoading,
		pageCount,
    } = useManageAccordions<CostCenterDto>({
		fetchHook: useGetCostCenter,
		options: { companyId: currentCompany?.id }
	});
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Cost Centers</h2>
				{!showNewCostCenterForm && (
					<button
						onClick={() => setShowNewCostCenterForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Cost Center
					</button>
				)}
			</div>

			{showNewCostCenterForm && (
				<NewCostCenter setShowNewCostCenterForm={setShowNewCostCenterForm} />
			)}

			{(isLoading && !costCenters.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{costCenters.map((costCenter) => (
				<EditCostCenter 
					key={costCenter.id}
					costCenter={costCenter} 
				/>
			))}

			{(!isLoading && !costCenters.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no cost centers added yet. Click 'Add New Cost Center' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default CostCenters;
