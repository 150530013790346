import {useQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    BackPayDto, BackPayPaginator,
    CreateBackPayDto, QueryBackPayDto,
    UpdateBackPayDto, SearchBackPayDto
} from "../models/back-pay.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetBackPay(options?: QueryBackPayDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.GET;
    const request = (options: QueryBackPayDto) => API.ProcessingCompanyAPI.getBackPay(options);

    const { data, isLoading, error, refetch } = useQuery<BackPayPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetBackPaySearch() {

    return useMutation(
        (options?: SearchBackPayDto) => API.ProcessingCompanyAPI.getBackPaySearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
export function useGetBackPayById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.DETAIL;
    const request = API.ProcessingCompanyAPI.getBackPayById(id);

    const {data, isLoading, error} = useQuery<{ data: BackPayDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateBackPay(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateBackPayDto}) => API.ProcessingCompanyAPI.createBackPay(payload),
        {
            onSuccess: (response) => {

                if(onSuccessCallback){
                    onSuccessCallback(response)
                }

                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.GET);
            },
        }
    )
}

export function useUpdateBackPay() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateBackPayDto, id: number}) => API.ProcessingCompanyAPI.updateBackPay(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.GET);
            },
        }
    )
}

export function useDeleteBackPay() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteBackPay(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.GET);
            },
        }
    )
}
