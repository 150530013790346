import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import Label from "../../../../components/form_fields/Label";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { OrganizationWithOptionalRelations } from "../../../../models/organization.dto";
import { 
	useCreditOrgTransaction, 
	useReverseOrgTransaction 
} from "../../../../services/organization-transaction.service";

interface IFormInputs {
	organizationId: string;
	amount: number;
	narration: string;
}

type FormAction = "CREATE" | "REVERSE";

type TransactionModalProps = Omit<IAddEditTableForm, "isEditForm" | "title" | "tableAPIRef"> & {
	formAction: FormAction;
	organizations: OrganizationWithOptionalRelations[];
}

const TransactionModal = ({
	open,
	setOpen,
	formAction,
	organizations,
}: TransactionModalProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [currencyCode, setCurrencyCode] = useState("");

	const {
		register,
		setValue,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>();

	const organizationId = watch("organizationId");
	useEffect(() => {
		if (!organizationId) return;

		setCurrencyCode(
			organizations.find(org => org.id === organizationId)?.currency?.code || ""
		);
	}, [organizationId])
	
	const { mutate: createTransaction } = useCreditOrgTransaction();
	const { mutate: reverseTransaction } = useReverseOrgTransaction();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		switch(formAction) {
			case "CREATE":
				createTransaction(
					{ payload: data },
					{
						onSuccess: () => {
							addNewTableData();
							setOpen(false);
							reset();
						},
						onSettled: () => setUploading(false),
					}
				);
				break;
			case "REVERSE":
				delete data.amount;
				reverseTransaction(
					{ payload: { ...data, transactionId: formDataValues?.id } },
					{
						onSuccess: () => {
							addNewTableData();
							setOpen(false);
							reset();
						},
						onSettled: () => setUploading(false),
					}
				);
				break;
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={getTransactionTitle(formAction)}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{formAction === "CREATE" && (
						<div className="w-full">
							<Label title="Organization" for="organizationId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.organizationId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("organizationId", {
									required: "Select organization here",
								})}
							>
								<option value="">Select Organization</option>
								{organizations.map((organization) => (
									<option 
										key={organization.id} 
										value={organization.id}
									>
										{organization.name}
									</option>
								))}
							</select>
							{errors.organizationId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.organizationId.message}
								</p>
							)}
						</div>
					)}
					{formAction !== "REVERSE" && (
						<div className="w-full">
							<Label title="Amount" for="amount" />
							<div className="flex items-center">
								<MoneyInput 
									attributes={{
										id: "amount",
										placeholder: "0"
									}}
									register={register}
									name="amount"
									setValue={setValue}
									required="Enter amount here"
									error={errors.amount}
								/>
								{currencyCode && (
									<div className="h-14 w-36 mt-2 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
										{currencyCode}
									</div>
								)}
							</div>
							{errors.amount && (
								<p className="text-red-500 text-sm mt-1">
									{errors.amount.message}
								</p>
							)}
						</div>
					)}
					<div className="col-span-2 w-full">
						<Label title="Narration" for="narration" />
						<input
							placeholder={"Enter narration"}
							type="text"
							{...register("narration", {
								required: "Enter narration here",
							})}
							id="narration"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.narration
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.narration && (
							<p className="text-red-500 text-sm mt-1">
								{errors.narration.message}
							</p>
						)}
					</div>
				</div>
				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: getTransactionTitle(formAction)
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default TransactionModal;

const getTransactionTitle = (formAction: FormAction) => {
	switch(formAction) {
		case "CREATE":
			return "Create Transaction"
		case "REVERSE":
			return "Reverse Transaction"
	}
}