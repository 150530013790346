import EditUnion from "./EditUnion";
import NewUnion from "./NewUnion";
import { AiOutlinePlus } from "react-icons/ai";
import { UnionDto } from "../../../../../models/union.dto";
import { useGetUnion } from "../../../../../services/union.service";
import { useState } from "react";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { useGetCompanyCurrencyConfig } from "../../../../../services/company-currency.service";
import { SkewLoader } from "react-spinners";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const Unions = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewUnionForm, setShowNewUnionForm] = useState(false);
	
	const {
		accordionsData: unions,
		isLoading,
		pageCount,
    } = useManageAccordions<UnionDto>({
		fetchHook: useGetUnion,
		options: { companyId: currentCompany?.id }
	});
	
	const { data: companyCurrencies } = useGetCompanyCurrencyConfig({
		companyId: currentCompany?.id as number,
		limit: APP_CONFIG.PAGE_LIMIT,
	});

	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Unions</h2>
				{!showNewUnionForm && (
					<button
						onClick={() => setShowNewUnionForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Union
					</button>
				)}
			</div>
			
			{showNewUnionForm && (
				<NewUnion 
					companyCurrencies={companyCurrencies?.data || []}
					setShowNewUnionForm={setShowNewUnionForm} 
				/>
			)}
			
			{(isLoading && !unions.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{unions.map((union) => (
				<EditUnion 
					key={union.id}
					union={union} 
					companyCurrencies={companyCurrencies?.data || []}
				/>
			))}

			{(!isLoading && !unions.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no unions added yet. Click 'Add New Union' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default Unions;
