import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "paymentInstitution",
		headerName: "Payment Institution",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "--",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "sortCode",
		headerName: "Sort Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	
	...createdAtmodifiedAtActions
];
