import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    DisabilitiesDto, DisabilitiesPaginator,
    CreateDisabilitiesDto, QueryDisabilitiesDto,
    UpdateDisabilitiesDto
} from "../models/disabilities.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetDisabilities(options?: QueryDisabilitiesDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.GET;
    const request = (options: QueryDisabilitiesDto) => API.ProcessingCompanyAPI.getDisabilities(options);

	const { data, isLoading, error, refetch } = useQuery<DisabilitiesPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetDisabilitiesById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.DETAIL
    const request = API.ProcessingCompanyAPI.getDisabilitiesById(id);

    const {data, isLoading, error} = useQuery<{ data:DisabilitiesDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateDisabilities() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateDisabilitiesDto}) => API.ProcessingCompanyAPI.createDisabilities(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.GET);
            },
        }
    )
}

export function useUpdateDisabilities() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateDisabilitiesDto, id: number}) => API.ProcessingCompanyAPI.updateDisabilities(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.GET);
            },
        }
    )
}

export function useDeleteDisabilities() {

    return useMutation(
        ({ id }: { id: number }) => API.ProcessingCompanyAPI.deleteDisabilities(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}