import { create } from "zustand";
import { persist, StorageValue } from 'zustand/middleware'
import SecureLS from 'secure-ls';
import { AuthTokenPair, LoginResponse, UserType } from '../models/auth.dto';
import { setAccessToken } from "../services/utils/app.data";

const ls = new SecureLS({ isCompression: false });

// Custom storage engine that uses secure-ls and its methods
const secureLsStorage = {
    getItem: (name: string) => {
        const value = ls.get(name);
        return value ? JSON.parse(value) : null;
    },
    setItem: (name: string, value: StorageValue<UserStore>) => 
        ls.set(name, JSON.stringify(value)),
    removeItem: (name: string) => ls.remove(name),
};

export class LoginResponseDto extends LoginResponse {
    loginTime!: Date;
}

const defaultUser = {
    access_token: '',
    refresh_token: '',
    loginTime: new Date(),
    user: {
        id: '',
        firstName: '',
        lastName: '',
        organizationId: '',
        organizationRoleId: '',
        category: '',
        username: '',
        emailAddress: '',
        msisdn: '',
        imageUrl: '',
        password: '',
        requirePasswordChange: false,
        status: '',
        createdAt: (new Date()).toISOString(),
        modifiedAt: (new Date()).toISOString(),
    }
} as LoginResponseDto;

type UserStore = {
    currentUser: LoginResponseDto;
    userType: UserType | "";
    supervisor: boolean | null;
    setCurrentUser: (userObject: LoginResponseDto) => void;
    setUserType: (type: UserType) => void;
    updateUserAuthTokens: (accessAndRefreshToken: AuthTokenPair) => void;
    setSupervisor: (value: boolean) => void;
    clearUser: () => void;
}

const useUserStore = create(
    persist<UserStore>(
        (set) => ({
            currentUser: defaultUser,
            userType: "",
            supervisor: null,
            setCurrentUser: (userObject: LoginResponseDto) => {
                setAccessToken(userObject.access_token);
                set({ currentUser: userObject });
            },
            setUserType: (type: UserType) => {
                set({ userType: type });
            },
            updateUserAuthTokens: (accessAndRefreshToken: AuthTokenPair) => {
                setAccessToken(accessAndRefreshToken.access_token);
                
                set((state) => ({
                    currentUser: {
                        ...state.currentUser,
                        access_token: accessAndRefreshToken.access_token,
                        refresh_token: accessAndRefreshToken.refresh_token,
                        loginTime: new Date()
                    }
                }))
            },
            setSupervisor: (value: boolean) => {
                set({ supervisor: value });
            },
            clearUser: () => {
                set({ 
                    currentUser: defaultUser, 
                    userType: "", 
                    supervisor: null 
                });
            }            
        }),
        {
            name: '@user',
            storage: secureLsStorage,
        }
    )
);

export default useUserStore;