import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import API from './utils/api';
import {
    InvoiceDto, InvoicePaginator,
    CreateInvoiceDto, QueryInvoiceDto,
    UpdateInvoiceDto, PayInvoiceDto, CancelInvoiceDto, SearchInvoiceDto
} from "../models/invoice.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetInvoice(options?: QueryInvoiceDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET;
    const request = (options: QueryInvoiceDto) => API.BillingAndSubscriptionAPI.getInvoice(options);

    const { data, isLoading, error, refetch } = useQuery<InvoicePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetInvoiceSearch() {

    return useMutation(
        (options?: SearchInvoiceDto) => API.BillingAndSubscriptionAPI.getInvoiceSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetInvoiceById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.DETAIL
    const request = API.BillingAndSubscriptionAPI.getInvoiceById(id);

    const {data, isLoading, error} = useQuery<{ data:InvoiceDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateInvoice() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateInvoiceDto}) => API.BillingAndSubscriptionAPI.createInvoice(payload),
        {
            onSuccess: (response) => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET);
            },
        }
    )
}

export function useUpdateInvoice() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateInvoiceDto, id: number}) => API.BillingAndSubscriptionAPI.updateInvoice(id, payload),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET);
            },
        }
    )
}

export function usePayInvoice() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: PayInvoiceDto}) => API.BillingAndSubscriptionAPI.payInvoice(payload),
        {
            onSuccess: (response) => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET);
            },
        }
    )
}

export function useCancelInvoice() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CancelInvoiceDto}) => API.BillingAndSubscriptionAPI.cancelInvoice(payload),
        {
            onSuccess: (response) => {},
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET);
            },
        }
    )
}

