import {PaginatorInfo} from "./generic.dto";
import {CostCenterDto} from "./cost-center.dto";
import { EmployeeDto } from "./employee.dto";
import { EmployeeBand } from "./employee-band.dto";

export const APPLY_SAVINGS_CONTRIBUTION_ON = {
    BASIC: 'BASIC',
    GROSS: 'GROSS',
    FIXED: 'FIXED'
};

export type APPLY_SAVINGS_CONTRIBUTION_ON = (typeof APPLY_SAVINGS_CONTRIBUTION_ON)[keyof typeof APPLY_SAVINGS_CONTRIBUTION_ON]

export const FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY'
};

export type FREQUENCY = (typeof FREQUENCY)[keyof typeof FREQUENCY]

export type SavingsScheme = {
    id: number
    companyId: number
    code: string
    name: string
    description: string
    applyEmployeeContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeFixedComponent: number
    employeeRateComponent: number
    employerFixedComponent: number
    employerRateComponent: number
    prorate: boolean
    statutory: boolean
    startPayPeriodId: number | null
    endPayPeriodId: number | null
    frequency?: FREQUENCY
    createdAt: string
    modifiedAt: string | null
}

export class CreateSavingsSchemeDto {
    companyId!: number
    code!: string
    name!: string
    description!: string
    applyEmployeeContributionOn!: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn!: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure!: number
    employerContributionFigure!: number
    prorate!: boolean
    statutory!: boolean
    startPayPeriodId!: number
    endPayPeriodId!: number
    frequency?: FREQUENCY
    employeeIds?: number[]
    employeeBandIds?: number[]
}

export class UpdateSavingsSchemeDto {
    code?: string
    name?: string
    description?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure?: number
    employerContributionFigure?: number
    prorate?: boolean
    statutory?: boolean
    startPayPeriodId?: number
    endPayPeriodId?: number
    frequency?: FREQUENCY
}

export interface SavingsSchemeDto extends SavingsScheme {
    employeeSavingsSchemes?: EmployeeSavingsSchemeDto[];
    employeeBandSavingsSchemes?: EmployeeBandSavingsSchemeDto[];
}

export interface EmployeeSavingsSchemeDto extends EmployeeSavingsScheme {
    employee?: EmployeeDto;
}
  
export interface EmployeeBandSavingsSchemeDto extends EmployeeBandSavingsScheme {
employeeBand?: EmployeeBand;
}

type EmployeeSavingsScheme = {
    id: number;
    employeeId: number;
    savingsSchemeId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

type EmployeeBandSavingsScheme = {
    id: number;
    employeeBandId: number;
    savingsSchemeId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

export class QuerySavingsSchemeDto {
    companyId?: number;
    code?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    prorate?: boolean
    statutory?: boolean
    page?: number = 1;
    limit?: number
    orderBy?: SavingsSchemeOrderBy = SavingsSchemeOrderBy.CREATED_AT_ASC;
}

export class SearchSavingsSchemeDto {
    q?: string
    page?: number = 1;
    limit?: number
    orderBy?: SavingsSchemeOrderBy = SavingsSchemeOrderBy.CREATED_AT_ASC;
}


export enum SavingsSchemeOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc'
}



export class SavingsSchemeUpdateInput {
    code?: string
    name?: string
    description?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure?: number
    employerContributionFigure?: number
    employeeFixedComponent!: number
    employeeRateComponent!: number
    employerFixedComponent!: number
    employerRateComponent!: number
    prorate?: boolean
    statutory?: boolean
    startPayPeriodId?: number
    endPayPeriodId?: number
    frequency?: FREQUENCY

    constructor(updateSavingsSchemeDto: UpdateSavingsSchemeDto) {
        this.code = updateSavingsSchemeDto.code
        this.name = updateSavingsSchemeDto.name
        this.description = updateSavingsSchemeDto.description
        this.applyEmployeeContributionOn = updateSavingsSchemeDto.applyEmployeeContributionOn
        this.applyEmployerContributionOn = updateSavingsSchemeDto.applyEmployerContributionOn
        this.prorate = updateSavingsSchemeDto.prorate
        this.statutory = updateSavingsSchemeDto.statutory
        this.startPayPeriodId = updateSavingsSchemeDto.startPayPeriodId
        this.endPayPeriodId = updateSavingsSchemeDto.endPayPeriodId
        this.frequency = updateSavingsSchemeDto.frequency
    }
}

export interface SavingsSchemePaginator extends PaginatorInfo<SavingsSchemeDto> {}
