import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Avatar, 
    Box, 
    LinearProgress 
} from "@mui/material";
import { AiOutlineDownCircle } from "react-icons/ai";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import { CompanyDocumentTypeDto } from "../../../../../../models/company-document-type.dto";
import { EmployeeDocumentDto } from "../../../../../../models/employee-document.dto";
import { useUpdateEmployeeDocument, useDeleteEmployeeDocument } from "../../../../../../services/employee-document.service";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { BsUpload, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { uploadOneDocument } from "../../../../../../services/document.service";

interface IFormInputs {
    typeId: number;
    url: string;
}
type FileStatus = "success" | "error" | "uploading" | ""

interface IEditDocument {
	employeeDocument: EmployeeDocumentDto;
    companyDocumentTypes: CompanyDocumentTypeDto[];
}

const EditEmployeeDocument = ({ employeeDocument, companyDocumentTypes }: IEditDocument) => {
	const [document, setDocument] = useState(employeeDocument);
	const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateEmployeeDocument } = useUpdateEmployeeDocument();
	const [uploading, setUploading] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
        defaultValues: { typeId: document.typeId, url: document.url }
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
			"text/pdf": [".pdf"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (pdf, png, jpeg, jpg, heic or svg)");
		},
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		setUploading(true);
		
		if (file) {
			setFileStatus("uploading");

			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				data.url = fileUrl;
				setFileStatus("success");
			} else {
				setFileStatus("error");
				setUploading(false);
				return
			}
		}

		updateEmployeeDocument(
			{ payload: data, id: document.id },
			{
				onSuccess: (data) => {
                    setDocument(data.data);
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteEmployeeDocument } = useDeleteEmployeeDocument();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteEmployeeDocument(
			{ id: employeeDocument?.id },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
            <AccordionSummary
                expandIcon={<AiOutlineDownCircle className="text-xl" />}
                aria-controls={document.id + "bh-content"}
                id={document.id + "bh-header"}
                sx={{ px: 3, border: "1px" }}
            >
                <h2 className="font-inter text-sm font-semibold">
                    {employeeDocument.documentType?.name}
                </h2>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 3 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
                            <h2 className="font-semibold text-2xs">Details</h2>
                            <button
                                type="button"
                                className={`border-none text-primary-blue text-2xs ${
                                    !formDisabled ? "hidden" : ""
                                }`}
                                onClick={(e) => setFormDisabled(false)}
                            >
                                Edit
                            </button>
                        </div>
                        <div className="w-full mt-4">
                            <Label title="Document Type" for="typeId" />
                            <select
                                disabled={formDisabled}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.typeId
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                {...register("typeId", {
                                    required: "Select document type here",
                                    valueAsNumber: true,
                                })}
                            >
                                <option value="">
                                    Select Type
                                </option>
                                {companyDocumentTypes.map((documentType) => (
                                    <option
                                        key={documentType.id}
                                        value={documentType.id}
                                    >
                                        {documentType.name}
                                    </option>
                                ))}
                            </select>
                            {errors.typeId && (
                                <p className="text-red-500 text-sm mt-1">{errors.typeId.message}</p>
                            )}
                        </div>
                        <div className="mt-6">
                            <Label title="Document" for="document" />
                            <div className="flex space-x-4 mt-3">
                                <Link 
                                    to={document.url} 
                                    target="_blank"
                                    className="w-fit"
                                >
                                    <Avatar
                                        alt="file"
                                        src={file ? URL.createObjectURL(file) : document.url}
                                        variant="rounded"
                                        className="border border-primary-blue hover:border-2 cursor-pointer"
                                        sx={{ width: 165, height: 165 }}
                                    />
                                </Link>
                            </div>
                        </div>
                        {!formDisabled && (<>
                            <div className="w-full mt-6">
                                <div
                                    {...getRootProps()}
                                    className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
                                    flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
                                >
                                    <input {...getInputProps()} />
                                    <BsUpload className="text-xl mb-2" />
                                    <p>
                                        Update document 
                                        <span className="font-semibold"> (2mb max)</span>
                                    </p>
                                    <p>(pdf, png, jpeg, jpg, heic or svg)</p>
                                </div>
                            </div>
                            {file && (
                                <div className="flex items-center space-x-4 w-full mt-1">
                                    <BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
                                    <div className="flex flex-col items-start w-full">
                                        <div className="flex justify-between items-center w-full mb-1">
                                            <p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
                                                {file.name}
                                            </p>
                                            <button onClick={() => {
                                                setFile(null);
                                                setFileStatus("");
                                            }}>
                                                <IoMdClose className="text-xl hover:scale-[1.1]" />
                                            </button>
                                        </div>
                                        <Box sx={{ width: "100%", mb: 1, mt: 0 }}>
                                            <LinearProgress
                                                color={fileStatus === "error" ? "error" : "primary"}
                                                variant={"determinate"}
                                                value={fileStatus ? 100 : 0}
                                            />
                                        </Box>
                                        <div className="flex justify-between text-xs w-full">
                                            <p>
                                                File size: {(file.size / 1000000).toFixed(2)}mb
                                            </p>
                                            {fileStatus === "success" ? (
                                                <span className="text-green-500 text-xs italic">
                                                    File uploaded successfully
                                                </span>
                                            ) : fileStatus === "error" ? (
                                                <span className="text-red-500 text-xs italic">
                                                    File upload error! Try again
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>)}
                    </>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={employeeDocument.documentType?.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default EditEmployeeDocument;