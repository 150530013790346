import { Modal, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { 
	enumToStringConverter, 
	formatDate, 
	formatDateAndTime, 
	ModalStyle, 
	StyledDataGrid,
} from "../../../../helpers/appHelpers";
import { StatementRequestDto } from "../../../../models/statement-request.dto";
import { useGetStatementRequest } from "../../../../services/statement-request.service";
import OrangeButton from "../../../buttons/OrangeButton";
import CustomPagination from "../../../table/CustomPagination";
import { GridColDef } from "@mui/x-data-grid";
import useUserStore from "../../../../state-management/useUserStore";
import StatementsRequestModalForm from "./StatementsRequestModalForm";

type StatementsRequestViewProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StatementsRequestView = ({
	openModal,
	setOpenModal,
}: StatementsRequestViewProps) => {
	const { currentUser } = useUserStore();
	const [tableData, setTableData] = useState<StatementRequestDto[]>([]);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [openNewRequestModal, setOpenNewRequestModal] = useState(false);
	const [newData, setNewData] = useState("");

    const { data, isLoading, refetch } = useGetStatementRequest({
		organizationId: currentUser?.user?.organizationId,
        limit: 6,
        orderBy: "createdAt:desc" as any,
		page,
    });

	useEffect(() => {
		if (!isLoading && data?.data) {
			setTableData(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
		newData && setPage(1);

        refetch().then((response) => {
            if (response) {
                setTableData(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }

			newData && setNewData("");
        })
	}, [page, newData])

	const handlePageChange = (_, value: number) => setPage(value);

	return (<>
		<Modal
			open={openModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={1260} fontSize={"13px"}>
				<div className="flex justify-between items-center pt-4 pb-6">
					<div className="flex items-center gap-5">
						<h1 className="font-bold text-lg">Statement Requests</h1>
						<OrangeButton
							title={"Initiate New Request"}
							className={"px-5 h-10 flex items-center "}
							onClick={() => setOpenNewRequestModal(true)}
						/>
					</div>
					<button
						onClick={() => setOpenModal(false)}
					>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<hr />
				<div className="mt-6">
					<Box
						sx={{
							height: "530px",
							backgroundColor: "background.paper",
							borderRadius: 4,
							width: "100%",
							"& .actions": {
								color: "text.secondary",
							},
							"& .textPrimary": {
								color: "text.primary",
							},
							"& .super-app-theme--header": {
								backgroundColor: "#F2F2F4",
								fontWeight: "bold",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "11px",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f7fafc",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#e2e8f0",
								borderRadius: "6px",
								border: "3px solid #f7fafc",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#e2e8f0",
								borderRadius: "7px",
								border: "3px solid #f7fafc",
							},
						}}
					>
						<StyledDataGrid
							rows={tableData}
							rowHeight={65}
							columnHeaderHeight={70}
							columns={StatementsRequestColumns}
							loading={isLoading}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 6,
									},
								},
								columns: {
									columnVisibilityModel: {
										id: false,
									},
								},
							}}
							slots={{
								pagination: () => <CustomPagination 
									pageCount={pageCount} 
									pageState={page}
									handleChange={handlePageChange}
								/>
							}}
							pageSizeOptions={[5]}
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
							}
							getRowId={(row) => row.id}
						/>
					</Box>
				</div>
			</Box>
		</Modal>
		{openNewRequestModal && (
			<StatementsRequestModalForm
				openModal={openNewRequestModal}
				setOpenModal={setOpenNewRequestModal}
				setNewData={setNewData}
			/>
		)}
	</>);
};

export default StatementsRequestView;

const StatementsRequestColumns: GridColDef[] = [
	{
		field: "transactionType",
		headerName: "Transaction Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "requestType",
		headerName: "Request Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "endDate",
		headerName: "End Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const returnDate = formatDate(params);
			return returnDate;
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "completed",
		headerName: "Completed",
		width: 160,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
]