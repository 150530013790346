import { GradeLevelDto } from './grade-level.dto';
import { PayrollCompanyDto } from './company-account.dto';
import { PaginatorInfo } from './generic.dto';

type Announcement = {
  id: number;
  companyId: number;
  title: string;
  body: string;
  active: boolean;
  public: boolean | null;
  publishDate: string;
  createdAt: string;
  modifiedAt: string | null;
}

export type AnnouncementResource = {
  id: number;
  announcementId: number;
  resourceType: ResourceType;
  url: string;
  createdAt: string;
  modifiedAt: string | null;
}

export enum ResourceType {
  IMAGE = "IMAGE",
  OTHER = "OTHER"
}


export class UpdateAnnouncementResourceDto {
  resourceType?: ResourceType;
  url?: string;
}

export interface AnnouncementResourceDto extends AnnouncementResource {
  announcement?: Announcement;
}
export class AnnouncementResourceObject {
  resourceType!: ResourceType;
  url!: string;
}

export class CreateAnnouncementDto {
  companyId!: number;
  title!: string;
  body!: string;
  active?: boolean;
  public?: boolean;
  publishDate!: string;
  resources?: AnnouncementResourceObject[];
  targetGradeLevelIds?: number[];
}

export class UpdateAnnouncementDto {
  title?: string;
  body?: string;
  active?: boolean;
  public?: boolean;
  publishDate?: string;
  addResources?: AnnouncementResourceObject[];
  removeResourcesIds?: number[];
  unassignedTargetGradeLevelIds?: number[];
  assignedTargetGradeLevelIds?: number[];
}

export enum AnnouncementOrderBy {
  TITLE_ASC = 'title:asc',
  TITLE_DESC = 'title:desc',
  PUBLISH_DATE_ASC = 'publishDate:asc',
  PUBLISH_DATE_DESC = 'publishDate:desc',
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export class QueryEmployeeAnnouncementDto {
  'publishDate.gte'?: string;
  'publishDate.lte'?: string;
  page?: number = 1;
  limit?: number;
  orderBy: AnnouncementOrderBy = AnnouncementOrderBy.CREATED_AT_DESC;
}

export class QueryAnnouncementDto extends QueryEmployeeAnnouncementDto {
  companyId?: number;
  active?: boolean;
  public?: boolean;
  targetGradeLevelId?: number; 
}

export interface AnnouncementDto extends Announcement {
  company?: PayrollCompanyDto;
  resources?: AnnouncementResource[];
  targetGradeLevels?: GradeLevelDto[];
}

export class SearchAnnouncementDto {
  q?: string;
  page: number = 1;
  limit?: number;
  orderBy: AnnouncementOrderBy = AnnouncementOrderBy.CREATED_AT_DESC;
}

export interface AnnouncementResourceDto extends AnnouncementResource {
  announcement?: Announcement;
}

export interface AnnouncementPaginator extends PaginatorInfo<AnnouncementDto> {}
