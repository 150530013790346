import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { WorkTimeTabColumns } from "../../../components/custom_tab/tabColumns";
import { useSearchParams } from "react-router-dom";
import OvertimeManagementView from "./views/OvertimeManagementView";
import WorkTimeView from "./views/WorkTimeView";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";

const WortTimeAndOvertimeManagement = () => {
	const { userType } = useUserStore();
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "workTime";
	const [view, setView] = useState(currentView);
	
	return (<>
	{userType === "ORGANIZATION" ? (
		<PayrollCompanyLayout>
			<Helmet>
				<title>Work Time | Company Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Work Time</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={WorkTimeTabColumns}
			>
				{view === "workTime" && <WorkTimeView />}
				{view === "overtimeManagement" && <OvertimeManagementView />}
			</CustomTab>
		</PayrollCompanyLayout>
	):(
		<HumanResourceLayout>
			<Helmet>
				<title>Work Time | HR Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Work Time</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={WorkTimeTabColumns}
			>
				{view === "workTime" && <WorkTimeView />}
				{view === "overtimeManagement" && <OvertimeManagementView />}
			</CustomTab>
		</HumanResourceLayout>
	)}
	</>);
};

export default WortTimeAndOvertimeManagement;
