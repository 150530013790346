import {PaginatorInfo} from "./generic.dto";
import { OVERTIME_PAYMENT_TIER_TYPE } from "./overtime-payment-tier.dto";

type Overtime = {
  id: number
  companyId: number
  employeeBandId: number
  code: string
  name: string
  description: string
  minHoursRequired: number
  maxHoursPermitted: number
  taxable: boolean
  active: boolean
  createdAt: string
  modifiedAt: string | null
}

export class OvertimePaymentTier {
  type!: OVERTIME_PAYMENT_TIER_TYPE;
  fixedComponent!: number;
  factorComponent!: number;
  minHours!: number;
  maxHours!: number;
  currencyId!: number;
}

export class CreateOvertimeDto {
  companyId!: number;
  employeeBandId!: number;
  code!: string;
  name!: string;
  description!: string;
  minHoursRequired!: number;
  maxHoursPermitted!: number;
  taxable!: boolean;
  active !: boolean;
  overtimePaymentTiers?: OvertimePaymentTier[]
}

export class UpdateOvertimeDto {
  employeeBandId?: number;
  code?: string;
  name?: string;
  description?: string;
  minHoursRequired?: number;
  maxHoursPermitted?: number;
  taxable?: boolean;
  active?: boolean;
}

export interface OvertimeDto extends Overtime { }

export interface OvertimePaginator extends PaginatorInfo<OvertimeDto> {}

export class QueryOvertimeDto {
  companyId?: number;
  employeeBandId?: number;
  code?: string;
  minHoursRequired?: number;
  maxHoursPermitted?: number;
  taxable?: boolean;
  active?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy?: OvertimeOrderBy = OvertimeOrderBy.CREATED_AT_ASC;
}

export enum OvertimeOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  CODE_ASC = 'code:asc',
  CODE_DESC = 'code:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc',
  MIN_HOURS_REQUIRED_ASC = 'minHoursRequired:asc',
  MIN_HOURS_REQUIRED_DESC = 'minHoursRequired:desc',
  MAX_HOURS_PERMITTED_ASC = 'maxHoursPermitted:asc',
  MAX_HOURS_PERMITTED_DESC = 'maxHoursPermitted:desc',

}

export class SearchOvertimeDto {
  q?: string
  page: number = 1;
  limit?: number;
  orderBy: OvertimeOrderBy = OvertimeOrderBy.CREATED_AT_ASC;
}