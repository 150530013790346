import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useCompleteReimbursementRequest } from "../../../../services/reimbursement-request.service";

type CompleteRequestModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const CompleteRequestModal =  ({ open, setOpen, tableAPIRef }: CompleteRequestModalProps) => {
	const { formDataValues } = useEditFormStore();
	const [comment, setComment] = useState("");
	const [uploading, setUploading] = useState(false);

	const { mutate: completeRequest } = useCompleteReimbursementRequest();

	const complete = () => {
		setUploading(true);

		const payload = { comment: comment || undefined };

		completeRequest(
			{ payload, id: formDataValues?.id },
			{
				onSuccess: (updatedData) => {
					tableAPIRef?.current.updateRows([updatedData.data]);
					setOpen(false);
				},
				onSettled: () => setUploading(false)
			}
		)
	};

	return (
		<ModalComponent
			width={660}
			open={open}
			title="Complete Request"
			handleClose={() => setOpen(false)}
		>
			<div className="flex items-start justify-between">
				<div>
					<h2 className="text-secondary-lightgray text-2xs">Title</h2>
					<p className="text-2sm">
						{formDataValues?.title || ""}
					</p>
				</div>
				<div>
					<h2 className="text-secondary-lightgray text-2xs">Employee</h2>
					<p className="text-2sm">
						{(formDataValues?.employee?.firstName || "") + " " + (formDataValues?.employee?.lastName || "")}
					</p>
				</div>
			</div>
			<div className="mt-6">
				<h2 className="text-secondary-lightgray text-2xs">Description</h2>
				<p className="text-2sm">
					{formDataValues?.description || ""}
				</p>
			</div>
			<div className="w-full mt-6">
				<Label title="Comment" for="comment" optional />
				<input
					placeholder={"Enter comment"}
					type="text"
					id="comment"
					className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
			</div>
			<div className="w-full flex justify-end mt-8">
				<OrangeButton 
					title={uploading ? "Submitting..." : "Complete Request"}
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					disabled={uploading} 
					onClick={complete}
				/>
			</div>
		</ModalComponent>
	);
};
 
export default CompleteRequestModal;