import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { LoanDto, REPAYMENT_TYPE } from "../../../../models/loan.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetLoan, useDeleteLoan } from "../../../../services/loan.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { LoansColumns } from "../data";
import LoanModalForm from "../modals/LoanModalForm";

const LoansView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	// const { mutate: searchLoans } = useGetLoanSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<LoanDto>({ 
		fetchHook: useGetLoan,
		options: { companyId: currentCompany?.id }
	});
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};
	
	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteLoan } = useDeleteLoan();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteLoan(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};
	
	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = LoansColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					{/* <APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchLoans}
					/> */}
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Employee",
								fieldName: "employeeId",
								fieldType: "SELECT_FIELD",
								options: employees?.data || [],
								mapTexts: ["firstName", "lastName"],
							},
							{
								title: "Start Pay Period",
								fieldName: "startPayPeriodId",
								fieldType: "SELECT_FIELD",
								options: payPeriods?.data || [],
								mapText: "code",
							},
							{
								title: "Repayment Type",
								fieldName: "repaymentType",
								fieldType: "CHECKBOX_LIST",
								options: REPAYMENT_TYPE,
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Loan</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal &&
				<LoanModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
							? "Update Loan"
							: "Add New Loan"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					payPeriods={payPeriods?.data || []}
					employees={employees?.data || []}
				/>
			}
		</div>
	);
};

export default LoansView;
