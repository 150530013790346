import { useEffect, useState  } from "react";
import { Avatar } from "@mui/material";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { getReimbursementStatusColor } from "../data";
import { moneyFormat, enumToStringConverter } from "../../../../helpers/appHelpers";
import { useGetReimbursementRequestById } from "../../../../services/reimbursement-request.service";
import { Link } from "react-router-dom";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import RequestActionModal from "./RequestActionModal";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { useSearchParams } from "react-router-dom";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { UserCompanyProfile } from "../../../../models/user.dto";
import useUserStore from "../../../../state-management/useUserStore";
import { PATHS } from "../../../../routes/routes.paths";

type RequestDetailsModalProps = {
	title: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	supervisor: boolean;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const RequestDetailsModal = ({ 
	title, 
	open, 
	setOpen, 
	supervisor,
	tableAPIRef,
}: RequestDetailsModalProps) => {
	const [searchParams] = useSearchParams();
	const pathname = window.location.pathname;
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
    const requestId = searchParams.get("requestId");
	const { formDataValues, setFormData } = useEditFormStore();
	const [openRequestActionModal, setOpenRequestActionModal] = useState(false);

	const { data: reimbursementData, isLoading } = useGetReimbursementRequestById(
		requestId ? Number(requestId) : formDataValues?.id
	);

	useEffect(() => {
		if (!isLoading && reimbursementData) {
			setFormData(reimbursementData.data);
		}
	}, [isLoading])

	const handleClose = () => setOpen(false);

	return (<>
		<ModalComponent
			open={open}
			title={title}
			width={660}
			handleClose={handleClose}
			additionalStyles={{ opacity: openRequestActionModal ? 0 : 100 }}
		>
			<div className="text-charcoal">
				<section className="flex items-start justify-between mt-3">
					<div>
						<h2 className="text-secondary-lightgray text-2xs">Title</h2>
						<p className="text-2sm">
							{formDataValues?.title || ""}
						</p>
					</div>
					<div
						className="rounded-lg px-3 py-2 inline-flex text-white"
						style={{ background: getReimbursementStatusColor(formDataValues?.status) }}
					>
						<p className="text-xs">{enumToStringConverter(formDataValues?.status)}</p>
					</div>
				</section>
				<section className="mt-6">
					<h2 className="text-secondary-lightgray text-2xs">Description</h2>
					<p className="text-2sm">
						{formDataValues?.description || ""}
					</p>
				</section>
				{supervisor && (
					<div className="flex items-start justify-between mt-6">
						<div>
							<h2 className="text-secondary-lightgray text-2xs">Employee</h2>
							<p className="text-2sm">
								{(formDataValues?.employee?.firstName || "") + " "}
								{formDataValues?.employee?.lastName || ""}
							</p>
						</div>
					</div>
				)}
				{(formDataValues?.approver && !supervisor) && (
					<div className="flex items-start justify-between mt-6">
						<div>
							<h2 className="text-secondary-lightgray text-2xs">Approver</h2>
							<p className="text-2sm">
								{(formDataValues?.approver?.firstName || "") + " "}
								{formDataValues?.approver?.lastName || ""}
							</p>
						</div>
					</div>
				)}
				<section className="flex items-start justify-between mt-6">
					<div>
						<h2 className="text-secondary-lightgray text-2xs">Date Submitted</h2>
						<p className="text-2sm">
							{new Date(formDataValues?.createdAt).toLocaleDateString("en-US", { 
								month: "short", day: "numeric", year: "numeric" 
							})}
						</p>
					</div>
					<div className="flex flex-col items-end">
						<h2 className="text-secondary-lightgray text-2xs">Amount Payable</h2>
						<p className="text-2sm">
							<span> {(formDataValues?.currency?.currency?.code || "") + " "}</span>
							<span className="max-w-[80%] truncate">
								{moneyFormat(formDataValues?.amount)} 
							</span>
						</p>
					</div>
				</section>
				<section className="mt-6">
					<h2 className="text-secondary-lightgray text-2xs">Attachements</h2>
					<div>
						<div className="flex space-x-4 mt-3">
							{isLoading && <>
								{Array.from({ length: 2 }).map((_, index) => (
									<div key={index} className="h-[65px] w-[65px] rounded bg-gray-300 animate-pulse" />
								))}
							</>}

							{(!isLoading && !formDataValues?.requestAttachments?.length) && (
								<p>No attachments</p>
							)}
							
							{formDataValues?.requestAttachments?.map((attachment: any, index) => (
								<Link 
									key={index}
									to={attachment.attachmentUrl} 
									target="_blank"
									className="w-fit"
								>
									<Avatar
										alt={"file " + (index + 1)}
										src={attachment.attachmentUrl}
										variant="rounded"
										className="border border-primary-blue hover:border-2 cursor-pointer"
										sx={{ width: 65, height: 65 }}
									/>
								</Link>
							))}
						</div>
					</div>
				</section>
				<section className="my-8 text-2xs">
					<h2 className="font-bold mb-4 text-2sm">Comments</h2>
					{(!isLoading && !formDataValues?.requestComments?.length) && (
						<p className="text-base">No comments</p>
					)}
					<div className="mb-4 w-full max-h-[350px] text-[#292D30] flex flex-col gap-2.5 pr-1 overflow-y-auto children-scroll-bar">
						{isLoading && <>
							{Array.from({ length: 2 }).map((_, index) => (
								<div key={index} className="flex gap-2 w-full">
									<div className="h-[29px] w-[29px] rounded-full bg-gray-300 animate-pulse" />
									<div className="w-full">
										<div className="w-full flex items-center justify-between">
											<div className="w-[100px] h-[13px] rounded bg-gray-300 animate-pulse" />
											<div className="w-[65px] h-[13px] rounded bg-gray-300 animate-pulse" />
										</div>
										<div className="mt-2 w-full h-[13px] rounded bg-gray-300 animate-pulse" />
									</div>
								</div>
							))}
						</>}

						{formDataValues?.requestComments?.map((comment) => (
							<div key={comment.id} className="flex gap-2 w-full">
								<Avatar
									src={comment.commenter.photoUrl}
									alt="Dp"
									sx={{ width: 29, height: 29 }}
								/>
								<div className="w-full">
									<div className="w-full flex items-center justify-between">
										<p className="font-bold">
											{comment.commenter.firstName + " "}
											{comment.commenter.lastName}
										</p>
										<p>{comment.createdAt.slice(0, 10)}</p>
									</div>
									<p>{comment.comment}</p>
								</div>
							</div>
						))}
					</div>
				</section>

				{(
					(formDataValues?.status === "QUERIED") || 
					(supervisor && formDataValues?.status === "SUBMITTED")
				) && (<>
					{pathname === PATHS.HR_REIMBURSEMENTS ? (<>
						{formDataValues?.employee?.id !== companyProfile.employeeId && (
							<div className="w-full flex justify-end mt-8">
								<OrangeButton 
									title="Add Response To Claim"
									className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
									onClick={() => setOpenRequestActionModal(true)}
								/>
							</div>
						)}
					</>) : (<>
						<div className="w-full flex justify-end mt-8">
							<OrangeButton 
								title="Add Response To Claim"
								className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
								onClick={() => setOpenRequestActionModal(true)}
							/>
						</div>
					</>)}
				</>)}
			</div>
		</ModalComponent>

		{openRequestActionModal && (
			<RequestActionModal 
				open={openRequestActionModal}
				setOpen={setOpenRequestActionModal}
				supervisor={supervisor}
				tableAPIRef={tableAPIRef}
			/>
		)}
	</>);
};

export default RequestDetailsModal;
