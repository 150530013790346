import { useEffect, useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import { TbTriangleFilled } from "react-icons/tb";
import CompanyDetails from "./views/CompanyDetails";
import CompanyLevels from "./views/company_levels";
import CostCenters from "./views/cost_centers";
import CompanyGrouping from "./views/company_grouping";
import GradeLevels from "./views/grade_levels";
import { COMPANY_GROUPING_TYPE } from "../../../models/company-grouping.dto";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "../../../routes/routes.paths";
import DisciplinaryTypes from "./views/disciplinary_types";
import GrievanceTypes from "./views/grievance_types";
import EmployeeBands from "./views/employee_bands";
import WorkDaysInAPeriod from "./views/work_days_in_a_period";
import CustomTaxReliefs from "./views/custom_tax_relief";
import AssetTypes from "./views/asset_types";
import JobTitles from "./views/job_titles";
import PayPeriods from "./views/pay_periods";
import CompanyCurrency from "./views/company_currency";
import Unions from "./views/unions";
import ErpIntegrationMappings from "./views/erp_integration_mapping";
import Departments from "./views/departments";

const secondSideBars = [
	{
		key: "companyDetails",
		name: "Company Details",
		component: CompanyDetails,
		type: "",
	},
	{
		key: "companyLevels",
		name: "Company Levels",
		component: CompanyLevels,
		type: "",
	},
	{
		key: "companyCurrency",
		name: "Company Currencies",
		component: CompanyCurrency,
		type: "",
	},
	{
		key: "costCenters",
		name: "Cost Centers",
		component: CostCenters,
		type: "",
	},
	{
		key: "regions",
		name: "Regions",
		component: CompanyGrouping,
		type: COMPANY_GROUPING_TYPE.REGION,
	},
	{
		key: "divisions",
		name: "Divisions",
		component: CompanyGrouping,
		type: COMPANY_GROUPING_TYPE.DIVISION,
	},
	{
		key: "stations",
		name: "Stations",
		component: CompanyGrouping,
		type: COMPANY_GROUPING_TYPE.STATION,
	},
	{
		key: "tribes",
		name: "Tribes",
		component: CompanyGrouping,
		type: COMPANY_GROUPING_TYPE.TRIBE,
	},
	{
		key: "departments",
		name: "Departments",
		component: Departments,
		type: "",
	},
	{
		key: "gradeLevels",
		name: "Grade Levels",
		component: GradeLevels,
		type: "",
	},
	{
		key: "bands",
		name: "Employee Bands & Notches",
		component: EmployeeBands,
		type: "",
	},
	{
		key: "workDaysInAPeriod",
		name: "Work Days In A Period",
		component: WorkDaysInAPeriod,
		type: "",
	},
	{
		key: "customTaxRelief",
		name: "Custom Tax Relief",
		component: CustomTaxReliefs,
		type: "",
	},
	{
		key: "payPeriods",
		name: "Pay Periods",
		component: PayPeriods,
		type: "",
	},
	{
		key: "jobTitles",
		name: "Job Titles",
		component: JobTitles,
		type: "",
	},
	{
		key: "erpIntegrationMappings",
		name: "ERP Integration Mappings",
		component: ErpIntegrationMappings,
		type: "",
	},
	{
		key: "disciplinaryTypes",
		name: "Disciplinary Types",
		component: DisciplinaryTypes,
		type: "",
	},
	{
		key: "grievanceTypes",
		name: "Grievance Types",
		component: GrievanceTypes,
		type: "",
	},
	{
		key: "assetTypes",
		name: "Assets Type",
		component: AssetTypes,
		type: "",
	},
	{
		key: "unions",
		name: "Unions",
		component: Unions,
		type: "",
	},
];

const CompanySettings = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const view = searchParams.get("view") || "companyDetails";
	const [info, setInfo] = useState<any>();

	useEffect(() => {
		if (!view) {
			setSearchParams({ view: "companyDetails" });

			const selectedInfo = secondSideBars.find((x) => x.key === "companyDetails");
			setInfo(selectedInfo);
			return
		}

		const selectedInfo = secondSideBars.find((x) => x.key === view);
		setInfo(selectedInfo);
	}, [view])

	const handleFormClick = (key: string) => {
		setSearchParams({ view: key });
		setInfo(secondSideBars.find((x) => x.key === key));
		navigate(PATHS.COMPANY_SETTINGS + `?view=${key}`);
	};

	function switchView(view: string, props?: { [key: string]: any }) {
		const pickedView = secondSideBars.find((x) => x.key === view);
		const defaultView = <></>;
		if (pickedView?.key) {
			const { component } = pickedView;
			switch (view) {
				case pickedView.key:
					if (component.name) return component;
					return defaultView;
				default:
					return defaultView;
			}
		}

		return defaultView;
	}

	return (
		<PayrollCompanyLayout>
			<Helmet>Company Settings | Company Flow</Helmet>

			<h1 className="w-full font-bold text-xl bg-white mb-8">
				Company Settings{" "}
				<span className="text-[15px] font-medium text-[#007aff]">
					({info?.name})
				</span>
			</h1>
			{view && (
				<div 
					className="grow w-full flex gap-4"
					style={{ height: "calc(100% - 60px)" }}
				>
					<section className="h-full w-[20%] text-2sm rounded-lg flex flex-col gap-y-6 bg-white border py-7 px-4 overflow-y-auto children-scroll-bar">
						{secondSideBars.map((bar) => (
							<div
								key={bar.key}
								className={`flex items-center gap-3 cursor-pointer ${
									view === bar.key ? "text-primary-blue" : "text-black"
								}`}
								onClick={() => handleFormClick(bar.key)}
							>
								<TbTriangleFilled 
									className={`rotate-90 ${view !== bar.key && "text-white"}`} 
								/>
								<span>{bar.name}</span>
							</div>
						))}
					</section>
					<section className="h-full w-[80%] rounded-lg bg-white border py-8 px-7 overflow-y-auto children-scroll-bar">
						{secondSideBars.map((bar) => {
							const Component = switchView(view);
							return (
								view === bar.key && (
									<div key={bar.key} className="w-full">
										{/*//@ts-ignore*/}
										<Component type={bar.type} title={bar.name} />
									</div>
								)
							);
						})}
					</section>
				</div>
			)}
		</PayrollCompanyLayout>
	);
};

export default CompanySettings;
