import { createContext, useEffect, useState } from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { PATHS } from "./routes.paths";
import API from "../services/utils/api"
import packageJson from "../../package.json";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../services/utils/requests";
import { useGetCompaniesByOrganizationId } from "../services/organization.service";
import { useSignOut } from "../services/auth.service"
import { 
	adminRoutes, 
	authRoutes, 
	hrRoutes, 
	payrollCompanyRoutes, 
	payrollCompanySetupRoutes, 
	selfServiceRoutes 
} from "./routes.data";
import useUserStore from "../state-management/useUserStore";
import useCompanyStore from "../state-management/useCompanyStore";
import { useSupervisorCheck } from "../pages/self_service/dashboard/hooks/useSupervisorCheck";
import { EmployeeDto } from "../models/employee.dto";
import { useAsyncEffect, useLockFn } from "ahooks";
import { SubscriptionTierOrderBy } from "../models/subscription-tier.dto";

export const LoadingCompanies = createContext<boolean>(false);

const NotFoundComponent = () => <div>Page Not Found</div>;

function AppRoutes() {
	return (
		<BrowserRouter basename={packageJson.homepage || "/"}>
			<Routes>
				{/* Authentication */}
				{authRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<NoAuthRequired>
								<Component />
							</NoAuthRequired>
						}
					/>
				))}

				{/* Admin Dashboard */}
				{adminRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<RequireAuth>
								<AdminRoutes>
									<Component />
								</AdminRoutes>
							</RequireAuth>
						}
					/>
				))}

				{/* Payroll Company */}
				{payrollCompanySetupRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<RequireAuth>
								<PayrollCompanyRoutes>
									<Component />
								</PayrollCompanyRoutes>
							</RequireAuth>
						}
					/>
				))}

				{payrollCompanyRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<RequireAuth>
								<PayrollCompanyRoutes>
									<CompanyCheck>
										<Component />
									</CompanyCheck>
								</PayrollCompanyRoutes>
							</RequireAuth>
						}
					/>
				))}

				{/* Human Resource */}
				{hrRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<RequireAuth>
								<HumanResourceRoutes>
									<Component />
								</HumanResourceRoutes>
							</RequireAuth>
						}
					/>
				))}

				{/* Self Service Portal */}
				{selfServiceRoutes.map(({ path, element: Component }) => (
					<Route
						key={path}
						path={path}
						element={
							<RequireAuth>
								<SelfServiceRoutes>
									<Component />
								</SelfServiceRoutes>
							</RequireAuth>
						}
					/>
				))}

				<Route key={"notFound"} path={"*"} element={<NotFoundComponent />} />
			</Routes>
		</BrowserRouter>
	);
}

function NoAuthRequired({ children }: { children: JSX.Element }) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { currentUser, userType } = useUserStore();
	const location = useLocation();
	const PrivateRoutes = adminRoutes.concat(
		payrollCompanySetupRoutes, 
		payrollCompanyRoutes, 
		hrRoutes, 
		selfServiceRoutes,
	);

	const isAPrivateRoute = PrivateRoutes.map((x) =>
		x.path.toLowerCase()
	).includes(location.pathname.toLowerCase());
	const userSignedIn = currentUser?.user?.id;

	useEffect(() => {
		const prevPage = decodeURIComponent(
			searchParams.get("prev_page") || ""
		);

		if (userSignedIn && !isAPrivateRoute) {
			switch(userType) {
				case "ADMIN":
					if (prevPage && prevPage.startsWith("/admin")) {
						navigate(prevPage);
						return
					}
					navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
					break;

				case "ORGANIZATION":
					if (prevPage && prevPage.startsWith("/dashboard")) {
						navigate(prevPage);
						return
					}
					navigate(PATHS.OVERVIEW);
					break;

				case "HR":
					if (
						prevPage && (prevPage.startsWith("/hr") || 
						prevPage.startsWith("/selfservice"))
					) {
						navigate(prevPage);
						return
					}
					navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
					break;

				case "EMPLOYEE":
					if (prevPage && prevPage.startsWith("/selfservice")) {
						navigate(prevPage);
						return;
					}
					navigate(PATHS.SELF_SERVICE_DASHBOARD);
					break;
			}
		}
	}, [userSignedIn, userType])

	return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
	const location = useLocation();
	const signOutUser = useSignOut();
	const { currentUser, updateUserAuthTokens } = useUserStore();
	let refreshLogicCalled = false;

	const handleRefreshToken = useLockFn(async () => {
		refreshLogicCalled = true;

		try {
			const response = await API.UserAPI.refreshToken(
				currentUser.refresh_token
			);
			updateUserAuthTokens(response?.data);
		} catch (error: any) {
			if (error.response?.data?.error.includes("TOKEN_EXPIRED")) {
				return signOutUser(location);
			}
		}
	});

	useEffect(() => {
		axiosInstance.interceptors.response.use(
			(response: AxiosResponse<any, any>) => {
				refreshLogicCalled = false;
				return response;
			},
			async (error: { response: { status: number; data: any } }) => {
				if (
					(error.response && error.response.status === 401) ||
					(error.response && error.response.status === 403) ||
					(error.response && error.response.data.message === "Unauthorized")
				) {
					if (
						(
							error.response.data.error === "TokenExpiredError" ||
							error.response.data.message === "jwt expired"
						)
					) {
						if (!refreshLogicCalled) {
							await handleRefreshToken();
						}
					}
				}
				return Promise.reject(error);
			}
		);
	}, [])

	if (!currentUser?.user?.id) {
		return <Navigate 
			to={{ 
				pathname: PATHS.SIGN_IN,
				search: `?prev_page=${encodeURIComponent(location.pathname + location.search)}`
			}} 
		/>
	}

	return children;
}

function CompanyCheck({ children }: { children: JSX.Element }) {
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const { 
		currentCompany, 
		savedCompanies,
		setCurrentCompany,
		setSavedCompanies,
	} = useCompanyStore();
	const recentCompanyId = localStorage.getItem("recentCompanyId");

	const { 
		data: companies, 
		isLoading: isLoadingCompanies,
		error 
	} = useGetCompaniesByOrganizationId(
		currentUser.user.organizationId, 
		true,
	);

	useEffect(() => {
		if (companies?.data && !savedCompanies.length) {
			setSavedCompanies(companies.data || []);
			if (recentCompanyId) {
				setCurrentCompany(
					companies.data.find(company => company.id === Number(recentCompanyId)) || 
					companies.data[0] as any
				);
				return
			}
			setCurrentCompany(companies.data[0] as any);
		}
	}, [isLoadingCompanies]);

	useEffect(() => {
		if (
			!currentCompany &&
			((error as any)?.response?.data?.error === "FORBIDDEN" ||
			(error as any)?.response?.status === 403)
		) {
			navigate(PATHS.CREATE_PAYROLL_COMPANY);
		}
	}, [isLoadingCompanies, error]);

    useAsyncEffect(useLockFn(async () => {
        if (sessionStorage.getItem("highestSubscriptionTierId")) return;

        try {
            const { data } = await API.BillingAndSubscriptionAPI.getSubscriptionTierPackages({
				page: 1,
				limit: 20,
				orderBy: SubscriptionTierOrderBy.PRICE_AMOUNT_ASC,
			});

            sessionStorage.setItem("highestSubscriptionTierId", data[data.length - 1].id);
        } catch {}
    }), [])

	return (
		<LoadingCompanies.Provider value={isLoadingCompanies}>
			{children}
		</LoadingCompanies.Provider>
	)
}

function PayrollCompanyRoutes({ children }: { children: JSX.Element }) {
	const { currentUser } = useUserStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (!currentUser) return;
		const userCategory = currentUser.user.category;
		const adminUser = currentUser.user.organization?.billingType.admin;

		if (userCategory.toLowerCase() === "operations" && adminUser) {
			navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
		} else if (userCategory.toLowerCase() === "employee") {
			navigate(PATHS.SELF_SERVICE_DASHBOARD);
		} else if (userCategory.toLowerCase() === "hr") {
			navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
		}
	}, [currentUser])

	return children
}

function AdminRoutes({ children }: { children: JSX.Element }) {
	const { currentUser } = useUserStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (!currentUser) return;
		const userCategory = currentUser.user.category;
		const adminUser = currentUser.user.organization?.billingType.admin;

		if (userCategory.toLowerCase() === "operations" && !adminUser) {
			navigate(PATHS.OVERVIEW);
		} else if (userCategory.toLowerCase() === "employee") {
			navigate(PATHS.SELF_SERVICE_DASHBOARD);
		} else if (userCategory.toLowerCase() === "hr") {
			navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
		}
	}, [currentUser])

	return children
}

function HumanResourceRoutes({ children }: { children: JSX.Element }) {
	const navigate = useNavigate();
    const { currentUser } = useUserStore();
	const { currentCompany, setCurrentCompany } = useCompanyStore();
	const companyProfile = (currentUser.user.companyProfiles || [])[0];

	useEffect(() => {
		if (!currentUser) return;
		const userCategory = currentUser.user.category;
		const adminUser = currentUser.user.organization?.billingType.admin;

		if (userCategory.toLowerCase() === "operations" && adminUser) {
			navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
		} else if (userCategory.toLowerCase() === "operations" && !adminUser) {
			navigate(PATHS.OVERVIEW);
		} else if (userCategory.toLowerCase() === "employee") {
			navigate(PATHS.SELF_SERVICE_DASHBOARD);
		}
	}, [currentUser])

    useAsyncEffect(useLockFn(async () => {
        if (currentCompany) return;

        try {
            const response = await API.ProcessingCompanyAPI.getCompanyById(companyProfile?.companyId);

            setCurrentCompany(response.data);
        } catch {}
    }), [companyProfile?.companyId])

	return children
}

export const EmployeeSupervisees = createContext<EmployeeDto[]>([]);

function SelfServiceRoutes({ children }: { children: JSX.Element }) {
	const navigate = useNavigate();
    const { currentUser } = useUserStore();
	const companyProfile = (currentUser.user.companyProfiles || [])[0];

	useEffect(() => {
		if (!currentUser) return;
		const userCategory = currentUser.user.category;
		const adminUser = currentUser.user.organization?.billingType.admin;

		if (userCategory.toLowerCase() === "operations" && adminUser) {
			navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
		} else if (userCategory.toLowerCase() === "operations" && !adminUser) {
			navigate(PATHS.OVERVIEW);
		}
	}, [currentUser])
	
	const { supervisees } = useSupervisorCheck(companyProfile?.companyId);

	return <EmployeeSupervisees.Provider value={supervisees}> 
		{children}
	</EmployeeSupervisees.Provider>
}

export default AppRoutes;
