import { Popover } from "@mui/material";
import { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";
import { MdOutlinePersonRemove } from "react-icons/md";
import { PiUserSwitch } from "react-icons/pi";
import { EmployeeDto } from "../../../../models/employee.dto";
import { useUnlinkEmployeeFromCompanyTree } from "../../../../services/company-tree-node.service";
import { useOrganizationTreeStore, TreeNode } from "../../../../state-management/useOrganizationTreeStore";
import UpdateEmployeeModal from "./UpdateEmployeeModal";
import UpdateParentNodeModal from "./UpdateParentNodeModal";
import DeleteNodeModal from "./DeleteNodeModal";
import useUserStore from "../../../../state-management/useUserStore";
import API from '../../../../services/utils/api';

type NodeMenuProps = {
    anchorEl: any;
    node: TreeNode;
    employees: EmployeeDto[];
    handleClose: (closeMenu?: any) => void;
}

const NodeMenu = ({
    anchorEl,
    node,
    employees,
    handleClose,
}: NodeMenuProps) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
    const { treeSourceData, generateTree } = useOrganizationTreeStore();
	const [disableNodeMenu, setDisableNodeMenu] = useState(false);
	const [updateParentNodePopoverEl, setUpdateParentNodePopoverEl] = useState<any>(null);
	const [updateEmployeePopoverEl, setUpdateEmployeePopoverEl] = useState<any>(null);
	const [deleteNodePopoverEl, setDeleteNodePopoverEl] = useState<any>(null);

	const closeUpdateParentNodePopover = (closeMenu?: any) => {
		setUpdateParentNodePopoverEl(null);
		closeMenu === "true" && handleClose();
	};
	const closeUpdateEmployeePopover = (closeMenu?: any) => {
		setUpdateEmployeePopoverEl(null);
		closeMenu === "true" && handleClose();
	};
	const closeDeleteNodePopover = (closeMenu?: any) => {
		setDeleteNodePopoverEl(null);
		closeMenu === "true" && handleClose();
	};
	
    const { mutate: unLinkEmployee } = useUnlinkEmployeeFromCompanyTree();

	const handleUnLinkEmployee = () => {
		setDisableNodeMenu(true);

		unLinkEmployee(
			{ 
				companyId: companyProfile.companyId,
				nodeId: node?.id as number,
			},
			{
				onSuccess: async () => {
                    const { data } = await API.HrAPI.getCompanyTree(companyProfile.companyId);
                    generateTree(data);
					handleClose();
					setDisableNodeMenu(false);
				},
				onError: () => setDisableNodeMenu(false),
			}
		)
	};

    return (<>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => handleClose()}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    height: "auto",
                    fontSize: "15px",
                    color: "#4F4F4F",
                    borderRadius: 10,
                    background: "white",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                    overflow: "hidden",
                }
            }}
        >
            <button 
                onClick={(e) => setUpdateEmployeePopoverEl(e.currentTarget)}
                disabled={disableNodeMenu}
                className={`${updateEmployeePopoverEl ? "bg-[#F4F4F4]" : "hover:bg-[#F4F4F4]"} 
                    w-full pl-4 pr-6 py-3 flex items-center gap-2 node-button 
                `}
            >
                <PiUserSwitch className="text-[18px]" />
                <span>Re-assign</span>
            </button>
            <button 
                onClick={(e) => setUpdateParentNodePopoverEl(e.currentTarget)}
                disabled={disableNodeMenu}
                className={`${updateParentNodePopoverEl ? "bg-[#F4F4F4]" : "hover:bg-[#F4F4F4]"} 
                    w-full pl-4 pr-6 py-3 flex items-center gap-2 node-button 
                `}
            >
                <BiEditAlt className="text-[18px]" />
                <span>Change Supervisor</span>
            </button>
            <button 
                onClick={handleUnLinkEmployee}
                disabled={disableNodeMenu}
                className="w-full pl-4 pr-6 py-3 flex items-center gap-2 node-button hover:bg-[#F4F4F4]"
            >
                <MdOutlinePersonRemove className="text-[18px]" />
                <span>Remove Employee</span>
            </button>
            {(node.parentId !== null) && (
                <button 
                    onClick={(e) => setDeleteNodePopoverEl(e.currentTarget)}
                    disabled={disableNodeMenu}
                    className={`${deleteNodePopoverEl ? "bg-[#F4F4F4]" : "hover:bg-[#F4F4F4]"} 
                        w-full pl-4 pr-6 py-3 flex items-center gap-2 node-button 
                    `}
                >
                    <HiOutlineTrash className="text-[18px]" />
                    <span>Delete</span>
                </button>
            )}
        </Popover>

        {updateEmployeePopoverEl && (
            <UpdateEmployeeModal
                anchorEl={updateEmployeePopoverEl}
                selectedNode={node}
                employees={
                    employees?.filter((employee) => {
                        return !treeSourceData.find(node => node.data.employeeId === employee.id)
                    })
                }
                handleClose={closeUpdateEmployeePopover}
            />
        )}

        {updateParentNodePopoverEl && (
            <UpdateParentNodeModal
                anchorEl={updateParentNodePopoverEl}
                node={node}
                validParentNodes={
                    treeSourceData
                    .filter(treeNode => {
                        if (
                            treeNode.id !== node.id && 
                            treeNode.id !== node.parentId &&
                            treeNode.data.employee
                        ) {
                            return treeNode
                        }
                    })
                    .sort((a, b) => {
                        const fullNameA = a.data.employee?.firstName + ' ' + a.data.employee?.lastName;
                        const fullNameB = b.data.employee?.firstName + ' ' + b.data.employee?.lastName;
                        return fullNameA.localeCompare(fullNameB);
                    })
                }
                handleClose={closeUpdateParentNodePopover}
            />
        )}

        {deleteNodePopoverEl && (
            <DeleteNodeModal
                anchorEl={deleteNodePopoverEl}
                nodeId={node.id}
                childNodes={node.children || []}
                validParentNodes={
                    treeSourceData
                    .filter(treeNode => {
                        if (
                            treeNode.id !== node.id && 
                            treeNode.parentId !== node.id &&
                            treeNode.data.employee
                        ) {
                            return treeNode
                        }
                    })
                    .sort((a, b) => {
                        const fullNameA = a.data.employee?.firstName + ' ' + a.data.employee?.lastName;
                        const fullNameB = b.data.employee?.firstName + ' ' + b.data.employee?.lastName;
                        return fullNameA.localeCompare(fullNameB);
                    })
                }
                handleClose={closeDeleteNodePopover}
            />
        )}
    </>);
}
 
export default NodeMenu;