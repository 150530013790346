import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreatePartialSalaryDto,
    PartialSalaryDto,
    PartialSalaryPaginator,
    QueryPartialSalaryDto,
    QueryPartialSalaryGivenPayPeriodDto,
    UpdatePartialSalaryDto
} from "../models/partial-salary.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetPartialSalary(options?: QueryPartialSalaryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET;
    const request = (options: QueryPartialSalaryDto) => API.ProcessingCompanyAPI.getPartialSalary(options);

	const { data, isLoading, error, refetch } = useQuery<PartialSalaryPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetPartialSalaryGivenPayPeriod(payPeriodId: number, options?: QueryPartialSalaryGivenPayPeriodDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET_WITH_PAY_PERIOD;
    const request = (
        options: QueryPartialSalaryGivenPayPeriodDto, payPeriodId: number,
    ) => API.ProcessingCompanyAPI.getPartialSalaryGivenPayPeriod(payPeriodId, options);

	const { data, isLoading, error, refetch } = useQuery<PartialSalaryPaginator, Error>(
		[url, options, payPeriodId],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam), payPeriodId),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}


export function useGetPartialSalaryById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.DETAIL;
    const request = API.ProcessingCompanyAPI.getPartialSalaryById(id);

    const {data, isLoading, error} = useQuery<{ data: PartialSalaryDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreatePartialSalary() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreatePartialSalaryDto}) => API.ProcessingCompanyAPI.createPartialSalary(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET);
            },
        }
    )
}

export function useUpdatePartialSalary() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdatePartialSalaryDto, id: number}) => API.ProcessingCompanyAPI.updatePartialSalary(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET);
            },
        }
    )
}

export function useDeletePartialSalary() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deletePartialSalary(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}