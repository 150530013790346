import { Modal, Box } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { ModalStyle } from "../../helpers/appHelpers";
import OrangeButton from "../buttons/OrangeButton";

type UpdateAccountConfigModalProps = {
	open: boolean;
    content: string;
    actionButtonTitle?: string;
    doNotShowCloseButton?: boolean;
	handleClose: () => void;
    onClickActionButton: (() => void) | null;
}
const UpdateAccountConfigModal = ({
    open,
    content,
    actionButtonTitle,
    doNotShowCloseButton,
    handleClose,
    onClickActionButton,
}: UpdateAccountConfigModalProps) => {
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={ModalStyle} width={572}>
                <div className="flex justify-between items-center mb-4">
                    <h1 className="font-bold text-lg">Upgrade your Account</h1>
                    {!doNotShowCloseButton && (
                        <button onClick={handleClose}>
                            <IoMdClose className="text-xl hover:scale-[1.1]" />
                        </button>
                    )}
                </div>
                <p className="text-[15px] mb-8">{content}</p>
                <div className="flex justify-end">
                    <OrangeButton
                        title={actionButtonTitle || "Continue"}
                        onClick={() => onClickActionButton && onClickActionButton()}
                        className={"px-12"}
                    />
                </div>
            </Box>
        </Modal>
    );
}
 
export default UpdateAccountConfigModal;