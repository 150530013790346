import { useEffect, useState } from "react";
import EmptyPromptPopover from "../popovers/emptyPromptPopover";
import { Popover } from "@mui/material";
import { IoCaretDownSharp } from "react-icons/io5";

type MultiSelectFieldProps = {
    options: any[];
    setResourceIds: React.Dispatch<React.SetStateAction<number[]>>;
    placeholder: string;
    required: boolean;
    defaultValues?: number[];
    pathname?: string;
    mapKey?: string;
    mapValue?: string;
    mapText?: string;
    mapTexts?: string[];
    /** If true, the `Set Option` popup will not come up when 
     * the options array (the API resource data) is empty */
    doNotLinkToResource?: boolean;
}

const MultiSelectField = ({
    options,
    setResourceIds,
    placeholder,
    required,
    defaultValues,
    pathname,
    mapKey,
    mapValue,
    mapText,
    mapTexts,
    doNotLinkToResource,
}: MultiSelectFieldProps) => {
	const [selectedItems, setSelectedItems] = useState<any>({});
	const [emptyPopoverEl, setEmptyPopoverEl] = useState<any>(null);
	const [optionsContainerEl, setOptionsContainerEl] = useState<any>(null);

    useEffect(() => {
        if (!defaultValues?.length) return;

        options.forEach((option) => {
            const key = mapKey ? option[mapKey] : option.id;
            const value = getOptionValue(option, mapTexts, mapText);

            if (defaultValues?.find(val => val === key)) {
                handleOptionItems(key, value);
            }
        });
    }, [])

	const handleSelectBox = (event: any) => {
		if (!options.length) {
            setEmptyPopoverEl(event.currentTarget);
            return
        }

        setOptionsContainerEl(event.currentTarget);
	};

	const handleOptionItems = (key: number, value: string) => {
        if (selectedItems[key]) {
            setSelectedItems(prev => {
                delete prev[key];
                return prev;
            });
            setResourceIds(prev => prev.filter(item => item !== key));
        } else {
            setSelectedItems(prev => ({ ...prev, [key]: value }));
            setResourceIds(prev => [...prev, key]);
        }
	};

    return (<>  
        <div
            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm cursor-default flex items-center justify-between gap-10 ${
                (required && !Object.keys(selectedItems).length)
                    ? "border border-red-500 focus:border-red-500 focus:outline-red-500"
                    : "border border-transparent"
            }`}
            onClick={handleSelectBox}
        >
            {!Object.keys(selectedItems).length ? (
                <span>{placeholder}</span>
            ):(
                <span className="w-[90%] truncate">
                    {Object.entries(selectedItems).map(([key, value], index) => (
                        `${(value as any).toString().trim()}` + ((index !== Object.keys(selectedItems).length - 1) ? ", " : "")
                    ))}
                </span>
            )}
            <IoCaretDownSharp className="w-4 text-black" />
        </div>
        {(required && !Object.keys(selectedItems).length) && (
            <p className="text-red-500 text-sm mt-1">
                Select at least one option here
            </p>
        )}
        {optionsContainerEl && (
            <Popover
                open={Boolean(optionsContainerEl)}
                anchorEl={optionsContainerEl}
                onClose={() => setOptionsContainerEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "100%",
                        maxHeight: "900px",
                        maxWidth: "380px",
                        fontSize: "13px",
                        color: "black",
                        borderRadius: 10,
                        background: "white",
                        border: "0.2px solid #BCBCBC",
                        boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                        overflowX: "hidden",
                        overflowY: "auto",
                        whiteSpace: "normal",
                    }
                }}
                classes={{ root: "children-scroll-bar", paper: "children-scroll-bar" }}
            >
                {options.map((option) => (
                    <div 
                        key={mapKey ? option[mapKey] : option.id}
                        onClick={() => {
                            handleOptionItems(
                                mapValue ? option[mapValue] : option.id,
                                getOptionValue(option, mapTexts, mapText)
                            )
                        }} 
                        className={`w-full px-5 text-sm cursor-default hover:bg-[#acacac] hover:text-white ${
                            selectedItems[mapValue ? option[mapValue] : option.id]
                                ? "bg-[#7c7c7c] text-white" 
                                : ""
                        }`}
                    >
                        {getOptionValue(option, mapTexts, mapText)}
                    </div>
                ))}
            </Popover>
        )}
        {(!options.length && !doNotLinkToResource) && (
            <EmptyPromptPopover
                id={Boolean(emptyPopoverEl) ? "simple-popover" : undefined}
                open={Boolean(emptyPopoverEl)}
                anchorEl={emptyPopoverEl}
                handleClose={() => setEmptyPopoverEl(null)}
                pathname={pathname || ""}
            />
        )}
    </>);
}
 
export default MultiSelectField;

const getOptionValue = (option: any, mapTexts?: string[], mapText?: string) => {
    return mapTexts 
        ? mapTexts.reduce((sum, key) => sum + `${option[key]} `, "")
        : mapText 
            ? option[mapText] 
            : option.name;
};