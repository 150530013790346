import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    TaxReliefTypeDto, TaxReliefTypePaginator,
    CreateTaxReliefTypeDto, QueryTaxReliefTypeDto,
    UpdateTaxReliefTypeDto, SearchTaxReliefTypeDto
} from "../models/tax-relief-type.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetTaxReliefType(options?: QueryTaxReliefTypeDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.GET;
    const request = (options: QueryTaxReliefTypeDto) => API.ProcessingCompanyAPI.getTaxReliefType(options);

    const { data, isLoading, error, refetch } = useQuery<TaxReliefTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, ...queryOptions}
    );

    return { data, isLoading, error, refetch };
}

export function useGetTaxReliefTypeSearch() {

    return useMutation(
        (options?: SearchTaxReliefTypeDto) => API.ProcessingCompanyAPI.getTaxReliefTypeSearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
export function useGetTaxReliefTypeById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.DETAIL;
    const request = API.ProcessingCompanyAPI.getTaxReliefTypeById(id);

    const {data, isLoading, error} = useQuery<{ data:TaxReliefTypeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateTaxReliefType(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateTaxReliefTypeDto}) => API.ProcessingCompanyAPI.createTaxReliefType(payload),
        {
            onSuccess: (response,) => {

                if(onSuccessCallback){
                    onSuccessCallback(response)
                }

                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.GET);
            },
        }
    )
}

export function useUpdateTaxReliefType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateTaxReliefTypeDto, id: number}) => API.ProcessingCompanyAPI.updateTaxReliefType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.GET);
            },
        }
    )
}

export function useDeleteTaxReliefType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteTaxReliefType(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: (error, variables, context)=> {
                console.log(error);
                toast.error('An error occurred whiles running create/update');
            },
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.GET);
            },
        }
    )
}
