import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { useCreateContractType } from "../../../../../services/contract-type.service";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import useUserStore from "../../../../../state-management/useUserStore";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	active: boolean;
	considerEmployee: boolean;
	fixedTerm: boolean;
	postRetirement: boolean;
	suspendOnExpiration: boolean;
}

interface INewContractTypeForm {
	setShowNewContractTypeForm: React.Dispatch<React.SetStateAction<boolean>>;
	setNewData: React.Dispatch<React.SetStateAction<string>>;
}

const NewContractTypeForm = ({
	setShowNewContractTypeForm,
	setNewData,
}: INewContractTypeForm) => {
	const { currentUser } = useUserStore();
	const [uploading, setUploading] = useState(false);
	const { mutate: createContractType } = useCreateContractType();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createContractType(
			{
				payload: {
					...data,
					organizationId: currentUser.user.organization?.id as string,
				}
			},
			{
				onSuccess: () => {
					setNewData("true");
					setShowNewContractTypeForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for="name" />
					<input
						placeholder={"Enter name"}
						type="text"
						{...register("name", {
							required: "Enter name here",
						})}
						id="name"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.name && (
						<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors.code.message}
						</p>
					)}
				</div>
				<div className="col-span-2 w-full">
					<Label title="Description" for="description" />
					<FormDescriptionInput 
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`
						}}
						register={register}
						required="Enter brief description here"
					/>
					{errors?.description && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.description?.message}
						</p>
					)}
				</div>
			</div>
			<div className="w-full mt-6 flex flex-col gap-6">
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("considerEmployee", {
							required: false,
						})}
						id="considerEmployee"
					/>
					<label htmlFor="considerEmployee">Consider Employee</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("fixedTerm", {
							required: false,
						})}
						id="fixedTerm"
					/>
					<label htmlFor="fixedTerm">Fixed Term</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("postRetirement", {
							required: false,
						})}
						id="postRetirement"
					/>
					<label htmlFor="postRetirement">Post Retirement</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("suspendOnExpiration", {
							required: false,
						})}
						id="suspendOnExpiration"
					/>
					<label htmlFor="suspendOnExpiration">Suspend On Expiration</label>
				</div>
				<div className="flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active", {
							required: false,
						})}
						id="active"
					/>
					<label htmlFor="active">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewContractTypeForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={"Add Contract Type"}
					className={"h-14 px-8"}
					onClick={handleSubmit(onSubmit)}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewContractTypeForm;
