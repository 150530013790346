import {get, set, remove} from "./ls";
import {PayrollCompanyDto} from "../../models/company-account.dto";

export function setAccessToken(token: string) {
    set('@token', token)
}

export function getAccessToken() {
    return get('@token') as string;
}
