import { Pagination, PaginationItem } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

type CustomPaginationProps = {
    pageCount?: number;
    pageState?: number;
    handleChange?: (_: any, value: any) => void;
}

const CustomPagination = ({
    pageCount,
    pageState,
    handleChange: defaultHandleChange,
}: CustomPaginationProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
    const handleChange = (_, value: number) => {
        setPage(value);
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set("page", value.toString());
        setSearchParams(currentParams);
    };

    return (
        <Pagination
            color="primary"
            variant="text"
            shape="rounded"
            page={pageState || page}
            count={pageCount || 1}
            sx={{
                "& .MuiPaginationItem-root": {
                    backgroundColor: "#FFFFFF",
                    color: "#B4B7BD",
                    borderRadius: "4px",
                    minWidth: "32px",
                    height: "32px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    margin: "0 2px",
                    "&:hover": {
                        backgroundColor: "#F3BF7AD2",
                        color: "#000",
                    },
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#F58B00",
                    color: "#FFFFFF",
                },
                "& .MuiPaginationItem-root.Mui-selected:hover": {
                    backgroundColor: "#F58B00",
                    color: "#FFFFFF",
                },
            }}
            renderItem={(props2) => <PaginationItem {...props2} component="button" />}
            onChange={defaultHandleChange || handleChange}
        />
    );
}
 
export default CustomPagination;