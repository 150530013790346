import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { 
	formatDate, 
	formatDateAndTime, 
	moneyFormat, 
	enumToStringConverter 
} from "../../../helpers/appHelpers";
import { REIMBURESEMENT_REQUEST_STATUS } from "../../../models/reimbursement-request.dto";
import { Avatar } from "@mui/material";

const CommonColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "title",
		headerName: "Title",
		headerClassName: "super-app-theme--header",
		width: 250,
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "amount",
		headerName: "Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const currencyCode = params.row?.currency?.currency?.code;
			return (
				<div className="flex items-center gap-2 w-full">
					<span>{currencyCode || ""}</span>
					<span className="max-w-[80%] truncate">{" " + moneyFormat(params.value)}</span>
				</div>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getReimbursementStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getReimbursementStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "expenditureDate",
		headerName: "Expenditure Date",
		width: 220,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => {
			const expenditureDate = formatDate(params);
			return expenditureDate;
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Last Date Modified",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
]

export const YourClaimsTableColumns: GridColDef[] = [
	...CommonColumns,
	// {
	// 	field: "approver",
	// 	headerName: "Approver",
	// 	width: 250,
	// 	headerClassName: "super-app-theme--header",
	// 	renderCell(params) {
	// 		return (
	// 			<div className="flex items-center space-x-4">
	// 				<Avatar
	// 					src={params.value?.photoUrl}
	// 					alt="Dp"
	// 					className="w-10 h-10 rounded-full"
	// 				/>
	// 				<p>{(params.value?.firstName || "") + " " + (params.value?.lastName || "")}</p>
	// 			</div>
	// 		);
	// 	},
	// },
];

export const SuperviseesClaimsColumns: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.value?.photoUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p>{(params.value?.firstName || "") + " " + (params.value?.lastName || "")}</p>
				</div>
			);
		},
	},

	...CommonColumns,
];

export const getReimbursementStatusColor = (status: REIMBURESEMENT_REQUEST_STATUS) => {
	switch(status) {
		case "APPROVED":
			return "#4CAF50"
		case "COMPLETED":
			return "#808080"
		case "REJECTED":
			return "#F44336"
		case "SUBMITTED":
			return "#FFC107"
		case "QUERIED":
			return "#2196F3"
		default: 
			return "#ADD8E6"
	}
};