import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Country",
		width: 240,
		headerClassName: "super-app-theme--header",
		renderCell: (params) => {
			return (
				<div className="w-full flex items-center gap-3">
					<img
						src={params.row.flagUrl}
						alt="flag"
						className="w-8 h-8 rounded-lg object-cover"
					/>
					<p className="text-sm line-clamp-1">{params.row.name}</p>
				</div>
			);
		},
	},
	{
		field: "code",
		headerName: "Country Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "currency",
		headerName: "Currency",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.row.currency ?
						<p>
							{params.row.currency.name} ({params.row.currency.symbol})
						</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	{
		field: "applyLoanDeductionOnBasic",
		headerName: "Apply Loan Deduction On Basic",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.value !== null ?
						<p>{params.value === true ? "Yes" : "No"}</p> : 
						<p>--</p>
					}
				</div>
			);
		},
	},
	{
		field: "maxLoanDeductionPercentage",
		headerName: "Max Loan Deduction Percentage",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.value !== null ?
						<p>{params.value * 100}%</p> : 
						<p>--</p>
					}
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
