import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import {
    CreateLeavePlanDto,
    LeavePlanDto,
    LeavePlanPaginator,
    QueryLeavePlanDto,
    UpdateLeavePlanDto
} from "../../../models/leave-plan.dto";
import {
    AdjustDaysDto,
    CreateLeaveRequestDto,
    LeaveRequestDto,
    LeaveRequestPaginator,
    LeaveResponseInputDto,
    QueryLeaveRequestDto,
    UpdateLeaveRequestDto
} from "../../../models/leave-request.dto";
import { CreateDisciplinaryActionTypeDto, DisciplinaryActionType, DisciplinaryActionTypePaginator, QueryDisciplinaryActionTypeDto, SearchDisciplinaryActionTypeDto, UpdateDisciplinaryActionTypeDto } from "../../../models/disciplinary-action-type.dto";
import { CreateDisciplinaryActionDto, DisciplinaryAction, DisciplinaryActionPaginator, QueryDisciplinaryActionDto, SearchDisciplinaryActionDto, UpdateDisciplinaryActionDto } from "../../../models/disciplinary-action.dto";
import { CreateGrievanceTypeDto, GrievanceType, GrievanceTypePaginator, QueryGrievanceTypeDto, SearchGrievanceTypeDto, UpdateGrievanceTypeDto } from "../../../models/grievance-type.dto";
import { CreateGrievanceReportDto, GrievanceReportDto, GrievanceReportPaginator, QueryGrievanceReportDto, SearchGrievanceReportDto, UpdateGrievanceReportDto } from "../../../models/grievance-report.dto";
import { AddGrievanceReportedEmployeeDto, ReportedEmployeesDto } from "../../../models/grievance-reported-employee.dto";
import { CreateLeaveTypeDto, EmployeLeaveTypeSummary, LeaveTypeDto, LeaveTypePaginator, QueryApplicableLeaveTypeDto, QueryLeaveTypeDto, SearchLeaveTypeDto, UpdateLeaveTypeDto } from "../../../models/leave-type.dto";
import { CreateLeavePackageDto, LeavePackageDto, LeavePackagePaginator, QueryLeavePackageDto, SearchLeavePackageDto, UpdateLeavePackageDto } from "../../../models/leave-package.dto";
import { CompanyTreeNode, CreateCompanyTreeNodeDto, DeleteCompanyTreeNodeQueryDto, GetSuperviseesWithMeta, UpdateCompanyTreeNodeDto } from "../../../models/company-tree-node.dto";
import { CompleteReimbursementRequestDto, CreateReimbursementRequestDto, QueryReimbursementRequestDto, ReimbursementRequestDto, ReimbursementRequestPaginator, ReimbursementRequestUpdatesDto, ReimbursementResponseInputDto, SearchReimbursementRequestDto, UpdateReimbursementRequestDto } from "../../../models/reimbursement-request.dto";
import { CreateEmployeeWorkTimeDto, EmployeeWorkTimeDto, EmployeeWorkTimePaginator, QueryEmployeeWorkTimeDto, UpdateEmployeeWorkTimeDto } from "../../../models/employee-work-time.dto";
import { CreateEmployeeOvertimeEntryDto, EmployeeOvertimeEntryDto, EmployeeOvertimeEntryPaginator, QueryEmployeeOvertimeEntryDto, UpdateEmployeeOvertimeEntryDto } from "../../../models/employee-overtime-entry.dto";
import { CompanyDocumentTypeDto, CompanyDocumentTypePaginator, CreateCompanyDocumentTypeDto, QueryCompanyDocumentTypeDto, SearchCompanyDocumentTypeDto, UpdateCompanyDocumentTypeDto } from "../../../models/company-document-type.dto";
import { CreateEmployeeDocumentDto, EmployeeDocumentDto, EmployeeDocumentPaginator, QueryEmployeeDocumentDto, UpdateEmployeeDocumentDto } from "../../../models/employee-document.dto";
import { AnnouncementDto, AnnouncementPaginator, AnnouncementResourceDto, CreateAnnouncementDto, QueryAnnouncementDto, SearchAnnouncementDto, UpdateAnnouncementDto, UpdateAnnouncementResourceDto } from "../../../models/announcement.dto";
import { CreateEmployeeApproverDto, EmployeeApproverDto, EmployeeApproverPaginator, EmployeeApproverPreflightResponseDto, GetOneEmployeeApproverDto, QueryEmployeeApproverDto, UpdateEmployeeApproverDto } from "../../../models/employee-approver.dto";

export class Hr {
    getLeaveType = (query?: QueryLeaveTypeDto) => HttpClient
        .get<LeaveTypePaginator>(ENDPOINTS.HR.LEAVE_TYPE.GET, query);
    getLeaveTypeSearch = (query?: SearchLeaveTypeDto) => HttpClient
        .get<LeaveTypePaginator>(ENDPOINTS.HR.LEAVE_TYPE.SEARCH, query);
    getApplicableLeaveType = (query?: QueryApplicableLeaveTypeDto) => HttpClient
        .get<LeaveTypePaginator>(ENDPOINTS.HR.LEAVE_TYPE.GET_APPLICABLE, query);
    getLeaveTypeById = (leaveTypeId: number) => HttpClient
        .get<{ data: LeaveTypeDto }>(ENDPOINTS.HR.LEAVE_TYPE.DETAIL
            .replace('{leaveTypeId}', String(leaveTypeId)));
    createLeaveType = (payload: CreateLeaveTypeDto) => HttpClient
        .post<{ data: LeaveTypeDto }>(ENDPOINTS.HR.LEAVE_TYPE.CREATE, payload); 
    updateLeaveType = (leaveTypeId: number, payload: UpdateLeaveTypeDto) => HttpClient
        .patch<{ data: LeaveTypeDto }>(ENDPOINTS.HR.LEAVE_TYPE.UPDATE
            .replace('{leaveTypeId}', String(leaveTypeId)), payload);
    deleteLeaveType = (leaveTypeId: number) => HttpClient
        .delete<{ data: LeaveTypeDto }>(ENDPOINTS.HR.LEAVE_TYPE.DELETE
            .replace('{leaveTypeId}', String(leaveTypeId)));
    
    getLeavePackage = (query?: QueryLeavePackageDto) => HttpClient
        .get<LeavePackagePaginator>(ENDPOINTS.HR.LEAVE_PACKAGE.GET, query);
    getLeavePackageSearch = (query?: SearchLeavePackageDto) => HttpClient
        .get<LeavePackagePaginator>(ENDPOINTS.HR.LEAVE_PACKAGE.SEARCH, query);
    getLeavePackageById = (leavePackageId: number) => HttpClient
        .get<{ data: LeavePackageDto }>(ENDPOINTS.HR.LEAVE_PACKAGE.DETAIL
            .replace('{leavePackageId}', String(leavePackageId)));
    createLeavePackage = (payload: CreateLeavePackageDto) => HttpClient
        .post<{ data: LeavePackageDto }>(ENDPOINTS.HR.LEAVE_PACKAGE.CREATE, payload);
    updateLeavePackage = (leavePackageId: number, payload: UpdateLeavePackageDto) => HttpClient
        .patch<{ data: LeavePackageDto }>(ENDPOINTS.HR.LEAVE_PACKAGE.UPDATE
            .replace('{leavePackageId}', String(leavePackageId)), payload);
    deleteLeavePackage = (leavePackageId: number) => HttpClient
        .delete<{ data: LeavePackageDto }>(ENDPOINTS.HR.LEAVE_PACKAGE.DELETE
            .replace('{leavePackageId}', String(leavePackageId)));
    getEmployeeLeaveTypeSummary = (employeeId: number, leaveTypeId: number) => HttpClient
        .post<{ data: EmployeLeaveTypeSummary }>(ENDPOINTS.HR.LEAVE_REQUEST.SUMMARY
            .replace('{employeeId}', String(employeeId))
            .replace('{leaveTypeId}', String(leaveTypeId)), undefined); 

    getLeavePlans = (query?: QueryLeavePlanDto) => HttpClient
        .get<LeavePlanPaginator>(ENDPOINTS.HR.LEAVE_PLAN.GET, query);
    getLeavePlanById = (leavePlanId: number) => HttpClient
        .get<{ data: LeavePlanDto }>(ENDPOINTS.HR.LEAVE_PLAN.DETAIL
            .replace('{leavePlanId}', String(leavePlanId)));
    createLeavePlan = (payload: CreateLeavePlanDto) => HttpClient
        .post<{ data: LeavePlanDto }>(ENDPOINTS.HR.LEAVE_PLAN.CREATE, payload);
    updateLeavePlan = (leavePlanId: number, payload: UpdateLeavePlanDto) => HttpClient
        .patch<{ data: LeavePlanDto }>(ENDPOINTS.HR.LEAVE_PLAN.UPDATE
            .replace('{leavePlanId}', String(leavePlanId)), payload);
    deleteLeavePlan = (leavePlanId: number) => HttpClient
        .delete<{ data: LeavePlanDto }>(ENDPOINTS.HR.LEAVE_PLAN.DELETE
            .replace('{leavePlanId}', String(leavePlanId)));

    getLeaveRequests = (query?: QueryLeaveRequestDto) => HttpClient
        .get<LeaveRequestPaginator>(ENDPOINTS.HR.LEAVE_REQUEST.GET, query);
    getLeaveRequestById = (leaveRequestId: number) => HttpClient
        .get<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.DETAIL
            .replace('{leaveRequestId}', String(leaveRequestId)));
    createLeaveRequest = (payload: CreateLeaveRequestDto) => HttpClient
        .post<{ data :LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.CREATE, payload);
    updateLeaveRequest = (leaveRequestId: number, payload: UpdateLeaveRequestDto) => HttpClient
        .patch<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.UPDATE
            .replace('{leaveRequestId}', String(leaveRequestId)), payload);
    deleteLeaveRequest = (leaveRequestId: number) => HttpClient
        .delete<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.DELETE
            .replace('{leaveRequestId}', String(leaveRequestId)));
    addLeaveResponse = (leaveRequestId: number, payload: LeaveResponseInputDto) => HttpClient
        .post<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.RESPONSE
            .replace('{leaveRequestId}', String(leaveRequestId)), payload);
    cancelLeaveRequest = (leaveRequestId: number) => HttpClient
        .post<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.CANCEL
            .replace('{leaveRequestId}', String(leaveRequestId)), undefined);
    adjustLeaveRequest = (leaveRequestId: number,  payload: AdjustDaysDto) => HttpClient
        .patch<{ data: LeaveRequestDto }>(ENDPOINTS.HR.LEAVE_REQUEST.ADJUST
            .replace('{leaveRequestId}', String(leaveRequestId)), payload);
    
    getDisciplinaryActionType = (query?: QueryDisciplinaryActionTypeDto) => HttpClient
        .get<DisciplinaryActionTypePaginator>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.GET, query);
    getDisciplinaryActionTypeBySearch = (query?: SearchDisciplinaryActionTypeDto) => HttpClient
        .get<DisciplinaryActionTypePaginator>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.SEARCH, query);
    getDisciplinaryActionTypeById = (discipActionTypeId: number) => HttpClient
        .get<{ data: DisciplinaryActionType }>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.DETAIL
            .replace('{discipActionTypeId}', String(discipActionTypeId)));
    createDisciplinaryActionType = (payload: CreateDisciplinaryActionTypeDto) => HttpClient
        .post<{ data: DisciplinaryActionType }>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.CREATE, payload);
    updateDisciplinaryActionType = (discipActionTypeId: number, payload: UpdateDisciplinaryActionTypeDto) => HttpClient
        .patch<{ data: DisciplinaryActionType }>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.UPDATE
            .replace('{discipActionTypeId}', String(discipActionTypeId)), payload);
    deleteDisciplinaryActionType = (discipActionTypeId: number) => HttpClient
        .delete<{ data: DisciplinaryActionType }>(ENDPOINTS.HR.DISCIPLINARY_ACTION_TYPE.DELETE
            .replace('{discipActionTypeId}', String(discipActionTypeId)));

    getDisciplinaryAction = (query?: QueryDisciplinaryActionDto) => HttpClient
        .get<DisciplinaryActionPaginator>(ENDPOINTS.HR.DISCIPLINARY_ACTION.GET, query);
    getDisciplinaryActionBySearch = (query?: SearchDisciplinaryActionDto) => HttpClient
        .get<DisciplinaryActionPaginator>(ENDPOINTS.HR.DISCIPLINARY_ACTION.SEARCH, query);
    getDisciplinaryActionById = (discipActionId: number) => HttpClient
        .get<{ data: DisciplinaryAction }>(ENDPOINTS.HR.DISCIPLINARY_ACTION.DETAIL
            .replace('{discipActionId}', String(discipActionId)));
    createDisciplinaryAction = (payload: CreateDisciplinaryActionDto) => HttpClient
        .post<{ data: DisciplinaryAction }>(ENDPOINTS.HR.DISCIPLINARY_ACTION.CREATE, payload);
    updateDisciplinaryAction = (discipActionId: number, payload: UpdateDisciplinaryActionDto) => HttpClient
        .patch<{ data: DisciplinaryAction }>(ENDPOINTS.HR.DISCIPLINARY_ACTION.UPDATE
            .replace('{discipActionId}', String(discipActionId)), payload);
    deleteDisciplinaryAction = (discipActionId: number) => HttpClient
        .delete<{ data: DisciplinaryAction }>(ENDPOINTS.HR.DISCIPLINARY_ACTION.DELETE
            .replace('{discipActionId}', String(discipActionId)));

    getGrievanceType = (query?: QueryGrievanceTypeDto) => HttpClient
        .get<GrievanceTypePaginator>(ENDPOINTS.HR.GRIEVANCE_TYPE.GET, query);
    getGrievanceTypeBySearch = (query?: SearchGrievanceTypeDto) => HttpClient
        .get<GrievanceTypePaginator>(ENDPOINTS.HR.GRIEVANCE_TYPE.SEARCH, query);
    getGrievanceTypeById = (grievanceTypeId: number) => HttpClient
        .get<{ data: GrievanceType }>(ENDPOINTS.HR.GRIEVANCE_TYPE.DETAIL
            .replace('{grievanceTypeId}', String(grievanceTypeId)));
    createGrievanceType = (payload: CreateGrievanceTypeDto) => HttpClient
        .post<{ data: GrievanceType }>(ENDPOINTS.HR.GRIEVANCE_TYPE.CREATE, payload);
    updateGrievanceType = (grievanceTypeId: number, payload: UpdateGrievanceTypeDto) => HttpClient
        .patch<{ data: GrievanceType }>(ENDPOINTS.HR.GRIEVANCE_TYPE.UPDATE
            .replace('{grievanceTypeId}', String(grievanceTypeId)), payload);
    deleteGrievanceType = (grievanceTypeId: number) => HttpClient
        .delete<{ data: GrievanceType }>(ENDPOINTS.HR.GRIEVANCE_TYPE.DELETE
            .replace('{grievanceTypeId}', String(grievanceTypeId)));

    getGrievanceReport = (query?: QueryGrievanceReportDto) => HttpClient
        .get<GrievanceReportPaginator>(ENDPOINTS.HR.GRIEVANCE_REPORT.GET, query);
    getGrievanceReportBySearch = (query?: SearchGrievanceReportDto) => HttpClient
        .get<GrievanceReportPaginator>(ENDPOINTS.HR.GRIEVANCE_REPORT.SEARCH, query);
    getGrievanceReportById = (grievanceReportId: number) => HttpClient
        .get<{ data: GrievanceReportDto }>(ENDPOINTS.HR.GRIEVANCE_REPORT.DETAIL
            .replace('{grievanceReportId}', String(grievanceReportId)));
    createGrievanceReport = (payload: CreateGrievanceReportDto) => HttpClient
        .post<{ data: GrievanceReportDto }>(ENDPOINTS.HR.GRIEVANCE_REPORT.CREATE, payload);
    updateGrievanceReport = (grievanceReportId: number, payload: UpdateGrievanceReportDto) => HttpClient
        .patch<{ data: GrievanceReportDto }>(ENDPOINTS.HR.GRIEVANCE_REPORT.UPDATE
            .replace('{grievanceReportId}', String(grievanceReportId)), payload);
    deleteGrievanceReport = (grievanceReportId: number) => HttpClient
        .delete<{ data: GrievanceReportDto }>(ENDPOINTS.HR.GRIEVANCE_REPORT.DELETE
            .replace('{grievanceReportId}', String(grievanceReportId)));
    createReportedEmployee = (grievanceReportId: number, payload: AddGrievanceReportedEmployeeDto) => HttpClient
        .post<{ data: ReportedEmployeesDto }>(ENDPOINTS.HR.GRIEVANCE_REPORT.CREATE_REPORTED_EMPLOYEE
            .replace('{grievanceReportId}', String(grievanceReportId)), payload);
    deleteReportedEmployee = (grievanceReportId: number, employeeId: number) => HttpClient
        .delete<{ data: void }>(ENDPOINTS.HR.GRIEVANCE_REPORT.DELETE
            .replace('{grievanceReportId}', String(grievanceReportId))
            .replace('{employeeId}', String(employeeId)));
    
    getCompanyTree = (companyId: number) => HttpClient
        .get<{ data: CompanyTreeNode }>(ENDPOINTS.HR.COMPANY_TREE_NODE.GET_TREE
            .replace('{companyId}', String(companyId)));
    getCompanyTreeNode = (companyId: number, nodeId: number) => HttpClient
        .get<{ data: CompanyTreeNode }>(ENDPOINTS.HR.COMPANY_TREE_NODE.GET_NODE
            .replace('{companyId}', String(companyId))
            .replace('{nodeId}', String(nodeId)));
    createCompanyTreeNode = (companyId: number, payload: CreateCompanyTreeNodeDto) => HttpClient
        .post<{ data: CompanyTreeNode }>(ENDPOINTS.HR.COMPANY_TREE_NODE.CREATE
            .replace('{companyId}', String(companyId)), payload);
    updateCompanyTreeNode = (companyId: number, nodeId: number, payload: UpdateCompanyTreeNodeDto) => HttpClient
        .patch<{ data: CompanyTreeNode }>(ENDPOINTS.HR.COMPANY_TREE_NODE.UPDATE
            .replace('{companyId}', String(companyId))
            .replace('{nodeId}', String(nodeId)), payload);
    unlinkEmployeeFromCompanyTreeNode = (companyId: number, nodeId: number) => HttpClient
        .delete<{ data: CompanyTreeNode }>(ENDPOINTS.HR.COMPANY_TREE_NODE.UNLINK
            .replace('{companyId}', String(companyId))
            .replace('{nodeId}', String(nodeId)));
    deleteCompanyTreeNode = (companyId: number, nodeId: number, query?: DeleteCompanyTreeNodeQueryDto) => HttpClient
        .delete<{ data: void }>(ENDPOINTS.HR.COMPANY_TREE_NODE.DELETE
            .replace('{companyId}', String(companyId))
            .replace('{nodeId}', String(nodeId)), query);
    getSupervisees = (companyId: number) => HttpClient
        .get<GetSuperviseesWithMeta>(ENDPOINTS.HR.COMPANY_TREE_NODE.GET_SUPERVISEES
            .replace('{companyId}', String(companyId)));

    getReimbursementRequest = (query?: QueryReimbursementRequestDto) => HttpClient
        .get<ReimbursementRequestPaginator>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.GET, query);
    getReimbursementRequestSearch = (query?: SearchReimbursementRequestDto) => HttpClient
        .get<ReimbursementRequestPaginator>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.SEARCH, query);
    getReimbursementRequestById = (reimbReqId: number) => HttpClient
        .get<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.DETAIL
            .replace('{reimbReqId}', String(reimbReqId)));
    createReimbursementRequest = (payload: CreateReimbursementRequestDto) => HttpClient
        .post<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.CREATE, payload);
    updateReimbursementRequest = (reimbReqId: number, payload: UpdateReimbursementRequestDto) => HttpClient
        .patch<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.UPDATE
            .replace('{reimbReqId}', String(reimbReqId)), payload);
    respondToReimbursementRequest = (reimbReqId: number, payload: ReimbursementResponseInputDto) => HttpClient
        .post<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.RESPOND
            .replace('{reimbReqId}', String(reimbReqId)), payload);
    postUpdateToReimbursementRequest = (reimbReqId: number, payload: ReimbursementRequestUpdatesDto) => HttpClient
        .post<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.POST_UPDATE
            .replace('{reimbReqId}', String(reimbReqId)), payload);
    completeReimbursementRequest = (reimbReqId: number, payload: CompleteReimbursementRequestDto) => HttpClient
        .post<{ data: ReimbursementRequestDto }>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.COMPLETE
            .replace('{reimbReqId}', String(reimbReqId)), payload);
    deleteReimbursementRequest = (reimbReqId: number) => HttpClient
        .delete<void>(ENDPOINTS.HR.REIMBURSEMENT_REQUEST.DELETE.replace('{reimbReqId}', String(reimbReqId)));

    getEmployeeWorkTime = (query?: QueryEmployeeWorkTimeDto) => HttpClient
        .get<EmployeeWorkTimePaginator>(ENDPOINTS.HR.EMPLOYEE_WORK_TIME.GET, query);
    getEmployeeWorkTimeById = (empWorkTimeId: number) => HttpClient
        .get<{ data: EmployeeWorkTimeDto }>(ENDPOINTS.HR.EMPLOYEE_WORK_TIME.DETAIL
            .replace('{empWorkTimeId}', String(empWorkTimeId)));
    createEmployeeWorkTime = (payload: CreateEmployeeWorkTimeDto) => HttpClient
        .post<{ data: EmployeeWorkTimeDto }>(ENDPOINTS.HR.EMPLOYEE_WORK_TIME.CREATE, payload);
    updateEmployeeWorkTime = (empWorkTimeId: number, payload: UpdateEmployeeWorkTimeDto) => HttpClient
        .patch<{ data: EmployeeWorkTimeDto }>(ENDPOINTS.HR.EMPLOYEE_WORK_TIME.UPDATE
            .replace('{empWorkTimeId}', String(empWorkTimeId)), payload);
    deleteEmployeeWorkTime = (empWorkTimeId: number) => HttpClient
        .delete<void>(ENDPOINTS.HR.EMPLOYEE_WORK_TIME.DELETE
            .replace('{empWorkTimeId}', String(empWorkTimeId)));

    getEmployeeOvertimeEntry = (query?: QueryEmployeeOvertimeEntryDto) => HttpClient
        .get<EmployeeOvertimeEntryPaginator>(ENDPOINTS.HR.EMPLOYEE_OVERTIME_ENTRY.GET, query);
    getEmployeeOvertimeEntryById = (empOvertimeEntryId: number) => HttpClient
        .get<{ data: EmployeeOvertimeEntryDto }>(ENDPOINTS.HR.EMPLOYEE_OVERTIME_ENTRY.DETAIL
            .replace('{empOvertimeEntryId}', String(empOvertimeEntryId)));
    createEmployeeOvertimeEntry = (payload: CreateEmployeeOvertimeEntryDto) => HttpClient
        .post<{ data: EmployeeOvertimeEntryDto }>(ENDPOINTS.HR.EMPLOYEE_OVERTIME_ENTRY.CREATE, payload);
    updateEmployeeOvertimeEntry = (empOvertimeEntryId: number, payload: UpdateEmployeeOvertimeEntryDto) => HttpClient
        .patch<{ data: EmployeeOvertimeEntryDto }>(ENDPOINTS.HR.EMPLOYEE_OVERTIME_ENTRY.UPDATE
            .replace('{empOvertimeEntryId}', String(empOvertimeEntryId)), payload);
    deleteEmployeeOvertimeEntry = (empOvertimeEntryId: number) => HttpClient
        .delete<void>(ENDPOINTS.HR.EMPLOYEE_OVERTIME_ENTRY.DELETE
            .replace('{empOvertimeEntryId}', String(empOvertimeEntryId)));

            
getCompanyDocumentType = (query?: QueryCompanyDocumentTypeDto) => HttpClient
    .get<CompanyDocumentTypePaginator>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.GET, query);
getCompanyDocumentTypeSearch = (query?: SearchCompanyDocumentTypeDto) => HttpClient
    .get<CompanyDocumentTypePaginator>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.SEARCH, query);
getCompanyDocumentTypeById = (compDocTypeId: number) => HttpClient
    .get<{ data: CompanyDocumentTypeDto }>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.DETAIL
        .replace('{compDocTypeId}', String(compDocTypeId)));
createCompanyDocumentType = (payload: CreateCompanyDocumentTypeDto) => HttpClient
    .post<{ data: CompanyDocumentTypeDto}>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.CREATE, payload);
updateCompanyDocumentType = (compDocTypeId: number, payload: UpdateCompanyDocumentTypeDto) => HttpClient
    .patch<{ data: CompanyDocumentTypeDto }>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.UPDATE
        .replace('{compDocTypeId}', String(compDocTypeId)), payload);
deleteCompanyDocumentType = (compDocTypeId: number) => HttpClient
    .delete<void>(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.DELETE
        .replace('{compDocTypeId}', String(compDocTypeId)));

getEmployeeDocument = (query?: QueryEmployeeDocumentDto) => HttpClient
    .get<EmployeeDocumentPaginator>(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.GET, query);
getEmployeeDocumentById = (empDocumentId: number) => HttpClient
    .get<{ data: EmployeeDocumentDto }>(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.DETAIL
        .replace('{empDocumentId}', String(empDocumentId)));
createEmployeeDocument = (payload: CreateEmployeeDocumentDto) => HttpClient
    .post<{ data: EmployeeDocumentDto}>(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.CREATE, payload);
updateEmployeeDocument = (empDocumentId: number, payload: UpdateEmployeeDocumentDto) => HttpClient
    .patch<{ data: EmployeeDocumentDto }>(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.UPDATE
        .replace('{empDocumentId}', String(empDocumentId)), payload);
deleteEmployeeDocument = (empDocumentId: number) => HttpClient
    .delete<void>(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.DELETE
        .replace('{empDocumentId}', String(empDocumentId)));

getAnnouncement = (query?: QueryAnnouncementDto) => HttpClient
    .get<AnnouncementPaginator>(ENDPOINTS.HR.ANNOUNCEMENT.GET, query);
getMyAnnouncement = (query?: QueryAnnouncementDto) => HttpClient
    .get<AnnouncementPaginator>(ENDPOINTS.HR.ANNOUNCEMENT.MY_GET, query);
getAnnouncementSearch = (query?: SearchAnnouncementDto) => HttpClient
    .get<AnnouncementPaginator>(ENDPOINTS.HR.ANNOUNCEMENT.SEARCH, query);
getMyAnnouncementSearch = (query?: SearchAnnouncementDto) => HttpClient
    .get<AnnouncementPaginator>(ENDPOINTS.HR.ANNOUNCEMENT.MY_SEARCH, query);
getAnnouncementById = (announcementId: number) => HttpClient
    .get<{ data: AnnouncementDto }>(ENDPOINTS.HR.ANNOUNCEMENT.DETAIL
        .replace('{announcementId}', String(announcementId)));
createAnnoucement = (payload: CreateAnnouncementDto) => HttpClient
    .post<{ data: AnnouncementDto}>(ENDPOINTS.HR.ANNOUNCEMENT.CREATE, payload);
updateAnnouncement = (announcementId: number, payload: UpdateAnnouncementDto) => HttpClient
    .patch<{ data: AnnouncementDto }>(ENDPOINTS.HR.ANNOUNCEMENT.UPDATE
        .replace('{announcementId}', String(announcementId)), payload);
updateAnnouncementResource = (announcementId: number, resourceId: number, payload: UpdateAnnouncementResourceDto) => HttpClient
    .patch<{ data: AnnouncementResourceDto }>(ENDPOINTS.HR.ANNOUNCEMENT.UPDATE
        .replace('{announcementId}', String(announcementId))
        .replace('{resourceId', String(resourceId)), payload);
deleteAnnouncment = (announcementId: number) => HttpClient
    .delete<void>(ENDPOINTS.HR.ANNOUNCEMENT.DELETE
        .replace('{announcementId}', String(announcementId)));

getEmployeeApprover = (employeeId: number, query?: QueryEmployeeApproverDto) => HttpClient
    .get<EmployeeApproverPaginator>(ENDPOINTS.HR.EMPLOYEE_APPROVER.GET
        .replace('{employeeId}', String(employeeId)), query);
getEmployeeApproverById = (employeeId: number, empApproverId: number, query?: GetOneEmployeeApproverDto) => HttpClient
    .get<{ data: EmployeeApproverDto }>(ENDPOINTS.HR.EMPLOYEE_APPROVER.DETAIL
        .replace('{empApproverId}', String(empApproverId))
        .replace('{employeeId}', String(employeeId)));
createEmployeeApprover = (employeeId: number, payload: CreateEmployeeApproverDto) => HttpClient
    .post<{ data: EmployeeApproverDto}>(ENDPOINTS.HR.EMPLOYEE_APPROVER.CREATE
        .replace('{employeeId}', String(employeeId)), payload);
preflightEmployeeApprover = (employeeId: number, payload: CreateEmployeeApproverDto) => HttpClient
    .post<{ data: EmployeeApproverPreflightResponseDto}>(ENDPOINTS.HR.EMPLOYEE_APPROVER.PREFLIGHT
        .replace('{employeeId}', String(employeeId)), payload);
updateEmployeeApprover = (employeeId: number, empApproverId: number, payload: UpdateEmployeeApproverDto) => HttpClient
    .patch<{ data: EmployeeApproverDto }>(ENDPOINTS.HR.EMPLOYEE_APPROVER.UPDATE
        .replace('{employeeId}', String(employeeId))
        .replace('{empApproverId}', String(empApproverId)), payload);
deleteEmployeeApprover = (employeeId: number, empApproverId: number) => HttpClient
    .delete<void>(ENDPOINTS.HR.EMPLOYEE_APPROVER.DELETE
        .replace('{employeeId}', String(employeeId))
        .replace('{empApproverId}', String(empApproverId)));
}