import {PaginatorInfo} from "./generic.dto";
import {EmployeeDto} from "./employee.dto";

class AuthTokenPair {
    access_token!: string;
    refresh_token!: string;
}

export const USER_CATEGORY = {
    OPERATIONS: 'OPERATIONS',
    EMPLOYEE: 'EMPLOYEE',
    HR: 'HR'
};

export type USER_CATEGORY = (typeof USER_CATEGORY)[keyof typeof USER_CATEGORY];

export class User {
    id!: string;
    organizationId  !: string;
    organizationRoleId    !: string;
    category               !: USER_CATEGORY;
    username           !: string;
    firstName           !: string;
    lastName             !: string;
    emailAddress     !: string;
    msisdn               ?: string;
    imageUrl              ?: string;
    password             !: string;
    requirePasswordChange  !: boolean;
    status                 !: string;
    createdAt             !: string;
    modifiedAt             ?: string;
    // lastLoggedInAt         ?: string;
    // statusLastModifiedAt   ?: string;
    // passwordLastModifiedAt ?: string;
}

class Organization {
    id                       !:string;
    name                    !:string;
    countryId                !:number;
    currencyId              !:number;
    billingTypeId            !:number;
    subscriptionTierId      !:string;
    status                   ?:string;
    contactEmail              !:string;
    billingContactEmail      ?:string;
    contactMsisdn             ?:string;
    address                   !:string;
    logoUrl                  !:string;
    subscriptionDate         ?:Date
    lastSuccessfullyBilledAt?:Date
    statusLastModifiedAt     ?:Date
    createdAt                ?:Date
    modifiedAt               ?:Date
}

class OrganizationRole {
    id             !: string;
    organizationId !: string;
    name          !: string;
    primary       !: boolean;
    permissions    !: string[];
    active         !: boolean;
    createdAt      !: string;
    modifiedAt     ?: string;
}

export interface UserDto extends Omit<User, 'password'> {
    organization?: Organization;
    organizationRole?: OrganizationRole;
    companyProfiles?: Partial<UserCompanyProfile>[];
}

export class LoginResponse extends AuthTokenPair {
    user!: UserDto;
}

export type UserCompanyProfile = {
    companyId: number;
    employeeId?: number;
    roleId: string; //send an empty string
    employeeGrade?: number; //set this to null
}

export class CreateUserDto {
    organizationId!: string;
    organizationRoleId!: string;
    username!: string;
    firstName!: string;
    lastName!: string;
    emailAddress!: string;
    msisdn?: string | null;
    companyProfiles?: UserCompanyProfile[];
}

export class UpdateSelfDto {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    msisdn?: string;
    imageUrl?: string | null;
}

export class UpdateUserDto {
    organizationRoleId?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    msisdn?: string | null;
    imageUrl?: string | null;
    status?: string;
    companyProfiles?: UserCompanyProfile[];
  }

export class LoginDto {
    username!: string;
    password!: string;
}

export class ResendUserConfirmationDto {
    username!: string;
}

export class UserConfirmationDto {
    token!: string;
    password!: string;
}

export class InitiatePasswordResetDto {
    username!: string;
}

export class SetPasswordDto {
    token!: string;
    password!: string;
}

export interface RefreshTokenPayload {
    sessionId: string;
    iss?: string;
}

export class QueryUserDto {
    organizationId?: string;
    organizationRoleId?: string;
    category?: USER_CATEGORY;
    requirePasswordChange?: boolean;
    status?: string;
    'createdAt.gte'?: string;
   'createdAt.lte'?: string;
    page?: number = 1;
    limit?: number;
    orderBy?: UserOrderBy = UserOrderBy.CREATED_AT_DESC;
}

export enum UserOrderBy {
    FIRST_NAME_ASC = 'firstName:asc',
    FIRST_NAME_DESC = 'firstName:desc',
    LAST_NAME_ASC = 'lastName:asc',
    LAST_NAME_DESC = 'lastName:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export interface UserPaginator extends PaginatorInfo<UserDto> {
}

export class CountUserDto {
    organizationId?: string;
    organizationRoleId?: string;
    category?: USER_CATEGORY;
    status?: string;
    'createdAt.gte'?: string;
    'createdAt.lte'?: string;
  }
  
  export class ChangePasswordDto {
    password!: string;
    newPassword!: string;
  }

  
  export class SearchUserDto {
    q!: string;
    page: number = 1;
    limit?: number;
  }
  
  export interface UserProfile {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    msisdn?: string | null;
  }

  export interface AccountCheckDto {
    username: string;
  }
  
  export interface AccountCheckResponse {
    exists: boolean;
  }
  
  
  export type DetailedUser = User & {
    organizationRole?: Partial<OrganizationRole>;
    companyProfiles?: Partial<UserCompanyProfile>[];
  };
  
  export function toSafeUser(user: DetailedUser | UserDto): UserDto {
    const copy = JSON.parse(JSON.stringify(user));
    delete copy['password'];
    return copy as UserDto;
  }
  
  export function toSafeUsers(users: DetailedUser[]): UserDto[] {
    return users.map(user => toSafeUser(user));
  }
  