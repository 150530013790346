import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, moneyFormat } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "currency",
		headerName: "Currency",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params: any) {
			return (
				<div className="flex items-center space-x-2">
					{params.value?.name}
				</div>
			);
		},
	},
	{
		field: "buyRate",
		headerName: "Buy Rate",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{moneyFormat(params.value)}
				</div>
			);
		},
	},
	{
		field: "sellRate",
		headerName: "Sell Rate",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{moneyFormat(params.value)}
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
