import { useContext, useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { SuperviseesLeaveRequestColumns } from "../data";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useGetLeaveRequest } from "../../../../services/leave-request.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { LEAVE_REQUEST_OPTIONS, LeaveRequestDto } from "../../../../models/leave-request.dto";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { Popover } from "@mui/material";
import { LuEye } from "react-icons/lu";
import LeaveRequestDetailsModal from "../modals/LeaveRequestDetailsModal";
import { useSearchParams } from "react-router-dom";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { EmployeeSupervisees } from "../../../../routes";

const SuperviseesLeaveRequestsView = () => {
	const [searchParams] = useSearchParams();
    const requestId = searchParams.get("requestId");
	const { setFormData } = useEditFormStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openLeaveRequestDetailsModal, setOpenLeaveRequestDetailsModal] = useState(false);
	const supervisees = useContext(EmployeeSupervisees);

	useEffect(() => {
		if (requestId) {
			setOpenLeaveRequestDetailsModal(true);
		}
	}, [requestId])

	const {
		tableAPIRef,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<LeaveRequestDto>({
		fetchHook: useGetLeaveRequest,
		options: { queryMode: "SUPERVISEES" }
	});

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClose = () => setAnchorEl(null);

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = SuperviseesLeaveRequestColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
            <div className="flex justify-between mb-6">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Start Date",
									fieldName: "startDate",
								},
								{
									title: "Return Date",
									fieldName: "returnDate",
								},
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Supervisee",
							fieldName: "employeeId",
							fieldType: "SELECT_FIELD",
							options: supervisees || [],
							mapTexts: ["firstName", "lastName"],
						},
						{
							title: "Status",
							fieldName: "status",
							fieldType: "CHECKBOX_LIST",
							options: LEAVE_REQUEST_OPTIONS,
						},
						{
							title: "Start Date",
							fieldName: "startDate",
							fieldType: "DATE_RANGE",
						},
						{
							title: "Return Date",
							fieldName: "returnDate",
							fieldType: "DATE_RANGE",
						},
					]}
				/>
            </div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
            <Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
                            setOpenLeaveRequestDetailsModal(true);
                            handleClose();
                        }}
                        className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
                    >
                        <LuEye />
                        <p className="text-2sm">View Details</p>
                    </div>
                </div>
            </Popover>
            
			{openLeaveRequestDetailsModal && (
				<LeaveRequestDetailsModal
					open={openLeaveRequestDetailsModal}
					title={"Request Details"}
					setOpen={setOpenLeaveRequestDetailsModal}
					tableAPIRef={tableAPIRef}
					supervisor
				/>
			)}
		</>
	);
};

export default SuperviseesLeaveRequestsView;
