import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import ActionPopover from "../../../../components/popovers/actionPopover";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import DeleteModal from "../../../../components/modals/DeleteModal";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { EmployeeBandOrderBy } from "../../../../models/employee-band.dto";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import { CompanyPayrollRunColumns } from "../data";
import RunPayrollModalForm from "../modals/RunPayrollModalForm";
import Search from "../../../../components/search/SearchInput";

const CompanyPayrollRuns = () => {
	const { currentCompany } = useCompanyStore();
	const tableAPIRef = useGridApiRef();
	const [tableData, setTableData] = useState<any[]>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const { formDataValues, setFormData } = useEditFormStore();
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};
	
	const handleDeleteMethod = () => {
		setAnchorEl(null);
		setOpenDeleteModal(false);
		setTableData((data: any[]) => {
			return data.filter((item) => item.id !== formDataValues?.id);
		});
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = CompanyPayrollRunColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<Search 
						fallbackData={[] as any}
						setTableData={setTableData}
					/>
					<TableFilter
						fields={[]}
					/>
				</div>
                <OrangeButton
                    title="Submit Payroll Run"
                    className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                    onClick={handleOpenModal}
                />
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={false}
				pageCount={1}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				spanContent=""
			/>

			{openModal && (
				<RunPayrollModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Payroll Run" : "Create Payroll Run"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					payPeriods={payPeriods?.data || []}
					employees={employees?.data || []}
					employeeBands={employeeBands?.data || []}
				/>
			)}
		</>
	);
};

export default CompanyPayrollRuns;
