import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreditOrganizationTransactionDto,
    OrganizationTransaction,
    OrganizationTransactionPaginator,
    QueryOrganizationTransactionDto, 
    ReverseOrganizationTransactionDto,
    SearchOrganizationTransactionDto,
    UpdateOrganizationTransactionDto
} from '../models/organization-transaction.dto';
import ENDPOINTS from './utils/endpoints';
import { UpdateOrganizationDto } from '../models/organization.dto';

export function useGetOrgTransaction(options?: QueryOrganizationTransactionDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.GET;
    const request = (options: QueryOrganizationTransactionDto) => API.BillingAndSubscriptionAPI.getOrgTransaction(options);

    const { data, isLoading, error, refetch } = useQuery<OrganizationTransactionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetOrgTransactionSearch() {

    return useMutation(
        (options?: SearchOrganizationTransactionDto) => API.BillingAndSubscriptionAPI.getOrgTransactionSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetOrgTransactionById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.DETAIL;
    const request = API.BillingAndSubscriptionAPI.getOrgTransactionById(id);

    const { data, isLoading, error } = useQuery<OrganizationTransaction, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreditOrgTransaction(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreditOrganizationTransactionDto }) => API.BillingAndSubscriptionAPI.creditOrgTransaction(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.GET);
            },
        },
    );
}

export function useReverseOrgTransaction(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: ReverseOrganizationTransactionDto }) => API.BillingAndSubscriptionAPI.reverseOrgTransaction(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.GET);
            },
        },
    );
}

export function useUpdateOrgTransaction() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateOrganizationTransactionDto, id: number }) => API.BillingAndSubscriptionAPI.updateOrgTransaction(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.GET);
            },
        },
    );
}