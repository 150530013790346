import { useContext, useState } from "react";
import { EmployeeDataContext } from "..";
import NewRelative from "../../../shared/employees/EditEmployee/forms/relatives/NewRelative";
import useUserStore from "../../../../state-management/useUserStore";
import { AiOutlinePlus } from "react-icons/ai";
import { EmployeeRelativeDto, EmployeeRelativeOrderBy } from "../../../../models/employee-relative.dto";
import { useGetEmployeeRelative } from "../../../../services/employee-relative.service";
import EditRelative from "../../../shared/employees/EditEmployee/forms/relatives/EditRelative";
import { SkewLoader } from "react-spinners";

const EmployeeRelativesView = () => {
	const employeeData = useContext(EmployeeDataContext)?.employeeData;
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [showNewRelativeForm, setShowNewRelativeForm] = useState(false);
	
	const { data: employeeRelatives, isLoading } = useGetEmployeeRelative({ 
		employeeId: employeeData?.id as number,
		orderBy: EmployeeRelativeOrderBy.CREATED_AT_DESC,
	});
	
	return (
		<div className="mb-4 lg:w-[80%]">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-2xs lg:text-sm">
				<h2 className="font-semibold">Available Relatives</h2>
				{!showNewRelativeForm && (
					<button
						onClick={() => setShowNewRelativeForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-1 lg:mr-2 text-2sm lg:text-lg" />
						Add New Relative
					</button>
				)}
			</div>
				
			{showNewRelativeForm && (
				<NewRelative 
					employeeId={employeeData?.id as number}
					companyId={companyProfile.companyId}
					setShowNewRelativeForm={setShowNewRelativeForm} 
				/>
			)}
			
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{(employeeRelatives as EmployeeRelativeDto[]).map((relative) => (
				<EditRelative 
					key={relative.id}
					employeeRelative={relative} 
				/>
			))}
			
			{(!isLoading && employeeRelatives.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no relatives added yet. Click 'Add New Relative' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default EmployeeRelativesView