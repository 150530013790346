import {
	Avatar,
	Box,
	Modal,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	tableCellClasses,
} from "@mui/material";
import { useState } from "react";
import { BsArrowRight, BsBoxArrowUpRight } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import TransactionHistory from "./TransactionHistory";
import { enumToStringConverter, ModalStyle, moneyFormat } from "../../../../helpers/appHelpers";
import { useGetUserCount } from "../../../../services/user.service";
import { useGetSubscriptionTierById } from "../../../../services/subscription-tier.service";
import { useGetCompaniesByOrganizationId } from "../../../../services/organization.service";
import { useGetEmployeeCount } from "../../../../services/employee.service";
import { getOrganizationStatusColor } from "../data";
import { OrganizationWithOptionalRelations } from "../../../../models/organization.dto";
import ViewCompanyDetails from "./ViewCompanyDetails";

const style = {
	position: "absolute" as "absolute",
	top: "0%",
	right: "0%",
	transform: "translate(-0%, -0%)",
	width: 800,
	height: "100vh",
	bgcolor: "background.paper",
	boxShadow: 24,
	border: "none",
	borderRadius: "8px",
	outline: "none",
	overflow: "auto",
	maxHeight: "100vh",
	display: "flex",
	flexDirection: "column",
};

type ViewInfoModalProps = {
	organization: OrganizationWithOptionalRelations;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewInfoModal = ({ organization, open, setOpen}: ViewInfoModalProps) => {
	const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
	const [openCompanyDetails, setOpenCompanyDetails] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState<any>(null);
	const [openDeleteAccountConfirmation, setOpenDeleteAccountConfirmation] = useState(false);

	const { 
		data: totalUsers,
		isLoading: loadingTotalUser,
	} = useGetUserCount({
		organizationId: organization?.id,
		category: "OPERATIONS",
	});
	const { 
		data: subscriptionTier,
		isLoading: loadingSubscriptionTier,
	} = useGetSubscriptionTierById(organization.subscriptionTierId);
	const { 
		data: companies, 
		isLoading: loadingCompanies,
	} = useGetCompaniesByOrganizationId(organization?.id);

	return (
		<div>
			<Modal
				open={open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div className="flex justify-between items-center px-9 mt-9 mb-8">
						<h1 className="font-bold text-lg">{organization?.name}</h1>
						<button onClick={() => setOpen(false)}>
							<IoMdClose className="text-xl hover:scale-[1.1]" />
						</button>
					</div>
					<div className="grow overflow-auto px-9 mb-9 scroll-smooth">
						<Avatar
							src={organization?.logoUrl}
							variant="rounded"
							sx={{ width: 182, height: 182 }}
						/>
						<div className="my-8">
							<h6 className="text-2sm font-semibold mb-3">Account Details</h6>
							<Table
								sx={{
									[`& .${tableCellClasses.root}`]: {
										borderBottom: "none",
									},
									"& .MuiTableCell-root": {
										padding: "10px 0px",
										borderBottom: "none",
									},
								}}
							>
								<TableHead>
									<TableRow>
										<TableCell
											width={120}
											sx={{
												fontSize: "13px",
												color: "#4F4F4F",
												fontWeight: 300,
											}}
										>
											Current Status
										</TableCell>
										<TableCell
											width={200}
											sx={{
												fontSize: "13px",
												color: "#4F4F4F",
												fontWeight: 300,
											}}
										>
											Date Created
										</TableCell>
										<TableCell
											width={200}
											sx={{
												fontSize: "13px",
												color: "#4F4F4F",
												fontWeight: 300,
											}}
										>
											No. of Users
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											<div 
												className="rounded-lg px-3 py-2 w-fit min-w-[80px] text-center relative font-semibold overflow-hidden"
												style={{ color: getOrganizationStatusColor(organization?.status) }}
											>
												<div 
													className="absolute inset-0" 
													style={{ 
														background: getOrganizationStatusColor(organization?.status), 
														opacity: "15%"
													}}
												/>
												<p> 
													{organization?.status === "SUSPENDED_PENDING_PAYMENT" 
														? "Expired Subscription"
														: enumToStringConverter(organization?.status)
													}
												</p>
											</div>
										</TableCell>
										<TableCell>
											<p className="text-2sm font-light">
												{formattedDateTime(organization?.createdAt)}
											</p>
										</TableCell>
										<TableCell>
											{loadingTotalUser 
												? <Skeleton variant="text" sx={{ fontSize: "18px", width: 30 }} />
												: <p className="text-2sm font-light">{totalUsers?.data.count || "-"}</p>
											}
										</TableCell>
									</TableRow>
									{/* <TableRow>
										<span className="flex items-center space-x-3 mt-4">
											<p className="text-2xs font-light text-charcoal mr-2">
												Enable Account
											</p>
											<span>
												<FormGroup>
													<FormControlLabel
														control={<IOSSwitch sx={{ m: 0 }} defaultChecked />}
														label=""
													/>
												</FormGroup>
											</span>
										</span>
									</TableRow> */}
								</TableBody>
							</Table>
							<section className="my-12">
								<h6 className="text-2sm font-semibold">Companies</h6>
								{loadingCompanies ? (
									<div className="mt-5 flex font-light text-sm items-center justify-between w-full">
										<div className="flex items-center space-x-3 min-w-0">
											<Skeleton variant="circular" width={22} height={22} />
											<Skeleton variant="text" sx={{ fontSize: "20px", width: 100 }} />
										</div>
										<Skeleton variant="text" sx={{ fontSize: "20px", width: 150 }} />
										<Skeleton variant="text" sx={{ fontSize: "20px", width: 110 }} />
										<Skeleton variant="text" sx={{ fontSize: "20px", width: 110 }} />
									</div>
								):(<>
									{companies?.data?.map((company) => (
										<div 
											key={company.id} 
											className="w-full mt-5 flex font-light text-sm items-center justify-between "
										>
											<div className="flex items-center space-x-3 min-w-0">
												<Avatar
													src={company.logoUrl || ""}
													alt="company logo"
													sx={{ height: 20, width: 20 }}
												/>
												<p className="overflow-hidden truncate w-[100px]">
													{company.name}
												</p>
											</div>
											<div className="min-w-0">
												<p className="overflow-hidden truncate w-[150px]">
													{formattedDateTime(company.createdAt)}
												</p>
											</div>
											<div>
												<p className="overflow-hidden truncate w-[100px] flex items-center gap-2">
													<EmployeesCount companyId={company.id} /> 
													<span> Employee(s)</span>
												</p>
											</div>
											<div 
												onClick={() => {
													setSelectedCompany(company);
													setOpenCompanyDetails(true);
												}}
												className="flex items-center space-x-3 text-primary-blue cursor-pointer"
											>
												<p>View Details</p>
												<BsBoxArrowUpRight />
											</div>
										</div>
									))}
								</>)}

								{!loadingCompanies && !companies?.data?.length && (
									<p className="text-2sm text-secondary-darkgray font-light">No companies</p>
								)}
							</section>
							{organization.billingType?.name !== "Contract" && (
								<section className="my-12">
									<div className="flex items-center justify-between">
										<h6 className="text-2sm font-semibold mb-2">
											Billing and Subscription
										</h6>
										{(subscriptionTier && Number(subscriptionTier?.data?.priceAmount) !== 0) ? (
											<BsArrowRight
												className="cursor-pointer"
												onClick={() => setOpenTransactionHistory(true)}
											/>
										): null}
									</div>
									<div className="space-y-1.5">
										<h3 className="text-2xs text-secondary-darkgray font-light">
											Current Package
										</h3>
										{loadingSubscriptionTier ? (<>
											<Skeleton variant="rounded" width={280} height={15} />
											<Skeleton variant="rounded" width={210} height={25} />
										</>):(<>
											<p className="text-2sm text-charcoal">
												{subscriptionTier?.data.currency?.code + " "
													+ moneyFormat(subscriptionTier?.data.priceAmount || "") + "/"
													+ enumToStringConverter(subscriptionTier?.data.billingPeriod || "")
												}, 145 days remaining
											</p>
											<div className="w-fit bg-[#6978F0] font-semibold text-white whitespace-nowrap 
												text-xs px-2 py-[6px] rounded flex items-center justify-center"
											>
												<p>{subscriptionTier?.data.name}</p>
											</div>
										</>)} 
									</div>
								</section>
							)}
							<section>
								<h6 className="text-2sm font-semibold mb-2">Danger zone</h6>
								<div
									onClick={() => {
										setOpenDeleteAccountConfirmation(true);
										setOpen(false);
									}}
									className="inline-flex items-center bg-red-100 text-red-400 py-4 px-5 text-xs rounded-lg space-x-2 hover:text-red-500 hover:bg-red-200 cursor-pointer mt-2"
								>
									<RiDeleteBin5Line className="text-sm" />
									<p>Delete Account</p>
								</div>
							</section>
						</div>
					</div>
				</Box>
			</Modal>

			{openTransactionHistory && (
				<TransactionHistory
					organizationId={organization?.id}
					subscriptionPackage={subscriptionTier?.data}
					openTransactionHistory={openTransactionHistory}
					setOpenTransactionHistory={setOpenTransactionHistory}
				/>
			)}
			{openCompanyDetails && (
				<ViewCompanyDetails
					open={openCompanyDetails}
					setOpen={setOpenCompanyDetails}
					company={selectedCompany}
				/>
			)}

			<Modal
				open={openDeleteAccountConfirmation}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={ModalStyle} width={520}>
					<h6 className="text-center text-base font-semibold mb-2">
						Delete Account
					</h6>
					<p className="text-center">
						Are you sure you want to delete this account? This action is
						irreversible
					</p>
					<div className="flex w-full justify-center space-x-4 items-center mt-10">
						<button
							onClick={() => {
								setOpenDeleteAccountConfirmation(false);
								setOpen(true);
							}}
							className="px-16 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
						>
							Cancel
						</button>
						<button
							className="px-12 py-4 border text-white hover:opacity-90 bg-secondary-red rounded-lg text-2xs"
						>
							Yes, Delete
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default ViewInfoModal;

const EmployeesCount = ({ companyId }) => {
	const { 
		data: employeesCount,
		isLoading
	} = useGetEmployeeCount({ companyId });

	return isLoading 
		? <Skeleton variant="text" sx={{ fontSize: "20px", width: 20 }} />
		: <span>{employeesCount?.data.count || "-"}</span>
}
		
const formattedDateTime = (unformattedDateTime) => {
	const dateTime = new Date(unformattedDateTime);
	const formattedDate = dateTime.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	});
	const formattedTime = dateTime.toLocaleTimeString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
	});
	return `${formattedDate}, ${formattedTime}`;
};
