import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDate } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Tax Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.row.country ?
						<p>
							{params.row.country.name}
						</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	{
		field: "enabled",
		headerName: "Enabled",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>{params.value === true ? "Yes" : "No"}</p>
				</div>
			);
		},
	},
	{
		field: "isDefault",
		headerName: "Default",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>{params.value === true ? "Yes" : "No"}</p>
				</div>
			);
		},
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header grey-background",
		valueGetter: (params: GridValueGetterParams) => {
			const onBoardingDate = formatDate(params);
			return onBoardingDate;
		},
	},
	{
		field: "endDate",
		headerName: "End Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header grey-background",
		valueGetter: (params: GridValueGetterParams) => {
			const onBoardingDate = formatDate(params);
			return onBoardingDate;
		},
	},
	{
		field: "effectiveDate",
		headerName: "Effective Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header grey-background",
		valueGetter: (params: GridValueGetterParams) => {
			const onBoardingDate = formatDate(params);
			return onBoardingDate;
		},
	},
	{
		field: "expiryDate",
		headerName: "Expiry Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header grey-background",
		valueGetter: (params: GridValueGetterParams) => {
			const onBoardingDate = formatDate(params);
			return onBoardingDate;
		},
	},
	
	...createdAtmodifiedAtActions
];
