import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.row.country ?
						<p>
							{params.row.country.name}
						</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
