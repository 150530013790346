import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../components/modals/DeleteModal";
import ActionPopover from "../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../components/table/CustomStyledTable";
import TableFilter from "../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import { GradeLevelOrderBy } from "../../../models/grade-level.dto";
import { useGetGradeLevel } from "../../../services/grade-level.service";
import useCompanyStore from "../../../state-management/useCompanyStore";
import useEditFormStore from "../../../state-management/useEditFormStore";
import AnnouncementsModalForm from "./modal/AnnouncementsModalForm";
import { AnnouncementsColumns } from "./data";
import { AiOutlinePlus } from "react-icons/ai";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../helpers/useManageTable";
import { AnnouncementDto } from "../../../models/announcement.dto";
import { 
    useGetAnnouncementBySearch, 
    useGetAnnouncement, 
    useDeleteAnnouncement 
} from "../../../services/announcement.service";
import APISearchInput from "../../../components/search/APISearchInput";

const Announcements = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	const { mutate: searchAnnouncements } = useGetAnnouncementBySearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<AnnouncementDto>({
		fetchHook: useGetAnnouncement,
		options: { companyId: currentCompany?.id }
	});
    
    // Fetched data for select fields requiring data from database
    const { data: gradeLevels } = useGetGradeLevel({ 
        companyId: currentCompany?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
        orderBy: GradeLevelOrderBy.NAME_ASC,
    });

    const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteAnnouncement } = useDeleteAnnouncement();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteAnnouncement(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

    const additionalColumns = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            headerClassName: "super-app-theme--header",
            cellClassName: "actions ",
            getActions: (params: any) => {
                return [
                    <GridActionsCellItem
                        icon={<BiDotsHorizontalRounded />}
                        label="actions"
                        onClick={(e: any) => {
                            setFormData(params?.row);
                            setAnchorEl(e.currentTarget);
                        }}
                    />,
                ];
            },
        },
    ];

    const columnsWithAdditions = AnnouncementsColumns.map(
        (obj) => additionalColumns.find((o) => o.field === obj.field) || obj
    );

    return (
        <HumanResourceLayout>
            <Helmet>
                <title>Announcements | HR Flow</title>
            </Helmet>
            <h1 className="font-bold text-xl">Announcements</h1>
            <>
                <div className="flex justify-between items-center my-6">
                    <div className="flex items-center gap-3">
                        <APISearchInput 
                            fallbackData={fetchedTableData}
                            setTableData={setTableData}
                            mutate={searchAnnouncements}
                        />
                        <TableFilter
                            fields={[
                                {
                                    title: "",
                                    fieldName: "",
                                    fieldType: "SORT",
                                    options: [
                                        {
                                            title: "Title",
                                            fieldName: "title",
                                        },
                                        {
                                            title: "Publish Date",
                                            fieldName: "publishDate",
                                        },
                                        {
                                            title: "Date Created",
                                            fieldName: "createdAt",
                                        },
                                        {
                                            title: "Date Modified",
                                            fieldName: "modifiedAt",
                                        },
                                    ]
                                },
                                {
                                    title: "Target Grade Level",
                                    fieldName: "targetGradeLevelId",
                                    fieldType: "SELECT_FIELD",
                                    options: gradeLevels?.data || [],
                                },
                                {
                                    title: "Publish Date",
                                    fieldName: "publishDate",
                                    fieldType: "DATE_RANGE",
                                },
                                {
                                    title: "Active",
                                    fieldName: "active",
                                    fieldType: "YES_NO",
                                },
                                {
                                    title: "Public",
                                    fieldName: "public",
                                    fieldType: "YES_NO",
                                },
                            ]}
                        />
                    </div>
                    <div
                        className="flex items-center text-primary-blue cursor-pointer"
                        onClick={handleOpenModal}
                    >
                        <AiOutlinePlus className="mr-1 text-base" />
                        <span className="text-sm">Add New Announcement</span>
                    </div>
                </div>
                <CustomStyledTable
                    rows={tableData}
                    columns={columnsWithAdditions}
                    apiRef={tableAPIRef}
                    loading={isLoading}
                    pageCount={pageCount}
                    setAnchorEl={setAnchorEl}
                />
                <ActionPopover
                    id={anchorEl ? "simple-popover" : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenEdit={setIsEditForm}
                    setOpenModal={setOpenModal}
                />
                <DeleteModal
                    openDeleteModal={openDeleteModal}
                    spanContent={formDataValues?.title || ""}
                    setOpenDeleteModal={setOpenDeleteModal}
                    handleDeleteMethod={handleDeleteMethod}
                    deleting={deleting}
                />

                {openModal && (
                    <AnnouncementsModalForm
                        open={openModal}
                        setOpen={setOpenModal}
                        title={
                            isEditForm ? "Update Announcement" : "Create Announcement"
                        }
                        isEditForm={isEditForm}
                        tableAPIRef={tableAPIRef}
                        gradeLevels={gradeLevels?.data || []}
                    />
                )}
            </>
        </HumanResourceLayout>
    );
};

export default Announcements;