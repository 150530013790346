import { List, ListItem, Popover, Typography } from "@mui/material";
import { useOrganizationTreeStore, TreeNode } from "../../../../state-management/useOrganizationTreeStore";
import { useUpdateCompanyTree } from "../../../../services/company-tree-node.service";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import useUserStore from "../../../../state-management/useUserStore";
import API from '../../../../services/utils/api';

type UpdateParentNodeModalProps = {
    anchorEl: any;
    node: TreeNode;
    validParentNodes: TreeNode[];
    handleClose: (closeMenu?: any) => void;
}

const UpdateParentNodeModal = ({
    anchorEl,
    node,
    validParentNodes,
    handleClose,
}: UpdateParentNodeModalProps) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
    const { generateTree } = useOrganizationTreeStore();
    const { mutate: updateTreeNode } = useUpdateCompanyTree();
	const [validParentNodesState, setValidParentNodesState] = 
        useState(validParentNodes);
	const [parentId, setParentId] = useState<number>();
	const [inputValue, setInputValue] = useState("");
    const [uploading, setUploading] = useState(false);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);

	const updateInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		setInputValue(input.value)
	};

	const searchItems = () => {
		const lowercasedInputValue = inputValue.trim().toLowerCase();

		if (lowercasedInputValue === "") {
            setValidParentNodesState(validParentNodes);
            return
        }

		const filteredNodes = validParentNodes.filter(node => {
            const employee = node.data.employee;
            const fullName = employee?.firstName + " " + employee?.lastName;
            return fullName.toLowerCase().includes(lowercasedInputValue.toLowerCase())
        });
		
        setValidParentNodesState(filteredNodes);
	};

	const handleUpdateTreeNode = () => {
        if (!parentId) return;
        
        setUploading(true);

        updateTreeNode(
            { 
                companyId: companyProfile.companyId,
                nodeId: node.id,
                payload: { parentId }
            },
            {
                onSuccess: async () => {
                    const { data } = await API.HrAPI.getCompanyTree(companyProfile.companyId);
                    generateTree(data);
                    handleClose("true");
                    setUploading(false);
                    setUpdateSuccessful(true);
                },
                onError: () => setUploading(false)
            }
        )
    };
    
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    maxHeight: "350px",
                    width: "330px",
                    borderRadius: 8,
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            <h4 className="text-sm font-medium">Select New Supervisor</h4>
            <div className="w-full relative bg-white mt-2">
                <FiSearch className="absolute top-1/2 -translate-y-1/2 left-5 text-gray-500 text-lg" />
                <input
                    type="text"
                    className="w-full bg-secondary-gray rounded-lg py-3 pl-12 pr-4 bg text-2xs"
                    placeholder="Search employees"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        searchItems();
                    }}
                    onKeyUp={(e) => {
                        updateInputValue(e);
                        searchItems();
                    }}
                />
            </div>
            <div className="w-full flex flex-col text-sm my-2 overflow-y-auto">
                <List className="w-full">
                    {validParentNodesState.map((node) => (
                        <ListItem
                            key={node.id}
                            className={`cursor-pointer rounded-lg text-sm font-inter text-secondary-darkgray 
                                ${node.id === parentId ? "bg-[#E1E1E1]" : "hover:bg-[#F4F4F4]"} 
                            `}
                            onClick={() => setParentId(node.id)}
                            disabled={uploading}
                        >
                            <Typography>
                                {node.data.employee?.firstName + " " + node.data.employee?.lastName}
                            </Typography>
                        </ListItem>
                    ))}

                    {!validParentNodesState.length && <p className="text-center pt-5">No employee found</p>}
                </List>
            </div>
            <button 
                onClick={handleUpdateTreeNode}
                disabled={uploading || Boolean(!parentId)}
                className={`w-full bg-primary-mango py-3 rounded-lg text-white text-2xs
                    capitalize node-button ${!uploading && "hover:shadow-sm"}
                `}
            >
                {uploading ? "Updating..." : "Update Node"}
            </button>
        </Popover>
    );
}
 
export default UpdateParentNodeModal;