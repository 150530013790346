import React from "react";
import { Box } from "@mui/material";
import { IoMdWallet } from "react-icons/io";
import { HiNewspaper } from "react-icons/hi";
import { AiOutlineClockCircle, AiOutlineLineChart } from "react-icons/ai";
import {
	BsArrowRightShort,
	BsCaretDownFill,
	BsFillBarChartLineFill,
} from "react-icons/bs";
import PayrollCompanyLayout from "../../components/layouts/PayrollCompanyLayout";
import BarChart from "../../components/charts/Bar";
import { Helmet } from "react-helmet-async";
import LineChart from "../../components/charts/Line";
import { useNavigate } from "react-router-dom";
import { StyledDataGrid } from "../../helpers/appHelpers";
import { columns as employeeTableColumn } from "../shared/employees/main/data";
import { useGetEmployee, useGetEmployeeCount } from "../../services/employee.service";
import SkewLoader from "react-spinners/SkewLoader";
import useUserStore from "../../state-management/useUserStore";
import useCompanyStore from "../../state-management/useCompanyStore";
import { EmployeeOrderBy } from "../../models/employee.dto";
import { PATHS } from "../../routes/routes.paths";

const Overview = () => {
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const pathname = window.location.pathname;
	const BoxHeight = pathname === "/dashboard/overview" ? 528 : 787;
	const [chartView, setChartView] = React.useState("bar");
	const [employeeTableData, setEmployeeTableData] = React.useState<any[]>([]);

	const { data: allEmployeesCount } = useGetEmployeeCount({
		companyId: currentCompany?.id
	});
	const { data: allTerminatedEmployeesCount } = useGetEmployeeCount({
		companyId: currentCompany?.id,
		status: "TERMINATED"
	});
	const { data: allActiveEmployeesCount } = useGetEmployeeCount({
		companyId: currentCompany?.id,
		status: "ACTIVE"
	});

	const { data, isLoading } = useGetEmployee({
		companyId: currentCompany?.id,
		limit: 7,
		orderBy: EmployeeOrderBy.CREATED_AT_DESC,
	});

	React.useEffect(() => {
		if (!isLoading && data?.data) {
			setEmployeeTableData(data?.data);
		}
		// eslint-disable-next-line
	}, [isLoading]);

	return (
		<PayrollCompanyLayout>
			<Helmet>
				<title>Overview | Company Flow</title>
			</Helmet>
			{isLoading && (
				<div className="flex justify-center items-center h-full">
					<SkewLoader color="#FFB600" />
				</div>
			)}
			<div className="h-full flex flex-col children-scroll-bar">
				{(employeeTableData.length === 0 && !isLoading) && (
					<>
						<h1 className="font-bold text-xl mb-8">Overview</h1>
						<div className="items-center justify-center flex grow bg-white rounded-lg border italic">
							{/* Payroll Summary and Charts are under construction... 🚧 */}
							<p className="text-center text-base">
								Nothing to see here yet. Go to{" "}
								<span
									className="hover:cursor-pointer underline text-primary-mango"
									onClick={() => navigate(PATHS.EMPLOYEES)}
								>
									Employees
								</span>{" "}
								page to add some data.
							</p>
						</div>
					</>
				)}
				{(employeeTableData.length && !isLoading) ? (
					<>
						<div className="flex justify-between items-center">
							<h1 className="font-bold text-xl">Overview</h1>
							{/* <FormControl>
								<Select
									sx={{ fontSize: "12px", borderRadius: "10px", height: "40px" }}
									value={state}
									onChange={handleChange}
								>
									<MenuItem value={10}>This Month</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</Select>
							</FormControl> */}
						</div>
						<div className="flex justify-between items-center mt-4">
							<div className="flex items-center space-x-3 border h-[90px] w-[295px] px-3 rounded-lg">
								<div className="bg-primary-mango text-white px-2.5 py-[11px] rounded-lg">
									<IoMdWallet className="text-base" />
								</div>
								<div>
									<p className="text-[11px] text-secondary-darkgray">
										Number of Employees
									</p>
									<h3 className="font-medium text-lg">
										{allEmployeesCount?.data.count || "--"}
									</h3>
								</div>
							</div>
							<div className="flex items-center space-x-3 border h-[90px] w-[295px] px-3 rounded-lg">
								<div className="bg-primary-violet text-white px-2.5 py-[11px] rounded-lg">
									<HiNewspaper className="text-base" />
								</div>
								<div>
									<p className="text-[11px] text-secondary-darkgray">
										Terminated employees in this period
									</p>
									<h3 className="font-medium text-lg">
										{allTerminatedEmployeesCount?.data.count || "--"}
									</h3>
								</div>
							</div>
							<div className="flex items-center space-x-3 border h-[90px] w-[295px] px-3 rounded-lg">
								<div className="bg-secondary-darkgreen text-white px-2.5 py-[11px] rounded-lg">
									<AiOutlineClockCircle className="text-base" />
								</div>
								<div>
									<p className="text-[11px] text-secondary-darkgray">
										Active employees in this period
									</p>
									<h3 className="font-medium text-lg">
										{allActiveEmployeesCount?.data.count || "--"}
									</h3>
								</div>
							</div>
						</div>
						<div className="mt-8 text-sm shadow-sm bg-white border px-5 py-7 rounded-lg w-full">
							<div className="flex items-center justify-between">
								<h3 className="font-bold">Employee Summary</h3>
								<div className="flex items-center space-x-4">
									<div className="bg-gray-100 hover:opacity-80 hover:bg-slate-300 border p-1 rounded-lg">
										{chartView === "bar" && (
											<AiOutlineLineChart
												onClick={() => setChartView("line")}
												className="text-2xl cursor-pointer"
											/>
										)}
										{chartView === "line" && (
											<BsFillBarChartLineFill
												onClick={() => setChartView("bar")}
												className="text-2xl cursor-pointer"
											/>
										)}
									</div>
									<div className="flex space-x-2 items-center">
										<p>This Year</p>
										<BsCaretDownFill className="text-[10px]" />
									</div>
								</div>
							</div>
							{chartView === "bar" && <BarChart dataSet={employeeTableData} />}
							{chartView === "line" && (
								<LineChart dataSet={employeeTableData} />
							)}
						</div>
						<div className="mt-8 text-sm">
							<div className="flex justify-between bg-white py-4 px-2">
								<h1 className="text-lg font-bold">Last 7 employees added</h1>
								<div
									className="flex items-center font-semibold hover:cursor-pointer"
									onClick={() => navigate(PATHS.EMPLOYEES)}
								>
									<span>See more</span>
									<BsArrowRightShort />
								</div>
							</div>
							{employeeTableData && (
								<Box
									sx={{
										backgroundColor: "background.paper",
										borderRadius: 4,
										height: BoxHeight,
										width: "100%",
										"& .actions": {
											color: "text.secondary",
										},
										"& .textPrimary": {
											color: "text.primary",
										},
										"& .super-app-theme--header": {
											backgroundColor: "#F2F2F4",
											fontWeight: "bold",
										},
										"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
											width: "11px",
										},
										"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
											background: "#f7fafc",
										},
										"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
											backgroundColor: "#e2e8f0",
											borderRadius: "6px",
											border: "3px solid #f7fafc",
										},
										"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
											{
												backgroundColor: "#e2e8f0",
												borderRadius: "7px",
												border: "3px solid #f7fafc",
											},
									}}
									marginBottom="40px"
								>
									<StyledDataGrid
										hideFooterPagination
										hideFooter
										rows={employeeTableData}
										rowHeight={65}
										columnHeaderHeight={70}
										loading={
											isLoading ||
											(pathname === "/dashboard/overview" &&
												!employeeTableData.length)
										}
										columns={employeeTableColumn}
										initialState={{
											pagination: {
												paginationModel: {
													pageSize: 10,
												},
											},
											columns: {
												columnVisibilityModel: {
													id: false,
													actions: false,
													status: false,
													address: false,
													digitalAddress: false,
													taxIdentificationNumber: false,
													employeeNumber: false,
													dateOfBirth: false,
													updatedAt: false,
													deletedAt: false,
													resident: false,
													modifiedAt: false,
													unionMember: false,
													reemployed: false,
													ssn: false,
												},
											},
										}}
										pageSizeOptions={[7]}
										getRowClassName={(params) =>
											params.indexRelativeToCurrentPage % 2 === 0
												? "even-row"
												: "odd-row"
										}
									/>
								</Box>
							)}
						</div>
					</>
				): null}
			</div>
		</PayrollCompanyLayout>
	);
};

export default Overview;
