import { useEffect, useState } from "react";
import CompanySetupLayout from "../../../components/layouts/CompanySetupLayout";
import { moneyFormat } from "../../../helpers/appHelpers";
import { IoChevronBackOutline } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import Label from "../../../components/form_fields/Label";
import OrangeButton from "../../../components/buttons/OrangeButton";
import { DetailedSubscriptionTierDto, SubscriptionTierOrderBy } from "../../../models/subscription-tier.dto";
import { useGetSubscriptionTierPackages } from "../../../services/subscription-tier.service";
import { PATHS } from "../../../routes/routes.paths";

const SubscriptionPayment = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const subscriptionTierId = searchParams.get("subscriptionTierId");
	const [view, setView] = useState("Subscription forms");
	const [subscriptionTier, setSubscriptionTier] = 
		useState<DetailedSubscriptionTierDto | undefined>(undefined);
		
	const { 
		data: subscriptionTierPackages, 
		isLoading: isLoadingSubscriptions
	} = useGetSubscriptionTierPackages({
		detailed: true,
		page: 1,
		limit: 20,
		orderBy: SubscriptionTierOrderBy.PRICE_AMOUNT_ASC,
	});

	useEffect(() => {
		if (isLoadingSubscriptions || !subscriptionTierId) return;

		setSubscriptionTier(
			subscriptionTierPackages?.data.find(
				subscriptionTier => subscriptionTier.id === subscriptionTierId
			)
		);
	}, [subscriptionTierId, isLoadingSubscriptions])

	return (
		<CompanySetupLayout pathname={window.location.pathname}>
			<Helmet>
				<title>Subscription Payment | Akatua</title>
			</Helmet>
			{view === "Subscription forms" && (
				<div>
					{/* Back button */}
					<div
						onClick={() => {
							navigate(PATHS.CREATE_PAYROLL_COMPANY + "?displayPackages=true");
						}}
						className="flex justify-center w-full mb-12 cursor-pointer"
					>
						<div className="flex text-primary-blue w-[1160px] items-center text-2sm space-x-2">
							<IoChevronBackOutline className="text-sm" />
							<p>Go Back</p>
						</div>
					</div>
					<div className="flex justify-center items-start space-x-6">
						{/* Subscription form */}
						<form className="w-[560px] h-[614px] bg-[#26A0F91A] rounded-lg p-8">
							<h3 className="text-2xs">Subscribe to</h3>
							<h1 className="font-bold text-2xl mb-8">{subscriptionTier?.name}</h1>
							<div className="space-y-6">
								<div className="flex w-full justify-between items-center">
									<div className="space-y-1">
										<h3 className="font-bold text-base">Subscription</h3>
										<p className="text-2xs text-charcoal">Billed annually</p>
									</div>
									<p className="text-primary-blue text-lg font-bold">
										{Number(subscriptionTier?.priceAmount) === 0
											? "FREE"
											: getPackageBillingStats(subscriptionTier)
										}
									</p>
								</div>
								<div className="flex w-full justify-between items-center">
									<div className="space-y-1">
										<h3 className="font-bold text-base">Application fee</h3>
										<p className="text-2xs text-charcoal">Once only</p>
									</div>
									<div className="text-primary-blue text-lg font-bold">
										<p>$10</p>
									</div>
								</div>
								<div>
									<Label for={"promoCode"} title={"Apply Promotional Code"} />
									<input
										type="text"
										id="promoCode"
										placeholder="Code"
										className="w-full bg-white rounded-lg h-14 pl-4 pr-12 mt-2 bg text-sm"
									/>
								</div>
								<div className="border-secondary-darkgray h-[1px] border-b w-full"></div>
								<div className="flex w-full justify-between items-center">
									<h3 className="font-bold text-base">Total</h3>
									<div className="text-primary-blue text-lg font-bold">
										<p>${Number(subscriptionTier?.priceAmount) + 10}</p>
									</div>
								</div>
								<div className="flex items-center space-x-2 mt-">
									<input
										type="checkbox"
										style={{ transform: "scale(1.4)", margin: "8px" }}
										id="termsAndConditions"
									/>
									<label className="text-xs" htmlFor={"termsAndConditions"}>
										I agree to the{" "}
										<span className="text-primary-blue">
											Terms and Conditions
										</span>{" "}
										and{" "}
										<span className="text-primary-blue">
											Automatic Renewal Terms
										</span>{" "}
										above
									</label>
								</div>
							</div>
							<div className="flex justify-center mt-14">
								<OrangeButton
									onClick={() => setView("Subscription success")}
									title={"Subscribe"}
									className={"px-14"}
								/>
							</div>
						</form>
					</div>
				</div>
			)}
			{view === "Subscription success" && (
				<div className="mt-40 flex flex-col justify-center items-center text-sm">
					<div className="text-primary-blue bg-green-50 rounded-full flex justify-center items-center h-20 w-20 text-4xl">
						💫
					</div>
					<h1 className="text-2xl font-bold mt-10">
						Thank you for Subscribing!
					</h1>
					<p className="w-[458px] text-center mt-4">
						You have successfully subscribed to the{" "}
						<span className="font-medium">{subscriptionTier?.name} Package</span>. Start
						creating your companies
					</p>
					<div className="w-[15%] mt-8">
						<OrangeButton
							title={"Continue"}
							onClick={() => navigate(PATHS.COMPANY_ACCOUNT_SETUP)}
							className={"w-full"}
						/>
					</div>
				</div>
			)}
			{view === "Subscription error" && (
				<div className="mt-48 flex flex-col justify-center items-center text-sm">
					<div className="text-primary-blue bg-red-50 rounded-full flex justify-center items-center h-20 w-20 text-5xl">
						❗
					</div>
					<h1 className="text-2xl font-bold mt-10">Something went wrong!</h1>
					<p className="w-[400px] text-center mt-4">
						Sorry, you were unable to subscribe. Please contact support or Try
						again
					</p>
				</div>
			)}
		</CompanySetupLayout>
	);
};

export default SubscriptionPayment;

const getPackageBillingStats = (subscriptionTierPackage?: DetailedSubscriptionTierDto) => {
	const currencyCode = subscriptionTierPackage?.currency?.code || "$";
	const priceAmount = moneyFormat(subscriptionTierPackage?.priceAmount || "");

	switch(subscriptionTierPackage?.billingPeriod) {
		case "MONTHLY":
			return currencyCode + " " + priceAmount + "/mo"
		case "ANNUALLY":
			return currencyCode + " " + priceAmount + "/yr"
		default:
			return currencyCode + " " + priceAmount
	}
};
