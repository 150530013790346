import {PaginatorInfo} from "./generic.dto";
import {CostCenterDto} from "./cost-center.dto";

type AssignedAsset = {
    id: number
    employeeId: number
    companyId: number
    assetTypeId: number
    serialNumber: string
    dateAssigned: string | null
    dateReturned: string | null
    createdAt: string
    modifiedAt: string | null
}

export class CreateAssignedAssetDto {
    employeeId!: number
    companyId!: number
    assetTypeId!: number
    serialNumber!: string
    dateAssigned!: string | string
    dateReturned!: string | string
}


export interface AssignedAssetDto extends AssignedAsset { }

export interface AssignedAssetPaginator extends PaginatorInfo<AssignedAssetDto> {}

export class UpdateAssignedAssetDto {
    employeeId?: number
    companyId?: number
    assetTypeId?: number
    serialNumber?: string
    dateAssigned?: string | string
    dateReturned?: string | string
}

export class QueryAssignedAssetDto {
    employeeId?: number;
    companyId?: number;
    assetTypeId?: number;
    'dateAssigned.gte'?: Date;
    'dateAssigned.lte'?: Date;
    'dateReturned.gte'?: Date;
    'dateReturned.lte'?: Date;
    page?: number = 1;
    limit?: number;
    orderBy?: AssignedAssetOrderBy = AssignedAssetOrderBy.CREATED_AT_ASC;
}

export enum AssignedAssetOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    DATE_ASSIGNED_ASC = 'dateAssigned:asc',
    DATE_ASSIGNED_DESC = 'dateAssigned:desc',
    DATE_RETURNED_ASC = 'dateReturned:asc',
    DATE_RETURNED_DESC = 'dateReturned:desc',
}