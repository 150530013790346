import {PaginatorInfo} from "./generic.dto";

export const COMPANY_GROUPING_TYPE = {
    DIVISION: 'DIVISION',
    STATION: 'STATION',
    REGION: 'REGION',
    TRIBE: 'TRIBE'
};

export type COMPANY_GROUPING_TYPE = (typeof COMPANY_GROUPING_TYPE)[keyof typeof COMPANY_GROUPING_TYPE];

type CompanyGrouping = {
    id: number
    name: string
    companyId: number
    type: COMPANY_GROUPING_TYPE
    code: string
    active: boolean
    createdAt: string
    modifiedAt: string | null
}

export class CreateCompanyGroupingDto{
    companyId?: number;
    name!:string;
    type!: COMPANY_GROUPING_TYPE;
    code!: string;
    active!: boolean;
}

export class UpdateCompanyGroupingDto{
    name?: string;
    type?: COMPANY_GROUPING_TYPE;
    code?: string;
    active?: boolean;
}

export interface CompanyGroupingDto extends CompanyGrouping {}

export interface CompanyGroupingPaginator extends PaginatorInfo<CompanyGroupingDto> {}

export class QueryCompanyGroupingDto {
    active?: boolean;
    companyId?: number;
    code?: string;
    type?: COMPANY_GROUPING_TYPE;
    page?: number = 1;
    limit?: number;
    orderBy?: CompanyGroupingOrderBy = CompanyGroupingOrderBy.NAME_ASC;
  }
  
  export enum CompanyGroupingOrderBy {
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
  }