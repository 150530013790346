import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
import { CompanyTreeNodeDto } from "../models/company-tree-node.dto";
import { randomHexColors } from "../helpers/appHelpers";

type LevelColor = { 
    parendId: number | null; 
    levelColor: string 
}

export type TreeNode = {
    id: number;
    parentId: number | null;
    expanded: boolean;
    data: CompanyTreeNodeDto;
    children?: TreeNode[];
    "1"?: string;
}

type useOrganizationTreeStore = {
    organizationTree: TreeNode[];
    treeSourceData: TreeNode[];
    levelColors: LevelColor[];
    generateTree: (treeNodes: CompanyTreeNodeDto) => void;
    clearOrgTreeStore: () => void;
}

export const useOrganizationTreeStore = create(
    persist<useOrganizationTreeStore>(
        (set, get) => ({
            organizationTree: [],
            treeSourceData: [],
            levelColors: [],
            generateTree: (parentNode: CompanyTreeNodeDto) => {
                const allNodes = extractAllNodes(parentNode);
                const rootNode = generateTree(parentNode);
                const levelColors = getLevelColors(allNodes);

                set((state) => ({
                    ...state,
                    levelColors,
                    organizationTree: [rootNode],
                    treeSourceData: allNodes,
                }))
            },
            clearOrgTreeStore: () => {
                set((state) => ({
                    ...state,
                    organizationTree: [],
                    treeSourceData: [],
                    levelColors: [],
                }));
            }
        }),
        {
            name: 'organization-tree',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

const generateTree = (treeNode: CompanyTreeNodeDto) => {
    const { children: baseChildNodes, ...otherValues } = treeNode;

    let children: TreeNode[] = []

    if (baseChildNodes && baseChildNodes.length) {
        baseChildNodes.forEach(childNode => {
            const node = generateTree(childNode);
            children = children.concat([node]);
        });
    }

    let node: TreeNode = {
        id: treeNode.id,
        parentId: treeNode.parentId,
        expanded: true,
        data: otherValues,
        children
    }

    return node;
};

const extractAllNodes = (node: CompanyTreeNodeDto) => {
    const { children, ...otherValues } = node;

    let allNodes: TreeNode[] = [
        {
            id: node.id,
            parentId: node.parentId,
            expanded: true,
            data: otherValues,
            children: []
        }
    ];

    if (children && children.length) {
        children.forEach(childNode => {
            allNodes = allNodes.concat(extractAllNodes(childNode));
        });
    }

    return allNodes
};

const getLevelColors = (treeNodes: TreeNode[]) => {
    const nodeParentIds = new Set<number | null>();
    treeNodes.forEach((node) => nodeParentIds.add(node.parentId));

    const levelColors: LevelColor[] = Array.from(nodeParentIds).map((parendId, index) => {
        return {
            parendId, 
            levelColor: randomHexColors[index]
        }
    })

    return levelColors;
};
