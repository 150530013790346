import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useCreateCurrency, useUpdateCurrency } from "../../../../../../services/currency.service";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";

interface IFormInputs {
	code: string;
	name: string;
	symbol: string;
	active: string;
	isDefault: string;
}

const CurrenciesModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = React.useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			symbol: isEditForm ? formDataValues?.symbol : "",
			active: isEditForm ? (formDataValues?.active ? "yes" : "no") : "",
			isDefault: isEditForm ? (formDataValues?.isDefault ? "yes" : "no") : "",
		}
	});

	const { mutate: createCurrency } = useCreateCurrency();
	const { mutate: updateCurrency } = useUpdateCurrency();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);
		
        const active = data.active === "yes" ? true : false;
        const isDefault = data.isDefault === "yes" ? true : false;

		if (isEditForm) {
			updateCurrency(
				{ 
					payload: {
						...data,
						active,
						isDefault
					}, 
					id: formDataValues?.id 
				},
				{
					onSuccess: (updatedData: any) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createCurrency(
				{ payload: { ...data, active, isDefault } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency Code" for="code" />
						<input
							defaultValue={isEditForm ? formDataValues?.code : ""}
							placeholder={"Enter code"}
							type="text"
							{...register("code", {
								required: "Enter currency code here",
							})}
							id="code"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Symbol" for="symbol" />
						<input
							defaultValue={isEditForm ? formDataValues?.symbol : ""}
							placeholder={"Enter symbol"}
							type="text"
							{...register("symbol", {
								required: "Enter currency symbol here",
							})}
							id="symbol"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.symbol
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.symbol && (
							<p className="text-red-500 text-sm mt-1">
								{errors.symbol.message}
							</p>
						)}
					</div>
					<div className="col-span-2">
						<div className="flex space-x-9 font-light">
							<h3>Active</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === true}
									type="radio"
									value={"yes"}
									id="active"
								/>
								<label htmlFor="active">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === false}
									type="radio"
									value={"no"}
									id="inactive"
								/>
								<label htmlFor="inactive">No</label>
							</div>
						</div>
					</div>
					<div className="col-span-2">
						<div className="flex space-x-9 font-light">
							<h3>Default</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("isDefault")}
									defaultChecked={isEditForm && formDataValues?.isDefault === true}
									type="radio"
									value={"yes"}
									id="isDefault"
								/>
								<label htmlFor="isDefault">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("isDefault")}
									defaultChecked={isEditForm && formDataValues?.isDefault === false}
									type="radio"
									value={"no"}
									id="inisDefault"
								/>
								<label htmlFor="inisDefault">No</label>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Currency"
								: "Add New Currency"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default CurrenciesModalForm;
