import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { enumToStringConverter, IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { CountryDto } from "../../../../../../models/country.dto";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { BILLING_PERIOD, PLAN } from "../../../../../../models/subscription-tier.dto";
import { useCreateSubscriptionTier, useUpdateSubscriptionTier } from "../../../../../../services/subscription-tier.service";
import { CurrencyDto } from "../../../../../../models/currency.dto";
import { PromotionDto } from "../../../../../../models/promotion.dto";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";

interface IFormInputs {
    name: string;
    description: string;
    maxUsers: number;
    maxCompanies: number;
    maxEmployees: number;
    billingPeriod: BILLING_PERIOD;
    plan: PLAN;
    priceCurrencyId: number;
    priceAmount: number;
    promotionId?: number;
    countryId?: number;
    active?: boolean;
}

interface SubscriptionPackageModalFormProps extends IAddEditTableForm {
	countries: CountryDto[];
	promotions: PromotionDto[];
	currencies: CurrencyDto[];
}

const SubscriptionPackageModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries,
	promotions,
	currencies,
}: SubscriptionPackageModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			description: isEditForm ? formDataValues?.description : "",
			maxUsers: isEditForm ? formDataValues?.maxUsers : "",
			maxCompanies: isEditForm ? formDataValues?.maxCompanies : "",
			maxEmployees: isEditForm ? formDataValues?.maxEmployees : "",
			billingPeriod: isEditForm ? formDataValues?.billingPeriod : "",
			plan: isEditForm ? formDataValues?.plan : "",
			priceCurrencyId: isEditForm ? formDataValues?.priceCurrencyId : "",
			priceAmount: isEditForm ? formDataValues?.priceAmount : "",
			promotionId: isEditForm ? formDataValues?.promotionId : "",
			countryId: isEditForm ? formDataValues?.countryId : "",
			active: isEditForm ? formDataValues?.active : undefined,
		}
	});

	const { mutate: createSubscriptionPackage } = useCreateSubscriptionTier();
	const { mutate: updateSubscriptionPackage } = useUpdateSubscriptionTier();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateSubscriptionPackage(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createSubscriptionPackage(
				{ payload: data }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Users" for="maxUsers" />
						<input
							placeholder={"Enter max users"}
							type="text"
							{...register("maxUsers", {
								required: "Enter max users here",
								valueAsNumber: true,
							})}
							id="maxUsers"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.maxUsers
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.maxUsers && (
							<p className="text-red-500 text-sm mt-1">{errors.maxUsers.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Companies" for="maxCompanies" />
						<input
							placeholder={"Enter max companies"}
							type="text"
							{...register("maxCompanies", {
								required: "Enter max companies here",
								valueAsNumber: true,
							})}
							id="maxCompanies"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.maxCompanies
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.maxCompanies && (
							<p className="text-red-500 text-sm mt-1">{errors.maxCompanies.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Employees" for="maxEmployees" />
						<input
							placeholder={"Enter max employees"}
							type="text"
							{...register("maxEmployees", {
								required: "Enter max employees here",
								valueAsNumber: true,
							})}
							id="maxEmployees"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.maxEmployees
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.maxEmployees && (
							<p className="text-red-500 text-sm mt-1">{errors.maxEmployees.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Billing Period" for="billingPeriod" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.billingPeriod
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("billingPeriod", {
								required: "Select billing period here",
							})}
						>
							<option value="">
								Select Billing Period
							</option>
							{(Object.entries(BILLING_PERIOD)).map(([key, value]) => (
								<option key={key} value={value} >
									{enumToStringConverter(value)}
								</option>
							))}
						</select>
						{errors.billingPeriod && (
							<p className="text-red-500 text-sm mt-1">
								{errors.billingPeriod.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Plan" for="plan" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.plan
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("plan", {
								required: "Enter plan here",
							})}
						>
							<option value="">
								Select Plan
							</option>
							{(Object.entries(PLAN)).map(([key, value]) => (
								<option key={key} value={value} >
									{enumToStringConverter(value)}
								</option>
							))}
						</select>
						{errors.plan && (
							<p className="text-red-500 text-sm mt-1">
								{errors.plan.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency" for="priceCurrencyId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.priceCurrencyId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("priceCurrencyId", {
								required: "Enter currency here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Currency
							</option>
							{currencies.map((currency) => (
								<option key={currency.id} value={currency.id} >
									{currency.code}
								</option>
							))}
						</select>
						{errors.priceCurrencyId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.priceCurrencyId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Price" for="priceAmount" />
						<MoneyInput 
							attributes={{
								id: "priceAmount",
								placeholder: "0"
							}}
							register={register}
							name="priceAmount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.priceAmount : undefined}
							required="Enter price here"
							error={errors.priceAmount}
						/>
						{errors.priceAmount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.priceAmount.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Promotion" for="promotionId" optional />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.promotionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("promotionId", {
								required: false,
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Promotion
							</option>
							{promotions.map((promotion) => (
								<option key={promotion.id} value={promotion.id} >
									{promotion.title}
								</option>
							))}
						</select>
						{errors.promotionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.promotionId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Country" for="countryId" optional />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.countryId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("countryId", {
								required: false,
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Country
							</option>
							{countries.map((country) => (
								<option key={country.id} value={country.id} >
									{country.name}
								</option>
							))}
						</select>
						{errors.countryId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.countryId.message}
							</p>
						)}
					</div>
                    <div className="col-span-2 flex space-x-3 items-center">
                        <input 
                            type="checkbox"
                            {...register("active", {
                                required: false,
                            })}
                            id="active"
                        />
                        <label htmlFor="active">Active</label>
                    </div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Subscription Package"
								: "Add New Subscription Package"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default SubscriptionPackageModalForm;
