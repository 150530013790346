import PayrollCompanyLayout from "../../components/layouts/PayrollCompanyLayout";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/routes.paths";
import useUserStore from "../../state-management/useUserStore";

const Reports = () => {
	const { currentUser } = useUserStore();
	const navigate = useNavigate();
	
	return (
		<PayrollCompanyLayout>
			<div>reports</div>
		</PayrollCompanyLayout>
	);
};

export default Reports