import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    SubscriptionTierPaginator,
    CreateSubscriptionTierDto, 
    QuerySubscriptionTierDto,
    UpdateSubscriptionTierDto, 
    QueryApplicableSubscriptionTiersDto,
    DetailedSubscriptionTierDto
} from "../models/subscription-tier.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetSubscriptionTierPackages(options?: QueryApplicableSubscriptionTiersDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET_PACKAGES;
    const request = (options: QueryApplicableSubscriptionTiersDto) => API.BillingAndSubscriptionAPI.getSubscriptionTierPackages(options);

    const { data, isLoading, error, refetch } = useQuery<SubscriptionTierPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetSubscriptionTier(options?: QuerySubscriptionTierDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET;
    const request = (options: QuerySubscriptionTierDto) => API.BillingAndSubscriptionAPI.getSubscriptionTier(options);

    const { data, isLoading, error, refetch } = useQuery<SubscriptionTierPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetSubscriptionTierById(id: string) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.DETAIL
    const request = API.BillingAndSubscriptionAPI.getSubscriptionTierById(id);

    const {data, isLoading, error} = useQuery<{ data: DetailedSubscriptionTierDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateSubscriptionTier(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateSubscriptionTierDto}) => API.BillingAndSubscriptionAPI.createSubscriptionTier(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET);
            },
        }
    )
}

export function useUpdateSubscriptionTier() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateSubscriptionTierDto, id: number}) => API.BillingAndSubscriptionAPI.updateSubscriptionTier(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET);
            },
        }
    )
}

export function useDeleteSubscriptionTier() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteSubscriptionTier(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
