import { Box, Modal } from "@mui/material";
import { ModalStyle } from "../../../../helpers/appHelpers";
import { useState } from "react";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useUpdateOrganization } from "../../../../services/organization.service";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

interface AccountActionModalProps {
	mode: "ACTIVATE" | "SUSPEND";
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}
const AccountActionModal = ({ 
    mode, 
    open, 
    setOpen, 
    tableAPIRef, 
}: AccountActionModalProps) => {
	const { formDataValues } = useEditFormStore();
    const [saving, setSaving] = useState(false);
	const { mutate: updateOrganization } = useUpdateOrganization();

    const handleModeAction = () => {
        setSaving(true);

        updateOrganization(
            { 
                payload: { status: mode === "ACTIVATE" ? "ACTIVE" : "SUSPENDED" }, 
                id: formDataValues.id 
            },
            {
                onSuccess: (updatedData) => {
                    tableAPIRef?.current.updateRows([updatedData.data]);
                    setOpen(false);
                },
                onError: () => setSaving(false),
            }
        )
    };

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={400}>
				<p className="text-center">
                    {mode === "ACTIVATE" ? (
                        <span>Are you sure you want to activate <span className="font-semibold">{formDataValues?.name}</span>?</span>
                    ):(
                        <span>Are you sure you want to suspend <span className="font-semibold">{formDataValues?.name}</span>?</span>
                    )}
				</p>

				<div className="flex w-full justify-center space-x-4 items-center mt-10 whitespace-nowrap">
					<button
						onClick={() => setOpen(false)}
						className={`px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs ${saving && "opacity-50"}`}
						disabled={saving}
					>
						Cancel
					</button>
					<button
						onClick={handleModeAction}
						className={`px-12 py-4 border text-white rounded-lg text-2xs hover:opacity-90 
                            ${mode === "ACTIVATE" ? "bg-primary-mango" : "bg-red-500"} ${saving && "opacity-50"}
                        `}
						disabled={saving}
					>
                        {mode === "ACTIVATE" ? (
                            <>{saving ? "Actiavating..." : "Yes, Activate"}</>
                        ):(
                            <>{saving ? "Suspending..." : "Yes, Suspend"}</>
                        )}
					</button>
				</div>
			</Box>
		</Modal>
	);
};

export default AccountActionModal