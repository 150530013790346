import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

type EditFormStore = {
    formDataValues: any,
    setFormData: (data: any) => void,
    clearFormData: () => void,
}

const useEditFormStore = create(
    persist<EditFormStore>(
        (set) => ({
            formDataValues: {},
            setFormData: (data: any) => {
                set({ formDataValues: data });
            },
            clearFormData: () => {
                set({ formDataValues: null });
            },
        }),
        {
            name: 'edit-form',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

export default useEditFormStore;