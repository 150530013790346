import { Box, Modal} from "@mui/material";
import { ModalStyle, StyledDataGrid } from "../../../../helpers/appHelpers";
import { SubscriptionTierDto } from "../../../../models/subscription-tier.dto";
import { IoMdClose } from "react-icons/io";
import { TransactionHistoryColumns } from "../../../shared/account_settings/nav/subscription/data";
import { useEffect, useState } from "react";
import CustomPagination from "../../../../components/table/CustomPagination";
import { useGetOrgTransaction } from "../../../../services/organization-transaction.service";
import { OrganizationTransaction } from "../../../../models/organization-transaction.dto";

interface ITransactionHistory {
	organizationId: string;
	subscriptionPackage: SubscriptionTierDto | undefined;
	openTransactionHistory: boolean;
	setOpenTransactionHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

const TransactionHistory = ({
	organizationId,
	subscriptionPackage,
	openTransactionHistory,
	setOpenTransactionHistory,
}: ITransactionHistory) => {
	const [tableData, setTableData] = useState<OrganizationTransaction[]>([]);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);

    const { data, isLoading, refetch } = useGetOrgTransaction({
		organizationId,
        limit: 6,
        orderBy: "createdAt:desc" as any,
		page,
    });

	useEffect(() => {
		if (!isLoading && data?.data) {
			setTableData(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
        refetch().then((response) => {
            if (response) {
                setTableData(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }
        })
	}, [page])

	const handlePageChange = (_, value: number) => setPage(value);

	return (
		<Modal
			open={openTransactionHistory}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={1260} fontSize={"13px"}>
				<div className="flex justify-between items-center pt-4 pb-6">
					<div className="flex items-center gap-5">
						<h1 className="font-bold text-lg">Transaction History</h1>
						<div className="bg-[#6978F0] font-semibold text-white whitespace-nowrap 
							text-xs px-2 py-[6px] rounded flex items-center justify-center"
						>
							<p>{subscriptionPackage?.name}</p>
						</div>
					</div>
					<button
						onClick={() => setOpenTransactionHistory(false)}
					>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<hr />
				<div className="mt-6">
					<Box
						sx={{
							height: "530px",
							backgroundColor: "background.paper",
							borderRadius: 4,
							width: "100%",
							"& .actions": {
								color: "text.secondary",
							},
							"& .textPrimary": {
								color: "text.primary",
							},
							"& .super-app-theme--header": {
								backgroundColor: "#F2F2F4",
								fontWeight: "bold",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "11px",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f7fafc",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#e2e8f0",
								borderRadius: "6px",
								border: "3px solid #f7fafc",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#e2e8f0",
								borderRadius: "7px",
								border: "3px solid #f7fafc",
							},
						}}
					>
						<StyledDataGrid
							rows={tableData}
							rowHeight={65}
							columnHeaderHeight={70}
							columns={TransactionHistoryColumns}
							loading={isLoading}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 6,
									},
								},
								columns: {
									columnVisibilityModel: {
										id: false,
									},
								},
							}}
							slots={{
								pagination: () => <CustomPagination 
									pageCount={pageCount} 
									pageState={page}
									handleChange={handlePageChange}
								/>
							}}
							pageSizeOptions={[5]}
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
							}
							getRowId={(row) => row.id}
						/>
					</Box>
				</div>
			</Box>
		</Modal>
	);
};

export default TransactionHistory;
