import { useState } from "react";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { Helmet } from "react-helmet-async";
import { BsDownload } from "react-icons/bs";
import PayslipsTable from "./table";
import Search from "../../../components/search/SearchInput";
import { Rows } from "./table/data";
import { Link } from "react-router-dom";
import CustomPagination from "../../../components/table/CustomPagination";

const SelfservicePayslips = () => {
	const [tableData, setTableData] = useState<any[]>(Rows);
	const [fetchedTableData, setFetchedTableData] = useState<any[]>([]);
	
	return (
		<SelfserviceLayout pageHeader="Payslips">
			<Helmet>
				<title>Payslips | Self Service Flow </title>
			</Helmet>
			<div className="mb-10 lg:mb-0">
				<div className="lg:w-fit w-full flex lg:justify-end mb-6">
					<Search fallbackData={fetchedTableData} setTableData={setTableData} />
				</div>
				<div className="mobile-full-width max-lg:pl-4 max-lg:hidden">
					<PayslipsTable tableData={tableData} />
				</div>
				<div className="lg:hidden w-full flex flex-col gap-6">
					{tableData.map((item) => (
						<div key={item.id} className="w-full px-4 py-6 rounded-[10px] bg-[#F2F2F4] flex flex-col gap-4">
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Pay Period</h6>
								<p className="text-sm font-medium text-charcoal">{item.payPeriod}</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Date Paid</h6>
								<p className="text-sm font-medium text-charcoal">{item.datePaid}</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Gross Pay</h6>
								<p className="text-sm font-medium text-charcoal">{item.grossPay}</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Net Pay</h6>
								<p className="text-sm font-medium text-charcoal">{item.netPay}</p>
							</div>
							<Link 
								to={item.pdfUrl} 
								target="_blank" 
								className="flex gap-3 text-sm font-medium text-primary-blue"
							>
								<BsDownload className="text-xl" />
								Download
							</Link>
						</div>
					))}
				</div>
				<div className="lg:hidden flex justify-end mt-8">
					<CustomPagination pageCount={1} />
				</div>
			</div>
		</SelfserviceLayout>
	);
};

export default SelfservicePayslips;
