import {PaginatorInfo} from "./generic.dto";

export type Notch = {
    id: number
    companyId: number
    employeeBandId: number
    amount: number
    code: string
    createdAt: string
    modifiedAt: string | null
}


export class CreateNotchDto {
    companyId!: number
    employeeBandId!: number
    code!: string
    amount!: number
}

export class CreateNotchWithBandDto {
    code!: string
    amount!: number
}

export type CreateNotchesDto = {
    companyId: number;
    employeeBandId: number;
    notchType: NOTCH_TYPE;
} & ({
    items: CreateNotchDto[];
} | {
    code: string;
    count: number;
})

export enum NOTCH_TYPE {
    FIXED = 'FIXED',
    EVENLY_SPREAD = 'EVENLY_SPREAD'
  }

export interface NotchDto extends Notch { }


export class UpdateNotchDto {
    employeeBandId?: number
    code?: string
    amount?: number
}

export class QueryNotchDto {
    companyId?: number;
    employeeBandId?: number
    code?: string
    active?: boolean;
    page?: number = 1;
    limit?: number ;
    orderBy?: NotchOrderBy = NotchOrderBy.CREATED_AT_ASC;
}

export class SearchNotchDto {
    q?: string
    page?: number = 1;
    limit?: number;
    orderBy?: NotchOrderBy = NotchOrderBy.CREATED_AT_ASC;
}

export enum NotchOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    AMOUNT_ASC = 'amount:asc',
    AMOUNT_DESC = 'amount:desc',
}

export interface NotchPaginator extends PaginatorInfo<NotchDto> {}