import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { useCreateUnion } from "../../../../../services/union.service";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
    code: string;
    name: string;
    description?: string;
    duesCollected: number;
    deductionFrequency: string;
	currencyId: number;
    active?: boolean;
}

interface INewUnion {
	companyCurrencies: CompanyCurrencyConfigDto[];
	setShowNewUnionForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewUnion = ({ companyCurrencies, setShowNewUnionForm }: INewUnion) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { mutate: createEmployeeUnion } = useCreateUnion();
	const { addNewAccordionsData } = useManageAccordionsData();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createEmployeeUnion(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewUnionForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for="name" />
					<input
						placeholder={"Enter name"}
						type="text"
						{...register("name", {
							required: "Enter name here",
						})}
						id="name"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.name && (
						<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors.code.message}
						</p>
					)}
				</div>
				<div className="w-full col-span-2">
					<Label title="Description" for="description" optional />
					<FormDescriptionInput 
						register={register}
						required={false}
						attributes={{
							className: "w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none"
						}}
					/>
				</div>
				<div className="w-full">
					<Label title="Dues Collected" for="duesCollected" />
					<MoneyInput 
						attributes={{
							id: "duesCollected",
							placeholder: "Enter dues collected",
						}}
						register={register}
						name="duesCollected"
						setValue={setValue}
						required={"Enter dues collected here"}
						error={errors.duesCollected}
					/>
					{errors.duesCollected && (
						<p className="text-red-500 text-sm mt-1">
							{errors.duesCollected.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Currency" for="currencyId" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
							errors.currencyId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("currencyId", {
							required: "Select currency here",
							valueAsNumber: true
						})}
					>
						<option value="">
							Select Currency
						</option>
						{companyCurrencies.map((currency) => (
							<option 
								key={currency.id} 
								value={currency.id} 
							>
								{currency?.currency?.name}
							</option>
						))}
					</select>
					{errors.currencyId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currencyId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Deduction Frequency" for="deductionFrequency" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.deductionFrequency
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("deductionFrequency", {
							required: "Select deduction frequency here",
						})}
					>
						<option className="opacity-50" value="">
							Select Frequency
						</option>
						<option value="DAILY">Daily</option>
						<option value="WEEKLY">Weekly</option>
						<option value="MONTHLY">Monthly</option>
						<option value="QUARTERLY">Quarterly</option>
						<option value="ANNUALLY">Annually</option>
					</select>
					{errors.deductionFrequency && (
						<p className="text-red-500 text-sm mt-1">{errors.deductionFrequency.message}</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active")}
						id="active"
					/>
					<label htmlFor="active">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewUnionForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Union"}`}
					className={"h-14 px-8"}
					type="submit"
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewUnion;
