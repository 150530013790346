import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import SalaryAdvanceView from "./views/SalaryAdvanceView";
import PartialSalaryView from "./views/PartialSalaryView";
import { Helmet } from "react-helmet-async";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { PATHS } from "../../../routes/routes.paths";
import { useSearchParams } from "react-router-dom";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { PayAdvancesTabColumns } from "../../../components/custom_tab/tabColumns";

const PayAdvances = () => {
	const { userType } = useUserStore();
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "salaryAdvance";
	const [view, setView] = useState(currentView);

	return (<>
		{userType === "ORGANIZATION" ? (
			<PayrollCompanyLayout>
				<Helmet>
					<title>Pay Advances | Company Flow</title>
				</Helmet>
                <h1 className="font-bold text-xl mb-8">Pay Advances</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={PayAdvancesTabColumns}
				>
					{view === "salaryAdvance" && <SalaryAdvanceView />}
					{view === "partialSalary" && <PartialSalaryView />}
				</CustomTab>
			</PayrollCompanyLayout>
		):(
			<HumanResourceLayout>
				<Helmet>
					<title>Pay Advances | HR Flow</title>
				</Helmet>
                <h1 className="font-bold text-xl mb-8">Pay Advances</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={PayAdvancesTabColumns}
				>
					{view === "salaryAdvance" && <SalaryAdvanceView />}
					{view === "partialSalary" && <PartialSalaryView />}
				</CustomTab>
			</HumanResourceLayout>
		)}
	</>);
};

export default PayAdvances;
