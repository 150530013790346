import {PaginatorInfo} from "./generic.dto";

type Permission = {
    modifiedAt?: string
    createdAt: string
    isDefault: boolean
    name: string,
    code: string
    groupId: string | null;
}

export interface PermissionDto extends Permission{}

export interface PermissionPaginator extends PaginatorInfo<PermissionDto> {}

export class CreatePermissionDto {
    code!: string;
    name!: string;
    groupId!: string | null;
    isDefault?: boolean = false;
}

export class QueryPermissionDto {
    isDefault?: boolean;
    page?: number;
    groupId?: string;
    limit?: number;
    orderBy?: PermissionOrderBy = PermissionOrderBy.NAME_DESC;
}

export class UpdatePermissionDto {
    name?: string;
    groupId?: string;
    isDefault?: boolean;
}

export enum PermissionOrderBy {
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
}