import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { PermissionDto } from "../../../../../models/permission.dto";
import { OrganizationRoleDto, UserCategory } from "../../../../../models/organization-role.dto";
import { useState } from "react";
import { useUpdateOrganizationRole } from "../../../../../services/organization-role.service";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";

type IFormInputs = {
	name: string;
	active: boolean;
}
interface IEditOrganizationRole {
    permissionsData: PermissionDto[];
    organizationRole: OrganizationRoleDto;
	setReloadData: React.Dispatch<React.SetStateAction<string>>;
}

const EditOrganizationRole = ({
    permissionsData, 
    organizationRole,
    setReloadData,
}: IEditOrganizationRole) => {
    const [expanded, setExpanded] = useState(false);
    const { mutate: updateRole } = useUpdateOrganizationRole();
	const [permissions, setCurrentRolePermissions] = 
        useState<string[]>(organizationRole.permissions);
	const [permissionsError, setPermissionsError] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	
	const {
		register,
		handleSubmit,
        reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: organizationRole.name,
			active: organizationRole.active,
		}
	});

	const updatePermissions = (newEntry: string) => {
        const optionAlreadyPresent = permissions.find((permission) => {
			return permission === newEntry
		});
		const filteredPermissions = permissions.filter((permission) => {
			return permission !== newEntry
		});

		if (optionAlreadyPresent) {
			setCurrentRolePermissions(filteredPermissions);
		} else {
			setCurrentRolePermissions(prev => [...prev, newEntry]);
		}
    };

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		if (!permissions.length) {
			setPermissionsError(true);
			return
		}
		setUploading(true);

		updateRole(
			{
				payload: { ...data, permissions }, 
                id: organizationRole.id
			},
			{
				onSuccess: () => {
					setReloadData("true");
					setExpanded(false);
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	// const [deleting, setDeleting] = useState(false);
	// const { mutate: deleteOrganizationRole } = useDeleteOrganizationRole();

	// const handleDeleteMethod = () => {
	// 	setDeleting(true);

	// 	deleteOrganizationRole(
	// 		{ id: contractType?.id },
	// 		{
	// 			onSuccess: () => {
	// 				setReloadData("true");
	// 				setOpenDeleteModal(false);
	// 			},
	// 			onSettled: () => setDeleting(false),
	// 		}
	// 	);
	// };

    return (  
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
            <AccordionSummary 
                expandIcon={<IoIosArrowDown className="text-xl" />}
				aria-controls={organizationRole.id + "bh-content"}
				id={organizationRole.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
            >
                <h1 className="font-semibold text-sm">{organizationRole.name}</h1>
            </AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
                <div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
                    <h2 className="font-semibold text-2xs">Details</h2>
                    <button
                        type="button"
                        className={`border-none text-primary-blue text-2xs ${
                            !formDisabled ? "hidden" : ""
                        }`}
                        onClick={() => setFormDisabled(false)}
                    >
                        Edit
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
                        <div className="w-full col-span-2">
                            <Label title="Name" for="name" />
                            <input
                                defaultValue={organizationRole.name}
                                disabled={formDisabled}
                                placeholder={"Enter name"}
                                type="text"
                                {...register("name", {
                                    required: "Enter name here",
                                })}
                                id="name"
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.name
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors.name && (
                                <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
                            )}
                        </div>
                        <div className="w-full col-span-2 flex space-x-3 items-center">
                            <input 
                                type="checkbox"
                                defaultChecked={organizationRole.active}
                                {...register("active", {
                                    required: false,
                                })}
                                id="active"
                                disabled={formDisabled}
                            />
                            <label htmlFor="active">Active</label>
                        </div>
                    </div>
                    <div>
                        <p className="text-gray-500 text-sm mt-8">
                            Select permissions for this role
                        </p>
                        <ul className="mt-4 space-y-8">
                            {permissionsData.map((permission) => (
                                <li key={permission.code} className="flex items-center space-x-3">
                                    <input 
                                        type="checkbox" 
                                        defaultChecked={
                                            organizationRole.permissions.includes(permission.code) ||
                                            permission.isDefault === true
                                        }
                                        name={permission.code} 
                                        id={permission.code} 
                                        onChange={() => updatePermissions(permission.code)}
                                        disabled={formDisabled}
                                    />
                                    <label htmlFor={permission.code}>{permission.name}</label>
                                </li>
                            ))}
                        </ul>
                        {permissionsError &&
                            <p className="text-red-500 text-sm mt-4">Select at least one permission to continue</p>
                        }
                    </div>
                    
                    {!formDisabled && (
                        <div className="flex w-full justify-end space-x-4 items-center mt-10">
                            <button
                                type="button"
                                className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
                                onClick={() => {
                                    setFormDisabled(true);
                                    reset();
                                }}
                            >
                                Cancel
                            </button>
                            <OrangeButton
                                title={`${uploading ? "Saving..." : "Save"}`}
                                className={"px-12"}
                                type={"submit"}
                                disabled={uploading}
                            />
                        </div>
                    )}
                    
                    {formDisabled && (
                        <div className="flex w-full justify-end space-x-4 items-center mt-10">
                            <button
                                type="button"
                                onClick={() => setOpenDeleteModal(true)}
                                className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
                                disabled={uploading}
                            >
                                Delete
                            </button>
                        </div>
                    )}

                    {/* <DeleteModal
                        openDeleteModal={openDeleteModal}
                        spanContent={organizationRole.name}
                        setOpenDeleteModal={setOpenDeleteModal}
                        handleDeleteMethod={handleDeleteMethod}
                        deleting={deleting}
                    /> */}
                </form>
            </AccordionDetails>
        </Accordion>
    );
}
 
export default EditOrganizationRole;