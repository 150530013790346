import { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { Columns } from "./data";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import PaymentInstitutionModalForm from "./modal";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { useGetCountry } from "../../../../../services/country.service";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../helpers/useManageTable";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { CountryOrderBy } from "../../../../../models/country.dto";
import TableFilter from "../../../../../components/table/filter/TableFilter";
import APISearchInput from "../../../../../components/search/APISearchInput";
import { 
	useDeletePaymentInstitution, 
	useGetPaymentInstitution, 
	useGetPaymentInstitutionSearch 
} from "../../../../../services/payment-institution.service";
import { PaymentInstitutionDto } from "../../../../../models/payment-institution.dto";
import DeleteModal from "../../../../../components/modals/DeleteModal";

const PaymentInstitutions = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	const { mutate: searchPaymentInstitutions } = useGetPaymentInstitutionSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<PaymentInstitutionDto>({ 
		fetchHook: useGetPaymentInstitution 
	});

	// Fetched data for select fields requiring data from database
	const { data: countries } = useGetCountry({ 
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: CountryOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletePaymentInstitution } = useDeletePaymentInstitution();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletePaymentInstitution(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];
	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Payment Institutions</h2>
			<hr />
			<div className="flex justify-between items-center mt-7 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchPaymentInstitutions}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
								]
							},
							{
								title: "Country",
								fieldName: "countryId",
								fieldType: "SELECT_FIELD",
								options: countries?.data || [],
							},
							{
								title: "Active",
								fieldName: "active",
								fieldType: "YES_NO",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Payment Institution
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<PaymentInstitutionModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
							? "Update Payment Institution"
							: "Add New Payment Institution"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					countries={countries?.data || []}
				/>
			)}
		</div>
	);
};

export default PaymentInstitutions;
