import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "date",
		headerName: "Date",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params: any) {
			return (
				<div className="flex items-center space-x-2">
					{formatDate(params.value)}
				</div>
			);
		},
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "type",
		headerName: "Type",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	
	...createdAtmodifiedAtActions
];

function formatDate(inputDate: string): string {
    const date = new Date(inputDate);

    const fullMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
    const month = fullMonths[date.getMonth()];
    const day = date.getDate();
	
    // Determine the suffix based on the last digit of the day
    const lastDigit = day % 10;
    let suffix = "th";
    if (lastDigit === 1 && day !== 11) {
        suffix = "st";
    } else if (lastDigit === 2 && day !== 12) {
        suffix = "nd";
    } else if (lastDigit === 3 && day !== 13) {
        suffix = "rd";
    }

    const formattedDate = `${day}${suffix} ${month}`;

    return formattedDate
}
