import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { moneyFormat } from "../../../../../../helpers/appHelpers";
import { EmployeeBandDto } from "../../../../../../models/employee-band.dto";
import { NotchDto } from "../../../../../../models/notches.dto";
import { useDeleteNotch, useUpdateNotch } from "../../../../../../services/notches.service";
import Label from "../../../../../../components/form_fields/Label";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
	amount: number;
}

interface IEditNotch {
	notch: NotchDto;
	employeeBand: EmployeeBandDto;
	setForceRefetch: React.Dispatch<React.SetStateAction<number>>;
}

const EditNotch = ({ notch, employeeBand, setForceRefetch }: IEditNotch) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateNotch } = useUpdateNotch();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: notch.code,
			amount: notch.amount,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateNotch(
			{ payload: data, id: notch.id }, 
			{
				onSuccess: () => {
					setForceRefetch(Math.random());
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletNotch } = useDeleteNotch();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletNotch(
			{ id: notch?.id },
			{
				onSuccess: () => {
					setForceRefetch(Math.random());
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={notch.id + "bh-content"}
				id={notch.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{notch.code}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: notch.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Amount" for="amount" />
								<MoneyInput 
									attributes={{
										id: "amount",
										placeholder: "Enter amount",
										disabled: formDisabled,
									}}
									register={register}
									name="amount"
									setValue={setValue}
									defaultValue={notch.amount}
									required={"Enter amount here"}
									validate={(value: number) => {
										if (value < employeeBand.minimumBasicPay) {
											return `Cannot be less than the minimum basic pay 
												(${moneyFormat(employeeBand.minimumBasicPay)})`
										}
										if (employeeBand.maximumBasicPay && value > employeeBand.maximumBasicPay) {
											return `Cannot be more than the maximum basic pay 
												(${moneyFormat(employeeBand.maximumBasicPay)})`
										}
										return true
									}}
									error={errors.amount}
								/>
								{errors?.amount && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.amount?.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={notch.code}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditNotch;
