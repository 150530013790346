import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CompanyDocumentTypeDto,
    CompanyDocumentTypePaginator,
    CreateCompanyDocumentTypeDto,
    QueryCompanyDocumentTypeDto,
    SearchCompanyDocumentTypeDto,
    UpdateCompanyDocumentTypeDto,
} from '../models/company-document-type.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetCompanyDocumentType(options?: QueryCompanyDocumentTypeDto) {
    const url = ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.GET;
    const request = (options: QueryCompanyDocumentTypeDto) => API.HrAPI.getCompanyDocumentType(options);

    const { data, isLoading, error, refetch } = useQuery<CompanyDocumentTypePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetCompanyDocumentTypeSearch(options?: SearchCompanyDocumentTypeDto) {

    return useMutation(
        (options?: SearchCompanyDocumentTypeDto) => API.HrAPI.getCompanyDocumentTypeSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetCompanyDocumentTypetById(id: number) {
    const url = ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.DETAIL;
    const request = API.HrAPI.getCompanyDocumentTypeById(id);

    const { data, isLoading, error } = useQuery<{ data: CompanyDocumentTypeDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreateCompanyDocumentType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateCompanyDocumentTypeDto }) => API.HrAPI.createCompanyDocumentType(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.GET);
            },
        },
    );
}

export function useUpdateCompanyDocumentType() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateCompanyDocumentTypeDto, id: number }) => API.HrAPI.updateCompanyDocumentType(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.COMPANY_DOCUMENT_TYPE.GET);
            },
        },
    );
}

export function useDeleteCompanyDocumentType() {

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteCompanyDocumentType(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}