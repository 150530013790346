import { useEffect, useState } from "react";
import EditContractType from "./EditContractType";
import NewContractTypeForm from "./NewContractTypeForm";
import { ContractTypeDto, ContractTypeOrderBy } from "../../../../../models/contract-type.dto";
import { useGetContractType } from "../../../../../services/contract-type.service";
import { AiOutlinePlus } from "react-icons/ai";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { SkewLoader } from "react-spinners";
import CustomPagination from "../../../../../components/table/CustomPagination";

const ContractTypes = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewContractTypeForm, setShowNewContractTypeForm] = useState(false);
	const [contractTypes, setContractTypes] = useState<ContractTypeDto[]>([]);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [newData, setNewData] = useState("");
	const [reloadData, setReloadData] = useState("");
	
	const handlePageChange = (_, value: number) => setPage(value);
	
	const { data, isLoading, refetch } = useGetContractType({
        organizationId: currentCompany?.organizationId,
		orderBy: ContractTypeOrderBy.CREATED_AT_DESC,
		limit: 8,
        page: newData ? 1 : page,
    });

	useEffect(() => {
		if (!isLoading && data?.data) {
			setContractTypes(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
		newData && setPage(1);

        refetch().then((response) => {
            if (response) {
                setContractTypes(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }

			newData && setNewData("");
			reloadData && setReloadData("");
        });
	}, [page, newData, reloadData])
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Contract Types</h2>
				{!showNewContractTypeForm && (
					<button
						onClick={() => setShowNewContractTypeForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Contract Type
					</button>
				)}
			</div>

			{showNewContractTypeForm && (
				<NewContractTypeForm
					setShowNewContractTypeForm={setShowNewContractTypeForm}
					setNewData={setNewData}
				/>
			)}

			{(isLoading && !contractTypes.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{contractTypes.map((contractType) => (
				<EditContractType
					key={contractType.id}
					contractType={contractType}
					setReloadData={setReloadData}
				/>
			))}

			{(!isLoading && !contractTypes.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no contract types added yet. Click 'Add New Contract Type' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end my-8">
				<CustomPagination 
					pageCount={pageCount} 
					pageState={page}
					handleChange={handlePageChange}
				/>
			</div>
		</div>
	);
};

export default ContractTypes;
