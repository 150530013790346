import { useContext, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useDeleteTaxRate, useGetTaxRate } from "../../../../../../services/tax-rate.service";
import { AiOutlinePlus } from "react-icons/ai";
import ActionPopover from "../../../../../../components/popovers/actionPopover";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import { RateTaxRuleColumns } from "../data";
import RateTaxRuleModalForm from "../modals/RateTaxRuleModalForm";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { INCOME_PERIOD, TaxRateDto } from "../../../../../../models/tax-rate.dto";
import CustomStyledTable from "../../../../../../components/table/CustomStyledTable";
import { ActiveCountryContext } from "..";
import TableFilter from "../../../../../../components/table/filter/TableFilter";

const RateTaxRuleView = () => {
	const countries = useContext(ActiveCountryContext);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<TaxRateDto>({
		fetchHook: useGetTaxRate
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteTaxRateRule } = useDeleteTaxRate();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteTaxRateRule(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];
	const columnsWithAdditions = RateTaxRuleColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-7 mb-8">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Country",
							fieldName: "countryId",
							fieldType: "SELECT_FIELD",
							options: countries,
						},
						{
							title: "Income Period",
							fieldName: "incomePeriod",
							fieldType: "CHECKBOX_LIST",
							options: INCOME_PERIOD,
						},
						{
							title: "On Direct Income",
							fieldName: "onDirectIncome",
							fieldType: "YES_NO",
						},
					]}
				/>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New 	
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<RateTaxRuleModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Rate Rule" : "Add New Rate Rule"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</>
	);
};

export default RateTaxRuleView;
