import { useState } from "react";
import AdminLayout from "../../../components/layouts/AdminLayout";
import { Helmet } from "react-helmet-async";
import { IoMdWallet } from "react-icons/io";
import { HiNewspaper, HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";
import OrangeButton from "../../../components/buttons/OrangeButton";
import AccountModalForm from "./modals/AccountModalForm";
import { useGetOrganization, useGetOrganizationBySearch, useGetOrganizationCount } from "../../../services/organization.service";
import { OrganizationDto, STATUS } from "../../../models/organization.dto";
import { useGetCompanyCount } from "../../../services/company-account.service";
import { useManageTableWithSearchParams } from "../../../helpers/useManageTable";
import { Avatar, Popover } from "@mui/material";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import { Columns } from "./data";
import ViewOrganizationInfo from "./modals/ViewOrganizationInfo";
import TableFilter from "../../../components/table/filter/TableFilter";
import { useGetCountry } from "../../../services/country.service";
import { useGetSubscriptionTier } from "../../../services/subscription-tier.service";
import { useGetBillingType } from "../../../services/billing-type.service";
import APISearchInput from "../../../components/search/APISearchInput";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useEditFormStore from "../../../state-management/useEditFormStore";
import { BsPencil } from "react-icons/bs";
import { LuEye } from "react-icons/lu";
import CustomStyledTable from "../../../components/table/CustomStyledTable";
import AccountActionModal from "./modals/AccountActionModal";

const AccountsManagement = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openAccountModalForm, setOpenAccountModalForm] = useState(false);
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
	const [openAccountActionModal, setOpenAccountActionModal] = useState(false);
	const [accountActionMode, setAccountActionMode] = useState<"ACTIVATE" | "SUSPEND">("ACTIVATE");
	const [isEditForm, setIsEditForm] = useState(false);
	const { formDataValues, setFormData } = useEditFormStore();

	const { data: totalOrganizations } = useGetOrganizationCount();
	const { data: totalCompanies } = useGetCompanyCount();

	const { mutate: searchOrganizations } = useGetOrganizationBySearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<OrganizationDto>({
		fetchHook: useGetOrganization
	});
	
	const options = {
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "name:asc" as any,
	}
	const { data: countries } = useGetCountry(options);
	const { data: subscriptionTiers } = useGetSubscriptionTier(options);
	const { data: billingTypes } = useGetBillingType({
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "name:asc" as any,
	});

	const handleClose = () => setAnchorEl(null);

	const additionalColumns = [
		{
			field: "name",
			headerName: "Name",
			width: 280,
			editable: true,
			headerClassName: "super-app-theme--header",
			renderCell: (params: any) => {
				return (
					<div className="flex items-center space-x-4">
						<Avatar
							src={params.row.logoUrl}
							alt="logo"
							className="w-10 h-10 rounded-lg object-cover"
						/>
						<p className="text-sm">{params.row.name}</p>
					</div>
				);
			},
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map((obj) => {
		return additionalColumns.find((o) => o.field === obj.field) || obj;
	});

	return (
		<AdminLayout>
			<Helmet>
				<title>Accounts Management | Admin Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-6">Accounts Management</h1>
			<div className="flex justify-between items-center space-x-6">
				<div className="flex items-center space-x-3 border w-full px-5 rounded-lg py-8">
					<div className="bg-primary-mango text-white px-2.5 py-[11px] rounded-lg">
						<IoMdWallet className="text-base" />
					</div>
					<div>
						<p className="text-[11px] text-secondary-darkgray">
							Total Number Of Accounts
						</p>
						<h3 className="font-medium text-lg">{totalOrganizations?.data.count || "--"}</h3>
					</div>
				</div>
				<div className="flex items-center space-x-3 border w-full px-5 rounded-lg py-8">
					<div className="bg-primary-violet text-white px-2.5 py-[11px] rounded-lg">
						<HiNewspaper className="text-base" />
					</div>
					<div>
						<p className="text-[11px] text-secondary-darkgray">
							Total Number of companies
						</p>
						<h3 className="font-medium text-lg">{totalCompanies?.data.count || "--"}</h3>
					</div>
				</div>
			</div>
			<div className="my-6 w-full flex justify-between items-center">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchOrganizations}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
								]
							},
							{
								title: "Status",
								fieldName: "status",
								fieldType: "CHECKBOX_LIST",
								options: STATUS,
							},
							{
								title: "Country",
								fieldName: "countryId",
								fieldType: "SELECT_FIELD",
								options: countries?.data || [],
							},
							{
								title: "Subscription Tier",
								fieldName: "subscriptionTierId",
								fieldType: "SELECT_FIELD",
								options: subscriptionTiers?.data || [],
							},
							{
								title: "Billing Type",
								fieldName: "billingTypeId",
								fieldType: "SELECT_FIELD",
								options: billingTypes,
							},
						]}
					/>
				</div>
				<OrangeButton
					title={"Add New Account"}
					className={"px-5 h-10 flex items-center "}
					onClick={() => {
						setOpenAccountModalForm(true);
						setIsEditForm(false);
					}}
				/>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: "auto",
						borderRadius: 8,
						display: "flex",
						justifyContent: "center",
					},
				}}
			>
				<div className="flex flex-col justify-center p-3 text-secondary-darkgray">
					<div
						onClick={() => {
							setOpenAccountModalForm(true);
							setIsEditForm(true);
							handleClose();
						}}
						className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
					>
						<BsPencil />
						<p className="text-2sm">Edit</p>
					</div>
					<div
						onClick={() => {
							setOpenViewDetailsModal(true);
							handleClose();
						}}
						className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
					>
						<LuEye />
						<p className="text-2sm">View Details</p>
					</div>
					{(formDataValues?.status === "ACTIVE" || formDataValues?.status === "SUSPENDED_PENDING_PAYMENT") && (
						<div
							onClick={() => {
								setOpenAccountActionModal(true);
								setAccountActionMode("SUSPEND");
								handleClose();
							}}
							className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
						>
							<HiOutlineLockClosed />
							<p className="text-2sm">Suspend</p>
						</div>
					)}
					{(formDataValues?.status === "SUSPENDED" || formDataValues?.status === "SUSPENDED_PENDING_PAYMENT") && (
						<div
							onClick={() => {
								setOpenAccountActionModal(true);
								setAccountActionMode("ACTIVATE");
								handleClose();
							}}
							className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
						>
							<HiOutlineLockOpen />
							<p className="text-2sm">Activate</p>
						</div>
					)}
				</div>
			</Popover>
			{openViewDetailsModal && (
				<ViewOrganizationInfo
					organization={formDataValues}
					open={openViewDetailsModal}
					setOpen={setOpenViewDetailsModal}
				/>
			)}
			{openAccountModalForm && (
				<AccountModalForm
					open={openAccountModalForm}
					setOpen={setOpenAccountModalForm}
					title={
						isEditForm 
						? "Update " + formDataValues?.name
						: "Create New Account"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					countries={countries?.data || []}
					billingTypes={billingTypes}
				/>
			)}
			{openAccountActionModal && (
				<AccountActionModal
					mode={accountActionMode}
					open={openAccountActionModal}
					setOpen={setOpenAccountActionModal}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</AdminLayout>
	);
};

export default AccountsManagement;
