import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary, Box, Modal } from "@mui/material";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import Label from "../../../../../../components/form_fields/Label";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import { EmployeeDto } from "../../../../../../models/employee.dto";
import { DepartmentLeadershipDto } from "../../../../../../models/department-leadership.dto";
import { 
	useUpdateDepartmentLeadership, 
	useDeleteDepartmentLeadership, 
	useUnlinkDepartmentLeadership 
} from "../../../../../../services/department-leadership.service";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import APISelectField from "../../../../../../components/form_fields/APISelectField";
import { formatDepartmentRank } from "../data";
import { ModalStyle } from "../../../../../../helpers/appHelpers";

interface IFormInputs {
	employeeId?: number;
	permanent?: string;
}

interface IEditLeader {
	leader: DepartmentLeadershipDto;
	employees: EmployeeDto[];
}

const EditLeader = ({ leader, employees }: IEditLeader) => {
	const { currentCompany } = useCompanyStore();
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openUnlinkModal, setOpenUnlinkModal] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [unlinking, setUnlinking] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			employeeId: leader.employeeId || undefined,
			permanent: leader.permanent ? "yes" : "no",
		}
	});
	
	const { mutate: updateLeader } = useUpdateDepartmentLeadership();
	const { mutate: deleteLeader } = useDeleteDepartmentLeadership();
	const { mutate: unlinkOccupant } = useUnlinkDepartmentLeadership();

	const onSubmit: SubmitHandler<IFormInputs> = async (data: any) => {
		setUploading(true);
		data.permanent = data.permanent === "yes" ? true : false;

		updateLeader(
			{ 
				payload: data, 
				companyId: currentCompany?.id as number,
				id: leader.id,
			}, 
			{
				onSuccess: () => setFormDisabled(true),
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteLeader(
			{ id: leader?.id, companyId: currentCompany?.id as number },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

	const handleUnlinkOccupant = () => {
		setUnlinking(true);

		unlinkOccupant(
			{ id: leader?.id, companyId: currentCompany?.id as number },
			{
				onSuccess: () => {
					setValue("employeeId", undefined);
					setOpenUnlinkModal(false);
				},
				onSettled: () => setUnlinking(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={leader.id + "bh-content"}
				id={leader.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="w-full mr-2 flex items-center justify-between font-inter text-sm font-semibold">
					<p>
						{formatDepartmentRank(leader.rank) + " "}
						{leader.employee ? `(${leader.employee.firstName} ${leader.employee.lastName})` : ""}
					</p>
					<div 
						className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
						style={{ color: leader.permanent ? "#4CAF50" : "#FFC107" }}
					>
						<div 
							className="absolute inset-0" 
							style={{ 
								background: leader.permanent ? "#4CAF50" : "#FFC107", 
								opacity: "15%"
							}}
						/>
						<p>{leader.permanent ? "Permanent" : "Acting"}</p>
					</div>
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
							<div className="w-full">
								<Label title="Assign Employee" for="employeeId" />
								<APISelectField 
									attributes={{
										id: "employeeId",
										disabled: formDisabled,
									}}
									placeholder="Select Employee"
									register={register}
									name="employeeId"
									options={employees}
									pathname=""
									mapTexts={["firstName", "lastName"]}
									required={false}
									error={errors.employeeId}
									doNotLinkToResource
									valueAsNumber
								/>
								{errors.employeeId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.employeeId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Role Status" for="permanent" />
								<select
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
										errors.permanent
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("permanent", {
										required: "Select role status here",
									})}
									disabled={formDisabled}
								>
									<option value="">Select Role Status</option>
									<option value="yes">Permanent</option>
									<option value="no">Acting</option>
								</select>
								{errors.permanent && (
									<p className="text-red-500 text-sm mt-1">
										{errors.permanent.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							{leader.employeeId && (
								<button
									type="button"
									onClick={() => setOpenUnlinkModal(true)}
									className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								>
									Remove Occupant
								</button>
							)}
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className="px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs"
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={formatDepartmentRank(leader.rank)}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
					<Modal
						open={openUnlinkModal}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={ModalStyle} width={400}>
							<p className="text-center">
								<span>Are you sure you want to remove</span>
								<span className="font-semibold">{" " + leader.employee?.firstName + " " + leader.employee?.lastName}</span>
								<span>{""} as the {formatDepartmentRank(leader.rank)}?</span>
							</p>

							<div className="flex w-full justify-center space-x-4 items-center mt-10">
								<button
									onClick={() => setOpenUnlinkModal(false)}
									className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
									disabled={unlinking}
								>
									Cancel
								</button>
								<button
									onClick={handleUnlinkOccupant}
									className="px-12 py-4 border text-white hover:opacity-90 bg-secondary-red rounded-lg text-2xs"
									disabled={unlinking}
								>
									{unlinking ? "Removing..." : "Yes, Remove"}
								</button>
							</div>
						</Box>
					</Modal>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditLeader;
