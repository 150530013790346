import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateExchangeRateDto, 
    CurrencyConversionInputDto, 
    CurrencyConversionResponseDto, 
    DetailedExchangeRateDto, 
    ExchangeRatePaginator, 
    QueryExchangeRateDto, 
    UpdateExchangeRateDto 
} from '../models/exchange-rate.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetExchangeRate(options?: QueryExchangeRateDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.GET;
    const request = (options: QueryExchangeRateDto) => API.BillingAndSubscriptionAPI.getExchangeRate(options);

    const { data, isLoading, error, refetch } = useQuery<ExchangeRatePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetExchangeRateById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.DETAIL;
    const request = API.BillingAndSubscriptionAPI.getExchangeRateById(id);

    const { data, isLoading, error } = useQuery<{ data: DetailedExchangeRateDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useConvertCurrency(convertParams: CurrencyConversionInputDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.CONVERT;
    const request = API.BillingAndSubscriptionAPI.convertCurrency(convertParams);

    const { data, isLoading, error } = useQuery<{ data: CurrencyConversionResponseDto}, Error>(
        [url],
        (() => request),
    );

    return { data, isLoading, error };
}

export function useCreateExchangeRate() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateExchangeRateDto }) => API.BillingAndSubscriptionAPI.createExchangeRate(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.GET);
            },
        },
    );
}

export function useUpdateExchangeRate() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateExchangeRateDto, id: number }) => API.BillingAndSubscriptionAPI.updateExchangeRate(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.GET);
            },
        },
    );
}

export function useDeleteExchangeRate() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteExchangeRate(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
        },
    );
}