import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useCreateEmployeeDocument } from "../../../../../../services/employee-document.service";
import { CompanyDocumentTypeDto } from "../../../../../../models/company-document-type.dto";
import { Box, LinearProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { BsUpload, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { uploadOneDocument } from "../../../../../../services/document.service";

interface IFormInputs {
    typeId: number;
    url: string;
}
type FileStatus = "success" | "error" | "uploading" | ""

interface INewDocument {
	employeeId: number;
    companyDocumentTypes: CompanyDocumentTypeDto[];
	setShowNewDocumentForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewEmployeeDocument = ({
	employeeId,
	companyDocumentTypes,
	setShowNewDocumentForm,
}: INewDocument) => {
	const { mutate: createEmployeeDocument } = useCreateEmployeeDocument();
	const [uploading, setUploading] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
			"text/pdf": [".pdf"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (pdf, png, jpeg, jpg, heic or svg)");
		},
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		setUploading(true);
		
		if (file) {
			setFileStatus("uploading");

			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				data.url = fileUrl;
				setFileStatus("success");
			} else {
				setFileStatus("error");
				setUploading(false);
				return
			}
		}

		createEmployeeDocument(
			{ payload: { ...data, employeeId } },
			{
				onSuccess: () => setShowNewDocumentForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="w-full mt-6">
				<Label title="Document Type" for="typeId" />
				<select
					className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
						errors.typeId
							? " border border-red-500 focus:border-red-500 focus:outline-red-500"
							: "border-none"
					}`}
					{...register("typeId", {
						required: "Select document type here",
						valueAsNumber: true,
					})}
				>
					<option value="">
						Select Type
					</option>
					{companyDocumentTypes.map((documentType) => (
						<option
							key={documentType.id}
							value={documentType.id}
						>
							{documentType.name}
						</option>
					))}
				</select>
				{errors.typeId && (
					<p className="text-red-500 text-sm mt-1">{errors.typeId.message}</p>
				)}
			</div>
			<div className="w-full mt-6">
				<Label title="Document" for="" />
				<div
					{...getRootProps()}
					className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
					flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
				>
					<input {...getInputProps()} />
					<BsUpload className="text-xl mb-2" />
					<p>
						Upload document 
						<span className="font-semibold"> (2mb max)</span>
					</p>
					<p>(pdf, png, jpeg, jpg, heic or svg)</p>
				</div>
			</div>
			{file && (
				<div className="flex items-center space-x-4 w-full mt-1">
					<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
					<div className="flex flex-col items-start w-full">
						<div className="flex justify-between items-center w-full mb-1">
							<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
								{file.name}
							</p>
							<button onClick={() => {
								setFile(null);
								setFileStatus("");
							}}>
								<IoMdClose className="text-xl hover:scale-[1.1]" />
							</button>
						</div>
						<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
							<LinearProgress
								color={fileStatus === "error" ? "error" : "primary"}
								variant={"determinate"}
								value={fileStatus ? 100 : 0}
							/>
						</Box>
						<div className="flex justify-between text-xs w-full">
							<p>
								File size: {(file.size / 1000000).toFixed(2)}mb
							</p>
							{fileStatus === "success" ? (
								<span className="text-green-500 text-xs italic">
									File uploaded successfully
								</span>
							) : fileStatus === "error" ? (
								<span className="text-red-500 text-xs italic">
									File upload error! Try again
								</span>
							) : null}
						</div>
					</div>
				</div>
			)}

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewDocumentForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Document"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={!file || uploading}
				/>
			</div>
		</form>
	);
};

export default NewEmployeeDocument;