import { Popover } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface IEmptyPromptPopover {
	id: string | undefined;
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	handleClose: () => void;
	pathname: string;
}

const EmptyPromptPopover = ({
	id,
	open,
	anchorEl,
	handleClose,
	pathname,
}: IEmptyPromptPopover) => {
	const navigate = useNavigate();
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			PaperProps={{
				style: {
					width: 200, // Set your desired width
					borderRadius: 8,
					display: "flex",
					justifyContent: "center", // Set your desired border radius
				},
			}}
		>
			<div
				onClick={() => navigate(pathname)}
				className="text-primary-blue text-2xs font-normal cursor-pointer h-10 flex justify-start w-full items-center p-2"
			>
				Set up Option
			</div>
		</Popover>
	);
};

export default EmptyPromptPopover;
