import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Currency",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Currency Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "symbol",
		headerName: "Symbol",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	{
		field: "isDefault",
		headerName: "Default",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
