import { useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { SavingsAndBackpayTabColumns } from "../../../components/custom_tab/tabColumns";
import { useSearchParams } from "react-router-dom";
import SavingsView from "./views/SavingsView";
import BackPayView from "./views/BackPayView";

const SavingsAndBackpay = () => {
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "savings";
	const [view, setView] = useState(currentView);

	return (
		<PayrollCompanyLayout>
			<Helmet>
				<title>Savings & Back Pay | Company Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Savings & Back Pay</h1>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={SavingsAndBackpayTabColumns}
			>
				{view === "savings" && <SavingsView />}
				{view === "backpay" && <BackPayView />}
			</CustomTab>
		</PayrollCompanyLayout>
	);
};

export default SavingsAndBackpay;
