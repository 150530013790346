import { useState } from 'react'
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GrievanceReportsColumns } from "../data";
import { 
	useDeleteGrievanceReport, 
	useGetGrievanceReport, 
	useGetGrievanceReportBySearch 
} from "../../../../services/grievance-report.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import DeleteModal from '../../../../components/modals/DeleteModal';
import ActionPopover from '../../../../components/popovers/actionPopover';
import { GrievanceReportDto } from '../../../../models/grievance-report.dto';
import { AiOutlinePlus } from 'react-icons/ai';
import TableFilter from '../../../../components/table/filter/TableFilter';
import { APP_CONFIG } from '../../../../helpers/appHelpers';
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useGetEmployee } from '../../../../services/employee.service';
import { useGetGrievanceType } from '../../../../services/grievance-type.service';
import useUserStore from '../../../../state-management/useUserStore';
import GrievanceModalForm from '../modals/GrievanceModalForm';
import APISearchInput from '../../../../components/search/APISearchInput';
import { EmployeeOrderBy } from '../../../../models/employee.dto';

const GrievanceReportsView = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	const { mutate: searchGrievanceReports } = useGetGrievanceReportBySearch();
	const {
        tableAPIRef,
        fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<GrievanceReportDto>({
		fetchHook: useGetGrievanceReport,
		options: { companyId: companyProfile.companyId }
	});
	
	// Fetched data for select fields requiring data from database
	const options = {
		companyId: companyProfile.companyId,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "createdAt:desc" as any,
	};
	const { data: employees } = useGetEmployee({
		...options,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: grievanceTypes } = useGetGrievanceType(options);

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteGrievanceReport } = useDeleteGrievanceReport();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteGrievanceReport(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = GrievanceReportsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchGrievanceReports}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Report Number",
										fieldName: "reportNumber",
									},
									{
										title: "Report Date",
										fieldName: "reportDate",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Grievance Type",
								fieldName: "grievanceTypeId",
								fieldType: "SELECT_FIELD",
								options: grievanceTypes?.data || [],
							},
							{
								title: "Reporting Employee",
								fieldName: "reportingEmployeeId",
								fieldType: "SELECT_FIELD",
								options: employees?.data || [],
								mapTexts: ["firstName", "lastName"],
							},
							{
								title: "Report Date",
								fieldName: "reportDate",
								fieldType: "INPUT_FIELD",
								inputType: "date"
							},
							{
								title: "Date Created",
								fieldName: "createdAt",
								fieldType: "DATE_RANGE",
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Grievance Report</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<GrievanceModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm 
							? "Update Grievance Report" 
							: "New Grievance Report"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					employees={employees?.data || []}
					grievanceTypes={grievanceTypes?.data || []}
				/>
			)}
		</>
	);
};
export default GrievanceReportsView;