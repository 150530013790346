import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import SkewLoader from "react-spinners/SkewLoader";
import { CustomTaxReliefDto } from "../../../../../models/custom-tax-relief.dto";
import { useGetCustomTaxRelief } from "../../../../../services/custom-tax-relief.service";
import EditCustomTaxRelief from "./EditCustomTaxRelief";
import NewCustomTaxRelief from "./NewCustomTaxRelief";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { useGetEmployeeBand } from "../../../../../services/employee-band.service";
import { useGetEmployee } from "../../../../../services/employee.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { EmployeeOrderBy } from "../../../../../models/employee.dto";
import { EmployeeBandOrderBy } from "../../../../../models/employee-band.dto";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const CustomTaxReliefs = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewCustomTaxReliefForm, setShowNewCustomTaxReliefForm] = useState(false);
	
	const {
		accordionsData: customTaxReliefs,
		isLoading,
		pageCount,
    } = useManageAccordions<CustomTaxReliefDto>({
		fetchHook: useGetCustomTaxRelief,
		options: { companyId: currentCompany?.id }
	});
	
	// Form select fields API data source
	const { data: employees } = useGetEmployee({
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC,
	});
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Custom Tax Reliefs</h2>
				{!showNewCustomTaxReliefForm && (
					<button
						onClick={() => setShowNewCustomTaxReliefForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Custom Tax Relief
					</button>
				)}
			</div>
			
			{showNewCustomTaxReliefForm && (
				<NewCustomTaxRelief 
					employees={employees?.data || []}
					employeeBands={employeeBands?.data || []}
					setShowNewCustomTaxReliefForm={setShowNewCustomTaxReliefForm} 
				/>
			)}
			
			{(isLoading && !customTaxReliefs.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{customTaxReliefs.map((customTaxRelief) => (
				<EditCustomTaxRelief 
					key={customTaxRelief.id}
					customTaxRelief={customTaxRelief} 
				/>
			))}

			{(!isLoading && !customTaxReliefs.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no custom tax reliefs added yet. Click 'Add New Custom Tax Relief' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default CustomTaxReliefs;
