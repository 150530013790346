import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    CompanyLevelDto, CompanyLevelPaginator,
    CreateCompanyLevelDto, QueryCompanyLevelDto,
    UpdateCompanyLevelDto,
} from '../models/company-level.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetCompanyLevel(options?: QueryCompanyLevelDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.GET;
    const request = (options: QueryCompanyLevelDto) => API.ProcessingCompanyAPI.getCompLevel(options);

	const { data, isLoading, error, refetch } = useQuery<CompanyLevelPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetCompanyLevelById(id: number){
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.DETAIL;
    const request = API.ProcessingCompanyAPI.getCompLevelById(id);

    const { data, isLoading, error } = useQuery<{ data: CompanyLevelDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateCompanyLevel(onSuccessCallback?: (responsData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateCompanyLevelDto }) => API.ProcessingCompanyAPI.createCompLevel(payload),
        {
            onSuccess: (response) => {
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.GET);
            },
        },
    );
}

export function useUpdateCompanyLevel() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateCompanyLevelDto }) => API.ProcessingCompanyAPI.updateCompLevel(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.GET);
            },
        },
    );
}

export function useDeleteCompanyLevel() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteCompLevelById(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}