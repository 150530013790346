import { useState } from "react";
import EditAsset from "./EditAsset";
import NewAsset from "./NewAsset";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { AiOutlinePlus } from "react-icons/ai";
import { AssignedAssetOrderBy } from "../../../../../../models/asset-assign.dto";
import { useGetAssignedAsset } from "../../../../../../services/assigned-asset.service";
import { useGetAssetsType } from "../../../../../../services/asset-type.service";
import { APP_CONFIG } from "../../../../../../helpers/appHelpers";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import { SkewLoader } from "react-spinners";

const EmployeeAssets = () => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [showNewAssetForm, setShowNewAssetForm] = useState(false);

	const { data: employeeAssets, isLoading } = useGetAssignedAsset({
        employeeId: formDataValues?.id,
        page: 1,
        orderBy: AssignedAssetOrderBy.CREATED_AT_DESC
    });

	const { data: assetTypes } = useGetAssetsType({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc",
	});
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Assigned Assets</h2>
				{!showNewAssetForm && (
					<button
						onClick={() => setShowNewAssetForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Assign New Asset
					</button>
				)}
			</div>
			
			{showNewAssetForm && (
				<NewAsset 
					employeeId={formDataValues?.id}
					assetTypes={assetTypes?.data || []}
					setShowNewAssetForm={setShowNewAssetForm} 
				/>
			)}

			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{employeeAssets?.data?.map((asset) => (
				<EditAsset
					key={asset.id}
					asset={asset}
					assetTypes={assetTypes?.data || []}
				/>
			))}
			
			{(!isLoading && employeeAssets?.data?.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no assets added yet. Click 'Assign New Asset' to begin.
					</h3>
				</div>
			)}
		</div>
	);
};

export default EmployeeAssets;