import React, { useEffect, useRef } from "react";
import { Chart, registerables, ChartConfiguration } from "chart.js";

Chart.register(...registerables);

interface ILineChart {
	dataSet: any[];
}

interface StatusCounts {
	[year: string]: {
		year: string;
		ACTIVE?: number;
		TERMINATED?: number;
		ELAPSED_CONTRACT?: number;
		DISABLED?: number;
		ARCHIVED?: number;
	};
}

const LineChart = ({ dataSet }: ILineChart) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null);
	const chartInstanceRef = useRef<Chart | null>(null);

	// Transform data for the stacked bar chart
	const statusCounts: StatusCounts = dataSet.reduce((acc, employee) => {
		const employmentDate = new Date(employee?.employmentDate);
		const year = employmentDate?.toLocaleString("en-US", { year: "numeric" });
		const status = employee?.status;

		if (!acc[year]) {
			acc[year] = { year };
		}
		acc[year][status] = (acc[year][status] || 0) + 1;

		return acc;
	}, {});

	const statusData = Object.values(statusCounts);

	useEffect(() => {
		const data = {
			labels: statusData.map((item) => item?.year),
			datasets: [
				{
					label: "Active",
					data: statusData.map((item) => item?.ACTIVE || 0),
					borderColor: "#27AE60",
					borderWidth: 1,
					fill: false,
				},
				{
					label: "Terminated",
					data: statusData.map((item) => item?.TERMINATED || 0),
					borderColor: "#EC5557",
					borderWidth: 1,
					fill: false,
				},
				{
					label: "Elapsed",
					data: statusData.map((item) => item?.ELAPSED_CONTRACT || 0),
					borderColor: "#F5DD00",
					borderWidth: 1,
					fill: false,
				},
				{
					label: "Disabled",
					data: statusData.map((item) => item?.DISABLED || 0),
					borderColor: "#B4B7BD",
					borderWidth: 1,
					fill: false,
				},
				{
					label: "Archived",
					data: statusData.map((item) => item?.ARCHIVED || 0),
					borderColor: "#516CB8",
					borderWidth: 1,
					fill: false,
				},
			],
		};

		const options: ChartConfiguration["options"] = {
			plugins: {
				legend: {
					align: "center",
					position: "bottom",
					labels: {
						boxWidth: 7,
						boxHeight: 7,
					},
				},
			},
			scales: {
				x: {
					grid: {
						color: "transparent", // Set grid color to transparent
					},
				},
				y: {
					grid: {
						color: "transparent", // Set grid color to transparent
					},
				},
			},
		};

		if (chartRef.current) {
			if (chartInstanceRef.current) {
				// Destroy the existing chart if it exists
				chartInstanceRef.current.destroy();
			}
			// Create a new chart instance
			chartInstanceRef.current = new Chart(chartRef.current, {
				type: "line",
				data,
				options,
			});
		}

		return () => {
			// Clean up the chart instance when the component unmounts
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, []);

	return (
		<div className="w-full">
			<div style={{ padding: "20px 0", width: "100%", height: "50%" }}>
				<canvas ref={chartRef} />
			</div>
		</div>
	);
};

export default LineChart;
