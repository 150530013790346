import { useQuery } from "react-query";
import API from './utils/api'
import {
    QueryOrganizationAccountDto,
    OrganizationAccountPaginator,
    OrganizationAccountDto
} from "../models/organization-account.dto";
import ENDPOINTS from "./utils/endpoints";
import useUserStore from "../state-management/useUserStore";

export function useGetOrganizationAccount(options?: QueryOrganizationAccountDto) {
    if (useUserStore().userType !== "ORGANIZATION") return null;

    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORGANIZATION_ACCOUNT.GET;
	const request = (options: QueryOrganizationAccountDto) =>
		API.BillingAndSubscriptionAPI.getOrganizationAccount(options);

	const { data, isLoading, error, refetch } = useQuery<OrganizationAccountPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetOrganizationAccountByOrganizationId(id: string) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORGANIZATION_ACCOUNT.DETAIL
    const request = API.BillingAndSubscriptionAPI.getOrganizationAccountByOrganizationId(id);

    const {data, isLoading, error} = useQuery<{ data: OrganizationAccountDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}