import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { EmployeLeaveTypeSummary } from "../../models/leave-type.dto";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.plugins.legend.display = false;

const LeaveDaysChart = ({
	numberOfDaysLeft,
	numberOfDaysPending,
	numberOfDaysUsed,
}: EmployeLeaveTypeSummary) => {
	const data = useMemo(() => {
		if (numberOfDaysLeft) {
			return [numberOfDaysLeft, numberOfDaysPending, numberOfDaysUsed]
		}
		return [100, 0];
	}, [numberOfDaysLeft])
	
	const backgroundColor = useMemo(() => {
		if (numberOfDaysLeft) {
			return ["#27AE60", "#6978F0", "#F2F2F4"]
		}
		return ["#F2F2F4", "#F2F2F4"];
	}, [numberOfDaysLeft])

	const chartData = useMemo(() => {
		return {
			labels: [
				`${numberOfDaysLeft} days leave days`, 
				`${numberOfDaysPending} days pending`, 
				`${numberOfDaysUsed} days used`
			],
			datasets: [ 
				{
					label: "# of days",
					data,
					backgroundColor,
					borderWidth: 1,
					hoverOffset: 4,
				},
			],
		}
	}, [data, backgroundColor])
	
	return (
		<Doughnut
			options={{ cutout: 100 }}
			data={chartData}
		/>
	);
};

export default LeaveDaysChart;
