import React from "react";
import Header from "../header";
import { AiOutlinePlus } from "react-icons/ai";
import { Popper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoAlertCircleOutline } from "react-icons/io5";
import { PATHS } from "../../routes/routes.paths";
import { BiSolidUpArrow } from "react-icons/bi";
import useCompanyStore from "../../state-management/useCompanyStore";

interface CompanySetupLayoutProps {
	children?: React.ReactNode;
	pathname?: string;
}

const CompanySetupLayout = ({
	children,
	pathname,
}: CompanySetupLayoutProps) => {
	const navigate = useNavigate();
	const { savedCompanies } = useCompanyStore();
	const [openPopper, setOpenPopper] = React.useState(true);
	const anchorRef = React.useRef<any>(null);

	// Function to close the Popper
	const handleClose = () => setOpenPopper(false);

	React.useEffect(() => {
		if (pathname === "/dashboard/create-payroll-company") {
			// set a timer to close the Popper after 5 minutes
			const timer = setTimeout(() => {
				handleClose();
			}, 50000);

			// Clean up the timer when the component unmounts
			return () => {
				clearTimeout(timer);
			};
		} else {
			handleClose();
		}
	}, [pathname]);

	const handleAddCompanyClick = () => {
        navigate(PATHS.COMPANY_ACCOUNT_SETUP);
	};

	return (
        <div className={"flex h-[100svh] w-screen relative overflow-y-auto"}>
            <div
                className={`grow ${
                    (pathname === "/dashboard/company-account-setup" ||
                    pathname === "/dashboard/subscription-payment")
                        ? "w-full"
                        : "flex flex-col"
                }`}
            >
                <div className="fixed z-50 w-full">
                    <Header 
                        companies={savedCompanies}
                        onMenuClick={() => {}} 
                    />
                </div>
                <div className="p-10 grow bg-white overflow-x-hidden overflow-y-auto mt-[50px] w-full">
                    {children}
                </div>
            </div>
        </div>
	);
};

export default CompanySetupLayout;
