import { useEffect, useState } from "react";
import EditDisability from "./EditDisability";
import NewDisabilityForm from "./NewDisabilityForm";
import { AiOutlinePlus } from "react-icons/ai";
import { useGetDisabilities } from "../../../../../services/disabilities.service";
import useUserStore from "../../../../../state-management/useUserStore";
import { DisabilitiesDto, DisabilitiesOrderBy } from "../../../../../models/disabilities.dto";
import { SkewLoader } from "react-spinners";
import CustomPagination from "../../../../../components/table/CustomPagination";

const Disabilities = () => {
	const { currentUser } = useUserStore();
	const [showNewDisabilityForm, setShowNewDisabilityForm] = useState(false);
	const [disabilities, setDisabilities] = useState<DisabilitiesDto[]>([]);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [newData, setNewData] = useState("");
	const [reloadData, setReloadData] = useState("");
	
	const handlePageChange = (_, value: number) => setPage(value);

	const { data, isLoading, refetch } = useGetDisabilities({
        organizationId: currentUser.user.organization?.id,
		orderBy: DisabilitiesOrderBy.CREATED_AT_DESC,
		limit: 8,
        page: newData ? 1 : page,
    });

	useEffect(() => {
		if (!isLoading && data?.data) {
			setDisabilities(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
		newData && setPage(1);

        refetch().then((response) => {
            if (response) {
                setDisabilities(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }

			newData && setNewData("");
			reloadData && setReloadData("");
        });
	}, [page, newData, reloadData])
	
	return (
		<div className="mb-4">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Disablities</h2>
				{!showNewDisabilityForm && (
					<button
						onClick={() => setShowNewDisabilityForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Disability
					</button>
				)}
			</div>

			{showNewDisabilityForm && (
				<NewDisabilityForm 
					setShowNewDisabilityForm={setShowNewDisabilityForm}
					setNewData={setNewData}
				/>
			)}

			{(isLoading && !disabilities.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}
	
			{disabilities.map((disability) => (
				<EditDisability 
					key={disability.id}
					disability={disability} 
					setReloadData={setReloadData}
				/>
			))}

			{(!isLoading && !disabilities.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no disabilities added yet. Click 'Add New Disability' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end my-8">
				<CustomPagination 
					pageCount={pageCount} 
					pageState={page}
					handleChange={handlePageChange}
				/>
			</div>
		</div>
	);
};

export default Disabilities;
