import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    NotchDto, NotchPaginator,
    CreateNotchDto,
    UpdateNotchDto, QueryNotchDto
} from "../models/notches.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetNotch(options?: QueryNotchDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.NOTCHES.GET;
    const request = (options: QueryNotchDto) => API.ProcessingCompanyAPI.getNotch(options);

    const { data, isLoading, error, refetch } = useQuery<NotchPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetNotchById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.NOTCHES.DETAIL;
    const request = API.ProcessingCompanyAPI.getNotchById(id);

    const {data, isLoading, error} = useQuery<{ data:NotchDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateNotch() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateNotchDto}) => API.ProcessingCompanyAPI.createNotch(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useUpdateNotch() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateNotchDto, id: number}) => API.ProcessingCompanyAPI.updateNotch(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useDeleteNotch() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteNotch(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}