import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from '../../../helpers/appHelpers';
import { Avatar } from "@mui/material";

export const SavingsColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "applyEmployeeContributionOn",
		headerName: "Apply Employee Contribution On",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "applyEmployerContributionOn",
		headerName: "Apply Employer Contribution On",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	// {
	// 	field: "employeeFixedComponent",
	// 	headerName: "Employee Fixed Component",
	// 	width: 280,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// },
	// {
	// 	field: "employeeRateComponent",
	// 	headerName: "Employee Rate Component",
	// 	width: 280,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => params.value + "%",
	// },
	// {
	// 	field: "employerFixedComponent",
	// 	headerName: "Employer Fixed Component",
	// 	width: 280,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// },
	// {
	// 	field: "employerRateComponent",
	// 	headerName: "Employer Rate Component",
	// 	width: 280,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => params.value + "%",
	// },
	{
		field: "frequency",
		headerName: "Frequency",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "prorate",
		headerName: "Prorate",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},
	{
		field: "statutory",
		headerName: "Statutory",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					<p>
						{params.value === true ? "Yes" : "No"}
					</p>
				</div>
			);
		},
	},

	...createdAtmodifiedAtActions
];

export const BackPayColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 380,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employee",
		headerName: "Employee",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.value?.photoUrl}
						alt="employee"
						className="w-10 h-10 rounded-full"
					/>
					<p>{(params.value?.firstName || "") + " " + (params.value?.lastName || "")}</p>
				</div>
			);
		},
	},
	{
		field: "category",
		headerName: "Category",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{Number(params.value) > 0 ? moneyFormat(params.value) : "--"}
				</div>
			);
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{Number(params.value) > 0 ? (params.value + "%") : "--"}
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];