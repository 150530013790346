import {PaginatorInfo} from "./generic.dto";

type OrganizationRole = {
    id: string;
    organizationId: string;
    name: string;
    category: UserCategory | null;
    primary: boolean;
    permissions: string[];
    active: boolean;
    createdAt: string
    modifiedAt: string | null
}

export interface OrganizationRoleDto extends OrganizationRole { }

export interface OrganizationRolePaginator extends PaginatorInfo<OrganizationRoleDto> {}
export enum UserCategory {
    OPERATIONS = "OPERATIONS",
    EMPLOYEE =  "EMPLOYEE"
}
export class CreateOrganizationRoleDto {
    organizationId!: string;
    name!: string;
    category?: UserCategory;
    permissions!: string[];
    active!: boolean;
}

export class UpdateOrganizationRoleDto {
    name?: string;
    category?: UserCategory;
    permissions?: string[];
    active?: boolean;
}

export class QueryOrganizationRoleDto {
    organizationId?: string;
    category?: UserCategory;
    active?: boolean;
    page?: number = 1;
    limit?: number;
    orderBy?: OrganizationRoleOrderBy = OrganizationRoleOrderBy.NAME_DESC;
}

export enum OrganizationRoleOrderBy {
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
}