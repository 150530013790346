import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../components/modals/DeleteModal";
import ActionPopover from "../../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { useGetAllowance } from "../../../../services/allowance.service";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { BackPayColumns } from "../data";
import BackPayModalForm from "../modals/BackPayModalForm";
import { BACKPAY_CATEGORY, BackPayDto } from "../../../../models/back-pay.dto";
import { useDeleteBackPay, useGetBackPay, useGetBackPaySearch } from "../../../../services/back-pay.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { AllowanceOrderBy } from "../../../../models/allowance.dto";
import { PayPeriodOrderBy } from "../../../../models/pay-period.dto";
import APISearchInput from "../../../../components/search/APISearchInput";
import { EmployeeOrderBy } from "../../../../models/employee.dto";

const BackPayView = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const { mutate: searchBackPays } = useGetBackPaySearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<BackPayDto>({
		fetchHook: useGetBackPay,
		options: { companyId: currentCompany?.id }
	});

	// Fetched data for select fields requiring data from database
	const options = {
        companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
	};

	const { data: employees } = useGetEmployee({
		...options,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: allowances } = useGetAllowance({
		...options,
		active: true,
		orderBy: AllowanceOrderBy.NAME_ASC,
	});
	const { data: payPeriods } = useGetPayPeriod({
		...options,
		orderBy: PayPeriodOrderBy.START_DATE_DESC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteBackPay } = useDeleteBackPay();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteBackPay(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = BackPayColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div className="font-inter">
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchBackPays}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Code",
										fieldName: "code",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
							{
								title: "Employee",
								fieldName: "employeeId",
								fieldType: "SELECT_FIELD",
								options: employees?.data || [],
								mapTexts: ["firstName", "lastName"],
							},
							{
								title: "Category",
								fieldName: "category",
								fieldType: "CHECKBOX_LIST",
								options: BACKPAY_CATEGORY,
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Back Pay</span>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<BackPayModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm ? "Update Back Pay" : "Add New Back Pay"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
                    employees={employees?.data || []}
                    allowances={allowances?.data || []}
                    payPeriods={payPeriods?.data || []}
				/>
			)}
		</div>
	);
};

export default BackPayView;