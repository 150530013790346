import { Popover } from "@mui/material";
import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LeavePlansColumns } from "../data";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { BsCalendar4Week, BsPencil, BsTrash } from "react-icons/bs";
import ConvertPlanModal from "../modals/ConvertPlanModal";
import { LeavePlanDto } from "../../../../models/leave-plan.dto";
import { useGetLeavePlan, useDeleteLeavePlan } from "../../../../services/leave-plan.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import useUserStore from "../../../../state-management/useUserStore";
import LeavePlanModalForm from "../modals/LeavePlanModalForm";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";

const LeavePlansView = () => {
	const currentUser = useUserStore().currentUser.user;
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openConvertPlan, setOpenConvertPlan] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const {
        tableAPIRef,
        tableData,
        isLoading,
        pageCount,
        setTableData,
	} = useManageTableWithSearchParams<LeavePlanDto>({
		fetchHook: useGetLeavePlan,
		options: { employeeId: companyProfile.employeeId as number }
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteLeavePlan } = useDeleteLeavePlan();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteLeavePlan(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = LeavePlansColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex items-center justify-between w-full mb-6">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Intended Start Date",
									fieldName: "intendedStartDate",
								},
								{
									title: "Intended Return Date",
									fieldName: "intendedReturnDate",
								},
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Intended Start Date",
							fieldName: "intendedStartDate",
							fieldType: "DATE_RANGE",
						},
						{
							title: "Intended Return Date",
							fieldName: "intendedReturnDate",
							fieldType: "DATE_RANGE",
						},
					]}
				/>
				<div>
					<OrangeButton
						title={"Plan Leave"}
						className={"px-4 h-12"}
						onClick={handleOpenModal}
					/>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: 172, // Set your desired width
						borderRadius: 8,
						display: "flex",
						justifyContent: "center", // Set your desired border radius
					},
				}}
			>
				<div className="flex flex-col justify-center p-3 text-secondary-darkgray whitespace-nowrap">
					<div
						onClick={() => {
							handleClose();
							setOpenConvertPlan(true);
						}}
						className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
					>
						<BsCalendar4Week />
						<p className="text-2sm">Convert Plan</p>
					</div>
					<div
						onClick={() => {
							setIsEditForm(true);
							setOpenModal(true);
							handleClose();
						}}
						className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
					>
						<BsPencil />
						<p className="text-2sm">Edit</p>
					</div>
					<div
						onClick={() => {
							handleClose();
							setOpenDeleteModal(true);
						}}
						className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
					>
						<BsTrash />
						<p className="text-2sm">Delete</p>
					</div>
				</div>
			</Popover>
			<ConvertPlanModal
				openConvertPlan={openConvertPlan}
				setOpenConvertPlan={setOpenConvertPlan}
				setLeavePlanTableData={setTableData}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<LeavePlanModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={isEditForm ? "Edit Leave Plan" : "Plan Leave"}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</>
	);
};

export default LeavePlansView;
