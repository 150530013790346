import { DepartmentDto } from "./department.dto";
import { EmployeeDto } from "./employee.dto";
import { PaginatorInfo } from "./generic.dto";

type DepartmentLeadership = {
  id: number;
  departmentId: number;
  rank: number;
  permanent: boolean;
  employeeId: number | null;
  createdAt: Date;
  modifiedAt: Date | null;
}
export class CreateDepartmentLeadershipDto {
  departmentId!: number;
  rank!: number;
  permanent?: boolean;
  employeeId?: number;
}

export interface DepartmentLeadershipDto extends DepartmentLeadership {
  department?: DepartmentDto;
  employee?: EmployeeDto;
}

export class UpdateDepartmentLeadershipDto {
  employeeId?: number;
  permanent?: boolean;
  rank?: number;
}

export class QueryDepartmentLeadershipDto {
  departmentId?: number;
  rank?: number;
  permanent?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy: DepartmentLeadershipOrderBy = DepartmentLeadershipOrderBy.CREATED_AT_ASC;
}

export enum DepartmentLeadershipOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  RANK_ASC = 'rank:asc',
  RANK_DESC = 'rank:desc',
}

export interface DepartmentLeadershipPaginator extends PaginatorInfo<DepartmentLeadershipDto> {}