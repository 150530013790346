import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useDeleteCompanyGrouping, useUpdateCompanyGrouping } from "../../../../../services/company-grouping.service";
import { CompanyGroupingDto } from "../../../../../models/company-grouping.dto";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	active: boolean;
}

interface IEditCompanyGroup {
	companyGroup: CompanyGroupingDto;
}

const EditCompanyGroup = ({companyGroup}: IEditCompanyGroup) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateCompanyGroup } = useUpdateCompanyGrouping();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: companyGroup.name,
			code: companyGroup.code,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateCompanyGroup(
			{ payload: data, id: companyGroup.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCompanyGroup } = useDeleteCompanyGrouping();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCompanyGroup(
			{ id: companyGroup?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={companyGroup.id + "bh-content"}
				id={companyGroup.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{companyGroup.name}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: companyGroup.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors?.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.code?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Name" for={`name`} />
								<input
									defaultValue={companyGroup.name}
									disabled={formDisabled}
									placeholder={"Enter Name"}
									type="text"
									{...register(`name`, {
										required: "Enter name here",
									})}
									id={`name`}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.name && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.name?.message}
									</p>
								)}
							</div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									defaultChecked={companyGroup.active === true}
									disabled={formDisabled}
									type="checkbox"
									{...register("active", {
										required: false
									})}
									id="active"
								/>
								<label htmlFor="active">Active</label>
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={companyGroup.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditCompanyGroup;
