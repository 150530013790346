import { UseFormRegister } from "react-hook-form";

interface IFormCodeInput {
    attributes: React.InputHTMLAttributes<HTMLInputElement>; 
    register: UseFormRegister<any>;
    required?: boolean | string;
}
const FormCodeInput = ({
    attributes, 
    register, 
    required, 
}: IFormCodeInput) => {
    // const [remainingChars, setRemainingChars] = useState(30);

    // const charactersLeft = () => {
    //     const inputFieldWrapper = document.querySelector(".code-field-wrapper") as HTMLDivElement;
    //     const inputField = inputFieldWrapper.querySelector("input") as HTMLInputElement;

    //     const remaining = 30 - inputField.value.length;
    //     setRemainingChars(remaining);
    // };

    // useEffect(() => charactersLeft(), []);

    return (  
        <div className="w-full code-field-wrapper relative">
            <input
                id="code"
                type="text"
                placeholder={"Enter code"}
                maxLength={30}
                {...register("code", {
                    required: required ? required : "Enter unique code here",
                    pattern: {
                        value: /^[a-zA-Z0-9]*$/,
                        message: "Only alphanumeric characters are allowed"
                    }
                })}
                // onInput={charactersLeft}
                { ...attributes }
            />
            {/* <div className="text-xs mt-1">{remainingChars} characters left</div> */}
        </div>
    );
}
 
export default FormCodeInput;