import {PaginatorInfo} from "./generic.dto";

export const OVERTIME_PAYMENT_TIER_TYPE = {
  FIXED: 'FIXED',
  PER_HOUR: 'PER_HOUR',
  BY_HOURLY_WAGE: 'BY_HOURLY_WAGE'
};

export type OVERTIME_PAYMENT_TIER_TYPE = keyof typeof OVERTIME_PAYMENT_TIER_TYPE

type OvertimePaymentTier = {
  id: number
  overtimeId: number
  type: OVERTIME_PAYMENT_TIER_TYPE
  fixedComponent: number;
  factorComponent: number
  minHours: number
  maxHours: number
  currencyId: number
  createdAt: string
  modifiedAt: string | null
}

export class CreateOvertimePaymentTierDto {
  overtimeId!: number;
  type!: OVERTIME_PAYMENT_TIER_TYPE;
  fixedComponent!: number;
  factorComponent!: number;
  minHours!: number;
  maxHours!: number;
  currencyId!: number;
}

export class UpdateOvertimePaymentTierDto {
  overtimeId?: number;
  type?: OVERTIME_PAYMENT_TIER_TYPE;
  fixedComponent?: number;
  factorComponent?: number;
  minHours?: number;
  maxHours?: number;
  currencyId?: number;
}

export interface OvertimePaymentTierDto extends OvertimePaymentTier { }

export interface OvertimePaymentTierPaginator extends PaginatorInfo<OvertimePaymentTierDto> {}

export class QueryOvertimePaymentTierDto {
  overtimeId?: number;
  type?: OVERTIME_PAYMENT_TIER_TYPE;
  minHours?: number;
  maxHours?: number;
  page?: number = 1;
  limit?: number;
  orderBy?: OvertimePaymentTierOrderBy = OvertimePaymentTierOrderBy.CREATED_AT_ASC;
}

export enum OvertimePaymentTierOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  MIN_HOURS_ASC = 'minHours:asc',
  MIN_HOURS_DESC = 'minHours:desc',
  MAX_HOURS_ASC = 'maxHours:asc',
  MAX_HOURS_DESC = 'maxHours:desc',

}