import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import APISelectField from "../../../../components/form_fields/APISelectField";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import { EmployeeDto } from "../../../../models/employee.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import useUserStore from "../../../../state-management/useUserStore";
import { useCreatePartialSalary, useUpdatePartialSalary } from "../../../../services/partial-salary.service";
import { PATHS } from "../../../../routes/routes.paths";

interface IFormInputs {
	employeeId: number;
	startPayPeriodId: number;
	amount: number;
	expired?: boolean;
}

type PartialSalaryModalFormProps = IAddEditTableForm & {
	payPeriods: PayPeriodDto[];
	employees: EmployeeDto[];
}

const PartialSalaryModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	payPeriods,
	employees,
}: PartialSalaryModalFormProps) => {
	const { userType } = useUserStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			amount: isEditForm ? formDataValues?.amount : "",
			startPayPeriodId: isEditForm ? formDataValues?.startPayPeriodId : "",
			expired: isEditForm ? formDataValues?.expired : undefined,
		}
	});

	const { mutate: createPartialSalary } = useCreatePartialSalary();
	const { mutate: updatePartialSalary } = useUpdatePartialSalary();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updatePartialSalary(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			delete data.expired;

			createPartialSalary(
				{ payload: data },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Employee" for="employeeId" />
							<APISelectField 
								attributes={{
									id: "employeeId",
								}}
								placeholder="Select Employee"
								register={register}
								name="employeeId"
								options={employees}
								mapTexts={["firstName", "lastName"]}
								pathname={PATHS.EMPLOYEES}
								required="Select employee here"
								error={errors.employeeId}
								doNotLinkToResource={userType === "HR"}
								valueAsNumber
							/>
							{errors.employeeId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Start Pay Period" for="startPayPeriodId" />
						<APISelectField 
							attributes={{
								id: "startPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="startPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.COMPANY_SETTINGS + "?view=payPeriods"}
							required="Select start pay period here"
							error={errors.startPayPeriodId}
							doNotLinkToResource={userType === "HR"}
							valueAsNumber
						/>
						{errors.startPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startPayPeriodId.message}
							</p>
						)}
					</div>
					<div className={`w-full ${!isEditForm ? "col-span-2" : ""}`}>
						<Label title="Amount" for="amount" />
						<MoneyInput 
							attributes={{
								id: "amount",
								placeholder: "0"
							}}
							register={register}
							name="amount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues.amount : undefined}
							required="Enter amount here"
							error={errors.amount}
						/>
						{errors.amount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.amount.message}
							</p>
						)}
					</div>
					{isEditForm && (
						<div className="col-span-2 flex space-x-3 items-center">
							<input 
								type="checkbox"
								{...register("expired", {
									required: false,
								})}
								id="expired"
							/>
							<label htmlFor="expired">Expired</label>
						</div>
					)}
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Partial Salary"
								: "Create Partial Salary"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default PartialSalaryModalForm;
