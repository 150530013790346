import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 230,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "countryCode",
		headerName: "Country Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];
