import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    PayrollCompanyDto, CompanyPaginator,
    CreatePayrollCompanyDto,
    UpdatePayrollCompanyDto, QueryPayrollCompanyDto, CountPayrollCompanyDto
} from "../models/company-account.dto";
import ENDPOINTS from "./utils/endpoints";
import {ExampleDto} from "../models/example.dto";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetCompany(options?: QueryPayrollCompanyDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET;
    const request = (options: QueryPayrollCompanyDto) => API.ProcessingCompanyAPI.getCompany(options);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<CompanyPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return API.Handlers.paginationHandler(data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage)
}

export function useGetCompanyCount(options?: CountPayrollCompanyDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET_COUNT;
    const request = API.ProcessingCompanyAPI.getCompanyCount(options);

    const {data, isLoading, error} = useQuery<{ data: { count: number} }, Error>(
        [url, options],
        (() => request),
    );

    return {
        data,
        isLoading,
        error,
    };
}

export function useGetPayrollCompanyById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY.DETAIL;
    const request = API.ProcessingCompanyAPI.getCompanyById(id);

    const {data, isLoading, error} = useQuery<{ data:PayrollCompanyDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
};

export function useCreateCompany() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreatePayrollCompanyDto}) => API.ProcessingCompanyAPI.createCompany(payload),
        {
            onSuccess: () => {

            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET);
            },
        }
    )
}

export function useUpdateCompany() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdatePayrollCompanyDto, id: number}) => API.ProcessingCompanyAPI.updateCompany(id, payload),
        {
            onSuccess: () => {
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET);
            },
        }
    )
}

export function useDeletePayrollProcessingCompany() {
    
    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteCompany(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}