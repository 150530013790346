import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateErpIntegrationMappingDto,
    ErpIntegrationMappingDto,
    ErpIntegrationMappingPaginator,
    QueryErpIntegrationMappingDto,
    SearchErpIntegrationMappingDto,
    UpdateErpIntegrationMappingDto,
} from "../models/erp-integration-mapping.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetErpIntegrationMapping(options?: QueryErpIntegrationMappingDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.GET;
    const request = (options: QueryErpIntegrationMappingDto) => API.ProcessingCompanyAPI.getErpIntegrationMapping(options);


	const { data, isLoading, error, refetch } = useQuery<ErpIntegrationMappingPaginator, Error>(
		[url, options],
		({ queryKey, pageParam }) =>
			request(Object.assign({}, queryKey[1], pageParam)),
		{...API.Handlers.pageParamOption}
	);

	return { data, isLoading, error, refetch };
}

export function useGetErpIntegrationMappingSearch() {

    return useMutation(
        (options?: SearchErpIntegrationMappingDto) => API.ProcessingCompanyAPI.getErpIntegrationMappingSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetErpIntegrationMappingById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.DETAIL;
    const request = API.ProcessingCompanyAPI.getErpIntegrationMappingById(id);

    const {data, isLoading, error} = useQuery<{ data: ErpIntegrationMappingDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateErpIntegrationMapping() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateErpIntegrationMappingDto}) => API.ProcessingCompanyAPI.createErpIntegrationMapping(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.GET);
            },
        }
    )
}

export function useUpdateErpIntegrationMapping() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateErpIntegrationMappingDto, id: number}) => API.ProcessingCompanyAPI.updateErpIntegrationMapping(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.GET);
            },
        }
    )
}

export function useDeleteErpIntegrationMapping() {

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteErpIntegrationMapping(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}