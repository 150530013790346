import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../components/form_fields/Label";
import { Helmet } from "react-helmet-async";
import { useCreateOrganization } from "../../services/organization.service";
import { useGetCountry } from "../../services/country.service";
import { APP_CONFIG } from "../../helpers/appHelpers";
import { CountryOrderBy } from "../../models/country.dto";
import { useAccountCheck } from "../../services/user.service";
import { useEffect, useState } from "react";

interface IFormInputs {
	name: string;
    countryId: number;
    billingTypeId: number;
    subscriptionTierId?: string;
    contactEmail: string;
	adminProfile: {
		username: string;
		emailAddress: string;
		firstName?: string;
		lastName?: string;
	};
}

const billingTypeId = process.env.REACT_APP_BILLING_TYPE_ID;
const subscriptionTierId = process.env.REACT_APP_SUBSCRIPTION_TIER_ID;

const Signup = () => {
	const navigate = useNavigate();
	const { 
		mutate: createOrganization, 
		isLoading: creatingOrganization 
	} = useCreateOrganization();
	const [usernameExists, setUsernameExists] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			billingTypeId: Number(billingTypeId),
			subscriptionTierId: subscriptionTierId,
		}
	});

	const username = watch("adminProfile.username");
	const { mutate: validateUsername } = useAccountCheck();

	useEffect(() => {
		if (!username?.trim()) {
			setUsernameExists(false);
			return
		}

		validateUsername(
			{ payload: { username: username.trim() } },
			{ onSuccess: (data) => setUsernameExists(data.data.exists) }
		)
	}, [username])
	
	const { data: countries } = useGetCountry({
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CountryOrderBy.NAME_ASC,
	});

	const signup: SubmitHandler<IFormInputs> = (data: any) => {
		if (usernameExists) return;

		createOrganization(
			{ 
				payload: { 
					...data, 
					contactEmail: data.adminProfile.emailAddress 
				} 
			},
			{
				onSuccess: () => {
					navigate(`/signup-confirmation?username=${data.adminProfile.username}`);
				},
				onError: () => {},
				onSettled: () => {}
			}
		)
	};

	return (
		<OnboardingLayout>
			<Helmet>
				<title>Signup | Akatua</title>
			</Helmet>
			<div className="flex flex-col items-center justify-center w-full overflow-y-auto my-16 lg:my-0">
				<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />
				<h1 className="mt-5 font-bold text-xl text-charcoal">
					Hey 👋, welcome to Akatua
				</h1>
				<form onSubmit={handleSubmit(signup)} className="text-charcoal lg:w-[65%] w-[90%] mt-4">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
						<div className="w-full">
							<Label title="First Name" for="firstName" />
							<input
								type="text"
								{...register("adminProfile.firstName", {
									required: "Enter your first name",
								})}
								id="firstName"
								placeholder="Enter first name"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.adminProfile?.firstName
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.firstName && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.firstName.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Last Name" for="lastName" />
							<input
								type="text"
								{...register("adminProfile.lastName", {
									required: "Enter your last name",
								})}
								id="lastName"
								placeholder="Enter last name"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.adminProfile?.lastName
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.lastName && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.lastName.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Username" for="userName" />
							<input
								type="text"
								{...register("adminProfile.username", {
									required: "Enter your username"
								})}
								id="userName"
								placeholder="Enter username"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.adminProfile?.username
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{(!usernameExists && errors.adminProfile?.username) && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.username.message}
								</p>
							)}
							{usernameExists && (
								<p className="text-red-500 text-sm mt-1">
									Username already taken
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Email" for="email" />
							<input
								type="email"
								{...register("adminProfile.emailAddress", {
									required: "Enter your email",
									pattern: {
										value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
										message: "Enter a valid email address",
									},
								})}
								id="email"
								placeholder="yourname@email.com"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.adminProfile?.emailAddress
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.adminProfile?.emailAddress && (
								<p className="text-red-500 text-sm mt-1">
									{errors.adminProfile.emailAddress.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Organization Name" for="organizationName" />
							<input
								type="text"
								{...register("name", {
									required: "Enter your organization name",
								})}
								id="organizationName"
								placeholder="Enter organization name"
								className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
									errors.name
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							{errors.name && (
								<p className="text-red-500 text-sm mt-1">
									{errors.name.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries?.data?.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					</div>
					<div className="lg:w-[50%]">
						<p className="text-xs">
							By signing up you agree to the{" "}
							<span className="text-primary-blue">Terms and Conditions</span>
						</p>
						<OrangeButton
							type="submit"
							title={creatingOrganization ? "Creating Account..." : "Create Account"}
							className="w-full mt-10"
							disabled={creatingOrganization}
						/>
						<p className="text-xs mt-6">
							Already have an account?{" "}
							<span className="text-primary-blue">
								<Link to="/signin">Sign in</Link>
							</span>
						</p>
					</div>
				</form>
			</div>
		</OnboardingLayout>
	);
};

export default Signup;