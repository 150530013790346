import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "level",
		headerName: "Level",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "minimumBasicPay",
		headerName: "Minimum Basic Pay",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{moneyFormat(params.value)}
				</div>
			);
		},
	},
	{
		field: "maximumBasicPay",
		headerName: "Maximum Basic Pay",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{moneyFormat(params.value)}
				</div>
			);
		},
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	
	...createdAtmodifiedAtActions
];
