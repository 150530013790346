import { Box } from "@mui/material";
import { StyledDataGrid } from "../../../../helpers/appHelpers";
import { Columns } from "./data";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BsDownload } from "react-icons/bs";
import CustomPagination from "../../../../components/table/CustomPagination";

const PayslipsTable = ({ tableData }) => {
	const handleViewAttachments = (param) => {
		// create a link to open it in the browser
		const pdfUrl = param; // Replace with the actual URL of PDF from the backend

		// Create an anchor element to open the PDF in the browser
		const link = document.createElement("a");
		link.href = pdfUrl;
		link.target = "_blank"; // Open the link in a new browser tab/window
		link.click();
	};
	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={
							<div className="flex items-center text-primary-blue space-x-4 text-2sm">
								<BsDownload className="font-bold" />
								<p>Download</p>
							</div>
						}
						label="actions"
						onClick={(e: any) => {
							handleViewAttachments(params.row.pdfUrl);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<Box
			sx={{
				height: "580px",
				backgroundColor: "background.paper",
				borderRadius: 4,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
				"& .super-app-theme--header": {
					backgroundColor: "#F2F2F4",
					fontWeight: "bold",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
					width: "11px",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
					background: "#f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
					backgroundColor: "#e2e8f0",
					borderRadius: "6px",
					border: "3px solid #f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
					backgroundColor: "#e2e8f0",
					borderRadius: "7px",
					border: "3px solid #f7fafc",
				},
			}}
		>
			<StyledDataGrid
				rows={[]}
				rowHeight={65}
				columnHeaderHeight={70}
				columns={columnsWithAdditions}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 7,
						},
					},
					columns: {
						columnVisibilityModel: {
							id: false,
						},
					},
				}}
				slots={{
					pagination: CustomPagination,
				}}
				pageSizeOptions={[7]}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
				}
				// apiRef={employeesTableAPIRef}
				getRowId={(row) => row.id}
			/>
		</Box>
	);
};

export default PayslipsTable;




		// To create a custom PDF Viewer in the browser in a new window every time
		// Step 1: Open a new window or tab with the PDF viewer for the selected row
		// const newWindow = window.open("", "_blank");
		// newWindow?.document.write(
		// 	'<html><head><title>Custom PDF Viewer</title></head><body style="margin: 0; padding: 0;">'
		// );

		// Step 2: Styling the container and title
		// newWindow?.document.write(
		// 	'<div style="text-align: center; background-color: #F58B00; padding: 20px; height: 100vh;">'
		// );
		// newWindow?.document.write(
		// 	'<h1 style="color: #333;">Custom PDF Viewer</h1>'
		// );

		// // Adding the PDF iframe
		// newWindow?.document.write(
		// 	'<iframe style="height: 700; color: #F2F2F4" width="800" src="' +
		// 		param +
		// 		'"></iframe>'
		// );

		// // Closing HTML tags
		// newWindow?.document.write("</div>");
		// newWindow?.document.write("</body></html>");

		// newWindow?.document.close();