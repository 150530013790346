//@ts-ignore
import axios, { InternalAxiosRequestConfig } from "axios";
import { getAccessToken } from "./app.data";
import { PATHS } from "../../routes/routes.paths";

export const axiosInstance = axios.create({
    //baseURL: process.env.HOST_API || "https://trace.lucidarray.dev/",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = getAccessToken();
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(req => {
    //@ts-ignore
    req.meta = req.meta || {}, req.meta.requestStartedAt = new Date().getTime();
    return req;
});

axiosInstance.interceptors.response.use(res => {
    //@ts-ignore
    console.log(`Execution time for: ${res.config.url}[${res.config.method}] - ${millisToMinutesAndSeconds(new Date().getTime() - res.config.meta.requestStartedAt)}`)
    return res;
},
    res => {
        console.warn(`Execution time for: ${res.config.url}[${res.config.method}] - ${millisToMinutesAndSeconds(new Date().getTime() - res.config.meta.requestStartedAt)}`)
        throw res;
    });

export class HttpClient {
    static async get<T>(url: string, params?: any) {
        const response = await axiosInstance.get<T>(url, { params });
        return response.data;
    }

    static async post<T>(url: string, data: any, options?: any) {
		filterPayloadData(data);

        const response = await axiosInstance.post<T>(url, data, options);
        return response.data;
    }

    static async postFile<T>(url: string, data: any, options?: any) {
		// filterPayloadData(data);
        const token = getAccessToken();

        const response = await axios.post<T>(url, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
                ...options, 
            }
        });
        return response.data;
    }

    static async put<T>(url: string, data: any) {
		filterPayloadData(data);

        const response = await axiosInstance.put<T>(url, data);
        return response.data;
    }

    static async patch<T>(url: string, data: any) {
		filterPayloadData(data);

        const response = await axiosInstance.patch<T>(url, data);
        return response.data;
    }

    static async delete<T>(url: string, params?: any) {
        const response = await axiosInstance.delete<T>(url, { params });
        return response.data;
    }
}

function millisToMinutesAndSeconds(millis: number) {
    const date = new Date(millis);
    return (`${date.getMinutes()}:${date.getSeconds()}`);
}

function filterPayloadData(data: any) {
    if (data) {
		Object.entries(data)?.forEach(([key, value]) => {
            if (typeof value === "string") {
                data[key] = value.trim()
            }
			if (value !== 0 && value !== false && !value) {
				delete data[key];
			}
		});
    }
}