import {PaginatorInfo} from "./generic.dto";

type ContractType = {
  id: number
  organizationId: string
  code: string
  name: string
  description: string
  considerEmployee: boolean
  fixedTerm: boolean
  postRetirement: boolean
  suspendOnExpiration: boolean
  active: boolean
  createdAt: string
  modifiedAt: string | null
}

export class CreateContractTypeDto {
  organizationId!: string;
  code!: string;
  active!: boolean;
  name!: string;
  description!: string;
  considerEmployee!: boolean;
  fixedTerm!: boolean;
  postRetirement!: boolean;
  suspendOnExpiration!: boolean;
}


export class UpdateContractTypeDto {
  code?: string;
  active?: boolean;
  name?: string;
  description?: string;
  considerEmployee?: boolean;
  fixedTerm?: boolean;
  postRetirement?: boolean;
  suspendOnExpiration?: boolean;
}

export interface ContractTypeDto extends ContractType { }



export class QueryContractTypeDto {
  q?: string;
  organizationId?: string;
  code?: string;
  active?: boolean;
  considerEmployee?: boolean;
  fixedTerm?: boolean;
  postRetirement?: boolean;
  suspendOnExpiration?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy?: ContractTypeOrderBy = ContractTypeOrderBy.CREATED_AT_ASC;
}

export enum ContractTypeOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  CODE_ASC = 'code:asc',
  CODE_DESC = 'code:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc',
}

export interface ContractTypePaginator extends PaginatorInfo<ContractTypeDto> {}