import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, LinearProgress } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { BsFillFileEarmarkTextFill, BsUpload } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { 
	usePostUpdateToReimbursementRequest, 
	useRespondToReimbursementRequest,
} from "../../../../services/reimbursement-request.service";
import { uploadOneDocument } from "../../../../services/document.service";
import { REIMBURESEMENT_REQUEST_STATUS } from "../../../../models/reimbursement-request.dto";
import { useSearchParams } from "react-router-dom";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

interface IFormInputs {
    action: string;
    comment?: string;
}

type FileStatus = "success" | "error" | "uploading" | ""

type RequestActionModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	supervisor: boolean;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const RequestActionModal =  ({ 
	open, 
	setOpen, 
	supervisor, 
	tableAPIRef, 
}: RequestActionModalProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
    const requestId = searchParams.get("requestId");
	const { formDataValues, setFormData } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			action: supervisor ? getCurrentAction(formDataValues?.status) : undefined
		}
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".png", ".jpeg", ".jpg", ".heic", ".svg"],
			"text/pdf": [".pdf"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid file (pdf, png, jpeg, jpg, heic or svg)");
		},
	});

	const { mutate: respondToRequest } = useRespondToReimbursementRequest();
	const { mutate: postUpdateToRequest } = usePostUpdateToReimbursementRequest();

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		let attachmentUrls: string[] = [];
		if (file) {
			setFileStatus("uploading");

			const formData = new FormData();
			formData.append("file", file);

			const uploadedFile = await uploadOneDocument(formData);
			const fileUrl = uploadedFile?.data?.url

			if (fileUrl) {
				attachmentUrls.push(fileUrl);
				setFileStatus("success");
			} else {
				setFileStatus("error");
				setUploading(false);
				return
			}
		}

        const payload: any = {
			comment: data.comment,
			attachmentUrls: attachmentUrls.length ? attachmentUrls : undefined
		}

		// This check: "formDataValues?.status !== data.action" is made to avoid responding with an existing value
        if (supervisor && getCurrentAction(formDataValues?.status) !== data.action) {
			payload.action = data.action;

			respondToRequest(
				{ payload, id: formDataValues?.id },
				{
                    onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setFormData({ ...formDataValues, ...updatedData.data });
						handleClose();
					},
					onSettled: () => setUploading(false)
				}
			)

		} else {
			postUpdateToRequest(
				{ payload, id: formDataValues?.id },
				{
                    onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setFormData({ ...formDataValues, ...updatedData.data });
						handleClose();
					},
					onSettled: () => setUploading(false)
				}
			)
		}
	};

	const handleClose = () => {
		setFile(null);
		setOpen(false);
		reset();

		if (requestId) {
			const currentParams = new URLSearchParams(window.location.search);
			currentParams.delete("requestId");
			setSearchParams(currentParams);
		}
	};

	return (
		<ModalComponent
			width={660}
			open={open}
			title="Take Action on Claim"
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-y-6">
                    {supervisor && (
                        <div className="w-full">
                            <Label title="Action" for="action" />
                            <select
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.action
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                {...register("action", {
                                    required: "Enter action here"
                                })}
                            >
                                <option value="">Select Action</option>
                                <option value="APPROVE">Approve</option>
                                <option value="REJECT">Reject</option>
                                <option value="QUERY">Query</option>
                            </select>
                            {errors.action && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.action?.message}
                                </p>
                            )}
                        </div>
                    )}
                    <div className="w-full">
                        <Label title="Comment" for="comment" optional />
                        <input
                            placeholder={"Enter comment"}
                            type="text"
                            {...register("comment", {
                                required: false,
                            })}
                            id="comment"
                            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
                        />
                    </div>
				</div>
                <div className="w-full mt-8">
                    <Label title="Attached File" for="" optional />
                    <div
                        {...getRootProps()}
                        className="mt-2 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[100px] w-full flex 
                        flex-col justify-center m-auto items-center text-primary-blue bg-[#26A0F91A] text-xs cursor-pointer"
                    >
                        <input {...getInputProps()} />
                        <BsUpload className="text-xl mb-2" />
                        <p>
                            Upload receipt or proof 
                            <span className="font-semibold"> (2mb max)</span>
                        </p>
                        <p>(pdf, png, jpeg, jpg, heic or svg)</p>
                    </div>
                </div>
				{file && (
					<div className="flex items-center space-x-4 w-full mt-1">
						<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
						<div className="flex flex-col items-start w-full">
							<div className="flex justify-between items-center w-full mb-1">
								<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
									{file.name}
								</p>
								<button onClick={() => {
									setFile(null);
									setFileStatus("");
								}}>
									<IoMdClose className="text-xl hover:scale-[1.1]" />
								</button>
							</div>
							<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
								<LinearProgress
									color={fileStatus === "error" ? "error" : "primary"}
									variant={"determinate"}
									value={fileStatus ? 100 : 0}
								/>
							</Box>
							<div className="flex justify-between text-xs w-full">
								<p>
									File size: {(file.size / 1000000).toFixed(2)}mb
								</p>
								{fileStatus === "success" ? (
									<span className="text-green-500 text-xs">
										File uploaded successfully
									</span>
								) : fileStatus === "error" ? (
									<span className="text-red-500 text-xs">
										File upload error! Try again
									</span>
								) : null}
							</div>
						</div>
					</div>
				)}
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton 
						type="submit"
						title={
							uploading 
							? (fileStatus === "uploading") 
                                ? "Uploading File..." 
                                : "Submitting..." 
							: "Submit"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading} 
					/>
				</div>
			</form>
		</ModalComponent>
	);
};
 
export default RequestActionModal;

const getCurrentAction = (status: REIMBURESEMENT_REQUEST_STATUS) => {
	switch(status) {
		case "APPROVED":
			return "APPROVE"
		case "REJECTED":
			return "REJECT"
		case "QUERIED":
			return "QUERY"
		default:
			return ""
	}
}