import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    TaxReliefDto, TaxReliefPaginator,
    CreateTaxReliefDto, QueryTaxReliefDto,
    UpdateTaxReliefDto
} from "../models/tax-relief.dto";
import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";

export function useGetTaxRelief(options?: QueryTaxReliefDto, queryOptions?: any) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.GET;
    const request = (options: QueryTaxReliefDto) => API.ProcessingCompanyAPI.getTaxRelief(options);

    const { data, isLoading, error, refetch } = useQuery<TaxReliefPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, ...queryOptions}
    );

    return { data, isLoading, error, refetch };
}

export function useGetTaxReliefById(id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.DETAIL;
    const request = API.ProcessingCompanyAPI.getTaxReliefById(id);

    const {data, isLoading, error} = useQuery<{ data:TaxReliefDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateTaxRelief(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateTaxReliefDto}) => API.ProcessingCompanyAPI.createTaxRelief(payload),
        {
            onSuccess: (response,) => {

                if(onSuccessCallback){
                    onSuccessCallback(response)
                }

                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.GET);
            },
        }
    )
}

export function useUpdateTaxRelief() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateTaxReliefDto, id: number}) => API.ProcessingCompanyAPI.updateTaxRelief(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.GET);
            },
        }
    )
}

export function useDeleteTaxRelief() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id}: {id: number}) => API.ProcessingCompanyAPI.deleteTaxRelief(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: (error, variables, context)=> {
                console.log(error);
                toast.error('An error occurred whiles running create/update');
            },
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.GET);
            },
        }
    )
}
