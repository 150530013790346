import React, { createContext } from "react";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { Helmet } from "react-helmet-async";
import LeaveDaysChart from "../../../components/charts/LeaveDaysDoughnut";
import LeaveRequestsView from "./views/LeaveRequestsView";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { TimeManagementTabColumns } from "../../../components/custom_tab/tabColumns";
import LeavePlansView from "./views/LeavePlansView";
import { useSearchParams } from "react-router-dom";
import useUserStore from "../../../state-management/useUserStore";
import { LeaveTypeDto } from "../../../models/leave-type.dto";
import { IoCaretDown } from "react-icons/io5";
import SuperviseesLeaveRequestsView from "./views/SuperviseesLeaveRequestsView";
import { useManageLeaveSummary } from "../dashboard/hooks/useManageLeaveSummary";

export const ApplicableLeaveTypeContext = createContext<LeaveTypeDto[]>([]);

const SelfserviceTimeMgmt = () => {
    const { currentUser, supervisor } = useUserStore();
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "leaveRequestsView";
	const [view, setView] = React.useState(currentView);

	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const {
        leaveTypes,
        leaveTypeSummary,
        activeLeaveType,
        dropdownOpen,
        setDropdownOpen,
        handleLeaveTypeMenu,
    } = useManageLeaveSummary(
		companyProfile.employeeId as number
	);
		
	return (
		<SelfserviceLayout pageHeader="Time Management">
			<Helmet>
				<title>Time Management Flow | Self Service Flow</title>
			</Helmet>
			<div className="lg:h-[308px] border mb-10 lg:mb-16 rounded-lg flex flex-col lg:flex-row items-center lg:px-12 py-10 relative">
				{activeLeaveType && (
					<div className="absolute right-4 top-3 z-[99] whitespace-nowrap max-lg:text-2sm">
						<button 
							onClick={() => setDropdownOpen(prev => !prev)} 
							className="focus:outline-none flex items-center gap-1 font-medium"
						>
							<span className="max-w-[120px] truncate">{activeLeaveType.name}</span>
							<IoCaretDown />
						</button>
						{dropdownOpen && (
							<div className="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
								<div className="py-1 flex flex-col">
									{(leaveTypes as LeaveTypeDto[]).map((leaveType) => (
										<button 
											key={leaveType.id}
											className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
												${activeLeaveType.id === leaveType.id 
													? "bg-gray-100" 
													: "hover:bg-gray-100"
												}
											`} 
											role="menuitem"
											onClick={() => handleLeaveTypeMenu(leaveType)}
										>
											{leaveType.name}
										</button>
									))}
								</div>
							</div>
						)}
					</div>
				)}
				<div className="h-[280px] flex flex-col items-center relative">
					<LeaveDaysChart {...leaveTypeSummary} />

					{leaveTypeSummary.numberOfDaysLeft && (
						<div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center space-y-2">
							<h1 className="text-[40px] font-bold">
								{leaveTypeSummary.numberOfDaysLeft}
							</h1>
							<p className="text-sm">Leave days left</p>
						</div>
					)}
				</div>
				<div className="space-y-4 mt-6 lg:ml-14 text-2sm font-inter font-light">
					<div className="flex items-center space-x-2">
						<div className="h-3.5 w-3.5 bg-[#27AE60] rounded-full" />
						<p>
							<span className="font-bold">
								{leaveTypeSummary?.numberOfDaysLeft || "--"}
							</span>
							<span> days left</span>
						</p>
					</div>
					<div className="flex items-center space-x-2">
						<div className="h-3.5 w-3.5 bg-[#6978F0] rounded-full" />
						<p>
							<span className="font-bold">
								{leaveTypeSummary?.numberOfDaysPending || "--"}
							</span>
							<span> days pending</span>
						</p>
					</div>
					<div className="flex items-center space-x-2">
						<div className="h-3.5 w-3.5 bg-[#e0e0e1] rounded-full" />
						<p>
							<span className="font-bold">
								{leaveTypeSummary?.numberOfDaysUsed || "--"}
							</span>
							<span> days used</span>
						</p>
					</div>
				</div>
			</div>
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={
					supervisor 
					? TimeManagementTabColumns
					: TimeManagementTabColumns.slice(0, 2)
				}
			>
				<ApplicableLeaveTypeContext.Provider value={leaveTypes}>
					<div className="pb-8 mt-6">
						{view === "leaveRequestsView" && <LeaveRequestsView />}
						{view === "leavePlansView" && <LeavePlansView />}
						{(view === "superviseesLeaveRequestsView" && supervisor) && (
							<SuperviseesLeaveRequestsView />
						)}
					</div>
				</ApplicableLeaveTypeContext.Provider>
			</CustomTab>
		</SelfserviceLayout>
	);
};

export default SelfserviceTimeMgmt;
