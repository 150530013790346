import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { EmployeeBandOrderBy } from "../../../../models/employee-band.dto";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import { CompanyPayslipColumns } from "../data";
import { Popover } from "@mui/material";
import { IoCaretDown } from "react-icons/io5";

const CompanyPayslips = () => {
	const { currentCompany } = useCompanyStore();
	const tableAPIRef = useGridApiRef();
	const [tableData, setTableData] = useState<any[]>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [payPeriodAnchorEl, setPayPeriodAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [activePayPeriod, setActivePayPeriod] = useState<any>({
        id: 1,
        name: "2022112", 
        startDate: "2022/02/11",
        endDate: "2022/03/10",
    });
	const { formDataValues, setFormData } = useEditFormStore();
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setOpenModal(true);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];

	const columnsWithAdditions = CompanyPayslipColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
                    <button
                        onClick={(e: any) => setPayPeriodAnchorEl(e.currentTarget)}
                        className="relative border border-gray-300 rounded-lg text-2xs py-3 px-3 flex items-center gap-3"
                    >
                        <div className="absolute top-0 -translate-y-1/2 left-2 px-2 bg-white text-[11px]">Pay Period</div>
                        <span className="max-w-[12 0px] truncate">
                            {activePayPeriod?.name} ({activePayPeriod?.startDate} - {activePayPeriod?.endDate})
                        </span>
                        <IoCaretDown />
                    </button>
                    <Popover
                        open={Boolean(payPeriodAnchorEl)}
                        anchorEl={payPeriodAnchorEl}
                        onClose={() => setPayPeriodAnchorEl(null)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        PaperProps={{
                            style: {
                                height: "auto",
                                maxHeight: "500px",
                                fontSize: "15px",
                                color: "#4F4F4F",
                                borderRadius: 10,
                                background: "white",
                                boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                                overflow: "hidden",
                            }
                        }}
                    >
                        <div className="py-1 flex flex-col">
                            {demoPayPeriods.map((demoPayPeriod) => (
                                <button 
                                    key={demoPayPeriod.id}
                                    className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                        ${activePayPeriod.id === demoPayPeriod.id 
                                            ? "bg-gray-200" 
                                            : "hover:bg-gray-100"
                                        }
                                    `} 
                                    role="menuitem"
                                    onClick={() => {
                                        setActivePayPeriod(demoPayPeriod);
                                        setPayPeriodAnchorEl(null);
                                    }}
                                >
                                    {demoPayPeriod.name} ({demoPayPeriod.startDate} - {demoPayPeriod.endDate})
                                </button>
                            ))}
                        </div>
                    </Popover>
					<TableFilter
						fields={[]}
					/>
				</div>
                <OrangeButton
                    title="Download Excel File"
                    className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                    onClick={handleOpenModal}
                />
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={CompanyPayslipColumns}
				apiRef={tableAPIRef}
				loading={false}
				pageCount={1}
				setAnchorEl={setAnchorEl}
			/>
		</>
	);
};

export default CompanyPayslips;

const demoPayPeriods = [
    {
        id: 2,
        name: "2022339", 
        startDate: "2022/01/01",
        endDate: "2022/01/07",
    },
    {
        id: 3,
        name: "2022926", 
        startDate: "2022/01/01",
        endDate: "2022/01/31",
    },
    {
        id: 4,
        name: "2022562", 
        startDate: "2022/01/01",
        endDate: "2022/12/31",
    },
]