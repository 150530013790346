import { Country } from "./country.dto";
import {PaginatorInfo} from "./generic.dto";
import {PaymentChannelDto} from "./payment-channel.dto";
const enum PAYMENT_CHANNEL_TYPE {
    WALLET = 'WALLET',
    BANK = 'BANK',
}

export type PaymentInstitution = {
    id: number;
    shortName: string;
    name: string;
    active: boolean;
    countryId: number;
    paymentChannelType: PAYMENT_CHANNEL_TYPE;
    code: string;
    swiftCode: string | null;
    createdAt: Date;
    modifiedAt: Date | null;
}

export interface PaymentInstitutionDto extends PaymentInstitution {
  country?: Country
}

export interface PaymentInstitutionPaginator extends PaginatorInfo<PaymentInstitutionDto> {
}

export class CreatePaymentInstitutionDto {
    shortName!: string;
    name!: string;
    active?: boolean;
    countryId!: number;
    paymentChannelType!: PAYMENT_CHANNEL_TYPE;
    code!: string;
    swiftCode?: string;
  }
  
  export class UpdatePaymentInstitutionDto {
    shortName?: string;
    name?: string;
    active?: boolean;
    paymentChannelType!: PAYMENT_CHANNEL_TYPE;
    code!: string;
    swiftCode?: string;
  }
  
  export enum PaymentInstitutionOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
  }
  
  export class QueryPaymentInstitutionDto {
    shortName?: string;
    active?: boolean;
    code?: string;
    swiftCode?: string;
    paymentChannelType?: PAYMENT_CHANNEL_TYPE;
  
    page?: number = 1;
    limit?: number;
    orderBy: PaymentInstitutionOrderBy = PaymentInstitutionOrderBy.CREATED_AT_DESC;
  }

export class SearchPaymentInstitutionDto {
    q?: string;
    page? = 1;
    limit?: number;
    orderBy?: PaymentInstitutionOrderBy = PaymentInstitutionOrderBy.CREATED_AT_DESC;
}