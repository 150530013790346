import {PaginatorInfo} from "./generic.dto";

export type Disabilities = {
    id: number
    organizationId: string
    code: string
    name: string
    description: string
    createdAt: string
    modifiedAt: string | null
}
export class CreateDisabilitiesDto {
    organizationId!: string
    code!: string
    name!: string
    description!: string
}

export class UpdateDisabilitiesDto {
    organizationId?: string
    code?: string
    name?: string
    description?: string
}

export class DeletedDisabilitiesDto {

}

export interface DisabilitiesDto extends Disabilities { }

export class QueryDisabilitiesDto {
    organizationId?: string;
    code?: string
    page?: number = 1;
    limit?: number;
    orderBy?: DisabilitiesOrderBy = DisabilitiesOrderBy.CREATED_AT_ASC;
}

export class SearchDisabilitiesDto {
    q?: string
    page?: number = 1;
    limit?: number;
    orderBy: DisabilitiesOrderBy = DisabilitiesOrderBy.CREATED_AT_ASC;
}

export enum DisabilitiesOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
}

export interface DisabilitiesPaginator extends PaginatorInfo<DisabilitiesDto> {}