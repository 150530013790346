import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useDeleteEmployeeQualification, useUpdateEmployeeQualification } from "../../../../../../services/employee-qualification.service";
import { EmployeeQualificationDto, QUALIFICATION_TYPE } from "../../../../../../models/employee-qualification.dto";
import { validatePast } from "../../../../../../helpers/appHelpers";
import DeleteModal from "../../../../../../components/modals/DeleteModal";

interface IFormInputs {
    qualificationType: QUALIFICATION_TYPE
    qualification: string
    awardingInstitution: string
    awardDate: string;
}

interface IEditQualification {
	qualification: EmployeeQualificationDto;
}

const EditQualification = ({qualification}: IEditQualification) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateEmployeeQualification } = useUpdateEmployeeQualification();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			qualificationType: qualification.qualificationType,
			qualification: qualification.qualification,
			awardingInstitution: qualification.awardingInstitution || undefined,
			awardDate: qualification.awardDate?.slice(0, 10),
		}
	});
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		updateEmployeeQualification(
			{ payload: data, id: qualification.id }, 
			{
				onSuccess: () => setFormDisabled(true),
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteEmployeeQualification } = useDeleteEmployeeQualification();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteEmployeeQualification(
			{ id: qualification?.id },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={qualification.id + "bh-content"}
				id={qualification.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{qualification.qualification}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Qualification Type" for="qualificationType" />
								<select
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.qualificationType
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									id="qualificationType"
									{...register("qualificationType", {
										required: "Select qualification type here",
									})}
									disabled={formDisabled}
								>
									<option value="">
										Select Type
									</option>
									<option value="PROFESSIONAL">Professional</option>
									<option value="ACADEMIC">Academic</option>
									<option value="VOCATIONAL">Vocational</option>
								</select>
								{errors.qualificationType && (
									<p className="text-red-500 text-sm mt-1">{errors.qualificationType.message}</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Qualification" for="qualification" />
								<input
									placeholder={"Enter qualification"}
									type="text"
									{...register("qualification", {
										required: "Enter qualification here",
									})}
									id="qualification"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.qualification
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									disabled={formDisabled}
								/>
								{errors.qualification && (
									<p className="text-red-500 text-sm mt-1">
										{errors.qualification.message}
									</p>
								)}
							</div>
							{qualification.awardingInstitution &&
								<div className="w-full">
									<Label title="Awarding Institution" for="awardingInstitution" />
									<input
										placeholder={"Enter institution"}
										type="text"
										{...register("awardingInstitution", {
											required: "Enter awarding institution here",
										})}
										id="awardingInstitution"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.awardingInstitution
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										disabled={formDisabled}
									/>
									{errors.awardingInstitution && (
										<p className="text-red-500 text-sm mt-1">
											{errors.awardingInstitution.message}
										</p>
									)}
								</div>
							}
							{qualification.awardDate && 
								<div className="w-full">
									<Label title="Award Date" for="awardDate" />
									<input
										placeholder={"mm/dd/yyyy"}
										type="date"
										{...register("awardDate", {
											required: "Enter award date here",
											validate: validatePast,
										})}
										id="awardDate"
										className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
											errors.awardDate
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
										disabled={formDisabled}
									/>
									{errors.awardDate && (
										<p className="text-red-500 text-sm mt-1">
											{errors.awardDate.message}
										</p>
									)}
								</div>
							}
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={qualification.qualification}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditQualification;