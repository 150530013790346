import { Country } from "./country.dto";
import {PaginatorInfo} from "./generic.dto";

export const BENEFIT_TYPE = {
  MEDICAL: 'MEDICAL',
  IN_KIND: 'IN_KIND',
  GIFT: 'GIFT'
};

export type BENEFIT_TYPE = (typeof BENEFIT_TYPE)[keyof typeof BENEFIT_TYPE]

export type BenefitTypeInstance = {
  id: number
  countryId: number
  code: string
  name: string
  description: string
  benefitType: BENEFIT_TYPE
  terminal: boolean
  createdAt: string
  modifiedAt: string | null
}

export interface BenefitType extends BenefitTypeInstance{}

export class CreateBenefitTypeDto {
  countryId!: number
  code!: string
  name!: string
  description!: string
  benefitType!: BENEFIT_TYPE
  terminal?: boolean
}

export class UpdateBenefitTypeDto {
  code?: string
  name?: string
  description?: string
  benefitType?: BENEFIT_TYPE
  terminal?: boolean;
}

export interface BenefitTypeDto extends BenefitType {
  country?: Country
}

export interface BenefitTypePaginator extends PaginatorInfo<BenefitTypeDto> {}

export class QueryBenefitTypeDto {
  countryId?: number;
  benefitType?: BENEFIT_TYPE
  terminal?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy?: BenefitTypeOrderBy = BenefitTypeOrderBy.CREATED_AT_ASC;
}



export class SearchBenefitTypeDto {
  q?: string;
  page?: number = 1;
  limit?: number;
  orderBy: BenefitTypeOrderBy = BenefitTypeOrderBy.CREATED_AT_ASC;
}

export enum BenefitTypeOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc',
}