import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useCreateLeaveType, useUpdateLeaveType } from "../../../../../../services/leave-type.service";
import { RxPlus } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
	name: string;
    colorCode: string;
	description: string;
}

const LeaveTypesModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
}: IAddEditTableForm) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [newColorCode, setNewColorCode] = useState("");
	const [colorCodeInputBlur, setColorCodeInputBlur] = useState(false);
	const colorInputRef = useRef<HTMLInputElement>(null);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: isEditForm ? formDataValues?.code : "",
			name: isEditForm ? formDataValues?.name : "",
			colorCode: isEditForm ? formDataValues?.colorCode : "",
			description: isEditForm ? formDataValues?.description : "",
		}
	});

	const colorCode = watch("colorCode");
	useEffect(() => {
		if (
			formDataValues?.colorCode &&
			!formDataValues?.colorCode.includes("6978F0") &&
			!formDataValues?.colorCode.includes("27AE60") &&
			!formDataValues?.colorCode.includes("9B51E0") &&
			!formDataValues?.colorCode.includes("EC5557")
		) {
			setNewColorCode(isEditForm ? formDataValues?.colorCode : "");
			isEditForm && setColorCodeInputBlur(true);
		}
	}, [formDataValues])

	const { mutate: createLeaveType } = useCreateLeaveType();
	const { mutate: updateLeaveType } = useUpdateLeaveType();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateLeaveType(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createLeaveType(
				{ payload: data }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Assign Color" for="colorCode" />
						<div className="flex items-center justify-between mt-2">
							<div 
								onClick={() => {
									setValue("colorCode", "#6978F0");
									setNewColorCode("");
								}}
								className="h-14 w-14 rounded-full bg-[#6978F0] text-2xl font-light 
								text-white grid place-content-center cursor-pointer"
							>
								{colorCode === "#6978F0" && <FaCheck />}
							</div>
							<div 
								onClick={() => {
									setValue("colorCode", "#27AE60");
									setNewColorCode("");
								}}
								className="h-14 w-14 rounded-full bg-[#27AE60] text-2xl font-light 
								text-white grid place-content-center cursor-pointer"
							>
								{colorCode === "#27AE60" && <FaCheck />}
							</div>
							<div 
								onClick={() => {
									setValue("colorCode", "#9B51E0");
									setNewColorCode("");
								}}
								className="h-14 w-14 rounded-full bg-[#9B51E0] text-2xl font-light 
								text-white grid place-content-center cursor-pointer"
							>
								{colorCode === "#9B51E0" && <FaCheck />}
							</div>
							<div 
								onClick={() => {
									setValue("colorCode", "#EC5557");
									setNewColorCode("");
								}}
								className="h-14 w-14 rounded-full bg-[#EC5557] text-2xl font-light 
								text-white grid place-content-center cursor-pointer"
							>
								{colorCode === "#EC5557" && <FaCheck />}
							</div>
							<div className="relative">
								<div 
									onClick={() => {
										colorInputRef.current?.focus()
									}}
									className="h-14 w-14 rounded-full border border-[#B4B7BD] text-[#B4B7BD] 
									text-2xl font-light grid place-content-center cursor-pointer"
								>
									<RxPlus />
								</div>
								{newColorCode && (
									<div 
										onClick={() => {
											colorInputRef.current?.focus()
										}}
										className={`absolute inset-0 rounded-full bg-[${newColorCode}] text-2xl font-light 
										text-white grid place-content-center cursor-pointer`}
										style={{ backgroundColor: newColorCode }}
									>
										<FaCheck />
									</div>
								)}
								<input
									type="color"
									defaultValue={isEditForm ? formDataValues?.colorCode : ""}
									{...register("colorCode", {
										required: "Assign color here",
									})}
									className="h-14 w-14 rounded-full absolute inset-0 opacity-0 cursor-pointer"
									onChange={(e) => {
										setNewColorCode(e.target.value);
										setValue("colorCode", e.target.value);
									}}
									onBlur={() => setColorCodeInputBlur(true)}
								/>
								{(newColorCode && colorCodeInputBlur) && (
									<div className="absolute top-0 left-[80px] whitespace-nowrap w-fit h-14 px-5 bg-[#27AE60] 
										bg-opacity-20 rounded-[10px] text-sm leading-[56px] text-[#27AE60] color-code-tooltip"
									>
										Click to change selection
									</div>
								)}
							</div>
						</div>
						{errors.colorCode && (
							<p className="text-red-500 text-sm mt-1">{errors.colorCode.message}</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Leave Type"
								: "Add New Leave Type"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default LeaveTypesModalForm;
