import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    CompanyTreeNodeDto,
    CreateCompanyTreeNodeDto,
    DeleteCompanyTreeNodeQueryDto,
    GetSuperviseesWithMeta,
    UpdateCompanyTreeNodeDto
} from '../models/company-tree-node.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetCompanyTree(id: number){
    const url = ENDPOINTS.HR.COMPANY_TREE_NODE.GET_TREE;
    const request = API.HrAPI.getCompanyTree(id);

    const { data, isLoading, error, refetch } = useQuery<{ data: CompanyTreeNodeDto }, Error>(
        [url, id],
        (() => request),
        { enabled: false },
    );
    
    return { data, isLoading, error, refetch };
}

export function useGetCompanyNode(companyId: number, nodeId: number){
    const url = ENDPOINTS.HR.COMPANY_TREE_NODE.GET_NODE;
    const request = API.HrAPI.getCompanyTreeNode(companyId, nodeId);

    const { data, isLoading, error } = useQuery<{ data: CompanyTreeNodeDto }, Error>(
        [url, nodeId],
        (() => request),
        { enabled: !!(nodeId) },
    );
    
    return { data, isLoading, error };
}


export function useCreateCompanyTreeNode() {

    return useMutation(
        ({ companyId, payload }: { companyId: number, payload: CreateCompanyTreeNodeDto }) => API.HrAPI.createCompanyTreeNode(companyId, payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUpdateCompanyTree() {

    return useMutation(
        ({ companyId, nodeId, payload }: { companyId: number, nodeId: number, payload: UpdateCompanyTreeNodeDto }) => API.HrAPI.updateCompanyTreeNode(companyId, nodeId, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useUnlinkEmployeeFromCompanyTree() {

    return useMutation(
        ({ companyId, nodeId }: { companyId: number, nodeId: number }) => API.HrAPI.unlinkEmployeeFromCompanyTreeNode(companyId, nodeId),
        {
            onSuccess: () => {
                toast.success('Employee removed successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useDeleteCompanyTree() {

    return useMutation(
        ({ companyId, nodeId, payload }: { companyId: number, nodeId: number, payload: DeleteCompanyTreeNodeQueryDto }) => API.HrAPI.deleteCompanyTreeNode(companyId, nodeId, payload),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetSupervisees(companyId: number){
    const url = ENDPOINTS.HR.COMPANY_TREE_NODE.GET_SUPERVISEES;
    const request = API.HrAPI.getSupervisees(companyId);

    const { data, isLoading, error, refetch } = useQuery<GetSuperviseesWithMeta, Error>(
        [url, companyId],
        (() => request),
        { enabled: !!(companyId) },
    );
    
    return { data, isLoading, error, refetch };
}