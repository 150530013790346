import { ExampleDto } from "../../models/example.dto";
import {HttpClient} from "./requests";
import ENDPOINTS from "./endpoints";
import {ProcessingCompany} from "./apis/processing-company.api";
import {User} from "./apis/user.api";
import {BillingAndSubscription} from "./apis/billing.api";
import {Organization} from "./apis/organization.api";
import {Document} from "./apis/document.api";
import {Hr} from "./apis/hr.api";
import {toast} from "react-toastify";

class Example {
    getById = (id: number) => HttpClient.get<ExampleDto>(ENDPOINTS.EXAMPLE.GET_1);
}
function onErrorHandler (error: any, variables: any, context: any) {
    const text = error?.response?.data?.message as string
                || error?.message as string
                || 'An error occurred while processing the request.';

    if (text.toLowerCase().includes("prisma")) {
        toast.error("An error occured on the server while processing the request.");
        return
    }

    toast.error(text);
}

function paginationHandler(data: any,
                           isLoading: boolean,
                           error: any,
                           fetchNextPage: Function,
                           hasNextPage: boolean | undefined,
                           isFetchingNextPage: boolean,
                           hasPreviousPage: boolean | undefined,
                           fetchPreviousPage: Function){
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }

    return {
        data: data?.pages.flatMap((page: {data: any}) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages) ? data?.pages[data.pages.length - 1]  : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
};


type pageParamOptionType = {page: number, pageSize: number, totalCount: number};

const pageParamOption = {
    getNextPageParam: ({  page, pageSize, totalCount }: pageParamOptionType) =>
    {
        if (page * pageSize < totalCount) {
            return { page: page + 1, size: pageSize };
        }
        return false;
    },
    getPreviousPageParam: ({ page, pageSize, totalCount }: pageParamOptionType) => {
        if (page * pageSize < totalCount) {
            return { page: page - 1, size: pageSize };
        }
        return false;
    },
}
export default {
    ProcessingCompanyAPI: new ProcessingCompany(),
    UserAPI: new User(),
    ExampleAPI: new Example(),
    OrganizationAPI: new Organization(),
    BillingAndSubscriptionAPI: new BillingAndSubscription(),
    DocumentAPI: new Document(),
    HrAPI: new Hr(),
    Handlers: {
        onErrorHandler,
        paginationHandler,
        pageParamOption
    }
}