import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import Label from "../../../../../../components/form_fields/Label";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import useUserStore from "../../../../../../state-management/useUserStore";
import { useCreateErpIntegrationMapping, useUpdateErpIntegrationMapping } from "../../../../../../services/erp-integration-mapping.service";
import { PaymentInstitutionBranchDto } from "../../../../../../models/payment-institution-branch.dto";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
	mappingCode1: string;
	mappingCode2?: string;
	description: string;
	sequenceNumber: number;
	bankBranchId: number;
}

type ErpIntegrationMappingModalFormProps = IAddEditTableForm &{
	paymentInstitutionBranches: PaymentInstitutionBranchDto[];
}

const ErpIntegrationMappingModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	paymentInstitutionBranches,
}: ErpIntegrationMappingModalFormProps) => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: isEditForm ? formDataValues?.code : "",
			mappingCode1: isEditForm ? formDataValues?.mappingCode1 : "",
			mappingCode2: isEditForm ? formDataValues?.mappingCode2 : "",
			description: isEditForm ? formDataValues?.description : "",
			sequenceNumber: isEditForm ? formDataValues?.sequenceNumber : "",
			bankBranchId: isEditForm ? formDataValues?.bankBranchId : "",
		}
	});

	const { mutate: createErpIntegrationMapping } = useCreateErpIntegrationMapping();
	const { mutate: updateErpIntegrationMapping } = useUpdateErpIntegrationMapping();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateErpIntegrationMapping(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createErpIntegrationMapping(
				{ 
					payload: { 
						...data, 
						companyId: currentCompany?.id as number,
						organizationId: currentUser.user.organizationId,
					} 
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Mapping Code 1" for="mappingCode1" />
						<input
							placeholder={"Enter mapping code"}
							type="text"
							{...register("mappingCode1", {
								required: "Enter mapping code 1 here",
							})}
							id="mappingCode1"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.mappingCode1
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.mappingCode1 && (
							<p className="text-red-500 text-sm mt-1">{errors.mappingCode1.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Mapping Code 2" for="mappingCode2" optional />
						<input
							placeholder={"Enter mapping code"}
							type="text"
							{...register("mappingCode2", {
								required: false,
							})}
							id="mappingCode2"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.mappingCode2
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.mappingCode2 && (
							<p className="text-red-500 text-sm mt-1">{errors.mappingCode2.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Sequence Number" for={`sequenceNumber`} />
						<input
							placeholder={"Enter sequence number"}
							type="number"
							{...register(`sequenceNumber`, {
								required: "Enter sequence number here",
								valueAsNumber: true
							})}
							id={`sequenceNumber`}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors?.sequenceNumber
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors?.sequenceNumber && (
							<p className="text-red-500 text-sm mt-1">
								{errors?.sequenceNumber?.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Payment Institution Branch" for="bankBranchId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.bankBranchId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("bankBranchId", {
								required: "Select payment institution branch here",
								valueAsNumber: true
							})}
						>
							<option value="">
								Select Branch
							</option>
							{paymentInstitutionBranches.map((paymentInstitutionBranch) => (
								<option 
									key={paymentInstitutionBranch.id} 
									value={paymentInstitutionBranch.id} 
								>
									{paymentInstitutionBranch.name}
								</option>
							))}
						</select>
						{errors.bankBranchId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.bankBranchId.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Integration"
								: "Create Integration"
						}`}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default ErpIntegrationMappingModalForm;
