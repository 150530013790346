import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import NewOrganizationRole from "./NewOrganizationRole";
import { useGetOrganizationRole } from "../../../../../services/organization-role.service";
import { OrganizationRoleDto, OrganizationRoleOrderBy } from "../../../../../models/organization-role.dto";
import { useGetPermission } from "../../../../../services/permission.service";
import EditOrganizationRole from "./EditOrganizationRole";
import useUserStore from "../../../../../state-management/useUserStore";
import { SkewLoader } from "react-spinners";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { PermissionOrderBy } from "../../../../../models/permission.dto";
import CustomPagination from "../../../../../components/table/CustomPagination";

const Roles = () => {
	const [showNewRolesForm, setShowNewRolesForm] = useState(false);
	const currentOrganization = useUserStore().currentUser.user.organization;
	const [organizationRoles, setOrganizationRoles] = useState<OrganizationRoleDto[]>([]);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [newData, setNewData] = useState("");
	const [reloadData, setReloadData] = useState("");
	
	const handlePageChange = (_, value: number) => setPage(value);

	const { data, isLoading, refetch } = useGetOrganizationRole({
		organizationId: currentOrganization?.id,
		page: 1,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: OrganizationRoleOrderBy.CREATED_AT_DESC,
	});

	useEffect(() => {
		if (!isLoading && data?.data) {
			setOrganizationRoles(data.data);
            setPageCount(data.pagination?.totalPages || 1);
		}
	}, [isLoading]);
    
	useEffect(() => {
		newData && setPage(1);

        refetch().then((response) => {
            if (response) {
                setOrganizationRoles(response.data?.data || []);
                setPageCount(response.data?.pagination?.totalPages || 1);
            }

			newData && setNewData("");
			reloadData && setReloadData("");
        });
	}, [page, newData, reloadData])

	const { data: permissionsData } = useGetPermission({
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: PermissionOrderBy.NAME_ASC,
	});
	
	return (
		<>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">User Role Management</h2>
				<button
					onClick={() => setShowNewRolesForm(true)}
					className={`border-none flex items-center text-primary-blue`}
				>
					<AiOutlinePlus className="mr-2 text-lg" />
					Add New Role
				</button>
			</div>

			{showNewRolesForm && (
				<NewOrganizationRole 
					open={showNewRolesForm} 
					setOpen={setShowNewRolesForm} 
					permissionsData={permissionsData}
					setNewData={setNewData}
				/>
			)}
			
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}
		
			{organizationRoles.map((organizationRole: OrganizationRoleDto) => (
				<EditOrganizationRole 
					key={organizationRole.id}
					permissionsData={permissionsData}
					organizationRole={organizationRole}
					setReloadData={setReloadData}
				/>
			))}

			{(!isLoading && organizationRoles.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no roles added yet. Click 'Add New Role' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end my-8">
				<CustomPagination 
					pageCount={pageCount} 
					pageState={page}
					handleChange={handlePageChange}
				/>
			</div>
		</>
	);
};

export default Roles;
