import { Popover } from "@mui/material";
import { useOrganizationTreeStore } from "../../../../state-management/useOrganizationTreeStore";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../components/form_fields/Label";
import { EmployeeDto } from "../../../../models/employee.dto";
import { JobTitleDto } from "../../../../models/job-titles.dto";
import { useCreateCompanyTreeNode } from "../../../../services/company-tree-node.service";
import { useState } from "react";
import useUserStore from "../../../../state-management/useUserStore";
import API from '../../../../services/utils/api';

type FormInputs = {
    jobTitleId: number;
    employeeId?: number;
}

type AddNodeModalProps = {
    anchorEl: any;
    nodeParentId: number | null;
    jobTitles: JobTitleDto[];
    employees: EmployeeDto[];
    handleClose: () => void;
}

const AddNodeModal = ({
    anchorEl,
    nodeParentId,
    jobTitles,
    employees,
    handleClose,
}: AddNodeModalProps) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
    const { treeSourceData, generateTree } = useOrganizationTreeStore();
    const { mutate: createTreeNode } = useCreateCompanyTreeNode();
    const [uploading, setUploading] = useState(false);
    
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = (data: FormInputs) => {
        setUploading(true);
        
        if (data.employeeId) {
            const alreadyAssignedEmployee = Boolean(treeSourceData.find(
                node => node.data.employee?.id === data.employeeId
            ))
            
            if (alreadyAssignedEmployee) {
                alert("Employee already assigned to a node.");
                return
            }
        }

        createTreeNode(
            { 
                companyId: companyProfile.companyId,
                payload: {
                    ...data,
                    parentId: nodeParentId || undefined,
                } 
            },
            {
                onSuccess: async () => {
                    const { data } = await API.HrAPI.getCompanyTree(companyProfile.companyId);
                    generateTree(data);
                    setUploading(false);
                    handleClose();
                    reset();
                },
                onError: () => setUploading(false),
            }
        )
    };
    
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    width: "330px",
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            <form 
                onSubmit={handleSubmit(onSubmit)}
                className="w-full p-4 flex flex-col gap-4 text-sm"
            >
                <div className="w-full">
                    <Label title="Job Title" for="jobTitleId" />
                    <select
                        className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                            errors.jobTitleId
                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                : "border-none"
                        }`}
                        {...register("jobTitleId", {
                            required: "Select job title here",
                            valueAsNumber: true,
                        })}
                    >
                        <option value="">
                            Select Job Title
                        </option>
                        {jobTitles.map((jobTitle) => (
                            <option 
                                key={jobTitle.id} 
                                value={jobTitle.id} 
                            >
                                {jobTitle.name}
                            </option>
                        ))}
                    </select>
                    {errors.jobTitleId && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors.jobTitleId.message}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <Label title="Employee" for="employeeId" optional />
                    <select
                        className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                            errors.employeeId
                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                : "border-none"
                        }`}
                        {...register("employeeId", {
                            required: false,
                            valueAsNumber: true,
                        })}
                    >
                        <option value="">
                            Select Employee
                        </option>
                        {employees.map((employee) => (
                            <option 
                                key={employee.id} 
                                value={employee.id} 
                            >
                                {employee.firstName + " " + employee.lastName}
                            </option>
                        ))}
                    </select>
                    {errors.employeeId && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors.employeeId.message}
                        </p>
                    )}
                </div>

                <button 
                    type="submit" 
                    disabled={uploading}
                    className={`w-full bg-primary-mango py-3 rounded-lg text-white text-2xs
                        capitalize node-button ${uploading ? "opacity-50" : "hover:shadow-sm"}
                    `}
                >
                    {uploading ? "Uploading..." : "Add Node"}
                </button>
            </form>
        </Popover>
    );
}
 
export default AddNodeModal;