import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { CurrencyDto } from "../../../../../../models/currency.dto";
import MoneyInput from "../../../../../../components/form_fields/MoneyInput";
import { useCreateExchangeRate, useUpdateExchangeRate } from "../../../../../../services/exchange-rate.service";

interface IFormInputs {
	fromCurrencyId: number;
	toCurrencyId: number;
	buyRate: number;
	sellRate: number;
}

interface ExchangeRatesModalFormProps extends IAddEditTableForm {
	currencies: CurrencyDto[];
}

const ExchangeRatesModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	currencies,
}: ExchangeRatesModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			buyRate: isEditForm ? formDataValues?.buyRate : undefined,
			sellRate: isEditForm ? formDataValues?.sellRate : undefined,
		}
	});

	const { mutate: createExchangeRate } = useCreateExchangeRate();
	const { mutate: updateExchangeRate } = useUpdateExchangeRate();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateExchangeRate(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createExchangeRate(
				{ payload: data }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="From Currency" for="fromCurrencyId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.fromCurrencyId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("fromCurrencyId", {
									required: "Select from currency here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Currency
								</option>
								{currencies.map((currency) => (
									<option key={currency.id} value={currency.id} >
										{currency.name + ` (${currency.code})`}
									</option>
								))}
							</select>
							{errors.fromCurrencyId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.fromCurrencyId.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="To Currency" for="toCurrencyId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.toCurrencyId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("toCurrencyId", {
									required: "Select to currency here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Currency
								</option>
								{currencies.map((currency) => (
									<option key={currency.id} value={currency.id} >
										{currency.name + ` (${currency.code})`}
									</option>
								))}
							</select>
							{errors.toCurrencyId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.toCurrencyId.message}
								</p>
							)}
						</div>
					</>)}
					<div className="w-full">
						<Label title="Buy Rate" for="buyRate" />
						<MoneyInput 
							attributes={{
								id: "buyRate",
								placeholder: "0"
							}}
							register={register}
							name="buyRate"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.buyRate : undefined}
							required="Enter buy rate here"
							error={errors.buyRate}
						/>
						{errors.buyRate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.buyRate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Sell Rate" for="sellRate" />
						<MoneyInput 
							attributes={{
								id: "sellRate",
								placeholder: "0"
							}}
							register={register}
							name="sellRate"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.sellRate : undefined}
							required="Enter sell rate here"
							error={errors.sellRate}
						/>
						{errors.sellRate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.sellRate.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Exchange Rate"
								: "Add New Exchange Rate"
						}`}
						className={`px-8`}
						type={"submit"}
						onClick={handleSubmit(onSubmit)}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default ExchangeRatesModalForm;
