import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime, moneyFormat } from "../../../../../helpers/appHelpers";

export const ExchangeRateColumns: GridColDef[] = [
	{
		field: "fromCurrency",
		headerName: "From Currency",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name + " (" + params.value?.code + ")",
	},
	{
		field: "toCurrency",
		headerName: "To Currency",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name + " (" + params.value?.code + ")",
	},
	{
		field: "buyRate",
		headerName: "Buy Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "sellRate",
		headerName: "Sell Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	
	...createdAtmodifiedAtActions
];

export const ExchangeRateHistoryColumns: GridColDef[] = [
	{
		field: "oldBuyRate",
		headerName: "Old Buy Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "oldSellRate",
		headerName: "Old Sell Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "newBuyRate",
		headerName: "New Buy Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "newSellRate",
		headerName: "New Sell Rate",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value),
	},
	{
		field: "completed",
		headerName: "Completed",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
];
