import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import SelfserviceOvertimeView from "./views/SelfserviceOvertimeView";
import SelfserviceWorkTimeView from "./views/SelfserviceWorkTimeView";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { EmployeeWorkTimeTabColumns } from "../../../components/custom_tab/tabColumns";
import { REQUEST_QUERY_MODE } from "../../../models/leave-request.dto";
import { styled, SwitchProps, Switch, FormControlLabel, FormGroup } from "@mui/material";
import useUserStore from "../../../state-management/useUserStore";

const SelfserviceWortTimeAndOvertime = () => {
	const { supervisor } = useUserStore();
	const [searchParams] = useSearchParams();
	const [view, setView] = useState(searchParams.get("view") || "workTime");
	const [superviseesMode, setSuperviseesMode] = useState<REQUEST_QUERY_MODE>("SELF");

	const handleToggleSuperviseesMode = () => {
		if (superviseesMode === "SELF") {
			setSuperviseesMode("SUPERVISEES");
		} else {
			setSuperviseesMode("SELF");
		}
	};
	
	return (
		<SelfserviceLayout 
			pageHeader=""
			pageHeaderNode={(
				<div className="lg:w-full flex max-lg:flex-col gap-5 items-end justify-between">
					<h1 className="font-bold text-lg lg:text-[23px] text-charcoal">Work Time</h1>
					{supervisor && (
						<div className="max-lg:hidden flex items-center gap-3">
							<p className="max-lg:text-sm font-medium text-charcoal mr-2">
								Supervisees Mode
							</p>
							<span>
								<FormGroup>
									<FormControlLabel
										control={<IOSSwitch sx={{ m: 0 }} onChange={handleToggleSuperviseesMode} />}
										label=""
									/>
								</FormGroup>
							</span>
						</div>
					)}
				</div>
			)}
		>
			<Helmet>
				<title>Work Time | Self Service Flow</title>
			</Helmet>
			{/* <div className="max-lg:flex hidden items-center gap-3 pb-4">
				<p className="text-sm font-medium text-charcoal mr-">
					Supervisees Mode
				</p>
				<span className="scale-[0.85]">
					<FormGroup>
						<FormControlLabel
							control={<IOSSwitch sx={{ m: 0 }} onChange={handleToggleSuperviseesMode} />}
							label=""
						/>
					</FormGroup>
				</span>
			</div> */}
			<CustomTab
				view={view}
				setView={setView}
				tabColumns={EmployeeWorkTimeTabColumns}
			>
				{view === "workTime" && <SelfserviceWorkTimeView superviseesMode={superviseesMode} />}
				{view === "overtime" && <SelfserviceOvertimeView superviseesMode={superviseesMode} />}
			</CustomTab>
		</SelfserviceLayout>
	);
};

export default SelfserviceWortTimeAndOvertime;

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 44,
	height: 22,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(21px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#27AE60",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 18,
		height: 18,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));
