import {
    CountryDto,
    CountryPaginator,
    CreateCountryDto,
    QueryCountryDto,
    SearchCountryDto,
    UpdateCountryDto
} from "../../../models/country.dto";
import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import {
    CreateCurrencyDto,
    CurrencyDto,
    CurrencyPaginator,
    QueryCurrencyDto,
    SearchCurrencyDto,
    UpdateCurrencyDto
} from "../../../models/currency.dto";
import {
    CreateSubscriptionTierDto,
    DetailedSubscriptionTierDto,
    QueryApplicableSubscriptionTiersDto,
    QuerySubscriptionTierDto, SubscriptionTierDto,
    SubscriptionTierPaginator, UpdateSubscriptionTierDto
} from "../../../models/subscription-tier.dto";
import {
    BillingTypeDto,
    BillingTypePaginator,
    CreateBillingTypeDto,
    QueryBillingTypeDto, UpdateBillingTypeDto
} from "../../../models/billing-type.dto";
import {
    CancelInvoiceDto,
    CreateInvoiceDto,
    InvoiceDto,
    InvoicePaginator, PayInvoiceDto,
    QueryInvoiceDto,
    SearchInvoiceDto,
    UpdateInvoiceDto
} from "../../../models/invoice.dto";
import {
    CreatePaymentChannelDto,
    PaymentChannelDto,
    PaymentChannelPaginator, QueryPaymentChannelDto,
    SearchPaymentChannelDto,
    UpdatePaymentChannelDto
} from "../../../models/payment-channel.dto";
import {
    CreatePaymentInstitutionDto,
    PaymentInstitutionDto,
    PaymentInstitutionPaginator, QueryPaymentInstitutionDto,
    SearchPaymentInstitutionDto,
    UpdatePaymentInstitutionDto
} from "../../../models/payment-institution.dto";
import {
    QuerySubscriptionBillingDto,
    SubscriptionBillingDto,
    SubscriptionBillingPaginator,
    UpdateSubscriptionBillingDto
} from "../../../models/subscription-billing.dto";
import { CreatePromotionDto, PromotionDto, PromotionPaginator, QueryPromotionDto, SearchPromotionDto, UpdatePromotionDto } from "../../../models/promotion.dto";
import { CreditOrganizationTransactionDto, OrganizationTransaction, OrganizationTransactionPaginator, QueryOrganizationTransactionDto, ReverseOrganizationTransactionDto, SearchOrganizationTransactionDto, UpdateOrganizationTransactionDto } from "../../../models/organization-transaction.dto";
import { CreatePaymentInstitutionBranchDto, PaymentInstitutionBranchDto, PaymentInstitutionBranchPaginator, QueryPaymentInstitutionBranchDto, SearchPaymentInstitutionBranchDto, UpdatePaymentInstitutionBranchDto } from "../../../models/payment-institution-branch.dto";
import { CreateExchangeRateDto, CurrencyConversionInputDto, CurrencyConversionResponseDto, DetailedExchangeRateDto, ExchangeRate, ExchangeRatePaginator, QueryExchangeRateDto, UpdateExchangeRateDto } from "../../../models/exchange-rate.dto";
import { ExchangeRateHistoryPaginator, QueryExchangeRateHistoryDto } from "../../../models/exchange-rate-history.dto";
import { CreateStatementRequestDto, QueryStatementRequestDto, StatementRequestDto, StatementRequestPaginator, UpdateStatementRequestDto } from "../../../models/statement-request.dto";
import { OrganizationAccountDto, OrganizationAccountPaginator, QueryOrganizationAccountDto } from "../../../models/organization-account.dto";

export class BillingAndSubscription {
    getCountry = (query?: QueryCountryDto) => HttpClient
        .get<CountryPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.GET, query);
    getCountrySearch = (query?: SearchCountryDto) => HttpClient
        .get<CountryPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.SEARCH, query);
    getCountryById = (countryId: number) => HttpClient
        .get<CountryDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.DETAIL
            .replace('{countryId}', String(countryId)));
    createCountry = (payload: CreateCountryDto) => HttpClient
        .post<CountryDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.CREATE, payload);
    updateCountry = (countryId: number, payload: UpdateCountryDto) => HttpClient
        .patch<CountryDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.UPDATE
            .replace('{countryId}', String(countryId)), payload);
    deleteCountry = (countryId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.DELETE
            .replace('{countryId}', String(countryId)));


    getCurrency = (query?: QueryCurrencyDto) => HttpClient
        .get<CurrencyPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.GET, query);
    getCurrencySearch = (query?: SearchCurrencyDto) => HttpClient
        .get<CurrencyPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.SEARCH, query);
    getCurrencyById = (currencyId: number) => HttpClient
        .get<CurrencyDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.DETAIL
            .replace('{currencyId}', String(currencyId)));
    createCurrency = (payload: CreateCurrencyDto) => HttpClient
        .post<CurrencyDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.CREATE, payload);
    updateCurrency = (currencyId: number, payload: UpdateCurrencyDto) => HttpClient
        .patch<CurrencyDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.UPDATE
            .replace('{currencyId}', String(currencyId)), payload);
    deleteCurrency = (currencyId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.DELETE
            .replace('{currencyId}', String(currencyId)));


    getSubscriptionTierPackages = (query?: QueryApplicableSubscriptionTiersDto) => HttpClient
        .get<SubscriptionTierPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET_PACKAGES, query);
    getSubscriptionTier = (query?: QuerySubscriptionTierDto) => HttpClient
        .get<SubscriptionTierPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.GET, query);
    getSubscriptionTierById = (subscriptionTierId: string) => HttpClient
        .get<{ data:DetailedSubscriptionTierDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.DETAIL
            .replace('{subscriptionTierId}', String(subscriptionTierId)));
    createSubscriptionTier = (payload: CreateSubscriptionTierDto) => HttpClient
        .post<{ data:SubscriptionTierDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.CREATE, payload);
    updateSubscriptionTier = (subscriptionTierId: number, payload: UpdateSubscriptionTierDto) => HttpClient
        .patch<{ data:SubscriptionTierDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.UPDATE
            .replace('{subscriptionTierId}', String(subscriptionTierId)), payload);
    deleteSubscriptionTier = (subscriptionTierId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_TIER.DELETE
            .replace('{subscriptionTierId}', String(subscriptionTierId)));

    getBillingType = (query?: QueryBillingTypeDto) => HttpClient
        .get<BillingTypePaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.GET, query);
    getBillingTypeById = (billingTypeId: number) => HttpClient
        .get<{ data:BillingTypeDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.DETAIL
            .replace('{billingTypeId}', String(billingTypeId)));
    createBillingType = (payload: CreateBillingTypeDto) => HttpClient
        .post<{ data:BillingTypeDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.CREATE, payload);
    updateBillingType = (billingTypeId: number, payload: UpdateBillingTypeDto) => HttpClient
        .patch<{ data:BillingTypeDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.UPDATE
            .replace('{billingTypeId}', String(billingTypeId)), payload);
    deleteBillingType = (billingTypeId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.BILLING_TYPE.DELETE
            .replace('{billingTypeId}', String(billingTypeId)));

    getPaymentChannel = (query?: QueryPaymentChannelDto) => HttpClient
        .get<PaymentChannelPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.GET, query);
    getPaymentChannelSearch = (query?: SearchPaymentChannelDto) => HttpClient
        .get<PaymentChannelPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.SEARCH, query);
    getPaymentChannelById = (paymentChannelId: number) => HttpClient
        .get<{ data:PaymentChannelDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.DETAIL
            .replace('{paymentChannelId}', String(paymentChannelId)));
    createPaymentChannel = (payload: CreatePaymentChannelDto) => HttpClient
        .post<{ data:PaymentChannelDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.CREATE, payload);
    updatePaymentChannel = (paymentChannelId: number, payload: UpdatePaymentChannelDto) => HttpClient
        .patch<{ data:PaymentChannelDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.UPDATE
            .replace('{paymentChannelId}', String(paymentChannelId)), payload);
    deletePaymentChannel = (paymentChannelId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_CHANNEL.DELETE
            .replace('{paymentChannelId}', String(paymentChannelId)));

    getPaymentInstitution = (query?: QueryPaymentInstitutionDto) => HttpClient
        .get<PaymentInstitutionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.GET, query);
    getPaymentInstitutionSearch = (query?: SearchPaymentInstitutionDto) => HttpClient
        .get<PaymentInstitutionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.SEARCH, query);
    getPaymentInstitutionById = (paymentInstitutionId: number) => HttpClient
        .get<{ data:PaymentInstitutionDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.DETAIL
            .replace('{paymentInstitutionId}', String(paymentInstitutionId)));
    createPaymentInstitution = (payload: CreatePaymentInstitutionDto) => HttpClient
        .post<{ data:PaymentInstitutionDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.CREATE, payload);
    updatePaymentInstitution = (paymentInstitutionId: number, payload: UpdatePaymentInstitutionDto) => HttpClient
        .patch<{ data:PaymentInstitutionDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.UPDATE
            .replace('{paymentInstitutionId}', String(paymentInstitutionId)), payload);
    deletePaymentInstitution = (paymentInstitutionId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION.DELETE
            .replace('{paymentInstitutionId}', String(paymentInstitutionId)));

    getInvoice = (query?: QueryInvoiceDto) => HttpClient
        .get<InvoicePaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.GET, query);
    getInvoiceSearch = (query?: SearchInvoiceDto) => HttpClient
        .get<InvoicePaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.SEARCH, query);
    getInvoiceById = (invoiceId: number) => HttpClient
        .get<{ data:InvoiceDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.DETAIL
            .replace('{invoiceId}', String(invoiceId)));
    createInvoice = (payload: CreateInvoiceDto) => HttpClient
        .post<{ data:InvoiceDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.CREATE, payload);
    updateInvoice = (invoiceId: number, payload: UpdateInvoiceDto) => HttpClient
        .patch<{ data:InvoiceDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.UPDATE
            .replace('{invoiceId}', String(invoiceId)), payload);
    payInvoice = (payload: PayInvoiceDto) => HttpClient
        .post<{ data:InvoiceDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.PAY, payload);
    cancelInvoice = (payload: CancelInvoiceDto) => HttpClient
        .post<{ data:InvoiceDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.INVOICE.CANCEL, payload);

    getSubscriptionBilling = (query?: QuerySubscriptionBillingDto) => HttpClient
        .get<SubscriptionBillingPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_BILLING.GET, query);
    getSubscriptionBillingById = (subscriptionBillingId: number) => HttpClient
        .get<{ data:SubscriptionBillingDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.SUBSCRIPTION_BILLING.DETAIL
            .replace('{subscriptionBillingId}', String(subscriptionBillingId)));

    getPromotion = (query?: QueryPromotionDto) => HttpClient
        .get<PromotionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.GET, query);
    getPromotionSearch = (query?: SearchPromotionDto) => HttpClient
        .get<PromotionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.SEARCH, query);
    getPromotionById = (promotionId: number) => HttpClient
        .get<PromotionDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.DETAIL
            .replace('{promotionId}', String(promotionId)));
    createPromotion = (payload: CreatePromotionDto) => HttpClient
        .post<PromotionDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.CREATE, payload);
    updatePromotion = (promotionId: number, payload: UpdatePromotionDto) => HttpClient
        .patch<PromotionDto>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.UPDATE
            .replace('{promotionId}', String(promotionId)), payload);
    deletePromotion = (promotionId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PROMOTION.DELETE
            .replace('{promotionId}', String(promotionId)));

    getOrgTransaction = (query?: QueryOrganizationTransactionDto) => HttpClient
        .get<OrganizationTransactionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.GET, query);
    getOrgTransactionSearch = (query?: SearchOrganizationTransactionDto) => HttpClient
        .get<OrganizationTransactionPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.SEARCH, query);
    getOrgTransactionById = (transactionId: number) => HttpClient
        .get<OrganizationTransaction>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.DETAIL
            .replace('{transactionId}', String(transactionId)));
    creditOrgTransaction = (payload: CreditOrganizationTransactionDto) => HttpClient
        .post<OrganizationTransaction>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.CREDIT, payload);
    reverseOrgTransaction = (payload: ReverseOrganizationTransactionDto) => HttpClient
        .post<OrganizationTransaction>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.REVERSE, payload);
    updateOrgTransaction = (transactionId: number, payload: UpdateOrganizationTransactionDto) => HttpClient
        .patch<OrganizationTransaction>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORG_TRANSACTION.UPDATE
            .replace('{transactionId}', String(transactionId)), payload);

    getPaymentInstitutionBranch = (query?: QueryPaymentInstitutionBranchDto) => HttpClient
        .get<PaymentInstitutionBranchPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.GET, query);
    getPaymentInstitutionBranchSearch = (query?: SearchPaymentInstitutionBranchDto) => HttpClient
        .get<PaymentInstitutionBranchPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.SEARCH, query);
    getPaymentInstitutionBranchById = (paymentInstitutionBranchId: number) => HttpClient
        .get<{ data:PaymentInstitutionBranchDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.DETAIL
            .replace('{paymentInstitutionBranchId}', String(paymentInstitutionBranchId)));
    createPaymentInstitutionBranch = (payload: CreatePaymentInstitutionBranchDto) => HttpClient
        .post<{ data:PaymentInstitutionBranchDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.CREATE, payload);
    updatePaymentInstitutionBranch = (paymentInstitutionBranchId: number, payload: UpdatePaymentInstitutionBranchDto) => HttpClient
        .patch<{ data:PaymentInstitutionBranchDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.UPDATE
            .replace('{paymentInstitutionBranchId}', String(paymentInstitutionBranchId)), payload);
    deletePaymentInstitutionBranch = (paymentInstitutionBranchId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.PAYMENT_INSTITUTION_BRANCH.DELETE
            .replace('{paymentInstitutionBranchId}', String(paymentInstitutionBranchId)));

    getExchangeRate = (query?: QueryExchangeRateDto) => HttpClient
        .get<ExchangeRatePaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.GET, query);
    convertCurrency = (payload?: CurrencyConversionInputDto) => HttpClient
        .get<{ data: CurrencyConversionResponseDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.CONVERT, payload);
    getExchangeRateById = (exchangeRateId: number) => HttpClient
        .get<{ data: DetailedExchangeRateDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.DETAIL
            .replace('{exchangeRateId}', String(exchangeRateId)));
    createExchangeRate = (payload: CreateExchangeRateDto) => HttpClient
        .post<{ data: ExchangeRate}>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.CREATE, payload);
    updateExchangeRate = (exchangeRateId: number, payload: UpdateExchangeRateDto) => HttpClient
        .patch<{ data: ExchangeRate }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.UPDATE
            .replace('{exchangeRateId}', String(exchangeRateId)), payload);
    deleteExchangeRate = (exchangeRateId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE.DELETE
            .replace('{exchangeRateId}', String(exchangeRateId)));

    getExchangeRateHistory = (query?: QueryExchangeRateHistoryDto) => HttpClient
        .get<ExchangeRateHistoryPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.EXCHANGE_RATE_HISTORY.GET, query);

    getStatementRequest = (query?: QueryStatementRequestDto) => HttpClient
        .get<StatementRequestPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.GET, query);
    getStatementRequestById = (statementRequestId: number) => HttpClient
        .get<{ data: StatementRequestDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.DETAIL
            .replace('{statementRequestId}', String(statementRequestId)));
    createStatementRequest = (payload: CreateStatementRequestDto) => HttpClient
        .post<{ data: StatementRequestDto}>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.CREATE, payload);
    updateStatementRequest = (statementRequestId: number, payload: UpdateStatementRequestDto) => HttpClient
        .patch<{ data: StatementRequestDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.UPDATE
            .replace('{statementRequestId}', String(statementRequestId)), payload);
    deleteStatementRequest = (statementRequestId: number) => HttpClient
        .delete<void>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.STATEMENT_REQUEST.DELETE
            .replace('{statementRequestId}', String(statementRequestId)));

    getOrganizationAccount = (query?: QueryOrganizationAccountDto) => HttpClient
        .get<OrganizationAccountPaginator>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORGANIZATION_ACCOUNT.GET, query);
    getOrganizationAccountByOrganizationId = (organizationId?: string) => HttpClient
        .get<{ data: OrganizationAccountDto }>(ENDPOINTS.BILLING_AND_SUBSCRIPTION.ORGANIZATION_ACCOUNT.DETAIL
            .replace('organizationId', String(organizationId)));
}