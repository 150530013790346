import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { 
    EmployeeApproverDto,
    CreateEmployeeApproverDto, 
    EmployeeApproverPreflightResponseDto 
} from "../../../../../models/employee-approver.dto";
import Label from "../../../../../components/form_fields/Label";
import { EmployeeDto } from "../../../../../models/employee.dto";
import { 
    useCreateEmployeeApprover, 
    usePreflightEmployeeApprover, 
    useUpdateEmployeeApprover 
} from "../../../../../services/employee-approver.service";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { toast } from 'react-toastify';
import { SkewLoader } from "react-spinners";
import { Popover } from "@mui/material";
import { useAsyncEffect, useLockFn } from "ahooks";
import API from "../../../../../services/utils/api";

interface IFormInputs {
    approvers: (CreateEmployeeApproverDto & { id: number })[];
}

interface PreflightResponse {
    level: number;
    response: EmployeeApproverPreflightResponseDto;
}

type RequestApproversProps = {
    employees: EmployeeDto[];
}
const RequestApprovers = ({ employees }: RequestApproversProps) => {
    const { currentCompany } = useCompanyStore();
    const { formDataValues } = useEditFormStore();
	const [employeeApprovers, setEmployeeApprovers] = useState<EmployeeApproverDto[]>([]);
	const [loadingApprovers, setLoadingApprovers] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [preflightModalEl, setErrorPreflightEl] = useState<any>(null);
	const [preflightResponse, setPreflightResponse] = useState<PreflightResponse>({} as any);

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

    useAsyncEffect(useLockFn(async () => {
        try {
            const { data } = await API.HrAPI.getEmployeeApprover(
                formDataValues.id,
                {
                    limit: Math.max(
                        currentCompany?.leaveRequestApprovalsRequired as number, 
                        currentCompany?.reimbursementRequestApprovalsRequired as number
                    ),
                }
            );

            setEmployeeApprovers(data);
            setValue("approvers", data.map((approver) => ({ 
                id: approver.id, 
                approverId: approver.approverId, 
                level: approver.level, 
            })));
        } finally {
            setLoadingApprovers(false);
        }
    }), [])

    const { mutate: createEmployeeApprover } = useCreateEmployeeApprover();
    const { mutate: updateEmployeeApprover } = useUpdateEmployeeApprover();
    const { mutate: preflightEmployeeApprover } = usePreflightEmployeeApprover();

    const preflightCheck = (id: string, level: number) => {
        preflightEmployeeApprover(
            {
                payload: { approverId: Number(id), level },
                employeeId: formDataValues.id,
            },
            {
                onSuccess: ({ data }) => {
                    setPreflightResponse({ level, response: data });
                },
            }
        )
    };

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

        if (employeeApprovers.length) {
            updateEmployeeApprover(
                {
                    payload: { approverId: data.approvers[0].approverId, level: data.approvers[0].level },
                    id: data.approvers[0].id,
                    employeeId: formDataValues.id,
                },
                {
                    onSuccess: () => {
                        updateEmployeeApprover(
                            {
                                payload: { approverId: data.approvers[1].approverId, level: data.approvers[1].level },
                                id: data.approvers[1].id,
                                employeeId: formDataValues.id,
                            },
                            {
                                onSuccess: () => {
                                    toast.success("Approvers updated successfully");
                                    setUploading(false);
                                },
                                onError: () => {
                                    toast.success("First approver updated successfully");
                                    setUploading(false);
                                },
                            }
                        )
                    },
                    onError: () => setUploading(false),
                }
            )
        } else {
            createEmployeeApprover(
                {
                    payload: { approverId: data.approvers[0].approverId, level: data.approvers[0].level },
                    employeeId: formDataValues.id,
                },
                {
                    onSuccess: () => {
                        createEmployeeApprover(
                            {
                                payload: { approverId: data.approvers[1].approverId, level: data.approvers[1].level },
                                employeeId: formDataValues.id,
                            },
                            {
                                onSuccess: () => {
                                    toast.success("Approvers created successfully");
                                    setUploading(false);
                                },
                                onError: () => {
                                    toast.success("First approver created successfully");
                                    setUploading(false);
                                },
                            }
                        )
                    },
                    onError: () => setUploading(false),
                }
            )
        }
    };

    return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{(loadingApprovers || !employees.length) ? (
				<div className="text-center py-14">
					<SkewLoader color="#F58B00" />
				</div>
			):(<>
                <div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4">
                    <div className="w-full">
                        <Label title="First Approver" for="approvers.0.approverId" optional />
                        <select
                            id="approvers.0.approverId"
                            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
                                errors.approvers && errors.approvers[0]?.approverId
                                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                    : "border-none"
                            }`}
                            {...register("approvers.0.approverId", {
                                required: false,
                                valueAsNumber: true,
                            })}
                            onInput={(e: any) => {
                                preflightCheck(e.target.value, 1);
                                setValue("approvers.0.level", 1);
                            }}
                        >
                            <option value="">
                                Select Employee
                            </option>
                            {employees.map((employee) => (
                                <option 
                                    key={employee.id} 
                                    value={employee.id} 
                                >
                                    {employee.firstName + " " + employee.lastName}
                                </option>
                            ))}
                        </select>
                        {(preflightResponse?.level === 1 && 
                            (preflightResponse?.response?.warnings?.length || preflightResponse?.response?.errors?.length)
                        ) ? (
                            <p className="text-sm mt-1">
                                <span className="font-semibold">{preflightResponse?.response.warnings.length}</span>
                                {" warnings, "}
                                <span className="font-semibold">{preflightResponse?.response.errors.length}</span>
                                {" errors click "}
                                <button 
                                    type="button"
                                    onClick={(e) => setErrorPreflightEl(e.currentTarget)}
                                    className="text-primary-blue"
                                >
                                    here
                                </button>
                                {" "}to view
                            </p>
                        ) : null}
                    </div>
                    <div className="w-full">
                        <Label title="Second Approver" for="approvers.1.approverId" optional />
                        <select
                            id="approvers.1.approverId"
                            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
                                errors.approvers && errors.approvers[1]?.approverId
                                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                    : "border-none"
                            }`}
                            {...register("approvers.1.approverId", {
                                required: false,
                                valueAsNumber: true,
                            })}
                            onInput={(e: any) => {
                                preflightCheck(e.target.value, 2);
                                setValue("approvers.1.level", 2);
                            }}
                        >
                            <option value="">
                                Select Employee
                            </option>
                            {employees.map((employee) => (
                                <option 
                                    key={employee.id} 
                                    value={employee.id} 
                                >
                                    {employee.firstName + " " + employee.lastName}
                                </option>
                            ))}
                        </select>
                        {(preflightResponse?.level === 2 && 
                            (preflightResponse?.response?.warnings?.length || preflightResponse?.response?.errors?.length)
                        ) ? (
                            <p className="text-sm mt-1">
                                <span className={`font-semibold ${preflightResponse?.response.warnings.length ? "text-[#FFC107]" : ""}`}>
                                    {preflightResponse?.response.warnings.length}
                                    {" warnings"}
                                </span>
                                {", "}
                                <span className={`font-semibold ${preflightResponse?.response.errors.length ? "text-[#F44336]" : ""}`}>
                                    {preflightResponse?.response.errors.length}
                                    {" errors"}
                                </span>
                                {" click "}
                                <button 
                                    type="button"
                                    onClick={(e) => setErrorPreflightEl(e.currentTarget)}
                                    className="text-primary-blue"
                                >
                                    here
                                </button>
                                {" "}to view
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className="w-full flex items-center justify-end mt-8">
                    <button
                        type="submit"
                        disabled={uploading}
                        className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                    >
                        {uploading ? "Uploading..." : "Save Record"}
                    </button>
                </div>
            </>)}
            <Popover
                open={Boolean(preflightModalEl)}
                anchorEl={preflightModalEl}
                onClose={() => setErrorPreflightEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        maxHeight: "900px",
                        maxWidth: "450px",
                        fontSize: "13px",
                        color: "#4F4F4F",
                        borderRadius: 10,
                        background: "white",
                        boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                        padding: "20px",
                        overflowX: "hidden",
                        overflowY: "auto",
                        whiteSpace: "normal",
                    }
                }}
                classes={{ root: "children-scroll-bar", paper: "children-scroll-bar" }}
            >
                <div className="flex flex-col gap-3">
                    {preflightResponse?.response?.warnings?.length ? (
                        <div>
                            <h6 className="font-semibold">Warning(s)</h6>
                            <ul className="ml-2 pt-2 pl-5 list-disc list-inside space-y-2">
                                {preflightResponse?.response?.warnings.map((warning, index) => (
                                    <li key={index} className="-indent-5">
                                        {warning} 
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ): null}
                    {preflightResponse?.response?.errors?.length ? (
                        <div>
                            <h6 className="font-semibold">Error(s)</h6>
                            <ul className="ml-2 pt-2 pl-5 list-disc list-inside space-y-2">
                                {preflightResponse?.response?.errors.map((error, index) => (
                                    <li key={index} className="-indent-5">
                                        {error} 
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ): null}
                </div>
            </Popover>
		</form>
    );
}
 
export default RequestApprovers;