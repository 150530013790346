import { createContext, useEffect, useState } from "react";
import { PATHS } from "../../../routes/routes.paths";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { Helmet } from "react-helmet-async";
import { IoIosAddCircleOutline } from "react-icons/io";
import { HiDotsHorizontal } from "react-icons/hi";
import { Avatar } from "@mui/material";
import { OrganizationChart } from "primereact/organizationchart";
import { APP_CONFIG } from "../../../helpers/appHelpers";
import Search from "../../../components/search/SearchInput";
import AddNodeModal from "./modals/AddNodeModal";
import { useOrganizationTreeStore, TreeNode } from "../../../state-management/useOrganizationTreeStore";
import { JobTitleOrderBy } from "../../../models/job-titles.dto";
import { useGetEmployee } from "../../../services/employee.service";
import { useGetJobTitle } from "../../../services/job-title.service";
import { useGetCompanyTree } from "../../../services/company-tree-node.service";
import { EmployeeOrderBy } from "../../../models/employee.dto";
import SkewLoader from "react-spinners/SkewLoader";
import NodeMenu from "./modals/NodeMenuModal";
import useUserStore from "../../../state-management/useUserStore";

export const TreeContext = createContext<() => void>(() => {});

const OrganisationalTree = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { 
		organizationTree, 
		treeSourceData,
		levelColors,
		generateTree,
	} = useOrganizationTreeStore();
	const [selection, setSelection] = useState([]);
	const [selectedNode, setSelectedNode] = useState<TreeNode>();
	const [addNodeEl, setAddNodeEl] = useState<any>(null);
	const [nodeMenuEl, setNodeMenuEl] = useState<any>(null);
	
	const { 
		data: companyTree, 
		isLoading: companyTreeLoading,
		refetch,
	} = useGetCompanyTree(companyProfile?.companyId);

	useEffect(() => {refetch()}, []);

	useEffect(() => {
		if (companyTree?.data) {
			generateTree(companyTree?.data);
		}
	}, [companyTree])

	const { data: jobTitles } = useGetJobTitle({
		companyId: companyProfile?.companyId,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: JobTitleOrderBy.NAME_ASC
    });
	const { data: employees } = useGetEmployee({
		companyId: companyProfile?.companyId,
		status: "ACTIVE",
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
    });

	const nodeTemplate = (node: TreeNode) => {
		const colorCode = levelColors.find(
			colorObj => colorObj.parendId === node.parentId
		);

		return (
			<div
				className="w-[200px] rounded-xl bg-[#F2F2F4] relative flex flex-col
				font-inter text-[11px] leading-[1.4] text-[#292D30]"
			>
				<IoIosAddCircleOutline
					onClick={(e) => {
						setSelectedNode(node);
						setAddNodeEl(e.currentTarget);
					}}
					className="absolute -right-[35px] top-1/2 -translate-y-1/2 text-[30px] text-primary-blue cursor-pointer"
				/>
				<HiDotsHorizontal
					onClick={(e) => {
						setSelectedNode(node);
						setNodeMenuEl(e.currentTarget);
					}}
					className="absolute top-2 right-2 text-[20px] cursor-pointer"
				/>
				<div className="px-4 py-3.5 mr-auto flex items-center gap-2.5">
					<div>
						<Avatar
							src={node.data?.employee?.photoUrl}
							alt={node.data?.employee?.firstName}
							className="w-8 h-8 object-cover"
						/>
					</div>
					{node.data.employee ? (
						<p className="font-bold max-w-[80px] truncate">
							{node.data?.employee?.firstName + " " + node.data?.employee?.lastName}
						</p>
					) : (
						<p className="text-primary-blue">Assign Employee</p>
					)}
				</div>
				<div 
					className="w-full p-1 rounded-b-xl text-center font-normal text-white"
					style={{ background: colorCode?.levelColor }}
				>
					{node.data.jobTitle?.name}
				</div>
			</div>
		);
	};

	return (
		<HumanResourceLayout>
			<Helmet>
				<title>Organisational Tree | HR Flow </title>
			</Helmet>

			<div className="flex justify-between items-center">
				<h1 className="font-bold text-xl text-charcoal">Organisational Tree</h1>
			</div>
			<div className="bg-white overflow-y-auto h-[750px] rounded-lg border border-gray-100 mt-6 pb-3 relative">
				<div className="flex justify-between items-center px-4 py-3 sticky inset-0 z-[99] bg-white shadow-md">
					<h3 className="text-2xs relative">
						Organisation,{" "}
						<span className="font-bold">
							Total {treeSourceData.length} people
						</span>
					</h3>
					<div className="relative z-[99999999]">
						{/* <Search fallbackData={[]} setTableData={setTableData} /> */}
					</div>
				</div>
				<div className="grow flex flex-col items-center px-4 mt-8 mb-32 relative">
					{(companyTreeLoading && organizationTree.length < 1) ? (
						<div className="grow place-content-center py-[200px] text-center my-auto">
							<div className="flex items-center gap-4">
								<span className="font-medium text-[18px]">Generating Tree</span>
								<SkewLoader color="#F58B00" />
							</div>
						</div>
					):(<>
						{(organizationTree.length) ? (
							<OrganizationChart
								value={organizationTree}
								className="mt-6"
								// selectionMode="multiple"
								selection={selection}
								nodeTemplate={nodeTemplate}
								onSelectionChange={(e: any) => setSelection(e?.data)}
								style={
									{
										height: "80%",
										width: "80%",
									}
								}
							/>
						):(
							<div className="absolute -bottom-24 right-1/2 translate-x-1/2 mt-10 mr-10">
								<div
									className="mt-14 inline-flex space-x-2 items-center p-6 text-xs text-primary-blue bg-[#26A0F91A] rounded-lg cursor-pointer"
									onClick={(e) => setAddNodeEl(e.currentTarget)}
								>
									<IoIosAddCircleOutline className="text-xl" />
									<p>Add Personnel</p>
								</div>
							</div>
						)}
					</>)}
				</div>

				{addNodeEl && (
					<AddNodeModal
						anchorEl={addNodeEl}
						nodeParentId={selectedNode?.id as number}
						jobTitles={jobTitles?.data || []}
						employees={
							employees?.data?.filter((employee) => {
								return !treeSourceData.find(node => node.data.employeeId === employee.id)
							}) || []
						}
						handleClose={() => setAddNodeEl(null)}
					/>
				)}

				{nodeMenuEl && (
					<NodeMenu 
						anchorEl={nodeMenuEl}
						node={selectedNode as TreeNode}
						employees={employees?.data || []}
						handleClose={() => setNodeMenuEl(null)}
					/>
				)}
			</div>
		</HumanResourceLayout>
	);
};

export default OrganisationalTree;

