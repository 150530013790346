import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import { CreatePaymentInstitutionBranchDto } from "../../../../../../models/payment-institution-branch.dto";
import { useCreatePaymentInstitutionBranch, useUpdatePaymentInstitutionBranch } from "../../../../../../services/payment-institution-branch.service";
import { PaymentInstitutionDto } from "../../../../../../models/payment-institution.dto";

type IFormInputs = Omit<CreatePaymentInstitutionBranchDto, "active"> & {
	active: string;
};

interface PaymentInstitutionBranchModalFormProps extends IAddEditTableForm {
	paymentInstitutions: PaymentInstitutionDto[];
}

const PaymentInstitutionBranchModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	paymentInstitutions,
}: PaymentInstitutionBranchModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			paymentInstitutionId: isEditForm ? formDataValues?.paymentInstitutionId : "",
			sortCode: isEditForm ? formDataValues?.sortCode : "",
			description: isEditForm ? formDataValues?.description : "",
		}
	});

	const { mutate: createPaymentInstitutionBranch } = useCreatePaymentInstitutionBranch();
	const { mutate: updatePaymentInstitutionBranch } = useUpdatePaymentInstitutionBranch();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);
		
		if (isEditForm) {
			const active = data.active === "yes" ? true : false;

			updatePaymentInstitutionBranch(
				{ payload: { ...data, active }, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createPaymentInstitutionBranch(
				{ payload: { ...data } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Payment Institution" for="paymentInstitutionId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.paymentInstitutionId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("paymentInstitutionId", {
								required: "Select payment institution here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Institution
							</option>
							{paymentInstitutions.map((paymentInstitution) => (
								<option key={paymentInstitution.id} value={paymentInstitution.id} >
									{paymentInstitution.name}
								</option>
							))}
						</select>
						{errors.paymentInstitutionId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.paymentInstitutionId.message}
							</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="col-span-2 w-full">
						<Label title="Sort Code" for="sortCode" />
						<input
							placeholder={"Enter sort code"}
							type="text"
							{...register("sortCode", {
								required: "Enter sort code here",
							})}
							id="sortCode"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.sortCode
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.sortCode && (
							<p className="text-red-500 text-sm mt-1">{errors.sortCode.message}</p>
						)}
					</div>
					{isEditForm && (
						<div className="col-span-2">
							<div className="flex space-x-9 font-light">
								<h3>Active</h3>
								<div className="flex space-x-3 items-center">
									<input
										{...register("active")}
										defaultChecked={isEditForm && formDataValues?.active === true}
										type="radio"
										value={"yes"}
										id="active"
									/>
									<label htmlFor="active">Yes</label>
								</div>
								<div className="flex space-x-3 items-center">
									<input
										{...register("active")}
										defaultChecked={isEditForm && formDataValues?.active === false}
										type="radio"
										value={"no"}
										id="inactive"
									/>
									<label htmlFor="inactive">No</label>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Payment Institution Branch"
								: "Add New Payment Institution Branch"
						}`}
						className={`px-8`}
						type={"submit"}
						onClick={handleSubmit(onSubmit)}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default PaymentInstitutionBranchModalForm;
