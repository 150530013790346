import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDate } from "../../../helpers/appHelpers";

export const AnnouncementsColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "title",
		headerName: "Title",
		headerClassName: "super-app-theme--header",
		width: 300,
	},
	{
		field: "body",
		headerName: "Body",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "publishDate",
		headerName: "Publish Date",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => formatDate(params),
	},
	{
		field: "public",
		headerName: "Public",
		width: 160,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "active",
		headerName: "Active",
		width: 160,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
    
    ...createdAtmodifiedAtActions
];