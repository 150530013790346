import React from "react";

const OnboardingLayout = ({ children }: any) => {
	return (
		<div className="flex h-[100svh]">
			<div className="bg-sidebar-img h-full hidden lg:block bg-no-repeat bg-auto w-[45%]"></div>
			{/* <div className="w-full"> */}
				{children}
			{/* </div> */}
		</div>
	);
};

export default OnboardingLayout;
