import { useContext, useEffect, useState } from "react";
import Label from "../../../../components/form_fields/Label";
import { Accordion, AccordionSummary, AccordionDetails, Avatar } from "@mui/material";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { SkewLoader } from "react-spinners";
import { EmployeeDataContext } from "..";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { EmployeeDocumentOrderBy } from "../../../../models/employee-document.dto";
import { useGetEmployeeDocument } from "../../../../services/employee-document.service";

const EmploymentDocumentsView = () => {
	const employeeData = useContext(EmployeeDataContext)?.employeeData;

	const { data: employeeDocuments, isLoading } = useGetEmployeeDocument({ 
		employeeId: employeeData?.id as number,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: EmployeeDocumentOrderBy.CREATED_AT_DESC,
	});

    const [expanded, setExpanded] = useState<any>(
        (employeeDocuments?.data || Array.from({ length: 50 })).reduce(
			(acc, _, index) => ({ ...acc, [`${index}`]: false }), {})
    );

	useEffect(() => {
		setExpanded(employeeDocuments?.data?.reduce(
			(acc, _, index) => ({ ...acc, [`${index}`]: false }), {})
		)
	}, [isLoading])
	
    const toggleDropdown = (field: string) => {
        setExpanded(previousValue => {
            if (previousValue[field]) {
                return { ...previousValue, [field]: false };
            }
            return { ...previousValue, [field]: true };
        });
    };

    return (
		<div className={`mb-4 ${(!isLoading && !employeeDocuments?.data?.length) ? "w-full" : "lg:w-[50%]"}`}>
			<h2 className="font-semibold text-2xs lg:text-sm">
				Uploaded Documents
			</h2>
			
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}
			
			{(!isLoading && !employeeDocuments?.data?.length) && (
				<div className="text-center py-10 text-sm lg:text-2sm">
					No documents to show.
				</div>
			)}

			{employeeDocuments?.data?.map((document, index) => (
				<Accordion
					key={document.id}
					expanded={expanded && expanded[`${index}`]}
					onChange={() => toggleDropdown(`${index}`)}
					classes={{
						root: "border border-gray-300 rounded-lg py-2 mb-2",
					}}
				>
					<AccordionSummary
						expandIcon={<AiOutlineDownCircle className="text-xl" />}
						aria-controls={document.id + "bh-content"}
						id={document.id + "bh-header"}
						sx={{ px: 3, border: "1px" }}
					>
						<h2 className="font-inter text-sm font-semibold">
							{document.documentType?.name}
						</h2>
					</AccordionSummary>
					<AccordionDetails sx={{ px: 3 }}>
						<div className="mt-4">
							<Label title="Document" for="document" />
							<div className="flex space-x-4 mt-3">
								<Link 
									to={document.url} 
									target="_blank"
									className="w-fit"
								>
									<Avatar
										alt="file"
										src={document.url}
										variant="rounded"
										className="border border-primary-blue hover:border-2 cursor-pointer"
										sx={{ width: 165, height: 165 }}
									/>
								</Link>
							</div>
						</div>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
    );
}
 
export default EmploymentDocumentsView;