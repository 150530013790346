import { GridColDef } from "@mui/x-data-grid";

export const RecentPayColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "dateCompleted",
		headerName: "Date Completed",
		width: 230,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "netAmount",
		headerName: "Net Pay",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
];
