import { Box, Modal } from "@mui/material";
import { ModalStyle } from "../../helpers/appHelpers";
import { SetStateAction } from "react";

interface DeleteModalProps {
	openDeleteModal: boolean;
	setOpenDeleteModal: React.Dispatch<SetStateAction<boolean>>;
	handleDeleteMethod: () => void;
	spanContent?: string;
	deleting?: boolean;
}
const DeleteModal = ({
	openDeleteModal,
	spanContent,
	setOpenDeleteModal,
	handleDeleteMethod,
	deleting
}: DeleteModalProps) => {
	return (
		<Modal
			open={openDeleteModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{ width: 400, ...ModalStyle }}>
				<p className="text-center">
					<span className="text-secondary-red font-medium">{"This action is irreversible! "}</span>
					<span>Are you sure you want to delete </span>
					{spanContent ? <span className="font-bold"> {" " + spanContent}</span> : " this row"}?
				</p>

				<div className="flex w-full justify-center space-x-4 items-center mt-10 text-center whitespace-nowrap">
					<button
						onClick={() => setOpenDeleteModal(false)}
						className={`w-full lg:w-fit lg:px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs ${deleting && "opacity-50"}`}
						disabled={deleting}
					>
						Cancel
					</button>
					<button
						onClick={handleDeleteMethod}
						className={`w-full lg:w-fit lg:px-12 py-4 border text-white hover:opacity-90 bg-secondary-red rounded-lg text-2xs ${deleting && "opacity-50"}`}
						disabled={deleting}
					>
						{deleting ? "Deleting..." : "Yes, Delete"}
					</button>
				</div>
			</Box>
		</Modal>
	);
};

export default DeleteModal