import { useState } from "react";
import { EmployeeDto } from "../../../../models/employee.dto";
import { useUpdateCompanyTree } from "../../../../services/company-tree-node.service";
import { useOrganizationTreeStore, TreeNode } from "../../../../state-management/useOrganizationTreeStore";
import { List, ListItem, Popover, Typography } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import useUserStore from "../../../../state-management/useUserStore";
import API from '../../../../services/utils/api';

type UpdateEmployeeModalProps = {
    anchorEl: any;
    selectedNode: TreeNode;
    employees: EmployeeDto[];
    handleClose: (closeMenu?: any) => void;
}

const UpdateEmployeeModal = ({
    anchorEl,
    selectedNode,
    employees,
    handleClose,
}: UpdateEmployeeModalProps) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
    const { generateTree } = useOrganizationTreeStore();
    const { mutate: updateTreeNode } = useUpdateCompanyTree();
	const [inputValue, setInputValue] = useState("");
	const [employeesState, setEmployeesState] = useState(employees);
    const [uploading, setUploading] = useState(false);

	const updateInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		setInputValue(input.value)
	};

	const searchItems = () => {
		const lowercasedInputValue = inputValue.trim().toLowerCase();

		if (lowercasedInputValue === "") {
            setEmployeesState(employees);
            return
        }

		const filteredEmployees = employees.filter(profile => {
            const fullName = profile.firstName + " " + profile.lastName;
            return fullName.toLowerCase().includes(lowercasedInputValue.toLowerCase())
        });
		
        setEmployeesState(filteredEmployees);
	};

    const updateEmployee = (id: number) => {
        setUploading(true);

        updateTreeNode(
            { 
                companyId: companyProfile.companyId,
                nodeId: selectedNode.id,
                payload: { employeeId: id as number }
            },
            {
                onSuccess: async () => {
                    const { data } = await API.HrAPI.getCompanyTree(companyProfile.companyId);
                    generateTree(data);
                    handleClose("true");
                    setUploading(false);
                },
                onError: () => setUploading(false)
            }
        )
    };

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    maxHeight: "265px",
                    width: "300px",
                    borderRadius: 8,
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            <div className="h-full w-full px-4 flex flex-col text-sm overflow-y-auto">
                <div className="sticky top-0 pt-4 w-full z-[99999] bg-white">
                    <div className="w-full relative">
                        <FiSearch className="absolute top-1/2 -translate-y-1/2 left-5 text-gray-500 text-lg" />
                        <input
                            type="text"
                            className="w-full bg-secondary-gray rounded-lg py-3 pl-12 pr-4 bg text-2xs"
                            placeholder="Search employees"
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                                searchItems();
                            }}
                            onKeyUp={(e) => {
                                updateInputValue(e);
                                searchItems();
                            }}
                        />
                    </div>
                </div>
                <List className="w-full">
                    {employeesState
                    .filter(employee => employee.id !== selectedNode.data.employeeId)
                    .map((employee) => (
                        <ListItem
                            className="cursor-pointer text-sm font-inter text-secondary-darkgray  hover:bg-[#F4F4F4]"
                            key={employee.id}
                            onClick={() => updateEmployee(employee.id)}
                            disabled={uploading}
                        >
                            <Typography>
                                {employee.firstName + " " + employee.lastName}
                            </Typography>
                        </ListItem>
                    ))}

                    {!employeesState.length && <p className="text-center pt-5">No employee found</p>}
                </List>
            </div>
        </Popover>
    );
}
 
export default UpdateEmployeeModal;