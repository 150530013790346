import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import API from './utils/api';
import {
    DisciplinaryActionDto, DisciplinaryActionPaginator,
    CreateDisciplinaryActionDto, QueryDisciplinaryActionDto,
    UpdateDisciplinaryActionDto, SearchDisciplinaryActionDto 
} from '../models/disciplinary-action.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetDisciplinaryAction(options?: QueryDisciplinaryActionDto) {
    const url = ENDPOINTS.HR.DISCIPLINARY_ACTION.GET;
    const request = (options: QueryDisciplinaryActionDto) => API.HrAPI.getDisciplinaryAction(options);

    const { data, isLoading, error, refetch } = useQuery<DisciplinaryActionPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption},
    );

    return { data, isLoading, error, refetch };
}

export function useGetDisciplinaryActionBySearch() {

    return useMutation(
        (options?: SearchDisciplinaryActionDto) => API.HrAPI.getDisciplinaryActionBySearch(options),
        {
            onSuccess: (response,) => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetDisciplinaryActionById(id: number){
    const url = ENDPOINTS.HR.DISCIPLINARY_ACTION.DETAIL;
    const request = API.HrAPI.getDisciplinaryActionById(id);

    const { data, isLoading, error } = useQuery<{ data: DisciplinaryActionDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );
    
    return { data, isLoading, error };
}


export function useCreateDisciplinaryAction(onSuccessCallback?: (responsData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateDisciplinaryActionDto }) => API.HrAPI.createDisciplinaryAction(payload),
        {
            onSuccess: (response) => {
                if (onSuccessCallback) {
                    onSuccessCallback(response);
                };
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.DISCIPLINARY_ACTION.GET);
            },
        },
    );
}

export function useUpdateDisciplinaryAction() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, payload }: { id: number, payload: UpdateDisciplinaryActionDto }) => API.HrAPI.updateDisciplinaryAction(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.DISCIPLINARY_ACTION.GET);
            },
        },
    );
}

export function useDeleteDisciplinaryAction() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteDisciplinaryAction(id),
        {
            onSuccess: () => {
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.DISCIPLINARY_ACTION.GET);
            },
        },
    );
}