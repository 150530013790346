import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";

export const CompanyPayrollRunColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "initiatedBy",
		headerName: "Initiated By",
		headerClassName: "super-app-theme--header",
		width: 280,
		renderCell: (params) => {
			const user = params.value || undefined;
			return (
				<div className="flex items-center space-x-4">
					{user && <>
						<Avatar
							src={user.photoUrl || ""}
							alt="user"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{user.firstName + " " + user.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "totalCost",
		headerName: "Total Cost",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "numberOfEmployees",
		headerName: "Number Of Employees",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];

export const CompanyPayslipColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 250,
		renderCell: (params: any) => {
			const employee = params.row.employee || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "netAmount",
		headerName: "Net Pay",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "initializedBy",
		headerName: "Initialized By",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "executedBy",
		headerName: "Executed By",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "firstReviewer",
		headerName: "First Reviewer",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "secondReviewer",
		headerName: "Second Reviewer",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];
