import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import { 
    CreateEmployeeDocumentDto,
    EmployeeDocumentDto,
    EmployeeDocumentPaginator,
    QueryEmployeeDocumentDto,
    UpdateEmployeeDocumentDto,
} from '../models/employee-document.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetEmployeeDocument(options?: QueryEmployeeDocumentDto) {
    const url = ENDPOINTS.HR.EMPLOYEE_DOCUMENT.GET;
    const request = (options: QueryEmployeeDocumentDto) => API.HrAPI.getEmployeeDocument(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeDocumentPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetEmployeeDocumentById(id: number) {
    const url = ENDPOINTS.HR.EMPLOYEE_DOCUMENT.DETAIL;
    const request = API.HrAPI.getEmployeeDocumentById(id);

    const { data, isLoading, error } = useQuery<{ data: EmployeeDocumentDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return { data, isLoading, error };
}

export function useCreateEmployeeDocument() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload }: { payload: CreateEmployeeDocumentDto }) => API.HrAPI.createEmployeeDocument(payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.GET);
            },
        },
    );
}

export function useUpdateEmployeeDocument() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, id }: { payload: UpdateEmployeeDocumentDto, id: number }) => API.HrAPI.updateEmployeeDocument(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.GET);
            },
        },
    );
}

export function useDeleteEmployeeDocument() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id }: { id: number }) => API.HrAPI.deleteEmployeeDocument(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.HR.EMPLOYEE_DOCUMENT.GET);
            },
        },
    );
}