import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import useUserStore from "../../../../../state-management/useUserStore";
import { useCreateCompanyLevel } from "../../../../../services/company-level.service";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";

interface IFormInputs {
	levelNumber: number;
	levelName: string;
	parentId?: number;
	childId?: number;
	juniorLevel: boolean;
}

interface INewCompanyLevelForm {
	companyLevels: CompanyLevelDto[];
	setShowNewCompanyLevelForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewCompanyLevelForm = ({
	companyLevels,
	setShowNewCompanyLevelForm,
}: INewCompanyLevelForm) => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createCompanyLevel } = useCreateCompanyLevel();
	const { addNewAccordionsData } = useManageAccordionsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (!data.parentId) delete data.parentId;
		if (!data.childId) delete data.childId;

		createCompanyLevel(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
					organizationId: currentUser.user.organization?.id as string,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewCompanyLevelForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Level Name" for={`levelName`} />
					<input
						placeholder={"Enter level name"}
						type="text"
						{...register(`levelName`, {
							required: "Enter level name here",
						})}
						id={`levelName`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.levelName
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.levelName && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.levelName?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Level Number" for={`levelNumber`} />
					<input
						placeholder={"Enter level number"}
						type="number"
						{...register(`levelNumber`, {
							required: "Enter level number here",
						})}
						id={`levelNumber`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.levelNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.levelNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.levelNumber?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Parent Level" for="parentId" optional />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.parentId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("parentId", {
							required: false,
							valueAsNumber: true
						})}
					>
						<option value="">
							Select Parent Level
						</option>
						{companyLevels.map((companyLevel) => (
							<option 
								key={companyLevel.id} 
								value={companyLevel.id} 
							>
								{companyLevel.levelName}
							</option>
						))}
					</select>
				</div>
				<div className="w-full">
					<Label title="Child Level" for="childId" optional />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.childId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("childId", {
							required: false,
							valueAsNumber: true
						})}
					>
						<option value="">
							Select Child Level
						</option>
						{companyLevels.map((companyLevel) => (
							<option 
								key={companyLevel.id} 
								value={companyLevel.id} 
							>
								{companyLevel.levelName}
							</option>
						))}
					</select>
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("juniorLevel")}
						id="juniorLevel"
					/>
					<label htmlFor="juniorLevel">Junior Staff</label>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewCompanyLevelForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Company Level"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewCompanyLevelForm;
