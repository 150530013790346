import { APP_CONFIG } from '../helpers/appHelpers';
import { CompanyCurrencyConfigDto } from './company-currency.dto';
import { EmployeeDto } from './employee.dto';
import { PaginatorInfo } from './generic.dto';
import { REQUEST_QUERY_MODE } from './leave-request.dto';

export const REIMBURESEMENT_REQUEST_STATUS = {
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  QUERIED: "QUERIED",
  COMPLETED: "COMPLETED",
}
  
export type REIMBURESEMENT_REQUEST_STATUS = keyof typeof REIMBURESEMENT_REQUEST_STATUS;

  type ReimbursementRequest = {
    id: number;
    employeeId: number;
    title: string;
    description: string;
    currencyId: number;
    amount: number
    status: REIMBURESEMENT_REQUEST_STATUS;
    expenditureDate: string;
    approverId: number | null;
    completerId: number | null;
    createdAt: string;
    modifiedAt: string | null;
    statusLastModifiedAt: string | null;
    approvedAt: string | null;
    completedAt: string | null;
  }

  type ReimbursementRequestAttachment = {
    id: number;
    requestId: number;
    uploaderId: number;
    attachmentUrl: string;
    createdAt: string;
  }

  type REIMBURESEMENT_REQUEST_STATE = "QUERY" | "APPROVAL" | "COMPLETION";

  type ReimbursementRequestComment = {
    id: number;
    requestId: number;
    commenterId: number;
    commenter: EmployeeDto;
    requestState: REIMBURESEMENT_REQUEST_STATE;
    comment: string;
    createdAt: string;
    modifiedAt: string | null;
  }

  export class CreateReimbursementRequestDto{
    employeeId!: number;
    title!: string;
    description!: string;
    currencyId!: number;
    amount!: number;
    expenditureDate!: string;
    attachmentUrls?: string[];
  }
  
  export class CreateReimbursementAttachment {
    uploaderId: number;
    attachmentUrl: string;
  
    constructor(attachmentUrl: string, employeeId: number) {
      this.uploaderId = employeeId;
      this.attachmentUrl = attachmentUrl;
    }
  }
  
  export class CreateReimbursementAttachmentWithReqId {
    uploaderId: number;
    attachmentUrl: string;
    requestId: number;
  
    constructor(attachmentUrl: string, employeeId: number, requestId: number) {
      this.uploaderId = employeeId;
      this.attachmentUrl = attachmentUrl;
      this.requestId = requestId;
    }
  }
  
  export interface ReimbursementRequestDto  extends ReimbursementRequest {
    employee?: EmployeeDto;
    approver?: EmployeeDto | null;
    completer?: EmployeeDto | null;
    currency?: CompanyCurrencyConfigDto;  
    requestAttachments?: ReimbursementRequestAttachment[]
    requestComments?:    ReimbursementRequestComment[]
  }
  
  export class UpdateReimbursementRequestDto{
    title?: string;
    description?: string;
    currencyId?: number;
    amount?: number;
    expenditureDate?: string;
  }
  
  export enum ReimbursementRequestOrderBy {
    TITLE_ASC = 'title:asc',
    TITLE_DESC = 'title:desc',
    EXPENDITURE_DATE_ASC = 'expenditureDate:asc',
    EXPENDITURE_DATE_DESC = 'expenditureDate:desc',
    APPROVED_AT_DATE_ASC = 'approvedAt:asc',
    APPROVED_AT_DATE_DESC = 'approvedAt:desc',
    COMPLETED_AT_DATE_ASC = 'completedAt:asc',
    COMPLETED_AT_DATE_DESC = 'completedAt:desc',
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
  }
  
  export class QueryReimbursementRequestDto {
    employeeId?: number;
    status?: REIMBURESEMENT_REQUEST_STATUS;
    'expenditureDate.gte'?: string;
    'expenditureDate.lte'?: string;
    approverId?: number;
    completerId?: number;
    queryMode?: REQUEST_QUERY_MODE;
    'createdAt.gte'?: string;
    'createdAt.lte'?: string;
    'approvedAt.gte'?: string;
    'approvedAt.lte'?: string;
    'completedAt.gte'?: string;
    'completedAt.lte'?: string;
    page: number = 1;
    limit: number = APP_CONFIG.PAGE_LIMIT;
    orderBy: ReimbursementRequestOrderBy = ReimbursementRequestOrderBy.CREATED_AT_ASC;
  }
  
  export const REIMBURSEMENT_RESPONSE_ACTION = {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
    QUERY: 'QUERY'
  };
  
  export type REIMBURSEMENT_RESPONSE_ACTION = 'APPROVE' | 'REJECT' | 'QUERY';
  
  export class ReimbursementResponseInputDto {
    action!: REIMBURSEMENT_RESPONSE_ACTION;
    comment?: string;
    attachmentUrls?: string[];
  }
  
  export class ReimbursementRequestUpdatesDto {
    comment?: string;
    attachmentUrls?: string[];
  }
  
  export class CompleteReimbursementRequestDto {
    comment?: string;
  }

  export class SearchReimbursementRequestDto {
    q?: string;
    queryMode?: REQUEST_QUERY_MODE;
    page = 1;
    limit?: number;
    orderBy: ReimbursementRequestOrderBy = ReimbursementRequestOrderBy.CREATED_AT_DESC;
  }

  export interface ReimbursementRequestPaginator extends PaginatorInfo<ReimbursementRequestDto> {}