import React, { useRef, useEffect } from "react";
import { Chart, ChartOptions, registerables } from "chart.js";
import {
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar as BarChartJs2 } from "react-chartjs-2";

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface IBarChart {
	dataSet: any[];
}

interface StatusCounts {
	[year: string]: {
		year: string;
		ACTIVE?: number;
		TERMINATED?: number;
		ELAPSED_CONTRACT?: number;
		DISABLED?: number;
		ARCHIVED?: number;
	};
}

const BarChart = ({ dataSet }: IBarChart) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null);
	const chartInstanceRef = useRef<Chart | null>(null);

	// Transform data for the stacked bar chart
	const statusCounts: StatusCounts = dataSet.reduce((acc, employee) => {
		const employmentDate = new Date(employee?.employmentDate);
		const year = employmentDate?.toLocaleString("en-US", { year: "numeric" });
		const status = employee?.status;

		if (!acc[year]) {
			acc[year] = { year };
		}
		acc[year][status] = (acc[year][status] || 0) + 1;

		return acc;
	}, {});

	const statusData = Object.values(statusCounts);

	const data = {
		labels: statusData.map((item) => item?.year),
		datasets: [
			{
				label: "Active",
				data: statusData.map((item) => item?.ACTIVE || 0),
				backgroundColor: "#27AE60",
				borderColor: "#27AE60",
				borderWidth: 1,
			},
			{
				label: "Terminated",
				data: statusData.map((item) => item?.TERMINATED || 0),
				backgroundColor: "#EC5557",
				borderColor: "#EC5557",
				borderWidth: 1,
			},
			{
				label: "Elapsed",
				data: statusData.map((item) => item?.ELAPSED_CONTRACT || 0),
				backgroundColor: "#F5DD00",
				borderColor: "#F5DD00",
				borderWidth: 1,
			},
			{
				label: "Disabled",
				data: statusData.map((item) => item?.DISABLED || 0),
				backgroundColor: "#B4B7BD",
				borderColor: "#B4B7BD",
				borderWidth: 1,
			},
			{
				label: "Archived",
				data: statusData.map((item) => item?.ARCHIVED || 0),
				backgroundColor: "#516CB8",
				borderColor: "#516CB8",
				borderWidth: 1,
			},
		],
	};

	const options: ChartOptions<"bar"> = {
		plugins: {
			legend: {
				align: "center",
				position: "bottom", // Set the position to one of the predefined values
				labels: {
					boxWidth: 7,
					boxHeight: 7,
				},
			},
		},
	};

	useEffect(() => {
		// Create or update the chart on component mount or data update
		if (chartRef.current) {
			if (chartInstanceRef.current) {
				// If chart instance exists, destroy it before re-creating
				chartInstanceRef.current.destroy();
			}
			// Create a new chart instance
			chartInstanceRef.current = new Chart(chartRef.current, {
				type: "bar",
				data,
				options,
			});
		}

		return () => {
			// Clean up the chart instance when the component unmounts
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, [data]); // Include data as a dependency to trigger chart update when data changes

	return (
		<div className="w-full">
			<BarChartJs2
				style={{ padding: "20px 0", width: "100%", height: "50%" }}
				data={data}
				options={options}
			/>
		</div>
	);
};

export default BarChart;
