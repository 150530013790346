import { AiOutlinePlus } from "react-icons/ai";
import EditAssetType from "./EditAssetType";
import NewAssetType from "./NewAssetType";
import SkewLoader from "react-spinners/SkewLoader";
import { useState } from "react";
import { AssetTypeDto } from "../../../../../models/assets-type.dto";
import { useGetAssetsType } from "../../../../../services/asset-type.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const AssetTypes = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewAssetTypeForm, setShowNewAssetTypeForm] = useState(false);

	const {
		accordionsData: assetTypes,
		isLoading,
		pageCount,
    } = useManageAccordions<AssetTypeDto>({
		fetchHook: useGetAssetsType,
		options: { companyId: currentCompany?.id }
	});
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Asset Types</h2>
				{!showNewAssetTypeForm && (
					<button
						onClick={() => setShowNewAssetTypeForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Asset Type
					</button>
				)}
			</div>

			{showNewAssetTypeForm && (
				<NewAssetType setShowNewAssetTypeForm={setShowNewAssetTypeForm} />
			)}
			
			{(isLoading && !assetTypes.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{assetTypes.map((assetType) => (
				<EditAssetType 
					key={assetType.id}
					assetType={assetType} 
				/>
			))}

			{(!isLoading && !assetTypes.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no asset types added yet. Click 'Add New Asset Type' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default AssetTypes;
