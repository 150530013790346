import {APP_CONFIG} from "../helpers/appHelpers";
import {PaginatorInfo} from "./generic.dto";
import {CurrencyDto} from "./currency.dto";

export type Country = {
    id: number;
    code: string;
    name: string;
    flagUrl: string;
    active: Boolean;
    currencyId: number;
    maxLoanDeductionPercentage: number;
    applyLoanDeductionOnBasic: Boolean;
    createdAt: string;
    modifiedAt?: string;
    currency?: any;
}
export class CreateCountryDto {
    code!: string;
    name!: string;
    flagUrl!: string;
    active?: boolean;
    currencyId!: number;
    maxLoanDeductionPercentage?: number;
    applyLoanDeductionOnBasic?: boolean;
}

export class UpdateCountryDto {
    name?: string;
    active?: boolean;
    flagUrl?: string;
    currencyId?: number;
    maxLoanDeductionPercentage?: number;
    applyLoanDeductionOnBasic?: boolean;
}

export interface CountryDto extends Country { }

export enum CountryOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    MODIFIED_AT_ASC  = 'modifiedAt:asc',
    MODIFIED_AT_DESC  = 'modifiedAt:desc'
}

export class QueryCountryDto {
    code?: string;
    active?: boolean;
    currencyId?: number;
    page?: number = 1;
    limit?: number = APP_CONFIG.PAGE_LIMIT;
    orderBy?: CountryOrderBy = CountryOrderBy.CREATED_AT_DESC;
}

export type DetailedCountryDto = Country & {
    currency?: Currency | null;
}

type Currency = {
    id: number;
    code: string;
};

export interface CountryPaginator extends PaginatorInfo<CountryDto> {}

export class SearchCountryDto {
    q?: string;
  
    page= 1;
    limit?: number;
    orderBy: CountryOrderBy = CountryOrderBy.CREATED_AT_DESC;
  }