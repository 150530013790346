import { Popover, List, ListItem, Typography } from "@mui/material";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDeleteCompanyTree } from "../../../../services/company-tree-node.service";
import { useOrganizationTreeStore, TreeNode } from "../../../../state-management/useOrganizationTreeStore";
import useUserStore from "../../../../state-management/useUserStore";
import API from '../../../../services/utils/api';

type DeleteNodeModalProps = {
    anchorEl: any;
    nodeId: number;
    childNodes: TreeNode[];
    validParentNodes: TreeNode[];
    handleClose: (closeMenu?: any) => void;
}

const DeleteNodeModal = ({
    anchorEl,
    nodeId,
    childNodes,
    validParentNodes,
    handleClose,
}: DeleteNodeModalProps) => {
	const companyProfile = (useUserStore().currentUser.user as any).companyProfiles[0];
    const { generateTree } = useOrganizationTreeStore();
    const { mutate: deleteTreeNode } = useDeleteCompanyTree();
	const [successorParentId, setSuccessorParentId] = useState<number>();
	const [inputValue, setInputValue] = useState("");
    const [deleting, setDeleting] = useState(false);
	const [validParentNodesState, setValidParentNodesState] = 
        useState(validParentNodes);

	const updateInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		setInputValue(input.value)
	};

	const searchItems = () => {
		const lowercasedInputValue = inputValue.trim().toLowerCase();

		if (lowercasedInputValue === "") {
            setValidParentNodesState(validParentNodes);
            return
        }

		const filteredNodes = validParentNodes.filter(node => {
            const employee = node.data.employee;
            const fullName = employee?.firstName + " " + employee?.lastName;
            return fullName.toLowerCase().includes(lowercasedInputValue.toLowerCase())
        });
		
        setValidParentNodesState(filteredNodes);
	};

	const handleDeleteTreeNode = () => {
        if (childNodes.length && !successorParentId) return;
        
        setDeleting(true);

        const payload = successorParentId ? { successorParentId } : {};
        const companyId = companyProfile.companyId;

        deleteTreeNode(
            { companyId, nodeId, payload },
            {
                onSuccess: async () => {
                    const { data } = await API.HrAPI.getCompanyTree(companyProfile.companyId);
                    generateTree(data);
                    handleClose("true");
                    setDeleting(false);
                },
                onError: () => setDeleting(false)
            }
        )
    };

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    maxHeight: "370px",
                    width: "330px",
                    borderRadius: 8,
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            {childNodes.length ? (<>
                <h4 className="text-sm font-medium">Select Successor</h4>
                <p className="text-xs text-[#727272]">New supervisor for child nodes</p>
                <div className="w-full relative bg-white mt-2">
                    <FiSearch className="absolute top-1/2 -translate-y-1/2 left-5 text-gray-500 text-lg" />
                    <input
                        type="text"
                        className="w-full bg-secondary-gray rounded-lg py-3 pl-12 pr-4 bg text-2xs"
                        placeholder="Search employees"
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            searchItems();
                        }}
                        onKeyUp={(e) => {
                            updateInputValue(e);
                            searchItems();
                        }}
                    />
                </div>
                <div className="w-full flex flex-col text-sm my-2 overflow-y-auto">
                    <List className="w-full">
                        {validParentNodesState.map((node) => (
                            <ListItem
                                key={node.id}
                                className={`cursor-pointer rounded-lg text-sm font-inter text-secondary-darkgray 
                                    ${node.id === successorParentId ? "bg-[#E1E1E1]" : "hover:bg-[#F4F4F4]"} 
                                `}
                                onClick={() => setSuccessorParentId(node.id)}
                                disabled={deleting}
                            >
                                <Typography>
                                    {node.data.employee?.firstName + " " + node.data.employee?.lastName}
                                </Typography>
                            </ListItem>
                        ))}

                        {!validParentNodesState.length && <p className="text-center py-5">No employee found</p>}
                    </List>
                </div>
            </>) : null}
            <p className="text-sm">
                Are you sure? This action is <span className="text-secondary-red"> irreversible!</span>
            </p>
            <button 
                onClick={handleDeleteTreeNode}
                disabled={deleting || Boolean(!successorParentId && childNodes.length)}
                className={`w-full bg-secondary-red py-3 rounded-lg text-white text-2xs
                    capitalize mt-2 node-button ${!deleting && "hover:shadow-sm"}
                `}
            >
                {deleting ? "Deleting..." : "Delete Node"}
            </button>
        </Popover>
    );
}
 
export default DeleteNodeModal;