import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useDeleteUnion, useUpdateUnion } from "../../../../../services/union.service";
import { UnionDto } from "../../../../../models/union.dto";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";
import MoneyInput from "../../../../../components/form_fields/MoneyInput";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
    code: string;
    name: string;
    description?: string;
    duesCollected: number;
    deductionFrequency: string;
	currencyId: number;
    active?: boolean;
}

interface IEditUnion {
	union: UnionDto;
	companyCurrencies: CompanyCurrencyConfigDto[];
}

const EditUnion = ({ union, companyCurrencies }: IEditUnion) => {
	const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateUnion } = useUpdateUnion();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: union.code,
			name: union.name,
			description: union.description,
			duesCollected: union.duesCollected,
			deductionFrequency: union.deductionFrequency as string,
			currencyId: union.currencyId as number,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
		setUploading(true);

		updateUnion(
			{ payload: data, id: union.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteAssetType } = useDeleteUnion();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteAssetType(
			{ id: union?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={union.id + "bh-content"}
				id={union.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{union.name}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Name" for="name" />
								<input
									defaultValue={union.name}
									disabled={formDisabled}
									placeholder={"Enter name"}
									type="text"
									{...register("name", {
										required: "Enter name here",
									})}
									id="name"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.name && (
									<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: union.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full col-span-2">
								<Label title="Description" for="description" optional />
								<FormDescriptionInput 
									attributes={{
										defaultValue: union.description,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.description
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`
									}}
									register={register}
									required={false}
									disabled={formDisabled}
								/>
							</div>
							<div className="w-full">
								<Label title="Dues Collected" for="duesCollected" />
								<MoneyInput 
									attributes={{
										id: "duesCollected",
										placeholder: "Enter dues collected",
										disabled: formDisabled,
									}}
									register={register}
									name="duesCollected"
									setValue={setValue}
									defaultValue={union.duesCollected}
									required={"Enter dues collected here"}
									error={errors.duesCollected}
								/>
								{errors.duesCollected && (
									<p className="text-red-500 text-sm mt-1">
										{errors.duesCollected.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Currency" for="currencyId" />
								<select
									defaultValue={union.currencyId || undefined}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
										errors.currencyId
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("currencyId", {
										required: "Select currency here",
										valueAsNumber: true
									})}
								>
									<option value="">
										Select Currency
									</option>
									{companyCurrencies.map((currency) => (
										<option 
											key={currency.id} 
											value={currency.id} 
										>
											{currency?.currency?.name}
										</option>
									))}
								</select>
								{errors.currencyId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.currencyId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Deduction Frequency" for="deductionFrequency" />
								<select
									defaultValue={union.deductionFrequency || undefined}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.deductionFrequency
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("deductionFrequency", {
										required: "Select deduction frequency here",
									})}
								>
									<option value="">
										Select Type
									</option>
									<option value="DAILY">Daily</option>
									<option value="WEEKLY">Weekly</option>
									<option value="MONTHLY">Monthly</option>
									<option value="QUARTERLY">Quarterly</option>
									<option value="ANNUALLY">Annually</option>
								</select>
								{errors.deductionFrequency && (
									<p className="text-red-500 text-sm mt-1">{errors.deductionFrequency.message}</p>
								)}
							</div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									defaultChecked={union.active === true}
									disabled={formDisabled}
									type="checkbox"
									{...register("active", {
										required: false
									})}
									id="active"
								/>
								<label htmlFor="active">Active</label>
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={union.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
);
};

export default EditUnion;
