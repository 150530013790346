import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { FREQUENCY } from "../../../../../models/savings-scheme.dto";
import { useCreateWorkDaysInAPeriod } from "../../../../../services/work-days-in-a-period.service";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import { enumToStringConverter } from "../../../../../helpers/appHelpers";

interface IFormInputs {
	period: keyof typeof FREQUENCY
	numberOfDays: number
}

interface INewWorkDaysInAPeriod {
	setShowNewWorkDaysInAPeriodForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewWorkDaysInAPeriod = ({
	setShowNewWorkDaysInAPeriodForm,
}: INewWorkDaysInAPeriod) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createWorkDaysInAPeriod } = useCreateWorkDaysInAPeriod();
	const { addNewAccordionsData } = useManageAccordionsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createWorkDaysInAPeriod(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewWorkDaysInAPeriodForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Period" for={`period`} />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.period
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register(`period`, {
							required: "Select period here",
						})}
					>
						<option value="">
							Select Period
						</option>
						{Object.keys(FREQUENCY).map(item => (
							<option value={item}>{enumToStringConverter(item)}</option>
						))}
					</select>
					{errors?.period && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.period?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Number Of Days" for={`numberOfDays`} />
					<input
						placeholder={"Enter number"}
						type="number"
						{...register(`numberOfDays`, {
							required: "Enter number of days here",
							valueAsNumber: true
						})}
						id={`numberOfDays`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.numberOfDays
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.numberOfDays && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.numberOfDays?.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewWorkDaysInAPeriodForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : `Add Work Days in a Period`}`}
					className={"h-14 px-8"}
					type="submit"
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewWorkDaysInAPeriod;
