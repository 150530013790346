import { useContext, useState } from "react";
import { EmployeeDataContext } from "..";
import { useGetUnion } from "../../../../services/union.service";
import NewEmployeeUnion from "../../../shared/employees/EditEmployee/forms/union-membership/NewEmployeeUnion";
import useUserStore from "../../../../state-management/useUserStore";
import EditEmployeeUnion from "../../../shared/employees/EditEmployee/forms/union-membership/EditEmployeeUnion";
import { EmployeeUnionDto, EmployeeUnionOrderBy } from "../../../../models/employee-union.dto";
import { AiOutlinePlus } from "react-icons/ai";
import { useGetEmployeeUnion } from "../../../../services/employee-union.service";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { UnionOrderBy } from "../../../../models/union.dto";
import { SkewLoader } from "react-spinners";

const EmployeeUnionsView = () => {
	const employeeData = useContext(EmployeeDataContext)?.employeeData;
	const [showNewUnionForm, setShowNewUnionForm] = useState(false);
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];

	const { data: employeeUnions, isLoading } = useGetEmployeeUnion({ 
		employeeId: employeeData?.id as number,
		orderBy: EmployeeUnionOrderBy.CREATED_AT_DESC,
	});

	const { data: unions } = useGetUnion({ 
		companyId: companyProfile?.companyId,
		active: true,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: UnionOrderBy.NAME_ASC,
	});
	
	return (
		<div className="mb-4 lg:w-[80%]">
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-2xs lg:text-sm">
				<h2 className="font-semibold">Available Unions</h2>
				{!showNewUnionForm && (
					<button
						onClick={() => setShowNewUnionForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-1 lg:mr-2 text-2sm lg:text-lg" />
						Add New Union
					</button>
				)}
			</div>

			{showNewUnionForm && (
				<NewEmployeeUnion 
					employeeId={employeeData?.id as number}
					companyId={companyProfile.companyId}
					unions={unions?.data || []}
					setShowNewUnionForm={setShowNewUnionForm} 
				/>
			)}
			
			{isLoading && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{(employeeUnions as EmployeeUnionDto[]).map((employeeUnion) => (
				<EditEmployeeUnion 
					key={employeeUnion.id}
					employeeUnion={employeeUnion}
					unions={unions?.data || []}
				/>
			))}

			{(!isLoading && employeeUnions.length === 0) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no unions added yet. Click 'Add New Union' to begin.
					</h3>
				</div>
			)}
		</div>
	);
}
 
export default EmployeeUnionsView;