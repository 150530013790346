import { useState } from "react";
import { SkewLoader } from "react-spinners";
import { AnnouncementDto } from "../../../../models/announcement.dto";
import CustomPagination from "../../../../components/table/CustomPagination";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Avatar, 
    Box, 
    Modal 
} from "@mui/material";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../helpers/appHelpers";
import APISearchInput from "../../../../components/search/APISearchInput";
import { useGetMyAnnouncementBySearch } from "../../../../services/announcement.service";
import { IoMdClose } from "react-icons/io";
import { useViewPort } from "../../../../helpers/useViewPort";

type AnnouncementsViewProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    announcements: AnnouncementDto[];
    isLoading: boolean;
    pageCount: number;
    selectedAnnouncementId?: number;
}

const AnnouncementsView = ({ 
    open, 
    setOpen, 
    announcements: announcementsData,
    isLoading, 
    pageCount, 
    selectedAnnouncementId, 
}: AnnouncementsViewProps) => {
	const viewPortWidth = useViewPort();
    const [announcements, setAnnouncements] = useState(announcementsData);
    const { mutate: searchAnnouncements } = useGetMyAnnouncementBySearch();
    
    const [expanded, setExpanded] = useState<Record<string, boolean>>(
        announcements.reduce((acc, announcement) => {
            return { ...acc, [`${announcement.id}`]: selectedAnnouncementId === announcement.id }
        }, {})
    );

    const toggleExpanded = (id: number) => {
        setExpanded(previousValue => {
            if (previousValue[id]) {
                return { ...previousValue, [id]: false };
            }
            return { ...previousValue, [id]: true };
        });
    };

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{ ...getStyle(viewPortWidth > 1024 ? 750 : "95%") }}>
				<div className="flex justify-between items-center px-6 lg:px-9 pt-5 lg:pt-9 mb-4">
					<h1 className="font-bold text-lg">Announcements</h1>
					<button onClick={() => setOpen(false)}>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<div className="grow px-6 lg:px-9 pb-5 lg:pb-9 overflow-auto scroll-smooth">
                    <div className="lg:w-fit mb-6">
                        <APISearchInput 
                            fallbackData={announcementsData}
                            setTableData={setAnnouncements}
                            mutate={searchAnnouncements}
                        />
                    </div>

                    {isLoading && (
                        <div className="text-center mt-10 py-10">
                            <SkewLoader color="#F58B00" />
                        </div>
                    )}
        
                    {announcements.map((announcement) => (
                        <Accordion
                            expanded={expanded[announcement.id]}
                            onChange={() => toggleExpanded(announcement.id)}
                            classes={{
                                root: "border border-gray-300 rounded-lg lg:py-2 mb-2",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<AiOutlineDownCircle className="text-lg lg:text-xl" />}
                                aria-controls={announcement.id + "bh-content"}
                                id={announcement.id + "bh-header"}
                                sx={{ border: "1px" }}
                            >
                                <h2 className="px-1 lg:px-3 font-inter text-2xs lg:text-sm font-semibold">
                                    {announcement.title}
                                </h2>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="px-1 lg:px-3">
                                    <p className="text-charcoal max-lg:text-sm">{announcement.body}</p>
                                    <div className={`flex gap-4 ${announcement.resources?.length ? "mt-6" : ""}`}>
                                        {announcement.resources?.map((resource, index) => (
                                            <Link 
                                                key={resource.id}
                                                to={resource.url} 
                                                target="_blank"
                                                className="w-fit"
                                            >
                                                <Avatar
                                                    alt={"file " + (index + 1)}
                                                    src={resource.url}
                                                    variant="rounded"
                                                    className="border border-primary-blue hover:border-2 cursor-pointer"
                                                    sx={{ width: 80, height: 80 }}
                                                />
                                            </Link>
                                        ))}
                                    </div>
                                    <p className="mt-4 text-xs font-semibold">
                                        Published At: {formatDate({ value: announcement.publishDate } as any)}
                                    </p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    <div className="w-full flex justify-end mt-8 lg:mb-4">
                        <CustomPagination pageCount={pageCount} />
                    </div>
				</div>
			</Box>
		</Modal>
	);
};

export default AnnouncementsView;

const getStyle = (width) => ({
    width,
    maxHeight: "95svh",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "none",
    borderRadius: "8px",
    outline: "none",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
});