import { BenefitTax } from "./benefit-tax.dto";
import { Country } from "./country.dto";
import {PaginatorInfo} from "./generic.dto";

export type TaxRate = {
  id: number
  countryId: number
  minTaxableAmount: number
  maxTaxableAmount: number
  rateComponent: number
  fixedComponent: number | null
  onDirectIncome: boolean
  benefitTaxId: number | null
  incomePeriod: INCOME_PERIOD
  createdAt: string
  modifiedAt: string | null
}

export const INCOME_PERIOD = {
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export type INCOME_PERIOD = (typeof INCOME_PERIOD)[keyof typeof INCOME_PERIOD]

export class CreateTaxRateDto {
  countryId!: number
  minTaxableAmount!: number
  maxTaxableAmount!: number
  rateComponent!: number
  fixedComponent?: number
  onDirectIncome!: boolean
  benefitTaxId?: number
  incomePeriod!: INCOME_PERIOD
}

export interface TaxRateDto extends TaxRate {
  benefitTax?: BenefitTax
  country?: Country
}

export interface TaxRatePaginator extends PaginatorInfo<TaxRateDto> {}
export class UpdateTaxRateDto {
  minTaxableAmount?: number
  maxTaxableAmount?: number
  rateComponent?: number
  fixedComponent?: number
  onDirectIncome?: boolean
  benefitTaxId?: number
  incomePeriod?: INCOME_PERIOD
}

export class QueryTaxRateDto {
  countryId?: number;
  onDirectIncome?: boolean;
  benefitTaxId?: number;
  incomePeriod?: INCOME_PERIOD;
  page?: number = 1;
  limit?: number;
  orderBy?: TaxRateOrderBy = TaxRateOrderBy.CREATED_AT_ASC;
}

export enum TaxRateOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc'
}