import { useContext, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { useDeleteTaxRelief, useGetTaxRelief } from "../../../../../../services/tax-relief.service";
import { AiOutlinePlus } from "react-icons/ai";
import ActionPopover from "../../../../../../components/popovers/actionPopover";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import { ReliefTaxRuleColumns } from "../data";
import ReliefTaxRuleModalForm from "../modals/ReliefTaxRuleModalForm";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { TaxReliefDto } from "../../../../../../models/tax-relief.dto";
import CustomStyledTable from "../../../../../../components/table/CustomStyledTable";
import { ActiveCountryContext } from "..";
import TableFilter from "../../../../../../components/table/filter/TableFilter";
import { APP_CONFIG } from "../../../../../../helpers/appHelpers";
import { INCOME_PERIOD } from "../../../../../../models/tax-rate.dto";
import { useGetTaxReliefType } from "../../../../../../services/tax-relief-type.service";

const ReliefTaxRuleView = () => {
	const countries = useContext(ActiveCountryContext);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);

	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<TaxReliefDto>({
		fetchHook: useGetTaxRelief
	});

	// Used in filter
	const { data: taxReliefTypes } = useGetTaxReliefType({
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "name:asc" as any,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteReliefTaxRule } = useDeleteTaxRelief();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteReliefTaxRule(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>,
				];
			},
		},
	];
	const columnsWithAdditions = ReliefTaxRuleColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-7 mb-8">
				<TableFilter
					fields={[
						{
							title: "",
							fieldName: "",
							fieldType: "SORT",
							options: [
								{
									title: "Date Created",
									fieldName: "createdAt",
								},
								{
									title: "Date Modified",
									fieldName: "modifiedAt",
								},
							]
						},
						{
							title: "Country",
							fieldName: "countryId",
							fieldType: "SELECT_FIELD",
							options: countries,
						},
						{
							title: "Tax Relief Type",
							fieldName: "taxReliefTypeId",
							fieldType: "SELECT_FIELD",
							options: taxReliefTypes?.data || [],
						},
						{
							title: "Income Period",
							fieldName: "incomePeriod",
							fieldType: "CHECKBOX_LIST",
							options: INCOME_PERIOD,
						},
					]}
				/>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New 	
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<ReliefTaxRuleModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
							? "Update Relief Rule"
							: "Add New Relief Rule"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
				/>
			)}
		</>
	);
};

export default ReliefTaxRuleView;
