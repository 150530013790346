import React from "react";
import { useSearchParams } from "react-router-dom";

interface ICustomTab {
	view: string;
	setView: (view: string) => void;
	searchParam?: string;
	children?: React.ReactNode;
	tabColumns: any[];
	isCount?: boolean;
	count?: number;
}

const CustomTab = ({
	view,
	setView,
	searchParam,
	children,
	tabColumns,
	isCount,
	count,
}: ICustomTab) => {
	const [_, setSearchParams] = useSearchParams();

	const changeView = (item: any) => {
		setView(item.view);
		setSearchParams({ [searchParam ? searchParam : "view"]: item.view });
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="flex space-x-6 border-b overflow-auto children-scroll-bar whitespace-nowrap w-full mobile-full-width max-lg:pl-4">
					{tabColumns.map((item: any, key) => (
						<button
							key={key}
							onClick={() => changeView(item)}
							className={`flex pb-3 max-lg:text-sm ${
								view === item.view
									? "border-b-2 border-primary-mango text-charcoal"
									: "text-gray-400"
							}`}
						>
							{item.name}
							{item.view === "pendingApprovals" && isCount && (
								<div className="bg-secondary-orange text-white ml-3 rounded-full w-6 h-6 flex items-center justify-center text-2xs">
									{count}
								</div>
							)}
						</button>
					))}
				</div>
			</div>
			{children}
		</>
	);
};

export default CustomTab;
