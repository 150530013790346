import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "mappingCode1",
		headerName: "Mapping Code 1",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "mappingCode2",
		headerName: "Mapping Code 2",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value || "--",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Unique Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "bankBranch",
		headerName: "Payment Institution Branch",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "sequenceNumber",
		headerName: "Sequence Number",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	
	...createdAtmodifiedAtActions
];
