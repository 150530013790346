import {useQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CountOrganizationDto,
    CreateOrganizationDto, OrganizationPaginator, OrganizationWithOptionalRelationsPaginator, QueryOrganizationDto,
    SearchOrganizationDto,
    UpdateOrganizationDto
} from "../models/organization.dto";
import ENDPOINTS from "./utils/endpoints";
import {CompanyPaginator} from "../models/company-account.dto";
import useCompanyStore from "../state-management/useCompanyStore";
import { APP_CONFIG } from "../helpers/appHelpers";

export function useGetOrganization(options?: QueryOrganizationDto) {
    const url = ENDPOINTS.ORGANIZATION.GET;
    const request = (options: QueryOrganizationDto) => API.OrganizationAPI.getOrganization(options);

    const { data, isLoading, error, refetch } = useQuery<OrganizationWithOptionalRelationsPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetOrganizationBySearch() {
    
    return useMutation(
        (options?: SearchOrganizationDto) => API.OrganizationAPI.getOrganizationSearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetCompaniesByOrganizationId(organizationId: string, useSaved: boolean = true) {
	const { savedCompanies } = useCompanyStore();
    const url = ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET;

    const storedCompanies: Promise<CompanyPaginator> = new Promise(function (resolve, reject) {
        return resolve({
            data: savedCompanies,
            page: 0, pageSize: 0, totalCount: 0, status: 200, requestId: '', errors: null, message: ''
        })
    });

    const request = (savedCompanies.length && useSaved) ? storedCompanies :
        API.ProcessingCompanyAPI.getCompany({ organizationId, limit: APP_CONFIG.PAGE_LIMIT });

    const {data, isLoading, error} = useQuery<CompanyPaginator, Error>(
        [url, organizationId],
        (() => request),
        {
            enabled: (savedCompanies.length && useSaved) ? false : !!(organizationId),
        }
    );

    return {
        data,
        isLoading,
        error,
    };

}

export function useCreateOrganization(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: { payload: CreateOrganizationDto }) => API.OrganizationAPI.create(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if (onSuccessCallback) {
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
            },
        }
    )
}

export function useUpdateOrganization() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: { payload: UpdateOrganizationDto, id: number }) => API.OrganizationAPI.update(id, payload),
        {
            onSuccess: () => {
                toast.success('Organization details updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
            },
        }
    )
}

export function useGetOrganizationCount(options?: CountOrganizationDto) {
    const url = ENDPOINTS.ORGANIZATION.GET_COUNT;
    const request = API.OrganizationAPI.getOrganizationCount(options);

    const {data, isLoading, error} = useQuery<{ data: { count: number} }, Error>(
        [url, options],
        (() => request),
    );

    return {
        data,
        isLoading,
        error,
    };
}

export function useUpdateBillingTypeOfOrganization() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: { payload: UpdateOrganizationDto, id: number }) => API.OrganizationAPI.updateBillingTypeId(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
            },
        }
    )
}