import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useCreateAssignedAsset } from "../../../../../../services/assigned-asset.service";
import { AssetTypeDto } from "../../../../../../models/assets-type.dto";
import { compareDateFields } from "../../../../../../helpers/appHelpers";
import useCompanyStore from "../../../../../../state-management/useCompanyStore";
import APISelectField from "../../../../../../components/form_fields/APISelectField";
import useUserStore from "../../../../../../state-management/useUserStore";

interface IFormInputs {
    assetTypeId: number;
    serialNumber: string;
    dateAssigned: string;
    dateReturned: string;
}

interface INewAsset {
	employeeId: number;
	assetTypes: AssetTypeDto[];
	setShowNewAssetForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewAsset = ({ employeeId, assetTypes, setShowNewAssetForm }: INewAsset) => {
	const { userType } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	const { mutate: createEmployeeAsset } = useCreateAssignedAsset();

	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createEmployeeAsset(
			{
				payload: {
					...data,
					employeeId,
					companyId: currentCompany?.id as number, 
				}
			},
			{
				onSuccess: () => setShowNewAssetForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Asset Type" for="assetTypeId" />
					{userType === "ORGANIZATION" ? (
						<APISelectField 
							attributes={{
								id: "assetTypeId",
							}}
							placeholder="Select Type"
							register={register}
							name="assetTypeId"
							options={assetTypes}
							pathname="/dashboard/company-settings?view=assetsType"
							required="Select asset type here"
							error={errors.assetTypeId}
							valueAsNumber
						/>
					):(
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.assetTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("assetTypeId", {
								required: "Select asset type here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Type
							</option>
							{assetTypes.map((assetType) => (
								<option 
									key={assetType.id}
									value={assetType.id}
								>
									{assetType.name}
								</option>
							))}
						</select>
					)}
					{errors.assetTypeId && (
						<p className="text-red-500 text-sm mt-1">{errors.assetTypeId.message}</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Serial Number" for="serialNumber" />
					<input
						placeholder={"Enter serial number"}
						type="text"
						{...register("serialNumber", {
							required: "Enter serial number here",
						})}
						id="serialNumber"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.serialNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.serialNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors.serialNumber.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Assigned Date" for="dateAssigned" />
					<input
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("dateAssigned", {
							required: "Enter assigned date here",
						})}
						id="dateAssigned"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.dateAssigned
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.dateAssigned && (
						<p className="text-red-500 text-sm mt-1">
							{errors.dateAssigned.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Return Date" for="dateReturned" />
					<input
						placeholder={"mm/dd/yyyy"}
						type="date"
						{...register("dateReturned", {
							required: "Enter return date here",
							validate: (value) => compareDateFields(
								watch("dateAssigned"), 
								value,
								"Assigned date must be less than return date"
							),
						})}
						id="dateReturned"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.dateReturned
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.dateReturned && (
						<p className="text-red-500 text-sm mt-1">
							{errors.dateReturned.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewAssetForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Asset"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewAsset;