import {PaginatorInfo} from "./generic.dto";
import { OrganizationDto } from "./organization.dto";

export interface AuthorizedUser {
  userId: string;
  organizationId: string;
  companyIds: string[];
  platformUser: boolean;
}

export const INVOICE_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED'
};

export type INVOICE_STATUS = keyof typeof INVOICE_STATUS;

export const INVOICE_CREATOR = {
  SYSTEM: 'SYSTEM',
  USER: 'USER'
};

export type INVOICE_CREATOR = keyof typeof INVOICE_CREATOR;

export const TRANSACTION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  REVERSAL: 'REVERSAL',
  ALL: 'ALL'
};

export type TRANSACTION_TYPE = keyof typeof TRANSACTION_TYPE;

export const ACTOR_TYPE = {
  SYSTEM: 'SYSTEM',
  USER: 'USER'
};

export type ACTOR_TYPE = keyof typeof ACTOR_TYPE;

export const PRODUCT_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  OTHER: 'OTHER'
};

export type PRODUCT_TYPE = keyof typeof PRODUCT_TYPE

export type Invoice = {
  id: number
  organizationId: string
  referenceCode: string
  description: string
  amount: number
  serviceDate: string
  status: INVOICE_STATUS
  createdBy: INVOICE_CREATOR
  creator: any | null
  productType: PRODUCT_TYPE | null
  createdAt: string
  modifiedAt: string | null
  statusLastModifiedAt: string | null
  paymentCompletedAt: string | null
}


export type InvoiceItem = {
  id: number
  invoiceId: number
  description: string
  amount: number
  createdAt: string
}

export class CreateInvoiceDto {
  organizationId!: string;
  description!: string;
  amount!: number;
  invoiceItems?: InvoiceItemSummaryDto[];
  productType?: PRODUCT_TYPE;
  serviceDate!: string;
  createdBy!: INVOICE_CREATOR;
  // creator?: AuthorizedUser;
}

export class UpdateInvoiceDto {
  statusLastModifiedAt?: string;
  status?: INVOICE_STATUS;
  paymentCompletedAt?: string;
}

export enum invoiceOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
}
export class QueryInvoiceDto {
  organizationId?: string;
  referenceCode?: string;
  status?: INVOICE_STATUS;
  productType?: PRODUCT_TYPE;
  includedRelations?: boolean;
  page?: number = 1;
  limit?: number;
  orderBy?: invoiceOrderBy = invoiceOrderBy.CREATED_AT_DESC;
}

export class PayInvoiceDto {
  amount!: number;
  paidBy!: INVOICE_CREATOR;
  reference!: string;
  narration!: string;
  payer?: AuthorizedUser;
}

export class CancelInvoiceDto {
  reference!: string;
  narration!: string;
}

// dto for presenting to frontend.
export interface InvoiceDto extends Invoice {
  invoiceItems?: InvoiceItemSummaryDto[];
  transactions?: TransactionDto[];
  outstandingInvoice?: outstandingInvoice;
  organization?: OrganizationDto
}
export type InvoiceItemSummaryDto = {
  description: string;
  amount: number;
};
type outstandingInvoice = {
  amount: number;
};
type TransactionDto = {
  currencyId: number;
  priorTransactionId: number;
  amount: number;
  type: TRANSACTION_TYPE;
  narration: string;
  reason: string;
  actorType: ACTOR_TYPE;
  actorId: number;
};

export function toInvoiceDto(invoice: Invoice): InvoiceDto {
  return {
    id: invoice.id,
    organizationId: invoice.organizationId,
    referenceCode: invoice.referenceCode,
    description: invoice.description,
    amount: invoice.amount,
    serviceDate: invoice.serviceDate,
    // invoiceItems:toInvoiceItemList(invoice.invoiceItems),
    productType: invoice.productType,
    status: invoice.status,
    createdBy: invoice.createdBy,
    createdAt: invoice.createdAt,
    creator: invoice.creator,
    modifiedAt: invoice.modifiedAt,
    statusLastModifiedAt: invoice.statusLastModifiedAt,
    paymentCompletedAt: invoice.paymentCompletedAt,
  };
}
// might not be used
export function toInvoiceItem(invoiceItem: InvoiceItem): InvoiceItemSummaryDto {
  return {
    description: invoiceItem.description,
    amount: invoiceItem.amount,
  };
}

export function toInvoiceDtoList(invoice: Invoice[]): InvoiceDto[] {
  return invoice.map((item) => toInvoiceDto(item));
}

export function toInvoiceItemList(invoiceItem: InvoiceItem[]): InvoiceItemSummaryDto[] {
  return invoiceItem.map((item) => toInvoiceItem(item));
}

export interface InvoicePaginator extends PaginatorInfo<InvoiceDto> {
}

export enum InvoiceOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
}

export class SearchInvoiceDto {
  q?: string;

  page= 1;
  limit?: number;
  orderBy: InvoiceOrderBy = InvoiceOrderBy.CREATED_AT_DESC;
}