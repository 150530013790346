import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useSearchParams } from "react-router-dom";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { ADJUSTMENT_OPTIONS, LEAVE_RESPONSE_OPTIONS } from "../../../../models/leave-request.dto";
import { useAddLeaveResponse, useAdjustLeavePackage } from "../../../../services/leave-request.service";

type IFormInputs = {
    action: string;
    adjustment: ADJUSTMENT_OPTIONS;
    count: number;
    comment?: string;
}

type LeaveRequestActionModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	response: boolean;
	tableAPIRef: React.MutableRefObject<GridApiCommunity>;
}

const LeaveRequestActionModal =  ({ 
	open, 
	setOpen, 
	response, 
	tableAPIRef, 
}: LeaveRequestActionModalProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
    const requestId = searchParams.get("requestId");
	const { formDataValues, setFormData } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			action: response ? getCurrentAction(formDataValues?.status) : undefined
		}
	});

	const { mutate: respondToRequest } = useAddLeaveResponse();
	const { mutate: adjustRequest } = useAdjustLeavePackage();

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

        const payload: any = {
			comment: data.comment || undefined,
		}

        if (response) {
			payload.action = data.action;

			respondToRequest(
				{ payload, id: formDataValues?.id },
				{
                    onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setFormData({ ...formDataValues, ...updatedData.data });
						handleClose();
					},
					onSettled: () => setUploading(false)
				}
			)

		} else {
			payload.adjustment = data.adjustment;
			payload.count = data.count;

			adjustRequest(
				{ payload, id: formDataValues?.id },
				{
                    onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setFormData({ ...formDataValues, ...updatedData.data });
						handleClose();
					},
					onSettled: () => setUploading(false)
				}
			)
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();

		if (requestId) {
			const currentParams = new URLSearchParams(window.location.search);
			currentParams.delete("requestId");
			setSearchParams(currentParams);
		}
	};

	return (
		<ModalComponent
			width={660}
			open={open}
			title={response ? "Take Action on Request" : "Leave Adjustment"}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-y-6">
                    {response ? (
                        <div className="w-full">
                            <Label title="Action" for="action" />
                            <select
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.action
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                {...register("action", {
                                    required: "Enter action here"
                                })}
                            >
                                <option value="">Select Action</option>
                                <option value="APPROVE">Approve</option>
                                <option value="DECLINE">Decline</option>
                            </select>
                            {errors.action && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.action?.message}
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="lg:flex lg:space-x-4 max-lg:space-y-6">
                            <div className="w-full">
                                <Label title="Adjustment Type" for="adjustment" />
                                <select
                                    className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                        errors.adjustment
                                            ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`}
                                    {...register("adjustment", {
                                        required: "Select adjustment here"
                                    })}
                                >
                                    <option value="">Select Type</option>
                                    <option value="INCREASE">Increase</option>
                                    <option value="DECREASE">Decrease</option>
                                </select>
                                {errors.adjustment && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.adjustment?.message}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <Label title="Number of Days" for="count" />
                                <div className="flex items-center h-14 mt-2">
                                    <input
                                        placeholder={"0"}
                                        type="number"
                                        {...register("count", {
                                            required: "Enter number of days here",
                                            valueAsNumber: true
                                        })}
                                        id="count"
                                        className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
                                            errors.count
                                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                    />
                                    <div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
                                        Days
                                    </div>
                                </div>
                                {errors.count && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.count.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="w-full">
                        <Label title="Comment" for="comment" optional={response} />
                        <input
                            placeholder={"Enter comment"}
                            type="text"
                            {...register("comment", {
                                required: response ? false : "Enter comment here",
                            })}
                            id="comment"
                            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
                        />
                    </div>
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton 
						type="submit"
						title={uploading ? "Submitting..." : "Submit"}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading} 
					/>
				</div>
			</form>
		</ModalComponent>
	);
};
 
export default LeaveRequestActionModal;

const getCurrentAction = (status: LEAVE_RESPONSE_OPTIONS) => {
	switch(status) {
		case "APPROVED":
			return "APPROVE"
		case "DECLINED":
			return "DECLINE"
		default:
			return ""
	}
}