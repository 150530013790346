import { useState } from "react";
import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { Helmet } from "react-helmet-async";
import { PATHS } from "../../../routes/routes.paths";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { ReimbursementTabColumns } from "../../../components/custom_tab/tabColumns";
import YourClaimsView from "./views/YourClaimsView";
import { useSearchParams } from "react-router-dom";
import SuperviseesClaimsView from "./views/SuperviseesClaimsView";
import useUserStore from "../../../state-management/useUserStore";

const SelfserviceReimbursement = () => {
    const { supervisor } = useUserStore();
	const [searchParams] = useSearchParams();
	const currentView = searchParams.get("view") || "yourClaimsView";
	const [view, setView] = useState(currentView);

	return (
		<SelfserviceLayout pageHeader="Reimbursements">
			<Helmet>
				<title> Reimbursements | Self Service Flow</title>
			</Helmet>
			{supervisor ? (
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={ReimbursementTabColumns}
				>
					<div className="mt-6">
						{view === "yourClaimsView" && <YourClaimsView />}
						{view === "superviseesClaimsView" && <SuperviseesClaimsView />}
					</div>
				</CustomTab>
			):(
				<YourClaimsView />
			)}
		</SelfserviceLayout>
	);
};

export default SelfserviceReimbursement;
