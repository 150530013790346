import { useState } from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import Label from "../../components/form_fields/Label";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import {Helmet} from "react-helmet-async";
import {useConfirmTokenAndCreatePassword} from "../../services/auth.service";
import {toast} from "react-toastify";
import { PATHS } from "../../routes/routes.paths";
import useUserStore from "../../state-management/useUserStore";

interface IFormInputs {
    password: string;
    confirmPassword: string;
}

const SetPassword = () => {
    const navigate = useNavigate();
	const [searchParams] = useSearchParams();
    const token = searchParams.get("code");
    const username = searchParams.get("username");
	const { setCurrentUser, setUserType } = useUserStore();
    const [isShown, setIsShown] = useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        formState: {errors},
    } = useForm<IFormInputs>();

    const { mutate: createPassword, isLoading } = useConfirmTokenAndCreatePassword()

    const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
        if(!token){
            toast.error('Token not found');
            return
        }

        const payload = {
            token,
            password: data.password
        };

        createPassword(
            { payload }, 
            {
                onSuccess: (data) => {
                    toast.success('Password set successfully');
					setCurrentUser({ ...data, loginTime: new Date() });

					const userCategory = data.user.category;
					const adminUser = data.user.organization?.billingType.admin;

					switch(userCategory.toLowerCase()) {
						case "operations":
							if (adminUser) {
								setUserType("ADMIN");
								navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
							} else {
								setUserType("ORGANIZATION");
								navigate(PATHS.OVERVIEW);
							}
							break;
						case "hr":
							setUserType("HR");
							navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
							break;
						case "employee":
							setUserType("EMPLOYEE");
							navigate(PATHS.SELF_SERVICE_DASHBOARD);
							break;
					}
                },
                onError: (error) => {
                    if (
                        error.response?.data?.error === "OTP_EXPIRED" || 
                        error.response?.data?.message === "OTP has expired"
                    ) {
                        navigate(
                            PATHS.SIGN_UP_CONFIRMATION + 
                            `?username=${username}&resend=true`
                        );
                    }
                }
            }
        );
    };

    return (
        <OnboardingLayout>
            <Helmet>
                <title>Create Password | Akatua</title>
            </Helmet>
            <div className="flex flex-col px-24 justify-center w-full">
                <img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130}/>
                <h1 className="mt-5 font-bold text-xl text-charcoal">
                    Hey 👋, welcome to Akatua
                </h1>
                <div className="mt-4">
                    <form 
                        onSubmit={handleSubmit(onSubmit)}
                        className="text-charcoal"
                    >
                        <div className="mt-6 flex items-center w-full space-x-4">
                            <div className="w-full">
                                <Label title="Password" for="password"/>
                                <div className="relative">
                                    <input
                                        type={!isShown ? "password" : "text"}
                                        id={"password"}
                                        placeholder={"Enter password"}
                                        className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
                                            errors.password
                                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                        {...register("password", {
                                            required: "Enter your password here",
                                            minLength: {
                                                value: 8,
                                                message: "Password should be at least 8 characters",
                                            },
                                        })}
                                    />

                                    <div
                                        className={`absolute top-[26px] right-6 block `}
                                        onClick={() => setIsShown(prev => !prev)}
                                    >
                                        {!isShown ? (
                                            <BsEyeSlash className="text-gray-400 text-lg"/>
                                        ) : (
                                            <BsEye className="text-gray-400 text-lg"/>
                                        )}
                                    </div>
                                </div>
                                {errors.password && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.password.message}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <Label title="Confirm Password" for="confirmPassword"/>
                                <div className="relative">
                                    <input
                                        type={!isShown ? "password" : "text"}
                                        id={"confirmPassword"}
                                        placeholder={"Re-Enter new password"}
                                        className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
                                            errors.confirmPassword
                                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                        {...register("confirmPassword", {
                                            required: "Confirm your new password",
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                    const { password } = getValues();
                                                    return (
                                                        password === value || "Passwords should match!"
                                                    );
                                                },
                                            },
                                        })}
                                    />
                                    <div
                                        className={`absolute top-[26px] right-6 block `}
                                        onClick={() => setIsShown(prev => !prev)}
                                    >
                                        {isShown === false ? (
                                            <BsEyeSlash className="text-gray-400 text-lg"/>
                                        ) : (
                                            <BsEye className="text-gray-400 text-lg"/>
                                        )}
                                    </div>
                                </div>
                                {errors.confirmPassword && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.confirmPassword.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="w-[50%]">
                            <p className="text-xs mt-8">
                                By signing up you agree to the{" "}
                                <span className="text-primary-blue">Terms and Conditions</span>
                            </p>
                            <OrangeButton
                                title="Set Password"
                                className="w-full mt-10 font-light"
                                type="submit"
                                disabled={isLoading}
                            />
                        </div>
                    </form>
                </div>
                {/* <p className="text-xs mt-6">
					Go back to{" "}
					<span className="text-primary-blue">
						<Link to="/signin">Sign in</Link>
					</span>
				</p> */}
            </div>
        </OnboardingLayout>
    );
};

export default SetPassword;
