import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CurrencyDto, CurrencyPaginator,
    CreateCurrencyDto, QueryCurrencyDto,
    UpdateCurrencyDto,
    SearchCurrencyDto
} from "../models/currency.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetCurrency(options?: QueryCurrencyDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.GET;
    const request = (options: QueryCurrencyDto) => API.BillingAndSubscriptionAPI.getCurrency(options);

    const { data, isLoading, error, refetch } = useQuery<CurrencyPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetCurrencySearch() {

    return useMutation(
        (options?: SearchCurrencyDto) => API.BillingAndSubscriptionAPI.getCurrencySearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetCurrencyById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.DETAIL
    const request = API.BillingAndSubscriptionAPI.getCurrencyById(id);

    const {data, isLoading, error} = useQuery<CurrencyDto, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateCurrency(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateCurrencyDto}) => API.BillingAndSubscriptionAPI.createCurrency(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.GET);
            },
        }
    )
}

export function useUpdateCurrency() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateCurrencyDto, id: number}) => API.BillingAndSubscriptionAPI.updateCurrency(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.CURRENCY.GET);
            },
        }
    )
}

export function useDeleteCurrency() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteCurrency(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}
