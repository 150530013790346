import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "colorCode",
		headerName: "Color Code",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className={`w-[80px] h-[30px] border-[0.5px] bg-[${params.value}]`} 
					style={{ backgroundColor: params.value }} 
				/>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];
