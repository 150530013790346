import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import EmployeesMainContent from "./main";
import { Helmet } from "react-helmet-async";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { PATHS } from "../../../routes/routes.paths";

const Employees = () => {
	const { userType } = useUserStore();

	return (<>
		{userType === "ORGANIZATION" ? (
			<PayrollCompanyLayout>
				<Helmet>
					<title>Employees | Company Flow</title>
				</Helmet>

				<EmployeesMainContent />
			</PayrollCompanyLayout>
		):(
			<HumanResourceLayout>
				<Helmet>
					<title>Employee Management | HR Flow</title>
				</Helmet>
				
				<EmployeesMainContent />
			</HumanResourceLayout>
		)}
	</>);
};

export default Employees;
