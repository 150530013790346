import {PaginatorInfo} from "./generic.dto";
import {EmployeeUnionDto} from "./employee-union.dto";

export const UNION_DEDUCTION_FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    ANNUALLY: 'ANNUALLY'
};

export type UNION_DEDUCTION_FREQUENCY = (typeof UNION_DEDUCTION_FREQUENCY)[keyof typeof UNION_DEDUCTION_FREQUENCY]

type Union = {
    id: number
    companyId: number
    code: string
    name: string
    description: string
    duesCollected: number;
    deductionFrequency: UNION_DEDUCTION_FREQUENCY | null
    active: boolean
    createdAt: string
    modifiedAt: string | null
    currencyId: number | null
}

export class CreateUnionDto {
    companyId!: number
    code!: string
    name!: string
    description?: string
    active?: boolean
    duesCollected!: number
    deductionFrequency!: UNION_DEDUCTION_FREQUENCY
    currencyId?: number
}
export interface UnionDto extends Union { }

export interface UnionPaginator extends PaginatorInfo<UnionDto> {}
export class UpdateUnionDto {
    code?: string
    name?: string
    description?: string
    duesCollected?: number
    active?: boolean
    deductionFrequency?: UNION_DEDUCTION_FREQUENCY
    currencyId?: number

}

export class QueryUnionDto {
    companyId?: number;
    code?: string
    deductionFrequency?: UNION_DEDUCTION_FREQUENCY
    active?: boolean;
    page?: number = 1;
    limit?: number;
    orderBy?: UnionOrderBy = UnionOrderBy.CREATED_AT_ASC;
}

export class SearchUnionDto {
    q?: string;
    page?: number = 1;
    limit?: number;
    orderBy?: UnionOrderBy = UnionOrderBy.CREATED_AT_ASC;
}

export enum UnionOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc',
}