import { Helmet } from "react-helmet-async";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import BonusView from "../../payroll_company/overtime_and_bonuses/views/BonusView";
import { PATHS } from "../../../routes/routes.paths"

const HRBonus = () => {
	return (
		<HumanResourceLayout>
			<Helmet>
				<title>Bonus Management | HR Flow</title>
			</Helmet>
			<h1 className="font-bold text-xl mb-8">Bonus Management</h1>
			<BonusView />
		</HumanResourceLayout>
	);
};

export default HRBonus;
