import {PaginatorInfo} from "./generic.dto";
import {Notch} from "./notches.dto";

export const PAY_PERIOD= {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};

export type PAY_PERIOD = keyof typeof PAY_PERIOD

export const NOTCH_TYPE = {
  FIXED:'FIXED',
  EVENLY_SPREAD: 'EVENLY_SPREAD'
}

export type NOTCH_TYPE = keyof typeof NOTCH_TYPE;

export type EmployeeBand = {
  id: number
  companyId: number
  name: string
  level: number
  description: string;
  payPeriod: PAY_PERIOD
  minimumBasicPay: number
  maximumBasicPay: number | null
  createdAt: string
  modifiedAt: string | null
  currencyId: number | null
}

export type CreateNotchesWithBandDto = {
  notchType: NOTCH_TYPE;
} & ({
  items: NotchDto[];
} | {
  code: string;
  count: number;
})

export class NotchDto {
  code!: string
  amount!: number
}

export class CreateEmployeeBandDto {
  companyId!: number;
  name!: string;
  description!: string;
  level!: number;
  minimumBasicPay!: number;
  maximumBasicPay?: number;
  payPeriod!: PAY_PERIOD;
  currencyId?: number;
  notch?: CreateNotchesWithBandDto;
}

export class UpdateEmployeeBandDto {
  name?: string;
  level?: number;
  description?: string;
  minimumBasicPay?: number;
  maximumBasicPay?: number;
  payPeriod?: PAY_PERIOD;
  currencyId?: number;
}

export interface EmployeeBandDto extends EmployeeBand {
  notches?: Notch[]
  notch?: Notch
}

export interface EmployeeBandPaginator extends PaginatorInfo<EmployeeBandDto> {}

export class QueryEmployeeBandDto {
  companyId?: number;
  page?: number = 1;
  limit?: number;
  orderBy?: EmployeeBandOrderBy = EmployeeBandOrderBy.CREATED_AT_ASC;
}

export enum EmployeeBandOrderBy {
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc',
  LEVEL_ASC = 'level:asc',
  LEVEL_DESC = 'level:desc',
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export class SearchEmployeeBandDto {
  q?: string
  page: number = 1;
  limit?: number;
  orderBy: EmployeeBandOrderBy = EmployeeBandOrderBy.CREATED_AT_ASC;
}