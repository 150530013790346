import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, formatDateAndTime } from "../../../helpers/appHelpers";
import { STATUS } from "../../../models/organization.dto";

export const Columns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "name",
		headerName: "Name",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getOrganizationStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getOrganizationStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p> 
						{params.value === "SUSPENDED_PENDING_PAYMENT" 
							? "Subscription Expired"
							: enumToStringConverter(params.value)
						}
					</p>
				</div>
			);
		},
	},
	{
		field: "contactEmail",
		headerName: "Contact Email",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "billingType",
		headerName: "Billing Type",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const billingType = params.value?.name;
			return billingType || "--";
		},
	},
	{
		field: "statusLastModifiedAt",
		headerName: "Status Date Modified",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const statusLastModifiedAt = formatDateAndTime(params);
			return statusLastModifiedAt;
		},
	},
	
	...createdAtmodifiedAtActions
];

export const getOrganizationStatusColor = (status: STATUS) => {
	switch(status) {
		case "ACTIVE":
			return "#4CAF50"
		case "SUSPENDED":
			return "#F44336"
		case "INACTIVE":
			return "#FFC107"
		case "SUSPENDED_PENDING_PAYMENT":
			return "#808080"
		default: 
			return "#2196F3"
	}
};