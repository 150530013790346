import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeleteModal from "../../../components/modals/DeleteModal";
import ActionPopover from "../../../components/popovers/actionPopover";
import CustomStyledTable from "../../../components/table/CustomStyledTable";
import TableFilter from "../../../components/table/filter/TableFilter";
import useCompanyStore from "../../../state-management/useCompanyStore";
import useEditFormStore from "../../../state-management/useEditFormStore";
import { DocumentTypesColumns } from "./data";
import { AiOutlinePlus } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import { useManageTableWithSearchParams, useManageTableWithSearchParamsData } from "../../../helpers/useManageTable";
import { CompanyDocumentTypeDto } from "../../../models/company-document-type.dto";
import { 
    useGetCompanyDocumentTypeSearch, 
    useGetCompanyDocumentType, 
    useDeleteCompanyDocumentType 
} from "../../../services/company-document-type.service";
import APISearchInput from "../../../components/search/APISearchInput";
import DocumentTypesModalForm from "./modal/DocumentTypesModalForm";

const CompanyDocumentTypes = () => {
	const { currentCompany } = useCompanyStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	
	const { mutate: searchCompanyDocumentTypes } = useGetCompanyDocumentTypeSearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<CompanyDocumentTypeDto>({
		fetchHook: useGetCompanyDocumentType,
		options: { companyId: currentCompany?.id }
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};

	const { formDataValues, setFormData } = useEditFormStore();
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteCompanyDocumentType } = useDeleteCompanyDocumentType();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteCompanyDocumentType(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadTableData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

    const additionalColumns = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            headerClassName: "super-app-theme--header",
            cellClassName: "actions ",
            getActions: (params: any) => {
                return [
                    <GridActionsCellItem
                        icon={<BiDotsHorizontalRounded />}
                        label="actions"
                        onClick={(e: any) => {
                            setFormData(params?.row);
                            setAnchorEl(e.currentTarget);
                        }}
                    />,
                ];
            },
        },
    ];

    const columnsWithAdditions = DocumentTypesColumns.map(
        (obj) => additionalColumns.find((o) => o.field === obj.field) || obj
    );

    return (
        <HumanResourceLayout>
            <Helmet>
                <title>Document Types | HR Flow</title>
            </Helmet>
            <h1 className="font-bold text-xl">Document Types</h1>
            <div className="flex justify-between items-center my-6">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchCompanyDocumentTypes}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Name",
										fieldName: "name",
									},
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
									{
										title: "Date Modified",
										fieldName: "modifiedAt",
									},
								]
							},
						]}
					/>
				</div>
				<div
					className="flex items-center text-primary-blue cursor-pointer"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-base" />
					<span className="text-sm">Add New Document Type</span>
				</div>
            </div>
            <CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                handleClose={handleClose}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEdit={setIsEditForm}
                setOpenModal={setOpenModal}
            />
            <DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name || ""}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
            />

            {openModal && (
                <DocumentTypesModalForm
                    open={openModal}
                    setOpen={setOpenModal}
                    title={
                        isEditForm ? "Update Document Type" : "Add New Document Type"
                    }
                    isEditForm={isEditForm}
                    tableAPIRef={tableAPIRef}
                />
            )}
        </HumanResourceLayout>
    );
};

export default CompanyDocumentTypes;