import { GridColDef } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, moneyFormat } from "../../../../../helpers/appHelpers";

export const RateTaxRuleColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "minTaxableAmount",
		headerName: "Min Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.minTaxableAmount;
			const currency = params.row.country?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "maxTaxableAmount",
		headerName: "Max Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.maxTaxableAmount;
			const currency = params.row.country?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{params.value > 0 ? `${params.value}%` : "--"}
				</div>
			);
		},
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const currency = params.row.country?.currency;
			return (
				<div className="flex items-center">
					{params.value > 0 ? 
						<p className="text-sm">
							{currency?.code || ""}{" "}{moneyFormat(params.value)}
						</p> 
						: "--"
					}
				</div>
			);
		},
	},
	{
		field: "incomePeriod",
		headerName: "Income Period",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "onDirectIncome",
		headerName: "On Direct Income",
		width: 200,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},

	...createdAtmodifiedAtActions,
];

export const BenefitsTaxRuleColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "benefitTypeInstance",
		headerName: "Benefit Type",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "minTaxableAmount",
		headerName: "Min Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.minTaxableAmount;
			const currency = params.row.country?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "maxTaxableAmount",
		headerName: "Max Taxable Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const amount = params.row.maxTaxableAmount;
			const currency = params.row.country?.currency;
			return <p className="text-sm">
				{currency?.code || ""}{" "}{moneyFormat(amount)}
			</p>;
		},
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{params.value > 0 ? `${params.value}%` : "--"}
				</div>
			);
		},
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const currency = params.row.country?.currency;
			return (
				<div className="flex items-center">
					{params.value > 0 ? 
						<p className="text-sm">
							{currency?.code || ""}{" "}{moneyFormat(params.value)}
						</p> 
						: "--"
					}
				</div>
			);
		},
	},
	{
		field: "isFinalTax",
		headerName: "Final Tax",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},

	...createdAtmodifiedAtActions,
];

export const ReliefTaxRuleColumns: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		hideable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 200,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "taxReliefType",
		headerName: "Tax Relief Type",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? params.value.name : "--",
	},
	{
		field: "rateComponent",
		headerName: "Rate Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center">
					{params.value > 0 ? `${params.value}%` : "--"}
				</div>
			);
		},
	},
	{
		field: "fixedComponent",
		headerName: "Fixed Component",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			const currency = params.row.country?.currency;
			return (
				<div className="flex items-center">
					{params.value > 0 ? 
						<p className="text-sm">
							{currency?.code || ""}{" "}{moneyFormat(params.value)}
						</p> 
						: "--"
					}
				</div>
			);
		},
	},
	{
		field: "incomePeriod",
		headerName: "Income Period",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},

	...createdAtmodifiedAtActions,
];
