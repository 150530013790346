import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../../../helpers/appHelpers";
import ModalComponent from "../../../../../../components/modals/ModalFormComponent";
import Label from "../../../../../../components/form_fields/Label";
import FormDescriptionInput from "../../../../../../components/form_fields/FormDescriptionInput";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../../../state-management/useEditFormStore";
import { Select, MenuItem } from "@mui/material";
import { CountryDto } from "../../../../../../models/country.dto";
import { useManageTableWithSearchParamsData } from "../../../../../../helpers/useManageTable";
import { useCreateBenefitType, useUpdateBenefitType } from "../../../../../../services/benefit-type.service";
import FormCodeInput from "../../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	countryId: number;
	benefitType: string;
	terminal?: string;
}

interface BenefitTypeModalFormProps extends IAddEditTableForm {
	countries: CountryDto[];
}

const BenefitTypeModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	countries,
}: BenefitTypeModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			benefitType: isEditForm ? formDataValues?.benefitType : "",
		}
	});

	const { mutate: createBenefitType } = useCreateBenefitType();
	const { mutate: updateBenefitType } = useUpdateBenefitType();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		data.terminal = data.terminal === "yes" ? true : false;
		
		if (isEditForm) {
			updateBenefitType(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.countryId = Number(data.countryId);
			createBenefitType(
				{ payload: data }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label title="Country" for="countryId" />
							<select
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
									errors.countryId
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("countryId", {
									required: "Select country here",
									valueAsNumber: true,
								})}
							>
								<option value="">
									Select Country
								</option>
								{countries.map((country) => (
									<option key={country.id} value={country.id} >
										{country.name}
									</option>
								))}
							</select>
							{errors.countryId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.countryId.message}
								</p>
							)}
						</div>
					)}
					<div className="w-full">
						<Label title="Benefit Type" for="benefitType" />
						<select
							defaultValue={isEditForm ? formDataValues?.benefitType : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.benefitType
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("benefitType", {
								required: "Select benefit type here",
							})}
						>
							<option value="">Select Benefit Type</option>
							<option value="MEDICAL">Medical</option>
							<option value="IN_KIND">In Kind</option>
							<option value="GIFT">Gift</option>
						</select>
						{errors.benefitType && (
							<p className="text-red-500 text-sm mt-1">
								{errors.benefitType.message}
							</p>
						)}
					</div>
					<div className="col-span-2 flex space-x-12 text-base">
						<h3>Terminal Benefit</h3>
						<div className="flex space-x-3 items-center">
							<input
								{...register("terminal", { required: false })}
								defaultChecked={isEditForm && formDataValues?.terminal === true}
								type="radio"
								value={"yes"}
								id="terminal"
							/>
							<label htmlFor="terminal">Yes</label>
						</div>
						<div className="flex space-x-3 items-center">
							<input
								{...register("terminal", { required: false })}
								defaultChecked={isEditForm && formDataValues?.terminal === false}
								type="radio"
								value={"no"}
								id="interminal"
							/>
							<label htmlFor="interminal">No</label>
						</div>
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Benefit Type"
								: "Add New Benefit Type"
						}`}
						className={`px-8`}
						type={"submit"}
						onClick={handleSubmit(onSubmit)}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default BenefitTypeModalForm;
