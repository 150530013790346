import React from 'react'

interface LabelProps{
	for: string;
	title: string;
	textSize?: string;
	optional?: boolean | string;
}

const Label = (props: LabelProps) => {
  return (
		<label htmlFor={props.for} className={`${props.textSize ? props.textSize : "text-sm"} max-lg:text-2xs font-medium text-secondary-darkgray flex gap-1`}>
			{props.title} 
			{props.optional && 
				<i className="font-bold">
					{typeof props.optional === "string" ? `(${props.optional})` : "(optional)"}
				</i>}
		</label>
	);
}

export default Label