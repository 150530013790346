import { PaginatorInfo } from "./generic.dto";
import { PaymentInstitutionDto } from "./payment-institution.dto";

export type PaymentInstitutionBranch = {
  id: number;
  paymentInstitutionId: number;
  name: string;
  sortCode: string;
  description: string;
  active: boolean;
  createdAt: Date;
  modifiedAt: Date | null;
}

export class CreatePaymentInstitutionBranchDto {
  paymentInstitutionId!: number;
  name!: string;
  sortCode!: string;
  description!: string;
}

export interface PaymentInstitutionBranchDto extends PaymentInstitutionBranch {
  paymentInstitution?: PaymentInstitutionDto;
}

export class UpdatePaymentInstitutionBranchDto {
  paymentInstitutionId?: number;
  name?: string;
  sortCode?: string;
  description?: string;
  active?: boolean;
}

export enum PaymentInstitutionBranchOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
  NAME_ASC = 'name:asc',
  NAME_DESC = 'name:desc',
  SORT_CODE_ASC = 'sortCode:asc',
  SORT_CODE_DESC = 'sortCode:desc'
}

export class QueryPaymentInstitutionBranchDto {
  paymentInstitutionId?: number;
  active?: boolean;

  page?: number = 1;
  limit?: number;
  orderBy: PaymentInstitutionBranchOrderBy = PaymentInstitutionBranchOrderBy.CREATED_AT_DESC;
}

export class SearchPaymentInstitutionBranchDto {
  q?: string;

  page = 1;
  limit?: number;
  orderBy: PaymentInstitutionBranchOrderBy = PaymentInstitutionBranchOrderBy.CREATED_AT_DESC;
}

export interface PaymentInstitutionBranchPaginator extends PaginatorInfo<PaymentInstitutionBranchDto> {
}