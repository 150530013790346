import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import { useResendConfirmationPassword } from "../../services/auth.service";
import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react";
import ModalComponent from "../../components/modals/ModalFormComponent";
import Label from "../../components/form_fields/Label";
import { useAsyncEffect, useLockFn } from "ahooks";
import API from "../../services/utils/api";
import { toast } from "react-toastify";

const SignupConfirmation = () => {
	const [searchParams] = useSearchParams();
	const resend = searchParams.get("resend");
	const [username, setUsername] = useState(
		searchParams.get("username") || ""
	);
	const [sending, setSending] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	useAsyncEffect(useLockFn(async () => {
		if (!resend || !username) return;

		setSending(true);

		try {
			await API.UserAPI.resendConfirmation({ username });
			
			toast.success("Email sent successfully!");
			setSending(false);
		} catch (error: any) {
			API.Handlers.onErrorHandler(error, undefined, undefined);
			setSending(false);
		}
	}), [resend])

	const { mutate, isLoading } = useResendConfirmationPassword();

	const resendConfirmation = () => {
		if (!username) {
			setOpenModal(true);
			return
		}

		mutate(
			{ payload: { username } },
			{ 
				onSuccess: () => {
					toast.success("Email sent successfully!");
					setOpenModal(false);
				}
			}
		)
	};

	return (
		<OnboardingLayout>
			<div className="flex flex-col items-center justify-center w-full">
				<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />

				<div className="w-[40%] text-center">
					<h1 className="mt-14 text-charcoal">
						We’ve sent you an account confirmation email, please check your
						inbox to complete the process.
					</h1>
					<p className="mt-2 text-charcoal text-sm">
						Go back to  
						<Link to="/signin" className="text-primary-blue"> Sign in </Link> page.
					</p> 
					<p className="my-8 text-charcoal text-sm">
						Didn’t receive the email?
					</p>
				</div>
				<div className="w-[30%]">
					<OrangeButton 
						className="w-full font-light" 
						title={isLoading ? "Sending..." : "Resend Email"} 
						onClick={resendConfirmation}
						disabled={isLoading}
					/>
				</div>

				<ModalComponent
					width={550}
					open={openModal}
					title="Account Confirmation"
					handleClose={() => setOpenModal(false)}
				>
					<div className="w-full">
						<Label title="Username" for="username" />
						<input
							placeholder={"Enter username"}
							type="text"
							id="username"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm border-none`}
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
					<div className="w-full flex justify-end mt-4">
						<OrangeButton 
							title={isLoading ? "Submitting..." : "Submit"}
							className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
							disabled={sending || isLoading} 
							onClick={resendConfirmation}
						/>
					</div>
				</ModalComponent>
			</div>
		</OnboardingLayout>
	);
};

export default SignupConfirmation;
