import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { SkewLoader } from "react-spinners";
import ModalComponent from "../../../../../components/modals/ModalFormComponent";
import { OvertimePaymentTierOrderBy } from "../../../../../models/overtime-payment-tier.dto";
import { useGetOvertimePaymentTier } from "../../../../../services/overtime-payment-tier.service";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import EditPaymentTier from "./EditPaymentTier";
import NewPaymentTier from "./NewPaymentTier";
import { CompanyCurrencyConfigDto } from "../../../../../models/company-currency.dto";

type OvertimePaymentTiersModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	companyCurrencies: CompanyCurrencyConfigDto[];
}

const OvertimePaymentTiersModal = ({ 
	open, 
	setOpen,
	companyCurrencies,
}: OvertimePaymentTiersModalProps) => {
	const { formDataValues } = useEditFormStore();
	const [showNewPaymentTierForm, setShowNewPaymentTierForm] = useState(false);
	
	const { data: paymentTiers, isLoading } = useGetOvertimePaymentTier({
		overtimeId: formDataValues?.id,
		orderBy: OvertimePaymentTierOrderBy.CREATED_AT_DESC,
		limit: 100,
    });
	
	return (
		<ModalComponent
			width={730}
			open={open}
			title={formDataValues.name + " Payment Tiers"}
			handleClose={() => setOpen(false)}
		>
			<div className="mb-4">
				<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
					<h2 className="font-semibold">Available Payment Tiers</h2>
					{!showNewPaymentTierForm && (
						<button
							onClick={() => setShowNewPaymentTierForm(true)}
							className="border-none flex items-center text-primary-blue"
						>
							<AiOutlinePlus className="mr-2 text-lg" />
							Add New Payment Tier
						</button>
					)}
				</div>

				{showNewPaymentTierForm && (
					<NewPaymentTier 
						overtimeType={formDataValues}
						setShowNewPaymentTierForm={setShowNewPaymentTierForm} 
						companyCurrencies={companyCurrencies}
					/>
				)}

				{isLoading && (
					<div className="text-center mt-10 py-10">
						<SkewLoader color="#F58B00" />
					</div>
				)}
	
				{paymentTiers?.data?.map((paymentTier, index) => (
					<EditPaymentTier 
						key={paymentTier.id}
						paymentTier={paymentTier}
						tier={paymentTiers?.data?.length - index}
						companyCurrencies={companyCurrencies}
					/>
				))}

				{(!isLoading && paymentTiers?.data?.length === 0) && (
					<div>
						<h3 className="text-center text-sm font-semibold mt-10">
							Sorry no payment tiers added yet. Click 'Add New Payment Tier' to begin.
						</h3>
					</div>
				)}
			</div>
		</ModalComponent>
	);
};

export default OvertimePaymentTiersModal;
