import React, { useState } from "react";
import ModalComponent from "../../modals/ModalFormComponent";
import Label from "../../form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../buttons/OrangeButton";
import { useChangePassword } from "../../../services/auth.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";

interface IChangePasswordModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	title: string;
}

interface IFormInputs {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const ChangePasswordModal = ({
	open,
	setOpen,
	title,
}: IChangePasswordModalProps) => {
    const [isShown, setIsShown] = useState(false);
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInputs>();

	const { mutate: changePassword } = useChangePassword();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		changePassword(
			{
				payload: {
					password: data.currentPassword,
					newPassword: data.newPassword
				}
			},
			{
				onSuccess: () => handleClose(),
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={window.innerWidth < 1024 ? "90%" : 600}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<div className="space-y-4 mt-4">
				<div className="w-full">
					<Label title="Current Password" for="currentPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"currentPassword"}
							placeholder={"Enter Current Password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.currentPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currentPassword", {
								required: "Enter your current password",
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.currentPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currentPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="New Password" for="newPassword"/>
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"newPassword"}
							placeholder={"Enter New Password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.newPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("newPassword", {
								required: "Enter your new password",
								minLength: {
									value: 8,
									message: "Password should be at least 8 characters",
								},
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.newPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.newPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Confirm Password" for="confirmPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"confirmPassword"}
							placeholder={"Re-enter New Password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.confirmPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("confirmPassword", {
								required: "Confirm your new password",
								validate: {
									matchesPreviousPassword: (value) => {
										const { newPassword } = getValues();
										return newPassword === value || "Passwords should match!";
									},
								},
							})}
							autoComplete="new-password"
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.confirmPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.confirmPassword.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex justify-end pt-10">
				<OrangeButton
					title="Save"
					className={"px-12"}
					onClick={handleSubmit(onSubmit)}
					disabled={uploading}
				/>
			</div>
		</ModalComponent>
	);
};

export default ChangePasswordModal;
