import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    CountryDto, CountryPaginator,
    CreateCountryDto, QueryCountryDto,
    SearchCountryDto,
    UpdateCountryDto
} from "../models/country.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetCountry(options?: QueryCountryDto) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.GET;
    const request = (options: QueryCountryDto) => API.BillingAndSubscriptionAPI.getCountry(options);

    const { data, isLoading, error, refetch } = useQuery<CountryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch };
}

export function useGetCountrySearch() {

    return useMutation(
        (options?: SearchCountryDto) => API.BillingAndSubscriptionAPI.getCountrySearch(options),
        {
            onSuccess: () => {
                //toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetCountryById(id: number) {
    const url = ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.DETAIL
    const request = API.BillingAndSubscriptionAPI.getCountryById(id);

    const {data, isLoading, error} = useQuery<CountryDto, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateCountry(onSuccessCallback?: (responseData?: any) => void) {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: CreateCountryDto}) => API.BillingAndSubscriptionAPI.createCountry(payload),
        {
            onSuccess: (response) => {
                toast.success('Record created successfully');
                if(onSuccessCallback){
                    onSuccessCallback(response)
                }
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.GET);
            },
        }
    )
}

export function useUpdateCountry() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload, id}: {payload: UpdateCountryDto, id: number}) => API.BillingAndSubscriptionAPI.updateCountry(id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.BILLING_AND_SUBSCRIPTION.COUNTRY.GET);
            },
        }
    )
}

export function useDeleteCountry() {

    return useMutation(
        ({ id }: { id: number }) => API.BillingAndSubscriptionAPI.deleteCountry(id),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

