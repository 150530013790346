import {useMutation} from "react-query";
import API from "./utils/api";
import {CreateOAuthTokenAccessDto} from "../models/document.dto";
import {APP_CONFIG} from "../helpers/appHelpers";
import { toast } from "react-toastify";



export function useCreateOAuthToken() {

    return useMutation(
        ({payload}: { payload: CreateOAuthTokenAccessDto }) => API.DocumentAPI.token(payload),
        {
            onSuccess: (response) => {
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
            },
        }
    )
}

export function useUploadFile() {

    return useMutation(
        ({payload, accessToken}: {
            payload: FormData;
            accessToken: string
        }) => API.DocumentAPI.uploadFile(payload, accessToken),
        {
            onSuccess: (response) => {
            },
            onError: (error, variables, context) => {
            },
            onSettled: () => {
            },
        }
    )
}

export async function uploadOneDocument(file: FormData, addAuth: boolean = true) {
    try{
        const oAuthData = {
            client_id: APP_CONFIG.PLATFORM_CLIENT.REACT_APP_CLIENT_ID || '',
            client_secret: APP_CONFIG.PLATFORM_CLIENT.REACT_APP_CLIENT_SECRET || '',
            grant_type: APP_CONFIG.PLATFORM_CLIENT.REACT_APP_GRANT_TYPE || ''
        };


        const token = addAuth ? await API.DocumentAPI.token(oAuthData) : 'NONE';

        if (token !== 'NONE') {
            if (token.access_token)
                return await API.DocumentAPI.uploadFile(file, token.access_token)
        }

        return await API.DocumentAPI.uploadFile(file, "");

    }catch(error){
        return null
    }
}
