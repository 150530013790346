import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AiOutlineDownCircle } from "react-icons/ai";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { EmployeeUnionDto } from "../../../../../../models/employee-union.dto";
import { useState, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDeleteEmployeeUnion, useUpdateEmployeeUnion } from "../../../../../../services/employee-union.service";
import { UnionDto } from "../../../../../../models/union.dto";
import DeleteModal from "../../../../../../components/modals/DeleteModal";

interface IFormInputs {
    unionId?: number;
    membershipNumber: string;
	active: boolean;
}

interface IEditUnion {
	employeeUnion: EmployeeUnionDto;
    unions: UnionDto[];
}

const EditEmployeeUnion = ({ employeeUnion, unions }: IEditUnion) => {
	const [union, setUnion] = useState(employeeUnion);
	const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateEmployeeUnion } = useUpdateEmployeeUnion();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
        defaultValues: {
            unionId: union.unionId,
            membershipNumber: union.membershipNumber || "",
        }
	});

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		updateEmployeeUnion(
			{ payload: data, id: union.id },
			{
				onSuccess: (data) => {
                    setUnion(data.data);
					setFormDisabled(true);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteEmployeeUnion } = useDeleteEmployeeUnion();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteEmployeeUnion(
			{ id: employeeUnion?.id },
			{
				onSuccess: () => setOpenDeleteModal(false),
				onSettled: () => setDeleting(false),
			}
		);
	};

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg lg:py-2 mb-2",
            }}
        >
            <AccordionSummary
                expandIcon={<AiOutlineDownCircle className="text-lg lg:text-xl" />}
                aria-controls={union.id + "bh-content"}
                id={union.id + "bh-header"}
                sx={{ border: "1px" }}
            >
                <h2 className="px-1 lg:px-3 font-inter text-2xs lg:text-sm font-semibold">
                    {employeeUnion.union?.name}
                </h2>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handleSubmit(onSubmit)} className="px-1 lg:px-3">
                    <>
                        <div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
                            <h2 className="font-semibold text-2xs">Details</h2>
                            <button
                                type="button"
                                className={`border-none text-primary-blue text-2xs ${
                                    !formDisabled ? "hidden" : ""
                                }`}
                                onClick={(e) => setFormDisabled(false)}
                            >
                                Edit
                            </button>
                        </div>
                        <div className="space-y-5 lg:space-y-0 lg:grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
                            <div className="w-full">
                                <Label title="Union" for="unionId" />
                                <select
                                    disabled={formDisabled}
                                    className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                        errors.unionId
                                            ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`}
                                    {...register("unionId", {
                                        required: "Select union here",
                                    })}
                                >
                                    <option className="opacity-50" value="">
                                        Select Union
                                    </option>
                                    {unions.map((union, index) => (
                                        <option
                                            key={index}
                                            value={union.id}
                                        >
                                            {union.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.unionId && (
                                    <p className="text-red-500 text-sm mt-1">{errors.unionId.message}</p>
                                )}
                            </div>
                            <div className="w-full">
                                <Label title="Membership Number" for="membershipNumber" />
                                <input
                                    disabled={formDisabled}
                                    placeholder={"Enter number"}
                                    type="text"
                                    {...register("membershipNumber", {
                                        required: "Enter membership number here",
                                    })}
                                    id="membershipNumber"
                                    className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                        errors.membershipNumber
                                            ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`}
                                />
                                {errors.membershipNumber && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.membershipNumber.message}
                                    </p>
                                )}
                            </div>
							<div className="col-span-2 flex space-x-3 items-center">
								<input 
									type="checkbox"
									defaultChecked={union.active === true}
									{...register("active", {
										required: false,
									})}
									id="active"
									disabled={formDisabled}
								/>
                                <label htmlFor="active" className="max-lg:text-sm">Active</label>
							</div>
                        </div>
                    </>
					
					{!formDisabled && (
						<div className="w-full flex items-center gap-4 lg:justify-end mt-10">
							<button
								type="button"
								className="px-12 py-4 max-lg:w-full border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12 max-lg:w-full"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={employeeUnion.union?.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default EditEmployeeUnion;