import {useQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    DepartmentLeadershipDto,
    CreateDepartmentLeadershipDto,
    QueryDepartmentLeadershipDto,
    DepartmentLeadershipPaginator,
    UpdateDepartmentLeadershipDto
} from "../models/department-leadership.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetDepartmentLeadership(companyId: number, options?: QueryDepartmentLeadershipDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET;
    const request = (options: QueryDepartmentLeadershipDto) => API.ProcessingCompanyAPI.getDepartmentLeadership(companyId, options);

    const { data, isLoading, error, refetch } = useQuery<DepartmentLeadershipPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useUnlinkDepartmentLeadership() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ companyId, id }: { companyId: number, id: number})  => API.ProcessingCompanyAPI.unlinkDepartmentLeadership(companyId, id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET);
                toast.success('Employee removed successfully');
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetDepartmentLeadershipById(companyId: number, id: number) {
    const url = ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.DETAIL;
    const request = API.ProcessingCompanyAPI.getDepartmentLeadershipById(companyId, id);

    const {data, isLoading, error} = useQuery<{ data:DepartmentLeadershipDto }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}
export function useCreateDepartmentLeadership() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ companyId, payload }: { companyId: number, payload: CreateDepartmentLeadershipDto}) => API.ProcessingCompanyAPI.createDepartmentLeadership(companyId, payload),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET);
            },
        }
    )
}

export function useUpdateDepartmentLeadership() {
    const queryClient = useQueryClient();

    return useMutation(
        (
            {payload, companyId, id}: {payload: UpdateDepartmentLeadershipDto, companyId: number, id: number}
        ) => API.ProcessingCompanyAPI.updateDepartmentLeadership(companyId, id, payload),
        {
            onSuccess: () => {
                toast.success('Record updated successfully');
            },
            onError: API.Handlers.onErrorHandler,
            onSettled: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET);
            },
        }
    )
}

export function useDeleteDepartmentLeadership() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ companyId, id }: { companyId: number, id: number }) => API.ProcessingCompanyAPI.deleteDepartmentLeadershipById(companyId,id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET);
                toast.success('Record deleted successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}